import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ReportSelectiveComponent } from '../../report-selective/report-selective.component';
import { ReportComponent } from '../report.component';
import { PermissionGuard } from 'src/app/core/permission-guard/permission.guard';

const routes: Routes = [{
  path: "",
  redirectTo: "report",
  pathMatch: 'full'
},
{
  path: "customreport",
  canActivate: [PermissionGuard],
  component: ReportComponent
},
{
  path: "report",
  canActivate: [PermissionGuard],
  component: ReportSelectiveComponent
}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ReportRoutingModule { }
