import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { SensorComponent } from '../sensor/sensor.component';
import { SettingsService } from 'src/app/core/services/settings/settings.service';
import { MessageService } from 'src/app/core/services/message/message.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ScoreDialogComponent } from 'src/shared/score-dialog/score-dialog.component';
import { Subscription } from 'rxjs';
import { MultienvironmentalScore, environmentalScore } from '../settings.model';
@Component({
  selector: 'app-create-environmental-score',
  templateUrl: './create-environmental-score.component.html',
  styleUrls: ['./create-environmental-score.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CreateEnvironmentalScoreComponent implements OnInit {
  symbol: string = '</>/=';
  defaultThresholdCount: number = 5;
  totalSensoryParts: environmentalScore[] = [];
  isupdate: boolean = false;
  isview = false;
  form: any;
  displayedColumns1: string[] = ['Threshold', 'Value', 'Score'];
  public subscriptions: Subscription[] = [];
  @Output() path = new EventEmitter<string>();
  @Input('environmentalvalue') environmentalvalue: any;
  constructor(private fb: FormBuilder, private SettingsService: SettingsService, private show_msg: MessageService,
    private cdref: ChangeDetectorRef,
    private dialog: MatDialog) { }
  albums: any = new FormArray([]);
  dataSource1: any[] = [];
  displayedColumns: string[] = ['Value', 'ScoreIndicator'];
  ngOnInit(): void {
    this.getSensorDatas();
    this.getEnvironmentscore();
  }
  sensorDataList: any[] = [];
  unfilteredlist: any[] = [];
  /**
   * get available sensor details Api
   */
  getSensorDatas() {
    this.subscriptions.push(this.SettingsService.getSensors().subscribe((res: any) => {
      this.unfilteredlist = res.data;
      this.getSensorType();
    }, (error: Response) => {
      console.log(error);
    }));
  }
  /**
   * form group for environmental score and value
   * @returns 
   */
  setvalue() {
    return new FormGroup({
      Value: new FormControl('', [Validators.required]),
      Score: new FormControl(0),
    });
  }
  /**
   * get control name for environmental score threshold table
   * @param index 
   * @param controlName 
   * @param array 
   * @returns 
   */
  getControl(index: number, controlName: string, array: FormArray): FormControl {
    return (array.at(index) as FormGroup).get(controlName) as FormControl;
  }
  sensorTypeList: any[] = [];
  /**
   * get sensor type Api
   */
  getSensorType() {
    this.subscriptions.push(this.SettingsService.getSensorType().subscribe((res: any) => {
      this.sensorTypeList = res.data;
      this.setdata();
    }, (error: Response) => {
      console.log(error);
    }));
  }
  /**
   * check whether its create ,update or view flow 
   */
  setdata() {
    this.environmentalvalue != undefined ? this.isupdate = true : this.isupdate = false;
    if (!this.isupdate) this.totalSensoryParts.push(this.setTotalThreshold());
    if (this.isupdate) this.setupdatevalue(this.environmentalvalue);
    if (this.isupdate) this.isview = this.environmentalvalue.screen == 'view' ? true : false;
  }
  /**
   * set default threshold data during create flow
   * @returns 
   */
  setTotalThreshold() {
    var sensor = new environmentalScore();
    var ary: any = new FormArray([]);
    var dataSource: any = new MatTableDataSource([]);
    sensor.headergroup = this.fb.group({
      sensor_type: new FormControl('', [Validators.required]),
      sensor_name: new FormControl('', [Validators.required])
    });
    for (var i = 0; i < this.defaultThresholdCount; i++) {
      ary.push(this.setvalue());
    }
    dataSource.data = ary.controls;
    sensor.multidevicethreshold.push({
      sensorSubType: '', thresholdList: ary,
      dataSource: dataSource
    });
    return sensor;
  }
  environmentaldata: any[] = [];
  /**
   * get environmental score values api
   */
  getEnvironmentscore() {
    this.subscriptions.push(this.SettingsService.getEnvironmentScore().subscribe((res: any) => {
      this.environmentaldata = res != null ? res : [];
    }, (error: Response) => {
      console.log(error);
    }));
  }
  /**
   * form group for sensor name and sensor type
   * @param a 
   * @param b 
   * @returns 
   */
  setheader(a: any, b: any) {
    return this.fb.group({
      sensor_type: new FormControl(a, [Validators.required]),
      sensor_name: new FormControl(b, [Validators.required])
    });
  }
  /**
   * set threshold data during update and view flow
   * @param data 
   */
  setupdatevalue(data: any) {
    var sensorsub = data.idSub.split('.');
    var sensor = new environmentalScore();
    sensor.headergroup = this.setheader(this.unfilteredlist.filter(m => (m.sensor_id == sensorsub[0]))[0].sensor_type_id, parseInt(sensorsub[0]));
    sensor.sensorType = this.unfilteredlist.filter(m => (m.sensor_id == sensorsub[0]))[0].sensor_type_id;
    sensor.sensorlist = this.unfilteredlist.filter(m => (m.sensor_type_id == sensor.sensorType));
    sensor.sensorName = parseInt(sensorsub[0]);
    var ary: any = new FormArray([]);
    var datasource: any = new MatTableDataSource([]);
    data.filter((c: any) => {
      ary.push(this.setvalueupdate(c));
    });
    datasource.data = ary.controls;
    sensor.multidevicethreshold.push({
      sensorSubType: '', thresholdList: ary,
      dataSource: datasource
    });
    this.totalSensoryParts.push(sensor);
  }
  /**
   * set value and score data during update and view flow
   * @param score 
   * @returns 
   */
  setvalueupdate(score: any) {
    return new FormGroup({
      Value: new FormControl(score.threshold_value, [Validators.required]),
      Score: new FormControl(score.threshold_max_score),
    });
  }
  /**
   * add new sensor and default threshold for that sensor
   */
  addNewField() {
    this.totalSensoryParts.push(this.setTotalThreshold());
  }
  /**
   * remove exesting sensor and threshold data for that sensor
   * @param index 
   */
  removeNewField(index: any) {
    this.totalSensoryParts.splice(index, 1);
  }

  /**
   * add new threshold for particular score table
   * @param source 
   * @param array 
   * @param index 
   */
  addSensor(source: MatTableDataSource<never>, array: FormArray, index: any) {
    if (array.length <= 9) {
      array.insert(index + 1, this.setvalue());
    } else {
      this.show_msg.errorSnackBar("env_score.reached_max_threshold");
    }
    source.data = array.controls as never[];
  }
  /**
   * remove selected threshold from particular threshold table
   * @param index 
   * @param source 
   * @param array 
   */
  removeSensor(index: any, source: MatTableDataSource<never>, array: FormArray) {
    array.removeAt(index);
    source.data = array.controls as never[];
  }
  /**
   * clear array and value from create to list component 
   */
  clear() {
    this.path.emit("");
  }
  /**
   * change sensor list with respect to selected sensor type
   * @param event 
   * @param index 
   */
  filterchange(event: any, index: any) {
    var sensorlist = this.unfilteredlist.filter(m => (m.sensor_type_id == event.value));
    var sensor: any[] = [];
    for (let j = 0; j < sensorlist.length; j++) {
      const element1 = sensorlist[j];
      let conf = false;
      for (let i = 0; i < this.environmentaldata.length; i++) {
        const element = this.environmentaldata[i];
        if (element.sensor_id == element1.sensor_id) conf = true;

      }
      element1.configured = conf;
      sensor.push(element1);
    }

    this.totalSensoryParts[index].sensorlist = sensor;
    this.selectSensor(event.value, index);
  }
  /**
   * sub-method of filterchange for selecting sensors
   * @param event 
   * @param index 
   */
  selectSensor(event: any, index: any) {
    var sensorTYdata = this.sensorTypeList.filter(m => (m.sensor_type_id == event))[0].types;
    sensorTYdata.sort((a: any, b: any) => a.sub_type_id - b.sub_type_id);
    if (sensorTYdata.length <= 1) {
      this.totalSensoryParts[index].multidevicethreshold = [];
      var multdata = new MultienvironmentalScore();
      multdata.sensorSubType = '';
      for (var i = 0; i < this.defaultThresholdCount; i++) {
        multdata.thresholdList.push(this.setvalue());
      }
      multdata.dataSource.data = multdata.thresholdList.controls;
      this.totalSensoryParts[index].multidevicethreshold.push(multdata);
    } else if (sensorTYdata.length > 1) {
      this.totalSensoryParts[index].multidevicethreshold = [];
      sensorTYdata.filter((d: any) => {
        var multdata = new MultienvironmentalScore();
        multdata.sensorSubType = d.sub_sensor_type;
        for (var i = 0; i < this.defaultThresholdCount; i++) {
          multdata.thresholdList.push(this.setvalue());
        }
        multdata.dataSource.data = multdata.thresholdList.controls;
        this.totalSensoryParts[index].multidevicethreshold.push(multdata);
      });
    }
  }
  /**
   * get sensor type name in dual sensor module(temprature and humidity)
   * @param data 
   * @returns 
   */
  setSubType(data: any) {
    var nameid = null;
    this.sensorTypeList.filter((m) => {
      var list = m.types.filter((n: any) => n.sub_sensor_type == data);
      if (list.length > 0) nameid = list[0].sub_type_id;
    });
    return nameid;
  }
  /**
   * info popup to show maximum score value in table
   * @param array 
   * @param controlName 
   */
  info(array: FormArray, controlName: string) {
    var maxval = 0;
    var attain = false;
    array.controls.filter((a: any) => {
      var index = array.controls.indexOf(a);
      if (a.value[controlName] != '' && index != (array.controls.length - 1)) {
        maxval = parseFloat(a.value[controlName]) > maxval ? (parseFloat(a.value[controlName])) : maxval;
        attain = true;
      }
    });
    const config: MatDialogConfig = {
      panelClass: 'dialog-responsive-info',
      width: '40%',
      minWidth: '40%',
      disableClose: true,
      data: {
        title: `${attain ? maxval : ''}`,
        isDelete: true
      },
    };
    const openDialog = this.dialog.open(ScoreDialogComponent, config);
    this.subscriptions.push(openDialog.afterClosed().subscribe((result: any) => {
    }));
  }
  /**
   * check weather submit or update according to new score or existing score
   * @returns 
   */
  submit() {
    var array: any = [];
    this.totalSensoryParts.filter((m) => {
      m.multidevicethreshold.filter(n => {
        array.push(n.thresholdList);
      });
    });
    var data = this.verify(array);
    if (data) {
      return this.show_msg.errorSnackBar('no_empty_field');
    }
    var obj = this.createSaveobj();
    if (!this.isupdate) this.save(obj);
    // if (this.isupdate) this.update();
  }
  /**
   * verify where all fields are filled
   * @param array 
   * @returns 
   */
  verify(array: any[]) {
    var data = false;
    array.filter((m: any) => {
      for (var i = 0; i <= m.controls.length - 2; i++) {
        if (m.controls[i].status == 'INVALID') {
          data = true;
        }
      }
    });
    return data;
  }
  /**
   * create object for save api
   * @returns 
   */
  createSaveobj() {
    var datalist: any = [];
    this.totalSensoryParts.filter((m) => {
      m.multidevicethreshold.filter((n: any) => {
        for (var i = 0; i < n.thresholdList.controls.length - 1; i++) {
          var data: any = {};
          data['shed_id'] = JSON.parse(localStorage.getItem('tank') || '')?.id.toString(),
            data['sensor_id'] = m.headergroup.controls.sensor_name.value.toString();;
          data['sensor_type_id'] = m.headergroup.controls.sensor_type.value.toString();
          data['sub_type_id'] = this.setSubType(n.sensorSubType);
          data['threshold_value'] = n.thresholdList.controls[i].controls.Value.value;
          data['threshold_max_score'] = n.thresholdList.controls[i].controls.Score.value;
          datalist.push(data);
        }
        var data: any = {};
        data['shed_id'] = JSON.parse(localStorage.getItem('tank') || '')?.id.toString(),
          data['sensor_id'] = m.headergroup.controls.sensor_name.value.toString();;
        data['sensor_type_id'] = m.headergroup.controls.sensor_type.value.toString();
        data['sub_type_id'] = this.setSubType(n.sensorSubType);
        data['threshold_value'] = 10101;
        data['threshold_max_score'] = n.thresholdList.controls[i].controls.Score.value;
        datalist.push(data);
      });
    });
    return datalist;
  }
  /**
   * update if its exesting score not developed yet
   * @param score 
   */
  update(score: any) {
    score['shed_id'] = JSON.parse(localStorage.getItem('tank') || '')?.id.toString();
    // this.SettingsService.updateEnvironmentScore(score,this.environmentalvalue.environment_threshold_id).subscribe((res: any) => {
    //   console.log(res)
    //   this.path.emit("")
    // }, (error: Response) => {
    //   console.log(error)
    // })
  }
  /**
   * save if its new score
   * @param score 
   */
  save(score: any) {
    var data = {
      data: score
    };
    this.subscriptions.push(this.SettingsService.saveEnvironmentScore(data).subscribe((res: any) => {
      this.path.emit("");
    }, (error: Response) => {
      console.log(error);
    }));
  }
  ngOnDestroy() {
    this.subscriptions.forEach((sub: Subscription) => sub.unsubscribe());
  }
}
