import { Component, OnInit, OnDestroy, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatListOption, MatSelectionList } from '@angular/material/list';
import { Subscription } from 'rxjs';
import { HomeService } from 'src/app/core/services/home/home.service';
import { MessageService } from 'src/app/core/services/message/message.service';
import { ReportsService } from 'src/app/core/services/reports/reports.service';
import { SettingsService } from 'src/app/core/services/settings/settings.service';
import { SocketService } from 'src/app/core/services/socket/socket.service';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-report-selective',
  templateUrl: './report-selective.component.html',
  styleUrls: ['./report-selective.component.scss']
})
export class ReportSelectiveComponent implements OnInit, OnDestroy {
  public report_from_group: any;
  public regenerate_status: string = 'pending';
  public company_name = localStorage.getItem('company')?.length ? JSON.parse(localStorage.getItem('company') || '')?.name : '--';
  public tank_data = localStorage.getItem('tank')?.length ? JSON.parse(localStorage.getItem('tank') || '') : '--';
  public batch_id = localStorage.getItem('batch_id') ? JSON.parse(localStorage.getItem('batch_id') || '') : null;
  public birdtype = localStorage.getItem('tank')?.length ? JSON.parse(localStorage.getItem('tank') || '')?.breedType : null;
  public progress_percentage: any = localStorage.getItem('r_r_p') ? JSON.parse(localStorage.getItem('r_r_p') || '') : 0;

  public progress_interval: any;
  reportsList = [{ id: 1, name: "Productivity Report" }, { id: 2, name: "Sensor Report" }];
  typeList = [{ id: 1, name: "Weekly" }, { id: 2, name: "Monthly" }];
  public subscriptions: Subscription[] = [];
  optionlist: any = [];
  weeklyOptions: any[] = [];
  selectedWeeklyOptions: any[] = [];
  selectedMonthlyOptions: any[] = [];
  monthlyOptions: any[] = [];
  public access: any;
  @ViewChild('weeklyList') weeklyList!: MatSelectionList;
  @ViewChild('monthlyList') monthlyList!: MatSelectionList;

  @ViewChild('fileImportInput') fileImportInput: any;

  csvRecords: any;
  header: boolean = false;

  constructor(private fb: FormBuilder, private show_msg: MessageService,
    private report: ReportsService, private home: HomeService,
    private SettingsService: SettingsService,
    private socket: SocketService,

    private http: HttpClient,
    private route: Router,
    private translate: TranslateService,

  ) {
    this.access = this.home.getAccess(9);
  }
  getCSVData(url: any) {
    return this.http.get(url, { responseType: 'text' });
  }

  ngOnInit(): void {

    if (localStorage.getItem('popupAllowed') && localStorage.getItem('popupAllowed') == 'yes') {
      console.log('Popup Allowed');

    } else {
      var windowName = 'userConsole';
      var popup = window.open('/popup-page.php', windowName, 'width=1, height=1, scrollbars, resizable');
      if (!popup || popup.closed || typeof popup.closed === 'undefined') {
        alert(`
        Please allow  pop-up  by clicking the icon
        appears on your browser's address bar.
        Then click "OK"
        (Access needed for download Report Files)`);
      } else {
        localStorage.setItem('popupAllowed', 'yes')
        popup.close();
      }
    }


    this.socket.report_regenerate.subscribe((res: any) => {
      console.log("SOCKET");
      console.log(res);
      
      if (res?.tank_id == this.tank_data.id) {
        this.progress_percentage = 100;
        clearInterval(this.progress_interval);
        setTimeout(() => {
          this.reportRegenerateStatus();
        }, 2000);
      }
    });
    this.reportRegenerateStatus();
    this.report_from_group = this.fb.group({
      report: new FormControl({ value: '', disabled: true }),
      report_type: new FormControl({ value: '', disabled: true })
    });
    this.getSensorDatas();
  }

  reportregenerateProgressBar = () => {
    this.progress_interval = setInterval(() => {
      this.progress_percentage = (this.progress_percentage + 1) % 101;
      localStorage.setItem('r_r_p', this.progress_percentage);
      console.log("progress_percentage", this.progress_percentage);
      if (this.progress_percentage == 99) {
        clearInterval(this.progress_interval);
      }
    }, 2000);


  };
  reportRegenerateMissing = () => {
    this.regenerate_status = 'pending';
    this.reportregenerateProgressBar();
    this.report.regenerateMissing().subscribe((res: any) => {

    }, (error) => {
      this.percentageResetFn();
      clearInterval(this.progress_interval);
    });

  };

  reportRegenerateStatus = () => {
    this.report_from_group?.controls['report'].setValue('');
    this.cleanupFunction();

    this.report.regenerateStatus().subscribe(async (res: any) => {
      if (res?.data?.length) {
        if (res.data[0].status == 'pending') {
          console.log("IF");
          this.regenerate_status = res.data[0].status;
          this.reportregenerateProgressBar();
        } else {
          this.percentageResetFn();
        }
      } else {
        this.percentageResetFn();
      }
    });

  };

  percentageResetFn = () => {
    this.regenerate_status = '';
    this.progress_percentage = 0;

  };

  sensorDataList: any[] = [];
  getSensorDatas() {
    this.subscriptions.push(this.SettingsService.getSensors().subscribe((res: any) => {
      this.sensorDataList = res.data;
    }, (error: Response) => {
      console.log(error);
    }));
  }

  waitFn = (time: number) => {
    return new Promise((res, rej) => { setTimeout(() => res(true), time); });
  };
  onWeeklyOptionClick(event: Event, option: any, monthlyList: any): void {
    monthlyList?.deselectAll();
    this.selectedMonthlyOptions = [];
    event.preventDefault();
    event.stopPropagation();
    const targetElement = event.target as HTMLElement;
    const matOption = targetElement.closest('mat-list-option');
    if (matOption) {
      const index = this.selectedWeeklyOptions.indexOf(option);
      if (index !== -1) {
        this.selectedWeeklyOptions.splice(index, 1);
      } else {
        this.selectedWeeklyOptions.push(option);
      }
    }
    this.selectedWeeklyOptions.sort((a, b) => a.collection_id - b.collection_id);
    this.translate.get("report.week").subscribe((translation) => {
      const commaSeparatedString: string = this.selectedWeeklyOptions.map(obj => translation + obj.week).join(', ');
      this.report_from_group.controls['report_type'].setValue(commaSeparatedString);
    })
  }
  onMonthlyOptionClick(event: Event, option: any, weeklyList: any): void {
    weeklyList?.deselectAll();
    this.selectedWeeklyOptions = [];
    event.preventDefault();
    event.stopPropagation();
    const targetElement = event.target as HTMLElement;
    const matOption = targetElement.closest('mat-list-option');
    if (matOption) {
      const index = this.selectedMonthlyOptions.indexOf(option);
      if (index !== -1) {
        this.selectedMonthlyOptions.splice(index, 1);
      } else {
        this.selectedMonthlyOptions.push(option);
      }
    }
    this.selectedMonthlyOptions.sort((a, b) => a.collection_id - b.collection_id);
    const commaSeparatedString: string = this.selectedMonthlyOptions.map(obj => obj.month).join(', ');
    this.report_from_group.controls['report_type'].setValue(commaSeparatedString);
  }
  selectReport(data: any, type: any) {
    if (this.report_from_group.controls['report'].value == data) return;
    const batch_id = localStorage.getItem('batch_id') ? JSON.parse(localStorage.getItem('batch_id') || '') : null;
    if (type == 1 && this.sensorDataList.length == 0) {
      this.show_msg.errorSnackBar("report_no_sensors");
      return;
    } else if (type == 2 && batch_id == null) {
      this.show_msg.errorSnackBar("report_no_active_batch");
      return;
    }

    this.translate.get(data).subscribe((translation) => {
      this.report_from_group.controls['report'].setValue(translation);
    });

    console.log("report", this.report_from_group.controls['report'].value, data);
    this.cleanupFunction();
    type == 1 ? this.getReportCollection('env') : this.getReportCollection('day_entry');
  }
  cleanupFunction() {
    this.report_from_group?.controls['report_type'].setValue('');
    this.weeklyOptions = [];
    this.monthlyOptions = [];
    this.selectedWeeklyOptions = [];
    this.selectedMonthlyOptions = [];

  }
  defineNot(event: Event, period: any) {
    if (this.report_from_group.controls['report'].value == 'Productivity Report') {
      this.show_msg.errorSnackBar(period == 'Month' ? 'report_month_data_empty' : 'report_week_data_empty');
    } else if (this.report_from_group.controls['report'].value == 'Sensor Report') {
      this.show_msg.errorSnackBar('report_sensor_inactive');
    }
  }
  getReportCollection(collection: string) {
    this.subscriptions.push(this.report.getReportCollection(collection, 'monthly').subscribe((res: any) => {
      if (res.length > 0) {
        this.monthlyOptions = res.sort((a: any, b: any) => b.collection_id - a.collection_id);
      }
    }, (error: Response) => {
      console.log(error);
    }));
    this.subscriptions.push(this.report.getReportCollection(collection, 'weekly').subscribe((res: any) => {
      if (res.length > 0) {
        this.weeklyOptions = res.sort((a: any, b: any) => b.collection_id - a.collection_id);
      }
    }, (error: Response) => {
      console.log(error);
    }));
  }
  openTrigger() {
    return this.report_from_group.controls['report'].value == '';
  }
  checkAlert(event: Event) {
    event.preventDefault();
    event.stopPropagation();
  }
  getPreviousMonths(numberOfMonths: number): any[] {
    const today = new Date();
    const previousMonths: { id: number, name: string; }[] = [{ id: 1, name: 'Current Month' }];
    const monthNames: string[] = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

    for (let i = 1; i <= numberOfMonths; i++) {
      const prevMonth = new Date(today.getFullYear(), today.getMonth() - i, 1);
      const prevMonthName = monthNames[prevMonth.getMonth()];
      const monthObj = { id: i + 1, name: prevMonthName };
      previousMonths.push(monthObj);
    }

    return previousMonths;
  }
  validate() {
    if (this.report_from_group.controls['report'].value != '' && this.report_from_group.controls['report_type'].value != '') {
      return true;
    } else {
      if (this.report_from_group.controls['report'].value == '') this.show_msg.errorSnackBar("no_report_selected");
      else this.show_msg.errorSnackBar("no_checkbox_selected");
      return false;
    }
  }

  downloadReport() {
    var dataList = [];
    if (this.validate()) {
      this.selectedWeeklyOptions.length > 0 ? dataList = this.selectedWeeklyOptions : dataList = this.selectedMonthlyOptions;
      console.log(dataList);
      dataList.forEach((value: any) => {
        this.subscriptions.push(this.report.getDownloadURL(value.file_key).subscribe((res: any) => {
          console.log(res);
          console.log(value.file_key)
          if (res.url) window.open(res.url, '_blank');
        }, (error: Response) => {
          console.log(error);
        }));
      });
    }
  }



  viewReport($event: any): void {
    var dataList = [];
    if (this.validate()) {
      this.selectedWeeklyOptions.length > 0 ? dataList = this.selectedWeeklyOptions : dataList = this.selectedMonthlyOptions;
    }

    for (let i = 0; i < dataList.length; i++) {
      const element = dataList[i];
      const keystring: string = element.file_key;
      const encode = window.btoa(keystring);
      const url = window.location.origin + `/#/viewreport/${encode}`;

      window.open(url, '_blank');
    }






    //  .toString();
    //  window.open(url, '_blank');

  }

  ngOnDestroy(): void {
    clearInterval(this.progress_interval);
    localStorage.removeItem('r_r_p');
  }
}

