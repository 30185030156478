<div class="h-100">
    <div *ngIf="!createscreen" fxFlex="100" fxLayout="column" class="per1pad full-div" fxLayoutGap="10">
        <!-- Blue header with create and list button with company details -->
        <mat-card style="background-color: #166DA6;color: white;height:4rem;overflow: hidden;" fxLayout="row"
            fxLayoutAlign="space-between center">
            <div fxLayout="column">
                <span style="font-size: 13px;color: white;font-weight: bold;line-height: 1;">{{shed_name}}</span>
                <span style="line-height: 1;margin-top: 7px;font-size: 10px;
                color: #FFFFFF;">{{company_name}}</span>
            </div>
            <div fxLayoutAlign="center center" fxLayoutGap="10">
                <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="5"
                    *ngIf="dataSource?.data?.length && !createscreen &&(access?.create && !access?.view)">
                    <mat-icon style="color: #FF6174 !important;" matTooltipClass="line-broken-tooltip"
                        [matTooltip]="recal_status?.msg ||''" *ngIf="recal_status?.status =='failed'">cancel</mat-icon>
                    <mat-icon style="color: #26de81 !important;" matTooltipClass="line-broken-tooltip"
                        [matTooltip]="recal_status?.msg ||''"
                        *ngIf="recal_status?.status == 'success'">verified</mat-icon>

                    <button mat-stroked-button class="add-button grow hide" (click)="recalculate();"
                        [disabled]="recal_status?.status == 'initiated'">
                        <span fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="5">
                            <mat-spinner strokeWidth="3" [diameter]="25"
                                *ngIf="recal_status?.status == 'initiated'"></mat-spinner>
                            <mat-icon style="height: 22px;width: 13px;margin-right: 4px;"
                                svgIcon="recalculate"></mat-icon>
                            <span>{{recal_status?.status == 'initiated'? ('growth_score.recalculating'|translate|titlecase)+'...' :'growth_score.recalculate'|translate|uppercase}}</span>
                        </span>

                    </button>
                </div>

                <button *ngIf="!createscreen &&(access?.create && !access?.view)" mat-stroked-button
                    class="add-button grow hide" fxLayout="row" (click)="createList();">
                    <mat-icon class="add-icon">add_circle</mat-icon>
                    <span>{{'button.create'|translate|uppercase}}</span>
                </button>
                <button *ngIf="!createscreen &&(access?.create && !access?.view)" mat-stroked-button
                    class="add-button grow show"
                    style="min-width: 0;width: 34px;justify-content: center;align-items: center;"
                    (click)="createList();">
                    <mat-icon class="listicon" svgIcon="plus" style="height: 22px;width: 22px;"></mat-icon>
                </button>
                <button *ngIf="createscreen &&(access?.create && !access?.view)" mat-stroked-button
                    class="add-button grow" fxLayout="row" (click)="createList()">
                    <mat-icon svgIcon="list" style="margin-right: 6px;height: 22px;width: 22px;"></mat-icon>
                    <span>{{'growth_score.list'|translate|uppercase}}</span>
                </button>
                <!-- bulk upload popup button -->
                <button *ngIf="!createscreen && (access?.create && !access?.view)" mat-stroked-button
                    class="add-button grow hide" fxLayout="row" (click)="bulkUpload();">
                    <mat-icon style="margin-right: 6px;" class="momarg">cloud_upload</mat-icon>
                    <span class="hide">{{'growth_score.upload_file'|translate|uppercase}}</span>
                </button>
                <!-- downoad sop records button -->
                <button *ngIf="!createscreen && tabledata.length>0 && (access?.create && !access?.view)"
                    mat-stroked-button class="add-button grow flixbttn hide" fxLayout="row" (click)="downloadGrowth();">
                    <mat-icon style="margin-right: 6px;" class="momarg">cloud_download</mat-icon>
                    <span>{{'growth_score.download_sop'|translate|uppercase}}</span>
                </button>
                <button class="show" style="width: 1.5rem;" *ngIf="!createscreen" mat-icon-button
                    [matMenuTriggerFor]="bulkupload">
                    <mat-icon style="color: white;">more_vert</mat-icon>
                </button>
                <!-- bulk upload and download sop mob and tab view -->
                <mat-menu #bulkupload="matMenu" xPosition="before">
                    <div class="redirect-menu">
                        <ng-container>
                            <button *ngIf="access?.create && !access?.view" mat-menu-item (click)="bulkUpload();">
                                <span>{{'growth_score.upload_file'|translate|uppercase}}</span>
                            </button>
                        </ng-container>
                        <ng-container>
                            <button *ngIf="access?.create && !access?.view" mat-menu-item (click)="downloadGrowth();">
                                <span>{{'growth_score.download_sop'|translate|uppercase}}</span>
                            </button>
                        </ng-container>
                        <ng-container>
                            <button *ngIf="access?.create && !access?.view" mat-menu-item (click)="recalculate();"
                                [disabled]="recal_status?.status == 'initiated'">
                                <span fxLayout="row"
                                    fxLayoutAlign="{{recal_status?.status == 'initiated' ?'center center':'start start' }}"
                                    fxLayoutGap="5">
                                    <mat-spinner strokeWidth="3" [diameter]="25"
                                        *ngIf="recal_status?.status == 'initiated'"></mat-spinner>
                                    <!-- <mat-icon style="height: 22px;width: 13px;margin-right: 4px;" svgIcon="recalculate"
                                        *ngIf="!(recal_status?.status == 'initiated')"></mat-icon> -->
                                    <span>{{recal_status?.status == 'initiated'? ('growth_score.recalculating'|translate|titlecase ) +'...':"growth_score.recalculate"|translate|uppercase}}</span>
                                </span>
                            </button>
                        </ng-container>

                    </div>
                </mat-menu>
            </div>
        </mat-card>
        <div fxFlex="85" fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="10px" *ngIf="!createscreen">
            <div fxLayout="column" fxFlex="100" fxLayoutGap="10">
                <mat-card fxLayout="column">
                    <div fxLayoutAlign="start start" fxLayoutAlign.lt-sm="center center"
                        style="width: 100%;overflow: auto;" class="scrollnull">
                        <div style="width: 100%;">
                            <!-- load empty image when no table data is available  -->
                            <div *ngIf="tabledata.length==0 && isloaded" style="color: #3B3B3B;text-align: center;height: 60vh;align-items: center;
                                display: grid;">
                                <div fxLayout="column" fxLayoutGap="10px" style="justify-content: center;
                                align-items: center;">
                                    <img src="../../../assets/images/empty/Createscreens.png" alt=""
                                        style="height: 140px;width: 140px;">
                                    <span>{{'growth_score.empty_msg'|translate|titlecase}}</span>
                                </div>
                            </div>
                            <!-- load table when table data is available  -->
                            <table class="level-one-table" mat-table [dataSource]="dataSource"
                                *ngIf="tabledata.length>0" style="min-width: 150vh;">

                                <!-- Age in weeks Column -->
                                <ng-container matColumnDef="Age in weeks">
                                    <th mat-header-cell *matHeaderCellDef>
                                        {{'growth_score.age_in_weeks'|translate|titlecase}}
                                    </th>
                                    <td mat-cell *matCellDef="let element;let i = index"> {{element.age}} </td>
                                </ng-container>

                                <!-- Parameters Column -->
                                <ng-container matColumnDef="Parameters">
                                    <th mat-header-cell *matHeaderCellDef>
                                        {{'growth_score.parameters'|translate|titlecase}}
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        <ul>
                                            <li *ngFor="let item of parameters" style="text-align:initial">{{'growth_score.'+item |translate | titlecase }}</li>
                                        </ul>
                                    </td>
                                </ng-container>

                                <!-- No.of.Thresholds Column -->
                                <ng-container matColumnDef="Total No.of.Thresholds">
                                    <th mat-header-cell *matHeaderCellDef>
                                        {{'growth_score.total_threshold'|translate|titlecase}}
                                    </th>
                                    <td mat-cell *matCellDef="let element"> {{element.totalthreshold}} </td>
                                </ng-container>

                                <!-- Min Score Column -->
                                <ng-container matColumnDef="Min Score">
                                    <th mat-header-cell *matHeaderCellDef>
                                        {{'growth_score.min_score'|translate|titlecase}}
                                    </th>
                                    <td mat-cell *matCellDef="let element"> {{element.min_score}} </td>
                                </ng-container>
                                <!-- Max score Column -->
                                <ng-container matColumnDef="Max score">
                                    <th mat-header-cell *matHeaderCellDef>
                                        {{'growth_score.max_score'|translate|titlecase}}
                                    </th>
                                    <td mat-cell *matCellDef="let element"> {{element.max_score}} </td>
                                </ng-container>
                                <!-- Action Column -->
                                <ng-container matColumnDef="Action">
                                    <th mat-header-cell *matHeaderCellDef>{{'growth_score.action'|translate|titlecase}}
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        <!-- <div style="display: flex;">
                                            <div style="width: 100%;">
                                                <span><mat-icon class="iconvalue" svgIcon="Edit"
                                                        (click)="editvalue(element.id,false)"
                                                        style="margin:0%"></mat-icon></span>
                                            </div>
                                            <div style="width: 100%;">
                                                <span><mat-icon class="iconvalue" svgIcon="view"
                                                        (click)="editvalue(element.id,true)"
                                                        style="margin:0%"></mat-icon></span>
                                            </div>
                                        </div> -->
                                        <div style="display: flex;gap: 20%;justify-content: center;">
                                            <mat-icon *ngIf="access?.edit && !access?.view" style="width: 1rem;"
                                                class="grow" (click)="editvalue(element.id,false)">edit
                                            </mat-icon>
                                            <mat-icon style="width: 1rem;" class="grow" svgIcon="view"
                                                (click)="editvalue(element.id,true)">
                                            </mat-icon>
                                        </div>
                                    </td>
                                </ng-container>

                                <tr mat-header-row *matHeaderRowDef="displayedColumns2;sticky:true"></tr>
                                <tr mat-row *matRowDef="let row; columns: displayedColumns2;"></tr>
                            </table>
                        </div>
                    </div>
                </mat-card>
            </div>
        </div>
    </div>

    <ng-container *ngIf="createscreen">

        <!-- create growth score screen -->
        <app-create-growth-score style="width:100%" [growthdata]="growthScorevalue"
            (dataReceive)="handleData($event)"></app-create-growth-score>
    </ng-container>

</div>