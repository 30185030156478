<div class="bg-img">
    <mat-card fxLayout="column" fxFlex.sm="50" fxFlex.gt-sm="50" fxFlex.lt-sm="80"
        class="card-container mat-elevation-z8">
        <img height="20%" style="margin-top: 4rem;" src="../../../assets//images/success.svg" alt="">
        <span class="label m-t-20">{{'password_reset_success.updated'|translate|titlecase}}</span>
        <span style="color: #A4A4A4;margin-top: 10%">{{'password_reset_success.msg1'|translate|titlecase}}<br>
            {{'password_reset_success.msg2'|translate|titlecase}}</span>

        <div style="margin-top: 30%;">
            <button fxFlex="100" class="submit grow" mat-raised-button
                (click)="submit()">{{'password_reset_success.back_to_login'|translate|titlecase}}</button>

        </div>


    </mat-card>
</div>