<mat-card class="responsive-container" fxLayout="column">
    <!---------------------------Show message if company list is empty---------------------------------->

    <div *ngIf="!!!farm_list?.length && !is_create_farm">
        <app-common-message [message]="'farm_tab.farm_empty_msg' |translate |titlecase">
        </app-common-message>
    </div>

    <!-------------------------------------------------------------------------------------------------->
    <!----------------------------------To show farm list------------------------------------------------->

    <div *ngIf="!is_create_farm && !is_edit_farm" fxLayout.lt-sm="column" fxLayout="row warp">

        <ng-container *ngFor="let farm of farm_list;let i = index">
            <mat-card (click)="redirectToShed(farm)" fxFlex.gt-sm="23" fxFlex.sm="48" fxLayoutGap="30"
                fxLayoutGap.gt-md="35" fxLayoutGap.gt-lg="80" class="inner-card" fxLayout="column">
                <div fxLayout="row" fxLayoutAlign="space-between start" fxFlex="77"
                    style="padding: 8px;overflow: hidden;">
                    <div fxFlex="90" fxLayout="column" fxLayoutGap="5">

                        <div fxFlex="90" class="farm-elipsis" (click)="viewFarm(farm);$event.stopPropagation()">
                            <span class="name-underline">
                                {{farm.farm_name |titlecase}}
                            </span>
                        </div>


                        <div fxFlex="90" class="company-elipsis"
                            [matTooltip]="company_data.name?.length >47 ? company_data.name:''"
                            style="font-size: 11.5px;font-weight: 400;text-overflow: ellipsis;overflow:hidden">
                            {{company_data.name |titlecase}}
                        </div>
                    </div>
                    <!-- <div fxLayout="row" fxLayoutGap="16">
                        <mat-icon *ngIf="access?.edit && !access?.view" style="width: 1.2rem;" svgIcon="Edit"
                            (click)="editFarm(farm);$event.stopPropagation();">
                        </mat-icon>
                        <mat-icon *ngIf="access?.delete && !access?.view" style="width: 1rem;" svgIcon="Delete"
                            (click)="deleteFarm(farm);$event.stopPropagation();">
                        </mat-icon>
                    </div> -->
                    <div fxFlex="10" fxLayout="row"
                        *ngIf="(access?.edit && !access?.view)||(access?.delete && !access?.view)"
                        #triggerReport="matMenuTrigger" [matMenuTriggerFor]="process"
                        (click)="triggerReport.openMenu();$event.stopPropagation()" class="process">
                        <mat-icon class="process" style="color: #166DA6;width: 1.2rem">more_vert</mat-icon>
                    </div>
                    <mat-menu #process="matMenu" xPosition="before">
                        <div class="redirect-menu">
                            <ng-container *ngIf="access?.edit && !access?.view">
                                <button mat-menu-item (click)="editFarm(farm);$event.stopPropagation()">
                                    <span>{{'button.edit'|translate|titlecase}}</span>
                                </button>
                            </ng-container>
                            <ng-container *ngIf="access?.delete && !access?.view">
                                <button mat-menu-item (click)="deleteFarm(farm);$event.stopPropagation()">
                                    <span>{{'button.delete'|translate|titlecase}}</span>
                                </button>
                            </ng-container>
                        </div>
                    </mat-menu>
                </div>
                <div fxFlex="30" fxLayout="row" style="background-color: #166DA6;padding: 10px;" fxLayoutGap="15"
                    fxLayoutAlign="start center">
                    <div class="white-bg">
                        <img style="width: 18px" src="../../../../assets/images/company.svg" alt="" />
                    </div>
                    <div style="color: #ffffff; font-size: 14px; margin-top: 5px">
                        {{ farm.total_sheds }}
                    </div>
                </div>

                <!-- <div fxLayout="column" style="text-align: center;margin-top: 2rem;align-items: center;">
                    <div class="white-bg">
                        <img style="width: 20px;" src="../../../../assets/images/company.svg" alt="">
                    </div>
                    <div style="color: #1492E6;font-size: medium;margin-top: 5px;">{{farm.total_sheds}}</div>
                </div> -->
            </mat-card>


        </ng-container>


    </div>
    <!-------------------------------------------------------------------------------------------------->

    <!--------------------------------------farm creation form------------------------------------------->
    <div *ngIf="is_create_farm || is_edit_farm" fxLayout="column">
        <form [formGroup]="farm_form_group" style="margin: 25px;">
            <div fxLayout="row" fxLayoutAlign="center center">
                <div fxFlex="50" fxFlex.lt-sm="100" fxLayout="column">
                    <div class="label" style="padding-bottom: 10px;">{{is_edit_farm
                        ? ('farm_tab.update_farm_details' |translate|titlecase):'farm_tab.fill_farm_details'|translate|titlecase}}
                    </div>

                    <div>
                        <mat-form-field appearance="outline">
                            <mat-label>{{'farm_tab.farm_name'|translate|titlecase}}</mat-label>
                            <input  [maxLength]="50" (ngModelChange)="is_farm_name_changed = true"
                                (focusout)="isFarmNameExist()" matInput formControlName="farm_name" type="text"
                                oninput="this.value = this.value.trimStart().replace(/\s+/g, ' ')"
                                autocomplete="new-password">
                            <mat-error>
                                <span
                                    *ngIf="farm_form_group.controls['farm_name'].touched  && farm_form_group.get('farm_name')?.hasError('required')">
                                    {{'farm_tab.farm_name_msg'|translate|titlecase}}
                                </span>
                                <span
                                    *ngIf="farm_form_group.controls['farm_name'].touched  && farm_form_group.get('farm_name')?.hasError('alreadyExist')">
                                    {{'farm_tab.farm_name_exist_msg'|translate|titlecase}}
                                </span>
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <!-- <div>
                        <mat-form-field appearance="outline">
                            <mat-label>Farm Location</mat-label>
                            <input (focusin)="farm_form_group.controls['farm_location'].markAllAsTouched()" matInput
                                formControlName="farm_location" [readonly]="true" type="text"
                                autocomplete="new-password">
                            <mat-icon matSuffix class="remove-icon" (click)="openMap()">location_on</mat-icon>
                            <mat-error>
                                <span
                                    *ngIf="farm_form_group.controls['farm_location'].touched  && farm_form_group.get('farm_location')?.hasError('required')">
                                    Click on location icon to pick your farm location
                                </span>
                            </mat-error>
                        </mat-form-field>
                    </div> -->
                    <div>
                        <mat-form-field appearance="outline">
                            <mat-label>{{'farm_tab.farm_address'|translate|titlecase}}</mat-label>
                            <input  matInput formControlName="farm_address" type="text"
                                oninput="this.value = this.value.trimStart().replace(/\s+/g, ' ')"
                                autocomplete="new-password">
                            <mat-error>
                                <span
                                    *ngIf="farm_form_group.controls['farm_address'].touched  && farm_form_group.get('farm_address')?.hasError('required')">
                                    {{'farm_tab.farm_address_msg'|translate|titlecase}}
                                </span>
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <div>
                        <mat-form-field appearance="outline">
                            <mat-label>{{'farm_tab.farm_manager'|translate|titlecase}}</mat-label>
                            <mat-select disableOptionCentering (valueChange)="setContactNo($event)"
                                formControlName="user_id" panelClass="PanelClass" autocomplete="new-password">
                                <ng-container *ngFor="let user of manager_list">
                                    <mat-option [matTooltip]="user.user_name" [value]="user.user_id">{{user.user_name
                                        |titlecase}}
                                    </mat-option>
                                </ng-container>
                            </mat-select>
                            <mat-error>
                                <span
                                    *ngIf="farm_form_group.controls['user_id'].touched  && farm_form_group.get('user_id')?.hasError('required')">
                                    {{'farm_tab.farm_manager_msg'|translate|titlecase}}
                                </span>
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <div style="font-size: 13px">
                        <mat-form-field appearance="outline">
                            <mat-label>{{'farm_tab.country'|translate|titlecase}}</mat-label>
                            <input type="text" aria-label="Number" matInput autocomplete="new-password"
                                formControlName="country_id" [matAutocomplete]="auto"
                                oninput="this.value=this.value.replace(/[^a-zA-Z]/g,'');"
                                (focusout)="focusOutCountryField()">
                            <mat-icon matSuffix
                                style="color: rgba(0,0,0,.54);width: 21px;height: 1rem;font-size: 22px;">arrow_drop_down</mat-icon>
                            <mat-autocomplete #auto="matAutocomplete" [displayWith]="countryDisplayFn">
                                <mat-option (onSelectionChange)="onSelectState()"
                                    *ngFor="let country of countryObservable | async" [value]="country.country_id">
                                    {{country.country_name |titlecase}}
                                </mat-option>
                            </mat-autocomplete>
                            <mat-error>
                                <span
                                    *ngIf="farm_form_group.controls['country_id'].touched  && farm_form_group.get('country_id')?.hasError('required')">
                                    {{'farm_tab.country_msg'|translate|titlecase}}
                                </span>
                                <span
                                    *ngIf="farm_form_group.controls['country_id'].touched  && farm_form_group.get('country_id')?.hasError('isError')">
                                    {{'farm_tab.country_invalid_msg'|translate|titlecase}}
                                </span>
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <div style="font-size: 13px">
                        <mat-form-field appearance="outline">
                            <mat-label>{{'farm_tab.state'|translate|titlecase}}</mat-label>
                            <input type="text" matInput formControlName="state_id" autocomplete="new-password"
                                [matAutocomplete]="stateauto" oninput="this.value=this.value.replace(/[^a-zA-Z]/g,'');"
                                (focusin)="onFocusInState()" (focusout)="focusOutStateField()">
                            <mat-icon matSuffix
                                style="color: rgba(0,0,0,.54);width: 21px;height: 1rem;font-size: 22px;">arrow_drop_down</mat-icon>
                            <mat-autocomplete #stateauto="matAutocomplete" [displayWith]="stateDisplayFn">
                                <mat-option *ngFor="let state of stateObservable | async" [value]="state.state_id">
                                    {{state.state_name |titlecase}}
                                </mat-option>
                            </mat-autocomplete>
                            <mat-error>
                                <span
                                    *ngIf="!!farm_form_group.controls['country_id'].value && farm_form_group.controls['state_id'].touched  && farm_form_group.get('state_id')?.hasError('required')">
                                    {{'farm_tab.state_msg'|translate|titlecase}}
                                </span>

                                <span
                                    *ngIf="!!!farm_form_group.controls['country_id'].value  && farm_form_group.get('state_id')?.touched">
                                    {{'farm_tab.country_first_msg'|translate|titlecase}}
                                </span>

                                <span
                                    *ngIf="!!farm_form_group.controls['country_id'].value  && farm_form_group.get('state_id')?.hasError('isError')">
                                    {{'farm_tab.state_invalid_msg'|translate|titlecase}}
                                </span>
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <div>
                        <mat-form-field appearance="outline">
                            <mat-label>{{'farm_tab.city'|translate|titlecase}}</mat-label>
                            <input  matInput formControlName="city_name" type="text"
                                oninput="this.value = this.value.trimStart().replace(/\s+/g, ' ')"
                                autocomplete="new-password">
                            <mat-error>
                                <span
                                    *ngIf="farm_form_group.controls['city_name'].touched  && farm_form_group.get('city_name')?.hasError('required')">
                                    {{'farm_tab.city_msg'|translate|titlecase}}
                                </span>
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <div id="field-disabled">
                        <mat-form-field appearance="outline">
                            <mat-label>{{'farm_tab.contact_number'|translate|titlecase}}</mat-label>
                            <input matInput formControlName="contact_no" type="text" autocomplete="new-password">
                        </mat-form-field>
                    </div>

                </div>
            </div>

        </form>


        <div style="margin: 10px 0px;" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="50"
            fxLayoutGap.lt-sm="25">
            <button class="submit grow" mat-stroked-button (click)="submit()">{{is_edit_farm ?
                ('button.update'|translate|titlecase):'button.submit' |translate|titlecase}}</button>
            <button class="cancel grow" mat-stroked-button
                (click)="clearAll()">{{'button.cancel'|translate|titlecase}}</button>

        </div>

    </div>
    <!-------------------------------------------------------------------------------------------------->




</mat-card>