import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, startWith, map, Subscription } from 'rxjs';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { HomeService } from 'src/app/core/services/home/home.service';
import { MessageService } from 'src/app/core/services/message/message.service';
import { REGEX } from 'src/shared/helpers/validators';
@Component({
  selector: 'app-management-register',
  templateUrl: './management-register.component.html',
  styleUrls: ['./management-register.component.scss']
})
export class ManagementRegisterComponent implements OnInit, OnDestroy {
  public params_data: any;
  public subscription: Subscription[] = [];
  public user_form_group!: FormGroup;
  public hide = true;
  public hide1 = true;
  public country_list: any = [];
  public company_data: any = localStorage.getItem('company') ? JSON.parse(localStorage.getItem('company') || '') : null;

  mobile_dial_code_filter = new FormControl(null);
  whatsapp_dial_code_filter = new FormControl(null);
  public mobileDialcodeObservable!: Observable<any>;
  public whatsappDialcodeObservable!: Observable<any>;
  public is_expired: any = "";
  is_login_name_changed = false;
  public token: any;
  constructor (
    private activeRoute: ActivatedRoute,
    private route: Router,
    private home: HomeService,
    private fb: FormBuilder,
    private msg: MessageService,
    public auth: AuthService
  ) {
    if (this.activeRoute.snapshot.params["user_data"]) {
      this.params_data = JSON.parse(window.atob(this.activeRoute.snapshot.params["user_data"]));
      this.token = this.activeRoute.snapshot.params["user_data"];
    }
  }

  ngOnInit (): void {

    this.home.validateToken({ company_id: this.params_data?.company_id }).subscribe((res: any) => {
      if (res?.is_registered) {
        this.route.navigate(['/auth/login']);
        return;
      }

      this.is_expired = res?.expired ? "TRUE" : "FALSE";
    });

    this.formGroupFn();
    this.subscription.push(
      this.home.getCountryServices.subscribe((res: any) => {
        if (res?.length) {
          this.country_list = res;
          this.dialCodeObservableFn();
        }
      })
    );


  }

  formGroupFn () {
    this.user_form_group = this.fb.group({
      role_id: new FormControl(this.params_data?.user_role, [Validators.required]),
      company_id: new FormControl(this.company_data?.id
        || this.params_data?.company_id, [Validators.required]),
      user_name: new FormControl('', [Validators.required]),
      user_email: new FormControl('', [Validators.required, Validators.pattern(REGEX.EMAIL)]),
      dial_code_mobile_no: new FormControl('', [Validators.required]),
      mobile: new FormControl('', [Validators.required]),
      dial_code_whatsapp_no: new FormControl('', [Validators.required]),
      whatsapp: new FormControl('', [Validators.required]),
      user_login_name: new FormControl('', [Validators.required]),
      password: new FormControl('', [Validators.required, Validators.pattern(REGEX.PASSWORD)]),
      confirm_password: new FormControl('', [Validators.required]),
      email_alert: new FormControl(true),
      ring_alert: new FormControl(true),
      sms_alert: new FormControl(true),
      whatsapp_alert: new FormControl(true),
    });
  }

  /** Validates if password and confirm_password fields have matching values 
   * and updates the error status accordingly. */
  passwordMatchValidator = () => {
    if (!(this.user_form_group?.controls['password']?.value
      === this.user_form_group?.controls['confirm_password']?.value)) {
      this.user_form_group?.controls['confirm_password']
        .setErrors({ ...this.user_form_group?.controls['confirm_password'].errors, 'mismatch': true });
    }
    else {
      const error: any = this.user_form_group?.controls['confirm_password']?.errors;
      if (error) {
        delete error.mismatch;
        Object.keys(error).length
          ? this.user_form_group?.controls['confirm_password'].setErrors({ error })
          : this.user_form_group?.controls['confirm_password'].setErrors(null);
      }
    }

  };

  /**----------------------dial code and mobile  number all functions---------------------------------- */

  private conuntryFilter (value: string) {
    const filterValue = value?.toLowerCase();
    return this.country_list
      .filter((option: any) => option?.country_name?.toLowerCase().startsWith(filterValue));

  }

  /** Sets up observables for mobileDialcode and whatsappDialcode 
   * filters to update the filtered country list based on the entered value. */
  dialCodeObservableFn () {
    this.mobileDialcodeObservable = this.mobile_dial_code_filter.valueChanges.pipe(
      startWith(''),
      map((value: any) => {
        const name = typeof value === 'string' ? value : value?.country_name;
        return name ? this.conuntryFilter(name) : this.country_list.slice();;
      }
      ),
    );

    this.whatsappDialcodeObservable = this.whatsapp_dial_code_filter.valueChanges.pipe(
      startWith(''),
      map((value: any) => {
        const name = typeof value === 'string' ? value : value?.country_name;
        return name ? this.conuntryFilter(name) : this.country_list.slice();;
      }
      ),
    );
  }

  dialCodeFocusOut (field: any) {
    if (field == 'Mobile') {
      this.user_form_group.controls['mobile'].markAllAsTouched();
      this.mobile_dial_code_filter.reset();
    }

    if (field == 'WhatsApp') {
      this.user_form_group.controls['whatsapp'].markAllAsTouched();
      this.whatsapp_dial_code_filter.reset();
    }

  }

  /**------------------------------------------------------------------------------------------------- */

  /**--------------------------to submit management user data----------------------------------------- */
  /** Submits the user registration form, performs validations, checks login name existence,
   *  merges contact numbers, sends registration request, and navigates to the login page. */

  async submit () {

    if (this.user_form_group.invalid) {
      this.msg.showFromGroupWarning(this.user_form_group);
      return;
    }

    if (this.is_login_name_changed) {
      const isExist = await this.isLoginNameExist();
      if (isExist) return;
    }

    // merge dial code and contact number
    const mobile_no = this.user_form_group.controls['dial_code_mobile_no'].value + " "
      + this.user_form_group.controls['mobile'].value;
    const whatsapp_no = this.user_form_group.controls['dial_code_whatsapp_no'].value + " "
      + this.user_form_group.controls['whatsapp'].value;
    const data = { ...this.user_form_group.value, mobile_no, whatsapp_no };
    this.subscription.push(
      this.home.registerUser(data).subscribe((res: any) => {
        this.route.navigate(['/auth/login']);
      })
    );


  }

  /**------------------------------------------------------------------------------------------------- */



  /**-------------------------------to check  login name already exist or not------------------- */

  /** Checks login name existence and sets/clears 'alreadyExist' error on 'user_login_name' control. */
  /** Resolves with true if login name exists, false otherwise. */

  isLoginNameExist () {
    return new Promise((resolve, reject) => {
      const user_login_name = this.user_form_group.get("user_login_name")?.value;
      const requestData = {
        key: 'user',
        value: user_login_name,
        user_id: this.user_form_group.get("user_id")?.value
      };

      if (user_login_name?.length && this.is_login_name_changed && !this.user_form_group.controls['user_login_name']?.errors?.['alreadyExist']) {
        this.auth.isRecordExist(requestData).subscribe((res: any) => {
          if (res) {
            if (res?.isRecordExist) {
              this.user_form_group.controls['user_login_name']?.setErrors({ alreadyExist: true });
              resolve(true);
            } else {
              this.user_form_group.controls['user_login_name']?.setErrors(null);
              resolve(false);
            }
          }
          this.is_login_name_changed = false;
        });
      } else {
        resolve(false);
      }
    });
  }

  /**------------------------------------------------------------------------------------------- */

  onFocusIn () {
    this.user_form_group?.controls['confirm_password'].markAllAsTouched();
  }

  ngOnDestroy (): void {
    this.subscription.forEach((s: Subscription) => s.unsubscribe());
  }

}
