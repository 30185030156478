import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BatchSettingComponent } from './batch-setting/batch-setting.component';
import { MedicationComponent } from './medication/medication.component';
import { PermissionGuard } from 'src/app/core/permission-guard/permission.guard';

const routes: Routes = [

  {
    path: "batch-setting",
    canActivate: [PermissionGuard],
    component: BatchSettingComponent
  },
  {
    path: 'medication-schedule',
    canActivate: [PermissionGuard],
    component: MedicationComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class BatchRoutingModule { }
