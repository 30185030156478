<div class="m-p-1" fxLayout="column" fxLayoutGap="10">
    <mat-card style="background-color: #166DA6;color: white;height:4.2rem;" fxLayout="row"
        fxLayoutAlign="space-between center">
        <div fxLayout="column">
            <span style="font-size: 13px;color: white;font-weight: bold;line-height: 1;">{{tank_name}}</span>
            <span style="line-height: 1;margin-top: 7px;font-size: 10px;
        color: #FFFFFF;">{{company_name}}</span>
        </div>

        <div fxLayout="row" fxLayoutGap="10">
            <mat-spinner strokeWidth="3" [diameter]="30" *ngIf="showspin && dataSource?.length>0"></mat-spinner>
            <div style="display: grid;align-content: center;" *ngIf="showicon && !showspin && dataSource?.length>0">
                <mat-icon
                    [ngClass]="{alarmtick:icon=='tick' ,alarexc: icon=='exclamation',alardot: (icon=='dots'||icon=='sync')}"
                    [matTooltip]="message" matTooltipPosition="left" svgIcon="{{icon}}"
                    style="display: inline-flex;"></mat-icon>
            </div>
            <button *ngIf="access?.create && !access?.view" mat-stroked-button style="text-align: center;"
                fxLayout="row" class="add-button grow" (click)="syncSensor();">
                <span>{{'sensor.sync'|translate|titlecase}}</span>
            </button>
            <button mat-stroked-button class="add-button" fxLayout="row" *ngIf="access?.create && !access?.view"
                (click)="addSensor()">
                <mat-icon
                    class="add-icon grow">add_circle</mat-icon><span>{{'sensor.add_sensor'|translate|titlecase}}</span></button>
        </div>
    </mat-card>


    <mat-card>
        <div *ngIf="!dataSource?.length">
            <app-common-message [message]="'sensor.sensor_empty_msg'|translate|titlecase">
            </app-common-message>
        </div>
        <div *ngIf="!!dataSource?.length" fxLayout="column" fxLayoutGap="25" class="table-container">
            <table class="level-one-table" mat-table [dataSource]="dataSource">

                <!-- S.No Column -->
                <ng-container matColumnDef="S.NO">
                    <th mat-header-cell *matHeaderCellDef>{{'sensor.table_header.s_no' |translate |titlecase}}</th>
                    <td mat-cell *matCellDef="let element;let i = index"> {{i+1}} </td>
                </ng-container>

                <!-- Sensor name Column -->
                <ng-container matColumnDef="Sensor name">
                    <th mat-header-cell *matHeaderCellDef>{{'sensor.table_header.sensor_name'|translate|titlecase}}
                    </th>
                    <td mat-cell *matCellDef="let element"> {{element.name}} </td>
                </ng-container>

                <!-- Type Column -->
                <ng-container matColumnDef="Type">
                    <th mat-header-cell *matHeaderCellDef> {{'sensor.table_header.type'|translate|titlecase}} </th>
                    <td mat-cell *matCellDef="let element"> {{getSensorTypeName(element.type)}} </td>
                </ng-container>

                <!-- Product code Column -->
                <ng-container matColumnDef="Product code">
                    <th mat-header-cell *matHeaderCellDef> {{'sensor.table_header.product_code'|translate|titlecase}}
                    </th>
                    <td mat-cell *matCellDef="let element"> {{element.code}} </td>
                </ng-container>
                <!-- Device id Column -->
                <ng-container matColumnDef="Device id">
                    <th mat-header-cell *matHeaderCellDef>{{'sensor.table_header.device_id'|translate|titlecase}}</th>
                    <td mat-cell *matCellDef="let element"> {{element.dId}} </td>
                </ng-container>

                <!-- Installed location Column -->
                <ng-container matColumnDef="Installed location">
                    <th mat-header-cell *matHeaderCellDef>
                        {{'sensor.table_header.installed_location'|translate|titlecase}}
                    </th>
                    <td mat-cell *matCellDef="let element"> {{element.il}} </td>
                </ng-container>
                <!-- Installed Ulocation Column -->
                <ng-container matColumnDef="Installed Ulocation">
                    <th mat-header-cell *matHeaderCellDef>
                        {{'sensor.table_header.installed_ulocation'|translate|titlecase}}
                    </th>
                    <td mat-cell *matCellDef="let element"> {{element.iu}} </td>
                </ng-container>
                <!-- Action Column -->
                <ng-container matColumnDef="Action">
                    <th mat-header-cell *matHeaderCellDef>{{'sensor.table_header.action'|translate|titlecase}}</th>
                    <td mat-cell *matCellDef="let element">
                        <div style="display: flex;gap: 30%;">
                            <mat-icon *ngIf="access?.edit && !access?.view" class="grow" style="width: 1.2rem;"
                                svgIcon="Edit" (click)="editvalue(element.id)">
                            </mat-icon>
                            <mat-icon *ngIf="access?.delete && !access?.view" class="grow" style="width: 1rem;"
                                svgIcon="Delete" (click)="deleteSensor(element)">
                            </mat-icon>
                        </div>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky:true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>

        </div>

    </mat-card>
</div>