import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { MessageService } from 'src/app/core/services/message/message.service';
import { SettingsService } from 'src/app/core/services/settings/settings.service';
import { sensorAlarm } from '../settings.model';

@Component({
  selector: 'app-create-sensor-alarm',
  templateUrl: './create-sensor-alarm.component.html',
  styleUrls: ['./create-sensor-alarm.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CreateSensorAlarmComponent implements OnInit {
  public sensor_alarm_group: any;
  public sensor_name_group: any;
  public addSensorArray: any = new FormArray([]);
  sensorList: string[] = ['1', '2', '3'];
  unitList: any[] = [{ name: '', list: [] }];
  alertList: string[] = ['1', '2', '3', '4'];
  isupdate: boolean = false;
  isview = false;
  public subscriptions: Subscription[] = [];
  @Output() path = new EventEmitter<string>();
  @Input('sensordata') sensordata: any;
  constructor(private fb: FormBuilder, private show_msg: MessageService, private SettingsService: SettingsService) { }

  ngOnInit(): void {
    this.getAlertDatas();
    this.getSensorDatas();
    // this.getUnitDatas();
    this.sensordata != undefined ? this.isupdate = true : this.isupdate = false;
    if (this.isupdate) this.isview = this.sensordata.screen == 'view' ? true : false;
    if (!this.isupdate) this.sensorbuild();
    if (this.isupdate) this.sensorupdate(this.sensordata);
  }
  sensorlevelList: any[] = [];
  /**
   * get alert levels and details service Api
   */
  getAlertDatas() {
    this.subscriptions.push(this.SettingsService.getAlarts().subscribe((res: any) => {
      this.sensorlevelList = res.data;
    }, (error: Response) => {
      console.log(error);
    }));
  }
  sensorDataList: any[] = [];
  /**
   * get available sensor in shed Service API
   */
  getSensorDatas() {
    this.subscriptions.push(this.SettingsService.getSensors().subscribe((res: any) => {
      // this.sensorDataList = res.data;
      this.getTable(res.data);
    }, (error: Response) => {
      console.log(error);
    }));
  }
  /**
   * get sensor alarm details service API for sensor alarm table
   * @param data 
   */
  getTable(data: any[]) {
    this.subscriptions.push(this.SettingsService.getSensorAlarm().subscribe((res: any) => {
      var tabledata: any = [];
      tabledata = res.data;
      if (!this.isupdate) {
        data.filter(m => {
          var data1 = tabledata.filter((n: any) => (n.sensor_id == m.sensor_id));
          if (data1.length == 0) this.sensorDataList.push(m);
        });
      } else {
        this.sensorDataList = data.filter(m => (m.sensor_id == this.sensordata.sensor_id));
      }

      this.getUnitDatas();
    }, (error: Response) => {
    }));

  }
  unfilteredunits: any[] = [];
  /**
   * get units for sensor types service API
   */
  getUnitDatas() {
    this.subscriptions.push(this.SettingsService.getAllUnits().subscribe((res: any) => {
      this.unfilteredunits = res.data;
      this.getSensorType();
    }, (error: Response) => {
      console.log(error);
    }));
  }
  /**
   * form group for sensor name,battery and signal strength
   */
  sensorbuild() {
    this.sensor_name_group = this.fb.group({
      sensor_name: new FormControl('', [Validators.required]),
      battery: new FormControl(null),
      signal_strength: new FormControl(null),
    });
    this.addSensorArray.push(this.getsensordata());
  }
  /**
   * form group for sensor unit and alert levels
   * @returns 
   */
  getsensordata() {
    return this.fb.group({
      Units: new FormControl('', [Validators.required]),
      too_low_value: new FormControl('', [Validators.required]),
      too_low_alert_level: new FormControl('', [Validators.required]),
      low_value: new FormControl('', [Validators.required]),
      low_alert_level: new FormControl('', [Validators.required]),
      high_value: new FormControl('', [Validators.required]),
      high_alert_level: new FormControl('', [Validators.required]),
      too_high_value: new FormControl('', [Validators.required]),
      too_high_alert_level: new FormControl('', [Validators.required])
    });

  }
  sensorTypeList: any[] = [];
  /**
   * get sensor type service API
   */
  getSensorType() {
    this.subscriptions.push(this.SettingsService.getSensorType().subscribe((res: any) => {
      this.sensorTypeList = res.data;
      if (this.isupdate) this.selectunitsNDTY(this.sensordata);
    }, (error: Response) => {
      console.log(error);
    }));
  }
  /**
   * form group data for update screen during update and view
   * @param c 
   */
  sensorupdate(c: any) {
    this.sensor_name_group = this.fb.group({
      sensor_name: new FormControl(c.sensor_id),
      battery: new FormControl(c.battery_alert_level_id),
      signal_strength: new FormControl(c.signal_strength_alert_level_id),
    });
    this.sensor_alarm_group = this.fb.group({
      Units: new FormControl(c.unit_id),
      too_low_value: new FormControl(c.too_low_value),
      too_low_alert_level: new FormControl(c.too_low_level_id),
      low_value: new FormControl(c.low_value),
      low_alert_level: new FormControl(c.low_level_id),
      high_value: new FormControl(c.high_value),
      high_alert_level: new FormControl(c.high_level_id),
      too_high_value: new FormControl(c.too_high_value),
      too_high_alert_level: new FormControl(c.too_high_level_id),
    });
    this.addSensorArray.push(this.sensor_alarm_group);
  }
  sensortype: any = '';
  subtype: any[] = [];
  /**
   * select units and sensor subtypes according to sensor
   * @param event 
   */
  selectSensor(event: any) {
    var ary: any = new FormArray([]);
    this.unitList = [];
    this.addSensorArray = ary;
    this.addSensorArray.push(this.getsensordata());
    var type = this.sensorDataList.filter(m => (m.sensor_id == event))[0].sensor_type_id;
    var sensorTYdata = this.sensorTypeList.filter(m => (m.sensor_type_id == type))[0].types;
    sensorTYdata.sort((a: any, b: any) => a.sub_type_id - b.sub_type_id);
    this.sensortype = type;
    this.subtype = sensorTYdata;
    if (sensorTYdata.length <= 1) {
      var data = this.unfilteredunits.filter(n => (n.sensor_type_id == type));
      var datum = { name: '', list: data };
      this.unitList.push(datum);
    } else if (sensorTYdata.length > 1) {
      sensorTYdata.filter((d: any) => {
        var data = this.unfilteredunits.filter(n => (n.sensor_type_id == type));
        var datum = { name: d.sub_sensor_type, list: data };
        this.unitList.push(datum);
      });
      this.addSensorArray.push(this.getsensordata());
    }

  }
  /**
   * select sensor units for sensor with sub types
   * @param data 
   */
  selectunitsNDTY(data: any) {
    this.unitList = [];
    this.sensortype = data.sensor_type_id;
    var sensorTYdata = this.sensorTypeList.filter(m => (m.sensor_type_id == this.sensortype))[0].types;
    this.subtype = sensorTYdata.filter((n: any) => (n.sub_type_id == data.sub_type_id));
    var data1 = this.unfilteredunits.filter(n => (n.sensor_type_id == this.sensortype));
    var datum = { name: this.subtype[0].sub_sensor_type, list: data1 };
    this.unitList.push(datum);
  }
  /**
   * check weather submit or update according to new alarm or existing alarm
   */
  summit() {
    this.isupdate ? this.update() : this.save();
  }
  /**
   * save if its new alarm
   * @returns 
   */
  save() {
    if (this.addSensorArray.invalid) {
      return this.show_msg.errorSnackBar('no_empty_field');
    }
    var data = this.setDataList();
    this.subscriptions.push(this.SettingsService.saveSensorAlarm(data).subscribe((res: any) => {
      this.path.emit("");
    }, (error: Response) => {
      console.log(error);
    }));
  }
  /**
   * update if its exesting alarm
   * @returns 
   */
  update() {
    if (this.addSensorArray.invalid) {
      return this.show_msg.errorSnackBar('no_empty_field');
    }
    var data = this.setDataList();
    this.subscriptions.push(this.SettingsService.updateSensorAlarm(data.data[0], this.sensordata.id).subscribe((res: any) => {
      this.path.emit("");
    }, (error: Response) => {
      console.log(error);
    }));

  }
  /**
   * set data for upload or create api if sensor with multiple sensor sub type
   * @returns 
   */
  setDataList() {
    var i = 0;
    var datalist: sensorAlarm[] = [];
    this.addSensorArray.controls.filter((d: any) => {
      var data = new sensorAlarm();
      data.sensor_id = this.sensor_name_group.value.sensor_name;
      data.unit_id = d.value.Units;
      data.too_low_value = d.value.too_low_value;
      data.too_low_level_id = d.value.too_low_alert_level;
      data.low_value = d.value.low_value;
      data.low_level_id = d.value.low_alert_level;
      data.high_value = d.value.high_value;
      data.high_level_id = d.value.high_alert_level;
      data.too_high_value = d.value.too_high_value;
      data.too_high_level_id = d.value.too_high_alert_level;
      data.sub_type_id = this.subtype[i].sub_type_id;
      data.sensor_type_id = this.sensortype;
      data.battery_alert_level_id = this.sensor_name_group.value.battery;
      data.signal_strength_alert_level_id = this.sensor_name_group.value.signal_strength;
      data.tank_id = JSON.parse(localStorage.getItem('tank') || '')?.id;
      datalist.push(data);
      i++;
    });
    return { data: datalist };
  }
  /**
   * set data for upload or create api if sensor with one sensor sub type
   * @returns 
   */
  setData() {
    var data = new sensorAlarm();
    data.sensor_id = this.sensor_alarm_group.value.sensor_name;
    data.unit_id = this.sensor_alarm_group.value.Units;
    data.too_low_value = this.sensor_alarm_group.value.too_low_value;
    data.too_low_level_id = this.sensor_alarm_group.value.too_low_alert_level;
    data.low_value = this.sensor_alarm_group.value.low_value;
    data.low_level_id = this.sensor_alarm_group.value.low_alert_level;
    data.high_value = this.sensor_alarm_group.value.high_value;
    data.high_level_id = this.sensor_alarm_group.value.high_alert_level;
    data.too_high_value = this.sensor_alarm_group.value.too_high_value;
    data.too_high_level_id = this.sensor_alarm_group.value.too_high_alert_level;

    data.sub_type_id = this.sensor_alarm_group.value.too_high_value;
    data.sensor_type_id = this.sensor_alarm_group.value.too_high_alert_level;
    data.battery_alert_level_id = this.sensor_alarm_group.value.battery;
    data.signal_strength_alert_level_id = this.sensor_alarm_group.value.signal_strength;
    data.tank_id = JSON.parse(localStorage.getItem('tank') || '')?.id;
    return data;
  }
  /**
   * validator for input fields
   * @param event 
   * @param value 
   * @returns 
   */
  setValueData(event: any, value: string) {
    if (event.keyCode == 190 && value == '') {
      return false;
    } else if (event.keyCode == 190 && value.indexOf('.') > -1) {
      return false;
    }
    return event.keyCode >= 48 && event.keyCode <= 57 || event.keyCode == 190 || event.keyCode == 8 || event.keyCode == 37 || event.keyCode == 39 || event.keyCode == 9;
  }
  /**
   * clear array
   */
  clearData() {
    this.addSensorArray.reset();
    this.path.emit("");
  }
  /**
   * return controle name in looping form array
   * @param controlName 
   * @param formGroup 
   * @returns 
   */
  givevalue(controlName: string, formGroup: FormGroup) {
    return formGroup.get(controlName)?.value;
  }
  ngOnDestroy() {
    this.subscriptions.forEach((sub: Subscription) => sub.unsubscribe());
  }


}