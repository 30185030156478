import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Subscription } from 'rxjs';
import { HomeService } from 'src/app/core/services/home/home.service';
import { SettingsService } from 'src/app/core/services/settings/settings.service';
import { BulkUploadDialogComponent } from 'src/shared/bulk-upload-dialog/bulk-upload-dialog.component';
import { MessageDialogComponent } from 'src/shared/message-dialog/message-dialog.component';
@Component({
  selector: 'app-environmental-score',
  templateUrl: './environmental-score.component.html',
  styleUrls: ['./environmental-score.component.scss']
})
export class EnvironmentalScoreComponent implements OnInit {
  current_time = new Date();
  createscreen: boolean = false;
  isloaded = false;
  symbol: string = '</>/=';
  displayedColumns: string[] = ['S.NO', 'Sensor type', 'Sensor name', 'Sub type', 'Total No.of.Thresholds', 'Min Score', 'Max score', 'Action'];
  parameters: string[] = ['sign', 'value'];
  dataSource = new MatTableDataSource([]);
  public company_name = localStorage.getItem('company')?.length ? JSON.parse(localStorage.getItem('company') || '')?.name : '--';
  public shed_name = localStorage.getItem('tank')?.length ? JSON.parse(localStorage.getItem('tank') || '')?.name : '--';
  public access: any;
  public subscriptions: Subscription[] = [];
  constructor(private fb: FormBuilder, private dialog: MatDialog, private SettingsService: SettingsService,
    private home: HomeService) {
    this.access = this.home.getAccess(24);
  }

  ngOnInit(): void {
    this.getSensorType();
    this.getSensorDatas();
  }
  /**
   * change list page from table and vice-verse
   */
  createList() {
    this.createscreen = !this.createscreen;
    this.environmentalvalue = undefined;
  }
  environmentaldata: any[] = [];
  /**
   * get environmental score details service API for environmental score table
   */
  getEnvironmentscore() {
    this.subscriptions.push(this.SettingsService.getEnvironmentScore().subscribe((res: any) => {
      this.environmentaldata = res != null ? res : [];
      this.dataSource.data = this.loopTableData(this.environmentaldata) as never[];
      this.isloaded = true;
    }, (error: Response) => {
      this.isloaded = true;
      console.log(error);
    }));
  }
  tablefilteredList: any = {};
  /**
   * set table data in datasource from table data method
   * @param data 
   * @returns 
   */
  loopTableData(data: any[]) {
    const datalist: any[] = [];
    var variables = data,
      result = variables.reduce(function (r, a) {
        var sub = a.sub_type_id == null ? 0 : a.sub_type_id;
        r[a.sensor_id.toString() + '.' + sub] = r[a.sensor_id.toString() + '.' + sub] || [];
        r[a.sensor_id.toString() + '.' + sub].push(a);
        return r;
      }, Object.create(null));
    this.tablefilteredList = result;
    Object.keys(this.tablefilteredList).forEach(key => {
      var datum: any = {};
      datum['id'] = key;
      datum['sensor_type'] = this.sensorTypeList.filter(m => (m.sensor_type_id == this.tablefilteredList[key][0].sensor_type_id))[0].name;
      datum['sensor_name'] = this.sensorDataList.filter(m => (m.sensor_id == this.tablefilteredList[key][0].sensor_id))[0].name;
      var sub = this.sensorTypeList.filter(m => (m.sensor_type_id == this.tablefilteredList[key][0].sensor_type_id))[0]
        .types.filter((c: any) => (c.sub_type_id == this.tablefilteredList[key][0].sub_type_id));
      datum['sub_type'] = sub.length > 0 ? sub[0].sub_sensor_type != null ? sub[0].sub_sensor_type : '-' : '-';
      datum['totalthreshold'] = this.tablefilteredList[key]?.length;
      var list: any[] = [];
      result[key].filter((m: any) => {
        list.push(m.threshold_max_score);
      });
      datum['min_score'] = Math.min(...list);
      datum['max_score'] = Math.max(...list);
      datalist.push(datum);
    });
    return datalist;
  }
  sensorDataList: any[] = [];
  /**
   * get available sensor in shed Service API
   */
  getSensorDatas() {
    this.subscriptions.push(this.SettingsService.getSensors().subscribe((res: any) => {
      this.sensorDataList = res.data;
      this.getSensorType();
    }, (error: Response) => {
      console.log(error);
    }));
  }
  sensorTypeList: any[] = [];
  /**
   * get sensor type service API
   */
  getSensorType() {
    this.subscriptions.push(this.SettingsService.getSensorType().subscribe((res: any) => {
      this.sensorTypeList = res.data;
      this.getEnvironmentscore();
    }, (error: Response) => {
      this.isloaded = true;
      console.log(error);
    }));
  }
  environmentalvalue: any;
  /**
   * edit and view function table screen to create screen
   * @param id 
   * @param view 
   */
  editvalue(id: any, view: any) {
    // this.environmentaldata.filter((c) => {
    //   c.environmental_thresholds.filter((n: any)=>{
    //     if (n.environment_threshold_id == id) {
    //       this.environmentalvalue = n;
    //     }
    //   })
    // });
    this.environmentalvalue = this.tablefilteredList[id];
    this.environmentalvalue['screen'] = view ? 'view' : 'update';
    this.environmentalvalue['idSub'] = id;
    this.createscreen = true;
  }
  /**
   * delete particular row from table Delete Popup
   * @param id 
   * @param data 
   */
  deletevalue(id: any, data: any) {
    const config: MatDialogConfig = {
      panelClass: 'dialog-responsive',
      width: '50%',
      minWidth: '50%',
      disableClose: true,
      data: {
        title: `${data}'s Environmental Score`,
        isDelete: true
      },
    };
    const openDialog = this.dialog.open(MessageDialogComponent, config);
    this.subscriptions.push(openDialog.afterClosed().subscribe((result: any) => {
      if (result) {
        this.subscriptions.push(this.SettingsService.deleteEnvironmentScore(id).subscribe((res: any) => {
          this.getSensorDatas();
        }, (error: Response) => {
          console.log(error);
        }));
      }

    }));

  }
  /**
   * open bulk upload popup for environmental score
   */
  bulkUpload() {
    const config: MatDialogConfig = {
      panelClass: 'dialog-responsive',
      width: '50%',
      minWidth: '50%',
      disableClose: true,
      data: {
        title: ``,
        isDelete: true
      },
    };
    const openDialog = this.dialog.open(BulkUploadDialogComponent, config);
    this.subscriptions.push(openDialog.afterClosed().subscribe((result: any) => {
      if (result) {
        // this.service.postProductivityBulkUpload(result).subscribe((res: any) => {
        //   this.getProductivityScore();
        // }, (error: Response) => {
        //   console.log(error)
        // })
      }
    }));

  }
  /**
   * data from child to parent component
   * @param event 
   */
  receiveData(event: any) {
    this.createscreen = !this.createscreen;
    this.getSensorDatas();
  }
  ngOnDestroy() {
    this.subscriptions.forEach((sub: Subscription) => sub.unsubscribe());
  }
}
