import { Injectable } from '@angular/core';
import *as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root'
})
export class CryptoService {

  constructor () { }
  private encryptionKey: string = 'ysquaref4d320f5a50a84f4f6a7d5eb55f46b68cf299e5869b69a1e2c8b309a4a7e6fc';
  encrypt (data: any) {
    const plaintext = JSON.stringify(data);
    const encrypted = CryptoJS.AES.encrypt(plaintext, this.encryptionKey);
    return encrypted.toString();
  }

  decrypt (data: string) {
    const bytes = CryptoJS.AES.decrypt(data, this.encryptionKey);
    const decryptedString = bytes.toString(CryptoJS.enc.Utf8);
    return decryptedString ? JSON.parse(decryptedString) : null;
  }
}
