import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GROWTH } from 'src/app/config/api.config';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GrowthService {


  private BASE_URL = environment.BASE_URL;
  constructor(private http: HttpClient) { }


  public postGrowth(data: any): Observable<any> {
    return this.http.post(`${this.BASE_URL}${GROWTH.CREATE_GROWTH}`, data);
  }
  public updateGrowth(data: any): Observable<any> {
    return this.http.put(`${this.BASE_URL}${GROWTH.CREATE_GROWTH}`, data);
  }
  public deleteGrowth(data: Record<string, Array<number>>): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: data,
    };
    return this.http.delete(`${this.BASE_URL}${GROWTH.CREATE_GROWTH}`, options);
  }
  public postGrowthBulkUpload(data: any): Observable<any> {
    const shed_id = JSON.parse(localStorage.getItem('tank') || '')?.id;
    return this.http.post(`${this.BASE_URL}${GROWTH.BULKUPLOAD}/${shed_id}`, data);
  }

  public getGrowthScore() {
    const page = 100;
    const shed_id = JSON.parse(localStorage.getItem('tank') || '')?.id;
    return this.http.get(`${this.BASE_URL}${GROWTH.GET_GROWTH}/${shed_id}?perpage=${page}`);
  }

  public getWeeklyGrowthScore() {
    const shed_id = JSON.parse(localStorage.getItem('tank') || '')?.id;
    const batch_id = localStorage.getItem('batch_id') ? JSON.parse(localStorage.getItem('batch_id') || '') : null;
    return this.http.get(`${this.BASE_URL}${GROWTH.GROTH_SCORE}/?shed_id=${shed_id}&batch_id=${batch_id}`);
  }

}
