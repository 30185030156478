import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MessageService } from 'src/app/core/services/message/message.service';
import { SettingsService } from 'src/app/core/services/settings/settings.service';

@Component({
  selector: 'app-bulk-upload-dialog',
  templateUrl: './bulk-upload-dialog.component.html',
  styleUrls: ['./bulk-upload-dialog.component.scss']
})
export class BulkUploadDialogComponent implements OnInit {
  public next: boolean = false;
  constructor (
    public dialogRef: MatDialogRef<BulkUploadDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: any, private show_msg: MessageService, private SettingsService: SettingsService
  ) { }

  ngOnInit (): void {
    const dialogContainer: any = document.getElementsByClassName("mat-dialog-container")[0];
    if (this.dialogData?.isDelete) {
      dialogContainer.style.overflow = "initial";
    } else {
      dialogContainer.style.overflow = "auto";
    }
    this.getpixval();
  }
  cancel () {
    this.dialogRef.close();
  }

  public files: any[] = [];
  selectedFile: any;
  onFileChange (event: any) {
    if (event.target.files[0].type != 'text/csv') return this.show_msg.errorSnackBar('invalid_file_format');
    this.selectedFile = event.target.files[0];
  }
  submit () {
    if (this.selectedFile == undefined) {
      return this.show_msg.errorSnackBar('no_file_selected');
    }

    const formData = new FormData();
    formData.append('file', this.selectedFile, this.selectedFile.name);
    this.dialogRef.close(formData);
  }
  width = '0px';
  getpixval () {
    var child: any = document.getElementById("dropzoneid");
    var childBoundRect = child?.getBoundingClientRect();
    var value = childBoundRect.width + 'px';
    this.width = value;
  }
  remove () {
    this.selectedFile = undefined;
  }
  download (type: string) {
    let url: string = '';
    let file_name: string = '';
    switch (true) {
      case type == 'template':
        url = `assets/Csvfiles/${this.dialogData?.sop_name}_template.csv`;
        file_name = `${this.dialogData?.sop_name}_template.csv`;
        break;
      case type == 'input_file':
        url = `assets/Csvfiles/${this.dialogData?.sop_name}_input_file.csv`;
        file_name = `${this.dialogData?.sop_name}_input_file.csv`;
        break;
      case type == 'user_manual':
        return;
      // url = `assets/Csvfiles/${this.dialogData?.template}_input_file.csv`;
      // file_name = `${this.dialogData?.template}_input_file.csv`;
      // break;

      default:
        break;
    }

    if (this.dialogData?.sop_name) {
      let link = document.createElement('a');
      link.setAttribute('type', 'hidden');
      link.href = url;
      link.download = file_name;
      document.body.appendChild(link);
      link.click();
      link.remove();
    } else {
      return this.show_msg.errorSnackBar('no_template_available');
    }
  }
}
