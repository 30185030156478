<div>
  <div fxFlex="100" fxLayout="column" class="per1pad full-div" fxLayoutGap="10">
    <!-- Blue header with create, list and sync button  with sync indicator  with company details -->
    <mat-card style="
        background-color: #166da6;
        color: white;
        height: 4rem;
        overflow: hidden;
      " fxLayout="row" fxLayoutAlign="space-between center">
      <div fxLayout="column">
        <span style="
            font-size: 13px;
            color: white; 
            font-weight: bold;
            line-height: 1;
          ">{{ shed_name }}</span>
        <span style="
            line-height: 1;
            margin-top: 7px;
            font-size: 10px;
            color: #ffffff;
          ">{{company_name }}</span>
      </div>
      <div fxLayoutAlign="center center" fxLayout="row" fxLayoutGap="10">
        <!-- <button *ngIf="!createscreen && access?.create && !access?.view" mat-stroked-button class="add-button grow hide"
          fxLayout="row" (click)="createList()">
          <mat-icon class="add-icon">add_circle</mat-icon>
          <span>CREATE</span>
        </button> -->
        <!-- <button *ngIf="!createscreen && access?.create && !access?.view" mat-stroked-button class="add-button grow show"
          style="
            min-width: 0;
            width: 34px;
            justify-content: center;
            align-items: center;
          " (click)="createList()">
          <mat-icon class="listicon" svgIcon="plus" style="height: 22px; width: 22px"></mat-icon>
        </button> -->
        <!-- <button *ngIf="createscreen && access?.create && !access?.view" mat-stroked-button class="add-button grow"
          fxLayout="row" (click)="createList()">
          <mat-icon svgIcon="list" style="margin-right: 6px; height: 22px; width: 22px"></mat-icon>
          <span>List</span>
        </button> -->
      </div>
    </mat-card>

    <!-- create sensor alarm screen -->
    <div fxFlex="100" fxLayout="column" fxLayout.lt-sm="column" fxLayoutGap="10">
      <mat-card class="mat-elevation-z6" fxLayout="column" fxLayoutGap="30px" fxLayoutAlign="space-between start"
        style="overflow: inherit !important">
        <div fxFlex="70" fxlayoutgap="12" fxLayout="column" style="width: 100%; padding: 2%; align-items: center"
          class="formdata">
          <!-- form group for alert levels and units with single or dual sensor type(temprature and humidity) -->
          <form [formGroup]="score_alert_form" fxFlex="30" fxlayoutgap="20" style="width: 80%" class="widthM"
            fxLayout="column">
            <!-- select sensor unit -->
            <div fxLayout="row" style="width: 100%">
              <div fxFlex="50" fxlayoutgap="20" fxLayout="column" style="justify-content: center" class="maxwidthM">
                <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="start center"
                  fxLayoutAlign.lt-sm="start start" style="margin-bottom: 20px">
                  <div mat-card-title class="labelSA" fxFlex="36">
                    {{'sop_alarm.breed_type'|translate|titlecase}}
                  </div>
                  <div fxFlex="57" fxLayoutGap="20" fxLayout="row" class="widthM">
                    <div style="font-size: 12px" fxFlex="63" class="sensoralarmField maxwidthM">
                      <mat-form-field appearance="outline">
                        <mat-label>{{'sop_alarm.breed_type'|translate|titlecase}}</mat-label>
                        <input [disabled]="true" matInput type="text" [(ngModel)]="shed.breedType"
                          [ngModelOptions]="{ standalone: true }" [readonly]="true" />
                      </mat-form-field>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div fxLayout="row" fxLayout.lt-sm="column" style="width: 100%" fxLayoutGap="20">
              <div fxFlex="50" fxLayout="column">
                <!-- too low section -->
                <!-- onkeypress="return (event.charCode >= 48 && event.charCode <= 57 )|| event.charCode ==46 "  -->
                <div fxFlex="30" fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="start center"
                  fxLayoutAlign.lt-sm="start start">
                  <div mat-card-title class="labelSA" fxFlex="38">{{'sop_alarm.too_low'|translate|titlecase}}</div>
                  <div fxFlex="63" fxLayoutGap="20" fxLayout="row" class="widthM">
                    <!-- set the lowest value for the alarm -->
                    <div style="font-size: 12px" fxFlex="63" class="sensoralarmField maxwidthM">
                      <mat-form-field appearance="outline">
                        <mat-label>{{'sop_alarm.value'|translate|titlecase}}</mat-label>
                        <input [readonly]="haveData" matInput (keydown.arrowup)="$event.preventDefault()"
                          (keydown.arrowdown)="$event.preventDefault()" (wheel)="$event.preventDefault()"
                          (keydown)="$event.key == 'e' || $event.key == 'E' || $event.key == '+' ? $event.preventDefault() : ''"
                          pattern="^(?!.*-.*-)(-?(?:\d+\.\d{0,3}|\d+|\.\d{1,3})?)?$"
                          oninput="if(!/^(-?(?:\d+\.\d{0,3}|\d+|\.\d{1,3})?)?$/.test(this.value)) this.value = this.value.slice(0, -1)"
                          type="text" formControlName="too_low_value" />




                      </mat-form-field>
                    </div>
                  </div>
                </div>
                <!-- set alarm level for too low -->
                <div fxFlex="30" fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="start center"
                  fxLayoutAlign.lt-sm="start start">
                  <div fxFlex="37" class="labledata"></div>
                  <div fxFlex="63" fxLayoutGap="20" fxLayout="row" class="widthM">
                    <div style="font-size: 12px" fxFlex="63" class="sensoralarmField maxwidthM">
                      <mat-form-field appearance="outline">
                        <mat-label>{{'sop_alarm.select_level'|translate|titlecase}}</mat-label>
                        <mat-select [disabled]="haveData" formControlName="too_low_level_id" panelClass="PanelClass">
                          <ng-container *ngFor="let unit of levelList">
                            <mat-option *ngIf="unit.level_name != '0'"
                              [value]="unit.level_id">{{'sop_alarm.level'|translate|titlecase}}
                              {{ unit.level_name }}
                            </mat-option>
                            <mat-option *ngIf="unit.level_name == '0'"
                              [value]="unit.level_id">{{'sop_alarm.no_alerts'|translate|titlecase}}
                            </mat-option>
                          </ng-container>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </div>
                </div>
                <!-- low section  -->
                <div fxFlex="30" fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="start center"
                  fxLayoutAlign.lt-sm="start start">
                  <div mat-card-title class="labelSA" fxFlex="38">{{'sop_alarm.low'|translate|titlecase}}</div>
                  <div fxFlex="63" fxLayoutGap="20" fxLayout="row" class="widthM">
                    <!-- set the low value for the alarm -->
                    <div style="font-size: 12px" fxFlex="63" class="sensoralarmField maxwidthM">
                      <mat-form-field disa appearance="outline">
                        <mat-label>{{'sop_alarm.value'|translate|titlecase}}</mat-label>
                        <!-- <input [readonly]="haveData" matInput type="text" formControlName="low_value"
                          onkeypress="return (event.charCode >= 48 && event.charCode <= 57 )|| event.charCode ==46 " 
                          pattern="^(?!.*-.*-)(-?(?:\d+\.\d{0,3}|\d+|\.\d{1,3})?)?$"
                          oninput="if(!/^(-?(?:\d+\.\d{0,3}|\d+|\.\d{1,3})?)?$/.test(this.value)) this.value = this.value.slice(0, -1)"
                          type="text"/> -->

                          <input [readonly]="haveData" matInput (keydown.arrowup)="$event.preventDefault()"
                          (keydown.arrowdown)="$event.preventDefault()" (wheel)="$event.preventDefault()"
                          (keydown)="$event.key == 'e' || $event.key == 'E' || $event.key == '+' ? $event.preventDefault() : ''"
                          pattern="^(?!.*-.*-)(-?(?:\d+\.\d{0,3}|\d+|\.\d{1,3})?)?$"
                          oninput="if(!/^(-?(?:\d+\.\d{0,3}|\d+|\.\d{1,3})?)?$/.test(this.value)) this.value = this.value.slice(0, -1)"
                          type="text" formControlName="low_value" />
                      </mat-form-field>
                    </div>
                  </div>
                </div>
                <!-- set alarm level for low -->
                <div fxFlex="30" fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="start center"
                  fxLayoutAlign.lt-sm="start start">
                  <div fxFlex="37" class="labledata"></div>
                  <div fxFlex="63" fxLayoutGap="20" fxLayout="row" class="widthM">
                    <div style="font-size: 12px" fxFlex="63" class="sensoralarmField maxwidthM">
                      <mat-form-field appearance="outline">
                        <mat-label>{{'sop_alarm.select_level'|translate|titlecase}}</mat-label>
                        <mat-select [disabled]="haveData" formControlName="low_level_id" panelClass="PanelClass">
                          <ng-container *ngFor="let unit of levelList">
                            <mat-option *ngIf="unit.level_name != '0'"
                              [value]="unit.level_id">{{'sop_alarm.level'|translate|titlecase}}
                              {{ unit.level_name }}
                            </mat-option>
                            <mat-option *ngIf="unit.level_name == '0'"
                              [value]="unit.level_id">{{'sop_alarm.no_alerts'|translate|titlecase}}
                            </mat-option>
                          </ng-container>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </div>
                </div>
              </div>
              <mat-divider [vertical]="true" class="hide"></mat-divider>
              <!-- high section -->
              <div fxFlex="50" fxLayout="column" style="padding-left: 2%">
                <div fxFlex="30" fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="start center"
                  fxLayoutAlign.lt-sm="start start">
                  <div mat-card-title class="labelSA" fxFlex="38">{{'sop_alarm.high'|translate|titlecase}}</div>
                  <div fxFlex="63" fxLayoutGap="20" fxLayout="row" class="widthM">
                    <div style="font-size: 12px" fxFlex="63" class="sensoralarmField maxwidthM">
                      <!-- set the lhigh value for the alarm -->
                      <mat-form-field appearance="outline">
                        <mat-label>{{'sop_alarm.value'|translate|titlecase}}</mat-label>
                        <input [readonly]="haveData" matInput type="text" formControlName="high_value"
                          onkeypress="return (event.charCode >= 48 && event.charCode <= 57 )|| event.charCode ==46 " />
                      </mat-form-field>
                    </div>
                  </div>
                </div>
                <!-- set alarm level for high -->
                <div fxFlex="30" fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="start center"
                  fxLayoutAlign.lt-sm="start start">
                  <div fxFlex="37" class="labledata"></div>
                  <div fxFlex="63" fxLayoutGap="20" fxLayout="row" class="widthM">
                    <div style="font-size: 12px" fxFlex="63" class="sensoralarmField maxwidthM">
                      <mat-form-field appearance="outline">
                        <mat-label>{{'sop_alarm.select_level'|translate|titlecase}}</mat-label>
                        <mat-select [disabled]="haveData" formControlName="high_level_id" panelClass="PanelClass">
                          <ng-container *ngFor="let unit of levelList">
                            <mat-option *ngIf="unit.level_name != '0'"
                              [value]="unit.level_id">{{'sop_alarm.level'|translate|titlecase}}
                              {{ unit.level_name }}
                            </mat-option>
                            <mat-option *ngIf="unit.level_name == '0'"
                              [value]="unit.level_id">{{'sop_alarm.no_alerts'|translate|titlecase}}
                            </mat-option>
                          </ng-container>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </div>
                </div>
                <!-- too high section -->
                <div fxFlex="30" fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="start center"
                  fxLayoutAlign.lt-sm="start start">
                  <div mat-card-title class="labelSA" fxFlex="38">{{'sop_alarm.too_high'|translate|titlecase}}</div>
                  <div fxFlex="63" fxLayoutGap="20" fxLayout="row" class="widthM">
                    <!-- set the highest value for the alarm -->
                    <div style="font-size: 12px" fxFlex="63" class="sensoralarmField maxwidthM">
                      <mat-form-field appearance="outline">
                        <mat-label>{{'sop_alarm.value'|translate|titlecase}}</mat-label>
                        <input [readonly]="haveData" matInput type="text" formControlName="too_high_value"
                          onkeypress="return (event.charCode >= 48 && event.charCode <= 57 )|| event.charCode ==46 " />
                      </mat-form-field>
                    </div>
                  </div>
                </div>
                <!-- set alarm level for too high -->
                <div fxFlex="30" fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="start center"
                  fxLayoutAlign.lt-sm="start start">
                  <div fxFlex="37" class="labledata"></div>
                  <div fxFlex="63" fxLayoutGap="20" fxLayout="row" class="widthM">
                    <div style="font-size: 12px" fxFlex="63" class="sensoralarmField maxwidthM">
                      <mat-form-field appearance="outline">
                        <mat-label>{{'sop_alarm.select_level'|translate|titlecase}}</mat-label>
                        <mat-select [disabled]="haveData" formControlName="too_high_level_id" panelClass="PanelClass">
                          <ng-container *ngFor="let unit of levelList">
                            <mat-option *ngIf="unit.level_name != '0'"
                              [value]="unit.level_id">{{'sop_alarm.level'|translate|titlecase}}
                              {{ unit.level_name }}
                            </mat-option>
                            <mat-option *ngIf="unit.level_name == '0'"
                              [value]="unit.level_id">{{'sop_alarm.no_alerts'|translate|titlecase}}
                            </mat-option>
                          </ng-container>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
        <!-- submit button -->
        <!-- while create flow its submit button if its update flow it will show update button 
              and if its view flow its didnt show any submit or update button it only shows back button  -->
        <div fxLayout="row" fxFlex="25" style="width: 100%" fxLayoutAlign="center end">
          <div fxFlex="100" *ngIf="haveData" fxLayoutAlign="center center" fxLayout.lt-sm="column" fxLayoutGap="25"
            fxLayoutAlign.lt-sm="center center" class="buttondiv" style="width: 100%; max-height: 6vh">
            <button class="submit grow changeview" mat-raised-button style="width: 14%; box-shadow: none"
              (click)="haveData = false; editMode = true; enableFields()">
              {{'button.edit'|translate|uppercase}}
            </button>
          </div>

          <div fxFlex="100" *ngIf="!haveData" fxLayoutAlign="center center" fxLayout.lt-sm="column" fxLayoutGap="25"
            fxLayoutAlign.lt-sm="center center" class="buttondiv" style="width: 100%; max-height: 6vh">
            <button class="submit grow changeview" mat-raised-button style="width: 14%; box-shadow: none"
              (click)="summit()">
              {{ editMode ? ('button.update'|translate|uppercase) : 'button.submit'|translate|uppercase }}
            </button>
            <button class="cancel grow changeview" mat-raised-button style="width: 14%; box-shadow: none"
              (click)="clearData()">
              {{'button.cancel'|translate|uppercase}}
            </button>
          </div>
        </div>
      </mat-card>
    </div>
  </div>
</div>