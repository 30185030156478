<mat-card style="height:100%;overflow: auto;" class="card-container" fxLayout="column">

    <div *ngIf="!isCreateUser && !is_edit_user" style="height: 100%;">

        <!---------------------------Show message if user list is empty---------------------------------->

        <div *ngIf="!dataSource?.data?.length">
            <app-common-message [message]="'user_tab.user_empty_msg'|translate |titlecase">
            </app-common-message>
        </div>

        <!------------------------------------------------------------------------------------------->

        <!------------------------------------------user table--------------------------------------->
        <div fxLayout="column" fxFlex="100" *ngIf="this.dataSource?.data?.length">
            <div style="max-height: 85%;overflow: auto;" *ngIf="this.dataSource?.data?.length">

                <table class="level-one-table" mat-table [dataSource]="dataSource">

                    <!-- S.No Column -->
                    <ng-container matColumnDef="S.No">
                        <th mat-header-cell *matHeaderCellDef>{{'user_tab.table_header.serial_no'|translate |titlecase}}
                        </th>
                        <td mat-cell *matCellDef="let element;let i = index"> {{ pageIndex * pageSize + ( i+1)}} </td>
                    </ng-container>

                    <!-- Name Column -->
                    <ng-container matColumnDef="Name">
                        <th mat-header-cell *matHeaderCellDef>{{'user_tab.table_header.name'|translate |titlecase}}
                        </th>
                        <td mat-cell *matCellDef="let element"><span (click)="viewUser(element)"
                                class="user-name">{{element.user_name}}</span>
                        </td>
                    </ng-container>
                    <!-- Login Name Column -->
                    <ng-container matColumnDef="Login Name">
                        <th mat-header-cell *matHeaderCellDef>
                            {{'user_tab.table_header.login_name'|translate |titlecase}}
                        </th>
                        <td mat-cell *matCellDef="let element">{{element.user_login_name}} </td>
                    </ng-container>

                    <!-- User Role Column -->
                    <ng-container matColumnDef="User Role">
                        <th mat-header-cell *matHeaderCellDef>{{'user_tab.table_header.user_role'|translate |titlecase}}
                        </th>
                        <td mat-cell *matCellDef="let element"> {{"user_tab."+element.role_name |translate |titlecase}} </td>
                    </ng-container>


                    <!-- Company Column -->
                    <ng-container matColumnDef="Company">
                        <th mat-header-cell *matHeaderCellDef
                            [ngStyle]="this.user_data?.user_role !== 1 ? {'display':'none'} :{}">
                            {{'user_tab.table_header.company'|translate |titlecase}}
                        </th>
                        <td mat-cell *matCellDef="let element"
                            [ngStyle]="this.user_data?.user_role !== 1 ? {'display':'none'}:{}">
                            {{element.company_name}}
                        </td>
                    </ng-container>

                    <!-- Email Column -->
                    <ng-container matColumnDef="Email">
                        <th mat-header-cell *matHeaderCellDef> {{'user_tab.table_header.email'|translate |titlecase}}
                        </th>
                        <td mat-cell *matCellDef="let element"> {{element.user_email}} </td>
                    </ng-container>
                    <!-- MobileColumn -->
                    <ng-container matColumnDef="Mobile">
                        <th mat-header-cell *matHeaderCellDef>{{'user_tab.table_header.mobile'|translate |titlecase}}
                        </th>
                        <td mat-cell *matCellDef="let element"> {{element.mobile_no}} </td>
                    </ng-container>

                    <!-- Action Column -->
                    <ng-container matColumnDef="Action">
                        <th mat-header-cell *matHeaderCellDef>{{'user_tab.table_header.action'|translate |titlecase}}
                        </th>
                        <td mat-cell *matCellDef="let element">
                            <div style="display: flex;gap: 30%;">
                                <mat-icon *ngIf="access?.edit && !access?.view" class="grow" style="width: 1.2rem;"
                                    svgIcon="Edit" (click)="editUser(element)">
                                </mat-icon>
                                <mat-icon *ngIf="access?.delete && !access?.view" class="grow" style="width: 1rem;"
                                    svgIcon="Delete" (click)="deleteUser(element)">
                                </mat-icon>
                            </div>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky: true"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>
            </div>

            <div style="max-height: 15%;"
                [ngStyle]="!!this.dataSource.data.length && (!isCreateUser && !is_edit_user) ? {'visibility': 'initial'}:{'visibility': 'hidden'}">
                <mat-paginator #paginator (page)="nextPage($event)" [length]="length" [pageSize]="pageSize"
                    [disabled]="disabled" [showFirstLastButtons]="showFirstLastButtons"
                    [pageSizeOptions]="showPageSizeOptions ? pageSizeOptions : []" [hidePageSize]="hidePageSize"
                    [pageIndex]="pageIndex">
                </mat-paginator>
            </div>
        </div>


        <!------------------------------------------------------------------------------------------->


    </div>


    <!------------------------------------------user creation form page------------------------------>

    <div fxLayout="column" *ngIf="isCreateUser || is_edit_user">

        <div style="margin: 5px 0px 0px 10px;" class="label">{{view_only ? 'User Details'
            :(is_edit_user ? 'user_tab.update_user_details':'user_tab.fill_user_details') | translate | titlecase}}
        </div>
        <!-- <div fxFlex="100"> -->
        <form [ngStyle]="view_only ? {'pointer-events': 'none'} : {'pointer-events': 'initial'}" fxFlex="100"
            [formGroup]="user_form_group" style="margin: 10px;">
            <div fxLayout.gt-sm="row" fxLayout.sm="row" fxLayout.lt-sm="column" fxLayoutGap.lt-sm="10"
                fxLayoutAlign.gt-sm="space-between start" fxLayoutAlign.sm="space-between start">

                <div fxFlex="33" fxLayout="column" fxLayoutGap.lt-sm="5" fxLayoutGap="15">

                    <div>
                        <mat-form-field appearance="outline">
                            <mat-label>{{'user_tab.user_name' | translate | titlecase}}</mat-label>
                            <input  oninput="this.value = this.value.trimStart().replace(/\s+/g, ' ')" matInput
                                formControlName="user_name" type="text" >
                            <mat-error>
                                <span
                                    *ngIf="user_form_group.controls['user_name'].touched  && user_form_group.get('user_name')?.hasError('required')">
                                    {{'user_tab.user_name_msg' | translate | titlecase}}
                                </span>
                            </mat-error>
                        </mat-form-field>

                    </div>

                    <div>
                        <mat-form-field appearance="outline">
                            <mat-label>{{'user_tab.user_role' | translate | titlecase}}</mat-label>
                            <mat-select disableOptionCentering [disabled]="is_edit_user" panelClass="PanelClass"
                                (valueChange)="setRemoveValidator($event)" formControlName="role_id"
                                autocomplete="new-password">
                                <ng-container *ngFor="let role of user_role">
                                    <mat-option
                                        [value]="role.role_id">{{'user_tab.'+role.role_name |translate |titlecase}}
                                    </mat-option>
                                </ng-container>
                            </mat-select>
                            <mat-error>
                                <span
                                    *ngIf="user_form_group.controls['role_id'].touched  && user_form_group.get('role_id')?.hasError('required')">
                                    {{'user_tab.user_role_msg' | translate | titlecase}}
                                </span>
                            </mat-error>
                        </mat-form-field>

                    </div>

                    <div *ngIf="user_data?.user_role ==1">
                        <mat-form-field appearance="outline">
                            <mat-label>{{'user_tab.company' | translate | titlecase}}</mat-label>
                            <mat-select [disabled]="is_edit_user" disableOptionCentering formControlName="company_id"
                                panelClass="PanelClass" autocomplete="new-password">
                                <ng-container *ngFor="let company of company_list">
                                    <mat-option [value]="company.company_id">{{company.company_name |titlecase}}
                                    </mat-option>
                                </ng-container>
                            </mat-select>
                            <mat-error>
                                <span
                                    *ngIf="user_form_group.controls['company_id'].touched  && user_form_group.get('company_id')?.hasError('required')">
                                    {{'user_tab.company_msg' | translate | titlecase}}
                                </span>
                            </mat-error>
                        </mat-form-field>

                    </div>

                    <div>
                        <mat-form-field appearance="outline">
                            <mat-label>{{'user_tab.email' | translate | titlecase}}</mat-label>
                            <input  matInput formControlName="user_email" type="text" pattern="^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$"
                                oninput="this.value = this.value.trimStart().replace(/[^\x00-\x7F]/g,'').trim('')"
                                autocomplete="new-password">
                            <mat-error>
                                <span
                                    *ngIf="user_form_group.controls['user_email'].touched  && user_form_group.get('user_email')?.hasError('required')">
                                    {{'user_tab.email_msg' | translate | titlecase}}
                                </span>
                                <span
                                    *ngIf="user_form_group.controls['user_email'].touched  && user_form_group.get('user_email')?.hasError('pattern')">
                                    {{'user_tab.email_msg_invalid' | translate | titlecase}}
                                </span>
                            </mat-error>
                        </mat-form-field>

                    </div>

                    <div fxLayout="row" fxLayoutGap="3">
                        <mat-form-field (click)="mobileDialCode.click()" fxFlex="32" appearance="outline">
                            <mat-label>{{'user_tab.code' | translate | titlecase}}</mat-label>
                            <mat-select (blur)="dialCodeFocusOut('Mobile')" formControlName="dial_code_mobile_no"
                                panelClass="PanelClass" autocomplete="new-password">
                                <mat-select-trigger>
                                    {{user_form_group.get('dial_code_mobile_no')?.value}}
                                </mat-select-trigger>

                                <div class="dial-code-filter">
                                    <mat-form-field floatLabel="never">
                                        <input #mobileDialCode matInput [formControl]="mobile_dial_code_filter"
                                            type="text" autocomplete="new-password"
                                            [placeholder]="('search_here'|translate|titlecase)+'...'"
                                            (keydown)="$event.stopPropagation()">
                                    </mat-form-field>
                                </div>

                                <ng-container *ngFor="let country of mobileDialcodeObservable | async">
                                    <mat-option [value]="country.dial_code">{{country.country_name
                                        +' '+ country.dial_code|titlecase}}
                                    </mat-option>
                                </ng-container>
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field fxFlex="68" appearance="outline">
                            <mat-label>{{'user_tab.mobile_number' | translate | titlecase}}</mat-label>
                            <input [maxLength]="15" matInput formControlName="mobile" type="number"
                                (keydown)="$event.key =='e' || $event.key =='E' ||$event.key =='-' ||$event.key == '.'? $event.preventDefault():''"
                                oninput="this.value=this.value.replace(/[^0-9.]/g,'');javascript: if (this.value.length >15) this.value = this.value.slice(0,15);"
                                autocomplete="new-password">
                            <mat-error>
                                <span
                                    *ngIf="user_form_group.controls['dial_code_mobile_no'].touched  && user_form_group.get('dial_code_mobile_no')?.hasError('required')">
                                    {{'user_tab.code_msg' | translate | titlecase}}
                                </span>
                                <span
                                    *ngIf="user_form_group.controls['mobile'].touched  && user_form_group.get('mobile')?.hasError('required') && !user_form_group.get('dial_code_mobile_no')?.hasError('required')">
                                    {{'user_tab.mobile_msg' | translate | titlecase}}
                                </span>
                                <!-- <span
                                    *ngIf="user_form_group.controls['mobile'].touched  && user_form_group.get('mobile')?.hasError('minlength') && !user_form_group.get('dial_code_mobile_no')?.hasError('required')">
                                    {{'user_tab.mobile_num_msg' | translate | titlecase}}
                                </span> -->
                                
                            </mat-error>
                        </mat-form-field>

                    </div>


                </div>

                <div fxHide fxShow.gt-sm
                    style="border-left: 1.5px solid #BCBCBC;height: 18.5rem;position: relative;top: 0.5rem;"></div>
                <div fxFlex="33" fxLayout="column" fxLayoutGap.lt-sm="5" fxLayoutGap="15">

                    <div fxLayout="row" fxLayoutGap="3">
                        <mat-form-field (click)="whatsAppDialCode.click()" fxFlex="32" appearance="outline">
                            <mat-label>{{'user_tab.code' | translate | titlecase}}</mat-label>
                            <mat-select (blur)="dialCodeFocusOut('WhatsApp')" formControlName="dial_code_whatsapp_no"
                                panelClass="PanelClass" autocomplete="new-password">
                                <mat-select-trigger>
                                    {{user_form_group.get('dial_code_whatsapp_no')?.value}}
                                </mat-select-trigger>

                                <div class="dial-code-filter">
                                    <mat-form-field floatLabel="never">
                                        <input #whatsAppDialCode matInput [formControl]="whatsapp_dial_code_filter"
                                            type="text" autocomplete="new-password"
                                            [placeholder]="('search_here'|translate|titlecase)+'...'"
                                            (keydown)="$event.stopPropagation()">
                                    </mat-form-field>
                                </div>
                                <ng-container *ngFor="let country of whatsappDialcodeObservable | async">
                                    <mat-option [value]="country.dial_code">{{country.country_name
                                        +' '+ country.dial_code|titlecase}}
                                    </mat-option>
                                </ng-container>
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field fxFlex="68" appearance="outline">
                            <mat-label>{{'user_tab.whatsapp_number' | translate | titlecase}}</mat-label>
                            <input [maxLength]="15" matInput formControlName="whatsapp" type="number"
                                (keydown)="$event.key =='e' || $event.key =='E' ||$event.key =='-' ||$event.key == '.'? $event.preventDefault():''"
                                oninput="this.value=this.value.replace(/[^0-9.]/g,'');javascript: if (this.value.length >15) this.value = this.value.slice(0,15);"
                                autocomplete="new-password">
                            <mat-error>
                                <span
                                    *ngIf="user_form_group.controls['dial_code_whatsapp_no'].touched  && user_form_group.get('dial_code_whatsapp_no')?.hasError('required')">
                                    {{'user_tab.code_msg' | translate | titlecase}}
                                </span>
                                <span
                                    *ngIf="user_form_group.controls['whatsapp'].touched  && user_form_group.get('whatsapp')?.hasError('required') && !user_form_group.get('dial_code_whatsapp_no')?.hasError('required')">
                                    {{'user_tab.whatsapp_msg' | translate | titlecase}}
                                </span>
                            </mat-error>
                        </mat-form-field>

                    </div>



                    <div>
                        <mat-form-field appearance="outline">
                            <mat-label>{{'user_tab.login_name' | translate | titlecase}}</mat-label>
                            <input (ngModelChange)="is_login_name_changed = true" (focusout)="isLoginNameExist()"
                                matInput [maxLength]="25"
                                oninput="this.value = this.value.trimStart().replace(/[^a-zA-Z0-9#!~\-_.]/g, '').replace(/\s+/g, ' ');"
                                formControlName="user_login_name" type="text" autocomplete="new-password">
                            <mat-error>
                                <span
                                    *ngIf="user_form_group.controls['user_login_name'].touched  && user_form_group.get('user_login_name')?.hasError('required')">
                                    {{'user_tab.login_name_msg' | translate | titlecase}}
                                </span>
                                <span
                                    *ngIf="user_form_group.controls['user_login_name'].touched  && user_form_group.get('user_login_name')?.hasError('alreadyExist')">
                                    {{'user_tab.login_name_already_exist' | translate | titlecase}}
                                </span>
                            </mat-error>
                        </mat-form-field>

                    </div>

                    <div>
                        <mat-form-field *ngIf="!is_edit_user && !view_only" appearance="outline">
                            <mat-label>{{'user_tab.password' | translate | titlecase}}</mat-label>
                            <input matInput (ngModelChange)="hidePassword('password');passwordMatchValidator()" [type]="hide ? 'password' 
                            : 'text'" formControlName="password"
                                oninput="this.value = this.value.trimStart().replace(/\s+/g, ' ')"
                                autocomplete="new-password">
                            <mat-icon style="color: #BCBCBC;" matSuffix (click)="showHidePassword('password')"
                                [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">{{hide ?
                                'visibility_off' : 'visibility'}}</mat-icon>
                            <mat-error>
                                <span
                                    *ngIf="user_form_group.controls['password'].touched  && user_form_group.get('password')?.hasError('pattern')">
                                    {{'user_tab.password_format_msg' | translate | titlecase}}</span>
                                <span
                                    *ngIf="user_form_group.controls['password'].touched  && user_form_group.get('password')?.hasError('required')">
                                    {{'user_tab.password_msg' | translate | titlecase}}</span>
                            </mat-error>
                        </mat-form-field>

                    </div>

                    <div>
                        <mat-form-field *ngIf="!is_edit_user && !view_only" appearance="outline">
                            <mat-label>{{'user_tab.confirm_password' | translate | titlecase}}</mat-label>
                            <input (focusin)="onFocusConfirmPassword()" (focusout)="onFocusOutConfirmPassword()"
                                [readonly]="this.user_form_group.controls['password'].value?.length < 8" matInput
                                (ngModelChange)="hidePassword('confirm_password');passwordMatchValidator()" [type]="hide1 ? 'password' 
                            : 'text'" formControlName="confirm_password"
                                oninput="this.value = this.value.trimStart().replace(/\s+/g, ' ')"
                                autocomplete="new-password">
                            <mat-icon style="color: #BCBCBC;" matSuffix (click)="showHidePassword('confirm_password')"
                                [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide1">{{hide1 ?
                                'visibility_off' : 'visibility'}}</mat-icon>

                            <mat-error>
                                <span
                                    *ngIf="user_form_group.controls['confirm_password'].touched  && user_form_group.get('confirm_password')?.hasError('pattern')">
                                    {{'user_tab.password_format_msg'|translate|titlecase}}</span>
                                <span *ngIf="!!this.user_form_group.controls['password'].value?.length && user_form_group.controls['confirm_password'].touched  
                                    && user_form_group.get('confirm_password')?.hasError('required')">
                                    {{'user_tab.confirm_password_msg' | translate | titlecase}}</span>
                                <span
                                    *ngIf="!!!this.user_form_group.controls['password'].value?.length  && user_form_group.get('confirm_password')?.hasError('required')">
                                    {{'user_tab.password_first_msg'|translate |titlecase}}</span>
                                <span
                                    *ngIf="user_form_group.controls['confirm_password']?.hasError('mismatch')  && !user_form_group.get('confirm_password')?.hasError('required')
                                        && !user_form_group.controls['confirm_password']?.hasError('pattern') && user_form_group.get('password')?.value?.length && (user_form_group.get('confirm_password')?.value?.length > 5 ||focus_out_confirm_password)">
                                    {{'user_tab.password_mismatch'|translate|titlecase}}
                                </span>
                            </mat-error>
                        </mat-form-field>

                    </div>



                </div>
                <div fxHide fxShow.gt-sm
                    style="border-left: 1.5px solid #BCBCBC;height: 18.5rem;position: relative;top: 0.5rem;"></div>
                <div fxFlex="20" fxLayout="column" fxLayoutGap.lt-sm="30" fxLayoutGap="30">
                    <div fxLayout="row" fxLayoutAlign="space-between center">
                        <span class="field">{{'user_tab.company_wide_alert' | translate | titlecase}}</span>
                        <mat-slide-toggle (change)="companyWideChange();showCompanyWideAlertStatus()"
                            formControlName="company_wide_alert" color="primary"></mat-slide-toggle>
                    </div>
                    <div>
                        <mat-form-field appearance="outline">
                            <mat-label>{{'user_tab.alert_level' | translate | titlecase}}</mat-label>
                            <mat-select (focusin)="focusInAlerts()" disableOptionCentering formControlName="alert_level"
                                panelClass="PanelClass" autocomplete="new-password">
                                <ng-container *ngFor="let alert of alert_levels">
                                    <mat-option [value]="alert.id">{{alert.name
                                            |titlecase}}
                                    </mat-option>
                                </ng-container>
                            </mat-select>
                            <mat-error>
                                <span *ngIf="user_form_group.controls['alert_level'].touched  
                                        && user_form_group.get('alert_level')?.hasError('required')">
                                    Alert Level is required
                                </span>

                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div fxLayout="row" fxLayoutAlign="space-between center">
                        <span class="field">{{'user_tab.email_alert' | translate | titlecase}}</span>
                        <mat-slide-toggle (click)="focusInAlerts()" formControlName="email_alert"
                            color="primary"></mat-slide-toggle>
                    </div>
                    <div fxLayout="row" fxLayoutAlign="space-between center">
                        <span class="field">{{'user_tab.ring_alert' | translate | titlecase}}</span>
                        <mat-slide-toggle (click)="focusInAlerts()" formControlName="ring_alert"
                            color="primary"></mat-slide-toggle>
                    </div>

                    <div fxLayout="row" fxLayoutAlign="space-between center">
                        <div>
                            <span class="field">{{'user_tab.sms_alert' | translate | titlecase}}</span>
                        </div>
                        <mat-slide-toggle (click)="focusInAlerts()" formControlName="sms_alert"
                            color="primary"></mat-slide-toggle>
                    </div>
                    <div fxLayout="row" fxLayoutAlign="space-between center">
                        <div>
                            <span class="field">{{'user_tab.whatsapp_alert' | translate | titlecase}}</span>
                        </div>
                        <mat-slide-toggle (click)="focusInAlerts()" formControlName="whatsapp_alert"
                            color="primary"></mat-slide-toggle>
                    </div>
                </div>
            </div>

        </form>
        <!-- </div> -->


        <div *ngIf="!view_only" style="margin: 20px 0px;" fxLayout="row" fxLayoutAlign="center center"
            fxLayoutGap.lt-sm="25" fxLayoutGap="50">
            <button class="submit grow" mat-stroked-button (click)="submit();$event.stopPropagation()">{{
               ( is_edit_user ? 'button.update':'button.submit') | translate | titlecase }}</button>
            <button class=" cancel grow" mat-stroked-button
                (click)="clearAll()">{{'button.cancel' |translate |titlecase}}</button>

        </div>
        <div *ngIf="view_only" style="margin: 20px 0px;" fxLayout="row" fxLayoutAlign="center center"
            fxLayoutGap.lt-sm="25" fxLayoutGap="50">

            <button class=" cancel grow" mat-stroked-button (click)="clearAll()">Back</button>

        </div>
    </div>

    <!------------------------------------------------------------------------------------------->


</mat-card>