import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { HomeService } from 'src/app/core/services/home/home.service';
import { LayoutService } from '../../layout/layout-service/layout.service';
import { MessageService } from 'src/app/core/services/message/message.service';

@Component({
  selector: 'app-privilege',
  templateUrl: './privilege.component.html',
  styleUrls: ['./privilege.component.scss']
})
export class PrivilegeComponent implements OnInit {
  public dataSource: any = new MatTableDataSource([]);
  public user_role: any = [];
  public company_list: any = [];
  public role_control = new FormControl(null, [Validators.required]);
  public displayedColumns = ["Menu", "Create/Add", "Edit", "Delete", "View only"];
  public user_data = localStorage.getItem('user_data') ? JSON.parse(localStorage.getItem("user_data") || "") : null;
  public company_data: any = localStorage.getItem('company') ? JSON.parse(localStorage.getItem('company') || '') : null;
  public company_control = new FormControl(this.company_data?.id);
  public is_prililege_empty = "";
  constructor (

    private home: HomeService,
    private tab_service: LayoutService,
    private msg: MessageService

  ) { }

  ngOnInit (): void {

    setTimeout(() => {
      this.tab_service.showCreateButton(false);
    });

    if (this.user_data.user_role == 1) {
      this.company_control.setValidators([Validators.required]);
      this.company_control.updateValueAndValidity();
    } else {
      this.company_control.clearValidators();
      this.company_control.updateValueAndValidity();
    }

    this.home.getAllRole().subscribe((res: any) => {
      if (res.data) {
        res = res.data.filter((role: any) => {
          return role.role_id > this.user_data.user_role;
        });
        this.user_role = res;
        this.role_control.setValue(this.user_role[0]?.role_id);
        this.getPrivilege();
      }
    });


    this.home.getAllCompany().subscribe((res: any) => {
      if (res.data?.length) {
        this.company_list = res.data;
      }
    });
  }

  getPrivilege () {
    if (this.company_control.valid && this.role_control.valid) {
      const req_data = {
        company_id: this.company_control.value,
        role_id: this.role_control.value
      };
      this.home.getPrivilege(req_data).subscribe((res: any) => {
        if (res?.menu_access) {
          this.dataSource.data = JSON.parse(res.menu_access);
        } else {
          this.dataSource.data = [];
          this.is_prililege_empty = "EMPTY";
        }
      });
    } else {
      this.is_prililege_empty = "EMPTY";
    }

  }


  checkView (i: any) {

    this.dataSource.data[i].create = false;
    this.dataSource.data[i].edit = false;
    this.dataSource.data[i].delete = false;

  }

  submit () {
    if (this.company_control.invalid || this.role_control.invalid) {
      this.msg.errorSnackBar("select both company and role");
      return;
    }
    const req_data = {
      company_id: this.company_control.value,
      role_id: this.role_control.value,
      menu_access: JSON.stringify(this.dataSource.data)
    };

    this.home.updateUserPrivilege(req_data).subscribe((res: any) => {
      if (res) {

      }
    });
  }


  cancel () {

  }

  deleteFn (event: any) {

    console.log(event);

  }


}
