import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Subscription, interval, mergeMap } from 'rxjs';
import { HomeService } from 'src/app/core/services/home/home.service';
import { SettingsService } from 'src/app/core/services/settings/settings.service';
import { MessageDialogComponent } from 'src/shared/message-dialog/message-dialog.component';
import { valuedata } from '../settings.model';
@Component({
  selector: 'app-sensor-alarm',
  templateUrl: './sensor-alarm.component.html',
  styleUrls: ['./sensor-alarm.component.scss']
})
export class SensorAlarmComponent implements OnInit {
  current_time = new Date();
  createscreen: boolean = false;
  isloaded = false;
  displayedColumns: string[] = [
    "code", 'type', 'subtype', 'unt',
    "cadTrade.total",
    "cadTrade.amount",
    "data2.total",
    "data2.amount",
    "data3.total",
    "data3.amount",
    "data4.total",
    "data4.amount",
    "data5.amount",
    // "data6.amount",
    "data7.amount",
    'id'
  ];
  displayedColumns1: string[] = ["code", "data.data.group"];
  dataSource1: any[] = [];
  public company_name = localStorage.getItem('company')?.length ? JSON.parse(localStorage.getItem('company') || '')?.name : '--';
  public shed_name = localStorage.getItem('tank')?.length ? JSON.parse(localStorage.getItem('tank') || '')?.name : '--';
  public access: any;
  public subscriptions: Subscription[] = [];
  constructor(private fb: FormBuilder, private SettingsService: SettingsService, private dialog: MatDialog, private home: HomeService
  ) {
    this.access = this.home.getAccess(20);

  }
  tabledata: any[] = [];
  showicon = false;
  showspin = false;
  icon = '';
  ngOnInit(): void {
    this.getSensorDatas();
    var systemTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    this.syncAlertsAlarmFlag(false);
  }
  /**
   * get sensor alarm details service API for sensor alarm table 
   */
  getTable() {
    this.SettingsService.getSensorAlarm().subscribe((res: any) => {
      this.tabledata = res.data;
      this.setTable(res.data);
      this.isloaded = true;
    }, (error: Response) => {
      this.isloaded = true;
    });

  }
  sensorDataList: any[] = [];
  /**
   * get available sensor in shed Service API
   */
  getSensorDatas() {
    this.SettingsService.getSensors().subscribe((res: any) => {
      this.sensorDataList = res.data;
      this.getUnitDatas();
    }, (error: Response) => {
      console.log(error);
    });
  }
  unitList: any[] = [];
  /**
   * get units for sensor types service API
   */
  getUnitDatas() {
    this.SettingsService.getAllUnits().subscribe((res: any) => {
      this.unitList = res.data;
      this.getSensorType();
    }, (error: Response) => {
      console.log(error);
    });
  }
  sensorTypeList: any[] = [];
  /**
   * get sensor type service API
   */
  getSensorType() {
    this.SettingsService.getSensorType().subscribe((res: any) => {
      this.sensorTypeList = res.data;
      this.getAlertDatas();
    }, (error: Response) => {
      console.log(error);
    });
  }
  sensorlevelList: any[] = [];
  /**
   * get alert levels and details service Api
   */
  getAlertDatas() {
    this.SettingsService.getAlarts().subscribe((res: any) => {
      this.sensorlevelList = res.data;
      this.getTable();
    }, (error: Response) => {
      console.log(error);
    });
  }
  /**
   * get sensor name with sensor ID for Table
   * @param id 
   * @returns 
   */
  setSensorName(id: any) {
    var name = '';
    this.sensorDataList.filter((c: any) => {
      if (id == c.sensor_id) name = c.name;
    });
    return name;
  }
  /**
   * get sensor unit name with unit ID
   * @param id 
   * @returns 
   */
  setUnitName(id: any) {
    var name = '';
    this.unitList.filter((c: any) => {
      if (id == c.unit_id) name = c.unit_name;
    });
    return name;
  }
  /**
   * get sensor type name with type ID
   * @param id 
   * @returns 
   */
  setTypeName(id: any) {
    var name = '';
    this.sensorTypeList.filter((c: any) => {
      if (id == c.sensor_type_id) name = c.name;
    });
    return name;
  }
  /**
   * get sensor type name in dual sensor module(temprature and humidity)
   * @param id 
   * @param sub 
   * @returns 
   */
  setSubTypeName(id: any, sub: any) {
    var name = '-';
    this.sensorTypeList.filter((c: any) => {
      if (id == c.sensor_type_id) {
        var type = c.types;
        if (sub != null) {
          name = type.filter((m: any) => (m.sub_type_id == sub))[0].sub_sensor_type;
        }
      }
    });
    return name;
  }
  /**
   * get Alert name with Alert ID
   * @param id 
   * @returns 
   */
  setAlertName(id: any) {
    var name = '';
    this.sensorlevelList.filter((c: any) => {
      if (id == c.level_id) name = c.level_name;
    });
    return name;
  }
  /**
   * set table data in datasource from table data method
   * @param data 
   */
  setTable(data: any[]) {
    var displayedColumns: valuedata[] = [];
    data.filter((c) => {
      var data1 = new valuedata();
      data1.code = this.setSensorName(c.sensor_id);
      data1.unt = this.setUnitName(c.unit_id);
      data1.type = this.setTypeName(c.sensor_type_id);
      data1.subtype = this.setSubTypeName(c.sensor_type_id, c.sub_type_id);
      data1.id = c.id;
      data1.cadTrade.total = c.too_low_value;
      data1.cadTrade.amount = this.setAlertName(c.too_low_level_id);
      data1.data2.total = c.low_value;
      data1.data2.amount = this.setAlertName(c.low_level_id);
      data1.data3.total = c.high_value;
      data1.data3.amount = this.setAlertName(c.high_level_id);
      data1.data4.total = c.too_high_value;
      data1.data4.amount = this.setAlertName(c.too_high_level_id);
      data1.data5.amount = c.battery_alert_level_id != null ? this.setAlertName(c.battery_alert_level_id) : '-';
      data1.data6.amount = c.sensor_id;
      data1.data7.amount = c.signal_strength_alert_level_id != null ? this.setAlertName(c.signal_strength_alert_level_id) : '-';
      data1.sensorId = c.sensor_id;
      displayedColumns.push(data1);
    });
    this.dataSource1 = displayedColumns;
  }
  /**
   * change list page from table and vice-verse
   */
  createList() {
    this.createscreen = !this.createscreen;
    this.sensordatavalue = undefined;
  }
  /**
   * data from child to parent component
   * @param event 
   */
  receiveData(event: any) {
    this.createscreen = !this.createscreen;
    this.getTable();
    this.syncAlertsAlarmFlag(false);
  }
  /**
   * data synchornization function
   * @param flag 
   */
  syncAlertsAlarmFlag(flag: boolean) {
    this.showspin = true;
    this.SettingsService.syncAlertsAlarmFlag().subscribe((res: any) => {
      if (res.data.length != 0) {
        this.setvalues(res.data[0], flag);
      } else {
        this.showicon = true;
        this.icon = 'dots';
        this.message = 'pending';
        this.showspin = false;
      }
    }, (error: Response) => {
      console.log(error);
      this.showspin = false;
    });
  }
  message = '';
  /**
   * data synchornization set value sub methode
   * @param data 
   * @param flag 
   */
  setvalues(data: any, flag: boolean) {
    this.showicon = true;
    if (data.status == 'Pending') {
      this.icon = 'dots';
      this.message = 'pending';
    } else if (data.status == 'Success') {
      if (!flag && data.is_updated == 0) {
        this.icon = 'sync';
        this.message = 'sync needed';
      } else if (!flag && data.is_updated != 0) {
        this.icon = 'tick';
        this.message = 'success';
      } else if (data.is_updated == 0) {
        this.loopedfunction();
      } else {
        this.icon = 'tick';
        this.message = 'success';
      }
    } else if (data.status == 'Error') {
      this.icon = 'exclamation';
      this.message = data.message;
    } else {
      this.showicon = false;
      this.icon = '';
      this.message = '';
    }
    this.showspin = false;
  }
  /**
   * progress circle,success and error icon in synchornization
   */
  loopedfunction() {
    this.showicon = false;
    var i = 0;
    this.showspin = true;
    var subscription = interval(10 * 1000)
      .pipe(
        mergeMap(() => this.SettingsService.syncAlertsAlarmFlag())
      )
      .subscribe((res: any) => {
        i++;
        if (res.data.length != 0 && res.data[0].is_updated != 0) {
          this.icon = 'tick';
          this.message = 'success';
          this.showicon = true;
          subscription.closed = true;
          this.showspin = false;
        } else if (i == 5) {
          this.icon = 'sync';
          this.message = 'not updated';
          this.showicon = true;
          subscription.closed = true;
          this.showspin = false;
        }
      });
  }
  /**
   * alert sync button loop function API
   */
  syncAlertsAlarm() {
    this.showspin = true;
    this.SettingsService.syncAlertsAlarm().subscribe((res: any) => {
      setTimeout(() => {
        this.showspin = true;
        this.syncAlertsAlarmFlag(true);
      }, 3000);
    }, (error: Response) => {
      console.log(error);
      this.showspin = false;
    });
  }
  sensordatavalue: any;
  /**
   * edit and view function table screen to create screen
   * @param id 
   * @param view 
   */
  editvalue(id: any, view: boolean) {
    this.tabledata.filter((c) => {
      if (c.id == id) {
        this.sensordatavalue = c;
      }
    });
    this.sensordatavalue['screen'] = view ? 'view' : 'update';
    this.createscreen = true;
  }
  /**
   * delete particular row from table Delete Popup
   * @param id 
   * @param data 
   */
  deletevalue(id: any, data: any) {
    const config: MatDialogConfig = {
      panelClass: 'dialog-responsive',
      width: '50%',
      minWidth: '50%',
      disableClose: true,
      data: {
        title: `${data} sensor`,
        isDelete: true
      },
    };
    const openDialog = this.dialog.open(MessageDialogComponent, config);
    openDialog.afterClosed().subscribe((result: any) => {
      if (result) {
        this.SettingsService.deleteSensorAlarm(id).subscribe((res: any) => {
          this.getTable();
        }, (error: Response) => {
          console.log(error);
        });
      }

    });

  }
  ngOnDestroy() {
    this.subscriptions.forEach((sub: Subscription) => sub.unsubscribe());
  }

}
