<div class="m-p-1" fxFlex="98" fxLayout="column" fxLayoutGap="10">

    <!----------------------------- top blue bar----------------------------------- -->
    <mat-card style="background-color: #166DA6;color: white;height:4.2rem;" fxLayout="row"
        fxLayoutAlign="space-between center">
        <div fxLayout="column">
            <span style="font-size: 13px;color: white;font-weight: bold;line-height: 1;">{{shed_data.name}}</span>
            <span style="line-height: 1;margin-top: 7px;font-size: 10px;color: #FFFFFF;">
                {{company_name}}</span>
        </div>
        <div fxLayoutAlign="end center">
            <button *ngIf="access?.create && !access?.view" mat-stroked-button style="text-align: center;"
                fxLayout="row" class="add-button grow" (click)="onClickSchedule();"><mat-icon class="add-icon"
                    *ngIf="showTable">add_circle</mat-icon>
                <span style="margin-left: 5px;">{{showTable ?
                ('medication.create_schedule'|translate|titlecase)
                :'medication.all_schedule'|translate|titlecase}}</span> </button>
        </div>
    </mat-card>

    <!---------------------------------------------------------------------------- -->

    <mat-card fxLayout="column" style="height: 85%;overflow: auto;">

        <!------------------------Show message if medication list is empty------------- -->

        <div *ngIf="showTable && !dataSource.data.length">
            <app-common-message [message]="'medication.schedule_empty_msg'|translate|titlecase">
            </app-common-message>
        </div>

        <!---------------------------------------------------------------------------- -->

        <!----------------------------medication table-------------------------------------- -->
        <div *ngIf="showTable && !!dataSource.data.length" class="table-container">
            <table class="level-one-table" mat-table [dataSource]="dataSource">

                <!-- S.No Column -->
                <ng-container matColumnDef="S.NO">
                    <th mat-header-cell *matHeaderCellDef>{{'medication.s_no'|translate|titlecase}}</th>
                    <td mat-cell *matCellDef="let element;let i = index"> {{i+1}} </td>
                </ng-container>

                <!-- age in week Column -->
                <ng-container matColumnDef="age in week">
                    <th mat-header-cell *matHeaderCellDef>{{'medication.age_in_week'|translate|titlecase}}</th>
                    <td mat-cell *matCellDef="let element"> {{element.age_in_week ?element.age_in_week :'--' }} </td>
                </ng-container>

                <!-- age in day Column -->
                <ng-container matColumnDef="age in day">
                    <th mat-header-cell *matHeaderCellDef>{{'medication.age_in_day'|translate|titlecase}}</th>
                    <td mat-cell *matCellDef="let element"> {{element.age_in_day ?element.age_in_day :'--' }} </td>
                </ng-container>
                <!-- medication name Column -->
                <ng-container matColumnDef="medication name">
                    <th mat-header-cell *matHeaderCellDef>{{'medication.medication_name'|translate|titlecase}}</th>
                    <td mat-cell *matCellDef="let element"> {{element.medication_name ?element.medication_name :'--' }}
                    </td>
                </ng-container>

                <!-- dose Column -->
                <ng-container matColumnDef="dose">
                    <th mat-header-cell *matHeaderCellDef> {{'medication.dose'|translate|titlecase}} </th>
                    <td mat-cell *matCellDef="let element"> {{element.dose}} </td>
                </ng-container>

                <!-- Due Date Column -->
                <ng-container matColumnDef="Due Date">
                    <th mat-header-cell *matHeaderCellDef> {{'medication.due_date'|translate|titlecase}} </th>
                    <td mat-cell *matCellDef="let element"> {{element.due_date |date:'d-MM-YYYY'}} </td>
                </ng-container>
                <!-- company Column -->
                <ng-container matColumnDef="company">
                    <th mat-header-cell *matHeaderCellDef>{{'medication.company'|translate|titlecase}}</th>
                    <td mat-cell *matCellDef="let element"> {{element.company ?element.company :'--' }} </td>
                </ng-container>

                <!-- type Column -->
                <ng-container matColumnDef="type">
                    <th mat-header-cell *matHeaderCellDef>{{'medication.type'|translate|titlecase}}</th>
                    <td mat-cell *matCellDef="let element"> {{element.type ?element.type :'--' }} </td>
                </ng-container>

                <!-- Action Column -->
                <ng-container matColumnDef="Action">
                    <th mat-header-cell *matHeaderCellDef>{{'medication.action'|translate|titlecase}}</th>
                    <td mat-cell *matCellDef="let element">
                        <div style="display: flex;gap: 30%;">
                            <mat-icon *ngIf="access?.edit && !access?.view" style="width: 1.2rem;" svgIcon="Edit"
                                (click)="editvalue(element.scheduled_id)">

                            </mat-icon>
                            <mat-icon *ngIf="access?.delete && !access?.view" style="width: 1rem;" svgIcon="Delete"
                                (click)="deletevalue(element)">

                            </mat-icon>
                        </div>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky:true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
        </div>

        <!---------------------------------------------------------------------------- -->

        <!------------------------medication form------------------------------------------ -->
        <div *ngIf="!showTable" fxLayout="column" fxLayoutGap="15">
            <div fxLayout="column" fxLayoutGap="10">
                <ng-container *ngFor="let schedule_group of medication_group.controls ;let last = last;let i = index">
                    <mat-card class="schedule-card">
                        <div fxLayoutAlign="space-between center" [id]="'medication_schedule_' +i">
                            <div mat-card-title style="margin-left: 5px;" class="label">
                                {{'medication.schedule'|translate|titlecase}}
                                {{(dataSource.data.length) +(i+1)}}
                            </div>
                            <div fxLayout="row" fxLayoutGap="5" *ngIf="!isUpdate">
                                <mat-icon *ngIf="medication_group.controls.length>1" class="remove-icon grow"
                                    (click)="removeMedication(i)">do_not_disturb_on</mat-icon>
                                <mat-icon *ngIf="last" class="add-icon grow"
                                    (click)="addMedication(i)">add_circle</mat-icon>
                            </div>
                        </div>
                        <form [formGroup]="schedule_group" fxLayout="row warp" fxLayout.lt-sm="column">
                            <div style="font-size: 13px;margin: 5px;height: 44px;" fxFlex="31">
                                <mat-form-field appearance="outline">
                                    <mat-label>{{'medication.age_in_week'|translate|titlecase}}</mat-label>
                                    <input matInput 
                                    (keydown.arrowup)="$event.preventDefault()"
                                    (keydown.arrowdown)="$event.preventDefault()" (wheel)="$event.preventDefault()" (keydown)="
                                      $event.key == 'e' ||
                                      $event.key == 'E' ||
                                      $event.key == '+' ||
                                      $event.key == '-' ||
                                      $event.key == '.'
                                        ? $event.preventDefault()
                                        : ''
                                    " formControlName="age_in_week" type="number"
                                        (change)="calculateDueDate(i)" autocomplete="new-password"
                                        oninput="this.value=this.value.replace(/[^0-9]/g,'');javascript: if (this.value.length >2) this.value = this.value.slice(0,2);">
                                </mat-form-field>
                            </div>

                            <div style="font-size: 13px;margin: 5px;height: 44px;" fxFlex="31">
                                <mat-form-field appearance="outline">
                                    <mat-label>{{'medication.age_in_day'|translate|titlecase}}</mat-label>
                                    <input matInput formControlName="age_in_day" type="number" (keydown.arrowup)="$event.preventDefault()"
                                    (keydown.arrowdown)="$event.preventDefault()" (wheel)="$event.preventDefault()" (keydown)="
                                      $event.key == 'e' ||
                                      $event.key == 'E' ||
                                      $event.key == '+' ||
                                      $event.key == '-' ||
                                      $event.key == '.'
                                        ? $event.preventDefault()
                                        : ''
                                    "
                                        (input)="ageInDaysRegex(i,$event)" (focusout)="calculateDueDate(i)"
                                        autocomplete="new-password">
                                </mat-form-field>
                            </div>
                            <div *ngIf="is_add_medicaiton !==i" fxLayout="row"
                                style="font-size: 13px;margin: 5px;height: 44px;align-items: baseline;" fxFlex="31"
                                fxLayoutGap="10">
                                <div fxFlex="100" style="font-size: 13px;" fxlayout="row">
                                    <mat-form-field appearance="outline">
                                        <mat-label>{{'medication.medication_name'|translate|titlecase}}</mat-label>
                                        <mat-select disableOptionCentering formControlName="medication_id"
                                            panelClass="PanelClass">
                                            <ng-container *ngFor="let medication of medication_list">
                                                <mat-option id="option-with-icon" fxLayout="row"
                                                    fxLayoutAlign="space-between center"
                                                    [value]="medication.medication_id">
                                                    <span>
                                                        {{medication.medication_name }}
                                                    </span>
                                                    <!-- <mat-icon style="color:#2b7aad;font-size: 18px;" matSuffix
                                                        matTooltipPosition="above" svgIcon="remove"
                                                        (click)="$event.stopPropagation();removeName(medication)"></mat-icon> -->
                                                </mat-option>
                                            </ng-container>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <mat-icon style="color:#2b7aad;font-size: 18px;"
                                    [matTooltip]="'medication.add_medication'|translate|titlecase"
                                    matTooltipPosition="above" (click)="is_add_medicaiton=i">add_circle</mat-icon>

                            </div>
                            <div *ngIf="is_add_medicaiton===i" fxLayout="row"
                                style="font-size: 13px;margin: 5px;height: 44px;align-items: baseline;" fxFlex="31"
                                fxLayoutGap="10">
                                <div fxFlex="100" style="font-size: 13px;" fxlayout="row">
                                    <mat-form-field appearance="outline">
                                        <mat-label>{{'medication.new_medication_name'|translate|titlecase}}</mat-label>
                                        <input matInput [formControl]="add_medication_control" type="text"
                                            autocomplete="new-password">
                                    </mat-form-field>
                                </div>
                                <mat-icon style="color:#26de81;font-size: 18px;" matTooltipPosition="above"
                                    (click)="addNewMedication(i)">check_circle</mat-icon>
                                <mat-icon style="color:red;font-size: 18px;" matTooltipPosition="above"
                                    (click)="cancelNewMedication(i)">cancel</mat-icon>

                            </div>



                            <div style="font-size: 13px;margin: 5px;height: 44px;" fxFlex="31">
                                <mat-form-field appearance="outline">
                                    <mat-label>{{'medication.remark'|translate|titlecase}} 01</mat-label>
                                    <input  matInput formControlName="remark_1" type="text" autocomplete="new-password">
                                </mat-form-field>
                            </div>

                            <div style="font-size: 13px;margin: 5px;height: 44px;" fxFlex="31">
                                <mat-form-field appearance="outline">
                                    <mat-label>{{'medication.remark'|translate|titlecase}} 02</mat-label>
                                    <input  matInput formControlName="remark_2" type="text" autocomplete="new-password">
                                </mat-form-field>
                            </div>

                            <div style="font-size: 13px;margin: 5px;height: 44px;" fxFlex="31">
                                <mat-form-field appearance="outline">
                                    <mat-label>{{'medication.remark'|translate|titlecase}} 03</mat-label>
                                    <input  matInput formControlName="remark_3" type="text" autocomplete="new-password">
                                </mat-form-field>
                            </div>

                            <div style="font-size: 13px;margin: 5px;height: 44px;" fxFlex="31">
                                <mat-form-field appearance="outline">
                                    <mat-label>{{'medication.dose'|translate|titlecase}}</mat-label>
                                    <input  matInput formControlName="dose" type="text" autocomplete="new-password">
                                </mat-form-field>
                            </div>

                            <div style="font-size: 13px;margin: 5px;height: 44px;" fxFlex="31">
                                <mat-form-field appearance="outline">
                                    <mat-label>{{'medication.company'|translate|titlecase}}</mat-label>
                                    <input  matInput formControlName="company" type="text" autocomplete="new-password">
                                </mat-form-field>
                            </div>

                            <div style="font-size: 13px;margin: 5px;height: 44px;" fxFlex="31">
                                <mat-form-field appearance="outline">
                                    <mat-label>{{'medication.due_date'|translate|titlecase}}</mat-label>
                                    <div fxLayout="row" style="height: 15px !important;">
                                        <input matInput [matDatepicker]="startDate" [min]="min_date"
                                            formControlName="due_date" [readonly]="true" [disabled]="true">
                                        <mat-datepicker-toggle
                                            style="position: relative !important; bottom: 15px !important;"
                                            matIconSuffix [for]="startDate"></mat-datepicker-toggle>
                                        <mat-datepicker #startDate></mat-datepicker>
                                    </div>

                                </mat-form-field>
                            </div>

                        </form>
                    </mat-card>

                </ng-container>

            </div>

            <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="25px">
                <button class="submit grow" mat-stroked-button (click)="submit()">{{!!medicationSchadulevalue ?
                    ('button.update'|translate|titlecase):'button.submit'|translate|titlecase}}</button>
                <button class="cancel grow" mat-stroked-button
                    (click)="clearAll()">{{'button.cancel'|translate|titlecase}}</button>

            </div>

        </div>
        <!---------------------------------------------------------------------------- -->




    </mat-card>







</div>