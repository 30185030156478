<!-- <h1>NgxCSVParser by <a href="https://DevelopersHive.com">Developers Hive</a></h1>
<div class="csv-file-chooser-section">

  <input type="file" #fileImportInput name="File Upload" id="csvFileUpload" (change)="fileChangeListener($event)"
    accept=".csv" />

  <label>
    Parse Headers?
  </label>
  <select aria-placeholder="Parse headers?" (ngModel)="header">
    <option [value]="true">Yes</option>
    <option [value]="false">No</option>
  </select>
</div> -->

<div>
  <mat-card style="background-color: #166DA6;color: white;height:4rem;overflow: hidden;" fxLayout="row"
  fxLayoutAlign="space-between center">
  <div fxLayout="column">
      <span style="font-size: 13px;color: white;font-weight: bold;line-height: 1;">{{shed_data?.name}} - {{type}}</span>
      <span style="line-height: 1;margin-top: 7px;font-size: 10px;
      color: #FFFFFF;">{{subHeader}}</span>
  </div>
  
</mat-card>
<div class="table-container">

 <div class="tableFixHead">
  <table  >
    <thead>
      <tr>
        <th  *ngFor="let key of headers"> <span  [innerHTML]="key"></span></th>
      </tr>
    </thead>
    <tbody >
      <tr *ngFor="let item of data">
        <ng-container *ngFor="let key of keys; let i = index">
          <ng-container *ngIf="i < 2">
            <ng-container *ngIf="i==0">
              <th >{{ item[key] }}</th>
            </ng-container>
            <ng-container *ngIf="i==1">
              <th class="secth" >{{ item[key] }}</th>
            </ng-container>
          </ng-container>
          <ng-container *ngIf="i > 1">
            <td>{{ item[key] }}</td>
          </ng-container>
        </ng-container>
      </tr>
    </tbody>
  </table>
 </div>



</div>
</div>


