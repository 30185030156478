<mat-card class="mat-elevation-z6" fxLayout="column" fxLayoutGap="20px" fxLayoutAlign="space-between center"
    style="overflow: inherit !important;">
    <div fxLayout="column" fxFlex="100" style="width: 100%;background-color: #f5faf5;" class="formdata"
        *ngFor="let sensorTotal of totalSensoryParts;let h = index">
        <div fxLayout="row" fxFlex="25" fxLayoutGap="25" style="padding: 1%;" fxLayoutAlign="space-between start">
            <!-- form container for sensor age ,Weekly feed male and female-->
            <form [formGroup]="sensorTotal.headergroup" fxLayout.lt-sm="column" fxLayout="row"
                class="environmentalscoreField environmentalscoreField2 fieldenv"
                style="display: flex;gap: 2%;margin-top: 2%;">
                <!-- sensor type formfield -->
                <mat-form-field appearance="outline">
                    <mat-label>{{'env_score.select_type'|translate|titlecase}}</mat-label>
                    <mat-select class="matselect" formControlName="sensor_type" [disabled]="isview"
                        (selectionChange)="filterchange($event,h)" disableOptionCentering panelClass="panelclass1">
                        <mat-option *ngFor="let role of sensorTypeList" [value]="role.sensor_type_id">
                            {{role.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <!-- sensor name formfield -->
                <mat-form-field appearance="outline">
                    <mat-label>{{'env_score.select_sensor'|translate|titlecase}}</mat-label>
                    <mat-select class="matselect" formControlName="sensor_name" disableOptionCentering
                        panelClass="panelclass1">
                        <mat-option *ngFor="let role of sensorTotal.sensorlist" [value]="role.sensor_id"
                            [disabled]="role.configured">
                            {{role.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </form>
            <!-- add or remove new sensor -->
            <div fxFlex="15" style="height: 10vh;" class="padup">
                <div style="display: flex;justify-content: end;" *ngIf="!isupdate">
                    <mat-icon *ngIf="h > 0" style="color:red;" (click)="removeNewField(h)">do_not_disturb_on</mat-icon>
                    <mat-icon *ngIf="h == (totalSensoryParts.length-1)" style="color:#4bae4f;"
                        (click)="addNewField()">add_circle</mat-icon>
                </div>
            </div>
        </div>
        <!-- form Container for environmental score -->
        <form style="padding: 2%;overflow: auto;" class="tableEnvironment scrollnull"
            *ngFor="let source of sensorTotal.multidevicethreshold">
            <div mat-card-title class="labelenv" style="margin: 1.5%;" *ngIf="(source.sensorSubType!='')">
                {{source.sensorSubType | titlecase}}
            </div>
            <table mat-table [dataSource]="source.dataSource" matSort style="min-width: 100vh;">
                <!-- threshold column -->
                <ng-container matColumnDef="Threshold">
                    <th mat-header-cell *matHeaderCellDef>{{'env_score.threshold'|translate|titlecase}}</th>
                    <td mat-cell *matCellDef="let element;let i = index"> {{i+1}} </td>
                </ng-container>
                <!-- productivity value -->
                <ng-container matColumnDef="Value">
                    <th mat-header-cell *matHeaderCellDef>
                        {{'env_score.value'|translate|titlecase}}
                    </th>
                    <td mat-cell *matCellDef="let element;let i = index">
                        <div *ngIf="i !=(source.thresholdList.controls.length-1)"
                            style="text-align: start;margin-left: 25%;">
                            <mat-form-field appearance="outline" style="width:70%">
                                <mat-label>{{'env_score.value'|translate|titlecase}}</mat-label>
                                <input type="text"
                                    onkeypress="return (event.charCode >= 48 && event.charCode <= 57 )|| event.charCode ==46 "
                                    autocomplete="new-password" matInput
                                    [formControl]="getControl(i,'Value',source.thresholdList)"
                                    [readonly]="isview" /></mat-form-field>
                        </div>
                        <div *ngIf="i ==(source.thresholdList.controls.length-1)" class="disableclassenv"
                            style="text-align: start; margin-left: 25%;">
                            <mat-form-field appearance="outline" style="width:70%;pointer-events:none;">
                                <mat-label>{{'env_score.max'|translate |titlecase}}</mat-label>
                                <input type="number" matInput [readonly]="true" />
                            </mat-form-field>
                            <mat-icon (click)="info(source.thresholdList,'Value')"
                                style="position: relative;top: 5px;">info</mat-icon>
                        </div>
                    </td>
                </ng-container>
                <!-- productivity score -->
                <ng-container matColumnDef="Score">
                    <th mat-header-cell *matHeaderCellDef>
                        {{'env_score.score'|translate|titlecase}}
                    </th>
                    <td mat-cell *matCellDef="let element;let i=index" class="environmentalscoreField" fxLayout="row"
                        fxLayoutGap="25">
                        <div fxFlex="80">
                            <mat-slider style="width:75% !important ;margin-top: 20px;"
                                [formControl]="getControl(i,'Score',source.thresholdList)" [disabled]="isview"
                                thumbLabel min="-10" max="10"></mat-slider>
                        </div>
                        <div [ngStyle]="isview ? {'pointer-events':'none'}:{}" fxFlex="20"
                            style="display: flex;align-items: center;justify-content: end;">
                            <mat-icon style="color:red;" *ngIf="i > 3 && i !=(source.thresholdList.controls.length-1)"
                                (click)="removeSensor(i,source.dataSource,source.thresholdList)">do_not_disturb_on</mat-icon>
                            <mat-icon style="color:#4bae4f;" *ngIf="i ==(source.thresholdList.controls.length-2)"
                                (click)="addSensor(source.dataSource,source.thresholdList,i)">add_circle</mat-icon>
                        </div>
                    </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns1"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns1;"></tr>
            </table>
        </form>
        <mat-divider fxLayout="column" *ngIf="((totalSensoryParts.length>1)&&(h!=totalSensoryParts.length-1))"
            class="show" style="position: relative;margin: 30px 0px 0px 0px; "></mat-divider>
    </div>
    <!-- submit button -->
    <!-- while create flow its submit button if its update flow it will show update button 
            and if its view flow its didnt show any submit or update button it only shows back button  -->
    <div fxLayout="row" fxFlex="20" style="width: 100%;" fxLayoutAlign="center end">
        <div fxFlex="100" fxLayoutAlign="center center" fxLayoutGap="25" fxLayout.lt-sm="column"
            fxLayoutAlign.lt-sm="center center" class="buttondiv" style="width: 100%;max-height: 6vh;">
            <button class="submit grow changeview" mat-raised-button style="box-shadow: none;width: 14%;"
                (click)="submit()"
                *ngIf="!isview">{{isupdate?('button.update'|translate|uppercase):'button.submit'|translate|uppercase}}</button>
            <button class="cancel grow changeview" mat-raised-button style="box-shadow: none;width: 14%;"
                (click)="clear()">{{isview?('button.back'|translate|uppercase):'button.cancel'|translate|uppercase}}</button>
        </div>
    </div>
</mat-card>