import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { MessageService } from 'src/app/core/services/message/message.service';
import { ProductivityService } from 'src/app/core/services/productivity/productivity.service';
import { ScoreDialogComponent } from 'src/shared/score-dialog/score-dialog.component';

@Component({
  selector: 'app-create-productivity-score',
  templateUrl: './create-productivity-score.component.html',
  styleUrls: ['./create-productivity-score.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CreateProductivityScoreComponent implements OnInit {
  public productivity_score_group: any;
  isupdate: boolean = false;
  public header_group: any;
  isview = false;
  public shed_id = localStorage.getItem('tank')?.length ? JSON.parse(localStorage.getItem('tank') || '')?.id : null;
  displayedColumns1: string[] = ['Threshold', 'Value', 'Score'];
  Totaleggs: any = new FormArray([]);
  dataSource1 = new MatTableDataSource([]);
  Selectedeggs: any = new FormArray([]);
  dataSource2 = new MatTableDataSource([]);
  CumulativeTotaleggs: any = new FormArray([]);
  dataSource3 = new MatTableDataSource([]);
  cumulativeSelectedeggs: any = new FormArray([]);
  dataSource4 = new MatTableDataSource([]);
  Avgeggs: any = new FormArray([]);
  dataSource5 = new MatTableDataSource([]);
  public remove_ids: Array<number> = [];
  public subscriptions: Subscription[] = [];
  @Output() dataReceive = new EventEmitter<any>();
  @Input('productivitydata') productivitydata: any;
  public company_name = localStorage.getItem('company')?.length ? JSON.parse(localStorage.getItem('company') || '')?.name : '--';
  public shed_name = localStorage.getItem('tank')?.length ? JSON.parse(localStorage.getItem('tank') || '')?.name : '--';

  constructor(private fb: FormBuilder, private show_msg: MessageService, private dialog: MatDialog, private translate: TranslateService,
    private service: ProductivityService) { }

  ngOnInit(): void {
    this.productivitydata?.data?.length ? this.isupdate = true : this.isupdate = false;
    // to move max limit to the end of an array
    if (this.productivitydata?.data?.length) {
      const max_data = this.productivitydata.data?.find(((max: Record<string, number>) => Number(max['cumulative_h_egg_expected']) == 10101 && Number(max['egg_weight_expected']) == 10101));
      this.productivitydata.data = this.productivitydata.data?.filter(((max: Record<string, number>) => Number(max['cumulative_h_egg_expected']) !== 10101 && Number(max['egg_weight_expected']) !== 10101));
      this.productivitydata.data?.push(max_data);
    }
    if (!this.isupdate) {
      // this.productivityScoreArray.push(this.initForm());
      this.headerLoad();
      this.initialthreshold();
    };
    if (this.isupdate) this.isview = this.productivitydata.screen == 'view' ? true : false;
    if (this.isupdate) this.productivityUpdate(this.productivitydata?.data);
    this.getProductivityScore();
  }
  /**
   * form group for age in weeks
   */
  headerLoad() {
    this.header_group = this.fb.group({
      age_In_Week: new FormControl('', [Validators.required])
    });
  }
  /**
   * form group for female and male productivity score and value
   * @returns 
   */
  setvalue() {
    return new FormGroup({
      Value: new FormControl(null, [Validators.required]),
      mandatory: new FormControl(0, [Validators.required]),
    });
  }
  /**
   * get control name for productivity score threshold table
   * @param index 
   * @param controlName 
   * @param array 
   * @returns 
   */
  getControl(index: number, controlName: string, array: FormArray): FormControl {
    return (array.at(index) as FormGroup).get(controlName) as FormControl;
  }
  /**
   * get value for productivity score threshold table
   * @param controlName 
   * @param formGroup 
   * @returns 
   */
  givevalue(controlName: string, formGroup: FormGroup) {
    return formGroup.get(controlName)?.value;
  }
  deleteScore = () => {
    const req_obj = {
      ids: [...new Set(this.remove_ids)]
    };
    this.subscriptions.push(this.service.deleteProductivity(req_obj).subscribe((res: any) => {
    }, (error: Response) => {
      console.log(error);
    }));
  };
  /**
   * add new threshold for particular score table
   * @param source 
   * @param array 
   * @param index 
   */
  // addSensor (index: any) {
  //   if (this.Totaleggs.length <= 9 && this.Selectedeggs.length <= 9 && this.CumulativeTotaleggs.length <= 9 && this.cumulativeSelectedeggs.length <= 9 && this.Avgeggs.length <= 9) {
  //     this.Totaleggs.insert(index + 1, this.setvalue());
  //     this.Selectedeggs.insert(index + 1, this.setvalue());
  //     this.CumulativeTotaleggs.insert(index + 1, this.setvalue());
  //     this.cumulativeSelectedeggs.insert(index + 1, this.setvalue());
  //     this.Avgeggs.insert(index + 1, this.setvalue());
  //   } else {
  //     this.show_msg.errorSnackBar("productivity_score.reached_max_threshold");
  //   }
  //   this.dataSource1.data = this.Totaleggs.controls as never[];
  //   this.dataSource2.data = this.Selectedeggs.controls as never[];
  //   this.dataSource3.data = this.CumulativeTotaleggs.controls as never[];
  //   this.dataSource4.data = this.cumulativeSelectedeggs.controls as never[];
  //   this.dataSource5.data = this.Avgeggs.controls as never[];
  // }

  addSensor(source: MatTableDataSource<never>, array: FormArray, index: any) {
    if (array.length <= 9) {
      array.insert(index + 1, this.setvalue());
    } else {
      this.show_msg.errorSnackBar("productivity_score.reached_max_threshold");
    }
    source.data = array.controls as never[];
  }
  /**
   * remove selected threshold from particular threshold table
   * @param index 
   * @param source 
   * @param array 
   */
  removeSensor(index: any, source: MatTableDataSource<never>, array: FormArray) {
    array.value[index]?.id ? this.remove_ids.push(array.value[index]?.id) : null;
    array.removeAt(index);
    source.data = array.controls as never[];
  }
  /**
   * add necessary thresholds in tables when component initialized
   */
  initialthreshold() {
    for (var i = 0; i <= 4; i++) {
      this.Totaleggs.push(this.setvalue());
      this.Selectedeggs.push(this.setvalue());
      this.CumulativeTotaleggs.push(this.setvalue());
      this.cumulativeSelectedeggs.push(this.setvalue());
      this.Avgeggs.push(this.setvalue());
    }
    this.dataSource1.data = this.Totaleggs.controls;
    this.dataSource2.data = this.Selectedeggs.controls;
    this.dataSource3.data = this.CumulativeTotaleggs.controls;
    this.dataSource4.data = this.cumulativeSelectedeggs.controls;
    this.dataSource5.data = this.Avgeggs.controls;
  }
  /**
   * set values during update and view screen
   * @param score 
   */
  productivityUpdate(score: any) {
    this.header_group = this.fb.group({
      age_In_Week: new FormControl(score[0].age, [Validators.required])
    });
    score.filter((m: any) => {
      if (m.t_egg_expected != null) this.Totaleggs.push(this.setvalueupdate1(m));
      if (m.h_egg_expected != null) this.Selectedeggs.push(this.setvalueupdate2(m));
      if (m.cumulative_t_egg_expected != null) this.CumulativeTotaleggs.push(this.setvalueupdate3(m));
      if (m.cumulative_h_egg_expected != null) this.cumulativeSelectedeggs.push(this.setvalueupdate4(m));
      if (m.egg_weight_expected != null) this.Avgeggs.push(this.setvalueupdate5(m));
    });
    this.dataSource1.data = this.Totaleggs.controls;
    this.dataSource2.data = this.Selectedeggs.controls;
    this.dataSource3.data = this.CumulativeTotaleggs.controls;
    this.dataSource4.data = this.cumulativeSelectedeggs.controls;
    this.dataSource5.data = this.Avgeggs.controls;
    if (this.isupdate && !this.isview) {
      this.header_group.get('age_In_Week')?.disable();
    }
  }
  /**
   * sub-method for productivityUpdate to set values for Totaleggs during update
   * @param score 
   * @returns 
   */
  setvalueupdate1(score: any) {
    return new FormGroup({
      Value: new FormControl(score.t_egg_expected, [Validators.required]),
      mandatory: new FormControl(score.t_egg_max_score, [Validators.required]),
      id: new FormControl(score.id)

    });
  }
  /**
   * sub-method for productivityUpdate to set values for Selectedeggs during update
   * @param score 
   * @returns 
   */
  setvalueupdate2(score: any) {
    return new FormGroup({
      Value: new FormControl(score.h_egg_expected, [Validators.required]),
      mandatory: new FormControl(score.h_egg_max_score, [Validators.required]),
      id: new FormControl(score.id)

    });
  }
  /**
   * sub-method for productivityUpdate to set values for CumulativeTotaleggs during update
   * @param score 
   * @returns 
   */
  setvalueupdate3(score: any) {
    return new FormGroup({
      Value: new FormControl(score.cumulative_t_egg_expected, [Validators.required]),
      mandatory: new FormControl(score.cumulative_t_egg_max_score, [Validators.required]),
      id: new FormControl(score.id)

    });
  }
  /**
   * sub-method for productivityUpdate to set values for cumulativeSelectedeggs during update
   * @param score 
   * @returns 
   */
  setvalueupdate4(score: any) {
    return new FormGroup({
      Value: new FormControl(score.cumulative_h_egg_expected, [Validators.required]),
      mandatory: new FormControl(score.cumulative_h_egg_max_score, [Validators.required]),
      id: new FormControl(score.id)

    });
  }
  /**
   * sub-method for productivityUpdate to set values for Avgeggs during update
   * @param score 
   * @returns 
   */
  setvalueupdate5(score: any) {
    return new FormGroup({
      Value: new FormControl(score.egg_weight_expected, [Validators.required]),
      mandatory: new FormControl(score.egg_weight_max_score, [Validators.required]),
      id: new FormControl(score.id)

    });
  }
  tabledata: any[] = [];
  /**
   * get productivity score values api
   */
  getProductivityScore() {
    this.subscriptions.push(this.service.getProductivityScore().subscribe((res: any) => {
      if (res.data?.length) {
        this.tabledata = res.data;
      }
    }));
  }
  /**
   * check weather submit or update according to new score or existing score
   * @returns 
   */
  submit() {
    if (!this.isupdate) {
      var agelist = this.tabledata.filter((m) => (m.age == this.header_group.controls.age_In_Week.value));
      if (agelist.length > 0) {
        return this.show_msg.errorSnackBar('productivity_score.age_in_week_alreay_exists');
      }
    }
    var array = [this.Totaleggs, this.Selectedeggs, this.CumulativeTotaleggs, this.cumulativeSelectedeggs, this.Avgeggs];
    var data = this.verify(array);
    if (data || this.header_group.invalid) {
      return this.show_msg.errorSnackBar('no_empty_field');
    }

    if (!(this.Totaleggs.length === this.Selectedeggs.length &&
      this.Selectedeggs.length === this.CumulativeTotaleggs.length &&
      this.CumulativeTotaleggs.length === this.cumulativeSelectedeggs.length &&
      this.cumulativeSelectedeggs.length === this.Avgeggs.length)) {
      this.show_msg.errorSnackBar('thresholds mismatch');
      return;
    }
    var maxth = array.sort((a: any, b: any) => b.length - a.length)[0].length;
    var obj = this.createSaveobj(maxth);
    if (!this.isupdate) this.save(obj);
    if (this.isupdate) this.update(obj);
    if (this.remove_ids.length)
      this.deleteScore();
  }
  /**
   * verify where all fields are filled
   * @param array 
   * @returns 
   */
  verify(array: any[]) {
    var data = false;
    array.filter((m: any) => {
      for (var i = 0; i <= m.controls.length - 2; i++) {
        if (m.controls[i].status == 'INVALID') {
          data = true;
        }
      }
    });
    return data;
  }
  /**
   * create object for save api
   * @param num 
   * @returns 
   */
  createSaveobj(num: any) {
    var datalist: any = [];
    for (var i = 0; i < num - 1; i++) {
      var data: any = {};
      data['shed_id'] = localStorage.getItem('tank')?.length ? JSON.parse(localStorage.getItem('tank') || '')?.id : null;
      data['age'] = this.header_group.controls.age_In_Week.value;
      if (i < this.Totaleggs.length - 1) {
        data['t_egg_expected'] = this.Totaleggs.controls[i].controls.Value.value;
        data['t_egg_max_score'] = this.Totaleggs.controls[i].controls.mandatory.value;
      }
      if (i < this.Selectedeggs.length - 1) {
        data['h_egg_expected'] = this.Selectedeggs.controls[i].controls.Value.value;
        data['h_egg_max_score'] = this.Selectedeggs.controls[i].controls.mandatory.value;
      }
      if (i < this.CumulativeTotaleggs.length - 1) {
        data['cumulative_t_egg_expected'] = this.CumulativeTotaleggs.controls[i].controls.Value.value;
        data['cumulative_t_egg_max_score'] = this.CumulativeTotaleggs.controls[i].controls.mandatory.value;
      }
      if (i < this.cumulativeSelectedeggs.length - 1) {
        data['cumulative_h_egg_expected'] = this.cumulativeSelectedeggs.controls[i].controls.Value.value;
        data['cumulative_h_egg_max_score'] = this.cumulativeSelectedeggs.controls[i].controls.mandatory.value;
      }
      if (i < this.Avgeggs.length - 1) {
        data['egg_weight_expected'] = this.Avgeggs.controls[i].controls.Value.value;
        data['egg_weight_max_score'] = this.Avgeggs.controls[i].controls.mandatory.value;
      }
      data['id'] = this.Avgeggs.controls[i].controls?.id?.value;
      datalist.push(data);
    }
    var data1: any = {};
    data1['shed_id'] = localStorage.getItem('tank')?.length ? JSON.parse(localStorage.getItem('tank') || '')?.id : null;
    data1['age'] = this.header_group.controls.age_In_Week.value;
    data1['t_egg_expected'] = 10101;
    data1['t_egg_max_score'] = this.Totaleggs.controls[this.Totaleggs.length - 1].controls.mandatory.value;
    data1['h_egg_expected'] = 10101;
    data1['h_egg_max_score'] = this.Selectedeggs.controls[this.Selectedeggs.length - 1].controls.mandatory.value;
    data1['cumulative_t_egg_expected'] = 10101;
    data1['cumulative_t_egg_max_score'] = this.CumulativeTotaleggs.controls[this.CumulativeTotaleggs.length - 1].controls.mandatory.value;
    data1['cumulative_h_egg_expected'] = 10101;
    data1['cumulative_h_egg_max_score'] = this.cumulativeSelectedeggs.controls[this.cumulativeSelectedeggs.length - 1].controls.mandatory.value;
    data1['egg_weight_expected'] = 10101;
    data1['egg_weight_max_score'] = this.Avgeggs.controls[this.Avgeggs.length - 1].controls.mandatory.value;
    data1['id'] = this.Avgeggs.controls[this.Avgeggs.length - 1].controls?.id?.value;
    datalist.push(data1);
    return datalist;
  }
  /**
   * info popup to show maximum score value in table
   * @param array 
   * @param controlName 
   */
  info(array: FormArray, controlName: string) {
    var maxval = 0;
    var attain = false;
    array.controls.filter((a: any) => {
      var index = array.controls.indexOf(a);
      if (a.value[controlName] != '' && index != (array.controls.length - 1)) {
        maxval = parseFloat(a.value[controlName]) > maxval ? (parseFloat(a.value[controlName])) : maxval;
        attain = true;
      }
    });
    const config: MatDialogConfig = {
      panelClass: 'dialog-responsive-info',
      width: '40%',
      minWidth: '40%',
      disableClose: true,
      data: {
        title: `${attain ? maxval : ''}`,
        isDelete: true
      },
    };
    const openDialog = this.dialog.open(ScoreDialogComponent, config);
    this.subscriptions.push(openDialog.afterClosed().subscribe((result: any) => {
    }));
  }
  /**
   * update if its exesting score not developed yet
   */
  update(data: any) {
    const reqObj = {
      data
    };
    this.subscriptions.push(this.service.updateProductivity(reqObj).subscribe((res: any) => {
      this.dataReceive.emit(false);
    }));
  }
  /**
   * save if its new score
   * @param save 
   */
  save(save: any) {
    var saveReq: any = {
      data: save
    };
    this.subscriptions.push(this.service.postProductivity(saveReq).subscribe((res: any) => {
      this.dataReceive.emit(false);
    }));
  }
  /**
   * clear array and value from create to list component
   */
  clear() {
    this.dataReceive.emit(false);
  }

  validateThresholdValue(i: number, control_name: string, form_array: FormArray) {
    if (i == 0) {
      const current_value = form_array.at(i).get(control_name)?.value;
      const next_value = form_array.at(i + 1).get(control_name)?.value;
      if ((current_value >= next_value) && next_value != null) {
        form_array.at(i).get(control_name)?.reset();

        this.subscriptions.push(
          this.translate.get('productivity_score.must_be_less_than').subscribe((translation) => {
            this.show_msg.errorSnackBar(`${translation} ${next_value}`);
          })
        );
      }
    }
    if (i > 0) {
      const current_value = form_array.at(i).get(control_name)?.value;
      const previous_value = form_array.at(i - 1).get(control_name)?.value;
      const next_value = form_array.at(i + 1).get(control_name)?.value;
      if (current_value || current_value == 0) {
        if (previous_value == null) {
          form_array.at(i).get(control_name)?.reset();
          this.subscriptions.push(
            this.translate.get('productivity_score.enter_threshold_value').subscribe((translation) => {
              this.show_msg.errorSnackBar(`${translation} ${(i + 1) - 1}`);
            })
          );
          return;
        }
        if (previous_value >= current_value) {
          form_array.at(i).get(control_name)?.reset();
          this.subscriptions.push(
            this.translate.get('productivity_score.must_be_greater_than').subscribe((translation) => {
              this.show_msg.errorSnackBar(`${translation} ${previous_value}`);
            })
          );
          return;
        }
        if ((next_value <= current_value) && (next_value != null && Number(next_value) != 10101)) {
          form_array.at(i).get(control_name)?.reset();
          this.subscriptions.push(
            this.translate.get('productivity_score.must_be_less_than').subscribe((translation) => {
              this.show_msg.errorSnackBar(`${translation} ${next_value}`);
            })
          );
        }
      }

    }
  }
  ngOnDestroy() {
    this.subscriptions.forEach((sub: Subscription) => sub.unsubscribe());
  }
}
