import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AuthRoutingModule } from './auth-routing.module';
import { LoginComponent } from './login/login.component';
import { SharedModule } from 'src/shared/shared.module';
import { MaterialModule } from 'src/shared/material/material.module';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { OtpComponent } from './otp/otp.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { InfoComponent } from './info/info.component';
import { ManagementRegisterComponent } from './management-register/management-register.component';
import { HomeModule } from '../pages/home/home.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [
    LoginComponent,
    ForgotPasswordComponent,
    OtpComponent,
    ResetPasswordComponent,
    InfoComponent,
    ManagementRegisterComponent,
  ],
  imports: [
    CommonModule,
    TranslateModule.forChild(),
    SharedModule,
    MaterialModule,
    AuthRoutingModule,
    HomeModule
  ]
})
export class AuthModule { }
