import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BatchRoutingModule } from './batch-routing.module';
import { BatchSettingComponent } from './batch-setting/batch-setting.component';
import { MaterialModule } from 'src/shared/material/material.module';
import { MedicationComponent } from './medication/medication.component';
import { FullLayoutModule } from '../../layout/layout.module';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from 'src/shared/shared.module';


@NgModule({
  declarations: [
    BatchSettingComponent,
    MedicationComponent
  ],
  imports: [
    CommonModule,
    TranslateModule.forChild(),
    MaterialModule,
    BatchRoutingModule,
    FullLayoutModule,
    SharedModule
  ]
})
export class BatchModule { }
