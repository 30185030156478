import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MortalityService } from 'src/app/core/services/mortality/mortality.service';
import { BulkUploadDialogComponent } from 'src/shared/bulk-upload-dialog/bulk-upload-dialog.component';
import { MessageDialogComponent } from 'src/shared/message-dialog/message-dialog.component';
import { ReportService } from '../../report/report.service';
import { HomeService } from 'src/app/core/services/home/home.service';
import { Subscription } from 'rxjs';
import { ScoreService } from '../score.service';
import { downloadsop } from '../settings.model';
import { RecalculateDialogComponent } from 'src/shared/recalculate-dialog/recalculate-dialog.component';
import * as moment from 'moment';
import { SocketService } from 'src/app/core/services/socket/socket.service';

@Component({
  selector: 'app-mortality-score',
  templateUrl: './mortality-score.component.html',
  styleUrls: ['./mortality-score.component.scss']
})
export class MortalityScoreComponent implements OnInit {
  current_time = new Date();
  createscreen: boolean = false;
  isloaded = false;
  displayedColumns: string[] = [
    "code",
    "cadTrade.total",
    "cadTrade.amount",
    "data2.total",
    "data2.amount",
    "data3.total",
    "data3.amount",
    "data4.total",
    "data4.amount",
    'id'
  ];
  displayedColumns1: string[] = ["code", "data.data.group"];
  dataSource1 = new MatTableDataSource([]);
  displayedColumns2: string[] = ['Age in weeks', 'Parameters', 'Total No.of.Thresholds', 'Min Score', 'Max score', 'Action'];
  parameters: string[] = ['Female Mortality per week (in %)', 'Male mortality per week (in %)', 'Female Cumulative mortality (in %)', 'Male cumulative mortality (in %)'];
  dataSource = new MatTableDataSource([]);
  public company_name = localStorage.getItem('company')?.length ? JSON.parse(localStorage.getItem('company') || '')?.name : '--';
  public shed_name = localStorage.getItem('tank')?.length ? JSON.parse(localStorage.getItem('tank') || '')?.name : '--';
  public shed_id = localStorage.getItem('tank')?.length ? JSON.parse(localStorage.getItem('tank') || '')?.id : null;
  public access: any;
  public subscriptions: Subscription[] = [];
  public recal_status: any;


  constructor(private fb: FormBuilder, private dialog: MatDialog, private ReportService: ReportService, private home: HomeService,
    private service: MortalityService, private Scores: ScoreService,
    private socket: SocketService) {
    this.access = this.home.getAccess(23);

  }

  ngOnInit(): void {
    this.getMortalityScore();
    this.getRecalStatus();
    this.subscriptions.push(
      this.socket.recalculation_status.subscribe((res: any) => {
        if (res?.shed_id == this.shed_id) {
          this.getRecalStatus();
        }
      })
    );
  }
  /**
   * change list page from table and vice-verse
   */
  createList() {
    this.createscreen = !this.createscreen;
    this.mortalityScorevalue = undefined;
  }
  tabledata: any[] = [];
  /**
   * get mortality score details service API for mortality score table
   */
  getMortalityScore() {
    this.subscriptions.push(this.service.getMortalityScore().subscribe((res: any) => {
      if (res.data?.length) {
        this.tabledata = res.data;
        this.dataSource.data = this.loopTableData(res.data) as never[];
      }
      this.isloaded = true;
    }, (error: Response) => {
      this.isloaded = true;
    }),
      this.Scores.getRecalMortalityStatus().subscribe((res: any) => {
        if (res.data?.last_recalculated_time) {
          const msg = `${res.data.msg}\nLast recalculated date: ${moment(res.data.last_recalculated_time).format("DD MMM YYYY, h:mm:ss a")}`;
          this.recal_status = {
            msg,
            error: !!res.data.error
          };
        }
      }));
  }
  tablefilteredList: any = {};
  /**
   * set table data in datasource from table data method
   * @param data 
   * @returns 
   */
  loopTableData(data: any[]) {
    const datalist: any[] = [];
    var variables = data,
      result = variables.reduce(function (r, a) {
        r[a.age] = r[a.age] || [];
        r[a.age].push(a);
        return r;
      }, Object.create(null));
    this.tablefilteredList = result;
    Object.keys(this.tablefilteredList).forEach(key => {
      var datum: any = {};
      datum['age'] = key;
      datum['id'] = key;
      datum['totalthreshold'] = this.tablefilteredList[key]?.length;
      var list: any[] = [];
      result[key].filter((m: any) => {
        list.push(m.f_mortality_max_score);
        list.push(m.cumulative_f_mortality_max_score);
        list.push(m.m_mortality_max_score);
        list.push(m.cumulative_m_mortality_max_score);
      });
      datum['min_score'] = Math.min(...list);
      datum['max_score'] = Math.max(...list);
      datalist.push(datum);
    });
    return datalist;
  }
  /**
   * data from child to parent component
   * @param event 
   */
  handleData(event: any) {
    this.getMortalityScore();
    this.createscreen = event;
    this.mortalityScorevalue = undefined;
  }
  mortalityScorevalue: any = undefined;
  /**
   * edit and view function table screen to create screen
   * @param id 
   * @param view 
   */
  editvalue(id: any, view: any) {
    // this.tabledata.filter((c) => {
    //   c.mortality_thresholds.filter((n:any)=>{
    //     if (n.threshold_id == id) {
    //       this.mortalityScorevalue = n;
    //       this.mortalityScorevalue['age']=c.age;
    //     }
    //   })
    // });
    const data = {
      data: this.tablefilteredList[id],
      screen: view ? 'view' : 'update'
    };
    this.mortalityScorevalue = data;
    this.createscreen = true;
  }
  /**
   * delete particular row from table Delete Popup
   * @param id 
   */
  deletevalue(id: any) {
    const config: MatDialogConfig = {
      panelClass: 'dialog-responsive',
      minWidth: '50%',
      disableClose: true,
      data: {
        title: `Selected Productivity Score`,
        isDelete: true
      },
    };
    const openDialog = this.dialog.open(MessageDialogComponent, config);
    this.subscriptions.push(openDialog.afterClosed().subscribe((result: any) => {
      if (result) {
        this.subscriptions.push(this.service.deleteMortality(id).subscribe((res: any) => {
          this.getMortalityScore();
        }, (error: Response) => {
          console.log(error);
        }));
      }

    }));

  }
  /**
   * open bulk upload popup for mortality score
   */
  bulkUpload() {
    const config: MatDialogConfig = {
      panelClass: 'dialog-responsive-bulk',
      width: '50%',
      minWidth: '50%',
      disableClose: true,
      data: {
        title: ``,
        isDelete: true,
        sop_name: 'mortality_score'
      },
    };
    const openDialog = this.dialog.open(BulkUploadDialogComponent, config);
    this.subscriptions.push(openDialog.afterClosed().subscribe((result: any) => {
      if (result) {
        this.subscriptions.push(this.service.postMortalityBulkUpload(result).subscribe((res: any) => {
          this.getMortalityScore();
        }, (error: Response) => {
          console.log(error);
        }));
      }
    }));

  }
  criticalHeader: string[] = ["Age in weeks", "Threshold", "Female Mortality Per Bird (in %)", "Female Mortality Per Bird (in %) Score", "Female Cumulative Mortality Per Bird (in %)",
    "Female Cumulative Mortality Per Bird (in %) Score", "Male Mortality Per Bird (in %)", "Male Mortality Per Bird (in %) Score",
    "Male Cumulative Mortality Per Bird (in %)", "Male Cumulative Mortality Per Bird (in %) Score"];

  /**
   * download sop records for mortality score
   */
  filterdataProductivity() {
    var arraylist: any[] = [];
    const result = this.tabledata.reduce(function (r, a) {
      r[a.age] = r[a.age] || [];
      r[a.age].push(a);
      return r;
    }, Object.create(null));
    var variables = result;
    Object.keys(variables).forEach(key => {
      var i = 1;
      variables[key].filter((sensor: any) => {
        let map: any = {};
        map['Age in weeks'] = sensor.age;
        map['Threshold'] = i;
        i++;
        map['Female Mortality Per Bird (in %)'] = this.setloops(sensor.f_mortality_expected == "10101.00" ? 'max' : sensor.f_mortality_expected);
        map['Female Mortality Per Bird (in %) Score'] = this.setloops(sensor.f_mortality_max_score);
        map['Female Cumulative Mortality Per Bird (in %)'] = this.setloops(sensor.cumulative_f_mortality_expected == "10101.00" ? 'max' : sensor.cumulative_f_mortality_expected);
        map['Female Cumulative Mortality Per Bird (in %) Score'] = this.setloops(sensor.cumulative_f_mortality_max_score);
        map['Male Mortality Per Bird (in %)'] = this.setloops(sensor.m_mortality_expected == "10101.00" ? 'max' : sensor.m_mortality_expected);
        map['Male Mortality Per Bird (in %) Score'] = this.setloops(sensor.m_mortality_max_score);
        map['Male Cumulative Mortality Per Bird (in %)'] = this.setloops(sensor.cumulative_m_mortality_expected == "10101.00" ? 'max' : sensor.cumulative_m_mortality_expected);
        map['Male Cumulative Mortality Per Bird (in %) Score'] = this.setloops(sensor.cumulative_m_mortality_max_score);
        arraylist.push(map);
      });
    });
    this.ReportService.downloadFile(arraylist, 'Mortality Score Sop', this.criticalHeader);
  }
  downloadMortality() {
    var downloadData: downloadsop = this.Scores.getThresholdNdMaxScore(this.tabledata);
    var prodcsvData = this.Scores.buildCsvObject(downloadData.data, downloadData.max, this.Scores.mortality, this.Scores.prodNdMortConstants);
    this.Scores.downloadCsv(prodcsvData, 'Mortality Score Sop');
  }
  /**
   * loop data for sop download records
   * @param data 
   * @returns 
   */
  setloops(data: any) {
    return data != null ? data : '';
  }
  getRecalStatus() {
    this.subscriptions.push(
      this.Scores.getRecalMortalityStatus().subscribe((res: any) => {
        if (res.data?.last_recalculated_time) {
          const msg = `${res.data.msg}\nLast recalculated date: ${moment(res.data.last_recalculated_time).format("DD MMM YYYY, h:mm:ss a")}`;
          this.recal_status = {
            msg,
            error: !!res.data.error
          };
        }
      }));
  }
  recalculate() {
    const config: MatDialogConfig = {
      panelClass: 'dialog-responsive',
      minWidth: '50%',
      disableClose: true,
      data: {
        title: "Mortality Score",
        is_mortality_score: true,
      },
    };
    const dialogRes = this.dialog.open(RecalculateDialogComponent, config);
    dialogRes.afterClosed().subscribe((res: any) => {
      if (res) {
        this.getRecalStatus();
      }

    });
  }
  ngOnDestroy() {
    this.subscriptions.forEach((sub: Subscription) => sub.unsubscribe());
  }
}
