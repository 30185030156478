import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MessageService } from 'src/app/core/services/message/message.service';
import { SettingsService } from 'src/app/core/services/settings/settings.service';

@Component({
  selector: 'app-iot-dialog',
  templateUrl: './iot-dialog.component.html',
  styleUrls: ['./iot-dialog.component.scss']
})
export class IotDialogComponent implements OnInit {
  public gateway_group: any;
  public TypeList = ['Farm Agent', 'Weight', 'Controller'];
  constructor(
    public dialogRef: MatDialogRef<IotDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: any, private show_msg: MessageService, private fb: FormBuilder, private SettingsService: SettingsService
  ) { }

  ngOnInit(): void {
    this.gateway_group = this.fb.group({
      type: new FormControl('', [Validators.required]),
      gateway_id: new FormControl('', [Validators.required])
    });
    const dialogContainer: any = document.getElementsByClassName("mat-dialog-container")[0];
    if (this.dialogData?.isDelete) {
      dialogContainer.style.overflow = "initial";
    } else {
      dialogContainer.style.overflow = "auto";
    }
    this.removelist();
  }
  tabledata = [];
  removelist() {
    this.SettingsService.getIotSerialNo().subscribe((res: any) => {
      if (res?.data?.length) {
        this.tabledata = res.data;
        this.dialogData?.isUpdate ? this.updateinput(res) : this.create();
      }
    }, (error: any) => {
    });
  }
  create() {
    var templist: any[] = [];
    this.TypeList.filter((n: any) => {
      var data = this.tabledata.filter((m: any) => (m.type == n));
      if (data.length == 0) templist.push(n);
    });
    this.TypeList = templist;

  }
  updateinput(res: any) {
    var data = res.data.filter((m: any) => (m.id == this.dialogData?.title));
    var templist: any[] = [];
    if (data.length > 0) {
      this.gateway_group = this.fb.group({
        type: new FormControl(data[0].type, [Validators.required]),
        gateway_id: new FormControl(data[0].serial_no, [Validators.required])
      });
      templist.push(data[0].type);
    }
    this.TypeList.filter((n: any) => {
      var data = this.tabledata.filter((m: any) => (m.type == n));
      if (data.length == 0) templist.push(n);
    });
    this.TypeList = templist;
  }
  cancel() {
    this.dialogRef.close(false);
  }
  submit() {
    this.dialogData?.isUpdate ? this.update() : this.save();
  }
  save() {
    if (this.gateway_group.invalid) {
      return this.show_msg.errorSnackBar('no_empty_field');
    }
    var data = {
      serial_no: this.gateway_group.value.gateway_id,
      tank_id: JSON.parse(localStorage.getItem('tank') || '')?.id,
      type: this.gateway_group.value.type,
    };
    this.SettingsService.saveIot(data).subscribe((res: any) => {
      this.dialogRef.close(true);
    });
  }
  update() {
    if (this.gateway_group.invalid) {
      return this.show_msg.errorSnackBar('no_empty_field');
    }
    var data = {
      serial_no: this.gateway_group.value.gateway_id,
      tank_id: JSON.parse(localStorage.getItem('tank') || '')?.id,
      type: this.gateway_group.value.type,
    };
    this.SettingsService.updateIotSerialNo(this.dialogData?.title, data).subscribe((res: any) => {
      this.dialogRef.close(true);
    });
  }


}
