import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MessageService } from 'src/app/core/services/message/message.service';
import { ALERT_INTERFACE, ALERT_LEVELS } from '../common-model/common.model';
import { Observable, Subscription, map, startWith, forkJoin } from 'rxjs';
import { HomeService } from 'src/app/core/services/home/home.service';
import { SettingsService } from 'src/app/core/services/settings/settings.service';

@Component({
  selector: 'app-alert-dialog',
  templateUrl: './alert-dialog.component.html',
  styleUrls: ['./alert-dialog.component.scss']
})
export class AlertDialogComponent implements OnInit, OnDestroy {
  public alert_form!: FormGroup;
  public subscription: Subscription[] = [];
  public alert_levels: ALERT_INTERFACE[] = ALERT_LEVELS;
  public company_list: Array<any> = [];
  public company_chip_list: Array<any> = [];
  public company_control: FormControl = new FormControl();
  public companyObservable: Observable<any> = new Observable();
  constructor (
    public dialogRef: MatDialogRef<AlertDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: any,
    private fb: FormBuilder,
    private msg: MessageService,
    private home: HomeService,
    private service: SettingsService
  ) { }

  ngOnInit (): void {
    this.init();
    const getCompany = this.home.getAllCompany();
    const getUserData = this.home.getUserByUserId();
    this.subscription.push(
      forkJoin([getCompany, getUserData]).subscribe(((response: any) => {
        if (response[0]?.data?.length) {
          response[0]?.data.sort((a: any, b: any) => a.company_name.localeCompare(b.company_name));;
          this.company_list = response[0]?.data;
          this.companyObservableFn();
        }
        if (response[1]?.data?.length) {
          this.init(response[1]?.data[0]);
          const company_id = JSON.parse(response[1].data[0].company_list).map((id: any) => id.company_id);
          this.company_chip_list = this.company_list.filter((c: any) => {
            return company_id.includes(c.company_id);

          });
        }

      }))
    );
  }

  init (data?: any) {

    this.alert_form = this.fb.group({
      company_wide_alert: new FormControl(data?.company_wide_alert == 1),
      alert_level: new FormControl({ value: data?.alert_level, disabled: true }),
      email_alert: new FormControl({ value: data?.email_alert == 1, disabled: true }),
      ring_alert: new FormControl({ value: data?.ring_alert == 1, disabled: true }),
      sms_alert: new FormControl({ value: data?.sms_alert == 1, disabled: true }),
      whatsapp_alert: new FormControl({ value: data?.whatsapp_alert == 1, disabled: true })
    });
    if (data?.company_list?.length) {
      this.company_chip_list = [];
      this.company_chip_list = JSON.parse(data.company_list);
    }
    this.companyWideChange();

  }

  companyWideChange = () => {
    setTimeout(() => {
      const data = this.alert_form.get('company_wide_alert')?.value;
      const form_controls = ['alert_level', 'email_alert', 'ring_alert', 'sms_alert', 'whatsapp_alert'];
      if (data) {
        this.alert_form.get('alert_level')?.setValidators([Validators.required]);
        form_controls.forEach((controls: string) => {
          this.alert_form.get(controls)?.enable();
          this.alert_form.get(controls)?.updateValueAndValidity();
        });

      } else {
        form_controls.forEach((controls: string) => {
          this.alert_form.get(controls)?.reset();
          this.alert_form.get(controls)?.clearValidators();
          this.alert_form.get(controls)?.updateValueAndValidity();
          this.alert_form.get(controls)?.disable();
        });

      }
    });

  };

  companySelectionChange (data: any) {
    this.company_chip_list.push(data);
    this.removeCompany(data);
    setTimeout(() => {
      var div: any = document.getElementById(`chip_scroll_down${this.company_chip_list?.length - 1}`);
      if (div)
        div.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
    }, 150);
  }

  addCompany (data: any) {
    this.company_list.push(data);
    this.company_list.sort((a, b) => a.company_name.localeCompare(b.company_name));
    this.companyObservableFn();
  }

  removeCompany (data: any) {
    this.company_list = this.company_list.filter((d: any) => { return d.company_id !== data.company_id; });
    this.companyObservableFn();
  }

  trackByFn (data: any): number {
    return data.company_id;
  }
  removeChipCompany (data: any, i: number) {
    this.company_chip_list.splice(i, 1);
    this.addCompany(data);
  }

  companyObservableFn () {
    this.companyObservable = this.company_control.valueChanges.pipe(
      startWith(''),
      map(value => {
        return value?.length ? this.companyFilter(value) : this.company_list.slice();
      })
    );
  }
  private companyFilter (value: string) {
    const filterValue = value?.toLowerCase();
    return this.company_list.filter((option: any) => option?.company_name?.toLowerCase().includes(filterValue));
  }



  showCompanyWideAlertStatus () {
    const data = this.alert_form.get('company_wide_alert')?.value;
    this.msg.infoSnackBar(`company wide alert is turned ${data ? 'on' : 'off'}`);
  }

  focusInAlerts () {
    const data = !this.alert_form.get('company_wide_alert')?.value;
    if (data)
      this.msg.infoSnackBar('Turn ON company wide alert first');
  }

  submit () {
    console.log(this.alert_form);

    if (this.alert_form.invalid) {
      this.msg.errorSnackBar('Select alert level');
      return;
    }
    // Ensure that at least one alert type is chosen when the company-wide alert is activated.
    const is_company_wide_alert = this.alert_form.get('company_wide_alert')?.value;
    if (is_company_wide_alert) {
      if (!this.company_chip_list.length) {
        this.msg.errorSnackBar('Atleast select one company');
        return;
      }
      const form_controls = ['email_alert', 'ring_alert', 'sms_alert', 'whatsapp_alert'];
      const is_not_valid = !form_controls.some((control: string) => this.alert_form.get(control)?.value);
      if (is_not_valid)
        return this.msg.infoSnackBar('Select atleast one mode of communication');
    }
    const company_list = this.company_chip_list.map((c: any) => { return { company_id: c.company_id }; });
    const reqObj = {
      ...this.alert_form.value,
      company_list: JSON.stringify(company_list)
    };
    this.subscription.push(
      this.service.companyWideAlert(reqObj).subscribe((res: any) => {
        if (res) {
          this.dialogRef.close();
        }
      })
    );
  }


  ngOnDestroy (): void {
    this.subscription.forEach((s: Subscription) => s.unsubscribe());

  }
}
