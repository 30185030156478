import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AlertsComponent } from './alerts/alerts.component';
import { EnvironmentalScoreComponent } from './environmental-score/environmental-score.component';
import { EquipmentComponent } from './equipment/equipment.component';
import { GrowthScoreComponent } from './growth-score/growth-score.component';
import { MortalityScoreComponent } from './mortality-score/mortality-score.component';
import { ProductivityScoreComponent } from './productivity-score/productivity-score.component';
import { SensorAlarmComponent } from './sensor-alarm/sensor-alarm.component';
import { ListScoreAlertsComponent } from './list-score-alerts/list-score-alerts.component';
import { PermissionGuard } from 'src/app/core/permission-guard/permission.guard';

const routes: Routes = [

  {
    path: "",
    redirectTo: "alerts",
    pathMatch: 'full'
  },

  {
    path: "alerts",
    canActivate: [PermissionGuard],
    component: AlertsComponent
  },
  {
    path: "sensor-alarm",
    canActivate: [PermissionGuard],
    component: SensorAlarmComponent
  },
  {
    path: "growth-score",
    canActivate: [PermissionGuard],
    component: GrowthScoreComponent
  }
  ,
  {
    path: "productivity-score",
    canActivate: [PermissionGuard],
    component: ProductivityScoreComponent
  }
  ,
  {
    path: "mortality-score",
    canActivate: [PermissionGuard],
    component: MortalityScoreComponent
  }
  ,
  {
    path: "environmental-score",
    canActivate: [PermissionGuard],
    component: EnvironmentalScoreComponent
  }
  ,
  {
    path: "equipment",
    canActivate: [PermissionGuard],
    component: EquipmentComponent
  },
  {
    path: "score-alerts/productivity",
    component: ListScoreAlertsComponent
  },
  {
    path: "score-alerts/mortality",
    component: ListScoreAlertsComponent
  },
  {
    path: "score-alerts/growth",
    component: ListScoreAlertsComponent
  }




];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SettingsRoutingModule { }
