export interface MenuItem {
    label: string;
    icon: string;
    url: string;
}
export interface sideMenuItem {
    label: string;
    icon: string;
    icon1?: string;
    subMenu: boolean;
    menu_id: number,
    url?: string;
    subMenuItems?: Array<subMenuItem>;

}

export interface subMenuItem {
    label: string;
    url?: string;
    menu_id: number;
    disable?: boolean;

}
export interface IKebabMenu {
    label: string,
    icon: string,
    functionName?: any,
    this?: any;

}

export const sideMenuItem: sideMenuItem[] = [
    {
        label: "side_menu.dashboard",
        icon: "assets/side-bar/dashboard",
        subMenu: false,
        url: "main/dashboard",
        menu_id: 6

    }
    ,
    {
        label: "side_menu.controller",
        icon: "assets/side-bar/controller",
        subMenu: false,
        url: "main/controller-dashboard",
        menu_id: 29

    }
    ,
    {
        label: "side_menu.day_entry",
        icon: "assets/side-bar/dayentry",
        subMenu: false,
        url: "day-entry",
        menu_id: 7

    },
    {
        label: "side_menu.reports",
        icon: "assets/side-bar/report",
        subMenu: true,
        url: "reports",
        menu_id: 8,
        subMenuItems: [
            {
                label: "side_menu.report",
                url: "reports/report",
                menu_id: 9

            },
            {
                label: "side_menu.custom_report",
                url: "reports/customreport",
                menu_id: 10
            },
        ]

    },
    {
        label: "side_menu.configuration",
        icon: "assets/side-bar/config",
        subMenu: true,
        url: "configuration",
        menu_id: 11,
        subMenuItems: [
            {
                label: "side_menu.sensor",
                url: "configuration/sensor",
                menu_id: 12

            },
            {
                label: "side_menu.gateways",
                url: "configuration/iot-gateway",
                menu_id: 13
            },
        ]

    },
    {
        label: "side_menu.batch",
        icon: "assets/side-bar/fish",
        icon1: "assets/side-bar/shrimp",
        subMenu: true,
        url: "batch",
        menu_id: 14,
        subMenuItems: [

            {
                label: "side_menu.batch_settings",
                url: "batch/batch-setting",
                menu_id: 15
            },
            {
                label: "side_menu.medication_schedule",
                url: "batch/medication-schedule",
                menu_id: 16
            }
        ]

    },
    // {
    //     label: "side_menu.score",
    //     icon: "assets/side-bar/score",
    //     subMenu: false,
    //     url: "score",
    //     menu_id: 17

    // },
    {
        label: "side_menu.settings",
        icon: "assets/side-bar/settings",
        subMenu: true,
        url: "settings",
        menu_id: 18,
        subMenuItems: [
            {
                label: "side_menu.alerts",
                url: "settings/alerts",
                menu_id: 19

            },
            {
                label: "side_menu.sensor_alarm",
                url: "settings/sensor-alarm",
                menu_id: 20
            },
            {
                label: "side_menu.growth_alarm",
                url: "settings/score-alerts/growth",
                menu_id: 26
            },
            {
                label: "side_menu.growth_score",
                url: "settings/growth-score",
                menu_id: 21
            },
            // {
            //     label: "side_menu.productivity_alarm",
            //     url: "settings/score-alerts/productivity",
            //     menu_id: 27
            // },
            // {
            //     label: "side_menu.productivity_score",
            //     url: "settings/productivity-score",
            //     menu_id: 22
            // },
            {
                label: "side_menu.mortality_alarm",
                url: "settings/score-alerts/mortality",
                menu_id: 28
            },
            {
                label: "side_menu.mortality_score",
                url: "settings/mortality-score",
                menu_id: 23
            },
            {
                label: "side_menu.env_score",
                url: "settings/environmental-score",
                menu_id: 24
            },
            {
                label: "side_menu.equipment",
                url: "settings/equipment",
                menu_id: 25
            }
        ]

    },
];

export const kebabMenuItems: IKebabMenu[] = [
    {
        label: "language",
        icon: "language",
        functionName: "language",
    },
    {
        label: "notifications",
        icon: "notifications",
        functionName: "notifications",
    },
    {
        label: "Logout",
        icon: "logout",
        functionName: "logout",
    },
];

export const MenuItemList = [
    {
        label: "Play Video",
        icon: "slideshow",
        showOnMobile: false,
        showOnTablet: false,
        showOnDesktop: true,
        url: "playVideo",

    },
    {
        label: "Blog",
        icon: "rss_feed",
        showOnMobile: false,
        showOnTablet: false,
        showOnDesktop: true,
        url: "",

    },
    {
        label: "Docs",
        icon: "notes",
        showOnMobile: false,
        showOnTablet: true,
        showOnDesktop: true,
        url: "",

    },
    {
        label: "About",
        icon: "help",
        showOnMobile: false,
        showOnTablet: true,
        showOnDesktop: true,
        url: "",

    },
    {
        label: "Sign Out",
        icon: "logout",
        showOnMobile: true,
        showOnTablet: true,
        showOnDesktop: true,
        url: "",

    }
];

export const HOME_TABS = () => {

    let top_menu = [
        {
            label: 'user',
            user_tab: true,
            enable: true,
            menu_id: 2
        },
        {
            label: 'company',
            company_tab: true,
            enable: true,
            menu_id: 3
        },
        // {
        //     label: 'farm',
        //     farm_tab: true,
        //     enable: true,
        //     menu_id: 4
        // },
        {
            label: 'tank',
            tank_tab: true,
            enable: true,
            menu_id: 5
        },
    ];

    const user_data: any = localStorage.getItem('user_data') ? JSON.parse(localStorage.getItem('user_data') || '') : null;
    const menu_access: any = user_data?.access?.menu_access ? JSON.parse(user_data.access?.menu_access || '') : null;
    if (menu_access) {
        const menuAccessIds: any = menu_access.filter((menu: any) => Object.values(menu).includes(true)).map((d: any) => d.menu_id);
        let filteredMenus = top_menu.filter((menu: any) => {
            return menuAccessIds.includes(menu.menu_id);
        });
        top_menu = filteredMenus;
    }
    return top_menu;

};