import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { CryptoService } from 'src/app/core/services/crypto/crypto.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit, OnDestroy {
  public subscription: Subscription[] = [];
  forgotPage = new FormGroup({
    user_login_name: new FormControl('', Validators.required),
  });

  constructor (
    private route: Router, private AuthService: AuthService,
    private crypto: CryptoService
  ) { }

  ngOnInit (): void {
  }

  /**------------------------------------------------------------------------------------------------- */

  /** Submits the forgot password form and sends a request to reset the password. */
  /** Navigates to '/auth/otp' page with the user ID on success and stores email and user login name in local storage. */

  submit () {
    if (this.forgotPage.invalid) {
      this.forgotPage.markAllAsTouched();
      return;
    }
    this.subscription.push(
      this.AuthService.forgetPassword(this.forgotPage.value).subscribe((res: any) => {
        if (res?.data) {
          const user_login_name: any = this.forgotPage.value['user_login_name'];
          const obj = {
            auth_id: res?.data,
            email: res.email,
            otp_expires_in: res?.expires_in,
            user_name: user_login_name
          };
          localStorage.setItem('auth_data', this.crypto.encrypt(obj));
          this.route.navigate(['/auth/otp']);
        }

      }, (error: Response) => {
      })
    );
  }
  /**------------------------------------------------------------------------------------------------- */

  ngOnDestroy (): void {
    this.subscription.forEach((s: Subscription) => s.unsubscribe());
  }
}
