import { Component, ElementRef, Inject, OnInit, Renderer2, ViewChild, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { DashboardAPIService } from 'src/app/core/services/dashboardAPI/dashboard-api.service';
import { MessageService } from 'src/app/core/services/message/message.service';
import { inactiveSensor } from 'src/app/pages/dashboard/Dashboard.model';
import { DashboardService } from 'src/app/pages/dashboard/dashboard.service';

@Component({
  selector: 'app-inactive-sensor-dialog',
  templateUrl: './inactive-sensor-dialog.component.html',
  styleUrls: ['./inactive-sensor-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class InactiveSensorDialogComponent implements OnInit {
  public subscriptions: Subscription[] = [];
  inactive:boolean=true;
  @ViewChild('scrollContainerinactive') scrollContainer!: ElementRef;
  livescoreEnv!:inactiveSensor;
  constructor(
    public dialogRef: MatDialogRef<InactiveSensorDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: any,private show_msg: MessageService,
    private api: DashboardAPIService,private renderer: Renderer2,private DashboardService: DashboardService
  ) { }
 sensor_type=[]
  ngOnInit(): void {
    const dialogContainer: any = document.getElementsByClassName("mat-dialog-container")[0];
    if (this.dialogData?.isDelete) {
      dialogContainer.style.overflow = "initial";
    } else {
      dialogContainer.style.overflow = "auto";
    }
    this.sensor_type=this.DashboardService.sensor_types;
    this.getEnvironmentalInactiveSensors(this.dialogData?.data);
  }
  inactivesensor:any[]=[]
  sensorWithoutSOP:any[]=[]
  getEnvironmentalInactiveSensors(data:inactiveSensor){
      if (data){
        this.livescoreEnv=data;
        this.inactivesensor=this.livescoreEnv.inactivesensor;
        this.sensorWithoutSOP=this.livescoreEnv.sensorWithoutSOP;
      }
  }
  cancel() {
    this.dialogRef.close();
  }
  getDate(date:any){
    if (!!date) {
      return moment(date).format("MM DD YYYY");
    }
    return moment().format("MM DD YYYY");
  }
  getIcon(data:any){
    return this.sensor_type[data];
  }
  ngOnDestroy() {
    this.subscriptions.forEach((sub: Subscription) => sub.unsubscribe())
  }
  getsplit(array1:any[],array2:any[]){
    return ((array1.length==0 && array2.length!=0)||(array1.length!=0 && array2.length==0))?true:false;
  }
  islistempty(array:any[]){
    return array.length==0?false:true;
  }
  secondpage=false
  onScroll(event: any) {
    if (event.target.offsetWidth + event.target.scrollLeft >= (event.target.scrollWidth-200)) {
      this.secondpage=true
    }else{
      this.secondpage=false
    }
}
scrollToLeft() {
  const container = this.scrollContainer.nativeElement;
  this.renderer.setStyle(container, 'scroll-behavior','smooth');
  container.scrollLeft -= container.offsetWidth;
}
scrollToright() {
  const container = this.scrollContainer.nativeElement;
  this.renderer.setStyle(container, 'scroll-behavior','smooth');
  container.scrollLeft += container.offsetWidth;
}

}
