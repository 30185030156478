import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from 'src/shared/shared.module';
import { AuthModule } from './auth/auth.module';
import { FullLayoutModule } from './pages/layout/layout.module';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { DayWiseEntryComponent } from './pages/day-wise-entry/day-wise-entry.component';
import { MaterialModule } from 'src/shared/material/material.module';
import { TokenInterceptor } from './core/interceptor/token-interceptor';
import { SettingsModule } from './pages/settings/settings.module';
import { HomeModule } from './pages/home/home.module';
import { ConfigurationModule } from './pages/settings/configuration/configuration.module';
import { BatchModule } from './pages/settings/batch/batch.module';

import { SocketIoModule, SocketIoConfig } from "ngx-socket-io";
import { environment } from 'src/environments/environment';
import { ScoreComponent } from './pages/score/score.component';
import { ReportModule } from './pages/report/report/report.module';
import { ViewreportComponent } from './pages/report/viewreport/viewreport.component';
import { FormsModule } from '@angular/forms';
import { MatCardModule } from '@angular/material/card';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

const config: SocketIoConfig = { url: environment.SOCKET_URL, options: {} };

@NgModule({
  declarations: [
    AppComponent,
    DayWiseEntryComponent,
    ScoreComponent,
    ViewreportComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    MaterialModule,
    SharedModule,
    AuthModule,
    FullLayoutModule,
    HomeModule,
    SettingsModule,
    ConfigurationModule,
    BatchModule,
    ReportModule,
    FormsModule,
    MatCardModule,
    SocketIoModule.forRoot(config)
  ],
  providers: [{
    provide: HTTP_INTERCEPTORS,
    useClass: TokenInterceptor,
    multi: true
  }],
  bootstrap: [AppComponent]
})
export class AppModule { }
