import { Injectable } from '@angular/core';
import { saveAs } from 'file-saver';
import { downloadsop } from './settings.model';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { RECALCULATE } from 'src/app/config/api.config';
const productivity = [
  {
    t_egg_expected: 'TE%-T',
    t_egg_max_score: 'TE%-S'
  },
  {
    h_egg_expected: 'SE%-T',
    h_egg_max_score: 'SE%-S'
  },
  {
    cumulative_t_egg_expected: 'C-TE%-T',
    cumulative_t_egg_max_score: 'C-TE%-S'
  },
  {
    cumulative_h_egg_expected: 'C-SE%-T',
    cumulative_h_egg_max_score: 'C-SE%-S'
  },
  {
    egg_weight_expected: 'E-W-T',
    egg_weight_max_score: 'E-W-S'
  }
];
const prodNdMortConstants = {
  age: 'WEEK',
};
const growthConstants = {
  age: 'WEEK',
  f_expected_feed: 'F-FEED',
  m_expected_feed: 'M-FEED'
};
const growth = [
  {
    f_expected_weight_gain: 'F-WG-T',
    f_weight_gain_max_score: 'F-WG-S'
  },
  {
    m_expected_weight_gain: 'M-WG-T',
    m_weight_gain_max_score: 'M-WG-S'
  },
  {
    f_expected_weight: 'F-W-T',
    f_weight_max_score: 'F-W-S'
  },
  {
    m_expected_weight: 'M-W-T',
    m_weight_max_score: 'M-W-S'
  }
];
const mortality = [
  {
    f_mortality_expected: 'F-M-T',
    f_mortality_max_score: 'F-M-S'
  },
  {
    m_mortality_expected: 'M-M-T',
    m_mortality_max_score: 'M-M-S'
  },
  {
    cumulative_f_mortality_expected: 'F-CM-T',
    cumulative_f_mortality_max_score: 'F-CM-S'
  },
  {
    cumulative_m_mortality_expected: 'M-CM-T',
    cumulative_m_mortality_max_score: 'M-CM-S'
  }
];
@Injectable({
  providedIn: 'root'
})
export class ScoreService {
  productivity = productivity;
  growth = growth;
  mortality = mortality;
  prodNdMortConstants = prodNdMortConstants;
  growthConstants = growthConstants;
  private BASE_URL = environment.BASE_URL;
  constructor(
    private http: HttpClient
  ) {

  }
  downloadCsv(objects: any[], fileName: string): void {
    const csvData = this.convertToCsv(objects);
    const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8' });
    saveAs(blob, fileName + '.csv');
  }
  private convertToCsv(objects: any[]): string {
    const separator = ',';
    const keys = Object.keys(objects[0]);
    const csvHeader = keys.join(separator);
    const csvRows = objects.map(object => {
      return keys.map(key => object[key]).join(separator);
    });
    return csvHeader + '\n' + csvRows.join('\n');
  }
  getThresholdNdMaxScore(objects: any[]): downloadsop {
    var obj = this.groupByThreshold(objects);
    return { data: obj, max: this.findMaxLength(obj) };
  }
  groupByThreshold(objects: any[]): any {
    return objects.reduce((acc, obj) => {
      const city = obj['age'];
      if (!acc[city]) {
        acc[city] = [];
      }
      acc[city].push(obj);
      return acc;
    }, {});
  }
  findMaxLength(objects: any): any {
    var listOfLists: any[][] = [];
    Object.keys(objects).forEach(key => {
      listOfLists.push(objects[key]);
    });
    return listOfLists.reduce((acc, curr) => {
      return curr.length > acc.length ? curr : acc;
    }, []).length;
  }
  buildCsvObject(objects: any, max: number, varobject: any[], variableobj2: any): any[] {
    var csvlist: any[] = [];
    Object.keys(objects).forEach(key => {
      var csvobject: any = {};
      Object.keys(variableobj2).forEach(key2 => {
        var subobj: any[] = [];
        subobj = objects[key];
        csvobject[variableobj2[key2]] = subobj[0][key2];
      });
      varobject.filter((a: any) => {
        for (var i = 0; i < max; i++) {
          var sepobj: any = [];
          sepobj = objects[key];
          Object.keys(a).forEach(key2 => {
            csvobject[a[key2] + (i + 1)] = sepobj[i] ? (sepobj[i][key2] != '10101.00' ? sepobj[i][key2] : 'max') : '';
          });
        }
      });
      csvlist.push(csvobject);
    });
    return csvlist;
  }


  // score recalculate 
  public recalculateGrowth(data: any): Observable<any> {
    const shed_id = JSON.parse(localStorage.getItem('tank') || '')?.id;
    const batch_id = localStorage.getItem('batch_id') ? JSON.parse(localStorage.getItem('batch_id') || '') : null;
    const reqObj = {
      shed_id, batch_id, ...data
    };
    return this.http.post(`${this.BASE_URL}${RECALCULATE.GORWTH_SCORE}`, reqObj);
  }
  public recalculateProductivity(data: any): Observable<any> {
    const shed_id = JSON.parse(localStorage.getItem('tank') || '')?.id;
    const batch_id = localStorage.getItem('batch_id') ? JSON.parse(localStorage.getItem('batch_id') || '') : null;
    const reqObj = {
      shed_id, batch_id, ...data
    };
    return this.http.post(`${this.BASE_URL}${RECALCULATE.PRODUCTIVITY_SCORE}`, reqObj);
  }

  public recalculateMortality(data: any): Observable<any> {
    const shed_id = JSON.parse(localStorage.getItem('tank') || '')?.id;
    const batch_id = localStorage.getItem('batch_id') ? JSON.parse(localStorage.getItem('batch_id') || '') : null;
    const reqObj = {
      shed_id, batch_id, ...data
    };
    return this.http.post(`${this.BASE_URL}${RECALCULATE.MORTALITY_SCORE}`, reqObj);
  }

  // Get Recalculation Status
  public getRecalGrowthStatus(): Observable<any> {
    const shed_id = JSON.parse(localStorage.getItem('tank') || '')?.id;
    return this.http.get(`${this.BASE_URL}${RECALCULATE.GROWTH_RECAL_STATUS}/${shed_id}`);
  }

  public getRecalProductivityStatus(): Observable<any> {
    const shed_id = JSON.parse(localStorage.getItem('tank') || '')?.id;
    return this.http.get(`${this.BASE_URL}${RECALCULATE.PRODUCTIVITY_RECAL_STATUS}/${shed_id}`);
  }

  public getRecalMortalityStatus(): Observable<any> {
    const shed_id = JSON.parse(localStorage.getItem('tank') || '')?.id;
    return this.http.get(`${this.BASE_URL}${RECALCULATE.MORTALITY_RECAL_STATUS}/${shed_id}`);
  }
}
