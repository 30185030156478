<!-- Loader wrapper -->
<div style="height: 100%;width: 100%;" [ngStyle]="start_loader ? {'display': 'initial'} :{'display': 'none'}">
    <div class="loader-wrapper">
        <!-- Loader circle -->
        <div class="loader"></div>
    </div>
</div>

<div style="height: 98%;">
    <div class="top-bar">
        <app-top-bar></app-top-bar>
    </div>
    <div class="m-p-1" fxLayout="column" fxLayoutGap="10" style="height: 90%;">
        <mat-card style="background-color: #166DA6;color: white;overflow-y: hidden;" fxLayout="row"
            fxLayoutAlign="space-between center">
            <div fxFlex="80" class="tab-container">
                <mat-tab-group (selectedTabChange)="selectTab($event);tabGroupContent.selectedIndex = current_tab"
                    [selectedIndex]="current_tab">
                    <ng-container *ngFor="let tab of tabs;let i = index">
                        <mat-tab [label]="'home_page.tab_menu.'+tab.label | translate |titlecase" *ngIf="tabs[i].enable"></mat-tab>
                    </ng-container>
                </mat-tab-group>
            </div>

            <div fxLayout="row" fxLayoutGap="10">
                <div>
                    <button fxShow.gt-sm fxHide.lt-sm (click)="emitCreateButton();"
                        *ngIf="is_hide_button && (access?.create && !access?.view)" mat-stroked-button
                        class="add-button grow" fxLayout="row">
                        <mat-icon class="add-icon">add_circle</mat-icon>

                        <span>
                            <span>{{'button.create' | translate | uppercase}}</span>
                            <span fxHide.lt-sm>
                                {{'home_page.tab_menu.'+button_label | translate | uppercase}}
                            </span>
                        </span>

                    </button>
                    <div fxHide.gt-sm fxHide.sm fxShow.lt-sm (click)="emitCreateButton();"
                        *ngIf="is_hide_button && (access?.create && !access?.view)" class="add-button grow">
                        <mat-icon class="add-icon">add_circle</mat-icon>
                    </div>
                </div>

                <div>
                    <button fxShow.gt-sm fxHide.lt-sm (click)="openAlertSettingPopUp();"
                        *ngIf="is_hide_button && (access?.create && !access?.view) && (button_label == 'company' && user_data.user_role ==1)"
                        mat-stroked-button class="add-button grow" fxLayout="row">
                        <mat-icon>campaign</mat-icon>

                        <span fxHide.lt-sm>
                            {{'alert_settings.alert_settings'|translate|uppercase}}
                        </span>
                    </button>
                    <div fxHide.gt-sm fxHide.sm fxShow.lt-sm (click)="openAlertSettingPopUp();"
                        *ngIf="is_hide_button && (access?.create && !access?.view) && (button_label == 'company' && user_data.user_role ==1)"
                        class="add-button grow">
                        <mat-icon style="background: white;
                        border-radius: 50%;">campaign</mat-icon>
                    </div>
                </div>



            </div>
        </mat-card>



        <div class="tab-content-container" style="height: 100%;overflow: auto;">
            <mat-tab-group style="height: 100%;" [selectedIndex]="current_tab" #tabGroupContent>
                <ng-container *ngFor="let tab of tabs">
                    <mat-tab *ngIf="tab.privilege_tab">
                        <ng-template matTabContent>
                            <app-privilege></app-privilege>
                        </ng-template>
                    </mat-tab>
                    <mat-tab *ngIf="tab.user_tab">
                        <ng-template matTabContent>
                            <app-user></app-user>
                        </ng-template>
                    </mat-tab>

                    <mat-tab *ngIf="tab.company_tab">
                        <ng-template matTabContent>
                            <app-company></app-company>
                        </ng-template>
                    </mat-tab>

                    <!-- <mat-tab *ngIf="tab.farm_tab">
                        <ng-template matTabContent>
                            <app-farm></app-farm>
                        </ng-template>
                    </mat-tab> -->

                    <mat-tab *ngIf="tab.tank_tab">
                        <ng-template matTabContent>
                            <app-tank></app-tank>
                        </ng-template>
                    </mat-tab>
                </ng-container>
            </mat-tab-group>
        </div>
    </div>
</div>