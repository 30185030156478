import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class LayoutService {


  constructor() { }

  public isToggle!: boolean;
  public current_tab: any = localStorage.getItem('current_tab') || 0;
  public subject = new Subject<any>();
  private toggleServices = new BehaviorSubject(this.isToggle);
  private homeTabServices = new BehaviorSubject(this.current_tab);
  private homeTabButtonServices = new BehaviorSubject('');
  private hideCreateButtonServices = new BehaviorSubject(localStorage.getItem('show_create_button') ? JSON.parse(localStorage.getItem("show_create_button") || "") : true);
  public toggle = this.toggleServices.asObservable();
  public home_tab = this.homeTabServices.asObservable();
  public home_tab_button = this.homeTabButtonServices.asObservable();
  public hide_home_tab_button = this.hideCreateButtonServices.asObservable();
  /*  SIDE NAV TOGGLE*/
  sideNavToggle(value: boolean) {
    this.toggleServices.next(value);
  }

  // Switch Between Home Tab
  switchHomeTab(index: any) {
    localStorage.setItem('current_tab', index);
    this.homeTabServices.next(index);
  }

  showCreateButton(show: boolean) {
    localStorage.setItem("show_create_button", JSON.stringify(show))
    this.hideCreateButtonServices.next(show);
  }
}
