import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { MORTALITY } from 'src/app/config/api.config';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MortalityService {


  private BASE_URL = environment.BASE_URL;
  constructor(private http: HttpClient) { }


  public postMortality(data: any): Observable<any> {
    return this.http.post(`${this.BASE_URL}${MORTALITY.CREATE_MORTALITY}`, data);
  }
  public updateMortality(data: any): Observable<any> {
    return this.http.put(`${this.BASE_URL}${MORTALITY.CREATE_MORTALITY}`, data);
  }
  public deleteMortality(data: any): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: data,
    };
    return this.http.delete(`${this.BASE_URL}${MORTALITY.CREATE_MORTALITY}`, options);
  }
  public postMortalityBulkUpload(data: any): Observable<any> {
    const shed_id = JSON.parse(localStorage.getItem('tank') || '')?.id;
    return this.http.post(`${this.BASE_URL}${MORTALITY.BULKUPLOAD}/${shed_id}`, data);
  }
  public getMortalityScore() {
    const page = 100;
    const shed_id = JSON.parse(localStorage.getItem('tank') || '')?.id;
    return this.http.get(`${this.BASE_URL}${MORTALITY.GET_MORTALITY}/${shed_id}?perpage=${page}`);
  }
}
