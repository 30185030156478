import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MaterialModule } from 'src/shared/material/material.module';
import { SettingsRoutingModule } from './settings-routing.module';
import { AlertsComponent } from './alerts/alerts.component';
import { SensorAlarmComponent } from './sensor-alarm/sensor-alarm.component';
import { GrowthScoreComponent } from './growth-score/growth-score.component';
import { ProductivityScoreComponent } from './productivity-score/productivity-score.component';
import { MortalityScoreComponent } from './mortality-score/mortality-score.component';
import { EnvironmentalScoreComponent } from './environmental-score/environmental-score.component';
import { CreateSensorAlarmComponent } from './create-sensor-alarm/create-sensor-alarm.component';
import { CreateGrowthScoreComponent } from './create-growth-score/create-growth-score.component';
import { CreateProductivityScoreComponent } from './create-productivity-score/create-productivity-score.component';
import { CreateMortalityScoreComponent } from './create-mortality-score/create-mortality-score.component';
import { CreateEnvironmentalScoreComponent } from './create-environmental-score/create-environmental-score.component';
import { EquipmentComponent } from './equipment/equipment.component';
import { FullLayoutModule } from '../layout/layout.module';
import { TranslateModule } from '@ngx-translate/core';
import { ListScoreAlertsComponent } from './list-score-alerts/list-score-alerts.component';
import { SharedModule } from 'src/shared/shared.module';


@NgModule({
  declarations: [
    AlertsComponent,
    SensorAlarmComponent,
    GrowthScoreComponent,
    ProductivityScoreComponent,
    MortalityScoreComponent,
    EnvironmentalScoreComponent,
    CreateSensorAlarmComponent,
    CreateGrowthScoreComponent,
    CreateProductivityScoreComponent,
    CreateMortalityScoreComponent,
    CreateEnvironmentalScoreComponent,
    EquipmentComponent,
    ListScoreAlertsComponent,

  ],
  imports: [
    CommonModule,
    TranslateModule.forChild(),
    SettingsRoutingModule,
    MaterialModule,
    FullLayoutModule,
    SharedModule
  ]
})
export class SettingsModule { }
