import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BATCH } from 'src/app/config/api.config';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})

export class BatchService {

  private BASE_URL = environment.BASE_URL;
  constructor(private http: HttpClient) {
  }
  public active_batch_data: any = null;

  public getMedication(): Observable<any> {
    const tank_id = JSON.parse(localStorage.getItem('tank') || '')?.id;
    return this.http.get(`${this.BASE_URL}${BATCH.GET_MEDICATION}/${tank_id}`,);
  }

  public batchAgeCalculate(batch_data: batch_interface) {
    let batch_start_date = new Date(batch_data.start_date);
    let millisecondsPerDay = 24 * 60 * 60 * 1000;
    let date1Ms = batch_start_date.getTime();
    let date2Ms = new Date(batch_data.current_date).getTime();
    let differenceMs = Math.abs(date1Ms - date2Ms);
    let days_to_add = Math.floor(differenceMs / millisecondsPerDay);
    let days_past = (parseInt(batch_data.age_weeks) * 7) + parseInt(batch_data.age_days);
    let totaldays_past = days_to_add + days_past;
    let weeks = Math.floor((totaldays_past - 1) / 7);
    let days = ((totaldays_past - 1) % 7) + 1;
    let current_age = `${weeks}.${days}`;
    console.log("current_age", current_age);
    console.log("date2Ms", date2Ms)
    return Number(current_age);
  }
  public getMedicationSchedule(included_ids?: any): Observable<any> {
    const tank_id = JSON.parse(localStorage.getItem('tank') || '')?.id;
    const batch_id = localStorage.getItem('batch_id') ? JSON.parse(localStorage.getItem('batch_id') || '') : null;
    let params = new HttpParams();
    params = params.append('tank_id', tank_id);
    params = params.append('batch_id', batch_id);
    params = params.append('exceptional_ids', included_ids ? `[${included_ids?.toString()}]` : 'null');
    return this.http.get(`${this.BASE_URL}${BATCH.GET_MEDICATION_SCHEDULE}/`, { params });
  }
  public getAllMedicationSchedule(): Observable<any> {
    const tank_id = JSON.parse(localStorage.getItem('tank') || '')?.id;
    const batch_id = localStorage.getItem('batch_id') ? JSON.parse(localStorage.getItem('batch_id') || '') : null;
    let params = new HttpParams();
    params = params.append('tank_id', tank_id);
    params = params.append('batch_id', batch_id);
    return this.http.get(`${this.BASE_URL}${BATCH.GET_ALL_MEDICATION_SCHEDULE}/`, { params });
  }
  public submitMedication(data: any): Observable<any> {
    return this.http.post(`${this.BASE_URL}${BATCH.SUBMIT_MEDICATION}`, data);
  }
  public addNewMedication(data: any): Observable<any> {
    return this.http.post(`${this.BASE_URL}${BATCH.ADD_MEDICATION}`, data);
  }
  public deleteMedication(medication_id: number): Observable<any> {
    return this.http.delete(`${this.BASE_URL}${BATCH.DELETE_MEDICATION}/${medication_id}`);
  }
  public medicationScheduleStatus(data: object): Observable<any> {
    return this.http.post(`${this.BASE_URL}${BATCH.SCHEDULE_STATUS}`, data);
  }
  public updateMedication(data: any, id: any): Observable<any> {
    return this.http.put(`${this.BASE_URL}${BATCH.SUBMIT_MEDICATION}/${id}`, data);
  }
  public deleteSchedule(id: any): Observable<any> {
    return this.http.delete(`${this.BASE_URL}${BATCH.SUBMIT_MEDICATION}/${id}`);
  }


  public getBatchList(status?: any): Observable<any> {

    let params = new HttpParams();
    if (status) {
      params = params.append('status', status);
    }
    const tank_id = JSON.parse(localStorage.getItem('tank') || '')?.id;
    return this.http.get(`${this.BASE_URL}${BATCH.GET_ACTIVE_BATCH_USING_TANKID}/${tank_id}`, { params });
  }
  public getActiveBatch(): Observable<any> {
    const status = 'active';
    let params = new HttpParams();
    if (status) {
      params = params.append('status', status);
    }
    const tank_id = localStorage.getItem('tank') ? JSON.parse(localStorage.getItem('tank') || '')?.id : null;
    return this.http.get(`${this.BASE_URL}${BATCH.GET_ACTIVE_BATCH_USING_TANKID}/${tank_id}`, { params });
  }
  public getCurrentBatch(): Observable<any> {
    const batch_id = localStorage.getItem('batch_id') ? JSON.parse(localStorage.getItem('batch_id') || '') : undefined;
    return this.http.get(`${this.BASE_URL}${BATCH.CURRENT_BATCH}/${batch_id}`);
  }
  public getCurrentBatchById(batch_id: any): Observable<any> {
    return this.http.get(`${this.BASE_URL}${BATCH.CURRENT_BATCH}/${batch_id}`);
  }

  //BATCH POST REQUEST
  public batchStart(data: any): Observable<any> {
    data.tank_id = JSON.parse(localStorage.getItem('tank') || '')?.id;
    return this.http.post(`${this.BASE_URL}${BATCH.BATCH}`, data);
  }

  //Batch PUT REQUEST
  public batchEnd(data: any, batch_id: any): Observable<any> {
    data.tank_id = JSON.parse(localStorage.getItem('tank') || '')?.id;
    return this.http.patch(`${this.BASE_URL}${BATCH.BATCH}/${batch_id}`, data);
  }


  public getBreed(): Observable<any> {
    return this.http.get(`${this.BASE_URL}${BATCH.GET_BREED}`,);
  }


}



export interface batch_interface {
  start_date: any,
  current_date: any,
  age_weeks: any,
  age_days: any,
}
