<form fxLayout="column" fxLayoutGap="5">
    <div (click)="cancel()"
        style="margin-bottom: 5px;display: flex;justify-content: end;position: relative;left: 16px;">
        <mat-icon style="margin-top: -16px;">cancel</mat-icon>
    </div>
    <div fxFlex="20" class="scoreclass" *ngIf="!(data!='')">
        {{'max_score_info'|translate|titlecase}}
    </div>

    <div fxFlex="20" class="scoreclass" *ngIf="(data!='')">
        {{'max_score_info'|translate|titlecase}} ({{data}})
    </div>
</form>