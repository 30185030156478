<mat-toolbar fxLayoutAlign="space-between center">
    <div fxLayoutGap="15" fxLayout="row">
        <div *ngIf="hide_home_tabs" class="toggle-icon" fxFlex="23">
            <img style="height: 1.3rem;" (click)="toggleSideNav(isToggle = !isToggle)"
                src="assets/side-bar/hamburgar.svg" alt="" srcset="">
        </div>

        <img style="height: 2rem;width: 5rem;" src="assets/images/iFRM_logo.png" alt="" />


    </div>


    <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="10">
        <div fxShow.gt-sm fxHide.lt-sm class="profile-info" fxLayout="column" *ngIf="getCompany()!=null"
            style="height: 50px;padding: 8px 0px;" fxLayoutGap="5">
            <div fxFlex="40" fxLayoutAlign="start center" style="font-size: 13px;font-weight: bold;">{{getCompany()}}
            </div>
            <div fxFlex="40" fxLayoutAlign="start center" style="font-size: 12px;font-weight: 300;">
                {{user_data.username}}
            </div>
        </div>
        <div style="color: #166DA6; padding-bottom: 7px;font-weight: 500;" class="header-font" fxLayout="column"
            fxLayoutAlign="start start">

        </div>

        <div>
            <div *ngIf="hide_home_tabs">

                <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="2">
                    <button (click)="redirect()" mat-stroked-button
                        style="background-color: #166DA6;color: white;outline: none;border: none;border-radius: 2px;height: 1.8rem;line-height: 30px;min-width: 100px;">
                        {{'home_page.tab_menu.'+redirect_to| translate|titlecase}}
                    </button>
                    <button *ngIf="redirect_list?.length"
                        style="border-radius: 0px;background-color: #166DA6;color: white;height: 1.8rem;border-radius: 2px;width: 1.5rem;"
                        mat-icon-button [matMenuTriggerFor]="redirectmenu">
                        <mat-icon style="color: white;
                        text-align: center;
                        justify-content: center;
                        align-items: center;
                        display: flex;">arrow_drop_down</mat-icon>
                    </button>
                </div>
                <mat-menu #redirectmenu="matMenu" xPosition="before">
                    <div class="redirect-menu">
                        <ng-container *ngFor="let item of redirect_list ;let i=index">
                            <button mat-menu-item (click)="setRedirect(item.label);">
                                <span>{{'home_page.tab_menu.'+item.label | translate | titlecase}}</span>
                            </button>

                        </ng-container>
                    </div>


                </mat-menu>

            </div>
        </div>

        <div fxLayout="row" fxLayoutGap="10">
            <!-- <ng-container *ngFor="let item of kebabMenu ;let i=index">

                <mat-icon *ngIf="item.icon !='logout'" [matTooltip]="item.label"
                    (click)="item.this[item.functionName]()">{{item.icon}}</mat-icon>
            </ng-container> -->
            <ng-container>
                <mat-icon [matMenuTriggerFor]="lang">language</mat-icon>
            </ng-container>
            <mat-menu #lang="matMenu">
                <button mat-menu-item (click)="languageSelect('en')">English</button>
                <button mat-menu-item (click)="languageSelect('ar')">Arabic</button>
                <button mat-menu-item (click)="languageSelect('ta')">Tamil</button>
                <button mat-menu-item (click)="languageSelect('hi')">Hindi</button>
                <button mat-menu-item (click)="languageSelect('te')">Telugu</button>
            </mat-menu>

        </div>


        <div *ngIf="show_profile">
            <button mat-icon-button [matMenuTriggerFor]="menu">
                <mat-icon>account_circle</mat-icon>
            </button>
            <div class="profile-menu">
                <mat-menu #menu="matMenu">
                    <div style="min-width: 15rem !important;padding: 5px;" (mouseleave)="closeMenu()" fxLayout="column"
                        fxLayoutAlign="center center" fxLayoutGap="7">
                        <div><mat-icon style="font-size: 2.5rem;cursor: initial;">account_circle</mat-icon></div>
                        <div style="font-weight:700">{{user_data.username}}</div>
                        <div style="color: #919191;">{{user_data.user_role ==1 ? 'Super admin'
                            :user_data.user_role ==2 ? 'Management'
                            :user_data.user_role ==3 ? 'Senior manager'
                            :user_data.user_role ==4 ? 'Farm manager'
                            :user_data.user_role ==5 ? 'Shed supervisor'
                            :''}}</div>

                        <div fxLayoutAlign="center center" style="padding: 8px 0px;">
                            <button class="submit grow" mat-stroked-button
                                (click)="logout()">{{'log_out'|translate|uppercase}}</button>

                        </div>
                    </div>


                </mat-menu>
            </div>

        </div>
    </div>
</mat-toolbar>