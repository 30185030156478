<mat-card class="mat-elevation-z6" fxLayout="column" fxLayoutGap="30px" fxLayoutAlign="space-between start"
    style="overflow: inherit !important">
    <div fxFlex="70" fxlayoutgap="12" fxLayout="column" style="width: 100%;padding: 2%;align-items: center;"
        class="formdata">
        <!-- form container for sensor name ,battery and signal strength-->
        <form [formGroup]="sensor_name_group" fxFlex="30" fxlayoutgap="20" style="width:80%" class="widthM"
            fxLayout="column">
            <div fxFlex="30" fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="startcenter"
                style="margin-bottom: 20px;" fxlayoutgap="25">
                <!-- sensor name list -->
                <div fxFlex="33" fxLayout="row">
                    <div style="font-size: 12px" fxFlex="90" class="sensoralarmField maxwidthM">
                        <mat-form-field appearance="outline">
                            <mat-label>{{'sensor_alarm.select_name'|translate|titlecase}}</mat-label>
                            <mat-select formControlName="sensor_name" panelClass="PanelClass" [disabled]="isupdate"
                                (selectionChange)="selectSensor($event.value)">
                                <ng-container *ngFor="let sensor of sensorDataList">
                                    <mat-option [value]="sensor.sensor_id">{{sensor.name}}
                                    </mat-option>
                                </ng-container>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                <!-- signal sterngth level -->
                <div fxFlex="33" fxLayout="row">
                    <div style="font-size: 12px" fxFlex="90" class="sensoralarmField maxwidthM">
                        <mat-form-field appearance="outline">
                            <mat-label>{{'sensor_alarm.signal_strength'|translate|titlecase}}</mat-label>
                            <mat-select formControlName="signal_strength" panelClass="PanelClass" [disabled]="isview"
                                placeholder="{{'sensor_alarm.select_level'|translate|titlecase}}">
                                <ng-container *ngFor="let unit of sensorlevelList">
                                    <mat-option *ngIf="unit.level_name!='0'"
                                        [value]="unit.level_id">{{'sensor_alarm.level'|translate|titlecase}}
                                        {{unit.level_name}}
                                    </mat-option>
                                    <mat-option *ngIf="unit.level_name=='0'"
                                        [value]="unit.level_id">{{'sensor_alarm.no_alerts'|translate|titlecase}}
                                    </mat-option>
                                </ng-container>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                <!-- battery level -->
                <div fxFlex="33" fxLayout="row">
                    <div style="font-size: 12px" fxFlex="90" class="sensoralarmField maxwidthM">
                        <mat-form-field appearance="outline">
                            <mat-label>{{'sensor_alarm.battery'|translate|titlecase}}</mat-label>
                            <mat-select formControlName="battery" panelClass="PanelClass" [disabled]="isview"
                                placeholder="{{'sensor_alarm.select_level'|translate|titlecase}}">
                                <ng-container *ngFor="let unit of sensorlevelList">
                                    <mat-option *ngIf="unit.level_name!='0'"
                                        [value]="unit.level_id">{{'sensor_alarm.level'|translate|titlecase}}
                                        {{unit.level_name}}
                                    </mat-option>
                                    <mat-option *ngIf="unit.level_name=='0'"
                                        [value]="unit.level_id">{{'sensor_alarm.no_alerts'|translate|titlecase}}
                                    </mat-option>
                                </ng-container>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
            </div>
        </form>
        <!-- form group for alert levels and units with single or dual sensor type(temprature and humidity) -->
        <form [formGroup]="sensorGroup" fxFlex="30" fxlayoutgap="20" style="width:80%" class="widthM" fxLayout="column"
            *ngFor="let sensorGroup of addSensorArray.controls ;let i = index;let last =last">
            <div mat-card-title class="labelSA" style="color: #166da6;"
                *ngIf="(unitList[i].name!='' && unitList[i].name!=null)">{{unitList[i].name | titlecase}}</div>
            <!-- select sensor unit -->
            <div fxLayout="row" style="width: 100%;">
                <div fxFlex="50" fxlayoutgap="20" fxLayout="column" style="justify-content: center;" class="maxwidthM">
                    <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="start center"
                        fxLayoutAlign.lt-sm="start start" style="margin-bottom: 20px;">
                        <div mat-card-title class="labelSA" fxFlex="36">{{'sensor_alarm.units'|translate|titlecase}}</div>
                        <div fxFlex="57" fxLayoutGap="20" fxLayout="row" class="widthM">
                            <div style="font-size: 12px" fxFlex="63" class="sensoralarmField maxwidthM">
                                <mat-form-field appearance="outline">
                                    <mat-label>{{'sensor_alarm.select_unit'|translate|titlecase}}</mat-label>
                                    <mat-select [disabled]="isview" formControlName="Units" panelClass="PanelClass">
                                        <ng-container *ngFor="let unit of unitList[i].list">
                                            <mat-option [value]="unit.unit_id">{{unit.unit_name}}
                                            </mat-option>
                                        </ng-container>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div fxLayout="row" fxLayout.lt-sm="column" style="width: 100%;" fxLayoutGap="20">
                <div fxFlex="50" fxLayout="column">
                    <!-- too low section -->
                    <div fxFlex="30" fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="start center"
                        fxLayoutAlign.lt-sm="start start">
                        <div mat-card-title class="labelSA" fxFlex="38">{{'sensor_alarm.too_low'|translate|titlecase}}
                        </div>
                        <div fxFlex="63" fxLayoutGap="20" fxLayout="row" class="widthM">
                            <!-- set the lowest value for the alarm -->
                            <div style="font-size: 12px" fxFlex="63" class="sensoralarmField maxwidthM">
                                <mat-form-field appearance="outline" [ngStyle]="isview ? {'pointer-events':'none'}:{}">
                                    <mat-label>{{'sensor_alarm.value'|translate|titlecase}}</mat-label>
                                    <input matInput formControlName="too_low_value" type="text"
                                        onkeypress="return (event.charCode >= 48 && event.charCode <= 57 )|| event.charCode ==46 "
                                        autocomplete="new-password" [readonly]="isview">
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                    <!-- set alarm level for too low -->
                    <div fxFlex="30" fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="start center"
                        fxLayoutAlign.lt-sm="start start">
                        <div fxFlex="37" class="labledata"></div>
                        <div fxFlex="63" fxLayoutGap="20" fxLayout="row" class="widthM">
                            <div style="font-size: 12px" fxFlex="63" class="sensoralarmField maxwidthM">
                                <mat-form-field appearance="outline">
                                    <mat-label>{{'sensor_alarm.alert_level'|translate|titlecase}}</mat-label>
                                    <mat-select formControlName="too_low_alert_level" panelClass="PanelClass"
                                        [disabled]="isview" placeholder="{{'sensor_alarm.select_level'|translate|titlecase}}">
                                        <ng-container *ngFor="let unit of sensorlevelList">
                                            <mat-option *ngIf="unit.level_name!='0'"
                                                [value]="unit.level_id">{{'sensor_alarm.level'|translate|titlecase}}
                                                {{unit.level_name}}
                                            </mat-option>
                                            <mat-option *ngIf="unit.level_name=='0'"
                                                [value]="unit.level_id">{{'sensor_alarm.no_alerts'|translate|titlecase}}
                                            </mat-option>
                                        </ng-container>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                    <!-- low section  -->
                    <div fxFlex="30" fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="start center"
                        fxLayoutAlign.lt-sm="start start">
                        <div mat-card-title class="labelSA" fxFlex="38">{{'sensor_alarm.low'|translate|titlecase}}</div>
                        <div fxFlex="63" fxLayoutGap="20" fxLayout="row" class="widthM">
                            <!-- set the low value for the alarm -->
                            <div style="font-size: 12px" fxFlex="63" class="sensoralarmField maxwidthM">
                                <mat-form-field appearance="outline" [ngStyle]="isview ? {'pointer-events':'none'}:{}">
                                    <mat-label>{{'sensor_alarm.value'|translate|titlecase}}</mat-label>
                                    <input matInput formControlName="low_value" type="text"
                                        onkeypress="return (event.charCode >= 48 && event.charCode <= 57 )|| event.charCode ==46 "
                                        autocomplete="new-password" [readonly]="isview">
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                    <!-- set alarm level for low -->
                    <div fxFlex="30" fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="start center"
                        fxLayoutAlign.lt-sm="start start">
                        <div fxFlex="37" class="labledata"></div>
                        <div fxFlex="63" fxLayoutGap="20" fxLayout="row" class="widthM">
                            <div style="font-size: 12px" fxFlex="63" class="sensoralarmField maxwidthM">
                                <mat-form-field appearance="outline">
                                    <mat-label>{{'sensor_alarm.alert_level'|translate|titlecase}}</mat-label>
                                    <mat-select formControlName="low_alert_level" panelClass="PanelClass belowscreen"
                                        [disabled]="isview" placeholder="{{'sensor_alarm.select_level'|translate|titlecase}}">
                                        <ng-container *ngFor="let unit of sensorlevelList">
                                            <mat-option *ngIf="unit.level_name!='0'"
                                                [value]="unit.level_id">{{'sensor_alarm.level'|translate|titlecase}}
                                                {{unit.level_name}}
                                            </mat-option>
                                            <mat-option *ngIf="unit.level_name=='0'"
                                                [value]="unit.level_id">{{'sensor_alarm.no_alerts'|translate|titlecase}}
                                            </mat-option>
                                        </ng-container>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                </div>
                <mat-divider [vertical]="true" class="hide"></mat-divider>
                <!-- high section -->
                <div fxFlex="50" fxLayout="column" style="padding-left: 2%;">
                    <div fxFlex="30" fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="start center"
                        fxLayoutAlign.lt-sm="start start">
                        <div mat-card-title class="labelSA" fxFlex="38">{{'sensor_alarm.high'|translate|titlecase}}
                        </div>
                        <div fxFlex="63" fxLayoutGap="20" fxLayout="row" class="widthM">
                            <div style="font-size: 12px" fxFlex="63" class="sensoralarmField maxwidthM">
                                <!-- set the lhigh value for the alarm -->
                                <mat-form-field appearance="outline" [ngStyle]="isview ? {'pointer-events':'none'}:{}">
                                    <mat-label>{{'sensor_alarm.value'|translate|titlecase}}</mat-label>
                                    <input matInput formControlName="high_value" type="text"
                                        onkeypress="return (event.charCode >= 48 && event.charCode <= 57 )|| event.charCode ==46 "
                                        autocomplete="new-password" [readonly]="isview">
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                    <!-- set alarm level for high -->
                    <div fxFlex="30" fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="start center"
                        fxLayoutAlign.lt-sm="start start">
                        <div fxFlex="37" class="labledata"></div>
                        <div fxFlex="63" fxLayoutGap="20" fxLayout="row" class="widthM">
                            <div style="font-size: 12px" fxFlex="63" class="sensoralarmField maxwidthM">
                                <mat-form-field appearance="outline">
                                    <mat-label>{{'sensor_alarm.alert_level'|translate|titlecase}}</mat-label>
                                    <mat-select formControlName="high_alert_level" panelClass="PanelClass"
                                        [disabled]="isview" placeholder="{{'sensor_alarm.select_level'|translate|titlecase}}">
                                        <ng-container *ngFor="let unit of sensorlevelList">
                                            <mat-option *ngIf="unit.level_name!='0'"
                                                [value]="unit.level_id">{{'sensor_alarm.level'|translate|titlecase}}
                                                {{unit.level_name}}
                                            </mat-option>
                                            <mat-option *ngIf="unit.level_name=='0'"
                                                [value]="unit.level_id">{{'sensor_alarm.no_alerts'|translate|titlecase}}
                                            </mat-option>
                                        </ng-container>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                    <!-- too high section -->
                    <div fxFlex="30" fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="start center"
                        fxLayoutAlign.lt-sm="start start">
                        <div mat-card-title class="labelSA" fxFlex="38">{{'sensor_alarm.too_high'|translate|titlecase}}
                        </div>
                        <div fxFlex="63" fxLayoutGap="20" fxLayout="row" class="widthM">
                            <!-- set the highest value for the alarm -->
                            <div style="font-size: 12px" fxFlex="63" class="sensoralarmField maxwidthM">
                                <mat-form-field appearance="outline" [ngStyle]="isview ? {'pointer-events':'none'}:{}">
                                    <mat-label>{{'sensor_alarm.value'|translate|titlecase}}</mat-label>
                                    <input matInput formControlName="too_high_value" type="text"
                                        onkeypress="return (event.charCode >= 48 && event.charCode <= 57 )|| event.charCode ==46 "
                                        autocomplete="new-password" [readonly]="isview">
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                    <!-- set alarm level for too high -->
                    <div fxFlex="30" fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="start center"
                        fxLayoutAlign.lt-sm="start start">
                        <div fxFlex="37" class="labledata"></div>
                        <div fxFlex="63" fxLayoutGap="20" fxLayout="row" class="widthM">
                            <div style="font-size: 12px" fxFlex="63" class="sensoralarmField maxwidthM">
                                <mat-form-field appearance="outline">
                                    <mat-label>{{'sensor_alarm.alert_level'|translate|titlecase}}</mat-label>
                                    <mat-select formControlName="too_high_alert_level"
                                        panelClass="PanelClass belowscreen" [disabled]="isview"
                                        placeholder="{{'sensor_alarm.select_level'|translate|titlecase}}">
                                        <ng-container *ngFor="let unit of sensorlevelList">
                                            <mat-option *ngIf="unit.level_name!='0'"
                                                [value]="unit.level_id">{{'sensor_alarm.level'|translate|titlecase}}
                                                {{unit.level_name}}
                                            </mat-option>
                                            <mat-option *ngIf="unit.level_name=='0'"
                                                [value]="unit.level_id">{{'sensor_alarm.no_alerts'|translate|titlecase}}
                                            </mat-option>
                                        </ng-container>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <mat-divider fxLayout="column" *ngIf="((addSensorArray.controls.length>1)&&(i==0))"
                style="position: relative;margin: 20px 0px; "></mat-divider>
        </form>
    </div>
    <!-- submit button -->
    <!-- while create flow its submit button if its update flow it will show update button 
            and if its view flow its didnt show any submit or update button it only shows back button  -->
    <div fxLayout="row" fxFlex="25" style="width: 100%;" fxLayoutAlign="center end">
        <div fxFlex="100" fxLayoutAlign="center center" fxLayout.lt-sm="column" fxLayoutGap="25"
            fxLayoutAlign.lt-sm="center center" class="buttondiv" style="width: 100%;max-height: 6vh;">
            <button class="submit grow changeview" mat-raised-button style="width: 14%;box-shadow: none;"
                (click)="summit()"
                *ngIf="!isview">{{isupdate?('button.update'|translate|titlecase):'button.submit'|translate|titlecase}}</button>
            <button class="cancel grow changeview" mat-raised-button style="width: 14%;box-shadow: none;"
                (click)="clearData()">{{isview?('button.back'|translate|titlecase):'button.cancel'|translate|titlecase}}</button>
        </div>
    </div>

</mat-card>