import { Component, OnInit, OnDestroy } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Subscription } from 'rxjs';
import { BatchService } from 'src/app/core/services/batch/batch.service';
import { DayEntryService } from 'src/app/core/services/day-entry/day-entry.service';
import { GrowthService } from 'src/app/core/services/growth/growth.service';
import { HomeService } from 'src/app/core/services/home/home.service';

@Component({
  selector: 'app-score',
  templateUrl: './score.component.html',
  styleUrls: ['./score.component.scss']
})
export class ScoreComponent implements OnInit, OnDestroy {
  public subscriptions: Subscription[] = [];
  displayedColumns: string[] = ['s_no', 'Date', 'No.Of Female Live Birds',
    'Avg Female Bird Weight',
    'No.Of Male Live Birds',
    'Avg Male Bird Weight',
    'Total Egg /Day',
    'Total Egg %',
    'Total Egg Score',
    'Selection Egg / Day',
    'Selection Egg %',
    'Selection Egg Score',
    'Cumulative Total Egg',
    'Cumulative Total Egg Score',
    'Cumulative Selection Egg',
    'Cumulative Selection Egg Score',
    'Egg Weight',
    'Egg Weight Score',
    'No of Female Mortality / day',
    'Mortality Female %',
    'Mortality Female Score',
    'No of Male Mortality / day',
    'Mortality Male %',
    'Mortality Male Score',
    'total female birds on batch start date',
    'total male birds on batch start date',
    'Cumulative Female total mortality as on day',
    'Cumulative Mortality Female %',
    'Cumulative Mortality Female Score',
    'Cumulative Male total mortality as on day',
    'Cumulative Mortality Male %',
    'Cumulative Mortality Male Score'];

  public weeklyDisplayColumn: string[] = ['s_no', 'Date', 'Weekly Female Bird Weight', 'Weekly Female Bird Weight Score', 'Weekly Female Bird Weight Gain', 'Weekly Female Bird Weight Gain Score', 'Weekly Male Bird Weight', 'Weekly Male Bird Weight Score', 'Weekly Male Bird Weight Gain', 'Weekly Male Bird Weight Gain Score'];

  public batch_data: any;
  dataSource = new MatTableDataSource([]);
  weeklyDataSource = new MatTableDataSource([]);

  public company_name = localStorage.getItem('company')?.length ? JSON.parse(localStorage.getItem('company') || '')?.name : '--';
  public shed_name = localStorage.getItem('tank')?.length ? JSON.parse(localStorage.getItem('tank') || '')?.name : '--';
  public access: any;
  constructor(
    private day_entry: DayEntryService,
    private batch_service: BatchService,
    private home: HomeService,
    private growth: GrowthService

  ) { this.access = this.home.getAccess(17); }

  ngOnInit(): void {

    this.subscriptions.push(
      this.day_entry.getDayWiseEntry().subscribe((res: any) => {
        if (res?.data?.length) {
          this.dataSource.data = res.data;
        }
      }),
      this.growth.getWeeklyGrowthScore().subscribe((res: any) => {
        if (res?.data?.length) {
          this.weeklyDataSource.data = res.data;
        }
      })
      ,
      this.batch_service.getActiveBatch().subscribe((res: any) => {
        if (res?.data[0]?.batch_id) {
          this.batch_data = res?.data[0];
        }
      })
    );



  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub: Subscription) => sub.unsubscribe());
  }
}
