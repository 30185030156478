import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormArray, FormBuilder, FormControl } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Subscription, interval, mergeMap } from 'rxjs';
import { HomeService } from 'src/app/core/services/home/home.service';
import { SettingsService } from 'src/app/core/services/settings/settings.service';
import { MessageDialogComponent } from 'src/shared/message-dialog/message-dialog.component';

@Component({
  selector: 'app-alerts',
  templateUrl: './alerts.component.html',
  styleUrls: ['./alerts.component.scss']
})
export class AlertsComponent implements OnInit, OnDestroy {
  public subscriptions: Subscription[] = [];
  public current_time: any;
  public alert_form_array: any = new FormArray([]);
  showicon = false;
  showspin = false;
  icon = '';
  public company_name = localStorage.getItem('company')?.length ? JSON.parse(localStorage.getItem('company') || '')?.name : '--';
  public shed_name = localStorage.getItem('tank')?.length ? JSON.parse(localStorage.getItem('tank') || '')?.name : '--';


  public access: any;

  constructor(
    private fb: FormBuilder,
    private route: Router, private SettingsService: SettingsService,
    private dialog: MatDialog,
    private home: HomeService


  ) {
    this.access = this.home.getAccess(19);
  }

  ngOnInit(): void {
    this.syncAlertsLevelFlag(false);
    this.getAlertDatas();
  }
  tabledata: any[] = [];
  getAlertDatas() {
    this.subscriptions.push(
      this.SettingsService.getAlarts().subscribe((res: any) => {
        this.tabledata = res.data;
        this.setalertdata(res.data);
      }, (error: Response) => {
        console.log(error);
      })
    );

  }
  setalertdata(data: any[]) {
    data.reverse();
    this.alert_form_array = new FormArray([]);
    data.filter((c) => {
      this.alert_form_array.push(this.alertsFromAPI(c));
    });

  }
  alertsFromAPI(data: any) {
    return this.fb.group({
      level: new FormControl(data.level_name),
      sms_alert: new FormControl(data.sms),
      ring_alert: new FormControl(data.ring),
      email_alert: new FormControl(data.email),
      whatsapp_alert: new FormControl(data.whatsapp),
      siren_alert: new FormControl(data.local_siren),
    });
  }


  init() {
    this.initFormArray();
  }


  initFormArray() {
    for (let index = 5; index >= 0; index--) {
      this.alert_form_array.push(this.alertsFromFn(index));
    }
  }

  setAlertDefalut(level: any) {

    switch (true) {
      case level == 0:
        return {
          sms: false,
          ring: false,
          email: false,
          whats_app: false,
          siren: false,
        };
      case level == 1:
        return {
          sms: true,
          ring: false,
          email: false,
          whats_app: false,
          siren: true,
        };
      case level == 2:
        return {
          sms: true,
          ring: true,
          email: false,
          whats_app: false,
          siren: true,
        };
      case level == 3:
        return {
          sms: true,
          ring: false,
          email: false,
          whats_app: true,
          siren: true,
        };
      case level == 4:
        return {
          sms: true,
          ring: true,
          email: false,
          whats_app: true,
          siren: true,
        };
      case level == 5:
        return {
          sms: true,
          ring: true,
          email: true,
          whats_app: true,
          siren: true,
        };
      default:
        return {
          sms: false,
          ring: false,
          email: false,
          whats_app: false,
          siren: false,
        };
    }

  }



  alertsFromFn(level: any) {
    const defaultValue = this.setAlertDefalut(level);
    return this.fb.group({
      level: new FormControl(level),
      sms_alert: new FormControl(defaultValue.sms),
      ring_alert: new FormControl(defaultValue.ring),
      email_alert: new FormControl(defaultValue.email),
      whatsapp_alert: new FormControl(defaultValue.whats_app),
      siren_alert: new FormControl(defaultValue.siren),
    });
  }
  savedatafn() {
    const defaultValue = this.setAlertDefalut(0);
    var data = new alertVo();
    data.shed_id = JSON.parse(localStorage.getItem('tank') || '')?.id;
    var level = parseInt(this.alert_form_array.controls[0].value.level);
    data.level_name = level + 1;
    data.sms = defaultValue.sms;
    data.ring = defaultValue.ring;
    data.email = defaultValue.email;
    data.whatsapp = defaultValue.whats_app;
    data.local_siren = defaultValue.siren;
    return data;
  }

  addAlrert() {
    var data = this.savedatafn();
    this.subscriptions.push(
      this.SettingsService.saveAlert(data).subscribe((res: any) => {
        this.getAlertDatas();
        this.alert_form_array.controls = [this.alertsFromFn(this.alert_form_array.length), ...this.alert_form_array.controls];
      }, (error: Response) => {
        console.log(error);
      })
    );

  }
  removeAlert(index: any, i?: any) {

    const config: MatDialogConfig = {
      panelClass: 'dialog-responsive',
      width: '50%',
      minWidth: '50%',
      disableClose: true,
      data: {
        title: `alert level ${i}`,
        isDelete: true
      },
    };
    const openDialog = this.dialog.open(MessageDialogComponent, config);
    openDialog.afterClosed().subscribe((result: any) => {
      if (result) {
        var value = this.tabledata.filter((c) => c.level_name == this.alert_form_array.controls[index].value.level);
        this.subscriptions.push(
          this.SettingsService.deleteAlert(value[0].level_id).subscribe((res: any) => {
            this.getAlertDatas();
            this.alert_form_array.controls = [this.alertsFromFn(this.alert_form_array.length), ...this.alert_form_array.controls];
          }, (error: Response) => {
            console.log(error);
          })

        );

      }
    });



  }


  syncAlertsLevelFlag(flag: boolean) {
    this.showspin = true;
    this.subscriptions.push(

      this.SettingsService.syncAlertsLevelFlag().subscribe((res: any) => {
        if (res.data.length != 0) {
          this.setvalues(res.data[0], flag);
        } else {
          this.showicon = true;
          this.icon = 'dots';
          this.message = 'pending';
          this.showspin = false;
        }
      }, (error: Response) => {
        console.log(error);
        this.showspin = false;
      })
    );

  }
  message = '';
  setvalues(data: any, flag: boolean) {
    this.showicon = true;
    if (data.status == 'Pending') {
      this.icon = 'dots';
      this.message = 'pending';
    } else if (data.status == 'Success') {
      if (!flag && data.is_updated == 0) {
        this.icon = 'sync';
        this.message = 'sync needed';
      } else if (!flag && data.is_updated != 0) {
        this.icon = 'tick';
        this.message = 'success';
      } else if (data.is_updated == 0) {
        this.loopedfunction();
      } else {
        this.icon = 'tick';
        this.message = 'success';
      }
    } else if (data.status == 'Error') {
      this.icon = 'exclamation';
      this.message = data.message;
    } else {
      this.showicon = false;
      this.icon = '';
      this.message = '';
    }
    this.showspin = false;
  }
  loopedfunction() {
    this.showicon = false;
    var i = 0;
    this.showspin = true;
    var subscription = interval(10 * 1000)
      .pipe(
        mergeMap(() => this.SettingsService.syncAlertsLevelFlag())
      )
      .subscribe((res: any) => {
        i++;
        if (res.data.length != 0 && res.data[0].is_updated != 0) {
          this.icon = 'tick';
          this.message = 'success';
          this.showicon = true;
          subscription.closed = true;
          this.showspin = false;
        } else if (i == 5) {
          this.icon = 'sync';
          this.message = 'not updated';
          this.showicon = true;
          subscription.closed = true;
          this.showspin = false;
        }
      });
  }
  syncAlertsLevel() {
    this.showspin = true;
    this.subscriptions.push(
      this.SettingsService.syncAlertsLevel().subscribe((res: any) => {
        setTimeout(() => {
          this.syncUserSettings();
          this.syncAlertsLevelFlag(true);
        }, 3000);
      }, (error: Response) => {
        console.log(error);
        this.showspin = false;
      })
    );

  }
  syncUserSettings() {
    this.subscriptions.push(
      this.SettingsService.syncUserSettings().subscribe((res: any) => {
      }, (error: Response) => {
      })
    );

  }
  onValChange(field: any, index: any, data: any) {
    let params: any = {};
    params[field] = data.checked;
    params['shed_id'] = JSON.parse(localStorage.getItem('tank') || '')?.id;
    var value = this.tabledata.filter((c) => c.level_name == this.alert_form_array.controls[index].value.level);
    this.subscriptions.push(
      this.SettingsService.updateAlert(params, value[0].level_id).subscribe((res: any) => {
        this.syncAlertsLevelFlag(false);
      }, (error: Response) => {
        console.log(error);
      })
    );

  }
  ngOnDestroy(): void {
    this.subscriptions.forEach((sub: Subscription) => sub.unsubscribe());
  }

}
export class alertVo {
  shed_id: any;
  level_name: any;
  sms: any;
  ring: any;
  email: any;
  whatsapp: any;
  local_siren: any;
}
