<div class="m-p-1" fxLayout="column" fxLayoutGap="10">
    <!-- Blue header with sync button  with sync indicator  with company details -->
    <mat-card style="background-color: #166DA6;color: white;height:4.2rem;" fxLayout="row"
        fxLayoutAlign="space-between center">
        <div fxLayout="column">
            <span style="font-size: 13px;color: white;font-weight: bold;line-height: 1;">{{shed_name}}</span>
            <span style="line-height: 1;margin-top: 7px;font-size: 10px;
color: #FFFFFF;">{{company_name}}</span>
        </div>
        <div fxLayout="row" fxLayoutGap="10">
            <mat-spinner strokeWidth="3" [diameter]="30" *ngIf="showspin"></mat-spinner>
            <div style="display: grid;align-content: center;" *ngIf="showicon && !showspin">
                <mat-icon
                    [ngClass]="{alerttick:icon=='tick' ,alertexc: icon=='exclamation',alertdot: (icon=='dots'||icon=='sync')}"
                    [matTooltip]="message" matTooltipPosition="left" svgIcon="{{icon}}"
                    style="display: inline-flex;"></mat-icon>
            </div>
            <button *ngIf="access?.create && !access?.view" mat-stroked-button
                style="text-align: center;background-color: white;" fxLayout="row" class="add-button grow"
                (click)="syncAlertsLevel();">
                <span style="color: #166DA6;">{{'alerts.sync'|translate|titlecase}}</span> </button>

        </div>
    </mat-card>
    <!-- container repeats respect to number of alerts -->
    <ng-container *ngFor="let alretGroup of alert_form_array.controls;let i=index">
        <form [formGroup]="alretGroup">
            <mat-card fxLayout="row" fxLayout.lt-sm="column" class="alerts" fxLayoutAlign.sm="space-between center"
                fxLayoutAlign.gt-sm="space-between center" fxLayoutGap.lt-sm="10">
                <!-- level indicator -->
                <div fxFlex="8" class="level" fxLayout.lt-sm="row" fxLayout="column" fxLayoutAlign="start center"
                    fxLayoutGap="10">
                    <span>
                        {{alretGroup.get('level').value == '0' ? ('alerts.no_alert'|translate|titlecase): 'alerts.level'|translate|titlecase
                        }}
                    </span>
                    <span>
                        {{alretGroup.get('level').value == '0' ? '' :'alerts.'+alretGroup.get('level').value |translate|titlecase}}
                    </span>
                </div>
                <!-- sms alart toggle -->
                <div fxLayout.lt-sm="row" fxLayout="column" fxLayoutAlign.lt-sm="space-between center"
                    fxLayoutGap="10px">
                    <span class="alert-label">{{'alerts.sms_alert'|translate|titlecase}}</span>

                    <mat-slide-toggle formControlName="sms_alert" (change)="onValChange('sms',i,$event)"
                        [disabled]="alretGroup.get('level').value == '0' || !access.edit || access.view"
                        color="primary"></mat-slide-toggle>
                </div>
                <!-- ring alart toggle -->
                <div fxLayout.lt-sm="row" fxLayoutAlign.lt-sm="space-between center" fxLayout="column"
                    fxLayoutGap="10px">
                    <span class="alert-label">{{'alerts.ring_alert'|translate|titlecase}}</span>

                    <mat-slide-toggle formControlName="ring_alert" (change)="onValChange('ring',i,$event)"
                        [disabled]="alretGroup.get('level').value == '0' || !access.edit || access.view"
                        color="primary"></mat-slide-toggle>
                </div>
                <!-- Email alart toggle -->
                <div fxLayout.lt-sm="row" fxLayoutAlign.lt-sm="space-between center" fxLayout="column"
                    fxLayoutGap="10px">
                    <span class="alert-label">{{'alerts.email_alert'|translate|titlecase}}</span>

                    <mat-slide-toggle formControlName="email_alert" (change)="onValChange('email',i,$event)"
                        [disabled]="alretGroup.get('level').value == '0' || !access.edit || access.view"
                        color="primary"></mat-slide-toggle>
                </div>
                <!-- whats'App alart toggle -->
                <div fxLayout.lt-sm="row" fxLayoutAlign.lt-sm="space-between center" fxLayout="column"
                    fxLayoutGap="10px">
                    <span class="alert-label">{{'alerts.whatsapp_alert'|translate|titlecase}}</span>

                    <mat-slide-toggle formControlName="whatsapp_alert" (change)="onValChange('whatsapp',i,$event)"
                        [disabled]="alretGroup.get('level').value == '0' || !access.edit || access.view"
                        color="primary"></mat-slide-toggle>
                </div>
                <!-- siren alart toggle -->
                <div fxLayout.lt-sm="row" fxLayoutAlign.lt-sm="space-between center" fxLayout="column"
                    fxLayoutGap="10px">
                    <span class="alert-label">{{'alerts.siren_alert'|translate|titlecase}}</span>

                    <mat-slide-toggle formControlName="siren_alert" (change)="onValChange('local_siren',i,$event)"
                        [disabled]="alretGroup.get('level').value == '0' || !access.edit || access.view"
                        color="primary"></mat-slide-toggle>
                </div>

                <div fxLayout.lt-sm="row" fxLayoutAlign.lt-sm="space-between center" fxLayout="column"
                    fxLayoutGap="10px">
                    <mat-icon *ngIf="alretGroup.get('level').value >=6"
                        (click)="removeAlert(i,alretGroup.get('level').value)"
                        class="remove-icon">do_not_disturb_on</mat-icon>
                </div>

            </mat-card>


        </form>

    </ng-container>

</div>