import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { BatchService } from 'src/app/core/services/batch/batch.service';
import { MessageService } from 'src/app/core/services/message/message.service';

@Component({
  selector: 'app-batch-details-dialog',
  templateUrl: './batch-details-dialog.component.html',
  styleUrls: ['./batch-details-dialog.component.scss']
})
export class BatchDetailsDialogComponent implements OnInit {
  public subscriptions: Subscription[] = [];
  constructor(
    public dialogRef: MatDialogRef<BatchDetailsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: any,
    private service: BatchService, private show_msg: MessageService,
    private translate: TranslateService,
  ) { }
  batchData: any = {}
  public birdtype = localStorage.getItem('tank')?.length ? JSON.parse(localStorage.getItem('tank') || '')?.breedType : '--';
  endcrrDate = new Date()
  ngOnInit(): void {
    const dialogContainer: any = document.getElementsByClassName("mat-dialog-container")[0];
    if (this.dialogData?.isDelete) {
      dialogContainer.style.overflow = "initial";
    } else {
      dialogContainer.style.overflow = "auto";
    }
    this.batchData = this.dialogData?.batchData;
    if (this.batchData.end_date) {
      this.endbatch()
      this.endbatchboo = true
      this.endcrrDate = new Date(this.batchData.end_date)
    } else {
      this.weekend = this.batchData.start_age_week;
      this.dayend = this.batchData.start_age_days;
      this.endcrrDate = new Date(this.batchData.start_date)
      this.endbatch()
      this.endbatchboo = false
    }

    this.translate.get('batch.Male count').subscribe((translation) => {
      let element = document.getElementById("count")
      if (element) {
        element.innerText = translation;
      }
    })

  }
  endbatchboo: boolean = false;
  batchend: any = {}
  weekend = '';
  dayend = ''
  endbatch() {
    this.subscriptions.push(
      this.service.getCurrentBatchById(this.batchData.batch_id).subscribe((res: any) => {
        if (res) {
          this.batchend = res;
          this.endcrrDate = new Date(res.dated_on)
          res.dated_age.toString().split('.')[0] ? this.weekend = res.dated_age.toString().split('.')[0] : 0;
          res.dated_age.toString().split('.')[1] ? this.dayend = res.dated_age.toString().split('.')[1] : 0;
        }
      }, (error: Response) => {

      })
    );
  }
  cancel() {
    this.dialogRef.close();
  }

}
