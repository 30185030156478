import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { DashboardAPIService } from 'src/app/core/services/dashboardAPI/dashboard-api.service';
import { MessageService } from 'src/app/core/services/message/message.service';
import { SettingsService } from 'src/app/core/services/settings/settings.service';

@Component({
  selector: 'app-add-sensor',
  templateUrl: './add-sensor.component.html',
  styleUrls: ['./add-sensor.component.scss']
})
export class AddSensorComponent implements OnInit, OnDestroy {
  public subscriptions: Subscription[] = [];
  public current_time: any;
  public company_name = localStorage.getItem('company')?.length ? JSON.parse(localStorage.getItem('company') || '')?.name : '--';
  public tank_name = localStorage.getItem('tank')?.length ? JSON.parse(localStorage.getItem('tank') || '')?.name : '--';

  public addSensorArray: any = new FormArray([]);
  constructor(private fb: FormBuilder, private dashBoardAPI: DashboardAPIService,
    private route: Router, private SettingsService: SettingsService, private msg: MessageService) { }
  updatedata: any;
  isupdate: boolean = false;
  sensortypeList: any[] = [];
  ngOnInit(): void {
    this.getsensortype();
    var json = localStorage.getItem('sensor');
    if (json != "" && json != null) this.updatedata = JSON.parse(json);
    if (this.updatedata == '' || this.updatedata == undefined) this.addSensorArray.push(this.sensorFromGroupFn());
    else {
      this.addSensorArray.push(this.sensorFromGroupFn_Update());
      this.isupdate = true;
    }
  }
  getsensortype() {
    this.subscriptions.push(
      this.SettingsService.getSensorType().subscribe((res: any) => {
        res.data.sort((a: any, b: any) => a.name.localeCompare(b.name));;
        this.sensortypeList = res.data
        console.log(this.sensortypeList);

        this.dashBoardAPI.getProducts().subscribe(res1 => {
          this.sensortypeList.forEach((sensor: any) => {
            res1.forEach((r: any) => {
              if (sensor.name == r.product_name)
                sensor.product_code = r.product_code;
            });
          });
          console.log(this.sensortypeList);

        });


      }, (error: Response) => {
      })
    );


  }
  PatchProductCode(i: any, product_code: any) {
    console.log(i, product_code);

    this.addSensorArray.at(i).patchValue({
      product_code: product_code
    });
  }
  redirect() {
    localStorage.removeItem('sensor');
    this.route.navigate(['configuration/sensor']);
  }
  sensorFromGroupFn_Update() {

    return this.fb.group({
      iot_data_tag: new FormControl(this.updatedata.sensor_type_id, [Validators.required]),
      name: new FormControl(this.updatedata.name, [Validators.required]),
      device_id: new FormControl(this.updatedata.device_id, [Validators.required]),
      product_code: new FormControl(this.updatedata.product_code, [Validators.required]),
      installed_location: new FormControl(this.updatedata.location, [Validators.required]),
      installed_ulocation: new FormControl(this.updatedata.ulocation, [Validators.required]),
    });
  }

  sensorFromGroupFn() {

    return this.fb.group({
      iot_data_tag: new FormControl('', [Validators.required]),
      name: new FormControl('', [Validators.required]),
      device_id: new FormControl('', [Validators.required]),
      product_code: new FormControl('', [Validators.required]),
      installed_location: new FormControl('', [Validators.required]),
      installed_ulocation: new FormControl('', [Validators.required]),
    });

  }

  addSensor() {
    this.addSensorArray.push(this.sensorFromGroupFn());
    var div: any = document.getElementById('sensor_scroll_end');
    setTimeout(() => {
      div.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
    }, 150);


  }
  removeSensor(index: any) {

    this.addSensorArray.removeAt(index);

  }

  showFormArrayWarning(form_array: any) {
    let index = 1;
    form_array.controls.forEach((form_group: FormGroup, i: any) => {
      Object.entries(form_group.controls).forEach(([key, value]) => {
        if (value.invalid && !value.value?.length && index) {
          index = 0;
          this.msg.errorSnackBar(`${key.replace(/_/g, " ")}`);
        }
      });
    });

  }
  submit() {
    if (this.addSensorArray.invalid) {
      this.showFormArrayWarning(this.addSensorArray);
      return;

    }
    if (this.isupdate) this.update();
    else this.save();
  }
  save() {
    var cumulative = this.setData();
    this.subscriptions.push(
      this.SettingsService.saveSensors(cumulative).subscribe((res: any) => {
        this.route.navigate(["configuration/sensor"]);
      }, (error: Response) => {
      })

    );


  }

  keyDownFn() {

  }
  update() {
    var data = this.fieldData(this.addSensorArray.controls[0]);
    this.subscriptions.push(
      this.SettingsService.updateSensor(data, this.updatedata.sensor_id).subscribe((res: any) => {
        this.clearAll();
      }, (error: Response) => {
      })
    );


  }
  setData() {
    var cumulative = new cumulativeSensor();
    var totData: sensors[] = [];
    this.addSensorArray.controls.filter((c: any) => {
      var data = this.fieldData(c);
      totData.push(data);
    });
    cumulative.sensor_sop = totData;
    return cumulative;
  }
  fieldData(c: any) {
    var data = new sensors();
    data.device_id = c.value.device_id;
    data.product_code = c.value.product_code;
    data.location = c.value.installed_location;
    data.ulocation = c.value.installed_ulocation;
    data.name = c.value.name;
    data.tank_id = JSON.parse(localStorage.getItem('tank') || '')?.id;
    data.sensor_type_id = c.value.iot_data_tag;
    return data;
  }
  clearAll() {
    this.addSensorArray.reset();
    this.redirect();
    // this.addSensorArray.controls.forEach((form_group: FormGroup) => {
    //   Object.entries(form_group.controls).forEach(([key, value]) => {
    //     if (key !== 'batch_id' && key !== "tank_id") {
    //       form_group.controls[`${key}`].reset();
    //     }
    //   });
    // });
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub: Subscription) => sub.unsubscribe());
  }
}
export class sensors {
  device_id: any;
  product_code: any;
  location: any;
  ulocation: any;
  name: any;
  tank_id: any;
  sensor_type_id: any;
}
export class cumulativeSensor {
  sensor_sop: sensors[] = [];
}
