<div fxLayout="column" fxLayoutGap="40" fxLayoutAlign="center center" style="min-height: 10rem;position: relative;">
    <img class="remove-icon" src="../../assets/images/remove.svg" alt="">
    <div style="color: #000000;font-size: 15px;font-weight: 700;padding-top: 10px;">
        {{'delete_content' |translate|titlecase}}
        {{dialogData?.title}}
        ?
    </div>
    <div fxLayout.lt-sm="column" fxLayout.gt-sm="row" fxLayoutGap="20">
        <button mat-stroked-button class="grow" style="background-color: #e3e3e3;color: black;" (click)="cancel()">
            {{'button.cancel' | translate |titlecase}}</button>
        <button mat-stroked-button class="grow" style="background-color: rgb(230 56 71);font-weight: 600;"
            (click)="deleteFn()">{{'button.delete' |translate |titlecase}}</button>
    </div>
</div>