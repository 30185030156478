import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-message-dialog',
  templateUrl: './message-dialog.component.html',
  styleUrls: ['./message-dialog.component.scss']
})
export class MessageDialogComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<MessageDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: any,
  ) { }

  ngOnInit(): void {

    const dialogContainer: any = document.getElementsByClassName("mat-dialog-container")[0];
    if (this.dialogData?.isDelete) {
      dialogContainer.style.overflow = "initial";
    } else {
      dialogContainer.style.overflow = "auto";
    }
  }
  deleteFn() {
    this.dialogRef.close(true)
  }
  cancel() {
    this.dialogRef.close();
  }
}
