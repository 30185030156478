<!-- -------------------------------------------Blue Bar--------------------------------------->
<mat-card class="m-p-1" style="background-color: #166da6; color: white; height: 4.2rem" fxLayout="row"
  fxLayoutAlign="space-between center">
  <div fxLayout="column" fxHide.lt-sm fxHide.sm fxShow.gt-sm>
    <span style="font-size: 13px; color: white; font-weight: bold; line-height: 1">{{ tank_name }}</span>
    <span style="line-height: 1; margin-top: 7px; font-size: 10px; color: #ffffff">{{company_name
      }}</span>
  </div>
  <div fxLayoutAlign="center center" style="font-size: 13px; color: white; font-weight: bold; line-height: 1">
    <span>{{ "tank_tab." + birdtype | translate | titlecase }}, </span>
    <span>{{
      is_day_entry_table
      ? ("day_entry.current_age" | translate | titlecase)
      : ("day_entry.age" | translate | titlecase)
      }}&nbsp;:&nbsp;</span><span *ngIf="!branchNotStarted" style="font-size: 12px; font-weight: 600">{{
      is_day_entry_table
      ? this.active_batch_data?.current_age
      : current_entry_age
      }}</span><span *ngIf="branchNotStarted" style="font-size: 12px; font-weight: 600">N/A</span>
  </div>

  <div *ngIf="!is_day_entry_table" fxLayoutAlign="center center"
    style="font-size: 13px; color: white; font-weight: bold; line-height: 1">
    <span>{{"day_entry.day_entry_on" | translate | titlecase }}&nbsp;:&nbsp;</span>
    <!-- <span style="font-size: 12px; font-weight: 600">{{
      this.dated_on.value | date : "dd-MM-YYYY"
      }}</span> -->
      <mat-form-field appearance="outline" class="matdate" style="width:140px;top:7px">
        <div fxLayout="row" style="height: 20px !important;">
            <input matInput [matDatepicker]="dayentryDate" [max]="max_date"
                placeholder="Day entry date" [min]="min_date" [(ngModel)]="start_date"
                (dateChange)="DateChange($event)" [readonly]="readonly" style="color:black">
            <mat-datepicker-toggle
                style="position: relative !important; bottom: 12px !important;"
                matIconSuffix [for]="dayentryDate" [disabled]="readonly"></mat-datepicker-toggle>
            <mat-datepicker #dayentryDate></mat-datepicker>
        </div>
    </mat-form-field>
  </div>

  <div *ngIf="is_day_entry_table" fxLayoutAlign="center center">
    <button *ngIf="access?.create && !access?.view" [ngClass]="!branchNotStarted ? 'grow' : 'discursor'"
      mat-stroked-button class="add-button" [disabled]="branchNotStarted" fxLayout="row" (click)="createDayEntry()">
      <mat-icon class="add-icon">add_circle</mat-icon>
      <span>{{ "button.create" | translate | uppercase }}</span>
    </button>
  </div>
</mat-card>
<form [formGroup]="day_entry_from_group" style="height: 83.4%; overflow: auto">
  <!-- --------------------------------Day entry table------------------------------------------->
  <mat-card *ngIf="is_day_entry_table" class="h-100"
    style="box-shadow: 0px 3px 6px #00000029; margin: 0% 1%; width: 98%" fxLayout="column">
    <div *ngIf="is_day_entry_table && !dataSource.data.length">
      <app-common-message [message]="
          branchNotStarted
            ? nostartdata
            : ('day_entry.no_day_entry_msg' | translate | titlecase)
        ">
      </app-common-message>
    </div>

    <!-- <div> -->
    <div style="overflow-y: auto">
      <table class="level-two-table" *ngIf="dataSource.data.length && birdtype != 'broiler'" mat-table
        [dataSource]="dataSource">
        <!--Entry Date Column -->
        <ng-container matColumnDef="Entry Date">
          <th style="display: none" mat-header-cell *matHeaderCellDef colspan="1"></th>
          <td mat-cell *matCellDef="let element; let i = index">
            <a style="
                border-bottom: 2px solid blue;
                color: blue;
                cursor: pointer;
              " (click)="openViewOnly(element)">{{ element.dated_on | date : "dd-MM-YYYY" }}</a>
          </td>
        </ng-container>
        <!--Opeing Stock Female Column -->
        <ng-container matColumnDef="Opeing Stock Female">
          <th mat-header-cell *matHeaderCellDef >
            {{
              birdtype != "shrimp"
              ? ("day_entry.fish" | translate | titlecase)
              : ("day_entry.shrimp" | translate | titlecase)
              }}
          </th>
          <td mat-cell *matCellDef="let element; let i = index">
            {{ element.fish_opening_stock }}
          </td>
        </ng-container>
        
        <ng-container matColumnDef="Closing Stock Female">
          <th mat-header-cell *matHeaderCellDef>
            {{
              birdtype != "shrimp"
              ? ("day_entry.fish" | translate | titlecase)
              : ("day_entry.shrimp" | translate | titlecase)
              }}
          </th>
          <td mat-cell *matCellDef="let element; let i = index">
            {{ element.fish_closing_stock }}
          </td>
        </ng-container>
        
        <!--Avg Column -->
        <ng-container matColumnDef="Avg">
          <th style="display: none" mat-header-cell *matHeaderCellDef colspan="1"></th>
          <td mat-cell *matCellDef="let element; let i = index">
            {{ element.avg_f_weight }}
          </td>
        </ng-container>
        <!--Present Column -->
        <ng-container matColumnDef="Present">
          <th style="display: none" mat-header-cell *matHeaderCellDef colspan="1"></th>
          <td mat-cell *matCellDef="let element; let i = index">
            {{ element.labours_present_count }}
          </td>
        </ng-container>
        <!-- Action Column -->
        <ng-container matColumnDef="Action">
          <th style="display: none" mat-header-cell *matHeaderCellDef>
            {{ "day_entry.action" | translate | titlecase }}
          </th>
          <td mat-cell *matCellDef="let element; let i = index">
            <div style="display: flex; gap: 20%; justify-content: center">
              <mat-icon *ngIf="access?.edit && !access?.view && isEnableEdit(element.dated_on)" style="width: 1.2rem;"
                class="grow" svgIcon="Edit" (click)="editvalue(element)">
              </mat-icon>
              <div *ngIf="i!==0 && i<7" style="width: 1.2rem;" class="grow">
              </div>
              <mat-icon *ngIf="access?.delete && !access?.view && isEnableDelete(element.dated_on)" style="width: 1rem"
                class="grow" svgIcon="Delete" (click)="deleteDayEntry(element)">
              </mat-icon>
            </div>
          </td>
        </ng-container>

        <!--Date Column -->
        <ng-container matColumnDef="Date">
          <th mat-header-cell *matHeaderCellDef colspan="1" rowspan="2">
            {{ "day_entry.date" | translate | titlecase }}
          </th>
        </ng-container>
        <!--Opening Stock Column -->
        <ng-container matColumnDef="Opening Stock" *ngIf="birdtype != 'layer'">
          <th mat-header-cell *matHeaderCellDef colspan="1">
            {{ "day_entry.opening_stock" | translate | titlecase }}
          </th>
        </ng-container>
        <ng-container matColumnDef="Opening Stock" *ngIf="birdtype == 'layer'">
          <th mat-header-cell *matHeaderCellDef colspan="1">
            {{ "day_entry.opening_stock" | translate | titlecase }}
          </th>
        </ng-container>
        <!--Closing Stock Column -->
        <ng-container matColumnDef="Closing Stock" *ngIf="birdtype != 'layer'">
          <th mat-header-cell *matHeaderCellDef colspan="1">
            {{ "day_entry.closing_stock" | translate | titlecase }}
          </th>
        </ng-container>
        <ng-container matColumnDef="Closing Stock" *ngIf="birdtype == 'layer'">
          <th mat-header-cell *matHeaderCellDef colspan="1">
            {{ "day_entry.closing_stock" | translate | titlecase }}
          </th>
        </ng-container>
        
        
        <!--Avg Egg Weight Column -->
        <ng-container matColumnDef="Avg Weight">
          <th mat-header-cell *matHeaderCellDef rowspan="2">
            {{ "day_entry.avg_weight" | translate | titlecase }}
          </th>
        </ng-container>
        <!--Labour Present Column -->
        <ng-container matColumnDef="Labour Present">
          <th mat-header-cell *matHeaderCellDef rowspan="2">
            {{ "day_entry.labour_present" | translate | titlecase }}
          </th>
        </ng-container>
        <!--Main Action Column -->
        <ng-container matColumnDef="Main Action">
          <th mat-header-cell *matHeaderCellDef rowspan="2">
            {{ "day_entry.action" | translate | titlecase }}
          </th>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedMainColumns; sticky: true"></tr>
        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>

      <table class="level-one-table" *ngIf="dataSource.data.length && birdtype == 'broiler'" mat-table
        [dataSource]="dataSource">
        <!--Entry Date Column -->
        <ng-container matColumnDef="Entry Date">
          <th mat-header-cell *matHeaderCellDef>Date</th>
          <td mat-cell *matCellDef="let element; let i = index">
            <a style="
                border-bottom: 2px solid blue;
                color: blue;
                cursor: pointer;
              " (click)="openViewOnly(element)">{{ element.dated_on | date : "dd-MM-YYYY" }}</a>
          </td>
        </ng-container>
        <!--Opening Stock Female Column -->
        <ng-container matColumnDef="Opeing Stock Female">
          <th mat-header-cell *matHeaderCellDef>Opening Stock Birds</th>
          <td mat-cell *matCellDef="let element; let i = index">
            {{ element.fish_opening_stock }}
          </td>
        </ng-container>
        <!--Closing Stock Female Column -->
        <ng-container matColumnDef="Closing Stock Female">
          <th mat-header-cell *matHeaderCellDef>Closing Stock Birds</th>
          <td mat-cell *matCellDef="let element; let i = index">
            {{ element.female_closing_stock }}
          </td>
        </ng-container>

        <!--Present Column -->
        <ng-container matColumnDef="Present">
          <th mat-header-cell *matHeaderCellDef>
            Labour <br />
            Present
          </th>
          <td mat-cell *matCellDef="let element; let i = index">
            {{ element.labours_present_count }}
          </td>
        </ng-container>

        <!-- Action Column -->
        <ng-container matColumnDef="Action">
          <th mat-header-cell *matHeaderCellDef>Action</th>
          <td mat-cell *matCellDef="let element; let i = index">
            <div style="display: flex; gap: 20%; justify-content: center">
              <mat-icon *ngIf="access?.edit && !access?.view && isEnableEdit(element.dated_on)" style="width: 1.2rem;"
                class="grow" svgIcon="Edit" (click)="editvalue(element)">
              </mat-icon>
              <div *ngIf="i!==0 && i<7" style="width: 1.2rem;" class="grow">
              </div>
              <mat-icon *ngIf="access?.delete && !access?.view && isEnableDelete(element.dated_on)" style="width: 1rem"
                class="grow" svgIcon="Delete" (click)="deleteDayEntry(element)">
              </mat-icon>
            </div>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumnsSingle"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumnsSingle"></tr>
      </table>
    </div>

    <div style="height: 15%" [ngStyle]="
        !!this.dataSource.data.length
          ? { visibility: 'initial' }
          : { visibility: 'hidden' }
      ">
      <mat-paginator #paginator (page)="nextPage($event)" [length]="length" [pageSize]="pageSize" [disabled]="disabled"
        [showFirstLastButtons]="showFirstLastButtons" [pageSizeOptions]="showPageSizeOptions ? pageSizeOptions : []"
        [hidePageSize]="hidePageSize" [pageIndex]="pageIndex" aria-label="Select page">
      </mat-paginator>
    </div>
    <!-- </div> -->
  </mat-card>
  <!-- ---------------------------------------------------------------------------------- -->

  <div *ngIf="!is_day_entry_table" fxFlex="100" style="margin: 0% 1%; overflow: hidden; height: fit-content"
    fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap.gt-md="8px" fxLayoutGap.sm="5px" fxLayoutGap.lt-sm="5px"
    fxLayoutGap.md="5">
    <div fxFlex="50" fxLayoutGap.gt-md="8px" fxLayoutGap.sm="5px" fxLayoutGap.lt-sm="5px" fxLayoutGap.md="5" [ngStyle]="
        view_only
          ? { 'pointer-events': 'none' }
          : { 'pointer-events': 'initial' }
      " fxLayout="column">
      <!-- -------------------------opening Stock---------------------------------------------->

      <mat-card fxLayout.md="column" fxLayoutGap.md="5" fxLayoutGap.sm="5" fxLayout.sm="column" fxLayout.lt-sm="row"
        fxLayout.gt-md="row" fxLayoutAlign.lt-sm="space-between center" fxLayoutAlign.gt-md="space-between center">
        <div class="label">
          {{ "day_entry.opening_stock" | translate | titlecase }}
        </div>

        <div fxLayout="row warp" fxLayoutGap="10px">
          <div fxLayout="row" fxLayoutGap="3px">
            <span class="field">{{
              birdtype != "shrimp"
              ? ("day_entry.fish" | translate | titlecase)
              : ("day_entry.shrimp" | translate | titlecase)
              }}
            </span>
            <span class="field-value">
              {{
              day_entry_from_group.get("fish_opening_stock").value || "01"
              }}</span>
          </div>
          <div class="field" *ngIf="birdtype == 'breeder'">|</div>
          <!-- <div fxLayout="row" fxLayoutGap="3px" *ngIf="birdtype == 'breeder'">
            <span class="field">{{ "day_entry.male" | translate | titlecase }}
            </span>
            <span class="field-value">{{
              day_entry_from_group.get("male_opening_stock").value || "00"
              }}</span>
          </div> -->
        </div>
      </mat-card>

      <!--------------------------------------------------------------------------------------->

      <!----------------------------------depletion--------------------------------------------->
      <!-- onkeypress="return event.charCode >= 48 && event.charCode <= 57" -->
      <mat-card fxLayout="column">
        <div style="padding-bottom: 5px" class="label">
          {{ "day_entry.depletion" | translate | titlecase }}
        </div>
        <div fxFlex="100" fxLayout="column" fxLayoutGap="5">
          <div fxFlex="100" fxLayout.md="column" fxLayout.sm="column" fxLayout.lt-sm="row" fxLayout.gt-md="row"
            fxLayoutAlign.lt-sm="space-between center" fxLayoutAlign.gt-md="space-between center">
            <div fxFlex="30" class="field">
              {{ "day_entry.mortality" | translate | titlecase }}
            </div>
            <div fxFlex="70" fxLayoutGap="12" fxLayout="row" [ngClass]="{ endfield: birdtype != 'breeder' }">
              <div style="font-size: 12px; height: 3rem" fxFlex="{{ birdtype == 'breeder' ? 48 : 60 }}">
                <mat-form-field appearance="outline">
                  <mat-label>{{
                    birdtype != "shrimp"
                    ? ("day_entry.fish" | translate | titlecase)
                    : ("day_entry.shrimp" | translate | titlecase)
                    }}</mat-label>
                  <input matInput (keydown.arrowup)="$event.preventDefault()"
                    (keydown.arrowdown)="$event.preventDefault()" (wheel)="$event.preventDefault()" (keydown)="
                      $event.key == 'e' ||
                      $event.key == 'E' ||
                      $event.key == '+' ||
                      $event.key == '-' ||
                      $event.key == '.'
                        ? $event.preventDefault()
                        : ''
                    " oninput="this.value=this.value.replace(/[^0-9]/g,'')" formControlName="fish_mortality_count"
                    type="number" (keyup)="depletionKeyUp('FM')" autocomplete="new-password" />
                </mat-form-field>
              </div>

            </div>
          </div>

          <div fxFlex="100" fxLayout.md="column" fxLayout.sm="column" fxLayout.lt-sm="row" fxLayout.gt-md="row"
            fxLayoutAlign.lt-sm="space-between center" fxLayoutAlign.gt-md="space-between center">
            <div fxFlex="30" class="field">
              {{ "day_entry.sold" | translate | titlecase }}
            </div>
            <div fxFlex="70" fxLayoutGap="12" fxLayout="row" [ngClass]="{ endfield: birdtype != 'breeder' }">
              <div style="font-size: 12px; height: 3rem" fxFlex="{{ birdtype == 'breeder' ? 48 : 60 }}">
                <mat-form-field appearance="outline">
                  <mat-label>{{
                    birdtype != "shrimp"
                    ? ("day_entry.fish" | translate | titlecase)
                    : ("day_entry.shrimp" | translate | titlecase)
                    }}</mat-label>
                  <input matInput (keydown.arrowup)="$event.preventDefault()"
                    (keydown.arrowdown)="$event.preventDefault()" (wheel)="$event.preventDefault()" (keydown)="
                      $event.key == 'e' ||
                      $event.key == 'E' ||
                      $event.key == '+' ||
                      $event.key == '-' ||
                      $event.key == '.'
                        ? $event.preventDefault()
                        : ''
                    " oninput="this.value=this.value.replace(/[^0-9]/g,'')" formControlName="fish_sold_count"
                    type="number" (keyup)="depletionKeyUp('FS')" autocomplete="new-password" />
                </mat-form-field>
              </div>
            </div>
          </div>

          <div fxFlex="100" fxLayout.md="column" fxLayout.sm="column" fxLayout.lt-sm="row" fxLayout.gt-md="row"
            fxLayoutAlign.lt-sm="space-between center" fxLayoutAlign.gt-md="space-between center">
            <div fxFlex="30" class="field">
              {{ "day_entry.transfer_in" | translate | titlecase }}
            </div>
            <div fxFlex="70" fxLayoutGap="12" fxLayout="row" [ngClass]="{ endfield: birdtype != 'breeder' }">
              <div style="font-size: 12px; height: 3rem" fxFlex="{{ birdtype == 'breeder' ? 48 : 60 }}">
                <mat-form-field appearance="outline">
                  <mat-label>{{
                    birdtype != "shrimp"
                    ? ("day_entry.fish" | translate | titlecase)
                    : ("day_entry.shrimp" | translate | titlecase)
                    }}</mat-label>
                  <input matInput formControlName="fish_transfer_in_count" type="number"
                    (keyup)="depletionKeyUp('FT')" (keydown.arrowup)="$event.preventDefault()"
                    (keydown.arrowdown)="$event.preventDefault()" (wheel)="$event.preventDefault()" (keydown)="
                      $event.key == 'e' ||
                      $event.key == 'E' ||
                      $event.key == '+' ||
                      $event.key == '-' ||
                      $event.key == '.'
                        ? $event.preventDefault()
                        : ''
                    " oninput="this.value=this.value.replace(/[^0-9]/g,'')" autocomplete="new-password" />
                </mat-form-field>
              </div>

            </div>
          </div>

          <div fxFlex="100" fxLayout.md="column" fxLayout.sm="column" fxLayout.lt-sm="row" fxLayout.gt-md="row"
            fxLayoutAlign.lt-sm="space-between center" fxLayoutAlign.gt-md="space-between center">
            <div fxFlex="30" class="field">
              {{ "day_entry.transfer_out" | translate | titlecase }}
            </div>
            <div fxFlex="70" fxLayoutGap="12" fxLayout="row" [ngClass]="{ endfield: birdtype != 'breeder' }">
              <div style="font-size: 12px; height: 3rem" fxFlex="{{ birdtype == 'breeder' ? 48 : 60 }}">
                <mat-form-field appearance="outline">
                  <mat-label>{{
                    birdtype != "shrimp"
                    ? ("day_entry.fish" | translate | titlecase)
                    : ("day_entry.shrimp" | translate | titlecase)
                    }}</mat-label>
                  <input matInput formControlName="fish_transfer_out_count" type="number"
                    (keyup)="depletionKeyUp('FT')" (keydown.arrowup)="$event.preventDefault()"
                    (keydown.arrowdown)="$event.preventDefault()" (wheel)="$event.preventDefault()" (keydown)="
                      $event.key == 'e' ||
                      $event.key == 'E' ||
                      $event.key == '+' ||
                      $event.key == '-' ||
                      $event.key == '.'
                        ? $event.preventDefault()
                        : ''
                    " oninput="this.value=this.value.replace(/[^0-9]/g,'')" autocomplete="new-password" />
                </mat-form-field>
              </div>

            
            </div>
          </div>
        </div>
      </mat-card>

      <!--------------------------------------------------------------------------------------->

      <!--------------------------------------Closing stock----------------------------------->

      <mat-card fxLayout.md="column" fxLayoutGap.md="5" fxLayoutGap.sm="5" fxLayout.sm="column" fxLayout.lt-sm="row"
        fxLayout.gt-md="row" fxLayoutAlign.lt-sm="space-between center" fxLayoutAlign.gt-md="space-between center">
        <div class="label">
          {{ "day_entry.closing_stock" | translate | titlecase }}
        </div>
        <div fxLayout="row warp" fxLayoutGap="10px">
          <div class="stock-height" fxLayout="row" fxLayoutGap="3px">
            <span class="field">{{
              birdtype != "shrimp"
              ? ("day_entry.fish" | translate | titlecase)
              : ("day_entry.shrimp" | translate | titlecase)
              }}
            </span>
            <span class="field-value">{{
              day_entry_from_group.get("fish_closing_stock").value || "0"
              }}</span>
          </div>
          <div class="stock-height" *ngIf="birdtype == 'breeder'">|</div>
          <!-- <div class="stock-height" fxLayout="row" fxLayoutGap="3px" *ngIf="birdtype == 'breeder'">
            <div class="field">
              {{ "day_entry.male" | translate | titlecase }}
            </div>
            <div class="field-value">
              {{ day_entry_from_group.get("male_closing_stock").value || "0" }}
            </div>
          </div> -->
        </div>
      </mat-card>

      <!--------------------------------------------------------------------------------------->

      <!--------------------------------Labour Present Count------------------------------------>

      <mat-card fxFlex="100" fxLayout.md="column" fxLayoutGap.md="5" fxLayoutGap.sm="5" fxLayout.sm="column"
        fxLayout.lt-sm="row" fxLayout.gt-md="row" fxLayoutAlign.lt-sm="space-between center"
        fxLayoutAlign.gt-md="space-between center">
        <div fxFlex="68" fxLayout="column">
          <span class="label">
            {{ "day_entry.labour_present_count" | translate | titlecase }}
          </span>
          
        </div>
        <div style="font-size: 12px; height: 3rem" fxFlex="32">
          <mat-form-field appearance="outline">
            <mat-label>{{
              "day_entry.count" | translate | titlecase
              }}</mat-label>
            <input (keydown.arrowup)="$event.preventDefault()" (keydown.arrowdown)="$event.preventDefault()"
              (wheel)="$event.preventDefault()" (keydown)="
                $event.key == 'e' ||
                $event.key == 'E' ||
                $event.key == '-' ||
                $event.key == '.'
                  ? $event.preventDefault()
                  : ''
              " oninput="this.value=this.value.replace(/[^0-9]/g,'')" matInput formControlName="labours_present_count"
              type="number" autocomplete="new-password" />
          </mat-form-field>
        </div>
      </mat-card>

      <!--------------------------------------------------------------------------------------->

      <!-------------------------------Feed Issue---------------------------------------------->
      <mat-card fxLayout="column">
        <div class="label" style="padding-bottom: 5px">
          {{ "day_entry.feed_issue" | translate | titlecase }}
        </div>
        <div fxLayout="column" fxLayoutGap="5">
          <div fxFlex="100" fxLayout.md="column" fxLayout.sm="column" fxLayout.lt-sm="row" fxLayout.gt-md="row"
            fxLayoutAlign.lt-sm="space-between center" fxLayoutAlign.gt-md="space-between center">
            <div fxFlex="30" class="field">
              {{ "day_entry.type_of_feed" | translate | titlecase }}
            </div>

            <div fxFlex="70" style="font-size: 12px; height: 3rem" [ngClass]="{ endfield: birdtype != 'breeder' }">
              <mat-form-field appearance="outline" [ngStyle]="birdtype != 'breeder' ? { width: '60%' } : {}">
                <mat-select disableOptionCentering formControlName="feed_id" panelClass="PanelClass" [placeholder]="
                    'day_entry.select_feed' | translate | titlecase
                  ">
                  <ng-container *ngFor="let feed of feed_type">
                    <mat-option [value]="feed.feed_id">{{
                      "day_entry." + feed.feed_name | translate | titlecase
                      }}
                    </mat-option>
                  </ng-container>
                </mat-select>
              </mat-form-field>
            </div>
          </div>

          <div fxFlex="100" fxLayout.md="column" fxLayout.sm="column" fxLayout.lt-sm="row" fxLayout.gt-md="row"
            fxLayoutAlign.lt-sm="space-between center" fxLayoutAlign.gt-md="space-between center">
            <div fxFlex="30" class="field">
              {{
                birdtype != "shrimp"
                ? ("day_entry.feed_in_grams_f" | translate | titlecase)
                : ("day_entry.feed_in_grams_s" | translate | titlecase)
                }}
            </div>
            <div fxFlex="70" fxLayoutGap="12" fxLayout="row" [ngClass]="{ endfield: birdtype != 'breeder' }">
              <div style="font-size: 12px; height: 3rem" fxFlex="{{ birdtype == 'breeder' ? 48 : 60 }}">
                <mat-form-field appearance="outline">
                  <mat-label>{{
                    birdtype != "shrimp"
                    ? ("day_entry.fish" | translate | titlecase)
                    : ("day_entry.shrimp" | translate | titlecase)
                    }}</mat-label>
                  <input matInput formControlName="feed_per_fish" type="number"
                    (keydown.arrowup)="$event.preventDefault()" (keydown.arrowdown)="$event.preventDefault()"
                    (wheel)="$event.preventDefault()"
                    oninput="if(!/^\d{1,3}(\.\d{0,3})?$/.test(this.value)) this.value = this.value.slice(0, -1)"
                    autocomplete="new-password" [placeholder]="'day_entry.in_grams' | translate | titlecase" />
                </mat-form-field>
              </div>

              
            </div>
          </div>
          <div fxFlex="100" fxLayout.md="column" fxLayout.sm="column" fxLayout.lt-sm="row" fxLayout.gt-md="row"
            fxLayoutAlign.lt-sm="space-between center" fxLayoutAlign.gt-md="space-between center">
            <div fxFlex="30" class="field">
              {{
                birdtype != "shrimp"
                ? ("day_entry.weight_in_grams_f" | translate | titlecase)
                : ("day_entry.weight_in_grams_s" | translate | titlecase)
                }}
            </div>
            <div fxFlex="70" fxLayoutGap="12" fxLayout="row" [ngClass]="{ endfield: birdtype != 'breeder' }">
              <div style="font-size: 12px; height: 3rem" fxFlex="{{ birdtype == 'breeder' ? 48 : 60 }}">
                <mat-form-field appearance="outline">
                  <mat-label>{{
                    birdtype != "shrimp" ?
                    ("day_entry.fish" | translate | titlecase)
                    : ("day_entry.shrimp" | translate | titlecase)
                    }}</mat-label>
                  <input matInput formControlName="avg_f_weight" type="number"
                    (keydown.arrowup)="$event.preventDefault()" (keydown.arrowdown)="$event.preventDefault()"
                    (wheel)="$event.preventDefault()"
                    oninput="if(!/^\d{1,4}(\.\d{0,2})?$/.test(this.value)) this.value = this.value.slice(0, -1)"
                    autocomplete="new-password" [placeholder]="'day_entry.in_grams' | translate | titlecase" />
                </mat-form-field>
              </div>

            </div>
          </div>
        </div>
      </mat-card>

      <!--------------------------------------------------------------------------------------->
    </div>


    <div fxFlex="50" fxLayout="column" fxLayoutGap.gt-md="8px" fxLayoutGap.sm="5px" fxLayoutGap.lt-sm="5px"
      fxLayoutGap.md="5" id="day_entry_medication">
      
      <mat-card fxFlex="100" fxLayout.md="column" fxLayoutGap.md="5" fxLayoutGap.sm="5" fxLayout.sm="column"
        *ngIf="birdtype == 'broiler'" fxLayout.lt-sm="row" fxLayout.gt-md="row"
        fxLayoutAlign.lt-sm="space-between center" fxLayoutAlign.gt-md="space-between center">
        <div fxFlex="68" fxLayout="column">
          <span class="label">
            {{ "day_entry.delivery_challan" | translate | titlecase }}
          </span>
        </div>
        <div style="font-size: 12px; height: 3rem" fxFlex="32">
          <mat-form-field style="height: 2.6rem" appearance="outline">
            <mat-label>{{
              "day_entry.number" | translate | titlecase
              }}</mat-label>
            <input matInput formControlName="delivery_challan" type="text"
              oninput="this.value = this.value.replace(/[^a-zA-Z0-9]/g,'')" autocomplete="new-password" />
          </mat-form-field>
        </div>
      </mat-card>

      <!-- ----------------------------------------Equipment Health------------------------ -->
      <mat-card [ngStyle]="
          view_only
            ? { 'pointer-events': 'none' }
            : { 'pointer-events': 'initial' }
        " fxLayout="column" id="day_entry_equipment">
        <div fxLayout="row" fxLayoutAlign="space-between center">
          <div mat-card-title class="label" style="padding-bottom: 10px">
            {{ "day_entry.equipment_health" | translate | titlecase }}
          </div>

          <div *ngIf="
              day_entry_from_group.get('equipmentlog')?.controls.length == 1 &&
              !view_only
            ">
            <mat-icon class="add-icon grow" (click)="addEquipment()">add_circle</mat-icon>
          </div>
          <div *ngIf="
              day_entry_from_group.get('equipmentlog')?.controls.length > 1 &&
              !view_only
            ">
            <mat-icon class="remove-icon grow" (click)="removeEquipment(0)">do_not_disturb_on</mat-icon>
          </div>
        </div>

        <ng-container *ngFor="
            let equipmentGrpup of day_entry_from_group.get('equipmentlog')
              ?.controls;
            let i = index;
            let last = last
          ">
          <div *ngIf="i > 0" style="margin: 10px 0px" fxlayout="column">
            <div><mat-divider></mat-divider></div>
            <div style="margin-top: 5px" fxLayoutAlign="end end" fxLayout="row" fxLayoutGap="10">
              <div>
                <mat-icon class="remove-icon grow" *ngIf="!view_only"
                  (click)="removeEquipment(i)">do_not_disturb_on</mat-icon>
              </div>
              <div *ngIf="
                  last &&
                  day_entry_from_group.get('equipmentlog')?.controls?.length <
                    5 &&
                  !view_only
                ">
                <mat-icon class="add-icon grow" (click)="addEquipment()">add_circle</mat-icon>
              </div>
            </div>
          </div>
          <form [formGroup]="equipmentGrpup" fxLayout="column" fxLayoutGap="5">
            <div fxFlex="100" fxLayout.md="column" fxLayout.sm="column" fxLayout.lt-sm="row" fxLayout.gt-md="row"
              fxLayoutAlign.lt-sm="space-between center" fxLayoutAlign.gt-md="space-between center">
              <div fxFlex="30" class="field">
                {{ i + 1 }}. {{ "day_entry.name" | translate | titlecase }}
              </div>

              <div fxFlex="65" style="font-size: 12px; height: 3rem">
                <mat-form-field appearance="outline">
                  <mat-label>{{
                    "day_entry.name" | translate | titlecase
                    }}</mat-label>
                  <mat-select disableOptionCentering formControlName="equipment" panelClass="PanelClass"
                    (selectionChange)="getEqptFailure($event.value, i)">
                    <ng-container *ngFor="let equipment of equipment_list">
                      <mat-option [value]="equipment.equipment_id">{{ equipment.equipment_name }}
                      </mat-option>
                    </ng-container>
                  </mat-select>
                  <mat-icon style="z-index: 999 !important" *ngIf="
                      this.day_entry_from_group
                        .get('equipmentlog')
                        .controls[i].get('equipment')?.value && !view_only
                    " matSuffix (click)="clearEquipmentSelection(i)">clear</mat-icon>
                </mat-form-field>
              </div>
            </div>
            <div fxFlex="100" fxLayout.md="column" fxLayout.sm="column" fxLayout.lt-sm="row" fxLayout.gt-md="row"
              fxLayoutAlign.lt-sm="space-between center" fxLayoutAlign.gt-md="space-between center">
              <div fxFlex="30" class="field">
                {{ "day_entry.fault" | translate | titlecase }}
              </div>

              <div fxFlex="65" style="font-size: 12px; height: 3rem">
                <mat-form-field appearance="outline">
                  <mat-label>{{
                    "day_entry.fault" | translate | titlecase
                    }}</mat-label>
                  <mat-select disableOptionCentering formControlName="failure" panelClass="PanelClass">
                    <ng-container *ngFor="let failure of equipment_failure[i]">
                      <mat-option [value]="failure.failure_id" (onSelectionChange)="setFailureData(failure, i)">{{
                        failure.failure_desc }}
                      </mat-option>
                    </ng-container>
                  </mat-select>
                  <mat-icon style="z-index: 999 !important" *ngIf="
                      this.day_entry_from_group
                        .get('equipmentlog')
                        .controls[i].get('failure')?.value && !view_only
                    " matSuffix (click)="clearFaultSelection(i)">clear</mat-icon>
                </mat-form-field>
              </div>
            </div>
          </form>
        </ng-container>
      </mat-card>

      <!--------------------------------------------------------------------------------------->

      <!-- ----------------------------------------Medication------------------------------- -->

      <mat-card [ngStyle]="
          view_only
            ? { 'pointer-events': 'none' }
            : { 'pointer-events': 'initial' }
        " fxLayout="column">
        <div fxLayout="row" fxLayoutAlign="space-between center">
          <div mat-card-title class="label" style="padding-bottom: 10px">
            {{ "day_entry.medication" | translate | titlecase }}
          </div>

          <div *ngIf="
              day_entry_from_group.get('medicationlog')?.controls.length == 1 &&
              !view_only
            ">
            <mat-icon class="add-icon grow" (click)="addMedication()">add_circle</mat-icon>
          </div>
          <div *ngIf="
              day_entry_from_group.get('medicationlog')?.controls.length > 1 &&
              !view_only
            ">
            <mat-icon class="remove-icon grow" (click)="removeMedication(0)">do_not_disturb_on</mat-icon>
          </div>
        </div>

        <ng-container *ngFor="
            let medicationGrpup of day_entry_from_group.get('medicationlog')
              ?.controls;
            let i = index;
            let last = last
          ">
          <div *ngIf="i > 0" style="margin: 10px 0px" fxlayout="column">
            <div><mat-divider></mat-divider></div>
            <div style="margin-top: 5px" fxLayoutAlign="end end" fxLayout="row" fxLayoutGap="10">
              <div>
                <mat-icon class="remove-icon grow" *ngIf="!view_only"
                  (click)="removeMedication(i)">do_not_disturb_on</mat-icon>
              </div>
              <div *ngIf="
                  last &&
                  day_entry_from_group.get('medicationlog')?.controls?.length <
                    5 &&
                  !view_only
                ">
                <mat-icon class="add-icon grow" (click)="addMedication()">add_circle</mat-icon>
              </div>
            </div>
          </div>
          <form [formGroup]="medicationGrpup" fxLayout="column" fxLayoutGap="5">
            <div fxFlex="100" fxLayout.md="column" fxLayout.sm="column" fxLayout.lt-sm="row" fxLayout.gt-md="row"
              fxLayoutAlign.lt-sm="space-between center" fxLayoutAlign.gt-md="space-between center">
              <div fxFlex="30" class="field">
                {{ i + 1 }}. {{ "day_entry.schedule" | translate | titlecase }}
              </div>
              <div fxFlex="65" style="font-size: 12px; height: 3rem">
                <mat-form-field appearance="outline">
                  <mat-label>{{
                    "day_entry.schedule" | translate | titlecase
                    }}</mat-label>
                  <mat-select disableOptionCentering formControlName="scheduled_id" panelClass="PanelClass">
                    <ng-container *ngFor="let medication of schedules[i]">
                      <mat-option (click)="medicationSelectionChange(i)" [value]="medication.scheduled_id">{{
                        medication.text }}
                      </mat-option>
                    </ng-container>
                  </mat-select>
                  <mat-icon *ngIf="
                      this.day_entry_from_group
                        .get('medicationlog')
                        .controls[i].get('scheduled_id')?.value && !view_only
                    " matSuffix (click)="clearScheduleSelection(i)">clear</mat-icon>
                </mat-form-field>
              </div>
            </div>

            <div fxFlex="100" fxLayout.md="column" fxLayout.sm="column" fxLayout.lt-sm="row" fxLayout.gt-md="row"
              fxLayoutAlign.lt-sm="space-between center" fxLayoutAlign.gt-md="space-between center">
              <div fxFlex="30" class="field">
                {{ "day_entry.medication_name" | translate | titlecase }}
              </div>
              <div fxFlex="65" style="font-size: 12px; height: 3rem">
                <mat-form-field appearance="outline">
                  <mat-label>{{
                    "day_entry.name_msg" | translate | titlecase
                    }}</mat-label>
                  <input matInput formControlName="medication_name" type="text"
                    oninput="this.value = this.value.trimStart().replace(/\s+/g, ' ')" autocomplete="new-password" />
                </mat-form-field>
              </div>
            </div>

            <div fxLayout.md="column" fxLayout.sm="column" fxLayout.lt-sm="row" fxLayout.gt-md="row"
              fxLayoutAlign.lt-sm="space-between center" fxLayoutAlign.gt-md="space-between center">
              <div fxFlex="35" class="field">
                {{ "day_entry.serial_number" | translate | titlecase }}
              </div>
              <div fxFlex="60" fxLayout="column" fxLayoutGap="20">
                <ng-container *ngFor="
                    let serialContol of medicationGrpup.get('serial_no')
                      .controls;
                    let vIndex = index;
                    let vLast = last
                  ">
                  <div fxFlex="row" fxLayoutGap="10" fxLayoutAlign="end center">
                    <div fxFlex.lt-md="85" fxFlex.gt-md="50" style="font-size: 12px; height: 3rem">
                      <mat-form-field appearance="outline">
                        <input matInput [formControl]="serialContol" type="text" />
                      </mat-form-field>
                    </div>

                    <div fxFlex="10" fxLayout="column" fxLayoutGap="5">
                      <mat-icon class="remove-icon grow" *ngIf="
                          medicationGrpup.get('serial_no').controls.length >
                            1 && !view_only
                        " (click)="removeSerialNo(i, vIndex)">do_not_disturb_on</mat-icon>
                      <mat-icon class="add-icon grow" (click)="addSerialNo(i)"
                        *ngIf="vLast && !view_only">add_circle</mat-icon>
                    </div>
                  </div>
                </ng-container>
              </div>
            </div>

            <div *ngIf="0" fxFlex="100" fxLayout="row" fxLayoutAlign="space-between center">
              <div fxFlex="30" class="field">Total Count</div>
              <div fxFlex="30" style="font-size: 12px; height: 3rem; margin-right: 28px">
                <mat-form-field appearance="outline">
                  <mat-label>Total</mat-label>
                  <input matInput type="text" autocomplete="new-password" [disabled]="true" [value]="
                      this.day_entry_from_group
                        .get('medicationlog')
                        .controls[i].get('serial_no').controls?.length
                    " />
                </mat-form-field>
              </div>
            </div>
          </form>
        </ng-container>
      </mat-card>

      <!--------------------------------------------------------------------------------------->

      <div *ngIf="!view_only" style="padding: 20px 0px" fxLayoutAlign="center center"
        fxLayoutAlign.lt-sm="center center">
        <div *ngIf="!view_only" fxLayout="row" fxLayout.md="column" fxLayoutAlign="center center" fxLayoutGap="10"
          fxLayoutGap.gt-md="50" fxLayoutGap.lt-sm="20">
          <button *ngIf="!view_only" [disabled]="disable_submit" class="submit grow" mat-stroked-button
            (click)="submitDayEntry()">
            {{
            editDayEntry
            ? ("button.update" | translate | titlecase)
            : ("button.submit" | translate | titlecase)
            }}
          </button>
          <button *ngIf="!view_only" class="cancel grow" mat-stroked-button (click)="cancelDayEntry()">
            {{ "button.cancel" | translate | titlecase }}
          </button>
        </div>
      </div>
      <div *ngIf="view_only" style="padding: 20px 0px" fxLayoutAlign="center center"
        fxLayoutAlign.lt-sm="center center">
        <button *ngIf="view_only" class="cancel grow" mat-stroked-button (click)="dayEntryback()">
          {{ "button.back" | translate | titlecase }}
        </button>
      </div>
    </div>
  </div>
</form>
