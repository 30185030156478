<div class="h-100">
    <div fxFlex="100" fxLayout="column" class="equipcard" class="per1pad full-div" fxLayoutGap="10px">
        <!-- Blue header with create and list button with company details -->
        <mat-card style="background-color: #166DA6;color: white;height:4rem;overflow: hidden;" fxLayout="row"
            fxLayoutAlign="space-between center">
            <div fxLayout="column">
                <span style="font-size: 13px;color: white;font-weight: bold;line-height: 1;">{{shed_name}}</span>
                <span style="line-height: 1;margin-top: 7px;font-size: 10px;
                color: #FFFFFF;">{{company_name}}</span>
            </div>
            <div fxLayoutAlign="center center">
                <button *ngIf="creatbt && (access?.create && !access?.view)" mat-stroked-button class="add-button grow"
                    fxLayout="row" (click)="createList();">
                    <mat-icon class="add-icon">add_circle</mat-icon>
                    <span>{{'button.create'|translate|uppercase}}</span>
                </button>
                <button *ngIf="listbt && (access?.create && !access?.view)" mat-stroked-button class="add-button grow"
                    fxLayout="row" (click)="ListPage()">
                    <mat-icon svgIcon="list" style="margin-right: 6px;height: 22px;width: 22px;"></mat-icon>
                    <span>{{'equipment.list'|translate|uppercase}}</span>
                </button>
            </div>
        </mat-card>
        <div fxFlex="85" fxLayout="column" fxLayout.lt-sm="column" fxLayoutGap="10px">
            <!-- tab to change between equipment and fault pages-->
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutAlign.lt-sm="center center" style="width: 100%;">
                <div class="tab-containerEquip">
                    <mat-tab-group (selectedTabChange)=" selectTab($event)" [selectedIndex]="0">
                        <mat-tab [label]="'equipment.name'|translate|uppercase"></mat-tab>
                        <mat-tab [label]="'equipment.fault'|translate|uppercase"></mat-tab>
                    </mat-tab-group>
                </div>
            </div>
            <!--equipment list table  -->
            <mat-card style="height:86%" class="mat-elevation-z6 scrollnull" fxLayout="column"
                fxLayoutAlign="space-between start" *ngIf="nameList">
                <!-- empty equipment image if table have no value -->
                <div *ngIf="tablenamelist.length==0 && isloaderName" style="color: #3B3B3B;text-align: center;height: 60vh;align-items: center; width: 100%;
                                display: grid;">
                    <div fxLayout="column" fxLayoutGap="10px" style="justify-content: center;
                                align-items: center;">
                        <img src="../../../assets/images/empty/Createscreens.png" alt=""
                            style="height: 140px;width: 140px;">
                        <span>{{'equipment.empty_msg1'|translate|titlecase}}</span>
                    </div>
                </div>
                <!-- if equipment table have values -->
                <div style="overflow: auto;width: 100%;" fxFlex="100">
                    <table class="level-one-table" mat-table [dataSource]="dataSource" *ngIf="tablenamelist.length>0">

                        <!-- S.No Column -->
                        <ng-container matColumnDef="S.NO">
                            <th mat-header-cell *matHeaderCellDef style="width: 20vh;">
                                {{'equipment.s_no'|translate|titlecase}}
                            </th>
                            <td mat-cell *matCellDef="let element;let i = index"> {{i+1}} </td>
                        </ng-container>

                        <!-- Sensor name Column -->
                        <ng-container matColumnDef="Sensor name">
                            <th mat-header-cell *matHeaderCellDef>{{'equipment.eq_name'|translate|titlecase}} </th>
                            <td mat-cell *matCellDef="let element"> {{element.name}} </td>
                        </ng-container>
                        <!-- Action Column -->
                        <ng-container matColumnDef="Action">
                            <th mat-header-cell *matHeaderCellDef style="width: 16vh;">
                                {{'equipment.action'|translate|titlecase}}
                            </th>
                            <td mat-cell *matCellDef="let element">
                                <div style="display: flex;">
                                    <div style="width: 50%;">
                                        <span *ngIf="access?.edit && !access?.view"><mat-icon class="iconvalue"
                                                svgIcon="Edit" style="margin:0%"
                                                (click)="nameUpdate(element.id)"></mat-icon></span>
                                    </div>
                                    <div style="width: 50%;">
                                        <span *ngIf="access?.delete && !access?.view"><mat-icon class="iconvalue"
                                                svgIcon="Delete" style="margin:0%"
                                                (click)="deleteEquipment(element.id,element.name)"></mat-icon></span>
                                    </div>
                                </div>
                            </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky:true"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                    </table>
                </div>

            </mat-card>
            <!--equipment create page  -->
            <mat-card class="mat-elevation-z6" fxLayout="column" fxLayoutAlign="space-between center" fxLayoutGap="30px"
                *ngIf="nameCT">
                <form [formGroup]="equipment_group" fxLayout="column" fxFlex="70" class="formcolor"
                    style="width: 100%;">
                    <!-- equipment name in put field -->
                    <div fxLayout="row" fxFlex="20" style="padding: 2%;">
                        <div fxFlex="20" class="dataAlign equipmentField">
                            <mat-form-field appearance="outline">
                                <mat-label>{{'equipment.name'|translate|titlecase}}</mat-label>
                                <input  matInput formControlName="age_in_weeks" type="text" autocomplete="off">
                            </mat-form-field>
                        </div>
                    </div>
                </form>
                <!-- while create flow its submit button if its update flow it will show update button 
                        and if its view flow its didnt show any submit or update button it only shows back button  -->
                <div fxFlex="30" style="width: 100%;" fxLayoutAlign="center end">
                    <div fxFlex="100" fxLayoutAlign="center center" fxLayoutAlign.lt-sm="center center" fxLayoutGap="25"
                        fxLayout="row" fxLayout.lt-sm="column" class="buttondiv" style="width: 100%;max-height: 6vh;">
                        <button class="submit grow changeview" mat-raised-button
                            (click)="nameSummit()">{{this.nameUpdateId != undefined?('button.update'|translate|uppercase):'button.save'|translate|uppercase}}</button>
                        <button class=" cancel grow changeview" mat-raised-button
                            (click)="clearEquipment()">{{'button.cancel'|translate|uppercase}}</button>
                    </div>
                </div>
            </mat-card>
            <!--fault list table  -->
            <mat-card class="mat-elevation-z6 scrollnull" fxLayout="column" fxLayoutAlign="space-between start"
                *ngIf="faultlist">
                <!-- empty fault image if table have no value -->
                <div *ngIf="tableFaultlist.length==0 && isloaderfault" style="color: #3B3B3B;text-align: center;height: 60vh;align-items: center;
                                display: grid; width: 100%;">
                    <div fxLayout="column" fxLayoutGap="10px" style="justify-content: center;
                                align-items: center;">
                        <img src="../../../assets/images/empty/Createscreens.png" alt=""
                            style="height: 140px;width: 140px;">
                        <span>{{'equipment.empty_msg2'|translate|titlecase}}</span>
                    </div>
                </div>
                <!-- if fault table have values -->
                <div style="overflow: auto;width: 100%;height: 100%;">
                    <table class="level-one-table" mat-table [dataSource]="dataSource2" *ngIf="tableFaultlist.length>0">
                        <!-- S.No Column -->
                        <ng-container matColumnDef="S.NO">
                            <th mat-header-cell *matHeaderCellDef>{{'equipment.s_no'|translate|titlecase}}</th>
                            <td mat-cell *matCellDef="let element;let i = index"> {{i+1}} </td>
                        </ng-container>

                        <!-- Sensor name Column -->
                        <ng-container matColumnDef="Name of equipment">
                            <th mat-header-cell *matHeaderCellDef>{{'equipment.eq_name'|translate|titlecase}} </th>
                            <td mat-cell *matCellDef="let element"> {{element.name}} </td>
                        </ng-container>
                        <ng-container matColumnDef="Equipment fault Type">
                            <th mat-header-cell *matHeaderCellDef>{{'equipment.fault_type'|translate|titlecase}} </th>
                            <td mat-cell *matCellDef="let element"> {{element.type}} </td>
                        </ng-container>
                        <ng-container matColumnDef="Equipment fault">
                            <th mat-header-cell *matHeaderCellDef>{{'equipment.eq_fault'|translate|titlecase}} </th>
                            <td mat-cell *matCellDef="let element"> {{element.fault}} </td>
                        </ng-container>
                        <!-- Action Column -->
                        <ng-container matColumnDef="Action">
                            <th mat-header-cell *matHeaderCellDef>{{'equipment.action'|translate|titlecase}}</th>
                            <td mat-cell *matCellDef="let element">
                                <div style="display: flex;">
                                    <div style="width: 50%;">
                                        <span *ngIf="access?.edit && !access?.view"><mat-icon class="iconvalue"
                                                svgIcon="Edit" style="margin:0%"
                                                (click)="faultUpdate(element.id)"></mat-icon></span>
                                    </div>
                                    <div style="width: 50%;">
                                        <span *ngIf="access?.delete && !access?.view"><mat-icon class="iconvalue"
                                                svgIcon="Delete" style="margin:0%"
                                                (click)="deleteFault(element.id,element.fault)"></mat-icon></span>
                                    </div>
                                </div>
                            </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="displayedColumns1;sticky:true"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns1;"></tr>
                    </table>
                </div>

            </mat-card>
            <!--fault create page  -->
            <mat-card class="mat-elevation-z6" fxLayout="column" fxLayoutAlign="space-between center" fxLayoutGap="30px"
                *ngIf="faultCT">
                <form [formGroup]="equipment_fault_group" fxLayout="row" fxLayout.lt-sm="column" fxFlex="70"
                    class="formcolor" style="width: 100%;">
                    <!-- choose which equipment have fault -->
                    <div fxLayout="row" fxFlex="33" style="padding: 2%;">
                        <div fxFlex="50" class="dataAlign equipmentField">
                            <mat-form-field appearance="outline">
                                <mat-label>{{'equipment.eq_name'|translate|titlecase}}</mat-label>
                                <mat-select formControlName="age_in_weeks" panelClass="PanelClass"
                                    placeholder="{{'equipment.select_eq'|translate|titlecase}}">
                                    <ng-container *ngFor="let unit of equipmentlist">
                                        <mat-option [value]="unit.id">{{unit.name}}
                                        </mat-option>
                                    </ng-container>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                    <!-- type of fault input field -->
                    <div fxLayout="row" fxFlex="33" style="padding: 2%;">
                        <div fxFlex="50" class="dataAlign equipmentField">
                            <mat-form-field appearance="outline">
                                <mat-label>{{'equipment.fault_type'|translate|titlecase}}</mat-label>
                                <input  matInput formControlName="equipment_fault_type" type="text" autocomplete="off">
                            </mat-form-field>
                        </div>
                    </div>
                    <!-- fault input field -->
                    <div fxLayout="row" fxFlex="33" style="padding: 2%;">
                        <div fxFlex="50" class="dataAlign equipmentField">
                            <mat-form-field appearance="outline">
                                <mat-label>{{'equipment.fault_label'|translate|titlecase}}</mat-label>
                                <input  matInput formControlName="equipment_fault" type="text" autocomplete="off">
                            </mat-form-field>
                        </div>
                    </div>
                </form>
                <!-- while create flow its submit button if its update flow it will show update button 
                        and if its view flow its didnt show any submit or update button it only shows back button  -->
                <div fxFlex="30" style="width: 100%;" fxLayoutAlign="center end">
                    <div fxFlex="100" fxLayoutAlign="center center" fxLayoutAlign.lt-sm="center center" fxLayoutGap="25"
                        fxLayout="row" fxLayout.lt-sm="column" class="buttondiv" style="width: 100%;max-height: 6vh;">
                        <button class=" submit grow changeview" mat-raised-button
                            (click)="faultSummit()">{{this.failureUpdateId != undefined?('button.update'|translate|uppercase):'button.submit'|translate|uppercase}}</button>
                        <button class=" cancel grow changeview" mat-raised-button
                            (click)="clearFailure()">{{'button.cancel'|translate|uppercase}}</button>
                    </div>
                </div>
            </mat-card>
        </div>
    </div>

</div>