<div>
    <div *ngIf="!getsplit(sensorWithoutSOP,inactivesensor)">
        <div fxflex="column" class="dasview">
            <div fxflex="row" style="height: 2.5rem;border-radius: 10px 10px 0px 0px;cursor: pointer;">
                <div fxFlex="50" fxLayoutAlign="center center" (click)="inactive=true" style="border-radius: 10px 0px 0px 0px;"
                [ngClass]="{selecteddiv:inactive ,unselecteddiv: !inactive}"> {{'dashboard.inactive_sensors'|translate|titlecase}}</div>
                <div fxFlex="50" fxLayoutAlign="center center" (click)="inactive=false" style="border-radius: 0px 10px 0px 0px;"
                [ngClass]="{selecteddiv:!inactive ,unselecteddiv: inactive}"> {{'dashboard.sensors_without_sop'|translate|titlecase}}</div>
            </div>
        <div style="height: 16.2rem;">
            <div  fxFlex="100" style="overflow: auto;" class="scrollnull" *ngIf="inactive">
                <div class="sensorfield" fxLayoutGap="12px" *ngFor="let item of inactivesensor" fxLayoutAlign="space-between center">
                    <div class="iconfieldinactive">
                        <mat-icon class="svgicon" svgIcon="{{getIcon(item?.product_code)}}" style="margin: 0%;height: 16px;width: 16px;"></mat-icon>
                    </div>
                    <div fxFlex="50"  style="display: flex;overflow: hidden;"  fxLayoutAlign="start center">
                        <div style="font-size: 12px;text-overflow: ellipsis;white-space: nowrap;overflow: hidden;">{{item?.name}}</div>
                    </div>
                    <div  fxFlex="40"  fxLayout="row"  fxLayoutGap="5px" fxLayoutAlign="start center">
                        <mat-icon class="clockicon" svgIcon="clock" style="margin: 0%;height: 16px;width: 16px;min-width: 16px;" ></mat-icon>
                        <div style="font-size: 12px;color:#ff0000; white-space: nowrap;overflow: hidden;text-overflow: ellipsis;">{{getDate(item?.latest_timestamp)| date:'MMM dd'}},{{getDate(item?.latest_timestamp)| date:'shortTime'}}</div>
                    </div>
                </div>
            </div>
            <div  fxFlex="100"  style="overflow: auto;" class="scrollnull" *ngIf="!inactive">
                <div class="sensorfield" fxLayoutGap="12px" *ngFor="let item of sensorWithoutSOP" fxLayoutAlign="start center">
                    <div class="iconfieldinactive">
                        <mat-icon class="svgicon" svgIcon="{{getIcon(item?.product_code)}}" style="margin: 0%;height: 16px;width: 16px;"></mat-icon>
                    </div>
                    <div fxFlex="80"  style="display: flex;overflow: hidden;"  fxLayoutAlign="start center">
                        <div style="font-size: 12px;text-overflow: ellipsis;white-space: nowrap;overflow: hidden;">{{item?.name}}</div>
                    </div>
                </div>
            </div>
        </div>
        <mat-divider ></mat-divider>
        <div fxLayoutAlign="center center" style="min-height: 2.5rem;font-size: 12px;font-weight: 600;padding: 10px 20px;white-space: break-spaces;">{{'dashboard.live_score_active_msg'|translate|titlecase}}</div>
    </div>
    <div #scrollContainerinactive fxLayout="column" class="sliderrowdialog scrollnull mobview" (scroll)="onScroll($event)">
        <div  class="scroll-container" style="display: flex;white-space: nowrap;overflow: auto;width: 200%;">
            <div style="padding: 0;overflow: hidden;border-radius: 10px;width: 100%;border: 1px solid #dbdbdb;margin: 20px 20px 0 20px;">    
                <div fxflex="column">
                    <div fxflex="row" style="height: 2.5rem;background-color: #d1e6f5;border-radius: 10px 10px 0px 0px;font-size: 13px;font-weight: 600;">
                        <div fxFlex="100" fxLayoutAlign="center center"> {{'dashboard.inactive_sensors'|translate|titlecase}}</div>
                    </div>
                    <!-- <mat-divider></mat-divider> -->
                    <div style="height: 16.2rem;">
                        <div  fxFlex="100" style="overflow: auto;" class="scrollnull" >
                            <div class="sensorfield" fxLayoutGap="12px" *ngFor="let item of inactivesensor" fxLayoutAlign="space-between center">
                                <div class="iconfieldinactive">
                                    <mat-icon class="svgicon" svgIcon="{{getIcon(item?.product_code)}}" style="margin: 0%;height: 16px;width: 16px;"></mat-icon>
                                </div>
                                <div fxFlex="90"   fxLayout="row" class="inactivemob">
                                    <div fxFlex="55"  style="display: flex;overflow: hidden;"  fxLayoutAlign="start center" class="sensornameinactive maxwidinactive">
                                        <div style="font-size: 12px;text-overflow: ellipsis;white-space: nowrap;overflow: hidden;">{{item?.name}}</div>
                                    </div>
                                    <div  fxFlex="45"  fxLayout="row"  fxLayoutGap="5px" fxLayoutAlign="start center" class="maxwidinactive">
                                        <mat-icon class="clockicon" svgIcon="clock" style="margin: 0%;height: 16px;width: 16px;min-width: 16px;" ></mat-icon>
                                        <div style="font-size: 12px;color:#ff0000; white-space: nowrap;overflow: hidden;text-overflow: ellipsis;">{{getDate(item?.latest_timestamp)| date:'MMM dd'}},{{getDate(item?.latest_timestamp)| date:'shortTime'}}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <mat-divider ></mat-divider>
                    <div fxLayoutAlign="center center" style="min-height: 2.5rem;font-size: 12px;font-weight: 600;padding: 10px 20px;white-space: break-spaces;">{{'dashboard.live_score_active_msg'|translate|titlecase}}</div>
                </div>
            </div>
            <div style="padding: 0;overflow: hidden;border-radius: 10px;width: 100%;border: 1px solid #dbdbdb;margin: 20px 20px 0 20px;" >
                <div fxflex="column">
                    <div fxflex="row" style="height: 2.5rem;background-color: #d1e6f5;border-radius: 10px 10px 0px 0px;font-size: 13px;font-weight: 600;">
                        <div fxFlex="100" fxLayoutAlign="center center" *ngIf="islistempty(sensorWithoutSOP)"> {{'dashboard.sensors_without_sop'|translate|titlecase}}</div>
                    </div>
                    <div style="height: 16.2rem;">
                        <div  fxFlex="100"  style="overflow: auto;" class="scrollnull" >
                            <div class="sensorfield" fxLayoutGap="12px" *ngFor="let item of sensorWithoutSOP" fxLayoutAlign="start center">
                                <div class="iconfieldinactive">
                                    <mat-icon class="svgicon" svgIcon="{{getIcon(item?.product_code)}}" style="margin: 0%;height: 16px;width: 16px;"></mat-icon>
                                </div>
                                <div fxFlex="80"  style="display: flex;overflow: hidden;"  fxLayoutAlign="start center">
                                    <div style="font-size: 12px;text-overflow: ellipsis;white-space: nowrap;overflow: hidden;">{{item?.name}}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- <mat-divider ></mat-divider>
                    <div fxLayoutAlign="center center" style="height: 2.5rem;font-size: 12px;font-weight: 600;padding: 0 20px;white-space: break-spaces;">The live score is based only on the active sensors with SOP</div> -->
                </div>
            </div>
        </div>
    </div>
    <div style="height: 2rem;display: flex;justify-content: center;align-items: center;gap: 3px;" class="mobview">
        <div style="height: 6px;width: 6px;border-radius: 6px;"
            [ngStyle]="!secondpage? {'background-color':'#166da6'}:{'background-color':'#d7e4ec'}"></div>
            <div style="height: 6px;width: 6px;border-radius: 6px;"
            [ngStyle]="secondpage? {'background-color':'#166da6'}:{'background-color':'#d7e4ec'}"></div>
    </div>
    </div>
    <div fxflex="column" *ngIf="getsplit(sensorWithoutSOP,inactivesensor)">
        <div fxflex="row" style="height: 2.5rem;background-color: #d1e6f5;border-radius: 10px 10px 0px 0px;font-size: 13px;font-weight: 600;">
            <div fxFlex="100" fxLayoutAlign="center center" *ngIf="islistempty(inactivesensor)"> {{'dashboard.inactive_sensors'|translate|titlecase}}</div>
            <div fxFlex="100" fxLayoutAlign="center center" *ngIf="islistempty(sensorWithoutSOP)"> {{'dashboard.sensors_without_sop'|translate|titlecase}}</div>
        </div>
        <!-- <mat-divider></mat-divider> -->
        <div style="height: 16.2rem;">
            <div  fxFlex="100" style="overflow: auto;" class="scrollnull" *ngIf="islistempty(inactivesensor)">
                <div class="sensorfield" fxLayoutGap="12px" *ngFor="let item of inactivesensor" fxLayoutAlign="space-between center">
                    <div class="iconfieldinactive">
                        <mat-icon class="svgicon" svgIcon="{{getIcon(item?.product_code)}}" style="margin: 0%;height: 16px;width: 16px;"></mat-icon>
                    </div>
                    <div fxFlex="90"   fxLayout="row" class="inactivemob">
                        <div fxFlex="50"  style="display: flex;overflow: hidden;"  fxLayoutAlign="start center" class="sensornameinactive maxwidinactive">
                            <div style="font-size: 12px;text-overflow: ellipsis;white-space: nowrap;overflow: hidden;">{{item?.name}}</div>
                        </div>
                        <div  fxFlex="40"  fxLayout="row"  fxLayoutGap="5px" fxLayoutAlign="start center"  class="maxwidinactive">
                            <mat-icon class="clockicon" svgIcon="clock" style="margin: 0%;height: 16px;width: 16px;min-width: 16px;" ></mat-icon>
                            <div style="font-size: 12px;color:#ff0000; white-space: nowrap;overflow: hidden;text-overflow: ellipsis;">{{getDate(item?.latest_timestamp)| date:'MMM dd'}},{{getDate(item?.latest_timestamp)| date:'shortTime'}}</div>
                        </div>
                    </div>
                </div>
            </div>
            <mat-divider [vertical]="true" *ngIf="islistempty(sensorWithoutSOP) && islistempty(inactivesensor)"></mat-divider>
            <div  fxFlex="100"  style="overflow: auto;" class="scrollnull" *ngIf="islistempty(sensorWithoutSOP)">
                <div class="sensorfield" fxLayoutGap="12px" *ngFor="let item of sensorWithoutSOP" fxLayoutAlign="start center">
                    <div class="iconfieldinactive">
                        <mat-icon class="svgicon" svgIcon="{{getIcon(item?.product_code)}}" style="margin: 0%;height: 16px;width: 16px;"></mat-icon>
                    </div>
                    <div fxFlex="80"  style="display: flex;overflow: hidden;"  fxLayoutAlign="start center">
                        <div style="font-size: 12px;text-overflow: ellipsis;white-space: nowrap;overflow: hidden;">{{item?.name}}</div>
                    </div>
                </div>
            </div>
        </div>
        <mat-divider ></mat-divider>
        <div fxLayoutAlign="center center" style="min-height: 2.5rem;font-size: 12px;font-weight: 600;padding: 10px 20px;white-space: break-spaces;">{{'dashboard.live_score_active_msg'|translate|titlecase}}</div>
    </div>
</div>
