import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { dashboardvo, chartvarity, summarizedata, sensorvarity } from './Dashboard.model';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
// const summery: summarizedata[] = [
//   { lable: 'Selected Eggs', value: '--' },
//   { lable: 'Rejected Eggs', value: '--' },
//   { lable: 'Total Eggs', value: '--' },
//   { lable: 'Mortality', value: '--' },
//   { lable: 'Avg feed per bird', value: '--' },
//   { lable: 'Avg weight gain per bird ', value: '--' },
//   { lable: 'Avg weight per bird ', value: '--' },
// ];
const summeryFish: summarizedata[] = [

  { lable: 'Mortality', value: '--' },
  { lable: 'Avg feed per fish', value: '--' },
  { lable: 'Avg weight gain per fish', value: '--' },
  { lable: 'Avg weight per fish', value: '--' },
];
const summeryShrimp: summarizedata[] = [

  { lable: 'Mortality', value: '--' },
  { lable: 'Avg feed per shrimp', value: '--' },
  { lable: 'Avg weight gain per shrimp', value: '--' },
  { lable: 'Avg weight per shrimp', value: '--' },
];
// const summeryBroiler: summarizedata[] = [
//   { lable: 'Mortality', value: '--' },
//   { lable: 'Avg feed per bird', value: '--' },
//   { lable: 'Avg weight gain per bird ', value: '--' },
//   { lable: 'Avg weight per bird ', value: '--' },
// ];
const summeryMale: summarizedata[] = [
  { lable: 'Mortality', value: '--' },
  { lable: 'Avg feed per bird', value: '--' },
  { lable: 'Avg weight gain per bird ', value: '--' },
  { lable: 'Avg weight per bird ', value: '--' },
];
// const henChartprodective: chartvarity = {
//   chartmodel: 'Egg productivity',
//   chartOptions: {
//     series: [],
//     chart: {
//       type: 'bar',
//       height: 285,
//       stacked: true,
//     },
//     annotations: {

//     },
//     dataLabels: {
//       enabled: false,
//     },
//     stroke: {
//       curve: 'straight',
//       width: 0,
//     },
//     grid: {
//       padding: {
//         right: 30,
//         left: 20,
//       },
//     },

//     labels: [],
//     xaxis: {
//       // type: 'datetime',
//       labels: {
//         formatter: function (val) {
//           return '' + new DatePipe('en_IN').transform(new Date(val), 'dd MMM');
//         }
//       }, tooltip: {
//         enabled: false
//       }
//     },
//     fill: {
//       colors: ['#00a269', '#d51d00']
//     },
//     legend: {
//       show: true,
//       showForSingleSeries: true,
//       customLegendItems: ["Selected Eggs", "Rejected Eggs"],
//       markers: {
//         fillColors: ["#00a269", "#d51d00"]
//       }
//     },
//     tooltip: {
//       custom: function ({ series, seriesIndex, dataPointIndex, w }) {
//         var data = w.globals.initialSeries[seriesIndex].data[dataPointIndex];
//         var series = w.globals.initialSeries[seriesIndex];
//         var seriesName = series.name;
//         var color = seriesName == 'Selected Eggs' ? '#26b07f' : '#aa3226';
//         return '<div style="height:100px;min-width:160px">' +
//           '<div style="height:34px;background-color:#eceff1;border-bottom:1px solid #dddddd;display: flex;align-items:center;padding-left: 5px;font-size:12px">' +
//           new DatePipe('en_IN').transform(new Date(data.x), 'dd MMM') + '</div>' +
//           '<div style="height: 62px;background-color:white;padding-bottom: 4px;">' +
//           '<div style="height:50%;display:flex">' +
//           '<div style="width:33px;display: flex;align-items:center;justify-content:center">' +
//           '<div style="width: 14px;height: 14px;background-color: #00adff;border-radius: 50%;"></div>' +
//           '</div>' +
//           '<div style="display: flex;align-items: center;font-size:12px;padding-right:8px;"><div>Total egg</div>:' +
//           '<div style="margin-left: 6px;font-weight: 600;"> ' + data.total + '</div></div>' +
//           '</div>' +
//           '<div style="height:50%;display:flex">' +
//           '<div style="width:33px;display: flex;align-items:center;justify-content:center">' +
//           '<div style="width: 14px;height: 14px;background-color:' + color + ';border-radius: 50%;"></div>' +
//           '</div>' +
//           '<div style="display: flex;align-items: center;font-size:12px;padding-right:8px;"><div>' + seriesName + '</div>:' +
//           '<div style="margin-left: 6px;font-weight: 600;"> ' + data.y + '</div></div>' +
//           '</div>' +
//           '</div>' +
//           '</div>';
//       }
//     },
//   },
//   pieOptions: {}
// };

const henChartMortality: chartvarity = {
  chartmodel: 'Mortality',
  chartOptions: {
    series: [],
    chart: {
      type: 'bar',
      height: 285,
    },
    annotations: {

    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'straight',
      width: 3,
      colors: ['#e75400']
    },

    grid: {
      padding: {
        right: 30,
        left: 20,
      },
    },
    labels: [],
    xaxis: {
      // type: 'datetime',
      labels: {
        formatter: function (val) {
          return '' + new DatePipe('en_IN').transform(new Date(val), 'dd MMM');
        }
      }, tooltip: {
        enabled: false
      }
    },
    fill: {
      colors: ['#e75400']
    },
    legend: {
      show: true,
      showForSingleSeries: true,
      customLegendItems: ["Mortality"],
      markers: {
        fillColors: ["#e75400"]
      }
    },
  },
  pieOptions: {}
};

const MaleBirdMortality: chartvarity = {
  chartmodel: 'Mortality',
  chartOptions: {
    series: [],
    chart: {
      type: 'bar',
      height: 285,
    },
    annotations: {

    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'straight',
      width: 3,
      colors: ['#1d00e7']
    },
    grid: {
      padding: {
        right: 30,
        left: 20,
      },
    },

    labels: [],
    xaxis: {
      // type: 'datetime',
      labels: {
        formatter: function (val) {
          return '' + new DatePipe('en_IN').transform(new Date(val), 'dd MMM');
        }
      }, tooltip: {
        enabled: false
      }
    },
    fill: {
      colors: ['#1d00e7']
    },
    legend: {
      show: true,
      showForSingleSeries: true,
      customLegendItems: ["Mortality"],
      markers: {
        fillColors: ["#1d00e7"]
      }
    },
  },
  pieOptions: {}
};
const MaleBirdVaccination: chartvarity = {
  chartmodel: 'Vaccination',
  chartOptions: {
    series: [],
    chart: {
      type: 'bar',
      height: 285,
    },
    annotations: {},
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'straight',
      width: 3,
    },
    grid: {
      padding: {
        right: 30,
        left: 20,
      },
    },

    labels: [],
    xaxis: {
      // type: 'datetime',
      labels: {
        formatter: function (val) {
          return '' + new DatePipe('en_IN').transform(new Date(val), 'dd MMM');
        }
      },
      tooltip: {
        enabled: false
      }
    },
  },
  pieOptions: {}
};
const labourChart: chartvarity = {
  chartmodel: 'Labour count',
  chartOptions: {
    series: [],
    chart: {
      type: 'bar',
      height: 285,
    },
    annotations: {

    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'straight',
      width: 0,
    },
    grid: {
      padding: {
        right: 30,
        left: 20,
      },
    },

    labels: [],
    xaxis: {
      // type: 'datetime',
      labels: {
        formatter: function (val) {
          return '' + new DatePipe('en_IN').transform(new Date(val), 'dd MMM');
        }
      },
      tooltip: {
        enabled: false
      }
    }, fill: {
      colors: ['#17cb11']
    },
    legend: {
      show: true,
      showForSingleSeries: true,
      customLegendItems: ["Labour count"],
      markers: {
        fillColors: ["#17cb11"]
      }
    },
  },
  pieOptions: {}
};
const ToolsChart: chartvarity = {
  chartmodel: 'Equipments',
  chartOptions: {},
  pieOptions: {
    series: [44, 55, 13, 43, 22],
    chart: {
      type: "donut",
      height: 285,
    },
    labels: ["Equipment-1", "Equipment-2", "Equipment-3", "Equipment-4", "Equipment-5"],
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200
          },
          legend: {
            position: "bottom"
          }
        }
      }
    ]
  }
};
const feedvsweight: chartvarity = {
  chartmodel: 'Feed Vs Weight',
  chartOptions: {
    series: [],
    chart: {
      height: 350,
      type: "line"
    },
    stroke: {
      width: [0, 4],
      colors: ['#8AD06E', '#FDC625']
    },
    dataLabels: {
      enabled: false,
    },
    labels: [],
    xaxis: {
      // type: "datetime"
      // labels: {
      //   formatter: function (val) {
      //     return ''+new DatePipe('en_IN').transform(new Date(val), 'dd MMM')
      //   }
      // }
      tooltip: {
        enabled: false
      }
    },
    yaxis: [
      {
        title: {
          text: "Avg Feed in (g)",
          style: {
            color: "#8AD06E"
          }
        }
      },
      {
        opposite: true,
        title: {
          text: "Avg Weight in (g)",
          style: {
            color: "#FDC625"
          }
        }
      }
    ],
    fill: {
      colors: ['#8AD06E', '#FDC625']
    },
    legend: {
      show: true,
      showForSingleSeries: true,
      customLegendItems: [],
      markers: {
        fillColors: ["#8AD06E", '#FDC625']
      }
    },
  },
  pieOptions: {}
};

const fcrChart: chartvarity = {
  pieOptions: {},
  chartmodel: 'FCR',
  chartOptions: {
    series: [],
    chart: {
      id: "fb",
      group: "social",
      type: "line",
      height: 250
    },

    colors: ["black"],
    yaxis: {

      title: {
        text: "FCR",
        style: {
          color: "#8AD06E"
        }
      },
      tickAmount: 0,
      labels: {

        minWidth: 5
      }
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      curve: "straight",
      width: 3,
      colors: ["#000"]
    },
    markers: {
      colors: ['"#000"'],
      size: 4,
      hover: {
        size: 5
      }
    },
    tooltip: {
      followCursor: false,
      theme: "dark",
      x: {
        show: false
      },
      marker: {
        show: false
      },
      y: {
        title: {
          formatter: function () {
            return "";
          }
        }
      }
    },
    xaxis: {
      title: {
        text: "AGE",
        style: {
          color: "#8AD06E"
        }
      },
      categories: []
    }
  }
};

const ScoreChart: chartvarity = {
  chartmodel: 'Scores',
  chartOptions: {
    series: [],
    chart: {
      height: 275,
      type: "line",
    },
    stroke: {
      width: [2, 2, 2, 2],
      colors: ['#44b8c6', '#48a539', '#f5d600', '#e2116a']
    },
    dataLabels: {
      enabled: false,
    },
    labels: [],
    xaxis: {
      type: "datetime",
      tooltip: {
        enabled: false
      }
    },
    yaxis: [
    ],
    fill: {
      colors: ['#44b8c6', '#48a539', '#f5d600', '#e2116a']
    },
    legend: {
      show: true,
      showForSingleSeries: true,
      customLegendItems: [],
      markers: {
        fillColors: ["#44b8c6", '#48a539', '#f5d600', '#e2116a']
      }
    },
    tooltip: {
      marker: {
        fillColors: []
      }
    }
  },
  pieOptions: {}
};
const SensorHistory: chartvarity = {
  chartmodel: 'sensor',
  chartOptions: {
    series: [
    ],
    chart: {
      type: 'line',
      height: 285,
    },
    annotations: {
      yaxis: [
        {
          y: 0,
          borderColor: '#ff0000',
          strokeDashArray: 2,

          // label: {
          //   borderColor: '#e75400',
          //   style: {
          //     color: '#fff',
          //     background: '#e75400',
          //     padding:{
          //       right:5,
          //       left:5
          //     }
          //   },
          //   position: 'left',
          //   textAnchor: 'hide',
          //   offsetX: 5,
          //   text: '',
          // },

        },
        {
          y: 0,
          borderColor: '#1d00e7',
          strokeDashArray: 1,
          // label: {
          //   borderColor: '#1d00e7',
          //   style: {
          //     color: '#fff',
          //     background: '#1d00e7'
          //   },
          //   text: ''
          // }
        }
      ]
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'straight',
      width: 5,
      colors: ['#e75400', '#1d00e7']
    },

    grid: {
      padding: {
        right: 30,
        left: 20,
      },
    },
    labels: [],
    xaxis: {
      type: 'datetime',
      labels: {
        datetimeUTC: false
      }, tooltip: {
        enabled: false
      }
    },
    fill: {
      colors: ['#e75400', '#1d00e7']
    },
    legend: {
      show: true,
      showForSingleSeries: true,
      customLegendItems: [],
      markers: {
        fillColors: ["#e75400", '#1d00e7']
      }
    },
    tooltip: {
      x: {
        formatter: function (val) {
          return '<div>' + new DatePipe('en_IN').transform(new Date(val), 'dd MMM HH:mm') + '</div>';
        }
      },
    },
  },
  pieOptions: {}
};

const alerts: string[] = ['Possibility of a huge workforce.', 'Contribution to the output of the industrial sector.', 'Obtaining foreign currency.',
  'Less capital is required.', 'Distribution that is fair', 'Make use of local resources.', 'Cost-effectiveness'
];
const alertsMale: string[] = ['Possibility of a huge workforce.', 'Contribution to the output of the industrial sector.', 'Obtaining foreign currency.',
  'Less capital is required.'
];
// const imagedata: any = {
//   temperature: 'Temperature',
//   humidity: 'Humidity',
//   anemometer: 'Wind',
//   lux: 'Light',
//   ammonia: 'NH3',
//   carbon_monoxide: 'CO',
//   pressure: 'Pressure',
//   carbon_dioxide: 'Co2'
// }

const sensorunit: any = {};

const alertLevel: any = {
  too_low: 'Too Low',
  low: 'Low',
  high: 'High',
  too_high: 'Too High'
};

const sensor_types: any = {
};

// const sensor_types2: any = {
//   "001": "TempHumid",
//   "003": "ammonia",
// }
// const sensorStaticData = [
//   { sensor: 'temperature_and_humidity', apirelay: 'temperature_and_humidity', filterdata: ['temperature', 'humidity'], displayName: 'Temperature,Humidity', emptyimg: '../../../assets/images/empty/Tempndhum.png' },
//   { sensor: 'anemometer', apirelay: 'anemometer', filterdata: ['anemometer'], displayName: 'Air flow', emptyimg: '../../../assets/images/empty/airflow.png' },
//   { sensor: 'lux', apirelay: 'lux', filterdata: ['lux'], displayName: 'Light', emptyimg: '../../../assets/images/empty/light.png' },
//   { sensor: 'ammonia_and_co', apirelay: 'ammonia_and_co', filterdata: ['ammonia', 'carbon_monoxide'], displayName: 'Ammonia,Co', emptyimg: '../../../assets/images/empty/ammonia.png' },
//   { sensor: 'pressure', apirelay: 'pressure', filterdata: ['pressure'], displayName: 'Pressure', emptyimg: '../../../assets/images/empty/Pressure.png' },
//   { sensor: 'carbon_dioxide', apirelay: 'carbon_dioxide', filterdata: ['carbon_dioxide'], displayName: 'Co2', emptyimg: '../../../assets/images/empty/Co2.png' }
// ]
// const constants: any = {
//   carbon_dioxide: 'Co2',
//   pressure: 'Pressure',
//   ammonia: 'Ammonia',
//   carbon_monoxide: 'Co',
//   lux: 'Light',
//   anemometer: 'Airflow',
//   temperature: 'Temperature',
//   humidity: 'Humidity'
// }
const constant: any = {
  growth: 'Growth',
  productivity: 'Productivity',
  mortality: 'Mortality',
  environmental: 'Environmental',
};

@Injectable({
  providedIn: 'root'
})
export class DashboardService {
  SensorHistory = SensorHistory;
  alertLevel = alertLevel;
  ScoreChart = ScoreChart;
  constant = constant;
  sensor_types = sensor_types;
  sensorunit = sensorunit;
  fcrchat = fcrChart;

  // imagedata = imagedata;
  // sensor_types2 = sensor_types2;
  // sensorStaticData = sensorStaticData;
  // constants = constants;
  // malerole = ['Mortality', 'Vaccination', 'Feed Vs Weight']
  fishrole = ['Mortality', 'Feed Vs Weight', 'FCR'];
  shrimprole = ['Mortality', 'Feed Vs Weight', 'FCR'];
  constructor(private iconRegistry: MatIconRegistry, private sanitizer: DomSanitizer) {
    // this.registerIconFromBase64()
  }
  fishData: dashboardvo = {
    type: 'Fish',
    data: summeryFish,
    alerts: alerts,
    chartData: [henChartMortality, feedvsweight, fcrChart],
    roles: [{ id: 1, name: 'Mortality' }, { id: 2, name: 'Feed Vs Weight' }, { id: 3, name: 'FCR' }],
    empty: '../../../assets/images/empty/bg_fish.svg',
    icon: 'fish'
  };
  registerIconFromBase64(product: any[]): void {
    this.setimgtype(product);
    product.forEach((value: any) => {
      value.data.forEach((value2: any) => {
        const svgString = this.extractBase64Data(value2.icon);
        const svgElement = this.sanitizer.bypassSecurityTrustHtml(svgString);
        this.iconRegistry.addSvgIconLiteral(value2.name, svgElement);
      });
      if (value.data.length > 1) {
        const svgString = this.extractBase64Data(value.icon);
        const svgElement = this.sanitizer.bypassSecurityTrustHtml(svgString);
        this.iconRegistry.addSvgIconLiteral(value.product_name, svgElement);
      }
    });
  }
  setimgtype(product: any[]) {
    product.forEach((value: any) => {
      if (value.data.length == 1) {
        this.sensor_types[value.product_code] = value.data[0].name;
      } else {
        this.sensor_types[value.product_code] = value.product_name;
      }
      value.data.forEach((value2: any) => {
        this.sensorunit[value2.name] = value2.unit;
      });
    });
  }
  extractBase64Data(base64String: string): string {
    const prefix = 'data:image/svg+xml;base64,';
    if (base64String.startsWith(prefix)) {
      return atob(base64String.slice(prefix.length));
    } else {
      throw new Error('Invalid base64 format. Make sure the string is properly encoded.');
    }
  }
  shrimpData: dashboardvo = {
    type: 'Shrimp',
    data: summeryShrimp,
    alerts: alerts,
    chartData: [henChartMortality, feedvsweight, fcrChart],
    roles: [{ id: 1, name: 'Mortality' }, { id: 2, name: 'Feed Vs Weight' }, { id: 3, name: 'FCR' }],
    empty: '../../../assets/images/empty/bg_shrimp.svg',
    icon: 'shrimp'
  };
  // maleBirdData: dashboardvo = {
  //   type: 'Male Bird',
  //   data: summeryMale,
  //   alerts: alertsMale,
  //   chartData: [MaleBirdMortality, MaleBirdVaccination, feedvsweight, fcrChart],
  //   roles: [{ id: 1, name: 'Mortality' }, { id: 2, name: 'Feed Vs Weight' }, { id: 3, name: 'FCR' }],
  //   empty: '../../../assets/images/empty/bg_fish.svg',
  //   icon: 'Rooster'
  // };
  workers: dashboardvo = {
    type: 'Labours',
    data: summeryShrimp,
    alerts: alertsMale,
    chartData: [labourChart],
    roles: [],
    empty: '../../../assets/images/empty/labour.png',
    icon: 'Worker'
  };
  tools: dashboardvo = {
    type: 'Equipments',
    data: summeryShrimp,
    alerts: alertsMale,
    chartData: [ToolsChart],
    roles: [],
    empty: '../../../assets/images/empty/Equipment.png',
    icon: 'Tools'
  };
  // temperature: sensorvarity = {
  //   imageName: 'Temperature',
  //   liveData: 'Temperature',
  //   variables: ['Name', 'Device ID', 'Zone', 'Product Code'],
  //   unit: 'ºC'
  // }
  // pressure: sensorvarity = {
  //   imageName: 'Pressure',
  //   liveData: 'Pressure',
  //   variables: ['Name', 'Device ID', 'Zone', 'Product Code'],
  //   unit: 'psi'
  // }
  // humidity: sensorvarity = {
  //   imageName: 'Humidity',
  //   liveData: 'Humidity',
  //   variables: ['Name', 'Device ID', 'Zone', 'Product Code'],
  //   unit: '%'
  // }
  // wind: sensorvarity = {
  //   imageName: 'Wind',
  //   liveData: 'Air flow',
  //   variables: ['Name', 'Device ID', 'Zone', 'Product Code'],
  //   unit: 'm/s'
  // }
  // ammonia: sensorvarity = {
  //   imageName: 'NH3',
  //   liveData: 'Ammonia',
  //   variables: ['Name', 'Device ID', 'Zone', 'Product Code'],
  //   unit: 'ppm'
  // }
  // carbonmo: sensorvarity = {
  //   imageName: 'CO',
  //   liveData: 'Carbon monoxide',
  //   variables: ['Name', 'Device ID', 'Zone', 'Product Code'],
  //   unit: 'ppm'
  // }
  // carbondi: sensorvarity = {
  //   imageName: 'Co2',
  //   liveData: 'Carbon dioxide ',
  //   variables: ['Name', 'Device ID', 'Zone', 'Product Code'],
  //   unit: 'ppm'
  // }
  // light: sensorvarity = {
  //   imageName: 'Light',
  //   liveData: 'Light',
  //   variables: ['Name', 'Device ID', 'Zone', 'Product Code'],
  //   unit: 'Lux'
  // }
  // temphumid: sensorvarity[] = [
  //   {
  //     imageName: 'Temperature',
  //     liveData: 'Temperature',
  //     variables: ['Name', 'Device ID', 'Zone', 'Product Code'],
  //     unit: 'ºC'
  //   }, {
  //     imageName: 'Humidity',
  //     liveData: 'Humidity',
  //     variables: ['Name', 'Device ID', 'Zone', 'Product Code'],
  //     unit: '%'
  //   }
  // ]
}