import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CWA, DAY_ENTRY, SETTINGS } from 'src/app/config/api.config';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {
  private BASE_URL = environment.BASE_URL;
  constructor(private http: HttpClient) { }

  public saveIot(iot: any): Observable<any> {
    return this.http.post(`${this.BASE_URL}${SETTINGS.IOT}`, iot);

  }
  public getIotSerialNo() {
    const tank_id = JSON.parse(localStorage.getItem('tank') || '')?.id;
    return this.http.get(`${this.BASE_URL}${SETTINGS.GET_IOT_SERIAL_NO}/${tank_id}`);

  }
  public updateIotSerialNo(serial_no_id: any, data: any): Observable<any> {
    return this.http.put(`${this.BASE_URL}${SETTINGS.IOT}/${serial_no_id}`, data);

  }
  public deleteIotSerialNo(id: any): Observable<any> {
    return this.http.delete(`${this.BASE_URL}${SETTINGS.IOT}/${id}`);

  }
  public getTopicUsingTankANDSerialNO(serial_no: any): Observable<any> {
    const shed_id = JSON.parse(localStorage.getItem('tank') || '')?.id;
    return this.http.get(`${this.BASE_URL}${SETTINGS.GET_TOPIC}?serial_no=${serial_no}&tank_id=${shed_id}`);

  }
  public syncAlertsAlarm(): Observable<any> {
    const shed_id = JSON.parse(localStorage.getItem('tank') || '')?.id;
    return this.http.get(`${this.BASE_URL}${SETTINGS.SYNCALERTSALARM}/${shed_id}`);
  }
  public syncAlertsAlarmFlag(): Observable<any> {
    const shed_id = JSON.parse(localStorage.getItem('tank') || '')?.id;
    return this.http.get(`${this.BASE_URL}${SETTINGS.SYNCALERTSALARMSTATUS}/${shed_id}`);
  }
  public syncSensor(): Observable<any> {
    const shed_id = JSON.parse(localStorage.getItem('tank') || '')?.id;
    return this.http.get(`${this.BASE_URL}${SETTINGS.SYNCSENSORCONFIG}/${shed_id}`);
  }
  public syncSensorFlag(): Observable<any> {
    const shed_id = JSON.parse(localStorage.getItem('tank') || '')?.id;
    return this.http.get(`${this.BASE_URL}${SETTINGS.SYNCSENSORCONFIGSTATUS}/${shed_id}`);
  }
  public saveSensorAlarm(alarm: any): Observable<any> {
    return this.http.post(`${this.BASE_URL}${SETTINGS.SAVESENSORALARM}`, alarm);

  }
  public getSensorAlarm(): Observable<any> {
    const tank_id = JSON.parse(localStorage.getItem('tank') || '')?.id;
    return this.http.get(`${this.BASE_URL}${SETTINGS.GETSENSORALARM}/${tank_id}`);
  }
  public updateSensorAlarm(alarm: any, id: any): Observable<any> {
    return this.http.put(`${this.BASE_URL}${SETTINGS.UPDATEALARM}${id}`, alarm);
  }
  public deleteSensorAlarm(id: any): Observable<any> {
    return this.http.delete(`${this.BASE_URL}${SETTINGS.DELETEALARM}${id}`);
  }
  public saveEquipment(equipment: any): Observable<any> {
    return this.http.post(`${this.BASE_URL}${SETTINGS.SAVEEQUIPMENT}`, equipment);

  }
  public getEquipment(): Observable<any> {
    return this.http.get(`${this.BASE_URL}${SETTINGS.GETEQUIPMENT}`);

  }
  public getAllEquipments() {
    const tank_id = JSON.parse(localStorage.getItem('tank') || '')?.id;
    return this.http.get(`${this.BASE_URL}${DAY_ENTRY.GET_EQUIPMENT}/${tank_id}`);
  }
  public updateEquipment(equipment: any, id: any): Observable<any> {
    return this.http.put(`${this.BASE_URL}${SETTINGS.SAVEEQUIPMENT}${id}`, equipment);

  }
  public deleteEquipment(id: any): Observable<any> {
    return this.http.delete(`${this.BASE_URL}${SETTINGS.SAVEEQUIPMENT}${id}`);
  }
  public getFaultEquipment(): Observable<any> {
    const tank_id = JSON.parse(localStorage.getItem('tank') || '')?.id;
    return this.http.get(`${this.BASE_URL}${SETTINGS.GETFAULTEQUIPMENT}/${tank_id}`);

  }
  public savefault(fault: any): Observable<any> {
    return this.http.post(`${this.BASE_URL}${SETTINGS.SAVEFAULT}`, fault);

  } public updateFault(equipment: any, id: any): Observable<any> {
    return this.http.put(`${this.BASE_URL}${SETTINGS.SAVEFAULT}${id}`, equipment);

  }
  public deleteFault(id: any): Observable<any> {
    return this.http.delete(`${this.BASE_URL}${SETTINGS.SAVEFAULT}${id}`);
  }

  public saveSensors(sensors: any): Observable<any> {
    return this.http.post(`${this.BASE_URL}${SETTINGS.SAVESENSORS}`, sensors);

  }
  public getSensors(): Observable<any> {
    const tank_id = JSON.parse(localStorage.getItem('tank') || '')?.id;
    return this.http.get(`${this.BASE_URL}${SETTINGS.GET_SENSORS}/${tank_id}`);
  }
  public getAllSensors(): Observable<any> {
    return this.http.get(`${this.BASE_URL}${SETTINGS.GET_All_SENSORS}`);
  }
  public getAllUnits(): Observable<any> {
    return this.http.get(`${this.BASE_URL}${SETTINGS.GET_All_UNITS}`);
  }
  public updateSensor(sensor: any, id: any): Observable<any> {
    return this.http.put(`${this.BASE_URL}${SETTINGS.UPDATESENSORS}${id}`, sensor);
  }
  public deleteSensor(id: any): Observable<any> {
    return this.http.delete(`${this.BASE_URL}${SETTINGS.DELETESENSOR}${id}`);
  }
  public syncAlertsLevelFlag(): Observable<any> {
    const shed_id = JSON.parse(localStorage.getItem('tank') || '')?.id;
    return this.http.get(`${this.BASE_URL}${SETTINGS.SYNCALERTSLEVELSTATUS}/${shed_id}`);
  }
  public syncAlertsLevel(): Observable<any> {
    const shed_id = JSON.parse(localStorage.getItem('tank') || '')?.id;
    return this.http.get(`${this.BASE_URL}${SETTINGS.SYNCALERTSLEVEL}/${shed_id}`);
  }
  public syncUserSettings(): Observable<any> {
    const shed_id = JSON.parse(localStorage.getItem('tank') || '')?.id;
    return this.http.get(`${this.BASE_URL}${SETTINGS.SYNCUSERSETTINGS}/${shed_id}`);
  }
  public saveAlert(alert: any): Observable<any> {
    return this.http.post(`${this.BASE_URL}${SETTINGS.SAVEALERT}`, alert);
  }
  public companyWideAlert(data: any): Observable<any> {
    const user_id = JSON.parse(localStorage.getItem('user_data') || '')?.user_id;
    return this.http.put(`${this.BASE_URL}${CWA.SAVE_CWA}/${user_id}`, data);
  }

  public getAlarts(): Observable<any> {
    const tank_id = JSON.parse(localStorage.getItem('tank') || '')?.id;
    return this.http.get(`${this.BASE_URL}${SETTINGS.GETALERTS}/${tank_id}`);
  }
  public updateAlert(alert: any, id: any): Observable<any> {
    return this.http.patch(`${this.BASE_URL}${SETTINGS.UPDATEALERT}${id}`, alert);
  }
  public deleteAlert(id: any): Observable<any> {
    return this.http.delete(`${this.BASE_URL}${SETTINGS.DELETEALERT}${id}`);
  }
  public getEquipmentDrop(): Observable<any> {
    return this.http.get(`${this.BASE_URL}${SETTINGS.EQUIPMENTDROPDOWN}`);
  }
  public getSensorType(): Observable<any> {
    return this.http.get(`${this.BASE_URL}${SETTINGS.SENSORTYPE}`);
  }
  public getEnvironmentScore(): Observable<any> {
    const tank_id = JSON.parse(localStorage.getItem('tank') || '')?.id;
    return this.http.get(`${this.BASE_URL}${SETTINGS.GETENVIRONMENTSCORE}/${tank_id}`);
  }
  public saveEnvironmentScore(Score: any): Observable<any> {
    return this.http.post(`${this.BASE_URL}${SETTINGS.ENVIRONMENTSCORE}`, Score);
  }
  public updateEnvironmentScore(Score: any, id: any): Observable<any> {
    return this.http.put(`${this.BASE_URL}${SETTINGS.ENVIRONMENTSCORE}${id}`, Score);
  }
  public deleteEnvironmentScore(id: any): Observable<any> {
    return this.http.delete(`${this.BASE_URL}${SETTINGS.ENVIRONMENTSCORE}${id}`);
  }
  public getAllScoreAlertLevelData(type: any) {
    const shed_id = JSON.parse(localStorage.getItem('tank') || '')?.id;
    return this.http.get(`${this.BASE_URL}${SETTINGS.SCOREALERT}${shed_id}&type=${type}`)
  }

  public saveScoreAlertData(data: any) {
    return this.http.post(`${this.BASE_URL}${SETTINGS.saveScore}`, data);
  }
  public updateScoreAlertData(id: any, data: any) {
    return this.http.put(`${this.BASE_URL}${SETTINGS.saveScore}/${id}`, data);
  }

}
