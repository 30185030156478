import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ManagementRegisterComponent } from './management-register/management-register.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { InfoComponent } from './info/info.component';
import { LoginComponent } from './login/login.component';
import { OtpComponent } from './otp/otp.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';

const routes: Routes = [
  {
    path: "",
    children: [

      {
        path: "",
        redirectTo: "/login",
        pathMatch: "full"
      },

      {
        path: "login",
        component: LoginComponent,
      },
    ]
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent
  },
  {
    path: 'otp',
    component: OtpComponent
  }
  ,
  {
    path: 'reset-password',
    component: ResetPasswordComponent
  }
  ,
  {
    path: 'management-register/:user_data',
    component: ManagementRegisterComponent
  }
  ,
  {
    path: 'info',
    component: InfoComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AuthRoutingModule { }
