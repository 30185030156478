import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './core/auth-guard/auth.guard';
import { LoginGuard } from './core/login-guard/login.guard';
import { ShedGuard } from './core/shed-guard/shed.guard';
import { DayWiseEntryComponent } from './pages/day-wise-entry/day-wise-entry.component';
import { FullLayoutComponent } from './pages/layout/full-layout/full-layout.component';
import { ScoreComponent } from './pages/score/score.component';
import { PermissionGuard } from './core/permission-guard/permission.guard';
import { ViewreportComponent } from './pages/report/viewreport/viewreport.component';

const routes: Routes = [
  {
    path: "",
    canActivate: [LoginGuard],
    children: [
      {
        path: "",
        redirectTo: "/auth/login",
        pathMatch: "full"
      },
      {
        path: "auth",
        loadChildren: () => import("../app/auth/auth.module").then(m => m.AuthModule),
      }
    ]

  },
  {
    path: 'home',
    canActivate: [AuthGuard],
    loadChildren: () => import("./pages/home/home.module").then(m => m.HomeModule)

  },
  {
    path: "",
    component: FullLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: "",
        redirectTo: "/main/dashboard",
        pathMatch: "full"
      },
      {
        path: "main",
        canActivate: [ShedGuard],
        loadChildren: () =>
          import("./pages/dashboard/dashboard.module").then(m => m.DashboardModule),
      },
      {
        path: "settings",
        canActivate: [ShedGuard],
        loadChildren: () =>
          import("./pages/settings/settings.module").then(m => m.SettingsModule),
      },
      {
        path: "configuration",
        canActivate: [ShedGuard],
        loadChildren: () =>
          import("./pages/settings/configuration/configuration.module").then(m => m.ConfigurationModule),
      },

      {
        path: "batch",
        canActivate: [ShedGuard],
        loadChildren: () =>
          import("./pages/settings/batch/batch.module").then(m => m.BatchModule),
      },
      {
        path: 'day-entry',
        canActivate: [PermissionGuard, ShedGuard],
        component: DayWiseEntryComponent
      },
      {
        path: 'reports',
        canActivate: [ShedGuard],
        loadChildren: () =>
          import("./pages/report/report/report.module").then(m => m.ReportModule),
      }
      ,
      {
        path: 'score',
        canActivate: [PermissionGuard, ShedGuard],
        component: ScoreComponent
      }
    ]
  },
  {
    path: 'viewreport/:encode',
    canActivate: [AuthGuard],
    component: ViewreportComponent
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
