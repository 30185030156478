import { ApexAxisChartSeries, ApexAnnotations, ApexChart, ApexFill, ApexXAxis, ApexYAxis, ApexDataLabels, ApexGrid, ApexLegend, ApexStroke, ApexTooltip, ApexMarkers, ApexNonAxisChartSeries, ApexResponsive } from "ng-apexcharts";

export class summarizedata {
  lable: string = '';
  value: string | any;
}
export type ChartOption = {
  series: ApexAxisChartSeries | any;
  annotations: ApexAnnotations;
  chart: ApexChart;
  fill: ApexFill;
  xaxis: ApexXAxis;
  yaxis: ApexYAxis | ApexYAxis[];
  dataLabels: ApexDataLabels;
  grid: ApexGrid;
  legend: ApexLegend;
  labels: string[];
  stroke: ApexStroke;
  tooltip: ApexTooltip;
  markers: ApexMarkers;
  colors?: any;
};
export type pieChartOptions = {
  series: ApexNonAxisChartSeries;
  chart: ApexChart;
  responsive: ApexResponsive[];
  labels: any;
};
export class dashboardvo {
  type: string = '';
  data: summarizedata[] = [];
  alerts: string[] = [];
  chartData: chartvarity[] = [];
  roles: any[] = [];
  empty: string = '';
  icon: string = '';
}
export class chartvarity {
  chartmodel: string = '';
  chartOptions: Partial<ChartOption> = {};
  pieOptions: Partial<pieChartOptions> = {};
}
export class labourNdEquipment {
  lable: string = '';
  data: string = '';
}
export class sheddetails {
  id: string = '';
  data: string = '';
}
export class alartdata {
  sensor: string = '';
  shedName: string = '';
  sensorName: string = '';
  deviceId: string = '';
  value: string = '';
  status: string = '';
}
export class sensornameval {
  name: any;
  id: any;
  updatedAt: any;
  list: any[] = [];
}
export class inactiveSensor {
  inactivesensor: any[] = [];
  sensorWithoutSOP: any[] = [];
}
export class sensordata {
  frontData: string = '';
  zone: string = '';
  deviceId: string = '';
  productCode: string = '';
  nameValue: string = '';
  name: string = '';
  updatedDate: string = '';
  unit: string = '';
  iconname: any;
}
export class sensordatamulti {
  frontData1: string = '';
  frontData2: string = '';
  zone: string = '';
  deviceId: string = '';
  productCode: string = '';
  nameValue1: string = '';
  nameValue2: string = '';
  name1: string = '';
  name2: string = '';
  unit1: string = '';
  unit2: string = '';
  updatedDate: string = '';
  iconname1: any;
  iconname2: any;
}
export class sensorvarity {
  imageName: string = '';
  liveData: string = '';
  variables: string[] = [];
  unit: string = '';
}