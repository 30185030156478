<div style="padding: 0 10%;">
    <form [formGroup]="gateway_group" fxLayout="column">
        <div mat-card-title class="label">
            <span> {{'gateway.add_gateway'|translate|titlecase}} </span>
        </div>
        <div fxFlex="50" style="display: flex;justify-content: center;">
            <mat-form-field appearance="outline">
                <mat-label>
                    {{'gateway.type'|translate|titlecase}}
                </mat-label>
                <mat-select formControlName="type" panelClass="PanelClass">
                    <ng-container *ngFor="let unit of TypeList">
                        <mat-option [value]="unit">{{"gateway."+unit |translate|titlecase}}
                        </mat-option>
                    </ng-container>
                </mat-select>
            </mat-form-field>
        </div>
        <div fxFlex="50" style="display: flex;justify-content: center;">
            <mat-form-field appearance="outline">
                <mat-label>{{'gateway.gateway_id'|translate|titlecase}}</mat-label>
                <input  matInput formControlName="gateway_id" type="text" autocomplete="off">
            </mat-form-field>
        </div>
    </form>
    <div fxLayout="row" style="width: 100%;margin-top: 30px;" fxLayoutAlign="center end">
        <div fxFlex="100" fxLayoutAlign="center center" fxLayoutGap="25" fxLayoutAlign.lt-sm="center center"
            class="buttondiv" style="width: 100%;max-height: 6vh;">
            <button fxFlex="50" class="submit grow btn" mat-raised-button style="box-shadow: none;"
                (click)="submit()">{{dialogData?.isUpdate?('button.update'|translate|titlecase):'button.submit'|translate|titlecase}}</button>
            <button fxFlex="50" class="cancel grow btn" mat-raised-button style="box-shadow: none;"
                (click)="cancel()">{{'button.cancel'|translate|titlecase}}</button>
        </div>
    </div>
</div>