import { FormArray } from "@angular/forms";
import { MatTableDataSource } from "@angular/material/table";

export interface displayheader {
  column: string;
  row: number;
  col: number;
}
export interface valueHeader {
  column: string;
  id: string;
}
export interface alarmData {
  sensor: string;
  units: string;
  valueTL: string;
  alertTL: string;
  valueL: string;
  alertL: string;
  valueH: string;
  alertH: string;
  valueTH: string;
  alertTH: string;
  Id: string;
}
export class valuedata {
  code: any;
  unt: any;
  id: any;
  type: any;
  subtype: any;
  cadTrade: caddata = new caddata();
  data2: caddata = new caddata();
  data3: caddata = new caddata();
  data4: caddata = new caddata();
  data5: caddata1 = new caddata1();
  data6: caddata1 = new caddata1();
  data7: caddata1 = new caddata1();
  sensorId?: any;
}
export class caddata {
  total: any;
  amount: any;
}
export class caddata1 {
  amount: any;
}
export class sensorAlarm {
  sensor_id: any;
  unit_id: any;
  too_low_value: any;
  too_low_level_id: any;
  low_value: any;
  low_level_id: any;
  high_value: any;
  high_level_id: any;
  too_high_value: any;
  too_high_level_id: any;
  battery_alert_level_id: any;
  signal_strength_alert_level_id: any;
  sub_type_id: any;
  sensor_type_id: any;
  tank_id: any;
}

export class equipmentVo {
  equipment_name: any;
  tank_id: any;
}
export class equipmenttable {
  name: any;
  id: any;
}
export class faultvo {
  tank_id: any;
  failure_desc: any;
  equipment_id: any;
  failure_code: any
}
export class faulttable {
  name: any;
  type: any;
  fault: any;
  id: any;
}

export class environmentalScore {
  sensorType: string = '';
  sensorName: any = 0;
  headergroup: any;
  multidevicethreshold: MultienvironmentalScore[] = [];
  sensorlist: any[] = []
}
export class MultienvironmentalScore {
  sensorSubType: string = '';
  thresholdList: any = new FormArray([]);
  dataSource = new MatTableDataSource([]);
}
export interface downloadsop {
  data: any,
  max: any
}

