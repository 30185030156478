import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
    providedIn: 'root',
})
export class TranslationService {
    constructor (private translate: TranslateService) {
        this.translate.use(localStorage.getItem('lang') || 'en');
    }

    setLanguage (language: string) {
        localStorage.setItem('lang', language);
        this.translate.use(language);
    }
}
