import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { REPORT } from 'src/app/config/api.config';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ReportsService {

  private BASE_URL = environment.BASE_URL;

  constructor(private http: HttpClient) { }

  public getProductivityReport(report: any): Observable<any> {
    return this.http.post(`${this.BASE_URL}${REPORT.GET_REPORT}`, report);
  }
  public getSensorReport(report: any): Observable<any> {
    return this.http.post(`${this.BASE_URL}${REPORT.GET_SENSOR_REPORT}`, report);
  }
  public getReportCollection(collectionType: string, type: string): Observable<any> {
    const tank_id = JSON.parse(localStorage.getItem('tank') || '')?.id;
    const batch_id = localStorage.getItem('batch_id') ? JSON.parse(localStorage.getItem('batch_id') || '') : null;
    let baseUrl = `${this.BASE_URL}${REPORT.GET_REPORT_COLLECTION}?tank_id=${tank_id}&collection_type=${collectionType}&type=${type}`;
    if (type == 'weekly') {
      baseUrl = baseUrl + `&batch_id=${batch_id}`;
    }
    return this.http.get(baseUrl);
  }
  public getDownloadURL(collectionType: string): Observable<any> {
    return this.http.get(`${this.BASE_URL}${REPORT.GET_DOWNLOAD_URL}?file_key=${collectionType}`);
  }

  public getReportFileAsJson(key: string): Observable<any> {
    return this.http.get(`${this.BASE_URL}${REPORT.GET_REPORT_AS_JSON}?file_key=${key}`);
  }

  downloadFile(url: string): Observable<ArrayBuffer> {
    return this.http.get(url, { responseType: 'arraybuffer' });
  }

  public DownloadURL(URL: string): Observable<any> {
    return this.http.get(URL);
  }

  public regenerateMissing(): Observable<any> {
    const tank_id = JSON.parse(localStorage.getItem('tank') || '')?.id;
    return this.http.post(`${this.BASE_URL}${REPORT.REGENERATE_MISSING}`, { tank_id });
  }

  public regenerateStatus(): Observable<any> {
    const tank_id = JSON.parse(localStorage.getItem('tank') || '')?.id;
    return this.http.get(`${this.BASE_URL}${REPORT.REGENERATE_STATUS}?tank_id=${tank_id}`);
  }

}
