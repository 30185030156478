import { Injectable } from '@angular/core';
import { Socket } from 'ngx-socket-io';

@Injectable({
  providedIn: 'root'
})
export class SocketService {

  constructor (private socket: Socket) {
    setTimeout(() => {
      const userData = {
        socketId: socket?.ioSocket?.id || null
      };
      this.socket.emit("insertSocketId", userData);
    }, 1000);
  }

  public dataChanges = this.socket.fromEvent<any>("sensor_data");
  public recalculation_status = this.socket.fromEvent<any>("recalculation_status");
  public controller = this.socket.fromEvent<any>("controller_data");
  public report_regenerate = this.socket.fromEvent<any>("report_gen_status");
}
