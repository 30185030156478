import { Component, HostListener, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { map, Observable, startWith, Subscription } from 'rxjs';
import { HomeService } from 'src/app/core/services/home/home.service';
import { MessageService } from 'src/app/core/services/message/message.service';
import { REGEX } from 'src/shared/helpers/validators';
import { MessageDialogComponent } from 'src/shared/message-dialog/message-dialog.component';
import { LayoutService } from '../../layout/layout-service/layout.service';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { ViewDetailsDialogComponent } from 'src/shared/view-details-dialog/view-details-dialog.component';

@Component({
  selector: 'app-company',
  templateUrl: './company.component.html',
  styleUrls: ['./company.component.scss']
})
export class CompanyComponent implements OnInit, AfterViewInit, OnDestroy {
  @HostListener('window:beforeunload', ['$event'])
  beforeunloadHandler(event: any) {
    if (this.is_edit_company) {
      event.preventDefault();
      event.returnValue = '';
      localStorage.setItem("show_create_button", JSON.stringify(true));

    }
  }
  public subscriptions: Subscription[] = [];
  public company_list: any = [];
  public company_form_group!: FormGroup;
  public is_create_company = localStorage.getItem('create_button') == 'false';
  public is_edit_company = false;
  public country_list: any = [];
  public state_list: any = [];
  public city_list: any = [];
  public manager_list: any = [];
  public tax_type_list: any = [];
  public current_tab: any = localStorage.getItem('current_tab') ? JSON.parse(localStorage.getItem('current_tab') || '') : 0;
  public user_data: any = localStorage.getItem('user_data') ? JSON.parse(localStorage.getItem('user_data') || '') : null;
  public countryObservable!: Observable<any>;
  public stateObservable!: Observable<any>;

  mobile_dial_code_filter = new FormControl(null);
  public mobileDialcodeObservable!: Observable<any>;
  public is_company_name_changed: boolean = false;
  public link_expire_time: string[] = [];
  public link_expire_interval: any;
  public per_page: number = 10;
  public current_page: number = 1;
  constructor(private fb: FormBuilder,
    private home: HomeService,
    private tab_service: LayoutService,
    private auth: AuthService,
    private show_msg: MessageService,
    private dialog: MatDialog) { }

  ngOnInit() {
    this.initForm();
    this.getAllCompany();
    localStorage.setItem("company", '');
    this.subscriptions = [

      // to get all countries
      this.home.getCountryServices.subscribe((res: any) => {
        if (res?.length) {
          this.country_list = res;
          this.countryObservableFn();
          this.dialCodeObservableFn();
        }
      }),

      // to enable and disable create button
      this.tab_service.hide_home_tab_button.subscribe((label: any) => {
        this.is_create_company = !label;
      })

    ];


  }

  ngAfterViewInit(): void {
    this.companyScrollEndFn();

  }

  // User has reached the bottom of the container
  // Load more company from the API here
  companyScrollEndFn = () => {
    const element = document.getElementById('company-container');
    if (element) {
      element.addEventListener('scroll', () => {
        if (element.scrollTop + element.clientHeight >= (element.scrollHeight - 2)) {
          // this.current_page += 1;
          this.per_page += 10;
          this.getAllCompany();
        }
      });
    }

  };

  companyTrackBy = (index: number, company: Record<string, any>) => {
    return company['company_id'];
  };

  /*---------------------------autocomplete option display function--------------------------------*/

  countryDisplayFn = (country_id: string): string => {
    if (!country_id) return '';
    const option = this.country_list.find((option: any) => {
      return option.country_id == country_id;
    });
    return option ? option.country_name : '';
  };

  stateDisplayFn = (state_id: string): string => {
    if (!state_id) return '';
    const option = this.state_list.find((option: any) => {
      return option.state_id == state_id;
    });
    return option ? option.state_name : '';
  };

  /*-----------------------------------------------------------------------------------------------*/


  /*--------------------------------Obervables----------------------------------------------------*/

  countryObservableFn() {
    this.countryObservable = this.company_form_group.controls['country_id'].valueChanges.pipe(
      startWith(''),
      map(value => {
        const name = typeof value === 'string' ? value : value?.country_name;
        return name ? this.conuntryFilter(name) : this.country_list.slice();
      }

      ),
    );
  }

  stateObservableFn() {
    this.stateObservable = this.company_form_group.controls['state_id'].valueChanges.pipe(
      startWith(''),
      map(value => {
        const name = typeof value === 'string' ? value : value?.state_name;
        return name?.length ? this.stateFilter(name) : this.state_list.slice();
      }

      ),
    );
  }

  dialCodeObservableFn() {
    this.mobileDialcodeObservable = this.mobile_dial_code_filter.valueChanges.pipe(
      startWith(''),
      map((value: any) => {
        const name = typeof value === 'string' ? value : value?.country_name;
        return name ? this.conuntryFilter(name) : this.country_list.slice();;
      }

      ),
    );

  }

  /*-----------------------------------------------------------------------------------------------*/


  /*---------------------------------country and state all functions-------------------------------*/

  dialCodeFocusOut(field: any) {
    if (field == 'Mobile') {
      this.company_form_group.controls['mobile'].markAllAsTouched();
      this.mobile_dial_code_filter.reset();
    }
  }

  focusOutCountryField() {
    const country_id = this.company_form_group.controls['country_id'].value;
    switch (true) {
      case typeof country_id == 'string' && !!country_id.length:
        this.company_form_group.controls['country_id'].setErrors({ isError: true });
        this.company_form_group.controls['state_id'].reset();
        this.state_list = [];
        this.stateObservableFn();
        break;
      case typeof country_id == 'string' && !!!country_id.length:
        this.company_form_group.controls['country_id'].setErrors({ required: true });
        this.company_form_group.controls['state_id'].reset();
        this.state_list = [];
        this.stateObservableFn();
        break;
    }
  }

  focusOutStateField() {
    const state_id = this.company_form_group.controls['state_id'].value;
    switch (true) {
      case typeof state_id == 'string' && !!state_id.length:
        this.company_form_group.controls['state_id'].setErrors({ isError: true });
        break;
      case typeof state_id == 'string' && !!!state_id.length:
        this.company_form_group.controls['state_id'].setErrors({ required: true });
        break;

    }
  }

  onFocusInState() {
    this.company_form_group.controls['state_id'].markAllAsTouched();
  }

  onSelectState() {
    this.company_form_group.controls['state_id'].reset();
    this.getState();
  }

  getState() {
    setTimeout(async () => {
      const country_id = this.company_form_group.controls['country_id'].value;
      this.state_list = await this.state(country_id);
      this.stateObservableFn();
    });

  }

  state(country_id: any): any {
    return new Promise((resolve, reject) => {
      this.subscriptions.push(
        this.home.getState(country_id).subscribe((res: any) => {
          if (res?.length) {
            this.state_list = res;
            resolve(res);
          } else {
            return resolve([]);
          }
        })
      );

    });

  }

  private conuntryFilter(value: string) {
    const filterValue = value?.toLowerCase();
    return this.country_list.filter((option: any) => option?.country_name?.toLowerCase().startsWith(filterValue));

  }

  private stateFilter(value: string) {
    const filterValue = value?.toLowerCase();
    return this.state_list.filter((option: any) => option?.state_name?.toLowerCase().startsWith(filterValue));
  }

  /*-----------------------------------------------------------------------------------------------*/


  // init form group
  async initForm(data?: any) {
    if (data?.country_id) {
      await this.state(data?.country_id);
    }
    this.company_form_group = this.fb.group({
      company_id: new FormControl(data?.company_id),
      company_name: new FormControl(data?.company_name, [Validators.required]),
      company_address: new FormControl(data?.company_address, [Validators.required]),
      zip_code: new FormControl(data?.zip_code, [Validators.required]),
      country_id: new FormControl(data?.country_id, [Validators.required]),
      state_id: new FormControl(data?.state_id, [Validators.required]),
      city_name: new FormControl(data?.city_name, [Validators.required]),
      primary_spoc: new FormControl(data?.primary_spoc, [Validators.required]),
      dial_code_mobile_no: new FormControl(data?.contact_no?.split(" ")[0], [Validators.required]),
      mobile: new FormControl(data?.contact_no?.split(" ")[1], [Validators.required, Validators.minLength(10), Validators.maxLength(10)]),
      primary_email: new FormControl(data?.primary_email, [Validators.required, Validators.pattern(REGEX.EMAIL)]),
      tax_type: new FormControl(data?.tax_type, [Validators.required]),
      tax_no: new FormControl(data?.tax_no, [Validators.required]),
    });

    if (data) {
      this.company_form_group.markAllAsTouched();
    }
  }


  redirectToFarm(data: any) {
    localStorage.setItem('company', JSON.stringify({ id: data.company_id, name: data.company_name }));
    this.tab_service.switchHomeTab(this.current_tab + 1);
  }

  getAllCompany() {
    const reqObj = {
      per_page: this.per_page,
      current_page: this.current_page
    };
    this.subscriptions.push(
      this.home.getAllCompany(reqObj).subscribe((res: any) => {
        if (res.data?.length) {
          // res.data.sort((a: any, b: any) => a.company_name.localeCompare(b.company_name));
          this.company_list = res.data;
          this.resentLinkTimerFn();
        }
      })
    );
  }

  getAllCompanyManager() {
    this.subscriptions.push(
      this.home.getAllCompanyManager().subscribe((res: any) => {
        if (res.length) {
          this.manager_list = res;
        }
      })
    );

  }


  async viewCompany(data: any) {

    const country = this.country_list.find((c: any) => {
      return c.country_id == data.country_id;
    })?.country_name;

    await this.state(data?.country_id);
    const state = this.state_list.find((s: any) => {
      return s.state_id == data?.state_id;
    })?.state_name;

    data.state = state;
    data.country = country;
    const config: MatDialogConfig = {
      panelClass: 'dialog-responsive',
      width: '70%',
      height: '60%',
      disableClose: false,
      data: {
        is_company_detail: true,
        data
      },
    };
    this.dialog.open(ViewDetailsDialogComponent, config);


  }
  //to send mail 
  resendMail(companyData: any, index: any) {
    const reqObj = {
      user_role: 2,
      primary_spoc: companyData.primary_spoc,
      mail_id: companyData.primary_email,
      type: "management_registration",
      company_name: companyData.company_name,
      company_id: companyData.company_id
    };
    this.auth.sendMail(reqObj).subscribe((res: any) => {
      this.getAllCompany();
    });
  }

  // to show resend link expire time
  resentLinkTimerFn = () => {
    this.link_expire_interval = setInterval(() => {
      let index = 0;
      this.company_list.forEach((company: any, i: any) => {
        const link_expiry_time = new Date(company.link_expiry_time).valueOf();
        if (link_expiry_time > new Date().valueOf()) {
          this.resentLinkTimer(company, i);
        } else {
          index++;
        }
      });
      if (index == this.company_list.length) {
        clearInterval(this.link_expire_interval);
      }

    }, 1000);

  };
  // to check link expired or not
  isLinkExpired(data: any) {
    if (new Date(data.link_expiry_time).valueOf() > new Date().valueOf()) {
      return false;
    }
    return true;
  }

  resentLinkTimer = (companyData: any, index: any) => {
    let minutes;
    let seconds;
    var currentDate = new Date();
    var linkExpirationDate = new Date(companyData.link_expiry_time);
    var timeDifference = linkExpirationDate.getTime() - currentDate.getTime();
    // var days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
    // var hours = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
    seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);
    this.link_expire_time[index] = `${minutes}m : ${String(seconds).length == 1 ? '0' + seconds : seconds}s`;
  };


  // to check if the record is already exists or not
  isCompanyNameExist() {

    return new Promise((resolve, reject) => {
      const company_name = this.company_form_group.get("company_name")?.value;
      const requestData = {
        key: 'company',
        value: company_name,
        company_id: this.company_form_group.get("company_id")?.value
      };

      if (company_name?.length && this.is_company_name_changed && !this.company_form_group.controls['company_name']?.errors?.['alreadyExist']) {
        this.auth.isRecordExist(requestData).subscribe((res: any) => {
          if (res) {
            if (res?.isRecordExist) {
              this.company_form_group.controls['company_name']?.setErrors({ alreadyExist: true });
              resolve(true);
            } else {
              this.company_form_group.controls['company_name']?.setErrors(null);
              resolve(false);
            }
          }
          this.is_company_name_changed = false;
        });
      } else {
        resolve(false);
      }

    });


  }



  /*-------------------------------submit user record-----------------------------------------------*/

  async submit() {
    if (this.company_form_group.invalid) {
      this.show_msg.showFromGroupWarning(this.company_form_group);
      return;
    }
    if (this.is_company_name_changed) {

      const isExist = await this.isCompanyNameExist();
      if (isExist) return;

    }

    const commonFn = () => {
      // this.company_list = [];
      this.getAllCompany();
      this.is_create_company = false;
      this.is_edit_company = false;
      this.initForm();
      this.tab_service.showCreateButton(true);
      setTimeout(() => {
        this.companyScrollEndFn();
      });
    };

    const contact_no = this.company_form_group.controls['dial_code_mobile_no'].value + " " + this.company_form_group.controls['mobile'].value;
    const data = { ...this.company_form_group.value, contact_no };
    // for company details update
    if (this.is_edit_company) {

      this.subscriptions.push(
        this.home.updateCompany(data).subscribe((res: any) => {
          commonFn();
        })
      );

      return;
    }

    this.subscriptions.push(
      this.home.createCompany(data).subscribe((res: any) => {
        commonFn();
      })
    );
  }

  /*-----------------------------------------------------------------------------------------------*/

  /*-----------------------------------user edit and delete functions-------------------------*/

  editCompany(data: any) {
    this.is_edit_company = true;
    this.initForm(data);
    this.tab_service.showCreateButton(false);
  }

  deleteCompany(data: any) {
    const config: MatDialogConfig = {
      panelClass: 'dialog-responsive',
      width: '50%',
      minWidth: '50%',
      disableClose: true,
      data: {
        title: `${data.company_name}`,
        isDelete: true
      },
    };

    const openDialog = this.dialog.open(MessageDialogComponent, config);
    openDialog.afterClosed().subscribe((result: any) => {
      if (result) {
        this.subscriptions.push(
          this.home.deleteCompany(data).subscribe((res: any) => {
            this.clearAll();
            this.getAllCompany();
          })
        );

      }

    });

  }

  /*-----------------------------------------------------------------------------------------------*/


  clearAll() {
    this.is_create_company = false;
    this.is_edit_company = false;
    this.initForm();
    this.tab_service.showCreateButton(true);
    this.state_list = [];
    setTimeout(() => {
      this.companyScrollEndFn();
    });
  }


  ngOnDestroy(): void {
    this.subscriptions.forEach((sub: Subscription) => sub.unsubscribe());
    clearInterval(this.link_expire_interval);
  }
}
