<div>
    <div fxFlex="100" fxLayout="column" class="per1pad full-div" fxLayoutGap="10px">
        <!-- Blue header with add button to create gateway with company details -->
        <mat-card style="background-color: #166DA6;color: white;height:4rem;overflow: hidden;" fxLayout="row"
            fxLayoutAlign="space-between center">
            <div fxLayout="column">
                <span style="font-size: 13px;color: white;font-weight: bold;line-height: 1;">{{tank_name}}</span>
                <span style="line-height: 1;margin-top: 7px;font-size: 10px;
                color: #FFFFFF;">{{company_name}}</span>
            </div>
            <div fxLayoutAlign="center center">
                <button *ngIf="access?.create && !access?.view" mat-stroked-button class="add-button grow"
                    fxLayout="row" (click)="addNewIot()">
                    <mat-icon class="add-icon">add_circle</mat-icon>
                    <span>{{'button.add'|translate|uppercase}}</span>
                </button>
            </div>
        </mat-card>
        <div fxFlex="100">
            <mat-card fxLayout="column" class="mat-elevation-z6" fxLayoutGap="30px"
                style="overflow: inherit !important;padding-bottom: 4%;">
                <!-- load empty image when no table data is available  -->
                <div *ngIf="tabledata.length==0" style="color: #3B3B3B;text-align: center;height: 60vh;align-items: center;
                                display: grid;">
                    <div fxLayout="column" fxLayoutGap="10px" style="justify-content: center;
                                align-items: center;">
                        <img src="../../../assets/images/empty/Createscreens.png" alt=""
                            style="height: 140px;width: 140px;">
                        <span>
                            {{'gateway.msg'|translate|titlecase}}
                        </span>
                    </div>
                </div>
                <!-- load table when table data is available  -->
                <div *ngIf="tabledata.length>0" fxLayout="column"
                    style="padding: 1%;background-color: #f5faf4;margin: 0%;" fxLayoutGap="20px">
                    <div mat-card-title class="label">{{'gateway.gateways'|translate|titlecase}}</div>
                    <div class="tableIot" style="overflow: auto;">
                        <table mat-table [dataSource]="dataSourceIOT" style="min-width: 90vh;">

                            <!-- S.No Column -->
                            <ng-container matColumnDef="S.NO">
                                <th mat-header-cell *matHeaderCellDef>{{'gateway.s_no'|translate|titlecase}}</th>
                                <td mat-cell *matCellDef="let element;let i = index"> {{i+1}} </td>
                            </ng-container>

                            <!-- Topic name Column -->
                            <ng-container matColumnDef="Topic name">
                                <th mat-header-cell *matHeaderCellDef>{{'gateway.type'|translate|titlecase}} </th>
                                <td mat-cell *matCellDef="let element" style="text-align: start;padding-left: 5vh;">
                                    {{element.type}}
                                </td>
                            </ng-container>

                            <!-- Topic structure Column -->
                            <ng-container matColumnDef="Topic structure">
                                <th mat-header-cell *matHeaderCellDef> {{'gateway.gateway_id'|translate|titlecase}}
                                </th>
                                <td mat-cell *matCellDef="let element" style="text-align: start;padding-left: 5vh;">
                                    {{element.serial_no}}
                                </td>
                            </ng-container>

                            <!-- Action Column -->
                            <ng-container matColumnDef="Action">
                                <th mat-header-cell *matHeaderCellDef style="width: 16vh;">
                                    {{'gateway.action'|translate|titlecase}}
                                </th>
                                <td mat-cell *matCellDef="let element">
                                    <div style="display: flex;">
                                        <div style="width: 50%;">
                                            <span *ngIf="access?.edit && !access?.view"><mat-icon class="iconvalue"
                                                    svgIcon="Edit" style="margin:0%"
                                                    (click)="addIotDialog(element.id);"></mat-icon></span>
                                        </div>
                                        <div style="width: 50%;">
                                            <span *ngIf="access?.delete && !access?.view"><mat-icon class="iconvalue"
                                                    svgIcon="Delete" style="margin:0%"
                                                    (click)="delete(element);"></mat-icon></span>
                                        </div>
                                    </div>
                                </td>
                            </ng-container>

                            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                        </table>
                    </div>
                </div>
                <!-- topic information table -->
                <div *ngIf="tabledata.length>0  && dataSource.data.length>0" class="tableIot">
                    <div mat-card-title class="label" style="margin-bottom: 20px;">
                        {{'gateway.topic_info'|translate|titlecase}}
                    </div>
                    <div style="overflow: auto;width: 100%;">
                        <table mat-table [dataSource]="dataSource" style="min-width: 160vh;">

                            <!-- S.No Column -->
                            <ng-container matColumnDef="S.NO">
                                <th mat-header-cell *matHeaderCellDef style="width: 16vh;">
                                    {{'gateway.s_no'|translate|titlecase}}
                                </th>
                                <td mat-cell *matCellDef="let element;let i = index"> {{i+1}} </td>
                            </ng-container>

                            <!-- Topic name Column -->
                            <ng-container matColumnDef="Topic name">
                                <th mat-header-cell *matHeaderCellDef style="width: 45vh;">
                                    {{'gateway.topic_name'|translate|titlecase}}
                                </th>
                                <td mat-cell *matCellDef="let element" style="text-align: start;padding-left: 5vh;">
                                    {{element.topic_name}}
                                </td>
                            </ng-container>

                            <!-- Topic structure Column -->
                            <ng-container matColumnDef="Topic structure">
                                <th mat-header-cell *matHeaderCellDef>{{'gateway.topic_structure'|translate|titlecase}}
                                </th>
                                <td mat-cell *matCellDef="let element" style="text-align: start;padding-left: 5vh;">
                                    {{element.topic}}
                                </td>
                            </ng-container>

                            <!-- Action Column -->
                            <ng-container matColumnDef="Action">
                                <th mat-header-cell *matHeaderCellDef style="width: 16vh;">
                                    {{'gateway.action'|translate|titlecase}}
                                </th>
                                <td mat-cell *matCellDef="let element">
                                    <div style="display: flex;gap: 30%;justify-content: center;">
                                        <mat-icon class="copyicon" svgIcon="copy"
                                            style="margin:0%;height: 20px;width: 20px;"
                                            (click)="saveInClipboard(element.topic)">
                                        </mat-icon>
                                    </div>
                                </td>
                            </ng-container>

                            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                        </table>
                    </div>
                </div>
            </mat-card>
        </div>
    </div>
</div>