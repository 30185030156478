import { DatePipe, TitleCasePipe } from '@angular/common';
import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_LOCALE, MAT_DATE_FORMATS } from '@angular/material/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { BatchService } from 'src/app/core/services/batch/batch.service';
import { CommonService } from 'src/app/core/services/common/common.service';
import { DayEntryService } from 'src/app/core/services/day-entry/day-entry.service';
import { HomeService } from 'src/app/core/services/home/home.service';
import { MessageService } from 'src/app/core/services/message/message.service';
import { BatchDetailsDialogComponent } from 'src/shared/batch-details-dialog/batch-details-dialog.component';
import { BatchEndingDialogComponent } from 'src/shared/batch-ending-dialog/batch-ending-dialog.component';

const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'DD-MM-YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};
@Component({
  selector: 'app-batch-setting',
  templateUrl: './batch-setting.component.html',
  styleUrls: ['./batch-setting.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    DatePipe
  ],
  encapsulation: ViewEncapsulation.None
})
export class BatchSettingComponent implements OnInit, OnDestroy {
  public subscriptions: Subscription[] = [];
  public current_time: any;
  public batch_form_group!: FormGroup;
  public batch_end_group!: FormGroup;
  public end_min_date: any = new Date();
  public active_batch_id: any;
  public is_batch_started = false;
  public breed_list: any = [];
  public showTable = false;
  public max_date = new Date();
  public displayedColumns: string[] = ['S.NO', 'Breed Name', 'Batch Number', 'Batch Start Date', 'Batch End Date', 'Action'];
  public dataSource = new MatTableDataSource([]);
  public company_name = localStorage.getItem('company')?.length ? JSON.parse(localStorage.getItem('company') || '')?.name : '--';
  public shed_name = localStorage.getItem('tank')?.length ? JSON.parse(localStorage.getItem('tank') || '')?.name : '--';
  public birdtype = localStorage.getItem('tank')?.length ? JSON.parse(localStorage.getItem('tank') || '')?.breedType : '--';
  lastUpdated: Date = new Date();
  public not_avail: string = "N/A";

  public access: any;
  constructor(private fb: FormBuilder,
    private service: BatchService,
    private msg: MessageService,
    private datePipe: DatePipe,
    private home: HomeService,
    private dialog: MatDialog,
    private dayservice: DayEntryService,
    private commonService: CommonService,
    private translate: TranslateService,
  ) {
    this.access = this.home.getAccess(15);
  }

  ngOnInit(): void {
    this.init();
    this.showHideTable();
    this.getActiveBatch();

    this.subscriptions.push(
      this.service.getBreed().subscribe((res: any) => {
        if (res.data?.length) {
          res.data.sort((a: any, b: any) => a.breed_name.localeCompare(b.breed_name));;
          this.breed_list = res.data;
        }
      }),
    );

  }

  batchstartdetails: any;
  dateerror: boolean = false;
  enddate = new Date();
  toTitleCase(value: string): string {
    return value.toLowerCase().replace(/\b\w/g, (match) => match.toUpperCase());
  }
  init(data?: any) {
    this.batchstartdetails = data;
    if (this.birdtype == 'fish') {
      this.batch_form_group = this.fb.group({
        breed_type: new FormControl({ value: this.toTitleCase(this.birdtype), disabled: true }),
        breed_id: new FormControl(data?.breed_id, [Validators.required]),
        batch_id: new FormControl({ value: data?.batch_id }),
        batch_number: new FormControl(Number(data?.batch_number) || null, [Validators.required]),
        start_date: new FormControl(data?.start_date || new Date(), [Validators.required]),
        start_age_week: new FormControl(data?.start_age_week, [Validators.required]),
        start_age_days: new FormControl(data?.start_age_days, [Validators.required]),
        start_fish_count: new FormControl(data?.start_fish_count, [Validators.required]),
        // start_male_count: new FormControl(data?.start_male_count, [Validators.required]),
      });
    } else {
      this.batch_form_group = this.fb.group({
        breed_type: new FormControl({ value: this.toTitleCase(this.birdtype), disabled: true }),
        breed_id: new FormControl(data?.breed_id, [Validators.required]),
        batch_id: new FormControl({ value: data?.batch_id }),
        batch_number: new FormControl(Number(data?.batch_number) || null, [Validators.required]),
        start_date: new FormControl(data?.start_date || new Date(), [Validators.required]),
        start_age_week: new FormControl(data?.start_age_week, [Validators.required]),
        start_age_days: new FormControl(data?.start_age_days, [Validators.required]),
        start_fish_count: new FormControl(data?.start_fish_count, [Validators.required]),
        // start_male_count: new FormControl(data?.start_male_count),
      });

    }
    this.enddate = new Date(data?.start_date);
    this.dateerror = this.currentData(this.enddate);
    this.showerror = false;
    if (data) {
      this.batch_form_group.disable();
    } else {
      this.batch_form_group.enable();
    }

    this.isStarted = (moment(data?.start_date).format('YYYY-MM-DD') <= moment(new Date()).format('YYYY-MM-DD')) ? true : false;
    this.batch_end_group = this.fb.group({
      end_date: new FormControl(data?.start_date),
      end_age_week: new FormControl(data?.start_age_week),
      end_age_days: new FormControl(data?.start_age_days),
      end_female_count: new FormControl(data?.start_fish_count),
      // end_male_count: new FormControl(data?.start_male_count),
    });
    this.batch_end_group.disable();
    if (data) this.getCurrentBatch();
  }
  batchData: any;
  showerror: boolean = false;
  isStarted: boolean = false;
  getCurrentBatch() {
    this.subscriptions.push(
      this.service.getCurrentBatch().subscribe((res: any) => {
        var data = res;
        if (data) {
          this.batchData = data;
          this.batch_end_group = this.fb.group({
            end_date: new FormControl(data.dated_on),
            end_age_week: new FormControl(data.dated_age.toString().split('.')[0] ? data.dated_age.toString().split('.')[0] : 0),
            end_age_days: new FormControl(data.dated_age.toString().split('.')[1] ? data.dated_age.toString().split('.')[1] : 0),
            end_female_count: new FormControl(data.fish_closing_stock),
            end_male_count: new FormControl(data.male_closing_stock),
          });
          this.batch_end_group.disable();
          this.showerror = data.fish_closing_stock ? true : false;
        }
      }, (error: Response) => {

      })
    );
  }
  currentData(date: Date) {
    var date1 = new Date();
    if (!date) return false;
    if (date.getDate() > date1.getDate()) {
      return true;
    } else {
      return false;
    }
  }

  /**----------------------------------------------------------------------------------------------------*/
  /**
Retrieves the active batch from the server and initializes the necessary data.
*/
  getActiveBatch() {
    this.subscriptions.push(
      this.service.getActiveBatch().subscribe((res: any) => {
        if (res?.data[0]?.batch_id) {
          this.init(res?.data[0]);
          this.active_batch_id = res?.data[0]?.batch_id;
          this.end_min_date = moment(res?.data[0]?.start_date).format('YYYY-MM-DD');
          this.is_batch_started = true;
          localStorage.setItem('batch_id', res?.data[0]?.batch_id);
        } else {
          localStorage.removeItem('batch_id');
        }
      })
    );

  }
  /**----------------------------------------------------------------------------------------------------*/



  /**----------------------------------------------------------------------------------------------------*/
  /**
  Retrieves the list of batches from the server based on the specified status.
  @param status Optional. The status of the batches to retrieve. If not provided, all batches will be retrieved.
  */

  getBatch(status?: any) {
    this.subscriptions.push(
      this.service.getBatchList(status).subscribe((res) => {
        this.dataSource.data = [];
        if (res?.data?.length) {
          this.dataSource.data = res?.data;
        }
      })
    );
  }
  getEndDate(date: any) {
    if (date) {
      return this.datePipe.transform(new Date(date), 'd-MM-YYYY');
    } else return 'Batch is active';
  }
  /**----------------------------------------------------------------------------------------------------*/

  /**----------------------------------------------------------------------------------------------------*/
  /**
Submits the batch form and starts or ends the batch based on the is_batch_started flag.
If the form is invalid and the batch is not started, shows a warning message and returns.
If the form is invalid and the batch is started, shows a warning message and returns.
Sets the start date and end date values of the form to Date objects with specific hours, minutes, and seconds.
If the batch is not started, calls the batchStart() service method and updates the active batch if successful.
If the batch is already started, calls the batchEnd() service method and handles batch reset and table visibility based on the end date.
*/

  showWarningMsg(form_group: FormGroup, user_label?: any) {
    let is_error = false;
    for (let key in form_group.controls) {
      let value = form_group.controls[key];
      if (value.invalid && !value.value?.length) {
        is_error = true;
        key = key.toLocaleLowerCase().includes('breed_id') ? 'breed name' : key;
        key = key.toLocaleLowerCase().includes('start_age_week') ? 'age in weeks' : key;
        key = key.toLocaleLowerCase().includes('start_age_days') ? 'age in days' : key;
        key = key.toLocaleLowerCase().includes('start_fish_count') ? this.birdtype == 'fish' ? 'fish count' : 'shrimp count' : key;
        // key = key.toLocaleLowerCase().includes('start_male_count') ? 'male count' : key;
        this.msg.errorSnackBar(`${key.replace(/_/g, " ")}`);
        break;
      }
    }
    return is_error;
  }

  submit() {
    if (this.batch_form_group.invalid && !this.is_batch_started) {
      this.showWarningMsg(this.batch_form_group);
      return;
    }
    if (this.batch_end_group.invalid && this.is_batch_started) {
      this.showWarningMsg(this.batch_end_group);
      return;
    }


    this.batch_form_group.value.start_date = new Date((new Date(this.batch_form_group.value.start_date)?.setHours(0, 0, 0)) as unknown as Date);
    this.batch_end_group.value.end_date = new Date((new Date(this.batch_end_group.value.end_date)?.setHours(23, 59, 50)) as unknown as Date);

    !this.is_batch_started
      ? this.subscriptions.push(
        this.service.batchStart(this.batch_form_group.value).subscribe((res: any) => {
          if (res.batch_id) {
            this.getActiveBatch();
          }

        })
      )
      : this.batchEnding();

  }
  batchEndFunction() {
    this.subscriptions.push(
      this.service.batchEnd(this.batch_end_group.value, this.active_batch_id).subscribe((res: any) => {
        const current_date = new Date(moment().format('YYYY-MM-DD'));
        const batch_end_date = new Date(moment(this.batch_end_group?.value?.end_date).format('YYYY-MM-DD'));
        // if (batch_end_date <= current_date) {
        this.resetBatch();

        // }
        this.showHideTable();
      })
    );
  }
  geterror() {
    return this.batch_end_group?.value.end_female_count != null ? true : false;
  }

  /**----------------------------------------------------------------------------------------------------*/

  /**----------------------------------------------------------------------------------------------------*/
  /**
Resets the active batch by clearing the active batch ID, initializing the form, and updating the batch status.
Sets the is_batch_started flag to false.
Removes the batch ID from the local storage.
*/

  resetBatch() {
    this.active_batch_id = null;
    this.init();
    this.is_batch_started = false;
    localStorage.removeItem('batch_id');
    this.showerror = false;
  }
  batchEnding() {
    var boo = false;
    if (this.batchData) {
      boo = (this.batchData.fish_closing_stock?.toString()) == "0" ? ((this.batchData.male_closing_stock == null || (this.batchData.male_closing_stock?.toString()) == "0") ? true : false) : false;
    }
    const config: MatDialogConfig = {
      panelClass: 'dialog-responsive-batch',
      width: '40%',
      minWidth: '40%',
      disableClose: false,
      data: {
        title: ``,
        isDelete: true,
        isConformation: !boo,
        isStarted: this.isStarted,
        isReconformation: false
      },
    };
    const openDialog = this.dialog.open(BatchEndingDialogComponent, config);
    this.subscriptions.push(openDialog.afterClosed().subscribe((result: any) => {
      if (result && (boo || !this.isStarted)) {
        this.batchEndFunction();
        this.batchData = {};
      } else if (result && !boo) {
        this.batchEndingConformation();
      }
    }));

  }
  batchEndingConformation() {
    const config: MatDialogConfig = {
      panelClass: 'dialog-responsive-batch',
      width: '40%',
      minWidth: '40%',
      disableClose: false,
      data: {
        title: ``,
        isDelete: true,
        isConformation: false,
        isStarted: true,
      },
    };
    const openDialog = this.dialog.open(BatchEndingDialogComponent, config);
    this.subscriptions.push(openDialog.afterClosed().subscribe((result: any) => {
      if (result) {
        this.batchEndFunction();
        this.batchData = {};
      }
    }));
  }
  batchDetails(data: any) {
    const config: MatDialogConfig = {
      panelClass: 'dialog-responsive-batch-details',
      width: '50%',
      minWidth: '50%',
      disableClose: false,
      data: {
        title: ``,
        isDelete: true,
        batchData: data
      },
    };
    const openDialog = this.dialog.open(BatchDetailsDialogComponent, config);
    this.subscriptions.push(openDialog.afterClosed().subscribe((result: any) => {
      if (result) { }
    }));

  }
  /**----------------------------------------------------------------------------------------------------*/

  showHideTable() {
    this.showTable = !this.showTable;
    if (this.showTable)
      this.getBatch();
  }

  clearAll() {
    this.init();

  }
  editvalue(data: any) {
    this.batchDetails(data);
  }
  current_entry_age: any;
  getCurrentEntryAge(data?: any): any[] {
    const data1 = {
      start_date: data?.start_date,
      current_date: new Date(),
      age_weeks: data?.start_age_week,
      age_days: data?.start_age_days,
    };

    this.current_entry_age = this.service.batchAgeCalculate(data1);
    // this.day_entry_from_group.controls['dated_age'].setValue(this.current_entry_age);
    return this.current_entry_age.toString().split('.');
  }

  transformInputValue(inputValue: string | number): string {
    if (inputValue != null) {
      const inputValueString = inputValue?.toString();
      const regex = this.birdtype === 'broiler' ? /^[0-9]{1,2}$|^100$/ : /^(?!0)[0-9]{1,2}$|^100$/;
      const match: any = inputValueString.match(regex);
      if (match) {
        return match[0];
      } else {
        return String(inputValue)?.slice(0, -1);
      }
    }
    return '';

  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub: Subscription) => sub.unsubscribe());
  }
}

