import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { COMMON_API, DAY_ENTRY } from 'src/app/config/api.config';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class DayEntryService {

  private BASE_URL = environment.BASE_URL;

  constructor(private http: HttpClient) { }

  public getAllFeed() {

    return this.http.get(`${this.BASE_URL}${DAY_ENTRY.GET_FEED}`);
  }

  public getAllEquipments() {
    const tank_id = JSON.parse(localStorage.getItem('tank') || '')?.id;
    return this.http.get(`${this.BASE_URL}${DAY_ENTRY.GET_EQUIPMENT}/${tank_id}`);
  }

  public getEqptFailureById(equipment_id: any) {

    return this.http.get(`${this.BASE_URL}${DAY_ENTRY.GET_EQPT_FAILURE_BY_ID}/${equipment_id}`);
  }

  public getDayWiseEntry(page?: any) {
    const tank_id = JSON.parse(localStorage.getItem('tank') || '')?.id;
    const batch_id = localStorage.getItem('batch_id') ? JSON.parse(localStorage.getItem('batch_id') || '') : null;
    return this.http.get(`${this.BASE_URL}${DAY_ENTRY.SUBMIT_DAY_ENTRY}/?perpage=${page?.per_page || null}&currentpage=${page?.current_page || null}&tank_id=${tank_id}&batch_id=${batch_id}`);
  }

  public getOpeingStock(prevDate: any) {
    const tank_id = JSON.parse(localStorage.getItem('tank') || '')?.id;
    const batch_id = localStorage.getItem('batch_id') ? JSON.parse(localStorage.getItem('batch_id') || '') : null;
    return this.http.get(`${this.BASE_URL}${DAY_ENTRY.GET_OPENING_STOCK}/?date=${prevDate}&tank_id=${tank_id}&batch_id=${batch_id}`);
  }


  public submitDayEntry(data: any): Observable<any> {

    return this.http.post(`${this.BASE_URL}${DAY_ENTRY.SUBMIT_DAY_ENTRY}`, data);
  }
  public updateDayEntry(data: any, id: any): Observable<any> {
    return this.http.put(`${this.BASE_URL}${DAY_ENTRY.SUBMIT_DAY_ENTRY}/${id}`, data);
  }
  public deleteDayEntry(id: any): Observable<any> {
    return this.http.delete(`${this.BASE_URL}${DAY_ENTRY.SUBMIT_DAY_ENTRY}/${id}`);
  }
  public getShedDetails(): Observable<any> {
    const tank_id = JSON.parse(localStorage.getItem('tank') || '')?.id;
    return this.http.get(`${this.BASE_URL}${COMMON_API.GET_SHED_DETAILS}/${tank_id}`);
  }
  public getlastdayentryRecord(): Observable<any> {
    const tank_id = JSON.parse(localStorage.getItem('tank') || '')?.id;
    const batch_id = localStorage.getItem('batch_id') ? JSON.parse(localStorage.getItem('batch_id') || '') : null;
    return this.http.get(`${this.BASE_URL}${DAY_ENTRY.GET_LAST_DAY_ENTRY}?tank_id=${tank_id}&batch_id=${batch_id}`);
  }


}
