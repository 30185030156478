import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { HomeService } from 'src/app/core/services/home/home.service';
import { MessageService } from 'src/app/core/services/message/message.service';
import { SettingsService } from 'src/app/core/services/settings/settings.service';
import { MessageDialogComponent } from 'src/shared/message-dialog/message-dialog.component';
import { equipmenttable, faulttable, equipmentVo, faultvo } from '../settings.model';

@Component({
  selector: 'app-equipment',
  templateUrl: './equipment.component.html',
  styleUrls: ['./equipment.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class EquipmentComponent implements OnInit {
  current_time = new Date();
  public equipment_group: any;
  public equipment_fault_group: any;
  nameList: boolean = true;
  nameCT: boolean = false;
  faultlist: boolean = false;
  faultCT: boolean = false;
  listbt: boolean = false;
  creatbt: boolean = true;
  isloaderName = false;
  isloaderfault = false;
  displayedColumns: string[] = ['S.NO', 'Sensor name', 'Action'];
  displayedColumns1: string[] = ['S.NO', 'Name of equipment', 'Equipment fault Type', 'Equipment fault', 'Action'];
  dataSource: any;
  dataSource2: any;
  nameorfault = 0;
  public company_name = localStorage.getItem('company')?.length ? JSON.parse(localStorage.getItem('company') || '')?.name : '--';
  public shed_name = localStorage.getItem('tank')?.length ? JSON.parse(localStorage.getItem('tank') || '')?.name : '--';
  public access: any;
  public subscriptions: Subscription[] = [];

  constructor(private fb: FormBuilder, private dialog: MatDialog, private SettingsService: SettingsService, private show_msg: MessageService,
    private home: HomeService) {
    this.access = this.home.getAccess(25);
  }

  ngOnInit(): void {
    this.getEquipmentlisttable();
    this.getFaultlist();
    this.equipment_group = this.fb.group({
      age_in_weeks: new FormControl('', [Validators.required])
    });
    this.equipment_fault_group = this.fb.group({
      age_in_weeks: new FormControl('', [Validators.required]),
      equipment_fault_type: new FormControl('', [Validators.required]),
      equipment_fault: new FormControl('', [Validators.required])
    });
  }
  tablenamelist: any[] = [];
  equipmentlist: any[] = [];
  /**
   * api to get equipment details for equipment table
   */
  getEquipmentlisttable() {
    this.subscriptions.push(this.SettingsService.getAllEquipments().subscribe((res: any) => {
      this.tablenamelist = res.data;
      this.setNameTable(res.data);
      this.equipmentlist = [];
      res.data.filter((c: any) => {
        var equipdata = {
          id: c.equipment_id,
          name: c.equipment_name
        };
        this.equipmentlist.push(equipdata);

      });
      this.isloaderName = true;
    }, (error: Response) => {
      this.isloaderName = true;
      console.log(error);
    }));
  }
  /**
   * sub-method of getEquipmentlisttable function to construct datasource for equipment table
   * @param data 
   */
  setNameTable(data: any[]) {
    var displayedColumns: equipmenttable[] = [];
    data.filter((c) => {
      var data1 = new equipmenttable();
      data1.name = c.equipment_name;
      data1.id = c.equipment_id;
      displayedColumns.push(data1);
    });
    this.dataSource = displayedColumns;
  }
  tableFaultlist: any[] = [];
  /**
   * api to get equipment fault details for fault table
   */
  getFaultlist() {
    this.subscriptions.push(this.SettingsService.getFaultEquipment().subscribe((res: any) => {
      this.tableFaultlist = res.data;
      this.setFaultTable(res.data);
      this.isloaderfault = true;
    }, (error: Response) => {
      this.isloaderfault = true;
      console.log(error);
    }));
  }
  /**
   * sub-method of getFaultlist function to construct datasource for fault table
   * @param data 
   */
  setFaultTable(data: any[]) {
    var displayedColumns: faulttable[] = [];
    data.filter((c) => {
      var data1 = new faulttable();
      data1.name = c.equipment?.equipment_name;
      data1.type = c.failure_code;
      data1.fault = c.failure_desc;
      data1.id = c.failure_id;
      displayedColumns.push(data1);
    });
    this.dataSource2 = displayedColumns;
  }
  /**
   * select tab between equipment and fault screen
   * @param data 
   */
  selectTab(data: any) {
    this.nameorfault = data.index;
    data.index == 0 ? this.ifnamelist() : this.iffaultlist();
    this.listbt = false;
    this.creatbt = true;
  }
  /**
   * change from create to list page for both equipment and fault and value reset
   */
  ListPage() {
    this.creatbt = true;
    this.listbt = false;
    this.nameorfault == 0 ? this.ifnamelist() : this.iffaultlist();
    this.equipment_group.reset();
    this.equipment_fault_group.reset();
  }
  /**
   * change from list to create page for both equipment and fault  and value reset
   */
  createList() {
    this.creatbt = false;
    this.listbt = true;
    this.nameorfault == 0 ? this.ifname() : this.iffault();
    this.equipment_group.reset();
    this.equipment_fault_group.reset();
  }
  nameUpdateId: any | undefined;
  /**
   * set the value for update equipment name 
   * @param id 
   */
  nameUpdate(id: any) {
    this.tablenamelist.filter((c) => {
      if (c.equipment_id == id) {
        this.nameUpdateId = c.equipment_id;
        this.equipment_group = this.fb.group({
          age_in_weeks: new FormControl(c.equipment_name, [Validators.required])
        });
      }
    });
    this.ifname();
    this.listbt = true;
    this.creatbt = false;
  }
  failureUpdateId: any | undefined;
  /**
   * set the value for update fault name
   * @param id 
   */
  faultUpdate(id: any) {
    this.tableFaultlist.filter((c) => {
      if (c.failure_id == id) {
        this.failureUpdateId = c.failure_id;
        this.equipment_fault_group = this.fb.group({
          age_in_weeks: new FormControl(c.equipment?.equipment_id, [Validators.required]),
          equipment_fault_type: new FormControl(c.failure_code, [Validators.required]),
          equipment_fault: new FormControl(c.failure_desc, [Validators.required])
        });
      }
    });
    this.iffault();
    this.listbt = true;
    this.creatbt = false;

  }
  /**
   * change to list page in equipment
   */
  ifnamelist() {
    this.nameCT = false; this.nameList = true; this.faultCT = false; this.faultlist = false;
    this.nameUpdateId = undefined;
    this.failureUpdateId = undefined;
  }
  /**
   * change to create page in equipment
   */
  ifname() {
    this.nameCT = true; this.nameList = false; this.faultCT = false; this.faultlist = false;
    this.failureUpdateId = undefined;
  }
  /**
   * change to list page in fault
   */
  iffaultlist() {
    this.nameCT = false; this.nameList = false; this.faultCT = false; this.faultlist = true;
    this.nameUpdateId = undefined;
    this.failureUpdateId = undefined;
  }
  /**
   * change to create page in fault
   */
  iffault() {
    this.nameCT = false; this.nameList = false; this.faultCT = true; this.faultlist = false;
    this.nameUpdateId = undefined;
  }
  /**
   * update or save equipment name
   * @returns 
   */
  nameSummit() {
    if (this.equipment_group.invalid) {
      return this.show_msg.errorSnackBar('no_empty_field');
    }
    if (this.nameUpdateId != undefined) this.updatename();
    else this.savename();
  }
  /**
   * save equipment name sub-method
   */
  savename() {
    var data = new equipmentVo();
    data.equipment_name = this.equipment_group.value.age_in_weeks;
    data.tank_id = JSON.parse(localStorage.getItem('tank') || '')?.id;
    this.subscriptions.push(this.SettingsService.saveEquipment(data).subscribe((res: any) => {
      this.ifnamelist();
      this.getEquipmentlisttable();
      this.listbt = false;
      this.creatbt = true;
    }, (error: Response) => {
      console.log(error);
    }));

  }
  /**
   * udate equipment name sub-method
   */
  updatename() {
    var data = new equipmentVo();
    data.equipment_name = this.equipment_group.value.age_in_weeks;
    data.tank_id = JSON.parse(localStorage.getItem('tank') || '')?.id;
    this.subscriptions.push(this.SettingsService.updateEquipment(data, this.nameUpdateId).subscribe((res: any) => {
      this.ifnamelist();
      this.getEquipmentlisttable();
      this.listbt = false;
      this.creatbt = true;
      this.nameUpdateId = undefined;
    }, (error: Response) => {
      console.log(error);
    }));

  }
  /**
   * update or save fault name
   * @returns 
   */
  faultSummit() {
    if (this.equipment_fault_group.invalid) {
      return this.show_msg.errorSnackBar('no_empty_field');
    }
    if (this.failureUpdateId != undefined) this.updatefault();
    else this.savefault();
  }
  /**
   * save fault name sub-method
   */
  savefault() {
    var data = new faultvo();
    data.equipment_id = this.equipment_fault_group.value.age_in_weeks;
    data.failure_desc = this.equipment_fault_group.value.equipment_fault;
    data.failure_code = this.equipment_fault_group.value.equipment_fault_type;
    data.tank_id = JSON.parse(localStorage.getItem('tank') || '')?.id;
    this.subscriptions.push(this.SettingsService.savefault(data).subscribe((res: any) => {
      this.getFaultlist();
      this.iffaultlist();
      this.listbt = false;
      this.creatbt = true;
    }, (error: Response) => {
      console.log(error);
    }));
  }
  /**
   * update fault name sub-method
   */
  updatefault() {
    var data = new faultvo();
    data.equipment_id = this.equipment_fault_group.value.age_in_weeks;
    data.failure_desc = this.equipment_fault_group.value.equipment_fault;
    data.failure_code = this.equipment_fault_group.value.equipment_fault_type;
    data.tank_id = JSON.parse(localStorage.getItem('tank') || '')?.id;
    this.subscriptions.push(this.SettingsService.updateFault(data, this.failureUpdateId).subscribe((res: any) => {
      this.getFaultlist();
      this.iffaultlist();
      this.listbt = false;
      this.creatbt = true;
      this.failureUpdateId = undefined;
    }, (error: Response) => {
      console.log(error);
    }));
  }
  /**
   * delete equipment name popup
   * @param id 
   * @param data 
   */
  deleteEquipment(id: any, data: any) {
    const config: MatDialogConfig = {
      panelClass: 'dialog-responsive',
      width: '50%',
      minWidth: '50%',
      disableClose: true,
      data: {
        title: `${data} equipment`,
        isDelete: true
      },
    };
    const openDialog = this.dialog.open(MessageDialogComponent, config);
    this.subscriptions.push(openDialog.afterClosed().subscribe((result: any) => {
      if (result) {
        this.subscriptions.push(this.SettingsService.deleteEquipment(id).subscribe((res: any) => {
          this.getEquipmentlisttable();
        }, (error: Response) => {
          console.log(error);
        }));
      }

    }));

  }
  /**
   * delete fault name popup
   * @param id 
   * @param data 
   */
  deleteFault(id: any, data: any) {
    const config: MatDialogConfig = {
      panelClass: 'dialog-responsive',
      width: '50%',
      minWidth: '50%',
      disableClose: true,
      data: {
        title: `${data} fault`,
        isDelete: true
      },
    };
    const openDialog = this.dialog.open(MessageDialogComponent, config);
    this.subscriptions.push(openDialog.afterClosed().subscribe((result: any) => {
      if (result) {
        this.subscriptions.push(this.SettingsService.deleteFault(id).subscribe((res: any) => {
          this.getFaultlist();
        }, (error: Response) => {
          console.log(error);
        }));
      }

    }));

  }
  /**
   * clear equipment form group and change to list page
   */
  clearEquipment() {
    this.equipment_group.reset();
    this.ListPage();
  }
  /**
   * clear fault form group and change to list page
   */
  clearFailure() {
    this.equipment_fault_group.reset();
    this.ListPage();
  }
  ngOnDestroy() {
    this.subscriptions.forEach((sub: Subscription) => sub.unsubscribe());
  }

}