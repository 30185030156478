import { Component, Inject, OnInit } from '@angular/core';
import { MatSnackBarRef, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';

@Component({
  selector: 'app-cust-snackbar',
  templateUrl: './cust-snackbar.component.html',
  styleUrls: ['./cust-snackbar.component.scss']
})
export class CustSnackbarComponent implements OnInit {

  public msg_style: styleInterface[] = [
    {
      type: 'success',
      color: '#47D764',
      icon: 'check_box'
    },
    {
      type: 'error',
      color: '#FF3131',
      icon: 'disabled_by_default'
    },
    {
      type: 'info',
      color: 'orange',
      icon: 'info'
    }
  ];
  constructor (
    @Inject(MAT_SNACK_BAR_DATA) public data: any,
    public snackBarRef: MatSnackBarRef<CustSnackbarComponent>,) { }

  ngOnInit (): void {
  }

}

export interface styleInterface {
  type: string,
  color: string,
  icon: string;
}
