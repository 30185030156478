import { DatePipe } from '@angular/common';
import { Component, HostListener, OnInit } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { MessageService } from 'src/app/core/services/message/message.service';
import { ReportsService } from 'src/app/core/services/reports/reports.service';
import { ReportService } from './report.service';
import { SettingsService } from 'src/app/core/services/settings/settings.service';
import { Subscription, concatMap, groupBy, mergeMap, of, reduce } from 'rxjs';
import { DashboardAPIService } from 'src/app/core/services/dashboardAPI/dashboard-api.service';
import { HomeService } from 'src/app/core/services/home/home.service';
import { ScoreService } from '../settings/score.service';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_LOCALE, MAT_DATE_FORMATS } from '@angular/material/core';
import { TranslateService } from '@ngx-translate/core';
const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'DD-MM-YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};
@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    DatePipe
  ],
})
export class ReportComponent implements OnInit {
  isSensor = false;
  public current_time = new Date();
  public report_from_group: any;
  public sensor_report_from_group: any;
  public max_date = new Date();
  public max_date1 = new Date();
  public min_date = new Date();
  public Date_from = new FormControl(new Date());
  public Date_to = new FormControl(new Date());
  public selectallbtn = new FormControl(false);
  public subscriptions: Subscription[] = [];
  public company_name = localStorage.getItem('company')?.length ? JSON.parse(localStorage.getItem('company') || '')?.name : '--';
  public shed_name = localStorage.getItem('tank')?.length ? JSON.parse(localStorage.getItem('tank') || '')?.name : '--';
  public batch_id = localStorage.getItem('batch_id') ? JSON.parse(localStorage.getItem('batch_id') || '') : null;
  public birdtype = localStorage.getItem('tank')?.length ? JSON.parse(localStorage.getItem('tank') || '')?.breedType : null;
  sensorsAvailabilty = [true, true, true, true, true, true];
  ismobview = false;
  public access: any;
  // sensordatum: any = this.ReportService.sensordatum;
  // criticalobj_2: any = {};
  // criticalobj_variable: any = this.ReportService.criticalobj_variable;
  constructor(private fb: FormBuilder, private ReportService: ReportService, private datePipe: DatePipe, private productsapi: DashboardAPIService, private show_msg: MessageService
    , private Report: ReportsService, private SettingsService: SettingsService, private home: HomeService, private Scores: ScoreService, private translate: TranslateService) { this.access = this.home.getAccess(10); }
  @HostListener('window:resize', ['$event'])
  onResize() {
    const query = window.matchMedia("(max-width: 600px)");
    this.ismobview = query.matches ? true : false;
  }
  ngOnInit(): void {
    this.getProducts();
    this.max_date1.setDate(this.max_date.getDate() - 1);
    this.Date_from = new FormControl(new Date(this.max_date1));
    this.min_date = this.max_date1;
    this.initForm();
    this.getSensorDatas();
    this.onResize();
  }
  /**
   * Initialize both productivity and sensor form group
   */
  initForm() {
    this.report_from_group = this.fb.group({
      fish_opening_stock: new FormControl(false),
      fish_closing_stock: new FormControl(false),
      fish_mortality_count: new FormControl(false),
      fish_sold_count: new FormControl(false),
      fish_transfer_in_count: new FormControl(false),
      fish_transfer_out_count: new FormControl(false),
      labour_attendance: new FormControl(false),
      type_feed: new FormControl(false),
      feed_per_fish: new FormControl(false),
      avg_f_weight: new FormControl(false),


      medication: new FormControl(false),
      equipment: new FormControl(false),
    });
    this.sensor_report_from_group = this.fb.group({
      air_flow: new FormControl(false),
      ventilation_pressure: new FormControl(false),
      light_level: new FormControl(false),
      temp_humid: new FormControl(false),
      ammoniaCo: new FormControl(false),
      carbon_di: new FormControl(false),
    });
  }
  /**
   * set report to date both productivity and sensor
   * @param event 
   */
  toDateChange(event: any) {
    this.max_date1 = new Date(event.value);
  }
  /**
   * set report from date both productivity and sensor
   * @param event 
   */
  fromDateChange(event: any) {
    this.min_date = new Date(event.value);
  }
  sensorDataList: any[] = [];
  /**
   * get available sensor details Api
   */
  getSensorDatas() {
    this.subscriptions.push(this.SettingsService.getSensors().subscribe((res: any) => {
      this.sensorDataList = res.data;
      this.getSensorType();
    }, (error: Response) => {
      console.log(error);
    }));
  }
  /**
   * check and enable available sensors in sensor reports
   */
  setsensor() {
    for (var i = 1; i <= 6; i++) {
      var sensor = this.sensorDataList.filter(m => (m.sensor_type_id == i));
      this.sensorsAvailabilty[i] = sensor.length > 0 ? true : false;
    }
  }
  sensorTypeList: any[] = [];
  /**
   * get sensor type Api
   */
  getSensorType() {
    this.subscriptions.push(this.SettingsService.getSensorType().subscribe((res: any) => {
      this.sensorTypeList = res.data;
      this.setsensor();
    }, (error: Response) => {
      console.log(error);
    }));
  }
  broiler = ['hatching_eggs', 'total_eggs', 'jumbo_eggs', 'cracked_eggs', 'wasted_eggs', 'egg_weight', 'stock_open', 'inward', 'outward', 'stock_close'];
  /**
   * Selectall available fields in both productivity and sensor reports
   * @param event 
   */
  selectAll(event: any) {
    if (!this.isSensor) {
      Object.keys(this.report_from_group.controls).forEach(key => {
        var leave = [];
        if (this.birdtype == 'broiler') leave = this.broiler.filter(m => (m == key));
        if (leave.length == 0)
          this.report_from_group.get(key).setValue(event.checked);
      });
    } else {
      // Object.keys(this.sensor_report_from_group.controls).forEach(key => {
      //   if (this.sensorsAvailabilty[this.sensordatum[key]]) this.sensor_report_from_group.get(key).setValue(event.checked);
      // });
      Object.keys(this.sensorproduct_group.controls).forEach(key => {
        var product = this.productsList.filter(m => (m.product_name == key));
        if (this.sensorDataList.length > 0) {
          var sensor = this.sensorDataList.filter(m => (m.product_code == product[0]?.product_code));
          if (sensor.length > 0) this.sensorproduct_group.get(key).setValue(event.checked);
        }
      });
    }
  }
  /**
   * uncheck selectall checkbox if any field unchecked after checking selectall checkbox
   * check's selectall checkbox
   * @param event 
   */
  selectAllCheck(event: any) {
    if (event.checked) {
      if (!this.isSensor) {
        var i = 0;
        Object.keys(this.report_from_group.controls).forEach(key => {
          var leave = [];
          if (this.birdtype == 'broiler') leave = this.broiler.filter(m => (m == key));
          if (!this.report_from_group.get(key).value && leave.length == 0) { i++; }
        });
        if (i > 0) { this.selectallbtn.setValue(false); } else { this.selectallbtn.setValue(true); }
      } else {
        var i = 0;
        Object.keys(this.sensor_report_from_group.controls).forEach(key => {
          if (!this.sensor_report_from_group.get(key).value) { i++; }
        });
        if (i > 0) { this.selectallbtn.setValue(false); } else { this.selectallbtn.setValue(true); }
      }
    } else {
      this.selectallbtn.setValue(false);
    }
  }
  selectAllCheckSensor(event: any, product: Record<string, any>) {
    if (event.checked) {
      const is_all_selected = Object.values(this.sensorproduct_group.value).every((value: any) => !!value);
      const is_available = this.sensorsAvailabiltymeth(product);
      is_all_selected && is_available ? this.selectallbtn.setValue(true) : this.selectallbtn.setValue(false);
    } else {
      this.selectallbtn.setValue(false);
    }
  }
  toTitleCase(input: string): string {
    if (!input) return '';

    return input
      .toLowerCase()
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ')
      .replace('_', ' ');
  }
  /**
   * switch between productivity and sensor reports page
   */
  selectTab() {
    this.isSensor = !this.isSensor;
    Object.keys(this.report_from_group.controls).forEach(key => {
      this.report_from_group.get(key).setValue(false);
    });
    Object.keys(this.sensor_report_from_group.controls).forEach(key => {
      this.sensor_report_from_group.get(key).setValue(false);
    });
    this.selectallbtn.setValue(false);
  }
  products: any[] = [];
  productsList: any[] = [];
  public sensorproduct_group: any;
  getProducts() {
    this.subscriptions.push(this.productsapi.getProducts().subscribe(res => {
      if (res) {
        this.productsList = res;
        of(res).pipe(
          concatMap((equipment) => equipment),
          groupBy((equipment: any) => equipment.category),
          mergeMap((group$) => group$.pipe(reduce((acc: any, cur: any) => [...acc, cur], [])))
        )
          .subscribe(equipment => this.products.push(equipment));
        this.setProductForm(res);
      }
    }));
  }
  setProductForm(data: any[]) {
    this.sensorproduct_group = this.fb.group({});
    data.forEach((value: any) => {
      if (value.category != 'stand_alone') {
        this.sensorproduct_group.addControl(value.product_name, new FormControl());
      }
    });
  }
  sensorsAvailabiltymeth(product: any) {
    if (this.sensorDataList.length > 0) {
      var sensor = this.sensorDataList.filter(m => (m.product_code == product?.product_code));
      // console.log("sensor.length", sensor.length);

      return sensor.length > 0 ? true : false;
    }
    return false;
  }
  csvdata: any = {};
  /**
   * check atleast any one field is checked or not,if not return false
   * @param event 
   * @returns 
   */
  validateProductivityReport(event: any) {
    var validate = false;
    Object.keys(event).forEach(key => {
      if (event[key].value == true) validate = true;
    });
    return validate;
  }
  /**
   * download productivity report function
   * @returns 
   */
  downloadProductivityReport() {
    const active_batch_id = !!localStorage.getItem('batch_id');
    if (!active_batch_id) {
      this.show_msg.errorSnackBar('report_no_active_batch');
      return;
    }
    var validate = this.validateProductivityReport(this.report_from_group.controls);
    if (!validate) {
      return this.show_msg.errorSnackBar('no_checkbox_selected');
    }
    var data: any = {};
    const date_from = new Date(this.Date_from.value as Date).setHours(0, 0, 0);
    const date_to = new Date(this.Date_to.value as Date).setHours(23, 59, 59);
    data['from_date'] = (new Date(date_from))?.toJSON();
    data['to_date'] = (new Date(date_to))?.toJSON();
    data['tank_id'] = (JSON.parse(localStorage.getItem('tank') || '')?.id)?.toString();
    data['batch_id'] = (JSON.parse(localStorage.getItem('batch_id') || ''))?.toString();
    data['tank_type'] = this.birdtype;
    //'dfhufhudshufh
    this.report_from_group.controls.fish_opening_stock.value ? data['opening_stock'] = '1' : '';
    this.report_from_group.controls.fish_closing_stock.value ? data['closing_stock'] = '1' : '';
    this.report_from_group.controls.fish_mortality_count.value ? data['mortality'] = '1' : '';
    this.report_from_group.controls.fish_sold_count.value ? data['sold'] = '1' : '';
    this.report_from_group.controls.fish_transfer_in_count.value ? data['transfer_in'] = '1' : '';
    this.report_from_group.controls.fish_transfer_out_count.value ? data['transfer_out'] = '1' : '';
    this.report_from_group.controls.labour_attendance.value ? data['labour_attedance'] = '1' : '';
    this.report_from_group.controls.feed_per_fish.value ? data['feed_per_fish'] = '1' : '';
    this.report_from_group.controls.avg_f_weight.value ? data['avg_f_weight'] = '1' : '';


    this.report_from_group.controls.type_feed.value ? data['feed'] = '1' : '';
    this.report_from_group.controls.medication.value ? data['medication'] = '1' : '';
    this.report_from_group.controls.equipment.value ? data['equipment'] = '1' : '';

    this.subscriptions.push(this.Report.getProductivityReport(data).subscribe((res: any) => {
      if (res?.data.length > 0) this.Scores.downloadCsv(res.data, 'Reports');
    }, (error: Response) => {
      this.show_msg.errorSnackBar('report_no_data');
    }));
  }
  /**
   * download sensor report function
   * @returns 
   */
  downloadSensorData() {
    var validate = this.validateProductivityReport(this.sensorproduct_group.controls);
    if (!validate) {
      return this.show_msg.errorSnackBar('no_checkbox_selected');
    }
    var request: any = {};
    console.log(this.Date_from.value);

    request['startDate'] = (new Date((this.min_date?.setHours(0, 0, 0)) as unknown as Date))?.toJSON();
    request['endDate'] = (new Date((this.max_date?.setHours(23, 59, 59)) as unknown as Date))?.toJSON();
    var multilist: any[] = [];
    var senderlist: any = [];
    Object.keys(this.sensorproduct_group.controls).forEach(key => {
      var product = this.productsList.filter(m => (m.product_name == key));
      if (this.sensorproduct_group.controls[key].value) {
        var formfilter = this.sensorDataList.filter(m => (m.product_code == product[0]?.product_code));
        formfilter.filter(n => {
          multilist.push(n.device_id);
          senderlist.push(n);
        });
      }
    });
    request['deviceList'] = multilist;
    request['timeZone'] = Intl.DateTimeFormat().resolvedOptions().timeZone;;
    this.subscriptions.push(this.Report.getSensorReport(request).subscribe((res: any) => {
      var obj = this.filterdataSensor(res.data, senderlist, res.deviceWithNoData);
      this.ReportService.downloadFile(obj.y, 'report', obj.x);
    }, (error: Response) => {
      this.show_msg.errorSnackBar("report_no_data");
    }));
  }
  /**
   * filter sensor data from api responce and create list for CSV report
   * @param data 
   * @param sensors 
   * @param nodata 
   * @returns 
   */
  filterdataSensor(data: any[], sensors: any[], nodata: any[]) {
    var datalist: any[] = [];
    var headerlist: any[] = [];
    var dummylist: any[] = [];
    headerlist.push('Date');
    headerlist.push('Time');
    var arraylist: any[] = [];
    datalist.push(...sensors);
    datalist.sort((a: any, b: any) => a.sensor_type_id - b.sensor_type_id);
    data.filter(sensordata => {
      var singledata: any = {};
      dummylist = [];
      datalist.filter(sensor => {
        Object.keys(sensordata).forEach(key => {
          var comparekey1 = sensor.name;
          var comparekey2 = (key.toString()).split(' - ')[0];
          if (comparekey1 == comparekey2) {
            singledata[key] = sensordata[key];
            dummylist.push(key);
          }
          if (key == 'date') {
            singledata['Date'] = sensordata[key];
          }
          if (key == 'hour') {
            singledata['Time'] = sensordata[key] + ':00:00';
          }
        });
        var nodatum = nodata.length > 0 ? nodata.filter(m => (m.device_id == sensor.device_id)) : [];
        if (nodatum.length > 0) {
          singledata[nodatum[0].name] = '-';
          dummylist.push(nodatum[0].name);
        }
      });
      arraylist.push(singledata);
    });
    headerlist.push(...dummylist);
    var obj = { x: headerlist, y: arraylist };
    return obj;
  }
  ngOnDestroy() {
    this.subscriptions.forEach((sub: Subscription) => sub.unsubscribe());
  }

}
