
export interface ALERT_INTERFACE {
    id: number,
    name: string;
}

export const ALERT_LEVELS: ALERT_INTERFACE[] = [
    { id: 0, name: 'Level 0', },
    { id: 1, name: 'Level 1' },
    { id: 2, name: 'Level 2' },
    { id: 3, name: 'Level 3' },
    { id: 4, name: 'Level 4' },
    { id: 5, name: 'Level 5' }
];