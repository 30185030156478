/* eslint-disable max-len */
import { Injectable } from "@angular/core";
import {
    HttpRequest,
    HttpHandler,
    HttpInterceptor,
    HttpErrorResponse,
    HttpResponse
} from "@angular/common/http";
import { catchError, finalize, Observable, tap, throwError } from "rxjs";
import { MessageService } from "../services/message/message.service";
import jwt_decode from 'jwt-decode';
import { Router } from "@angular/router";

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

    constructor (
        private message: MessageService, private router: Router,
    ) { }
    intercept (request: HttpRequest<unknown>, next: HttpHandler): Observable<any> {
        const token = localStorage.getItem("token");
        const exclude_urls = ['getLatestSensorData', 'getSensorSopUsingDeviceId', 'showAlertValueStatus', 'showAlertLevelStatus', 'showSensorConfigStatus', 'showScore', 'showLatestControllerData'];
        const spin = localStorage.getItem('doSpin');
        var boolValue = spin != null ? JSON.parse(spin) : false;
        if (!exclude_urls.some((url: any) => request.url.includes(url)) || boolValue) {
            this.message.show();
        }
        request = request.clone({
            headers: request.headers.set("Authorization", "Bearer " + token)
        });
        return next.handle(request).pipe(
            tap(res => {
                if (request.method !== 'GET') {
                    if (res instanceof HttpResponse) {
                        const msg = res.body?.msg;
                        msg && this.message.successSnackBar(msg);
                    }
                }
            }),
            catchError((err: any): any => {

                if (request.method !== 'GET') {
                    const msg = err.error?.msg;
                    if (err instanceof HttpErrorResponse && msg) {
                        this.message.errorSnackBar(msg);
                    } else {
                        this.message.errorSnackBar('something_went_wrong');
                    }
                }
                // return of({});
                return throwError(null);
            }), finalize(() => {
                this.message.hide();

            })
        );

    }
    getDecodedAccessToken (token: string): any {
        try {
            return jwt_decode(token);
        } catch (Error) {
            return null;
        }
    }
}
