import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { MessageService } from 'src/app/core/services/message/message.service';
import { HOME_TABS } from 'src/shared/model/layout-model/layout-model';
import { LayoutService } from '../../layout/layout-service/layout.service';
import { HomeService } from 'src/app/core/services/home/home.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { AlertDialogComponent } from 'src/shared/alert-dialog/alert-dialog.component';
@Component({
  selector: 'app-home-layout',
  templateUrl: './home-layout.component.html',
  styleUrls: ['./home-layout.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class HomeLayoutComponent implements OnInit {
  public current_time: any;
  public current_tab: any;
  public button_label: any;
  public user_data: any = localStorage.getItem('user_data') ? JSON.parse(localStorage.getItem('user_data') || '') : null;
  public default_menu_index: any;

  public tabs: any = HOME_TABS();
  public start_loader = false;
  public is_tab_clicked = false;
  public is_hide_button = true;
  previous_index: any = Number(localStorage.getItem('previous_tab'));
  public access: any;
  constructor (
    private dialog: MatDialog,
    private service: LayoutService,
    private message: MessageService,
    private home: HomeService,
  ) {
  }

  async ngOnInit (): Promise<void> {
    this.service.hide_home_tab_button.subscribe((hide: any) => {
      this.is_hide_button = hide;
    });

    // to show and hide common loader
    this.message.loaderState.subscribe((state: any) => {
      setTimeout(() => {
        this.start_loader = state;
      }, 150);
    });

    if (this.user_data.user_role >= 3) {
      await this.findFarmsCount();
    }
    this.findDefaultTabs();

    /**----------------------------------------------------------------------------------------------------*/

    /**Subscribes to the "home_tab" observable to listen for changes in the selected tab index.
    Updates the current tab, button label, access permissions, and visibility of the button based on the selected tab.
    Manages the enabling and disabling of tabs based on the selected and previous tab indexes.
    Handles special cases when selecting tabs from right to left or when reaching the last tab.
    */
    this.service.home_tab.subscribe((index: any) => {
      const i = Number(index);
      this.current_tab = i;
      this.button_label = this.tabs[i]?.label;
      this.access = this.home.getAccess(this.tabs[i].menu_id);
      if (this.user_data.user_role == 5) {
        this.is_hide_button = false;
      }
      const previous_tab = Number(localStorage.getItem('previous_tab'));
      this.tabs[i].enable = true;
      // tab select from right to left
      if (i <= this.default_menu_index) {
        let hide_index = this.default_menu_index;
        this.tabs.forEach((t: any, index: any) => {
          hide_index++;
          if (this.tabs[hide_index]?.enable) {
            this.tabs[hide_index].enable = false;
          }
        });
        return;
      }
      if (i <= previous_tab) {
        let hide_pre_tab = i;
        this.tabs.forEach((t: any, index: any) => {
          hide_pre_tab++;
          if (this.tabs[hide_pre_tab]?.enable) {
            this.tabs[hide_pre_tab].enable = false;
          }
        });
      }
      // hide next tabs from current tabs
      if (i) {
        let hide_next_tabs = i;
        this.tabs.forEach((t: any, index: any) => {
          hide_next_tabs++;
          if (this.tabs[hide_next_tabs]?.enable) {
            this.tabs[hide_next_tabs].enable = false;
          }
        });
      }

      if (i + 1 == this.tabs?.length) {
        this.tabs.forEach((t: any, index: any) => {
          this.tabs[index].enable = true;
        });
      }

    });

    /**----------------------------------------------------------------------------------------------------*/


  }

  findFarmsCount = () => {
    return new Promise((resolve, rejects) => {
      this.home.getAllFarmsUnderFarmUser().subscribe((res: any) => {
        if (res?.data?.length == 1) {
          this.tabs = this.tabs.filter((t: any) => t.menu_id != 4);
          this.default_menu_index = 1;
          this.tabs = this.tabs.map((t: any) => {
            t.enable = true;
            return t;
          });
        }
        this.findDefaultTabs();
        resolve(true);
      }, (error: any) => {
        resolve(true);
      });
    });



  };

  /**----------------------------------------------------------------------------------------------------*/

  /**Calculates the default menu index based on the user's role and the menu IDs.
  The default menu index is used to determine the tabs that should be shown without hiding.
    */
  findDefaultTabs = () => {
    this.default_menu_index = this.tabs?.reduce((count: any, menu: any) => {
      if (menu.menu_id === 1 || menu.menu_id === 2 || menu.menu_id == 3 && this.user_data.user_role !== 1) {
        return count + 1;
      } else {
        return count;
      }
    }, 0);
  };
  /**----------------------------------------------------------------------------------------------------*/

  emitCreateButton () {
    this.is_hide_button = false;
    localStorage.setItem("show_create_button", JSON.stringify(false));
    this.service.showCreateButton(this.is_hide_button);
  }

  openAlertSettingPopUp () {
    const config: MatDialogConfig = {
      panelClass: 'dialog-responsive',
      width: '50%',
      height: '96%',
      disableClose: true,
    };
    this.dialog.open(AlertDialogComponent, config);

  }

  /**----------------------------------------------------------------------------------------------------*/

  /**Handles the selection of a tab in the UI.
  Updates the button label, sets the "show_create_button" flag in local storage to true, and updates the previous tab index.
  Manages the visibility of the button and triggers the switchHomeTab and showCreateButton methods in the service.
  @param data The MatTabChangeEvent object containing information about the selected tab.
  */
  selectTab (data: MatTabChangeEvent) {
    // setTimeout(() => {
    this.button_label = this.tabs[data.index]?.label;
    localStorage.setItem("show_create_button", JSON.stringify(true));
    localStorage.setItem('previous_tab', this.previous_index);
    this.is_hide_button = true;
    this.previous_index = data.index;
    let index = data.index;
    this.current_tab = index;
    this.service.switchHomeTab(index);
    this.service.showCreateButton(this.is_hide_button);
    // }, 100);

  }

  /**----------------------------------------------------------------------------------------------------*/

}
