import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subject } from 'rxjs';
import { CustSnackbarComponent } from 'src/shared/cust-snackbar/cust-snackbar.component';

@Injectable({
  providedIn: 'root'
})
export class MessageService {

  constructor(
    private snackBar: MatSnackBar

  ) { }



  successSnackBar(msg: any) {
    this.snackBar.openFromComponent(CustSnackbarComponent, {
      duration: 2 * 1000,
      horizontalPosition: "right",
      verticalPosition: "top",
      data: {
        msg,
        success: true
      },
    });

  }
  infoSnackBar(msg: any) {
    this.snackBar.openFromComponent(CustSnackbarComponent, {
      duration: 4 * 1000,
      horizontalPosition: "right",
      verticalPosition: "top",
      data: {
        msg,
        info: true
      },
    });

  }
  errorSnackBar(msg: any) {
    this.snackBar.openFromComponent(CustSnackbarComponent, {
      duration: 4 * 1000,
      horizontalPosition: "right",
      verticalPosition: "top",
      data: {
        msg,
        error: true
      },
    });

  }

  showFromGroupWarning(form_group: FormGroup, user_label?: any) {
    const breedType = localStorage.getItem('tank')?.length
      ? JSON.parse(localStorage.getItem('tank') || '')?.breedType : null;
    let is_error = false;
    for (let key in form_group.controls) {
      let value = form_group.controls[key];
      if (value.invalid && !value.value?.length) {
        is_error = true;
        key = key.toLocaleLowerCase().includes('role_id') ? 'user role' : key;
        key = key.toLocaleLowerCase().includes('user_id') ? user_label || '' : key;
        key = key.toLocaleLowerCase().includes('company_id') ? 'company' : key;
        key = key.toLocaleLowerCase().includes('feed_id') ? 'type of feed' : key;
        key = key.toLocaleLowerCase().includes('avg_f_weight') ? 'average weight' : key;
        key = key.toLocaleLowerCase().includes('shed_type_id') ? 'shed type' : key;
        key = key.toLocaleLowerCase().includes('bird_type_id') ? 'bird type' : key;
        key = key.toLocaleLowerCase().includes('state_id') ? 'state' : key;
        key = key.toLocaleLowerCase().includes('country_id') ? 'country' : key;
        key = key.toLocaleLowerCase().includes('primary_spoc') ? 'contact person name' : key;
        key = key.toLocaleLowerCase().includes('contact_no') ? 'contact number' : key;
        key = key.toLocaleLowerCase() == 'mobile' ? 'mobile number' : key;
        key = key.toLocaleLowerCase() == 'whatsapp' ? 'whatsapp number' : key;
        key = key.toLocaleLowerCase() == 'dial_code_mobile_no' ? 'mobile country code' : key;
        key = key.toLocaleLowerCase() == 'dial_code_whatsapp_no' ? 'whatsapp country code' : key;
        key = key.toLocaleLowerCase() == 'city_name' ? 'city' : key;
        key = key.toLocaleLowerCase() == 'tax_no' ? 'tax number' : key;
        key = key.toLocaleLowerCase() == 'primary_email' ? 'email' : key;
        key = key.toLocaleLowerCase() == 'length' ? 'shed length' : key;
        key = key.toLocaleLowerCase() == 'width' ? 'shed width' : key;
        key = key.toLocaleLowerCase() == 'height' ? 'shed height' : key;
        key = key.toLocaleLowerCase() == 'fish_mortality_count' ? breedType == 'fish' ? 'fish mortality count' : 'shrimp mortality count' : key;
        key = key.toLocaleLowerCase() == 'fish_sold_count' ? breedType == 'fish' ? 'fish sold count' : 'shrimp sold count' : key;
        key = key.toLocaleLowerCase() == 'fish_transfer_in_count' ? breedType == 'fish' ? 'fish transfer in count' : 'shrimp transfer in count' : key;
        key = key.toLocaleLowerCase() == 'fish_transfer_out_count' ? breedType == 'fish' ? 'fish transfer out count' : 'shrimp transfer out count' : key;
        key = key.toLocaleLowerCase() == 'feed_per_fish' ? breedType == 'fish' ? 'feed per fish' : 'feed per shrimp' : key;



        console.log("Key in errorSnack", key);
        this.errorSnackBar(`${key.replace(/_/g, " ")}`);
        break;
      }
    }
    return is_error;
  }

  private loaderSubject = new Subject<boolean>();
  loaderState = this.loaderSubject.asObservable();

  private pending = 0;

  show() {
    setTimeout(() => {
      this.pending++;
      this.loaderSubject.next(true);
    }, 300);
  }

  hide() {
    setTimeout(() => {
      this.pending--;
      if (this.pending <= 0) {
        this.loaderSubject.next(false);
      }
    }, 300);
  }
}
