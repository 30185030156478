import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { GrowthService } from 'src/app/core/services/growth/growth.service';
import { MessageService } from 'src/app/core/services/message/message.service';
import { ScoreDialogComponent } from 'src/shared/score-dialog/score-dialog.component';

@Component({
  selector: 'app-create-growth-score',
  templateUrl: './create-growth-score.component.html',
  styleUrls: ['./create-growth-score.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CreateGrowthScoreComponent implements OnInit {
  public growth_score_group: any;
  public header_group: any;
  isupdate: boolean = false;
  WeeklyGain: any = new FormArray([]);
  dataSource1 = new MatTableDataSource([]);
  WeeklyWeight: any = new FormArray([]);
  dataSource2 = new MatTableDataSource([]);
  isview = false;
  public shed_id = localStorage.getItem('tank')?.length ? JSON.parse(localStorage.getItem('tank') || '')?.id : null;
  public company_name = localStorage.getItem('company')?.length ? JSON.parse(localStorage.getItem('company') || '')?.name : '--';
  public shed_name = localStorage.getItem('tank')?.length ? JSON.parse(localStorage.getItem('tank') || '')?.name : '--';
  public subscriptions: Subscription[] = [];
  @Output() dataReceive = new EventEmitter<any>();
  @Input('growthdata') growthdata: any;
  public remove_ids: Array<number> = [];
  displayedColumns = [
    "Threshold",
    "weeklyfemale",
    "weeklyfemalescore",
    "weeklymale",
    "weeklymalescore"
  ];
  constructor(private fb: FormBuilder, private show_msg: MessageService, private dialog: MatDialog, private translate: TranslateService,
    private growth: GrowthService) { }

  ngOnInit(): void {
    this.getGrowthScore();
    this.growthdata?.data?.length ? this.isupdate = true : this.isupdate = false;
    // to move max limit to the end of an array
    if (this.growthdata?.data?.length) {
      const max_data = this.growthdata.data?.find(((max: Record<string, number>) => Number(max['f_expected_weight']) == 10101 && Number(max['f_expected_weight_gain']) == 10101));
      this.growthdata.data = this.growthdata.data?.filter(((max: Record<string, number>) => Number(max['f_expected_weight']) !== 10101 && Number(max['f_expected_weight_gain']) !== 10101));
      this.growthdata.data?.push(max_data);
    }

    if (!this.isupdate) {
      // this.growthScoreArray.push(this.init());
      this.headerLoad();
      this.initialthreshold();
    };
    if (this.isupdate) this.isview = this.growthdata.screen == 'view' ? true : false;
    if (this.isupdate) this.growthUpdate(this.growthdata.data);
  }
  /**
   * form group for age in weeks,feed issue for male and female
   */
  headerLoad() {
    this.header_group = this.fb.group({
      age_In_Week: new FormControl('', [Validators.required]),
      feed_issue_female: new FormControl('', [Validators.required]),
      feed_issue_male: new FormControl('', [Validators.required])
    });
  }
  /**
   * form group for female and male growth score and value
   * @returns 
   */
  setvalue() {
    return new FormGroup({
      weekly_female: new FormControl(null, [Validators.required]),
      weekly_female_score: new FormControl(0, [Validators.required]),
      weekly_male: new FormControl(null, [Validators.required]),
      weekly_male_score: new FormControl(0),
    });
  }
  /**
   * get control name for growth score threshold table
   * @param index 
   * @param controlName 
   * @param array 
   * @returns 
   */
  getControl(index: number, controlName: string, array: FormArray): FormControl {
    return (array.at(index) as FormGroup).get(controlName) as FormControl;
  }
  /**
   * get value for growth score threshold table
   * @param controlName 
   * @param formGroup 
   * @returns 
   */
  givevalue(controlName: string, formGroup: FormGroup) {
    return formGroup.get(controlName)?.value;
  }
  /**
   * add new threshold for particular score table
   * @param source 
   * @param array 
   * @param index 
   */
  // addSensor (index: any) {
  //   if (this.WeeklyGain.length <= 9 && this.WeeklyWeight.length <=9) {
  //     this.WeeklyGain.insert(index + 1, this.setvalue());
  //     this.WeeklyWeight.insert(index + 1, this.setvalue());
  //   } else {
  //     this.show_msg.errorSnackBar("reached_max_threshold");
  //   }
  //   this.dataSource1.data = this.WeeklyGain.controls as never[];
  //   this.dataSource2.data = this.WeeklyWeight.controls as never[];
  // }
  addSensor(source: MatTableDataSource<never>, array: FormArray, index: any) {
    if (array.length <= 9) {
      array.insert(index + 1, this.setvalue());
    } else {
      this.show_msg.errorSnackBar("reached_max_threshold");
    }
    source.data = array.controls as never[];
  }
  /**
   * remove selected threshold from particular threshold table
   * @param index 
   * @param source 
   * @param array 
   */
  removeSensor(index: any, source: MatTableDataSource<never>, array: FormArray) {
    array.value[index]?.id ? this.remove_ids.push(array.value[index]?.id) : null;
    array.removeAt(index);
    source.data = array.controls as never[];
  }
  /**
   * add necessary thresholds in tables when component initialized
   */
  initialthreshold() {
    for (var i = 0; i <= 4; i++) {
      this.WeeklyGain.push(this.setvalue());
      this.WeeklyWeight.push(this.setvalue());
    }
    this.dataSource1.data = this.WeeklyGain.controls;
    this.dataSource2.data = this.WeeklyWeight.controls;
  }
  /**
   * set values during update and view screen
   * @param score 
   */
  growthUpdate(score: any) {
    this.header_group = this.fb.group({
      age_In_Week: new FormControl(score[0].age, [Validators.required]),
      feed_issue_female: new FormControl(score[0].f_expected_feed, [Validators.required]),
      feed_issue_male: new FormControl(score[0].m_expected_feed, [Validators.required])
    });
    score.filter((m: any) => {
      if (m.f_expected_weight_gain != null) this.WeeklyGain.push(this.setvalueupdate1(m));
      if (m.f_expected_weight != null) this.WeeklyWeight.push(this.setvalueupdate2(m));
    });
    this.dataSource1.data = this.WeeklyGain.controls;
    this.dataSource2.data = this.WeeklyWeight.controls;
    if (this.isupdate && !this.isview) {
      this.header_group.get('age_In_Week')?.disable();
    }
  }
  /**
   * sub-method for growthUpdate to set values for weight gain during update
   * @param score 
   * @returns 
   */
  setvalueupdate1(score: any) {
    return new FormGroup({
      weekly_female: new FormControl(score.f_expected_weight_gain, [Validators.required]),
      weekly_female_score: new FormControl(score.f_weight_gain_max_score, [Validators.required]),
      weekly_male: new FormControl(score.m_expected_weight_gain, [Validators.required]),
      weekly_male_score: new FormControl(score.m_weight_gain_max_score),
      id: new FormControl(score.id)
    });
  }
  /**
   * sub-method for growthUpdate to set values for expected weight during update
   * @param score 
   * @returns 
   */
  setvalueupdate2(score: any) {
    return new FormGroup({
      weekly_female: new FormControl(score.f_expected_weight, [Validators.required]),
      weekly_female_score: new FormControl(score.f_weight_max_score, [Validators.required]),
      weekly_male: new FormControl(score.m_expected_weight, [Validators.required]),
      weekly_male_score: new FormControl(score.m_weight_max_score),
      id: new FormControl(score.id)
    });
  }
  tabledata: any[] = [];
  /**
   * get growth score values api
   */
  getGrowthScore() {
    this.subscriptions.push(this.growth.getGrowthScore().subscribe((res: any) => {
      if (res.data?.length) {
        this.tabledata = res.data;
      }
    }));
  }
  /**
   * info popup to show maximum score value in table
   * @param array 
   * @param controlName 
   */
  info(array: FormArray, controlName: string) {
    var maxval = 0;
    var attain = false;
    array.controls.filter((a: any) => {
      var index = array.controls.indexOf(a);
      if (a.value[controlName] != '' && index != (array.controls.length - 1)) {
        maxval = parseFloat(a.value[controlName]) > maxval ? (parseFloat(a.value[controlName])) : maxval;
        attain = true;
      }
    });
    const config: MatDialogConfig = {
      panelClass: 'dialog-responsive-info',
      width: '40%',
      minWidth: '40%',
      disableClose: true,
      data: {
        title: `${attain ? maxval : ''}`,
        isDelete: true
      },
    };
    const openDialog = this.dialog.open(ScoreDialogComponent, config);
    this.subscriptions.push(openDialog.afterClosed().subscribe((result: any) => {
    }));
  }

  deleteScore = () => {
    const req_obj = {
      ids: [...new Set(this.remove_ids)]
    };
    this.subscriptions.push(this.growth.deleteGrowth(req_obj).subscribe((res: any) => {
    }, (error: Response) => {
      console.log(error);
    }));
  };
  /**
   * check weather submit or update according to new score or existing score
   * @returns 
   */
  submit() {
    if (!this.isupdate) {
      var agelist = this.tabledata.filter((m) => (m.age == this.header_group.controls.age_In_Week.value));
      if (agelist.length > 0) {
        return this.show_msg.errorSnackBar('age_in_week_already_exists');
      }
    }
    var array = [this.WeeklyGain, this.WeeklyWeight];
    var data = this.verify(array);
    if (data || this.header_group.invalid) {
      return this.show_msg.errorSnackBar('no_empty_field');
    }
    if (this.WeeklyGain.length !== this.WeeklyWeight.length) {
      this.show_msg.errorSnackBar('thresholds mismatch');
      return;
    }
    var maxth = array.sort((a: any, b: any) => b.length - a.length)[0].length;
    var obj = this.createSaveobj(maxth);
    if (!this.isupdate) this.save(obj);
    if (this.isupdate) this.update(obj);
    if (this.remove_ids.length)
      this.deleteScore();
  }
  /**
   * verify where all fields are filled
   * @param array 
   * @returns 
   */
  verify(array: any[]) {
    var data = false;
    array.filter((m: any) => {
      for (var i = 0; i <= m.controls.length - 2; i++) {
        if (m.controls[i].status == 'INVALID') {
          data = true;
        }
      }
    });
    return data;
  }
  /**
   * create object for save api
   * @param num 
   * @returns 
   */
  createSaveobj(num: any) {
    var datalist: any = [];
    for (var i = 0; i < num - 1; i++) {
      var data: any = {};
      data['shed_id'] = localStorage.getItem('tank')?.length ? JSON.parse(localStorage.getItem('tank') || '')?.id : null;
      data['age'] = this.header_group.controls.age_In_Week.value;
      data['f_expected_feed'] = this.header_group.controls.feed_issue_female.value;
      data['m_expected_feed'] = this.header_group.controls.feed_issue_male.value;
      if (i < this.WeeklyGain.length - 1) {
        data['f_expected_weight_gain'] = this.WeeklyGain.controls[i]?.controls.weekly_female.value != '' ? this.WeeklyGain.controls[i]?.controls.weekly_female.value : null;
        data['f_weight_gain_max_score'] = this.WeeklyGain.controls[i]?.controls.weekly_female_score.value;
        data['m_expected_weight_gain'] = this.WeeklyGain.controls[i]?.controls.weekly_male.value != '' ? this.WeeklyGain.controls[i]?.controls.weekly_male.value : null;
        data['m_weight_gain_max_score'] = this.WeeklyGain.controls[i]?.controls.weekly_male_score.value;
        data['id'] = this.WeeklyGain.controls[i].controls?.id?.value || null;
      }
      if (i < this.WeeklyWeight.length - 1) {
        data['f_expected_weight'] = this.WeeklyWeight.controls[i]?.controls.weekly_female.value != '' ? this.WeeklyWeight.controls[i]?.controls.weekly_female.value : null;
        data['f_weight_max_score'] = this.WeeklyWeight.controls[i]?.controls.weekly_female_score.value;
        data['m_expected_weight'] = this.WeeklyWeight.controls[i]?.controls.weekly_male.value != '' ? this.WeeklyWeight.controls[i]?.controls.weekly_male.value : null;
        data['m_weight_max_score'] = this.WeeklyWeight.controls[i]?.controls.weekly_male_score.value;
        data['id'] = this.WeeklyWeight.controls[i].controls?.id?.value || null;
      }
      datalist.push(data);
    }
    var data1: any = {};
    data1['shed_id'] = localStorage.getItem('tank')?.length ? JSON.parse(localStorage.getItem('tank') || '')?.id : null;
    data1['age'] = this.header_group.controls.age_In_Week.value;
    data['f_expected_feed'] = this.header_group.controls.feed_issue_female.value;
    data['m_expected_feed'] = this.header_group.controls.feed_issue_male.value;
    data1['f_expected_weight_gain'] = 10101;
    data1['f_weight_gain_max_score'] = this.WeeklyGain.controls[this.WeeklyGain.length - 1].controls.weekly_female_score.value;
    data1['m_expected_weight_gain'] = 10101;
    data1['m_weight_gain_max_score'] = this.WeeklyGain.controls[this.WeeklyGain.length - 1].controls.weekly_male_score.value;
    data1['f_expected_weight'] = 10101;
    data1['f_weight_max_score'] = this.WeeklyWeight.controls[this.WeeklyWeight.length - 1].controls.weekly_female_score.value;
    data1['m_expected_weight'] = 10101;
    data1['m_weight_max_score'] = this.WeeklyWeight.controls[this.WeeklyWeight.length - 1].controls.weekly_male_score.value;
    data1['id'] = this.WeeklyWeight.controls[this.WeeklyWeight.length - 1]?.controls?.id?.value || this.WeeklyGain.controls[this.WeeklyGain.length - 1]?.controls?.id?.value;
    datalist.push(data1);
    return datalist;
  }
  /**
   * update if its exesting score not developed yet
   */
  update(data: any) {
    const reqObj = {
      data
    };
    this.subscriptions.push(this.growth.updateGrowth(reqObj).subscribe((res: any) => {
      this.dataReceive.emit(false);
    }));

  }
  /**
   * save if its new score
   * @param save 
   */
  save(save: any) {
    var saveReq: any = {
      data: save
    };
    this.subscriptions.push(this.growth.postGrowth(saveReq).subscribe((res: any) => {
      this.dataReceive.emit(false);
    }));
  }
  /**
   * clear array and value from create to list component 
   */
  clear() {
    this.dataReceive.emit(false);
  }

  validateThresholdValue(i: number, control_name: string, form_array: FormArray) {
    if (i == 0) {
      const current_value = form_array.at(i).get(control_name)?.value;
      const next_value = form_array.at(i + 1).get(control_name)?.value;
      if ((current_value >= next_value) && next_value != null) {
        form_array.at(i).get(control_name)?.reset();
        this.subscriptions.push(
          this.translate.get('growth_score.must_be_less_than').subscribe((translation) => {
            this.show_msg.errorSnackBar(`${translation} ${next_value}`);
          })
        );

      }
    }
    if (i > 0) {
      const current_value = form_array.at(i).get(control_name)?.value;
      const previous_value = form_array.at(i - 1).get(control_name)?.value;
      const next_value = form_array.at(i + 1).get(control_name)?.value;

      if (current_value || current_value == 0) {
        if (previous_value == null) {
          form_array.at(i).get(control_name)?.reset();
          this.subscriptions.push(
            this.translate.get('growth_score.enter_threshold_value').subscribe((translation) => {
              this.show_msg.errorSnackBar(`${translation} ${(i + 1) - 1}`);
            })
          );
          return;
        }
        if (previous_value >= current_value) {
          form_array.at(i).get(control_name)?.reset();
          this.subscriptions.push(
            this.translate.get('growth_score.must_be_greater_than').subscribe((translation) => {
              this.show_msg.errorSnackBar(`${translation} ${previous_value}`);
            })
          );
          return;
        }
        if ((next_value <= current_value) && (next_value != null && Number(next_value) != 10101)) {
          form_array.at(i).get(control_name)?.reset();
          this.subscriptions.push(
            this.translate.get('growth_score.must_be_less_than').subscribe((translation) => {
              this.show_msg.errorSnackBar(`${translation} ${next_value}`);
            })
          );
        }
      }

    }
  }
  ngOnDestroy() {
    this.subscriptions.forEach((sub: Subscription) => sub.unsubscribe());
  }
}
