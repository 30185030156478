<div fxLayoutAlign="space-between center">
    <div style="font-size: 18px;"><b>{{dialogData.title |translate|titlecase}}</b></div>
    <mat-icon (click)="dialogRef.close()">close</mat-icon>
</div>
<form [formGroup]="recalculate_form">
    <div fxLayout="column" fxLayoutGap="10" style="margin-top: 20px;" class="radio">
        <mat-radio-group formControlName="recal_type" fxLayout="column" fxLayoutAlign="center center">
            <div fxLayoutAlign="center start" fxLayout="column" fxLayoutGap="10">
                <mat-radio-button *ngIf="!!!this.dialogData.is_growth_score" [value]="1">
                    <span>

                        {{"recalculate.for_current_week" |translate|titlecase}}
                    </span>
                </mat-radio-button>
                <mat-radio-button [value]="2">
                    <span>

                        {{"recalculate.for_entire_batch"|translate|titlecase}}
                    </span>
                </mat-radio-button>
            </div>

        </mat-radio-group>

        <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="20" class="m-t-10" fxLayoutAlign="center center">
            <button class="submit grow" mat-stroked-button
                (click)="calculate()">{{'button.submit'|translate|titlecase}}</button>
            <button class="cancel grow" mat-stroked-button
                (click)="dialogRef.close()">{{'button.cancel'|translate|titlecase}}</button>
        </div>

    </div>
</form>