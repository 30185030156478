import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import {
  DateAdapter,
  MAT_DATE_LOCALE,
  MAT_DATE_FORMATS,
} from '@angular/material/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { BatchService } from 'src/app/core/services/batch/batch.service';
import { DayEntryService } from 'src/app/core/services/day-entry/day-entry.service';
import { MessageService } from 'src/app/core/services/message/message.service';
import { MessageDialogComponent } from 'src/shared/message-dialog/message-dialog.component';
import {
  MomentDateAdapter,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
} from '@angular/material-moment-adapter';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { HomeService } from 'src/app/core/services/home/home.service';
import { TranslateService } from '@ngx-translate/core';

const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'DD-MM-YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};
@Component({
  selector: 'app-day-wise-entry',
  templateUrl: './day-wise-entry.component.html',
  styleUrls: ['./day-wise-entry.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class DayWiseEntryComponent implements OnInit, OnDestroy {
  @ViewChild(MatPaginator)
  public paginator!: MatPaginator;
  public pageEvent!: PageEvent;
  public current_time: any;
  public hours: any;
  public msg: any;
  public feed_type: any = [];
  public equipment_list: any = [];
  public equipment_failure: any = [];
  public schedules: Array<any> = [];
  public source_schedules: Array<any> = [];
  public current_entry_age: any;
  public subscriptions: Subscription[] = [];

  public length = 0;
  public pageSize = 10;
  public pageIndex = 0;
  public pageSizeOptions = [5, 10, 25];

  public disabled = false;
  public hidePageSize = false;
  public showPageSizeOptions = true;
  public showFirstLastButtons = true;
  public is_day_entry_table = true;
  public disable_submit = false;
  public view_only = false;
  last_entry_date!: Date;

  public max_date: any = new Date();
  public start_date: any = new Date();
  public end_date: any = new Date();
  public min_date: any = new Date();
  public readonly: any = false;
  public day_entry_from_group: any;
  public dated_on = new FormControl({ value: new Date(), disabled: false });
  public company_name = localStorage.getItem('company')?.length
    ? JSON.parse(localStorage.getItem('company') || '')?.name
    : '--';
  public tank_name = localStorage.getItem('tank')?.length
    ? JSON.parse(localStorage.getItem('tank') || '')?.name
    : '--';
  public birdtype = localStorage.getItem('tank')?.length
    ? JSON.parse(localStorage.getItem('tank') || '')?.breedType
    : '--';
  // public birdtype='layer';
  // public birdtype='broiler';
  public displayedMainColumns: string[] = [
    'Date',
    'Opening Stock',
    'Closing Stock',
    // 'Cool Room Stock',
    // 'Total Eggs',
    'Avg Weight',
    'Labour Present',
    'Main Action',
  ];
  public displayedColumns: string[] = [
    'Entry Date',
    'Opeing Stock Female',
    // 'Opeing Stock Male',
    'Closing Stock Female',
    // 'Closing Stock Male',
    // 'Cool Room Opening Stock',
    // 'Cool Room Closing Stock',
    // 'Total',
    'Avg',
    'Present',
    'Action',
  ];
  public displayedColumnsSingle: string[] = [
    'Entry Date',
    'Opeing Stock Female',
    'Closing Stock Female',
    'Present',
    'Action',
  ];
  public dataSource = new MatTableDataSource([]);
  public active_batch_data: any;
  public access: any;
  public shed_data: any;
  constructor(
    private fb: FormBuilder,
    private service: DayEntryService,
    private batch_service: BatchService,
    private show_msg: MessageService,
    private dialog: MatDialog,
    private home: HomeService,
    private translate: TranslateService,

  ) {
    this.access = this.home.getAccess(7);
  }
  branchNotStarted: boolean = false;
  nostartdata = '';
  ngOnInit(): void {
    this.tableformate();
    this.init();
    this.initForm();
  }

  init() {
    this.getShedDetails();
    this.getAllFeeds();
    this.getAllEquipments();
    this.initForm();

    this.subscriptions.push(
      // to get active batch
      this.batch_service.getActiveBatch().subscribe((res: any) => {
        if (res?.data[0]?.batch_id) {
          this.active_batch_data = res?.data[0];
          localStorage.setItem('batch_id', this.active_batch_data.batch_id);
          this.getDayEntry();
          this.getMedicationSchedule();
          if (
            moment(this.active_batch_data.start_date).format('YYYY-MM-DD') >
            moment(new Date()).format('YYYY-MM-DD')
          ) {
            this.branchNotStarted = true;
            this.nostartdata =
              'Batch will start only from ' +
              moment(this.active_batch_data.start_date).format('YYYY-MM-DD');
          }
        }
      })
    );
    this.dayEntryCheck();
  }

  tableformate() {
    if (this.birdtype == 'layer') {
      this.displayedMainColumns = [
        'Date',
        'Opening Stock',
        'Closing Stock',
        // 'Cool Room Stock',
        // 'Total Eggs',
        'Avg Weight',
        'Labour Present',
        'Main Action',
      ];
      this.displayedColumns = [
        'Entry Date',
        // 'Opeing Stock Female',
        // 'Closing Stock Female',
        // 'Cool Room Opening Stock',
        // 'Cool Room Closing Stock',
        // 'Total',
        'Avg',
        'Present',
        'Action',
      ];
    } else {
      this.displayedMainColumns = [
        'Date',
        'Opening Stock',
        'Closing Stock',
        // 'Cool Room Stock',
        // 'Total Eggs',
        'Avg Weight',
        'Labour Present',
        'Main Action',
      ];
      this.displayedColumns = [
        'Entry Date',
        'Opeing Stock Female',
        // 'Opeing Stock Male',
        'Closing Stock Female',
        // 'Closing Stock Male',
        // 'Cool Room Opening Stock',
        // 'Cool Room Closing Stock',
        // 'Total',
        'Avg',
        'Present',
        'Action',
      ];
    }
  }
  /*-------------------------------------Form init--------------------------------------------------*/

  initForm = () => {
    const tank_id = JSON.parse(localStorage.getItem('tank') || '')?.id;
    if (this.birdtype == 'fish') {
      this.day_entry_from_group = this.fb.group({
        fish_type_name: new FormControl(this.birdtype),
        dated_age: new FormControl(null, [Validators.required]),
        tank_id: new FormControl(tank_id, [Validators.required]),
        fish_opening_stock: new FormControl(null, [
          Validators.required,
          Validators.min(0),
        ]),
        fish_mortality_count: new FormControl(null, [
          Validators.required,
          Validators.min(0),
        ]),
        fish_sold_count: new FormControl(null, [
          Validators.required,
          Validators.min(0),
        ]),
        fish_transfer_in_count: new FormControl(null, [
          Validators.required,
          Validators.min(0),
        ]),
        fish_transfer_out_count: new FormControl(null, [
          Validators.required,
          Validators.min(0),
        ]),
        fish_closing_stock: new FormControl(),
        labours_present_count: new FormControl(null, [Validators.required]),
        feed_id: new FormControl(null, [Validators.required]),
        feed_per_fish: new FormControl(null, [Validators.required]),
        avg_f_weight: new FormControl(null),
        remarks: new FormControl(null),

        equipmentlog: new FormArray([this.equipmentGroupFn()]),
        medicationlog: new FormArray([this.medicationGroupFn()]),
      });
    } else if (this.birdtype == 'shrimp') {
      this.day_entry_from_group = this.fb.group({
        fish_type_name: new FormControl(this.birdtype),
        dated_age: new FormControl(null, [Validators.required]),
        tank_id: new FormControl(tank_id, [Validators.required]),
        fish_opening_stock: new FormControl(null, [
          Validators.required,
          Validators.min(0),
        ]),
        fish_mortality_count: new FormControl(null, [
          Validators.required,
          Validators.min(0),
        ]),
        fish_sold_count: new FormControl(null, [
          Validators.required,
          Validators.min(0),
        ]),
        fish_transfer_in_count: new FormControl(null, [
          Validators.required,
          Validators.min(0),
        ]),
        fish_transfer_out_count: new FormControl(null, [
          Validators.required,
          Validators.min(0),
        ]),


        fish_closing_stock: new FormControl(),
        labours_present_count: new FormControl(null, [Validators.required]),
        feed_id: new FormControl(null, [Validators.required]),
        feed_per_fish: new FormControl(null, [Validators.required]),
        avg_f_weight: new FormControl(null),
        remarks: new FormControl(null),
        equipmentlog: new FormArray([this.equipmentGroupFn()]),
        medicationlog: new FormArray([this.medicationGroupFn()]),
      });
    } else {
      this.day_entry_from_group = this.fb.group({
        fish_type_name: new FormControl(this.birdtype),
        dated_age: new FormControl(null, [Validators.required]),
        tank_id: new FormControl(tank_id, [Validators.required]),
        fish_opening_stock: new FormControl(null, [
          Validators.required,
          Validators.min(0),
        ]),
        fish_mortality_count: new FormControl(null, [
          Validators.required,
          Validators.min(0),
        ]),
        fish_sold_count: new FormControl(null, [
          Validators.required,
          Validators.min(0),
        ]),
        fish_transfer_in_count: new FormControl(null, [
          Validators.required,
          Validators.min(0),
        ]),
        fish_transfer_out_count: new FormControl(null, [
          Validators.required,
          Validators.min(0),
        ]),

        fish_closing_stock: new FormControl(),
        labours_present_count: new FormControl(null, [Validators.required]),
        feed_id: new FormControl(null, [Validators.required]),
        feed_per_fish: new FormControl(null, [Validators.required]),
        avg_f_weight: new FormControl(null),

        opening_stock_crs: new FormControl(0),
        receipt_crs: new FormControl(null),
        delivery_crs: new FormControl(null),
        closing_stock_crs: new FormControl(0),
        remarks: new FormControl(null),

        equipmentlog: new FormArray([this.equipmentGroupFn()]),
        medicationlog: new FormArray([this.medicationGroupFn()]),
      });
    }
    this.dated_on.setValue(new Date());
  };

  equipmentGroupFn() {
    return this.fb.group({
      equipment: new FormControl(null),
      failure: new FormControl(''),
      reported_by: new FormControl(''),
      status: new FormControl(''),
    });
  }

  serialCControlFn() {
    return new FormControl('');
  }

  medicationGroupFn() {
    return this.fb.group({
      medication_name: new FormControl(''),
      serial_no: new FormArray([this.serialCControlFn()]),
      scheduled_id: new FormControl(null),
      tank_id: new FormControl(
        JSON.parse(localStorage.getItem('tank') || '')?.id,
        [Validators.required]
      ),
    });
  }
  /*---------------------------------------------------------------------------------------------*/

  /**----------------------------------------edit day entry--------------------------------------*/

  /**
   * Populates the day entry form group for editing a day entry.
   */
  async editDayEntryForm(data: any) {
    const tank_id = JSON.parse(localStorage.getItem('tank') || '')?.id;
    // Create the day_entry_from_group form group
    if (this.birdtype == 'fish') {
      this.day_entry_from_group = this.fb.group({
        fish_type_name: new FormControl(this.birdtype),
        dated_age: new FormControl(this.current_entry_age, [
          Validators.required,
        ]),
        tank_id: new FormControl(tank_id, [Validators.required]),
        fish_opening_stock: new FormControl(data.fish_opening_stock || data.start_fish_count, [
          Validators.required,
        ]),

        fish_mortality_count: new FormControl(data.fish_mortality_count, [
          Validators.required,
        ]),

        fish_sold_count: new FormControl(data.fish_sold_count, [
          Validators.required,
        ]),

        fish_transfer_in_count: new FormControl(
          data.fish_transfer_in_count,
          [Validators.required]
        ),

        fish_transfer_out_count: new FormControl(
          data.fish_transfer_out_count,
          [Validators.required]
        ),

        fish_closing_stock: new FormControl(data.fish_closing_stock, [
          Validators.required,
        ]),

        labours_present_count: new FormControl(data.labours_present_count, [
          Validators.required,
        ]),
        feed_id: new FormControl(data.feed.feed_id, [Validators.required]),
        feed_per_fish: new FormControl(data.feed_per_fish, [
          Validators.required,
        ]),

        avg_f_weight: new FormControl(data.avg_f_weight),
        // avg_m_bird_weight: new FormControl(data.avg_m_bird_weight),

        // opening_stock_crs: new FormControl(data.opening_stock_crs, [
        //   Validators.required,
        // ]),
        // receipt_crs: new FormControl(data.receipt_crs, [Validators.required]),
        // delivery_crs: new FormControl(data.delivery_crs, [Validators.required]),
        // closing_stock_crs: new FormControl(data.closing_stock_crs, [
        //   Validators.required,
        // ]),
        remarks: new FormControl(data.remarks),
        equipmentlog: new FormArray([]),
        medicationlog: new FormArray([]),
      });
    } else if (this.birdtype == 'shrimp') {
      this.day_entry_from_group = this.fb.group({
        fish_type_name: new FormControl(this.birdtype),
        dated_age: new FormControl(this.current_entry_age, [
          Validators.required,
        ]),
        tank_id: new FormControl(tank_id, [Validators.required]),
        fish_opening_stock: new FormControl(data.fish_opening_stock || data.start_fish_count, [
          Validators.required,
        ]),
        fish_mortality_count: new FormControl(data.fish_mortality_count, [
          Validators.required,
        ]),
        fish_sold_count: new FormControl(data.fish_sold_count, [
          Validators.required,
        ]),
        fish_transfer_in_count: new FormControl(
          data.fish_transfer_in_count,
          [Validators.required]
        ),
        fish_transfer_out_count: new FormControl(
          data.fish_transfer_out_count,
          [Validators.required]
        ),
        fish_closing_stock: new FormControl(data.fish_closing_stock, [
          Validators.required,
        ]),
        labours_present_count: new FormControl(data.labours_present_count, [
          Validators.required,
        ]),
        feed_id: new FormControl(data.feed.feed_id, [Validators.required]),
        feed_per_fish: new FormControl(data.feed_per_fish, [
          Validators.required,
        ]),
        avg_f_weight: new FormControl(data.avg_f_weight),
        // avg_m_bird_weight: new FormControl(data.avg_m_bird_weight),

        // opening_stock_crs: new FormControl(data.opening_stock_crs, [
        //   Validators.required,
        // ]),
        // receipt_crs: new FormControl(data.receipt_crs, [Validators.required]),
        // delivery_crs: new FormControl(data.delivery_crs, [Validators.required]),
        // closing_stock_crs: new FormControl(data.closing_stock_crs, [
        //   Validators.required,
        // ]),
        remarks: new FormControl(data.remarks),
        equipmentlog: new FormArray([]),
        medicationlog: new FormArray([]),
      });
    } else {
      this.day_entry_from_group = this.fb.group({
        fish_type_name: new FormControl(this.birdtype),
        dated_age: new FormControl(this.current_entry_age, [
          Validators.required,
        ]),
        tank_id: new FormControl(tank_id, [Validators.required]),
        fish_opening_stock: new FormControl(data.fish_opening_stock || data.start_fish_count, [
          Validators.required,
        ]),
        fish_mortality_count: new FormControl(data.fish_mortality_count, [
          Validators.required,
        ]),
        fish_sold_count: new FormControl(data.fish_sold_count, [
          Validators.required,
        ]),
        fish_transfer_in_count: new FormControl(
          data.fish_transfer_in_count,
          [Validators.required]
        ),
        fish_transfer_out_count: new FormControl(
          data.fish_transfer_out_count,
          [Validators.required]
        ),
        fish_closing_stock: new FormControl(data.fish_closing_stock, [
          Validators.required,
        ]),
        labours_present_count: new FormControl(data.labours_present_count, [
          Validators.required,
        ]),
        feed_id: new FormControl(data.feed.feed_id, [Validators.required]),
        feed_per_fish: new FormControl(data.feed_per_fish, [
          Validators.required,
        ]),
        avg_f_weight: new FormControl(data.avg_f_weight),
        // avg_m_bird_weight: new FormControl(data.avg_m_bird_weight),

        opening_stock_crs: new FormControl(data.opening_stock_crs),
        receipt_crs: new FormControl(data.receipt_crs),
        delivery_crs: new FormControl(data.delivery_crs),
        closing_stock_crs: new FormControl(data.closing_stock_crs),
        remarks: new FormControl(data.remarks),
        equipmentlog: new FormArray([]),
        medicationlog: new FormArray([]),
      });
    }
    var i = 0;
    if (data.equipmentlogDetails?.length) {
      for await (const m of data.equipmentlogDetails) {
        const equipmentGroup = await this.equipmentGroupEdit(m, i);
        this.day_entry_from_group.get('equipmentlog').push(equipmentGroup);
        i++;
      }
    } else {
      this.day_entry_from_group
        .get('equipmentlog')
        .push(this.equipmentGroupFn());
    }
    // Process equipmentlogDetails
    var j = 0;
    if (data.medicationlogDetails?.length) {
      data.medicationlogDetails?.filter((m: any, index: any) => {
        this.schedules[index + 1] = this.source_schedules.filter((f: any) => {
          return f.scheduled_id !== m.schedule.scheduled_id;
        });

        this.day_entry_from_group
          .get('medicationlog')
          .push(this.medicationGroupEdit(m, j));
        var serialarray = m.serial_no.split(',');
        serialarray.filter((k: any) => {
          this.day_entry_from_group
            .get('medicationlog')
            .controls[j].get('serial_no')
            .push(new FormControl(k));
        });
        j++;
      });
    } else {
      // Add a default equipmentlog form group to the form array
      this.day_entry_from_group
        .get('medicationlog')
        .push(this.medicationGroupFn());
    }
    // Set the is_day_entry_table flag to false
    this.is_day_entry_table = false;
  }

  async equipmentGroupEdit(data: any, index: any) {
    return new Promise(async (resolve) => {
      await this.getEqptFailure(data.equipment.equipment_id, index);
      resolve(
        this.fb.group({
          equipment: new FormControl(data.equipment.equipment_id),
          failure: new FormControl(data.failure?.failure_id),
          reported_by: new FormControl(data.reported_by),
          status: new FormControl(data.status),
        })
      );
    });
  }

  medicationGroupEdit(data: any, index: any) {
    return this.fb.group({
      medication_name: new FormControl(data?.medication_name, [
        Validators.required,
      ]),
      serial_no: new FormArray([]),
      scheduled_id: new FormControl(data?.schedule?.scheduled_id),
      tank_id: new FormControl(
        JSON.parse(localStorage.getItem('tank') || '')?.id,
        [Validators.required]
      ),
    });
  }

  editDayEntry: any = undefined;

  async editvalue(data: any) {
    this.readonly = "true";
    this.start_date = data.dated_on;
    console.log("start_date", data)
    this.editDayEntry = data;
    const schedule_ids = this.editDayEntry.medicationlogDetails?.map(
      (m: any) => m.schedule.scheduled_id
    );
    if (schedule_ids.length) {
      await this.getMedicationSchedule(schedule_ids);
    } else {
      this.getMedicationSchedule();

    }

    this.editDayEntryForm(this.editDayEntry);
    this.dated_on.setValue(data.dated_on);
    this.getCurrentEntryAge();
  }
  /**-------------------------------------------------------------------------------------------------*/

  /*----------------------------------delete day entry--------------------------------------*/

  deleteDayEntry(data: any) {
    let message = `${moment(data.dated_on).format('DD MMM YYYY')} `
    this.translate.get("side_menu.day_entry").subscribe((translation) => {
      message += translation
    });
    const config: MatDialogConfig = {
      panelClass: 'dialog-responsive',
      width: '50%',
      minWidth: '50%',
      disableClose: true,
      data: {
        title: message,
        isDelete: true,
      },
    };
    const openDialog = this.dialog.open(MessageDialogComponent, config);
    openDialog.afterClosed().subscribe((result: any) => {
      if (result) {
        this.subscriptions.push(
          this.service.deleteDayEntry(data.dayentry_id).subscribe((res: any) => {
            this.getDayEntry();
            const schedule_ids: Array<number> = data.medicationlogDetails?.map((m: any) => m.schedule.scheduled_id);
            if (schedule_ids?.length)
              this.scheduleStatus(false, schedule_ids);
          })
        );
      }
    });
  }

  /*----------------------------------------------------------------------------------------------*/

  /**----------------------------------------get required datas from api------------------------------*/

  /**
  Retrieves the opening stock data from the previous day and sets the values in the day entry form.
  */
  getOpeingStock() {
    const prevDate = moment(this.dated_on.value)
      .subtract(1, 'days')
      .format('YYYY-MM-DD');
    this.subscriptions.push(
      this.service.getOpeingStock(prevDate).subscribe((res: any) => {
        if (res?.data?.length) {
          const fish_opening_stock =
            Number(res.data[0].fish_opening_stock) > 0
              ? Number(res.data[0].fish_opening_stock)
              : 0;

          const opening_stock_crs =
            Number(res.data[0].opening_stock_crs) > 0
              ? Number(res.data[0].opening_stock_crs)
              : 0;
          this.day_entry_from_group.controls['fish_opening_stock'].setValue(
            fish_opening_stock
          );
        }
      })
    );
  }

  /**

Retrieves the shed details from the service and stores them in the component's "shed_data" property.
*/

  getShedDetails() {
    this.subscriptions.push(
      this.service.getShedDetails().subscribe((res: any) => {
        if (res?.data?.length) {
          this.shed_data = res.data[0];
        }
      })
    );
  }

  tableList: any[] = [];
  /*------------------------------------Day entry table-------------------------------------------*/

  /**
  Retrieves the day-wise entry data from the service based on the current page index and page size,
  and updates the component's data source and pagination properties accordingly.
  */
  getDayEntry() {
    const page_data = {
      per_page: this.pageSize,
      current_page: this.pageIndex > 0 ? this.pageIndex + 1 : 1,
    };
    this.subscriptions.push(
      this.service.getDayWiseEntry(page_data).subscribe(
        (res: any) => {
          this.dataSource.data = [];
          this.dayEntryCheck();
          if (res?.data?.length) {
            this.dataSource.data = res.data;
            console.log("Day-entry-tableData", this.dataSource.data);
            this.length = res.total;
            this.tableList = res.data;
            this.is_day_entry_table = true;
          }
        },
        (error: Response) => { }
      )
    );
  }

  nextPage(e: PageEvent) {
    this.pageEvent = e;
    this.length = e.length;
    this.pageSize = e.pageSize;
    this.pageIndex = e.pageIndex;
    this.getDayEntry();
  }

  /*---------------------------------------------------------------------------------------------*/

  /*-------------------------------------All Get Apis------------------------------------------*/

  // to get all medication schedule
  getMedicationSchedule(included_ids?: Array<any>) {
    return new Promise((resolve, reject) => {
      this.subscriptions.push(
        this.batch_service
          .getMedicationSchedule(included_ids)
          .subscribe((res: any) => {
            if (res) {
              this.source_schedules = res.map((e: any) => {
                return {
                  scheduled_id: e.scheduled_id,
                  text: `${e.age_in_week}/${e.age_in_day}/${e.type}/${e.medication_name}/${e.remark_1}`,
                };
              });
              this.schedules[0] = this.source_schedules;
              resolve(true);
            }
          })
      );
    });
  }

  // Get All Feeds
  getAllFeeds() {
    this.subscriptions.push(
      this.service.getAllFeed().subscribe((res: any) => {
        res.data.sort((a: any, b: any) =>
          a.feed_name.localeCompare(b.feed_name)
        );
        this.feed_type = res.data;
      })
    );
  }

  // Get Equipments
  getAllEquipments() {
    this.subscriptions.push(
      this.service.getAllEquipments().subscribe((res: any) => {
        if (res?.data) {
          res.data.sort((a: any, b: any) =>
            a.equipment_name.localeCompare(b.equipment_name)
          );
          this.equipment_list = res.data;
        }
      })
    );
  }

  // Get Equipments Failure
  getEqptFailure(id: any, index: any) {
    return new Promise((resolve) => {
      const equipment_id = id;
      this.subscriptions.push(
        this.service.getEqptFailureById(equipment_id)?.subscribe((res: any) => {
          res.sort((a: any, b: any) =>
            a.failure_desc.localeCompare(b.failure_desc)
          );
          const failure_ids = this.day_entry_from_group
            .get('equipmentlog')
            .value.map((d: any) => {
              return d.failure;
            });
          res = res?.filter((f: any) => {
            return !failure_ids.includes(f?.failure_id);
          });
          this.equipment_failure[index] = res;
          resolve(true);
        })
      );
      this.equipementSelectionChange(index);
    });
  }

  /**-----------------------------------------------------------------------------------------------*/

  /*-------------------------------------to calculate Depletion count--------------------------------*/

  /**
  Calculates the closing stock of female and male birds based on the depletion inputs (mortality, sold, transfer) and updates the form controls accordingly.
  Displays an error message and resets the input if the calculated closing stock becomes negative.
  */
  depletionKeyUp(label: any) {
    let female_total = 0;
    let male_total = 0;

    const calculateFn = () => {
      const female_stock_total =
        Number(
          this.day_entry_from_group.controls['fish_mortality_count'].value
        ) +
        Number(this.day_entry_from_group.controls['fish_sold_count'].value) -
        Number(
          this.day_entry_from_group.controls['fish_transfer_in_count'].value
        ) +
        Number(
          this.day_entry_from_group.controls['fish_transfer_out_count'].value
        );



      female_total =
        Number(
          this.day_entry_from_group.controls['fish_opening_stock'].value
        ) - female_stock_total;


      this.day_entry_from_group
        .get('fish_closing_stock')
        .setValue(female_total);
    };
    calculateFn();
    switch (true) {
      case label == 'FM' && female_total < 0:
        this.show_msg.errorSnackBar('invalid_values');
        this.day_entry_from_group.controls['fish_mortality_count'].setValue(
          null
        );
        calculateFn();
        break;
      case label == 'FS' && female_total < 0:
        this.show_msg.errorSnackBar('invalid_values');
        this.day_entry_from_group.controls['fish_sold_count'].setValue(null);
        calculateFn();
        break;
      case label == 'FT' && female_total < 0:
        this.show_msg.errorSnackBar('invalid_values');
        this.day_entry_from_group.controls[
          'fish_transfer_out_count'
        ].setValue(null);
        calculateFn();
        break;
      case label == 'MM' && male_total < 0:
        calculateFn();
        this.show_msg.errorSnackBar('invalid_values');
        this.day_entry_from_group.controls['male_mortality_count'].setValue(
          null
        );
        break;
      case label == 'MS' && male_total < 0:
        this.show_msg.errorSnackBar('invalid_values');
        this.day_entry_from_group.controls['male_sold_count'].setValue(null);
        calculateFn();
        break;
      case label == 'MT' && male_total < 0:
        this.show_msg.errorSnackBar('invalid_values');
        this.day_entry_from_group.controls['male_transfer_out_count'].setValue(
          null
        );
        calculateFn();
        break;

      default:
        calculateFn();
        break;
    }
  }

  /*------------------------------------------------------------------------------------------------*/

  /**--------------------------check whether the day entry alreay exist or not ------------------*/
  /**

Determines the appropriate "dated on" value for the current day entry based on the last day entry record.
Sets the next date if there is a previous day entry, or sets the start date of the batch if there is no previous entry.
Sets the opening stock values from the active batch data.
 A promise resolving to a boolean indicating if the day entry is for the current date.
*/
  dayEntryCheck() {
    return new Promise((resolve, reject) => {
      this.service.getlastdayentryRecord().subscribe((res: any) => {
        if (res?.data) {
          const current_date = new Date(moment().format('YYYY-MM-DD'));
          this.last_entry_date = new Date(
            moment(res.data.dated_on).format('YYYY-MM-DD')
          );
          this.start_date = new Date(
            moment(res.data.dated_on).add(1, "days").format('YYYY-MM-DD'));
          // If the entry date is less than the current date, set the next date in the "dated on" field
          if (this.last_entry_date < current_date) {
            const entry_date: any = moment(res.data.dated_on)
              .add(1, 'days')
              .format('YYYY-MM-DD');
            this.dated_on.setValue(entry_date);
            resolve(false);
          } else {
            this.dated_on.setValue(current_date);
            resolve(true);
          }
        } else {
          // if no day entry data set batch start value as opening stock value
          this.day_entry_from_group.controls['fish_opening_stock'].setValue(
            this.active_batch_data.start_fish_count
          );

          const batch_start_date: any = moment(
            this.active_batch_data.start_date
          ).format('YYYY-MM-DD');
          this.dated_on.setValue(batch_start_date);
          this.start_date = batch_start_date;
          resolve(false);
        }
      });
    });
  }

  /**-------------------------------------------------------------------------------------------*/

  /**----------------------------------------submit day entry-----------------------------------*/

  /**
Saves the day entry form data.
Checks if the form is invalid and shows appropriate error messages.
Removes empty objects from equipmentlog and medicationlog arrays.
Submits the day entry data to the server.
Updates the day entry table and resets the form after successful submission.
*/
  async saveDayEntry() {
    // to check the form is invalid or not
    if (this.day_entry_from_group.invalid) {
      const already_shown = this.show_msg.showFromGroupWarning(
        this.day_entry_from_group
      );
      let e_index = 1;
      let m_index = 1;

      if (!already_shown) {
        this.day_entry_from_group
          .get('medicationlog')
          .controls.forEach((form_group: FormGroup, i: any) => {
            Object.entries(form_group.controls).forEach(([key, value]) => {
              if (value.invalid && !value.value?.length && m_index) {
                m_index = 0;
                this.show_msg.errorSnackBar(`schedule_name`);
              }
            });
          });
      }

      if (!already_shown && m_index) {
        this.day_entry_from_group
          .get('equipmentlog')
          .controls.forEach((form_group: FormGroup, i: any) => {
            Object.entries(form_group.controls).forEach(([key, value]) => {
              if (value.invalid && !value.value?.length && m_index) {
                e_index = 0;
                this.show_msg.errorSnackBar(`equipment_failure`);
              }
            });
          });
      }

      return;
    }
    this.disable_submit = true;
    // remove empty object from equipmentlog
    this.day_entry_from_group.value.equipmentlog =
      this.day_entry_from_group.value.equipmentlog?.filter((val: any) => {
        return !!val.equipment;
      });
    this.day_entry_from_group.value.medicationlog =
      this.day_entry_from_group.value.medicationlog?.filter((val: any) => {
        return !!val.scheduled_id;
      });
    if (!!!this.day_entry_from_group.value.equipmentlog?.length) {
      delete this.day_entry_from_group.value.equipmentlog;
    }
    if (!!!this.day_entry_from_group.value.medicationlog?.length) {
      delete this.day_entry_from_group.value.medicationlog;
    }
    this.day_entry_from_group.value.medicationlog?.map((val: any) => {
      val.serial_no = val.serial_no.toString();
    });
    const data = {
      ...this.day_entry_from_group.value,
      batch_id: this.active_batch_data?.batch_id,
      dated_on: moment(this.dated_on.value).format('YYYY-MM-DD'),
    };
    this.subscriptions.push(
      this.service.submitDayEntry(data).subscribe((res: any) => {
        this.disable_submit = false;
        // this.is_day_entry_table = true;
        // this.initForm();
        // setTimeout(() => {
        //   this.getDayEntry();
        // });
        this.cancelDayEntry();
      }, (error) => {
        this.disable_submit = false;
      })
    );
  }

  /**----------------------------------------------------------------------------------------------------*/

  /**----------------------------------------update day entry---------------------------------------------*/

  async updateDayEntry() {
    if (this.day_entry_from_group.invalid) {
      const already_shown = this.show_msg.showFromGroupWarning(
        this.day_entry_from_group
      );
      let e_index = 1;
      let m_index = 1;
      if (!already_shown) {
        this.day_entry_from_group
          .get('medicationlog')
          .controls.forEach((form_group: FormGroup, i: any) => {
            Object.entries(form_group.controls).forEach(([key, value]) => {
              if (value.invalid && !value.value?.length && m_index) {
                m_index = 0;
                this.show_msg.errorSnackBar(`schedule_name`);
              }
            });
          });
      }

      if (!already_shown && m_index) {
        this.day_entry_from_group
          .get('equipmentlog')
          .controls.forEach((form_group: FormGroup, i: any) => {
            Object.entries(form_group.controls).forEach(([key, value]) => {
              if (value.invalid && !value.value?.length && m_index) {
                e_index = 0;
                this.show_msg.errorSnackBar(`equipment_failure`);
              }
            });
          });
      }
      return;
    }

    this.disable_submit = true;
    this.day_entry_from_group.value.equipmentlog =
      this.day_entry_from_group.value.equipmentlog?.filter((val: any) => {
        return !!val.equipment;
      });
    this.day_entry_from_group.value.medicationlog =
      this.day_entry_from_group.value.medicationlog?.filter((val: any) => {
        return !!val.scheduled_id;
      });
    if (!!!this.day_entry_from_group.value.equipmentlog?.length) {
      delete this.day_entry_from_group.value.equipmentlog;
    }
    if (!!!this.day_entry_from_group.value.medicationlog?.length) {
      delete this.day_entry_from_group.value.medicationlog;
    }
    this.day_entry_from_group.value.medicationlog?.map((val: any) => {
      val.serial_no = val.serial_no.toString();
    });
    const data = {
      ...this.day_entry_from_group.value,
      batch_id: this.active_batch_data?.batch_id,
      dated_on: moment(this.dated_on.value).format('YYYY-MM-DD'),
    };
    const schedule_ids = this.editDayEntry.medicationlogDetails?.map(
      (m: any) => m.schedule.scheduled_id
    );
    if (schedule_ids?.length) {
      await this.scheduleStatus(false, schedule_ids);
    }

    this.subscriptions.push(
      this.service.updateDayEntry(data, this.editDayEntry.dayentry_id).subscribe((res: any) => {
        this.disable_submit = false;
        // this.is_day_entry_table = true;
        // this.initForm();
        // this.getDayEntry();
        // this.editDayEntry = undefined;
        this.cancelDayEntry();
      }, (error) => {
        this.disable_submit = false;
      })
    );
  }

  /**----------------------------------------------------------------------------------------------------*/

  /**----------------------------------------add new dayentry---------------------------------------------*/
  /**
  
  Creates a new day entry if the conditions are met: an active batch is available, 
  and a day entry has not already been submitted for the current date.
  If the day entry is for the current date, it proceeds with 
  creating the day entry by retrieving the opening stock and current entry age.
  Otherwise, it updates the "dated on" field and displays an error message.
  */
  async createDayEntry() {
    this.readonly = false;
    const batch_start = moment(this.dated_on.value)
      .format('YYYY-MM-DD');
    console.log("prevDate", batch_start);
    this.min_date = batch_start;
    const active_batch_id = !!localStorage.getItem('batch_id');
    if (!active_batch_id) {
      this.show_msg.errorSnackBar('start_batch_info');
      return;
    }
    if (
      moment(this.active_batch_data.start_date).format('YYYY-MM-DD') <
      moment(new Date()).format('YYYY-MM-DD')
    ) {
    }
    // to check day entry alreay submitted or not
    const is_entry_already_submitted = this.dataSource.data.some((d: any) => {
      return (
        moment(d.dated_on).format('YYYY-MM-DD') ==
        moment(this.dated_on.value).format('YYYY-MM-DD')
      );
    });

    if (is_entry_already_submitted) {
      this.show_msg.errorSnackBar(`dayentry_already_exists`);
      return;
    } else {
      const is_alredy_submmited = await this.dayEntryCheck();
      if (is_alredy_submmited) {
        this.show_msg.errorSnackBar(`dayentry_already_exists`);
        return;
      }
    }
    this.is_day_entry_table = false;
    this.getOpeingStock();
    this.getCurrentEntryAge();
    this.getMedicationSchedule();
  }

  /**----------------------------------------------------------------------------------------------------*/

  // to calculate egg total
  // onEggKeyUp() {
  //   const egg_total =
  //     Number(this.day_entry_from_group.controls['hatching_egg_count'].value) +
  //     Number(this.day_entry_from_group.controls['table_egg_count'].value) +
  //     Number(this.day_entry_from_group.controls['jumbo_egg_count'].value) +
  //     Number(this.day_entry_from_group.controls['crack_egg_count'].value) +
  //     Number(this.day_entry_from_group.controls['waste_egg_count'].value);

  //   this.day_entry_from_group.controls['total_egg_count'].setValue(egg_total);
  // }

  // to calculate closing stock total
  onCoolRoomKeyUp() {
    let close_stock_total = 0;
    const calculateFn = () => {
      close_stock_total =
        Number(this.day_entry_from_group.controls['opening_stock_crs'].value) +
        Number(this.day_entry_from_group.controls['receipt_crs'].value) -
        Number(this.day_entry_from_group.controls['delivery_crs'].value);
      this.day_entry_from_group.controls['closing_stock_crs'].setValue(
        close_stock_total
      );
    };
    calculateFn();
    if (close_stock_total < 0) {
      this.show_msg.errorSnackBar('invalid_values');
      this.day_entry_from_group.controls['delivery_crs'].setValue(null);
      calculateFn();
    }
  }

  // The labor count should be less than the count entered by the user on the shed creation page
  labourKeyPress(event: any) {
    setTimeout(() => {
      const count = this.day_entry_from_group.get(
        'labours_present_count'
      )?.value;
      if (Number(count) > this.shed_data?.labour_count) {
        this.day_entry_from_group.get('labours_present_count').reset();
        this.show_msg.errorSnackBar('Invalid Labour Count');
      }
    });
  }

  // when medication selected make medication name field as required
  medicationSelectionChange(index: any) {
    const is_selected = this.day_entry_from_group.get('medicationlog')?.controls[index]?.get('scheduled_id')?.value;
    const already_selected = this.day_entry_from_group.get('medicationlog').value.find((s: any, i: number) => s.scheduled_id == is_selected && i != index);
    if (!!already_selected) {
      this.day_entry_from_group.get('medicationlog')?.controls[index]?.get('scheduled_id')?.reset();
      this.show_msg.errorSnackBar('option_already_selected');
      return;
    }

    if (!!is_selected) {
      this.day_entry_from_group
        .get('medicationlog')
        ?.controls[index]?.get('medication_name')
        ?.reset();
      this.day_entry_from_group
        .get('medicationlog')
        ?.controls[index]?.get('medication_name')
        ?.setValidators([Validators.required]);
      this.day_entry_from_group
        .get('medicationlog')
        ?.controls[index]?.get('medication_name')
        ?.updateValueAndValidity();
    } else {
      this.day_entry_from_group
        .get('medicationlog')
        ?.controls[index]?.get('medication_name')
        ?.reset();
      this.day_entry_from_group
        .get('medicationlog')
        ?.controls[index]?.get('medication_name')
        ?.clearValidators();
      this.day_entry_from_group
        .get('medicationlog')
        ?.controls[index]?.get('medication_name')
        ?.updateValueAndValidity();
    }

    this.day_entry_from_group
      .get('medicationlog')
      ?.controls[index]?.get('serial_no')
      ?.clear();
    this.day_entry_from_group
      .get('medicationlog')
      ?.controls[index]?.get('serial_no')
      ?.push(this.serialCControlFn());
  }

  cancelDayEntry() {
    this.is_day_entry_table = true;
    this.initForm();
    this.editDayEntry = undefined;
    this.getDayEntry();
    this.schedules = [];
    this.source_schedules = [];
  }

  async openViewOnly(data: any) {
    this.readonly = true;
    this.view_only = true;
    this.start_date = data.dated_on;
    this.is_day_entry_table = false;
    const schedule_ids = data.medicationlogDetails?.map((m: any) => m.schedule.scheduled_id);
    if (schedule_ids.length) {
      await this.getMedicationSchedule(schedule_ids);
    }
    this.editDayEntryForm(data);
    this.dated_on.setValue(data.dated_on);
    this.getCurrentEntryAge();
  }

  dayEntryback() {
    this.is_day_entry_table = true;
    this.view_only = false;
    this.initForm();
    this.getDayEntry();
  }

  scheduleStatus(status: boolean, scheduled_id: Array<number>) {
    return new Promise((resolve, reject) => {
      const req_data = {
        is_taken: status,
        scheduled_id,
      };
      this.subscriptions.push(
        this.batch_service
          .medicationScheduleStatus(req_data)
          .subscribe(() => resolve(true))
      );
    });
  }
  clearScheduleSelection(i: any) {
    // this.scheduleStatus(false, [this.day_entry_from_group.get('medicationlog').controls[i].get('scheduled_id').value]);
    this.day_entry_from_group
      .get('medicationlog')
      .controls[i].get('scheduled_id')
      .setValue(null);
    this.medicationSelectionChange(i);
  }
  clearEquipmentSelection(i: any) {
    this.day_entry_from_group
      .get('equipmentlog')
      .controls[i].get('equipment')
      .setValue(null);
    this.day_entry_from_group
      .get('equipmentlog')
      .controls[i].get('failure')
      .setValue(null);
    this.equipment_failure[i] = [];
    this.equipementSelectionChange(i);
  }

  clearFaultSelection(i: any) {
    this.day_entry_from_group
      .get('equipmentlog')
      .controls[i].get('failure')
      .setValue(null);
  }

  submitDayEntry() {
    if (this.editDayEntry != undefined) this.updateDayEntry();
    else this.saveDayEntry();
  }

  addEquipment() {
    this.day_entry_from_group.get('equipmentlog').push(this.equipmentGroupFn());
    var div: any = document.getElementById('day_entry_equipment');
    setTimeout(() => {
      div.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest',
      });
    }, 150);
  }

  addMedication() {
    this.day_entry_from_group
      .get('medicationlog')
      .push(this.medicationGroupFn());
    const schedule_ids = this.day_entry_from_group
      .get('medicationlog')
      .value.map((s: any) => s.scheduled_id);
    this.schedules[schedule_ids.length - 1] = this.source_schedules.filter(
      (s: any) => !schedule_ids.includes(s.scheduled_id)
    );
    var div: any = document.getElementById('day_entry_medication');
    setTimeout(() => {
      div.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
        inline: 'nearest',
      });
    }, 150);
  }

  addSerialNo(index: any) {
    this.day_entry_from_group
      .get('medicationlog')
      .controls[index].get('serial_no')
      .push(this.serialCControlFn());
  }

  removeEquipment(index: any) {
    this.day_entry_from_group.get('equipmentlog').removeAt(index);
    this.equipment_failure?.splice(index, 1);
  }

  removeMedication(index: any) {
    this.day_entry_from_group.get('medicationlog').removeAt(index);
  }

  removeSerialNo(index: any, vIndex: any) {
    this.day_entry_from_group
      .get('medicationlog')
      .controls[index].get('serial_no')
      .removeAt(vIndex);
  }

  // make equipment seleted make failure as required
  equipementSelectionChange(index: any) {
    const is_selected = this.day_entry_from_group
      .get('equipmentlog')
      ?.controls[index]?.get('equipment')?.value;
    if (!!is_selected) {
      this.day_entry_from_group
        .get('equipmentlog')
        ?.controls[index]?.get('failure')
        ?.reset();
      this.day_entry_from_group
        .get('equipmentlog')
        ?.controls[index]?.get('failure')
        ?.setValidators([Validators.required]);
      this.day_entry_from_group
        .get('equipmentlog')
        ?.controls[index]?.get('failure')
        ?.updateValueAndValidity();
    } else {
      this.day_entry_from_group
        .get('equipmentlog')
        ?.controls[index]?.get('failure')
        ?.reset();
      this.day_entry_from_group
        .get('equipmentlog')
        ?.controls[index]?.get('failure')
        ?.clearValidators();
      this.day_entry_from_group
        .get('equipmentlog')
        ?.controls[index]?.get('failure')
        ?.updateValueAndValidity();
    }
  }

  setFailureData(data: any, index: any) {
    this.day_entry_from_group.get('equipmentlog').value[index]?.reported_by ==
      'Arun';
  }

  /**----------------------------------------------------------------------------------------------------*/

  /**Calculates the current entry age based on the start date, current date, 
   * and initial age of the batch.
Updates the "dated age" field in the day entry form with the calculated age.
*/
  getCurrentEntryAge() {
    const data = {
      start_date: this.active_batch_data.start_date,
      current_date: this.start_date,
      age_weeks: this.active_batch_data.start_age_week,
      age_days: this.active_batch_data.start_age_days,
    };

    this.current_entry_age = this.batch_service.batchAgeCalculate(data);
    this.day_entry_from_group.controls['dated_age'].setValue(
      this.current_entry_age
    );
  }

  isEnableEdit(date: any) {
    const edit_date = moment(this.last_entry_date).subtract(6, 'days').format('YYYY-MM-DD');
    if (
      moment(date).format('YYYY-MM-DD') >= edit_date
    ) {
      return true;
    }
    return false;
  }
  /**----------------------------------------------------------------------------------------------------*/

  isEnableDelete(date: Date) {
    if (moment(date).format('YYYY-MM-DD') ===
      moment(this.last_entry_date).format('YYYY-MM-DD')) {
      return true;
    }
    return false;
  }

  DateChange(event: any) {

    this.max_date = new Date();
    this.start_date = new Date(event.value);
    this.dated_on.setValue(this.start_date);
    console.log(new Date(event.value));
    console.log("event", event.target.value)

    this.getCurrentEntryAge();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub: Subscription) => sub.unsubscribe());
  }
}
