<div fxLayoutAlign="space-between center">
    <div style="font-size: 18px;"><b>{{'alert_settings.alert_settings'|translate|titlecase}}</b></div>
    <mat-icon (click)="dialogRef.close()">close</mat-icon>
</div>
<div class="alert-container">

    <form class="m-t-20" fxLayoutGap="15" fxLayout="column" [formGroup]="alert_form">
        <div fxLayout="row" fxLayoutAlign="space-between center">
            <span class="field">{{'alert_settings.company_wide_alerts'|translate|titlecase}}</span>
            <mat-slide-toggle (change)="companyWideChange();showCompanyWideAlertStatus()"
                formControlName="company_wide_alert" color="primary"></mat-slide-toggle>
        </div>

        <div>
            <div *ngIf="company_chip_list.length" fxLayout="row warp" class="chip-container">
                <ng-container *ngFor="let chip of company_chip_list;let i=index;trackBy: trackByFn">
                    <mat-chip-list>
                        <mat-chip [id]="'chip_scroll_down'+i" class="m-5">{{chip.company_name}}
                            <button matChipRemove (click)="removeChipCompany(chip,i)">
                                <mat-icon>close</mat-icon>
                            </button>
                        </mat-chip>
                    </mat-chip-list>
                </ng-container>
            </div>

            <div>
                <div>
                    <mat-form-field appearance="standard">
                        <!-- <mat-label>Company</mat-label> -->
                        <input type="text" [formControl]="company_control" matInput [matAutocomplete]="companyAuto"
                            [placeholder]="'alert_settings.add_company'|translate|titlecase">
                    </mat-form-field>

                    <mat-autocomplete #companyAuto="matAutocomplete">
                        <ng-container *ngFor="let company of companyObservable |async">
                            <mat-option (click)="companySelectionChange(company)">
                                {{company.company_name|titlecase}}
                            </mat-option>
                        </ng-container>


                    </mat-autocomplete>
                </div>

                <mat-form-field appearance="outline">
                    <mat-label>{{'alert_settings.alert_level'|translate|titlecase}}</mat-label>
                    <mat-select (focusin)="focusInAlerts()" disableOptionCentering formControlName="alert_level"
                        panelClass="PanelClass">
                        <ng-container *ngFor="let alert of alert_levels">
                            <mat-option [value]="alert.id">{{alert.name |titlecase}}</mat-option>
                        </ng-container>
                    </mat-select>
                </mat-form-field>


            </div>

        </div>


        <div fxLayout="row" fxLayoutAlign="space-between center">
            <span class="field">{{'alert_settings.email_alert'|translate|titlecase}}</span>
            <mat-slide-toggle (click)="focusInAlerts()" formControlName="email_alert"
                color="primary"></mat-slide-toggle>
        </div>

        <div fxLayout="row" fxLayoutAlign="space-between center">
            <span class="field">{{'alert_settings.ring_alert'|translate|titlecase}}</span>
            <mat-slide-toggle (click)="focusInAlerts()" formControlName="ring_alert" color="primary"></mat-slide-toggle>
        </div>

        <div fxLayout="row" fxLayoutAlign="space-between center">
            <div>
                <span class="field">{{'alert_settings.sms_alert'|translate|titlecase}}</span>
            </div>
            <mat-slide-toggle (click)="focusInAlerts()" formControlName="sms_alert" color="primary"></mat-slide-toggle>
        </div>

        <div fxLayout="row" fxLayoutAlign="space-between center">
            <div>
                <span class="field">{{'alert_settings.whatsapp_alert'|translate|titlecase}}</span>
            </div>
            <mat-slide-toggle (click)="focusInAlerts()" formControlName="whatsapp_alert"
                color="primary"></mat-slide-toggle>
        </div>

    </form>

    <div class="m-t-20" fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="20" fxLayoutAlign="center center">
        <button class="submit grow" mat-stroked-button
            (click)="submit()">{{'button.submit'|translate|titlecase}}</button>
        <button class="cancel grow" mat-stroked-button
            (click)="dialogRef.close()">{{'button.cancel'|translate|titlecase}}</button>
    </div>
</div>