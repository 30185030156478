import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from 'src/shared/material/material.module';
import { SideBarComponent } from './side-bar/side-bar.component';
import { TopBarComponent } from './top-bar/top-bar.component';
import { FullLayoutComponent } from './full-layout/full-layout.component';
import { SharedModule } from 'src/shared/shared.module';
import { RouterModule } from '@angular/router';
import { CommonTopBarComponent } from './common-top-bar/common-top-bar.component';
import { CommonMessageComponent } from './common-message/common-message.component';
import { TranslateModule } from '@ngx-translate/core';



@NgModule({
    declarations: [
        TopBarComponent,
        SideBarComponent,
        FullLayoutComponent,
        CommonTopBarComponent,
        CommonMessageComponent

    ],
    imports: [
        CommonModule,
        TranslateModule.forChild(),
        MaterialModule,
        SharedModule,
        RouterModule
    ],
    exports: [
        TopBarComponent,
        CommonTopBarComponent,
        CommonMessageComponent
    ]
})
export class FullLayoutModule { }
