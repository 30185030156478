import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { DayEntryService } from 'src/app/core/services/day-entry/day-entry.service';
import { MessageService } from 'src/app/core/services/message/message.service';
import { ScoreService } from 'src/app/pages/settings/score.service';

@Component({
  selector: 'app-recalculate-dialog',
  templateUrl: './recalculate-dialog.component.html',
  styleUrls: ['./recalculate-dialog.component.scss']
})
export class RecalculateDialogComponent implements OnInit, OnDestroy {
  public subscriptions: Subscription[] = [];
  public from_date!: string;
  public to_date!: string;
  public recalculate_form!: FormGroup;
  constructor (
    @Inject(MAT_DIALOG_DATA) public dialogData: any,
    public dialogRef: MatDialogRef<RecalculateDialogComponent>,
    private service: DayEntryService,
    private recalculateService: ScoreService,
    private msg: MessageService,
    private fb: FormBuilder
  ) {
  }

  ngOnInit (): void {
    this.recalculate_form = this.fb.group({
      recal_type: new FormControl(this.dialogData.is_growth_score ? 2 : null, [Validators.required]),
    });

    // this.subscriptions.push(
    //   this.service.getlastdayentryRecord().subscribe((res: any) => {
    //     const last_entry_date = moment(res.data.dated_on).format('YYYY-MM-DD');
    //     this.to_date = last_entry_date;
    //     // Assuming today is the current date
    //     const today = moment();
    //     // Find the day number of the current day (0: Sunday, 1: Monday, ..., 6: Saturday)
    //     const currentDayNumber = today.day();
    //     // Calculate the number of days to subtract to get to the first day of the last week (assuming Monday is the first day of the week)
    //     const daysToSubtract = currentDayNumber + 8;
    //     // Subtract the days from today to get the first day of the last week
    //     const lastWeekSaturday = today.subtract(daysToSubtract, 'days').format('YYYY-MM-DD');
    //     this.from_date = lastWeekSaturday;
    //   })
    // );

  }
  calculate () {
    if (this.recalculate_form.invalid) {
      this.msg.errorSnackBar('choose_option');
      return;

    }
    let reqObj: Record<string, string | number> = {};
    const { recal_type } = this.recalculate_form.value;
    if (recal_type == 1) {
      const today = moment();
      reqObj['from_date'] = today.clone().startOf('week').format('YYYY-MM-DD');
      reqObj['to_date'] = today.format('YYYY-MM-DD');
    }
    // const currentDayNumber = today.day();
    // const daysToSubtract = currentDayNumber + 7;
    // const lastWeekSaturday = today.subtract(daysToSubtract, 'days').format('YYYY-MM-DD');
    // this.from_date = lastWeekSaturday;
    // var growthtodate = moment().subtract(currentDayNumber + 1, 'days').format('YYYY-MM-DD');
    switch (true) {
      case this.dialogData.is_growth_score:
        this.subscriptions.push(
          this.recalculateService.recalculateGrowth(reqObj).subscribe((res: any) => {
            this.dialogRef.close("success");

          })
        );
        break;
      case this.dialogData.is_productivity_score:
        this.subscriptions.push(
          this.recalculateService.recalculateProductivity(reqObj).subscribe((res: any) => {
            this.dialogRef.close("success");

          })
        );
        break;
      case this.dialogData.is_mortality_score:
        this.subscriptions.push(
          this.recalculateService.recalculateMortality(reqObj).subscribe((res: any) => {
            this.dialogRef.close("success");
          })
        );
        break;

      default:
        break;
    }

    // this.dialogRef.close();

  }

  ngOnDestroy (): void {
    this.subscriptions.forEach((sub: Subscription) => sub.unsubscribe());
  }
}
