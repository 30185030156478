<mat-sidenav-container>
    <mat-sidenav class="mat-sidenav" #sidenav="matSidenav"
        [ngStyle]="isExpand ? {'width': side_bar_width}:{'width': '4.2rem'}">



        <div style="height: 90%;overflow-x: hidden;overflow-y: auto;">
            <div class="profile-logout" style="text-align: left;padding: 5px;">
                <div>
                    <mat-icon>account_circle</mat-icon>
                </div>
                <div style="font-weight:700">{{user_data.username}}</div>
                <div style="color: #919191;">{{user_data.user_role ==1 ? 'Super admin'
                    :user_data.user_role ==2 ? 'Management'
                    :user_data.user_role ==3 ? 'General manager'
                    :user_data.user_role ==4 ? 'Farm manager'
                    :user_data.user_role ==5 ? 'Shed supervisor'
                    :''}}</div>
                <div fxLayout="column" style="font-size: 11px;color: #919191;">
                    <span>{{shed?.name}}</span>
                    <span>{{company_name}}</span>
                </div>
            </div>
            <ng-container *ngFor=" let item of sideMenuItems;let i=index">
                <div class="main-menu-container">
                    <a (click)="openSubMenu(i,item)" mat-button [class.isActive]="isActive[i]" class="menu-button">
                        <div fxLayoutAlign="start center" fxLayoutGap="15">
                            <div fxFlex="18" style="text-align: left;" fxLayoutAlign="start center">
                                <img style="height: 2.2rem;width: 2.2rem;padding-left: 5px;"
                                [src]="(item.url === 'batch' && birdtype === 'fish') ? (isActive[i] ? item.icon + '_white.svg' : item.icon + '.svg') : (item.url === 'batch' && birdtype === 'shrimp' ? (isActive[i] ? item.icon1 + '_white.svg' : item.icon1 + '.svg') : (item.url !== 'batch' ? (isActive[i] ? item.icon + '_white.svg' : item.icon + '.svg') : ''))"

                                    alt="">
                                <!-- <mat-icon [svgIcon]="item.icon"></mat-icon> -->
                            </div>

                            <div *ngIf="isExpand" fxFlex="80" style="text-align: left;">
                                <div fxFlex="85">
                                    <span [matTooltip]=" item.label.length > 25 ? item.label : ''"
                                        matTooltipPosition="above"
                                        matTooltipHideDelay="10">{{item.label|translate|titlecase}}</span>
                                </div>
                                <div>
                                    <img *ngIf="item.subMenu" class="down-arrow"
                                        [src]=" isOpen[i] ? '../../../../assets/images/down-arrow-white.png' :'../../../../assets/images/down-arrow.svg' "
                                        alt="">
                                </div>
                            </div>

                        </div>


                    </a>
                </div>

                <!-- <mat-divider></mat-divider> -->
                <!-- SUB MENU -->
                <div>
                    <div class="sub-menu-container"
                        [ngStyle]="isOpen[i] ? {'padding': '5px','margin-bottom':'5px'}:{'padding': '0px','margin-bottom':'0px'}">
                        <ng-container *ngFor=" let subMenu of item.subMenuItems ? item.subMenuItems : null;let s=index">
                            <div [ngClass]="isOpen[i] ? 'submenu' : 'sub-menu-close'">
                                <a [disabled]="subMenu.disable"
                                    [ngStyle]="subMenu.disable ? {'pointer-events':'none'}:{'pointer-events':'initial'}"
                                    mat-button [routerLink]="subMenu.url ? subMenu.url : ''"
                                    [routerLinkActive]="['isSubActive']" class="sub-menu-button ellipsis">
                                    <!-- <mat-icon class="sub-menu-icon">emergency</mat-icon> -->
                                    <span>{{subMenu.label |translate|titlecase}}</span>
                                </a>
                            </div>
                        </ng-container>
                    </div>
                </div>

            </ng-container>
        </div>


        <div class="bottom-container">
            <div *ngIf="is_hide_collapse_icon" class="collapse-icon" fxLayoutAlign="end end"
                style="padding-bottom: 7px;padding-top: 7px; display: initial;">
                <img (click)="expandCollapse()"
                    [ngStyle]="isExpand ? {'transform': 'scaleX(1)'}:{'transform': 'scaleX(-1)'}" class="expand-icon"
                    src="assets/side-bar/expand_close_icon.png" alt="">
            </div>
            <div>

                AQUACULTURE
            </div>
            <div class="profile-logout">
                <button mat-flat-button style="background-color: #E4F1E4;width: 100%;"><span style="color: red;"
                        (click)="logout()">{{'log_out'|translate|titlecase}}</span></button>
            </div>

        </div>

    </mat-sidenav>

    <mat-sidenav-content [ngStyle]=" this.side_bar_width =='auto' 
        ? {'margin-left': '0px'} 
        :isExpand 
        ? {'margin-left': '15%'}:{'margin-left': '70px'}" class="content scrollnull">
        <router-outlet></router-outlet>
    </mat-sidenav-content>
</mat-sidenav-container>