<div class="bg-img">
    <form [formGroup]="restFromGroup">
        <mat-card style="overflow:auto;" fxLayout="column" fxFlex.sm="50" fxFlex.gt-sm="50" fxFlex.lt-sm="80"
            class="card-container mat-elevation-z8">
            <img height="20%" src="../../../assets/images/reset_your_password.svg" alt="">
            <span class="label m-t-10">{{'reset_password.reset_your_password'|translate|titlecase}}</span>
            <span class="m-t-10" style="color: #A4A4A4;">{{'reset_password.verified_msg1'|translate|titlecase}}<br>
                {{'reset_password.verified_msg2'|translate|titlecase}}</span>
            <div style="margin-top: 6%;" fxLayout="column" fxLayoutGap="10">

                <div>
                    <mat-form-field appearance="outline">
                        <input (ngModelChange)="hidePassword('password');passwordMatchValidator()"
                            [type]="hide ? 'password' : 'text'" matInput
                            [placeholder]="'reset_password.new_password'|translate|titlecase" formControlName="password"
                            autocomplete="off" />
                        <mat-icon style="color: #BCBCBC;" matSuffix (click)="showHidePassword('password')"
                            [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">{{hide ?
                            'visibility_off' : 'visibility'}}</mat-icon>
                        <mat-error>
                            <span *ngIf="restFromGroup.controls['password'].touched 
                                          && restFromGroup.controls['password'].hasError('pattern')">
                                {{'reset_password.new_password_invalid_msg'|translate|titlecase}}
                            </span>
                            <span *ngIf="restFromGroup.controls['password'].hasError('required') 
                                         && restFromGroup.controls['password'].touched">
                                {{'reset_password.new_password_msg'|translate|titlecase}}
                            </span>
                        </mat-error>

                    </mat-form-field>

                </div>


                <div>
                    <mat-form-field appearance="outline">
                        <input (focusin)="onFocusConfirmPassword()"
                            [readonly]="restFromGroup.controls['password'].value?.length <8"
                            (ngModelChange)="hidePassword('comfirm_password');passwordMatchValidator();"
                            [type]="hide1 ? 'password' : 'text'" matInput
                            [placeholder]="'Confirm Password'|translate|titlecase" formControlName="comfirm_password"
                            autocomplete="new-password" />
                        <mat-icon style="color: #BCBCBC;" matSuffix (click)="showHidePassword('comfirm_password')"
                            [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide1">{{hide1 ?
                            'visibility_off' : 'visibility'}}</mat-icon>

                        <mat-error>
                            <!-- <span
                                *ngIf="restFromGroup.controls['comfirm_password'].touched  && restFromGroup.get('comfirm_password')?.hasError('pattern')">
                                Password length should be minimum of 8 character , must include one number,one upper
                                case,one special character</span> -->
                            <span
                                *ngIf="!!this.restFromGroup.controls['password'].value?.length && restFromGroup.controls['comfirm_password'].touched  && restFromGroup.get('comfirm_password')?.hasError('required')">
                                {{'reset_password.confirm_password_msg'|translate|titlecase}}</span>
                            <span
                                *ngIf="!!!this.restFromGroup.controls['password'].value?.length  && restFromGroup.get('comfirm_password')?.hasError('required')">
                                {{'reset_password.password_first'|translate|titlecase}}</span>
                            <span
                                *ngIf="restFromGroup.controls['comfirm_password']?.hasError('mismatch')  && !restFromGroup.get('comfirm_password')?.hasError('required')
                                        && !restFromGroup.controls['comfirm_password']?.hasError('pattern') && restFromGroup.get('password')?.value?.length && restFromGroup.get('comfirm_password')?.value?.length > 5">
                                {{'reset_password.password_mismatch'|translate|titlecase}}
                            </span>
                        </mat-error>
                    </mat-form-field>

                </div>

            </div>
            <div style="margin-top: 12%;">
                <button fxFlex="100" class="submit grow" (click)="submit()"
                    mat-raised-button>{{'button.confirm'|translate|uppercase}}</button>
            </div>
        </mat-card>

    </form>
</div>