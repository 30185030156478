<div fxFlex="100" style="height: 450px;width: 700px;" fxLayout="column">
    <div fxLayoutAlign="end center" style="margin-bottom: 5px;">
        <mat-icon (click)="dialogRef.close()">close</mat-icon>
    </div>
    <div fxLayoutAlign="end center" fxLayoutGap="10">
        <div class="search-field" fxFlex="30">
            <mat-form-field appearance="outline">
                <mat-label>Search</mat-label>
                <input type="text" (ngModelChange)="searchFieldChange()" [formControl]="searchControl"
                    (keydown.enter)="$event.preventDefault()" matInput placeholder="Search Location" autocorrect="off"
                    autocapitalize="off" spellcheck="off" type="text" #search>
            </mat-form-field>
        </div>

        <div>

            <button mat-stroked-button [disabled]="(!!!this.address?.length)" (click)="submit()">Submit</button>
        </div>
    </div>


    <agm-map fxFlex="100" [latitude]="latitude" [longitude]="longitude" [zoom]="zoom" (mapClick)="mapClick($event)">
        <agm-marker [latitude]="latitude" [longitude]="longitude" [markerDraggable]="true"
            (dragEnd)="markerDragEnd($event)" (markerClick)="clickMarker($event)"></agm-marker>
    </agm-map>

    <div>
        <div>Address: <span style="font-weight: 700;">{{address}}</span> </div>
    </div>


</div>