import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { GrowthService } from 'src/app/core/services/growth/growth.service';
import { BulkUploadDialogComponent } from 'src/shared/bulk-upload-dialog/bulk-upload-dialog.component';
import { MessageDialogComponent } from 'src/shared/message-dialog/message-dialog.component';
import { ReportService } from '../../report/report.service';
import { HomeService } from 'src/app/core/services/home/home.service';
import { Subscription } from 'rxjs';
import { downloadsop } from '../settings.model';
import { ScoreService } from '../score.service';
import { RecalculateDialogComponent } from 'src/shared/recalculate-dialog/recalculate-dialog.component';
import * as moment from 'moment';
import { SocketService } from 'src/app/core/services/socket/socket.service';

@Component({
  selector: 'app-growth-score',
  templateUrl: './growth-score.component.html',
  styleUrls: ['./growth-score.component.scss']
})
export class GrowthScoreComponent implements OnInit {
  current_time = new Date();
  createscreen: boolean = false;
  isloaded = false;
  public shed_id = localStorage.getItem('tank')?.length ? JSON.parse(localStorage.getItem('tank') || '')?.id : null;
  displayedColumns: string[] = [
    "code",
    "cadTrade.total",
    "cadTrade.amount",
    "data2.total",
    "data2.amount",
    "data5.total",
    "data5.amount",
    "data3.total",
    "data3.amount",
    "data4.total",
    "data4.amount",
    "data6.total",
    "data6.amount",
    'id'
  ];
  displayedColumns1: string[] = ["code", "data.data.group"];
  dataSource1 = new MatTableDataSource([]);
  displayedColumns2: string[] = ['Age in weeks', 'Parameters', 'Total No.of.Thresholds', 'Min Score', 'Max score', 'Action'];
  parameters: string[] = ['Weekly fish weight gain ( in g )', 'Weekly fish weight ( in g )', 'Weekly Shrimp weight gain ( in g )', 'Weekly Shrimp weight ( in g )'];
  dataSource = new MatTableDataSource([]);
  public company_name = localStorage.getItem('company')?.length ? JSON.parse(localStorage.getItem('company') || '')?.name : '--';
  public shed_name = localStorage.getItem('tank')?.length ? JSON.parse(localStorage.getItem('tank') || '')?.name : '--';
  public access: any;
  public subscriptions: Subscription[] = [];
  public recal_status: any;

  constructor(private fb: FormBuilder, private dialog: MatDialog, private ReportService: ReportService, private home: HomeService,
    private growth: GrowthService, private Scores: ScoreService,
    private socket: SocketService) {
    this.access = this.home.getAccess(21);

  }

  ngOnInit(): void {
    this.getGrowthScore();
    this.getRecalStatus();
    this.subscriptions.push(
      this.socket.recalculation_status.subscribe((res: any) => {
        if (res?.shed_id == this.shed_id) {
          this.getRecalStatus();
        }
      })
    );
  }
  /**
   * change list page from table and vice-verse
   */
  createList() {
    this.createscreen = !this.createscreen;
    this.growthScorevalue = undefined;

  }
  tabledata: any[] = [];
  /**
   * get growth score details service API for growth score table 
   */
  getGrowthScore() {
    this.subscriptions.push(this.growth.getGrowthScore().subscribe((res: any) => {
      console.log(res.data.filter((ele: any) => ele.age == 3));
      if (res.data?.length) {
        this.tabledata = res.data;
        this.dataSource.data = this.loopTableData(res.data) as never[];
        // this.dataSource.data = res.data;
      }
      this.isloaded = true;
    }, (error) => {
      this.dataSource1.data = [];
      this.isloaded = true;
    }));

  }
  tablefilteredList: any = {};
  /**
   * set table data in datasource from table data method
   * @param data 
   * @returns 
   */
  loopTableData(data: any[]) {
    const datalist: any[] = [];
    var variables = data,
      result = variables.reduce(function (r, a) {
        r[a.age] = r[a.age] || [];
        r[a.age].push(a);
        return r;
      }, Object.create(null));
    this.tablefilteredList = result;
    Object.keys(this.tablefilteredList).forEach(key => {
      var datum: any = {};
      datum['age'] = key;
      datum['id'] = key;
      datum['totalthreshold'] = this.tablefilteredList[key]?.length;
      var list: any[] = [];
      result[key].filter((m: any) => {
        list.push(m.f_weight_gain_max_score);
        list.push(m.f_weight_max_score);
        list.push(m.m_weight_gain_max_score);
        list.push(m.m_weight_max_score);
      });
      datum['min_score'] = Math.min(...list);
      datum['max_score'] = Math.max(...list);
      datalist.push(datum);
    });
    return datalist;
  }
  /**
   * data from child to parent component
   * @param event 
   */
  handleData(event: any) {
    this.getGrowthScore();
    this.createscreen = event;
    this.growthScorevalue = undefined;
  }
  growthScorevalue: any = {};
  /**
   * edit and view function table screen to create screen
   * @param id 
   * @param view 
   */
  editvalue(id: any, view: any) {
    // this.tabledata.filter((c) => {
    //   if (c.id == id) {
    //         this.growthScorevalue = c; 
    //       }
    // });
    const data = {
      data: this.tablefilteredList[id],
      screen: view ? 'view' : 'update'
    };
    this.growthScorevalue = data;
    this.createscreen = !this.createscreen;
  }
  /**
   * delete particular row from table Delete Popup
   * @param id 
   */
  deletevalue(id: any) {
    const config: MatDialogConfig = {
      panelClass: 'dialog-responsive',
      width: '50%',
      minWidth: '50%',
      disableClose: true,
      data: {
        title: `Selected Growth Score`,
        isDelete: true
      },
    };
    const openDialog = this.dialog.open(MessageDialogComponent, config);
    this.subscriptions.push(openDialog.afterClosed().subscribe((result: any) => {
      if (result) {
        this.subscriptions.push(this.growth.deleteGrowth(id).subscribe((res: any) => {
          this.getGrowthScore();
        }, (error: Response) => {
          console.log(error);
        }));
      }

    }));

  }
  /**
   * open bulk upload popup for growth score
   */
  bulkUpload() {
    const config: MatDialogConfig = {
      panelClass: 'dialog-responsive-bulk',
      width: '60%',
      minWidth: '60%',
      disableClose: true,
      data: {
        title: ``,
        isDelete: true,
        sop_name: 'growth_score'
      },
    };
    const openDialog = this.dialog.open(BulkUploadDialogComponent, config);
    this.subscriptions.push(openDialog.afterClosed().subscribe((result: any) => {
      if (result) {
        this.subscriptions.push(this.growth.postGrowthBulkUpload(result).subscribe((res: any) => {
          this.getGrowthScore();
        }, (error: Response) => {
          console.log(error);
        }));
      }
    }));

  }
  criticalHeader: string[] = ['Age in weeks', 'Female Weekly feed issue per bird', 'Male Weekly feed issue per bird', 'Threshold', 'Female Weekly gain (in g)',
    'Female Weekly gain (in g) Score', 'Male Weekly gain (in g)', 'Male Weekly gain (in g) Score', 'Female Weekly weight (in g)',
    'Female Weekly weight (in g) Score', 'Male Weekly weight (in g)', 'Male Weekly weight (in g) Score'];

  /**
   * download sop records for growth score
   */
  filterdataProductivity() {
    var arraylist: any[] = [];
    const result = this.tabledata.reduce(function (r, a) {
      r[a.age] = r[a.age] || [];
      r[a.age].push(a);
      return r;
    }, Object.create(null));
    var variables = result;
    Object.keys(variables).forEach(key => {
      var i = 1;
      variables[key].filter((sensor: any) => {
        let map: any = {};
        map['Age in weeks'] = sensor.age;
        map['Female Weekly feed issue per bird'] = this.setloops(sensor.f_expected_feed);
        map['Male Weekly feed issue per bird'] = this.setloops(sensor.m_expected_feed);
        map['Threshold'] = i;
        i++;
        map['Female Weekly gain (in g)'] = this.setloops(sensor.f_expected_weight_gain == "10101.00" ? 'max' : sensor.f_expected_weight_gain);
        map['Female Weekly gain (in g) Score'] = this.setloops(sensor.f_weight_gain_max_score);
        map['Male Weekly gain (in g)'] = this.setloops(sensor.m_expected_weight_gain == "10101.00" ? 'max' : sensor.m_expected_weight_gain);
        map['Male Weekly gain (in g) Score'] = this.setloops(sensor.m_weight_gain_max_score);
        map['Female Weekly weight (in g)'] = this.setloops(sensor.f_expected_weight == "10101.00" ? 'max' : sensor.f_expected_weight);
        map['Female Weekly weight (in g) Score'] = this.setloops(sensor.f_weight_max_score);
        map['Male Weekly weight (in g)'] = this.setloops(sensor.m_expected_weight == "10101.00" ? 'max' : sensor.m_expected_weight);
        map['Male Weekly weight (in g) Score'] = this.setloops(sensor.m_weight_max_score);
        arraylist.push(map);
      });
    });
    this.ReportService.downloadFile(arraylist, 'Growth Score Sop', this.criticalHeader);
  }
  downloadGrowth() {
    var downloadData: downloadsop = this.Scores.getThresholdNdMaxScore(this.tabledata);
    var prodcsvData = this.Scores.buildCsvObject(downloadData.data, downloadData.max, this.Scores.growth, this.Scores.growthConstants);
    this.Scores.downloadCsv(prodcsvData, 'Growth Score Sop');
  }
  /**
   * loop data for sop download records
   * @param data 
   * @returns 
   */
  setloops(data: any) {
    return data != null ? data : '';
  }

  getRecalStatus() {
    this.subscriptions.push(
      this.Scores.getRecalGrowthStatus().subscribe((res: any) => {
        if (res.data?.last_recalculated_time) {
          const msg = `${res.data.msg}\nLast recalculated date: ${moment(res.data.last_recalculated_time).format("DD MMM YYYY, h:mm:ss a")}`;
          this.recal_status = {
            msg,
            error: !!res.data.error
          };
        }
      }));
  }
  recalculate() {
    const config: MatDialogConfig = {
      panelClass: 'dialog-responsive',
      minWidth: '50%',
      disableClose: true,
      data: {
        title: "recalculate.growth_score",
        is_growth_score: true,
      },
    };
    const dialogRes = this.dialog.open(RecalculateDialogComponent, config);
    dialogRes.afterClosed().subscribe((res: any) => {
      if (res) {
        this.getRecalStatus();
      }

    });
  }
  ngOnDestroy() {
    this.subscriptions.forEach((sub: Subscription) => sub.unsubscribe());
  }

}
