import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-view-details-dialog',
  templateUrl: './view-details-dialog.component.html',
  styleUrls: ['./view-details-dialog.component.scss']
})
export class ViewDetailsDialogComponent implements OnInit {
  public data: any;
  constructor (
    public dialogRef: MatDialogRef<ViewDetailsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: any,
  ) {
    this.data = this.dialogData?.data;
  }

  ngOnInit (): void {
  }

}
