import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { HomeService } from 'src/app/core/services/home/home.service';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {
  public subscription: Subscription[] = [];
  loginPage: any = new FormGroup({
    user_login_name: new FormControl('', Validators.required),
    password: new FormControl('', [
      Validators.required])
  });
  public hide = true;
  constructor(private AuthService: AuthService,
    private route: Router,
    private home: HomeService,
  ) { }

  ngOnInit(): void {
  }


  /**------------------------------------------------------------------------------------------------- */

  /** Handles the sign-in process by sending a request to the server 
   * with the loginPage form value and performing necessary actions based on the response. */
  /** Stores user data and token, retrieves role and country data,
   *  and navigates to the home page if sign-in is successful. */
  login() {
    if (this.loginPage.invalid) {
      this.loginPage.markAllAsTouched();
      return;
    }

    this.subscription.push(
      this.AuthService.signIn(this.loginPage
        .value).subscribe((res: any) => {
          if (res.token) {
            const userData = this.parseJwt(res.token);
            localStorage.setItem("user_data", JSON.stringify(userData));
            localStorage.setItem("token", res.token);
            this.home.getRoleBehaviorSubject();
            this.home.getCountryBehaviorSubject();
            if (userData.company_id) {
              localStorage.setItem('company', JSON.stringify({ id: userData.company_id, name: userData.company_name }));
            }
            if (userData.shed_id) {
              localStorage.setItem('tank', JSON.stringify({ id: userData.tank_id, name: userData.tank_name }));
            }
            if (userData.user_role == 5) {
              this.home.getShedBehaviorSubject();
            } else {
              this.route.navigate(['/home']);

            }
          }
        })
    );
  }

  /**------------------------------------------------------------------------------------------------- */


  // Jwt token decoder
  parseJwt(token: any) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
    return JSON.parse(jsonPayload);
  }

  showHidePassword() {
    if (this.loginPage.controls['password'].value?.length) {
      this.hide = !this.hide;
    } else {
      this.hide = true;
    }
  }

  hidePassword() {
    if (!!!this.loginPage.controls['password'].value?.length) {
      this.hide = true;
    }
  }

  ngOnDestroy(): void {
    this.subscription.forEach((s: Subscription) => s.unsubscribe());
  }
}
