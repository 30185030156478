import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ReportsService } from 'src/app/core/services/reports/reports.service';

@Component({
  selector: 'app-viewreport',
  templateUrl: './viewreport.component.html',
  styleUrls: ['./viewreport.component.scss']
})
export class ViewreportComponent implements OnInit {
  public params_data: any;
  public token: any;
  public headers: any = [];
  public keys: string[] = [];
  public data: any = []
  public shed_data = localStorage.getItem('tank')?.length ? JSON.parse(localStorage.getItem('tank') || '') : '--';
  subHeader: any
  type: any
  constructor(private activeRoute: ActivatedRoute, private route: Router, private report: ReportsService,) { }

  ngOnInit(): void {
    if (this.activeRoute.snapshot.params["encode"]) {
      this.params_data = window.atob(this.activeRoute.snapshot.params["encode"]);

    }
    if (this.params_data) {     
      this.subHeader = this.params_data.split('/')[5].split('-')[0] + '-' + this.params_data.split('/')[5].split('-')[1]
      this.type = this.params_data.split('/')[2] == 'day_entry' ? "Productivity Report" : "Environment Report"
      console.log(this.subHeader);
      this.report.getReportFileAsJson(this.params_data).subscribe(res => {
        this.data = res.data;
        let tempHeaders = Object.keys(this.data[0]);
        this.headers = tempHeaders.map((e: any) => {

          let splited = e.split('(')
          if (splited.length > 1) {
            return `${splited[0]}\n <p style="white-space: nowrap!important;">(${splited[1]}</p>`
          }
          return e;
        });


        this.keys = Object.keys(this.data[0]);
      })
    }
    setTimeout(() => {
      const table = document.querySelector('table');
      const firstColumn = table?.querySelector('th:first-child');
      const secondColumn: any = table?.querySelector('th:nth-child(2)');

      const firstColumnWidth = firstColumn?.getBoundingClientRect().width || 0;
      const secondColumnWidth = secondColumn?.getBoundingClientRect().width || 0;
      console.log(firstColumnWidth, secondColumnWidth);

      if (secondColumn) {
        secondColumn.style.left = `${firstColumnWidth}px`;
      }

      const tbList = document.getElementsByClassName('secth');
      for (let i = 0; i < tbList.length; i++) {
        const tb = tbList[i] as HTMLElement;
        tb.style.left = `${firstColumnWidth}px`;
      }

    }, 1000);



  }


}