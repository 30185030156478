import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PRODUCTIVITY } from 'src/app/config/api.config';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProductivityService {


  private BASE_URL = environment.BASE_URL;
  constructor(private http: HttpClient) { }


  public postProductivity(data: any): Observable<any> {
    return this.http.post(`${this.BASE_URL}${PRODUCTIVITY.CREATE_PRODUCTIVITY}`, data);
  }
  public updateProductivity(data: any): Observable<any> {
    return this.http.put(`${this.BASE_URL}${PRODUCTIVITY.CREATE_PRODUCTIVITY}`, data);
  }
  public deleteProductivity(data: any): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: data,
    };
    return this.http.delete(`${this.BASE_URL}${PRODUCTIVITY.CREATE_PRODUCTIVITY}`, options);
  }
  public postProductivityBulkUpload(data: any): Observable<any> {
    const shed_id = JSON.parse(localStorage.getItem('tank') || '')?.id;
    return this.http.post(`${this.BASE_URL}${PRODUCTIVITY.BULKUPLOAD}/${shed_id}`, data);
  }

  public getProductivityScore() {
    const page = 100;
    const shed_id = JSON.parse(localStorage.getItem('tank') || '')?.id;
    return this.http.get(`${this.BASE_URL}${PRODUCTIVITY.GET_PRODUCTIVITY}/${shed_id}?perpage=${page}`);
  }
}
