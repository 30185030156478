<div class="h-100">
    <div fxFlex="100" fxLayout="column" class="per1pad full-div" fxLayoutGap="10">
        <!-- Blue header with create and list button with company details -->
        <mat-card style="background-color: #166DA6;color: white;height:4rem;overflow: hidden;" fxLayout="row"
            fxLayoutAlign="space-between center">
            <div fxLayout="column">
                <span style="font-size: 13px;color: white;font-weight: bold;line-height: 1;">{{shed_name}}</span>
                <span style="line-height: 1;margin-top: 7px;font-size: 10px;
                color: #FFFFFF;">{{company_name}}</span>
            </div>
            <div fxLayoutAlign="center center" fxLayoutGap="10">
                <button *ngIf="!createscreen &&(access?.create && !access?.view)" mat-stroked-button
                    class="add-button grow hide" fxLayout="row" (click)="createList();">
                    <mat-icon class="add-icon">add_circle</mat-icon>
                    <span>{{'button.create'|translate|uppercase}}</span>
                </button>
                <button *ngIf="!createscreen &&(access?.create && !access?.view)" mat-stroked-button
                    class="add-button grow show"
                    style="min-width: 0;width: 34px;justify-content: center;align-items: center;"
                    (click)="createList();">
                    <mat-icon class="listicon" svgIcon="plus" style="height: 22px;width: 22px;"></mat-icon>
                </button>
                <button *ngIf="createscreen &&(access?.create && !access?.view)" mat-stroked-button
                    class="add-button grow" fxLayout="row" (click)="createList()">
                    <mat-icon svgIcon="list" style="margin-right: 6px;height: 22px;width: 22px;"></mat-icon>
                    <span>{{'env_score.list'|translate|uppercase}}</span>
                </button>
                <!-- bulk upload popup button still not developed in for productivity-->
                <!-- <button *ngIf="!createscreen" mat-stroked-button class="add-button grow flixbttn" fxLayout="row"
                (click)="bulkUpload();">
                <mat-icon style="margin-right: 6px;" class="momarg">cloud_upload</mat-icon>
                    <span class="hide">BULK UPLOAD</span>
                </button> -->
            </div>
        </mat-card>
        <div fxFlex="85" fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="10px" *ngIf="!createscreen">
            <div fxLayout="column" fxFlex="100" fxLayoutGap="10">
                <mat-card class="mat-elevation-z6" fxLayout="column">
                    <div fxLayoutAlign="start start" fxLayoutAlign.lt-sm="center center"
                        style="width: 100%;overflow: auto;" class="scrollnull">
                        <div style="width: 100%;">
                            <!-- load empty image when no table data is available  -->
                            <div *ngIf="environmentaldata.length==0 && isloaded" style="color: #3B3B3B;text-align: center;height: 60vh;align-items: center;
                                display: grid;">
                                <div fxLayout="column" fxLayoutGap="10px" style="justify-content: center;
                                align-items: center;">
                                    <img src="../../../assets/images/empty/Createscreens.png" alt=""
                                        style="height: 140px;width: 140px;">
                                    <span>{{'env_score.empty_msg'|translate|titlecase}}</span>
                                </div>
                            </div>
                            <!-- load table when table data is available  -->
                            <table class="level-one-table" mat-table [dataSource]="dataSource" style="min-width: 190vh;"
                                *ngIf="environmentaldata.length>0">
                                <!-- S.No Column -->
                                <ng-container matColumnDef="S.NO">
                                    <th mat-header-cell *matHeaderCellDef>{{'env_score.s_no'|translate|titlecase}}</th>
                                    <td mat-cell *matCellDef="let element;let i = index"> {{i+1}} </td>
                                </ng-container>
                                <!-- Sensor type Column -->
                                <ng-container matColumnDef="Sensor type">
                                    <th mat-header-cell *matHeaderCellDef>
                                        {{'env_score.sensor_type'|translate|titlecase}}
                                    </th>
                                    <td mat-cell *matCellDef="let element"> {{element.sensor_type}} </td>
                                </ng-container>
                                <!-- Sensor name Column -->
                                <ng-container matColumnDef="Sensor name">
                                    <th mat-header-cell *matHeaderCellDef>
                                        {{'env_score.sensor_name'|translate|titlecase}}
                                    </th>
                                    <td mat-cell *matCellDef="let element"> {{element.sensor_name}} </td>
                                </ng-container>
                                <!-- Sub type Column -->
                                <ng-container matColumnDef="Sub type">
                                    <th mat-header-cell *matHeaderCellDef>{{'env_score.sub_type'|translate|titlecase}}
                                    </th>
                                    <td mat-cell *matCellDef="let element"> {{element.sub_type}} </td>
                                </ng-container>
                                

                                <!-- No.of.Thresholds Column -->
                                <ng-container matColumnDef="Total No.of.Thresholds">
                                    <th mat-header-cell *matHeaderCellDef>
                                        {{'env_score.total_threshold'|translate|titlecase}}
                                    </th>
                                    <td mat-cell *matCellDef="let element"> {{element.totalthreshold}} </td>
                                </ng-container>

                                <!-- Min Score Column -->
                                <ng-container matColumnDef="Min Score">
                                    <th mat-header-cell *matHeaderCellDef> {{'env_score.min_score'|translate|titlecase}}
                                    </th>
                                    <td mat-cell *matCellDef="let element"> {{element.min_score}} </td>
                                </ng-container>
                                <!-- Max score Column -->
                                <ng-container matColumnDef="Max score">
                                    <th mat-header-cell *matHeaderCellDef>{{'env_score.max_score'|translate|titlecase}}
                                    </th>
                                    <td mat-cell *matCellDef="let element"> {{element.max_score}} </td>
                                </ng-container>
                                <!-- Action Column -->
                                <ng-container matColumnDef="Action">
                                    <th mat-header-cell *matHeaderCellDef>{{'env_score.action'|translate|titlecase}}
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        <div style="display: flex;">
                                            <div style="width: 100%;">
                                                <span><mat-icon class="iconvalue" svgIcon="view"
                                                        (click)="editvalue(element.id,true)"
                                                        style="margin:0%"></mat-icon></span>
                                            </div>
                                        </div>
                                    </td>
                                </ng-container>

                                <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky:true"></tr>
                                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                            </table>
                        </div>
                    </div>
                </mat-card>
            </div>
        </div>
        <!-- create environmental score screen -->
        <div fxFlex="94" style="overflow:auto" fxLayout="column" fxLayout.lt-sm="column" *ngIf="createscreen"
            fxLayoutGap="10">
            <app-create-environmental-score [style.width]="'100%'" (path)="receiveData($event)"
                [environmentalvalue]="environmentalvalue"></app-create-environmental-score>
        </div>
    </div>
</div>