<div class="p-1 h-100" fxLayout="column" fxLayoutGap="10">
    <!-- Blue header with create and list button with company details -->
    <mat-card style="background-color: #166DA6;color: white;height:4rem;overflow: hidden;" fxLayout="row"
        fxLayoutAlign="space-between center">
        <div fxLayout="column">
            <span style="font-size: 13px;color: white;font-weight: bold;line-height: 1;">{{shed_name}}</span>
            <span style="line-height: 1;margin-top: 7px;font-size: 10px;
     color: #FFFFFF;">{{company_name}}</span>
        </div>
        <form [formGroup]="header_group" fxLayoutAlign="center center" fxLayout="row"
            style="height: 2.9rem;background: white;border-radius: 5px;">
            <!--  form field for age in weeks-->
            <mat-form-field appearance="outline" style="height: 3.5rem !important;color: black !important;">
                <mat-label>{{'growth_score.age_in_weeks'|translate|titlecase}}</mat-label>
                <input matInput formControlName="age_In_Week" type="number" (keydown.arrowup)="$event.preventDefault()"
                    (keydown.arrowdown)="$event.preventDefault()" (wheel)="$event.preventDefault()"
                    (keydown)="$event.key =='e' || $event.key =='E' || $event.key =='+'|| $event.key =='-' ||$event.key == '.'? $event.preventDefault():''"
                    oninput="this.value=this.value.replace(/^(0*|[^\d]+)/g,''); if (this.value.length > 3) this.value = this.value.slice(0, 3);"
                    autocomplete="new-password" [readonly]="isview">
            </mat-form-field>
        </form>

        <button mat-stroked-button class="add-button grow" fxLayout="row" (click)="clear()">
            <mat-icon svgIcon="list" style="margin-right: 6px;height: 22px;width: 22px;"></mat-icon>
            <span>{{'growth_score.list'|translate|uppercase}}</span>
        </button>
    </mat-card>

    <mat-card class="mat-elevation-z6" fxLayout="column" fxLayoutGap="10px" fxLayoutAlign="space-between center"
        style="overflow-y:scroll;height: 90%;">
        <!-- form container for sensor age in weeks-->
        <!-- <form [formGroup]="header_group" class="mortalityscore1 fieldmor"
            style="margin-bottom: 30px;display: flex;gap: 2%;margin-top: 2%;width:100%">
            <mat-form-field appearance="outline">
                <mat-label>{{'mortality_score.age_in_weeks'|translate|titlecase}}</mat-label>
                <input matInput formControlName="age_In_Week" type="number" (keydown.arrowup)="$event.preventDefault()"
                    (keydown.arrowdown)="$event.preventDefault()" (wheel)="$event.preventDefault()"
                    (keydown)="$event.key =='e' || $event.key =='E' || $event.key =='+'|| $event.key =='-' ||$event.key == '.'? $event.preventDefault():''"
                    oninput="this.value=this.value.replace(/^(0*|[^\d]+)/g,''); if (this.value.length > 3) this.value = this.value.slice(0, 3);"
                    autocomplete="new-password" [readonly]="isview" autocomplete="off">
            </mat-form-field>
        </form> -->
        <!-- container -->
        <div style="width: 100%;background-color: #f5faf5;" class="tableMortality formdata">
            <!-- Form Container for mortality per bird-->
            <form style="padding: 2%;overflow: auto;" class="scrollnull">
                <table [dataSource]="dataSource1" mat-table style="width: 100%;min-width: 150vh;">
                    <!-- threshold column -->
                    <ng-container matColumnDef="Threshold">
                        <th [ngStyle]="{'display': 'none'}" mat-header-cell *matHeaderCellDef [attr.rowspan]="2"> </th>
                        <td mat-cell *matCellDef="let element;let i = index">
                            {{i+1}}
                        </td>
                    </ng-container>
                    <!-- mortality per bird female value -->
                    <ng-container matColumnDef="mortalityfemale" class="cellspace">
                        <th mat-header-cell *matHeaderCellDef> {{'mortality_score.per_fish'|translate|titlecase}} </th>
                        <td mat-cell *matCellDef="let element;let i = index">
                            <div *ngIf="i !=(mortalityperBird.controls.length-1)"
                                style="text-align: start;margin-left: 25%;">
                                <mat-form-field appearance="outline" style="width:70%">
                                    <mat-label>{{'mortality_score.value'|translate|titlecase}}</mat-label>
                                    <input type="number" (keydown.arrowup)="$event.preventDefault()"
                                        (keydown.arrowdown)="$event.preventDefault()" (wheel)="$event.preventDefault()"
                                        (keydown)="$event.key =='e' || $event.key =='E' || $event.key =='+'|| $event.key =='-' ? $event.preventDefault():''"
                                        oninput="if(!/^\d{1,2}(\.\d{0,2})?$/.test(this.value)) this.value = this.value.slice(0, -1)"
                                        autocomplete="new-password" matInput
                                        (focusout)="validateThresholdValue(i,'mortality_female',mortalityperBird)"
                                        [formControl]="getControl(i,'mortality_female',mortalityperBird)"
                                        [readonly]="isview" /></mat-form-field>
                            </div>
                            <div *ngIf="i ==(mortalityperBird.controls.length-1)" class="disableclassmor"
                                style="text-align: start;margin-left: 25%;">
                                <mat-form-field appearance="outline" style="width:70%;pointer-events:none;">
                                    <mat-label>{{'mortality_score.max'|translate|titlecase}}</mat-label>
                                    <input type="number" matInput [readonly]="true" />
                                </mat-form-field>
                                <mat-icon (click)="info(mortalityperBird,'mortality_female')"
                                    style="position: relative;top: 5px;">info</mat-icon>
                            </div>
                        </td>
                    </ng-container>
                    <!-- mortality per bird female score -->
                    <ng-container matColumnDef="mortalityfemalescore">
                        <th mat-header-cell *matHeaderCellDef class="similar-cell-width cellspace">
                            {{'mortality_score.score'|translate|titlecase}}
                        </th>
                        <td mat-cell *matCellDef="let element;let i = index" class="mortalityscoreField">
                            <div fxFlex="80">
                                <mat-slider style="width:75% !important ;margin-top: 20px;"
                                    [formControl]="getControl(i,'mortality_female_score',mortalityperBird)"
                                    [disabled]="isview" thumbLabel min="-10" max="10"></mat-slider>
                            </div>
                        </td>
                    </ng-container>
                    <!-- mortality per bird male value -->
                    <ng-container matColumnDef="mortalitymale">
                        <th mat-header-cell *matHeaderCellDef class="cellspace">
                            {{'mortality_score.per_shrimp'|translate|titlecase}}
                        </th>
                        <td mat-cell *matCellDef="let element;let i = index">
                            <div *ngIf="i !=(mortalityperBird.controls.length-1)"
                                style="text-align: start;margin-left: 25%;">
                                <mat-form-field appearance="outline" style="width:70%">
                                    <mat-label>{{'mortality_score.value'|translate|titlecase}}</mat-label>
                                    <input type="number" (keydown.arrowup)="$event.preventDefault()"
                                        (keydown.arrowdown)="$event.preventDefault()" (wheel)="$event.preventDefault()"
                                        (keydown)="$event.key =='e' || $event.key =='E' || $event.key =='+'|| $event.key =='-' ? $event.preventDefault():''"
                                        oninput="if(!/^\d{1,2}(\.\d{0,2})?$/.test(this.value)) this.value = this.value.slice(0, -1)"
                                        autocomplete="new-password" matInput
                                        (focusout)="validateThresholdValue(i,'mortality_male',mortalityperBird)"
                                        [formControl]="getControl(i,'mortality_male',mortalityperBird)"
                                        [readonly]="isview" /></mat-form-field>
                            </div>
                            <div *ngIf="i ==(mortalityperBird.controls.length-1)" class="disableclassmor"
                                style="text-align: start;margin-left: 25%;">
                                <mat-form-field appearance="outline" style="width:70%;pointer-events:none;">
                                    <mat-label>{{'mortality_score.max'|translate|titlecase}}</mat-label>
                                    <input type="number" matInput [readonly]="true" />
                                </mat-form-field>
                                <mat-icon (click)="info(mortalityperBird,'mortality_male')"
                                    style="position: relative;top: 5px;">info</mat-icon>
                            </div>
                        </td>
                    </ng-container>
                    <!-- mortality per bird male score -->
                    <ng-container matColumnDef="mortalitymalescore">
                        <th mat-header-cell *matHeaderCellDef class="similar-cell-width cellspace">
                            {{'mortality_score.score'|translate|titlecase}}
                        </th>
                        <td mat-cell *matCellDef="let element;let i = index" class="mortalityscoreField">
                            <div fxFlex="80">
                                <mat-slider style="width:75% !important ;margin-top: 20px;"
                                    [formControl]="getControl(i,'mortality_male_score',mortalityperBird)"
                                    [disabled]="isview" thumbLabel min="-10" max="10"></mat-slider>
                            </div>
                            <div [ngStyle]="isview ? {'pointer-events':'none'}:{}" fxFlex="20"
                                style="display: flex;align-items: center;justify-content: end;">
                                <mat-icon style="color:red;" *ngIf="i > 3 && i !=(mortalityperBird.controls.length-1)"
                                    (click)="removeSensor(i,dataSource1,mortalityperBird)">do_not_disturb_on</mat-icon>
                                <mat-icon style="color:#4bae4f;" *ngIf="i ==(mortalityperBird.controls.length-2)"
                                    (click)="addSensor(dataSource1,mortalityperBird,i)">add_circle</mat-icon>
                            </div>
                        </td>
                    </ng-container>
                    <!-- top row -->
                    <ng-container matColumnDef="header-row-first-group">
                        <th mat-header-cell *matHeaderCellDef [attr.rowspan]="2" class="cellspace">
                            {{'mortality_score.threshold'|translate|titlecase}}
                        </th>
                    </ng-container>
                    <ng-container matColumnDef="header-row-sec-group">
                        <th mat-header-cell *matHeaderCellDef [attr.colspan]="2">
                            {{'mortality_score.female'|translate|titlecase}}
                        </th>
                    </ng-container>
                    <ng-container matColumnDef="header-row-third-group">
                        <th mat-header-cell *matHeaderCellDef [attr.colspan]="2">
                            {{'mortality_score.male'|translate|titlecase}}
                        </th>
                    </ng-container>
                    <tr mat-header-row
                        *matHeaderRowDef="['header-row-first-group','header-row-sec-group','header-row-third-group']">
                    </tr>
                    <tr mat-header-row *matHeaderRowDef="displayedColumns;"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>
            </form>
            <!-- Form Container for cumulative mortality per bird-->
            <form style="padding: 2%;overflow: auto;" class="scrollnull">
                <table [dataSource]="dataSource2" mat-table style="width: 100%;min-width: 150vh;">
                    <!-- threshold column -->
                    <ng-container matColumnDef="Threshold">
                        <th [ngStyle]="{'display': 'none'}" mat-header-cell *matHeaderCellDef [attr.rowspan]="2"> </th>
                        <td mat-cell *matCellDef="let element;let i = index">
                            {{i+1}}
                        </td>
                    </ng-container>
                    <!-- cumulative mortality per bird female value -->
                    <ng-container matColumnDef="mortalityfemale" class="cellspace">
                        <th mat-header-cell *matHeaderCellDef>
                            {{'mortality_score.cumulative_per_fish'|translate|titlecase}}
                        </th>
                        <td mat-cell *matCellDef="let element;let i = index">
                            <div *ngIf="i !=(cumulativemortalityperBird.controls.length-1)"
                                style="text-align: start;margin-left: 25%;">
                                <mat-form-field appearance="outline" style="width:70%">
                                    <mat-label>{{'mortality_score.value'|translate|titlecase}}</mat-label>
                                    <input type="number" (keydown.arrowup)="$event.preventDefault()"
                                        (keydown.arrowdown)="$event.preventDefault()" (wheel)="$event.preventDefault()"
                                        (keydown)="$event.key =='e' || $event.key =='E' || $event.key =='+'|| $event.key =='-' ? $event.preventDefault():''"
                                        oninput="if(!/^\d{1,2}(\.\d{0,2})?$/.test(this.value)) this.value = this.value.slice(0, -1)"
                                        autocomplete="new-password" matInput
                                        (focusout)="validateThresholdValue(i,'mortality_female',cumulativemortalityperBird)"
                                        [formControl]="getControl(i,'mortality_female',cumulativemortalityperBird)"
                                        [readonly]="isview" /></mat-form-field>
                            </div>
                            <div *ngIf="i ==(cumulativemortalityperBird.controls.length-1)" class="disableclassmor"
                                style="text-align: start;margin-left: 25%;">
                                <mat-form-field appearance="outline" style="width:70%;pointer-events:none;">
                                    <mat-label>{{'mortality_score.max'|translate|titlecase}}</mat-label>
                                    <input type="number" matInput [readonly]="true" />
                                </mat-form-field>
                                <mat-icon (click)="info(cumulativemortalityperBird,'mortality_female')"
                                    style="position: relative;top: 5px;">info</mat-icon>
                            </div>
                        </td>
                    </ng-container>
                    <!-- cumulative mortality per bird female score -->
                    <ng-container matColumnDef="mortalityfemalescore">
                        <th mat-header-cell *matHeaderCellDef class="similar-cell-width cellspace">
                            {{'mortality_score.score'|translate|titlecase}}
                        </th>
                        <td mat-cell *matCellDef="let element;let i = index" class="mortalityscoreField">
                            <div fxFlex="80">
                                <mat-slider style="width:75% !important ;margin-top: 20px;"
                                    [formControl]="getControl(i,'mortality_female_score',cumulativemortalityperBird)"
                                    [disabled]="isview" thumbLabel min="-10" max="10"></mat-slider>
                            </div>
                        </td>
                    </ng-container>
                    <!-- cumulative mortality per bird male value -->
                    <ng-container matColumnDef="mortalitymale">
                        <th mat-header-cell *matHeaderCellDef class="cellspace">
                            {{'mortality_score.cumulative_per_shrimp'|translate|titlecase}}
                        </th>
                        <td mat-cell *matCellDef="let element;let i = index">
                            <div *ngIf="i !=(cumulativemortalityperBird.controls.length-1)"
                                style="text-align: start;margin-left: 25%;">
                                <mat-form-field appearance="outline" style="width:70%">
                                    <mat-label>{{'mortality_score.value'|translate|titlecase}}</mat-label>
                                    <input type="number" (keydown.arrowup)="$event.preventDefault()"
                                        (keydown.arrowdown)="$event.preventDefault()" (wheel)="$event.preventDefault()"
                                        (keydown)="$event.key =='e' || $event.key =='E' || $event.key =='+'|| $event.key =='-' ? $event.preventDefault():''"
                                        oninput="if(!/^\d{1,2}(\.\d{0,2})?$/.test(this.value)) this.value = this.value.slice(0, -1)"
                                        autocomplete="new-password" matInput
                                        (focusout)="validateThresholdValue(i,'mortality_male',cumulativemortalityperBird)"
                                        [formControl]="getControl(i,'mortality_male',cumulativemortalityperBird)"
                                        [readonly]="isview" /></mat-form-field>
                            </div>
                            <div *ngIf="i ==(cumulativemortalityperBird.controls.length-1)" class="disableclassmor"
                                style="text-align: start;margin-left: 25%;">
                                <mat-form-field appearance="outline" style="width:70%;pointer-events:none;">
                                    <mat-label>{{'mortality_score.max'|translate|titlecase}}</mat-label>
                                    <input type="number" matInput [readonly]="true" />
                                </mat-form-field>
                                <mat-icon (click)="info(cumulativemortalityperBird,'mortality_male')"
                                    style="position: relative;top: 5px;">info</mat-icon>
                            </div>
                        </td>
                    </ng-container>
                    <!-- cumulative mortality per bird male score -->
                    <ng-container matColumnDef="mortalitymalescore">
                        <th mat-header-cell *matHeaderCellDef class="similar-cell-width cellspace">
                            {{'mortality_score.score'|translate|titlecase}}
                        </th>
                        <td mat-cell *matCellDef="let element;let i = index" class="mortalityscoreField">
                            <div fxFlex="80">
                                <mat-slider style="width:75% !important ;margin-top: 20px;"
                                    [formControl]="getControl(i,'mortality_male_score',cumulativemortalityperBird)"
                                    [disabled]="isview" thumbLabel min="-10" max="10"></mat-slider>
                            </div>
                            <div [ngStyle]="isview ? {'pointer-events':'none'}:{}" fxFlex="20"
                                style="display: flex;align-items: center;justify-content: end;">
                                <mat-icon style="color:red;"
                                    *ngIf="i > 3 && i !=(cumulativemortalityperBird.controls.length-1)"
                                    (click)="removeSensor(i,dataSource2,cumulativemortalityperBird)">do_not_disturb_on</mat-icon>
                                <mat-icon style="color:#4bae4f;"
                                    *ngIf="i ==(cumulativemortalityperBird.controls.length-2)"
                                    (click)="addSensor(dataSource2,cumulativemortalityperBird,i)">add_circle</mat-icon>
                            </div>
                        </td>
                    </ng-container>
                    <!-- top row -->
                    <ng-container matColumnDef="header-row-first-group">
                        <th mat-header-cell *matHeaderCellDef [attr.rowspan]="2" class="cellspace">
                            {{'mortality_score.threshold'|translate|titlecase}}
                        </th>
                    </ng-container>
                    <ng-container matColumnDef="header-row-sec-group">
                        <th mat-header-cell *matHeaderCellDef [attr.colspan]="2">
                            {{'mortality_score.female'|translate|titlecase}}
                        </th>
                    </ng-container>
                    <ng-container matColumnDef="header-row-third-group">
                        <th mat-header-cell *matHeaderCellDef [attr.colspan]="2">
                            {{'mortality_score.male'|translate|titlecase}}
                        </th>
                    </ng-container>
                    <tr mat-header-row
                        *matHeaderRowDef="['header-row-first-group','header-row-sec-group','header-row-third-group']">
                    </tr>
                    <tr mat-header-row *matHeaderRowDef="displayedColumns;"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>
            </form>
        </div>
        <!-- submit Button -->
        <!-- while create flow its submit button if its update flow it will show update button 
            and if its view flow its didnt show any submit or update button it only shows back button  -->
        <div fxLayout="row" fxFlex="35" style="width: 100%;" fxLayoutAlign="center end">
            <div fxFlex="100" fxLayoutAlign="center center" fxLayout.lt-sm="column" fxLayoutGap="25"
                fxLayoutAlign.lt-sm="center center" class="buttondiv" style="width: 100%;max-height: 6vh;">
                <button class=" submit grow changeview" mat-raised-button style="box-shadow: none;width: 14%;"
                    (click)="submit()"
                    *ngIf="!isview">{{isupdate?('button.update'|translate|titlecase):'button.submit'|translate|titlecase}}</button>
                <button class="cancel grow changeview" mat-raised-button style="box-shadow: none;width: 14%;"
                    (click)="clear()">{{isview?('button.back'|translate|titlecase):'button.cancel'|translate|titlecase }}</button>
            </div>
        </div>
    </mat-card>
</div>