import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MessageService } from 'src/app/core/services/message/message.service';

@Component({
  selector: 'app-batch-ending-dialog',
  templateUrl: './batch-ending-dialog.component.html',
  styleUrls: ['./batch-ending-dialog.component.scss']
})
export class BatchEndingDialogComponent implements OnInit {
  endinfo:boolean=false;
  isnotStarted:boolean=false;
  constructor(
    public dialogRef: MatDialogRef<BatchEndingDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: any,private show_msg: MessageService
    ) { }

  ngOnInit(): void {
    const dialogContainer: any = document.getElementsByClassName("mat-dialog-container")[0];
    if (this.dialogData?.isDelete) {
      dialogContainer.style.overflow = "initial";
    } else {
      dialogContainer.style.overflow = "auto";
    }
    this.endinfo=this.dialogData?this.dialogData?.isConformation:false;
    this.isnotStarted=this.dialogData?!this.dialogData?.isStarted:false;
  }
  cancel() {
    this.dialogRef.close();
  }
  submit(){
    this.dialogRef.close(true);
  }

}
