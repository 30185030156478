import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { sideMenuItem } from 'src/shared/model/layout-model/layout-model';
import { MessageService } from '../services/message/message.service';
@Injectable({
  providedIn: 'root'
})
export class PermissionGuard implements CanActivate {
  private user_data: any = localStorage.getItem('user_data')
    ? JSON.parse(localStorage.getItem('user_data') || '') : null;
  private menu_access: any[] = this.user_data?.access?.menu_access
    ? JSON.parse(this.user_data?.access?.menu_access) : null;

  private menuAccessIds: any = this.menu_access?.filter((menu: any) => Object.values(menu).includes(true)).map((d: any) => d.menu_id);
  private all_menu: Array<any> = [...sideMenuItem,
  ...sideMenuItem.map(m => m.subMenuItems).flat()].filter(m => this.menuAccessIds?.includes(m?.menu_id));
  constructor(private show_msg: MessageService) {
  }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    //sop score page permission
    if (JSON.parse(localStorage.getItem('tank') || "")?.breedType !== 'breeder') {
      const settings_menu = sideMenuItem.find((m: Record<string, any>) => m["menu_id"] == 18)?.subMenuItems;
      const access_denied_menus: Array<number> = [21, 22, 23, 26, 27, 28];
      if (settings_menu?.length)
        for (let index = 0; index < settings_menu.length; index++) {
          const element = settings_menu[index];
          if (element?.url && state.url.includes(element?.url) && access_denied_menus.includes(element.menu_id)) {
            this.show_msg.errorSnackBar('access_denied');
            return false;
          }

        }
    }

    if (this.user_data.user_role !== 1) {
      const is_allow = this.all_menu.some(m => state.url.includes(m.url) && this.menuAccessIds?.includes(m.menu_id));
      !is_allow ? this.show_msg.errorSnackBar('access_denied') : '';
      return is_allow;
    }
    return true;
  }

}
