import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-common-top-bar',
  templateUrl: './common-top-bar.component.html',
  styleUrls: ['./common-top-bar.component.scss']
})
export class CommonTopBarComponent implements OnInit {
  public current_time: any;
  public company_name = localStorage.getItem('company')?.length ? JSON.parse(localStorage.getItem('company') || '')?.name : '--';
  public tank_name = localStorage.getItem('tank')?.length ? JSON.parse(localStorage.getItem('tank') || '')?.name : '--';
  constructor() { }

  ngOnInit(): void {

  }

}
