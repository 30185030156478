<div class="m-p-1" fxLayout="column" fxLayoutGap="10" style="height:96%">

    <!-- ----------------------------top blue bar ----------------------------------->
    <mat-card style="background-color: #166DA6;color: white;height:4.2rem;" fxLayout="row"
        fxLayoutAlign="space-between center">
        <div fxLayout="column">
            <span style="font-size: 13px;color: white;font-weight: bold;line-height: 1;">{{shed_name}}</span>
            <span style="line-height: 1;margin-top: 7px;font-size: 10px;
    color: #FFFFFF;">{{company_name}}</span>
        </div>
        <div>
            <button *ngIf="access?.create && !access?.view" mat-stroked-button style="text-align: center;"
                fxLayout="row" class="add-button grow" (click)="showHideTable();"><mat-icon class="add-icon"
                    *ngIf="showTable && !!!active_batch_id">add_circle</mat-icon>
                <span style="margin-left: 5px;">{{showTable ? !!active_batch_id ? ('batch.current_batch'|translate|titlecase) :('batch.create_batch'|translate|titlecase)
                    :'batch.all_batch'|translate|titlecase}}</span> </button>
        </div>
    </mat-card>
    <!-- ---------------------------------------- ----------------------------------->

    <mat-card fxLayout="column" style="height:85%">


        <!-- -----------------------show message is batch list is empty--------------------->

        <div *ngIf="showTable && !dataSource.data.length">
            <app-common-message [message]="'batch.batch_empty_msg'|translate|titlecase">
            </app-common-message>
        </div>

        <!-- ---------------------------------------- ----------------------------------->

        <!-- --------------------batch table------- ----------------------------------->

        <div *ngIf="showTable && !!dataSource.data.length" style="overflow:auto;">
            <table class="level-one-table" mat-table [dataSource]="dataSource">

                <!-- S.No Column -->
                <ng-container matColumnDef="S.NO">
                    <th mat-header-cell *matHeaderCellDef>{{'batch.s_no'|translate|titlecase}}</th>
                    <td mat-cell *matCellDef="let element;let i = index"> {{i+1}} </td>
                </ng-container>

                <!-- Batch Number Column -->
                <ng-container matColumnDef="Batch Number">
                    <th mat-header-cell *matHeaderCellDef>{{'batch.batch_number'|translate|titlecase}} </th>
                    <td mat-cell *matCellDef="let element"> {{element.batch_number}} </td>
                </ng-container>

                <!-- Breed Name Column -->
                <ng-container matColumnDef="Breed Name">
                    <th mat-header-cell *matHeaderCellDef> {{'batch.breed_name'|translate|titlecase}} </th>
                    <td mat-cell *matCellDef="let element"> {{element.breed_name | titlecase}} </td>
                </ng-container>

                <!-- Batch Start Date Column -->
                <ng-container matColumnDef="Batch Start Date">
                    <th mat-header-cell *matHeaderCellDef>{{'batch.batch_start_date'|translate|titlecase}} </th>
                    <td mat-cell *matCellDef="let element"> {{element.start_date |date:'dd-MM-YYYY'}} </td>
                </ng-container>

                <!-- Batch End Date Column -->
                <ng-container matColumnDef="Batch End Date">
                    <th mat-header-cell *matHeaderCellDef>{{'batch.batch_end_date'|translate|titlecase}} </th>
                    <td mat-cell *matCellDef="let element"> {{element.end_date ? (element.end_date |date:'dd-MM-YYYY'):'batch.batch_active' |translate |titlecase}} </td>
                    <!-- {{getEndDate(element.
                        end_date)}}  -->
                </ng-container>

                <!-- Action Column -->
                <ng-container matColumnDef="Action">
                    <th mat-header-cell *matHeaderCellDef>{{'batch.action'|translate|titlecase}} </th>
                    <td mat-cell *matCellDef="let element">
                        <div style="display: flex;">
                            <div style="width: 100%;">
                                <span><mat-icon class="iconvalue" svgIcon="view" (click)="editvalue(element)"
                                        style="margin: 0%;height: 18px;color: #9f9f9f;"></mat-icon></span>
                            </div>
                        </div>
                    </td>
                </ng-container>


                <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky:true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
        </div>
        <!-- ---------------------------------------- ----------------------------------->





        <div *ngIf="!showTable">
            <form [formGroup]="batch_form_group">
                <div fxLayout="column">
                    <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap.lt-sm="10"
                        fxLayoutGap="{{is_batch_started?75:100}}" class="batch-card">
                        <!-- ------------------------batch start form- ----------------------------------->

                        <div fxFlex="20" fxLayout="column" fxLayoutGap="15">
                            <div mat-card-title class="label" style="white-space: nowrap;">
                                {{'batch.starting_details'|translate|titlecase}}
                            </div>
                            <!-- <div></div> -->

                            <div style="font-size: 13px">
                                <mat-form-field appearance="outline" style="pointer-events: none;">
                                    <mat-label>{{'batch.breed_type'|translate|titlecase}}</mat-label>
                                    <input matInput formControlName="breed_type" type="text" [disabled]="true"
                                        autocomplete="new-password">
                                </mat-form-field>
                            </div>
                            <div style="font-size: 13px;">
                                <mat-form-field appearance="outline">
                                    <mat-label>{{'batch.breed_name'|translate|titlecase}}</mat-label>
                                    <mat-select disableOptionCentering formControlName="breed_id"
                                        panelClass="PanelClass">
                                        <ng-container *ngFor="let breed of breed_list">
                                            <mat-option [value]="breed.breed_id">{{breed.breed_name |titlecase}}
                                            </mat-option>
                                        </ng-container>
                                    </mat-select>
                                    <mat-error class="m-t-10">
                                        <span *ngIf="batch_form_group.controls['breed_id'].touched 
                                            && batch_form_group.get('breed_id')?.hasError('required')">
                                            {{'batch.breed_name_msg'|translate|titlecase}}
                                        </span>
                                    </mat-error>
                                </mat-form-field>
                            </div>

                            <div style="font-size: 13px">
                                <mat-form-field appearance="outline">
                                    <mat-label>{{'batch.batch_number'|translate|titlecase}}</mat-label>
                                    <input matInput formControlName="batch_number" type="text" [maxlength]="10"
                                        autocomplete="new-password">
                                    <mat-error class="m-t-10">
                                        <span *ngIf="batch_form_group.controls['batch_number'].touched 
                                                && batch_form_group.get('batch_number')?.hasError('required')">
                                            {{'batch.batch_number_msg'|translate|titlecase}}
                                        </span>
                                    </mat-error>
                                </mat-form-field>
                            </div>

                        </div>

                        <div fxFlex="20" fxLayout="column" fxLayoutGap="15">
                            <div mat-card-title fxHide.lt-sm fxShow.gt-sm class="label"></div>
                            <div fxHide.lt-sm fxShow.gt-sm></div>
                            <div style="font-size: 13px">
                                <mat-form-field appearance="outline">
                                    <mat-label>{{'batch.start_date'|translate|titlecase}}</mat-label>
                                    <div fxLayout="row" style="height: 15px !important;">
                                        <input matInput [matDatepicker]="startDate" [max]="max_date"
                                            formControlName="start_date" [readonly]="true">
                                        <mat-datepicker-toggle
                                            style="position: relative !important; bottom: 16px !important;right: -12px !important;"
                                            matIconSuffix [for]="startDate"></mat-datepicker-toggle>
                                        <mat-datepicker #startDate></mat-datepicker>
                                    </div>
                                    <mat-error class="m-t-10">
                                        <span *ngIf="batch_form_group.controls['start_date'].touched 
                                            && batch_form_group.get('start_date')?.hasError('required')">
                                            {{'batch.start_date_msg'|translate|titlecase}}
                                        </span>
                                    </mat-error>
                                </mat-form-field>
                            </div>

                            <div fxLayout="column">
                                <!-- <div><mat-label>Age</mat-label></div> -->
                                <div fxLayout="row" fxLayoutAlign="space-between center">
                                    <div fxFlex="47" style="font-size: 13px">
                                        <mat-form-field appearance="outline">
                                            <mat-label>{{'batch.weeks'|translate|titlecase}}</mat-label>
                                            <input matInput formControlName="start_age_week"
                                                (keydown.arrowup)="$event.preventDefault()" type="number"
                                                (keydown.arrowdown)="$event.preventDefault()"
                                                (wheel)="$event.preventDefault()"
                                                (keydown)="$event.key === 'e' || $event.key === 'E' || $event.key === '+' || $event.key === '-' || $event.key === '.' ? $event.preventDefault() : ''"
                                                (input)="batch_form_group.get('start_age_week')?.setValue(transformInputValue(batch_form_group.get('start_age_week')?.value))">
                                            <mat-error class="m-t-10 m-b-5">
                                                <span
                                                    *ngIf="batch_form_group.controls['start_age_week'].touched 
                                                            && batch_form_group.get('start_age_week')?.hasError('required')">
                                                    {{'batch.weeks_msg'|translate|titlecase}}
                                                </span>
                                            </mat-error>
                                        </mat-form-field>


                                    </div>
                                    <div fxFlex="47" style="font-size: 13px">
                                        <mat-form-field appearance="outline">
                                            <mat-label>{{'batch.days'|translate|titlecase}}</mat-label>
                                            <input matInput formControlName="start_age_days"
                                                (keydown.arrowup)="$event.preventDefault()"
                                                (keydown.arrowdown)="$event.preventDefault()"
                                                (wheel)="$event.preventDefault()"
                                                (keydown)="$event.key =='e' || $event.key =='E' || $event.key =='+'|| $event.key =='-' ||$event.key == '.'? $event.preventDefault():''"
                                                oninput="this.value=this.value.replace(/[^1-7]/g,'');javascript: if (this.value.length >1) this.value = this.value.slice(0,1);"
                                                type="number">
                                            <mat-error class="m-t-10">
                                                <span
                                                    *ngIf="batch_form_group.controls['start_age_days'].touched 
                                                        && batch_form_group.get('start_age_days')?.hasError('required')">
                                                    {{'batch.days_msg'|translate|titlecase}}
                                                </span>
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                </div>

                            </div>

                            <div style="font-size: 13px">
                                <mat-form-field appearance="outline">
                                    <mat-label>{{birdtype!='fish'?('batch.shrimp_count'|translate|titlecase):('batch.fish_count'|translate|titlecase)}}</mat-label>
                                    <input matInput formControlName="start_fish_count"
                                        (keydown.arrowup)="$event.preventDefault()"
                                        (keydown.arrowdown)="$event.preventDefault()" (wheel)="$event.preventDefault()"
                                        (keydown)="$event.key =='e' || $event.key =='E' || $event.key =='+'|| $event.key =='-' ||$event.key == '.'? $event.preventDefault():''"
                                        oninput="this.value=this.value.replace(/[^0-9]/g,'');javascript: if (this.value.length >8) this.value = this.value.slice(0,8);"
                                        type="number" autocomplete="new-password" [required]="true">
                                    <mat-error class="m-t-10">
                                        <span *ngIf="batch_form_group.controls['start_fish_count'].touched 
                                                && batch_form_group.get('start_fish_count')?.hasError('required')">
                                            {{birdtype=='fish'?('batch.fish_count_msg'|translate|titlecase):'batch.shrimp_count_msg'|translate|titlecase}}
                                        </span>
                                    </mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                        <!-- ---------------------------------------- ----------------------------------->

                        <!-- -------------------------Batch end form-- ----------------------------------->
                        <form [formGroup]="batch_end_group" fxFlex="20" *ngIf="is_batch_started">
                            <div *ngIf="is_batch_started" fxLayout="column" fxLayoutGap="15">
                                <div mat-card-title class="label" style="white-space: nowrap;">
                                    {{'batch.current_details'|translate|titlecase}}
                                </div>
                                <div style="font-size: 13px;position: relative;">
                                    <mat-form-field appearance="outline">
                                        <mat-label>{{'batch.date'|translate|titlecase}}</mat-label>
                                        <div fxLayout="row" style="height: 15px !important;">
                                            <input matInput [min]="end_min_date" [matDatepicker]="endDate"
                                                formControlName="end_date" autocomplete="new-password"
                                                [readonly]="true">
                                            <mat-datepicker-toggle
                                                style="position: relative !important; bottom: 16px !important;right: -12px !important;"
                                                matIconSuffix [for]="endDate"></mat-datepicker-toggle>
                                            <mat-datepicker #endDate></mat-datepicker>
                                        </div>

                                    </mat-form-field>
                                    <div style="display: flex;align-items: center;position: absolute;top: 35%;right: -15%;"
                                        class="mobviewbatch" *ngIf="!isStarted">
                                        <mat-icon class="erroricon" [matMenuTriggerFor]="redirectmenu3">
                                            info
                                        </mat-icon>
                                    </div>

                                    <mat-menu #redirectmenu3="matMenu" xPosition="before" yPosition="above">
                                        <div style="padding: 15px;" fxLayout="column" fxLayoutGap="5">
                                            <div>{{'batch.batch_start_from'|translate|titlecase}}
                                                {{enddate | date:'dd-MMM-YYYY'}}
                                            </div>
                                        </div>
                                    </mat-menu>
                                </div>

                                <div fxLayout="column">
                                    <!-- <div><mat-label>Age</mat-label></div> -->
                                    <div fxLayout="row" fxLayoutAlign="space-between center">
                                        <div fxFlex="47" style="font-size: 13px">
                                            <mat-form-field appearance="outline">
                                                <mat-label>{{'batch.weeks'|translate|titlecase}}</mat-label>
                                                <input matInput formControlName="end_age_week"
                                                    (keydown.arrowup)="$event.preventDefault()"
                                                    (keydown.arrowdown)="$event.preventDefault()"
                                                    (wheel)="$event.preventDefault()"
                                                    (keydown)="$event.key =='e' || $event.key =='E' || $event.key =='+'|| $event.key =='-' ||$event.key == '.'? $event.preventDefault():''"
                                                    oninput="this.value= this.value.replace(birdtype =='broiler' ? /[^0-9]/g : /[^1-9]/g,'');javascript: if (this.value.length >3) this.value = this.value.slice(0,3);"
                                                    type="number">
                                            </mat-form-field>
                                        </div>
                                        <div fxFlex="47" style="font-size: 13px">
                                            <mat-form-field appearance="outline">
                                                <mat-label>{{'batch.days'|translate|titlecase}}</mat-label>
                                                <input matInput formControlName="end_age_days" type="text"
                                                    [maxlength]="1" autocomplete="new-password"
                                                    onkeypress="return event.charCode >= 49 && event.charCode <= 55">
                                            </mat-form-field>
                                        </div>
                                    </div>

                                </div>
                                <div style="font-size: 13px;position: relative;">
                                    <mat-form-field appearance="outline" style="margin-right: 10px;">
                                        <mat-label>{{birdtype=='fish'?('batch.fish_count'|translate|titlecase):'batch.shrimp_count'|translate|titlecase}}</mat-label>
                                        <input matInput formControlName="end_female_count" type="number"
                                            autocomplete="new-password">
                                    </mat-form-field>
                                    <div style="display: flex;align-items: center;position: absolute;top: 35%;right: -15%;"
                                        class="mobviewbatch" *ngIf="showerror">
                                        <mat-icon class="erroricon" [matMenuTriggerFor]="redirectmenu">
                                            info
                                        </mat-icon>
                                    </div>
                                </div>
                                <mat-menu #redirectmenu="matMenu" xPosition="before" yPosition="above">
                                    <div style="padding: 15px;" fxLayout="column" fxLayoutGap="5">
                                        <div>{{'batch.transfer_in'|translate|titlecase}} :
                                            {{batchData?(batchData.fish_transfer_in_count!=null?batchData.fish_transfer_in_count:0):0}}
                                        </div>
                                        <div>{{'batch.transfer_out'|translate|titlecase}} :
                                            {{batchData?(batchData.fish_transfer_out_count!=null?batchData.fish_transfer_out_count:0):0}}
                                        </div>
                                        <div>{{'batch.mortality'|translate|titlecase}} :
                                            {{batchData?(batchData.fish_mortality_count!=null?batchData.fish_mortality_count:0):0}}
                                        </div>
                                        <div>{{'batch.sold'|translate|titlecase}} :
                                            {{batchData?(batchData.fish_sold_count!=null?batchData.fish_sold_count:0):0}}
                                        </div>
                                    </div>
                                </mat-menu>

                                <div style="font-size: 13px;position: relative;" *ngIf="birdtype=='Breeder'">
                                    <mat-form-field appearance="outline" style="margin-right: 10px;">
                                        <mat-label>{{'batch.male_count'|translate|titlecase}}</mat-label>
                                        <input matInput formControlName="end_male_count" type="number"
                                            autocomplete="new-password">
                                    </mat-form-field>
                                    <div style="display: flex;align-items: center;position: absolute;top: 35%;right: -15%;"
                                        class="mobviewbatch" *ngIf="showerror">
                                        <mat-icon class="erroricon" [matMenuTriggerFor]="redirectmenu2">
                                            info
                                        </mat-icon>
                                    </div>
                                </div>
                                <mat-menu #redirectmenu2="matMenu" xPosition="before" yPosition="above">
                                    <div style="padding: 15px;" fxLayout="column" fxLayoutGap="5">
                                        <div>{{'batch.transfer_in'|translate|titlecase}} :
                                            {{batchData?(batchData.male_transfer_in_count!=null?batchData.male_transfer_in_count:0):0}}
                                        </div>
                                        <div>{{'batch.transfer_out'|translate|titlecase}} :
                                            {{batchData?(batchData.male_transfer_out_count!=null?batchData.male_transfer_out_count:0):0}}
                                        </div>
                                        <div>{{'batch.mortality'|translate|titlecase}} :
                                            {{batchData?(batchData.male_mortality_count!=null?batchData.male_mortality_count:0):0}}
                                        </div>
                                        <div>{{'batch.sold'|translate|titlecase}} :
                                            {{batchData?(batchData.male_sold_count!=null?batchData.male_sold_count:0):0}}
                                        </div>
                                    </div>
                                </mat-menu>
                            </div>
                        </form>
                        <!-- ---------------------------------------- ----------------------------------->

                        <!-- <div style="color: gray;font-size: 11px;position: absolute;left: 75%;bottom:0px;display: flex;align-items: center;color: #c0c0c0;
                        white-space: nowrap;" *ngIf="is_batch_started"
                         class="dateposition">
                            <mat-icon class="clockicon" svgIcon="clock"
                                                style="margin: 0%;height: 16px;width: 14px;margin-right: 5px;"></mat-icon>
                            Last Updated on {{lastUpdated | date:'dd-MMM-YYYY'}} @{{lastUpdated | date:'shortTime'}} </div> -->
                    </div>

                    <div *ngIf="access?.create && !access?.view" style="margin-top: 10px;" fxLayout="row"
                        fxLayoutAlign="center center" fxLayoutGap="25px">
                        <button class="submit grow" mat-stroked-button (click)="submit()">{{
                            is_batch_started ?('batch.end_batch'|translate|titlecase)
                            :'batch.start_batch'|translate|titlecase}}</button>
                        <button *ngIf="batch_form_group.touched ||batch_end_group.touched " class="cancel grow"
                            mat-stroked-button (click)="clearAll()">{{'button.clear'|translate|titlecase}}</button>

                    </div>

                </div>
            </form>
        </div>




    </mat-card>

</div>