import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { REGEX } from '../../../shared/helpers/validators';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { Subscription } from 'rxjs';
import { CryptoService } from 'src/app/core/services/crypto/crypto.service';
@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit, OnDestroy {
  public subscription: Subscription[] = [];
  public hide = true;
  public hide1 = true;
  public restFromGroup!: FormGroup;
  private auth_data: any;
  constructor (
    private fb: FormBuilder,
    private route: Router,
    private AuthService: AuthService,
    private crypto: CryptoService
  ) {
    if (localStorage.getItem('auth_data')) {
      this.auth_data = this.crypto.decrypt(localStorage.getItem('auth_data') || '');
    }
    if (!!!this.auth_data?.auth_id || this.crypto.decrypt(localStorage.getItem('otp_validate') || '') !== 'done') {
      this.route.navigateByUrl('/auth/forgot-password');
      return;
    }
  }

  ngOnInit (): void {
    this.restFromGroup = this.fb.group({
      password: new FormControl('', [Validators.required, Validators.pattern(REGEX.PASSWORD)]),
      comfirm_password: new FormControl('', [Validators.required])
    });

  }

  /**------------------------------------------------------------------------------------------------- */

  /** Validates if password and confirm_password fields have matching values 
 * and updates the error status accordingly. */
  passwordMatchValidator = () => {

    if (!(this.restFromGroup?.controls['password']?.value === this.restFromGroup?.controls['comfirm_password']?.value)) {
      this.restFromGroup?.controls['comfirm_password'].setErrors({ ...this.restFromGroup?.controls['comfirm_password'].errors, 'mismatch': true });
    }
    else {
      const error: any = this.restFromGroup?.controls['comfirm_password']?.errors;
      if (error) {
        delete error.mismatch;
        Object.keys(error).length
          ? this.restFromGroup?.controls['comfirm_password'].setErrors({ error })
          : this.restFromGroup?.controls['comfirm_password'].setErrors(null);
      }
    }

  };

  /**------------------------------------------------------------------------------------------------- */

  /**------------------------------------------------------------------------------------------------- */
  /** Submits password reset form and sends request to change the password. */
  /** Navigates to '/info' page on success and removes the 'email' from local storage. */

  submit () {
    if (this.restFromGroup.invalid) {
      this.restFromGroup.markAllAsTouched();
      return;
    }
    let resetpasswordObj = new resetpassword();
    resetpasswordObj.user_id = this.auth_data?.auth_id;
    resetpasswordObj.password = this.restFromGroup.value['password'];
    resetpasswordObj.otp = this.auth_data.otp;

    this.AuthService.changePassword(resetpasswordObj).subscribe((res: any) => {
      this.route.navigateByUrl('/info');
      localStorage.clear();
    }, (error: Response) => {
      console.log(error);
    });

  }
  /**------------------------------------------------------------------------------------------------- */

  /**------------------------------------------------------------------------------------------------- */

  /** Toggles the visibility of password or confirm_password field based on the label. */
  /** Sets 'hide' or 'hide1' value based on the field's length. */

  showHidePassword (label: any) {
    switch (true) {
      case label == 'password':
        if (this.restFromGroup.controls['password'].value?.length) {
          this.hide = !this.hide;
        } else {
          this.hide = true;
        }
        break;
      case label == 'comfirm_password':
        if (this.restFromGroup.controls['comfirm_password'].value?.length) {
          this.hide1 = !this.hide1;
        } else {
          this.hide1 = true;
        }
        break;

      default:
        break;
    }

  }
  /**------------------------------------------------------------------------------------------------- */

  /**------------------------------------------------------------------------------------------------- */

  /** Hides the password field if it has no value. */
  /** Hides the confirm_password field if it has no value. */

  hidePassword (label: any) {
    switch (true) {
      case label == 'password':
        if (!!!this.restFromGroup.controls['password'].value?.length) {
          this.hide = true;
        }
        break;
      case label == 'comfirm_password':
        if (!!!this.restFromGroup.controls['comfirm_password'].value?.length) {
          this.hide1 = true;
        }
        break;

      default:
        break;
    }


  }
  /**------------------------------------------------------------------------------------------------- */

  onFocusConfirmPassword () {
    this.restFromGroup.controls['comfirm_password'].markAllAsTouched();
  }

  ngOnDestroy (): void {
    localStorage.clear();
  }
}
export class resetpassword {
  user_id: string = '';
  password: string = '';
  otp: string = '';
}
