import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { MessageService } from 'src/app/core/services/message/message.service';
import { MortalityService } from 'src/app/core/services/mortality/mortality.service';
import { ScoreDialogComponent } from 'src/shared/score-dialog/score-dialog.component';

@Component({
  selector: 'app-create-mortality-score',
  templateUrl: './create-mortality-score.component.html',
  styleUrls: ['./create-mortality-score.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CreateMortalityScoreComponent implements OnInit {
  public mortality_score_group: any;
  isupdate: boolean = false;
  public shed_id = localStorage.getItem('tank')?.length ? JSON.parse(localStorage.getItem('tank') || '')?.id : null;
  public header_group: any;
  isview = false;
  mortalityperBird: any = new FormArray([]);
  dataSource1 = new MatTableDataSource([]);
  cumulativemortalityperBird: any = new FormArray([]);
  dataSource2 = new MatTableDataSource([]);
  displayedColumns = [
    "Threshold",
    "mortalityfemale",
    "mortalityfemalescore",
    "mortalitymale",
    "mortalitymalescore"
  ];
  public subscriptions: Subscription[] = [];
  @Output() dataReceive = new EventEmitter<any>();
  @Input('mortalitydata') mortalitydata: any;
  public remove_ids: Array<number> = [];
  public company_name = localStorage.getItem('company')?.length ? JSON.parse(localStorage.getItem('company') || '')?.name : '--';
  public shed_name = localStorage.getItem('tank')?.length ? JSON.parse(localStorage.getItem('tank') || '')?.name : '--';

  constructor(private fb: FormBuilder, private show_msg: MessageService, private dialog: MatDialog, private translate: TranslateService,
    private service: MortalityService) { }

  ngOnInit(): void {
    this.mortalitydata?.data?.length ? this.isupdate = true : this.isupdate = false;
    // to move max limit (10101) to the end of an array
    if (this.mortalitydata?.data?.length) {
      const max_data = this.mortalitydata.data?.find(((max: Record<string, number>) => Number(max['f_mortality_expected']) == 10101 && Number(max['cumulative_m_mortality_expected']) == 10101));
      this.mortalitydata.data = this.mortalitydata.data?.filter(((max: Record<string, number>) => Number(max['f_mortality_expected']) !== 10101 && Number(max['cumulative_m_mortality_expected']) !== 10101));
      this.mortalitydata.data?.push(max_data);
    }
    if (!this.isupdate) {
      this.headerLoad();
      this.initialthreshold();
    };
    if (this.isupdate) this.isview = this.mortalitydata.screen == 'view' ? true : false;
    if (this.isupdate) this.mortalityUpdate(this.mortalitydata.data);
    this.getMortalityScore();
  }
  /**
   * form group for age in weeks
   */
  headerLoad() {
    this.header_group = this.fb.group({
      age_In_Week: new FormControl('', [Validators.required])
    });
  }
  /**
   * form group for female and male mortality score and value
   * @returns 
   */
  initForm() {
    return this.fb.group({
      threshold_sign_1: new FormControl('<'),
      f_mortality_expected: new FormControl('', [Validators.required]),
      f_mortality_weightage: new FormControl('0', [Validators.required]),
      threshold_sign_2: new FormControl('<'),
      cumulative_f_mortality_expected: new FormControl('', [Validators.required]),
      cumulative_f_mortality_weightage: new FormControl('0', [Validators.required]),
      threshold_sign_3: new FormControl('<'),
      m_mortality_expected: new FormControl('', [Validators.required]),
      m_mortality_weightage: new FormControl('0', [Validators.required]),
      threshold_sign_4: new FormControl('<'),
      cumulative_m_mortality_expected: new FormControl('', [Validators.required]),
      cumulative_m_mortality_weightage: new FormControl('0', [Validators.required])
    });
  }
  /**
   * set values during update and view screen
   * @param score 
   */
  mortalityUpdate(score: any) {
    this.header_group = this.fb.group({
      age_In_Week: new FormControl(score[0].age, [Validators.required])
    });
    score.filter((m: any) => {
      if (m.f_mortality_expected != null) this.mortalityperBird.push(this.setvalueupdate1(m));
      if (m.cumulative_f_mortality_expected != null) this.cumulativemortalityperBird.push(this.setvalueupdate2(m));
    });

    this.dataSource1.data = this.mortalityperBird.controls;
    this.dataSource2.data = this.cumulativemortalityperBird.controls;
    if (this.isupdate && !this.isview) {
      this.header_group.get('age_In_Week')?.disable();
    }
  }
  /**
   * sub-method for mortalityUpdate to set values for mortality per Bird during update
   * @param score 
   * @returns 
   */
  setvalueupdate1(score: any) {
    return new FormGroup({
      mortality_female: new FormControl(score.f_mortality_expected, [Validators.required]),
      mortality_female_score: new FormControl(score.f_mortality_max_score, [Validators.required]),
      mortality_male: new FormControl(score.m_mortality_expected, [Validators.required]),
      mortality_male_score: new FormControl(score.m_mortality_max_score),
      id: new FormControl(score.id)

    });
  }
  /**
   * sub-method for mortalityUpdate to set values for cumulative mortality per Bird during update
   * @param score 
   * @returns 
   */
  setvalueupdate2(score: any) {
    return new FormGroup({
      mortality_female: new FormControl(score.cumulative_f_mortality_expected, [Validators.required]),
      mortality_female_score: new FormControl(score.cumulative_f_mortality_max_score, [Validators.required]),
      mortality_male: new FormControl(score.cumulative_m_mortality_expected, [Validators.required]),
      mortality_male_score: new FormControl(score.cumulative_m_mortality_max_score),
      id: new FormControl(score.id)

    });
  }
  /**
   * form group for female and male mortality score and value
   * @returns 
   */
  setvalue() {
    return new FormGroup({
      mortality_female: new FormControl(null, [Validators.required]),
      mortality_female_score: new FormControl(0, [Validators.required]),
      mortality_male: new FormControl(null, [Validators.required]),
      mortality_male_score: new FormControl(0),
    });
  }
  /**
   * get control name for mortality score threshold table
   * @param index 
   * @param controlName 
   * @param array 
   * @returns 
   */
  getControl(index: number, controlName: string, array: FormArray): FormControl {
    return (array.at(index) as FormGroup).get(controlName) as FormControl;
  }
  /**
   * get value for mortality score threshold table
   * @param controlName 
   * @param formGroup 
   * @returns 
   */
  givevalue(controlName: string, formGroup: FormGroup) {
    return formGroup.get(controlName)?.value;
  }

  deleteScore = () => {
    const req_obj = {
      ids: [...new Set(this.remove_ids)]
    };
    this.subscriptions.push(this.service.deleteMortality(req_obj).subscribe((res: any) => {
    }, (error: Response) => {
      console.log(error);
    }));
  };
  /**
   * add new threshold for particular score table
   * @param source 
   * @param array 
   * @param index 
   */
  // addSensor (index: any) {
  //   if (this.mortalityperBird.length <= 9 && this.cumulativemortalityperBird.length <= 9) {
  //     this.mortalityperBird.insert(index + 1, this.setvalue());
  //     this.cumulativemortalityperBird.insert(index + 1, this.setvalue());
  //   } else {
  //     this.show_msg.errorSnackBar("mortality_score.reached_max_threshold");
  //   }
  //   this.dataSource1.data = this.mortalityperBird.controls as never[];
  //   this.dataSource2.data = this.cumulativemortalityperBird.controls as never[];
  // }
  addSensor(source: MatTableDataSource<never>, array: FormArray, index: any) {
    if (array.length <= 9) {
      array.insert(index + 1, this.setvalue());
    } else {
      this.show_msg.errorSnackBar("mortality_score.reached_max_threshold");
    }
    source.data = array.controls as never[];
  }
  /**
   * remove selected threshold from particular threshold table
   * @param index 
   * @param source 
   * @param array 
   */
  removeSensor(index: any, source: MatTableDataSource<never>, array: FormArray) {
    array.value[index]?.id ? this.remove_ids.push(array.value[index]?.id) : null;
    array.removeAt(index);
    source.data = array.controls as never[];
  }
  /**
   * add necessary thresholds in tables when component initialized
   */
  initialthreshold() {
    for (var i = 0; i <= 4; i++) {
      this.mortalityperBird.push(this.setvalue());
      this.cumulativemortalityperBird.push(this.setvalue());
    }
    this.dataSource1.data = this.mortalityperBird.controls;
    this.dataSource2.data = this.cumulativemortalityperBird.controls;
  }
  tabledata: any[] = [];
  /**
   * get mortality score values api
   */
  getMortalityScore() {
    this.subscriptions.push(this.service.getMortalityScore().subscribe((res: any) => {
      if (res.data?.length) {
        this.tabledata = res.data;
      }
    }));
  }
  /**
   * info popup to show maximum score value in table
   * @param array 
   * @param controlName 
   */
  info(array: FormArray, controlName: string) {
    var maxval = 0;
    var attain = false;
    array.controls.filter((a: any) => {
      var index = array.controls.indexOf(a);
      if (a.value[controlName] != '' && index != (array.controls.length - 1)) {
        maxval = parseFloat(a.value[controlName]) > maxval ? (parseFloat(a.value[controlName])) : maxval;
        attain = true;
      }
    });
    const config: MatDialogConfig = {
      panelClass: 'dialog-responsive-info',
      width: '40%',
      minWidth: '40%',
      disableClose: true,
      data: {
        title: `${attain ? maxval : ''}`,
        isDelete: true
      },
    };
    const openDialog = this.dialog.open(ScoreDialogComponent, config);
    this.subscriptions.push(openDialog.afterClosed().subscribe((result: any) => {
    }));
  }
  /**
   * check weather submit or update according to new score or existing score
   * @returns 
   */
  submit() {
    if (!this.isupdate) {
      var agelist = this.tabledata.filter((m) => (m.age == this.header_group.controls.age_In_Week.value));
      if (agelist.length > 0) {
        return this.show_msg.errorSnackBar('mortality_score.age_in_week_alreay_exists');
      }
    }
    var array = [this.mortalityperBird, this.cumulativemortalityperBird];
    var data = this.verify(array);
    if (data || this.header_group.invalid) {
      return this.show_msg.errorSnackBar('no_empty_field');
    } if (!(this.mortalityperBird.length == this.cumulativemortalityperBird.length)) {
      this.show_msg.errorSnackBar('thresholds mismatch');
      return;
    }
    var maxth = array.sort((a: any, b: any) => b.length - a.length)[0].length;
    var obj = this.createSaveobj(maxth);
    if (!this.isupdate) this.save(obj);
    if (this.isupdate) this.update(obj);
    if (this.remove_ids.length)
      this.deleteScore();
  }
  /**
   * verify where all fields are filled
   * @param array 
   * @returns 
   */
  verify(array: any[]) {
    var data = false;
    array.filter((m: any) => {
      for (var i = 0; i <= m.controls.length - 2; i++) {
        if (m.controls[i].status == 'INVALID') {
          data = true;
        }
      }
    });
    return data;
  }
  /**
   * create object for save api
   * @param num 
   * @returns 
   */
  createSaveobj(num: any) {
    var datalist: any = [];
    for (var i = 0; i < num - 1; i++) {
      var data: any = {};
      data['shed_id'] = localStorage.getItem('tank')?.length ? JSON.parse(localStorage.getItem('tank') || '')?.id : null;
      data['age'] = this.header_group.controls.age_In_Week.value;
      if (i < this.mortalityperBird.length - 1) {
        data['f_mortality_expected'] = this.mortalityperBird.controls[i].controls.mortality_female.value != '' ? this.mortalityperBird.controls[i].controls.mortality_female.value : null;
        data['f_mortality_max_score'] = this.mortalityperBird.controls[i].controls.mortality_female_score.value;
        data['m_mortality_expected'] = this.mortalityperBird.controls[i].controls.mortality_male.value != '' ? this.mortalityperBird.controls[i].controls.mortality_male.value : null;
        data['m_mortality_max_score'] = this.mortalityperBird.controls[i].controls.mortality_male_score.value;
      }
      if (i < this.cumulativemortalityperBird.length - 1) {
        data['cumulative_f_mortality_expected'] = this.cumulativemortalityperBird.controls[i].controls.mortality_female.value != '' ? this.cumulativemortalityperBird.controls[i].controls.mortality_female.value : null;
        data['cumulative_f_mortality_max_score'] = this.cumulativemortalityperBird.controls[i].controls.mortality_female_score.value;
        data['cumulative_m_mortality_expected'] = this.cumulativemortalityperBird.controls[i].controls.mortality_male.value != '' ? this.cumulativemortalityperBird.controls[i].controls.mortality_male.value : null;
        data['cumulative_m_mortality_max_score'] = this.cumulativemortalityperBird.controls[i].controls.mortality_male_score.value;
      }
      data['id'] = this.cumulativemortalityperBird.controls[i].controls?.id?.value;
      datalist.push(data);
    }
    var data1: any = {};
    data1['shed_id'] = localStorage.getItem('tank')?.length ? JSON.parse(localStorage.getItem('tank') || '')?.id : null;
    data1['age'] = this.header_group.controls.age_In_Week.value;
    data1['f_mortality_expected'] = 10101;
    data1['f_mortality_max_score'] = this.mortalityperBird.controls[this.mortalityperBird.length - 1].controls.mortality_female_score.value;
    data1['m_mortality_expected'] = 10101;
    data1['m_mortality_max_score'] = this.mortalityperBird.controls[this.mortalityperBird.length - 1].controls.mortality_male_score.value;
    data1['cumulative_f_mortality_expected'] = 10101;
    data1['cumulative_f_mortality_max_score'] = this.cumulativemortalityperBird.controls[this.cumulativemortalityperBird.length - 1].controls.mortality_female_score.value;
    data1['cumulative_m_mortality_expected'] = 10101;
    data1['cumulative_m_mortality_max_score'] = this.cumulativemortalityperBird.controls[this.cumulativemortalityperBird.length - 1].controls.mortality_male_score.value;
    data1['id'] = this.cumulativemortalityperBird.controls[this.cumulativemortalityperBird.length - 1]?.controls?.id?.value;
    datalist.push(data1);
    return datalist;
  }
  /**
   * update if its exesting score not developed yet
   */
  update(data: any) {
    const reqObj = {
      data
    };

    this.subscriptions.push(this.service.updateMortality(reqObj).subscribe((res: any) => {
      this.dataReceive.emit(false);
    }));

  }
  /**
   * save if its new score
   * @param save 
   */
  save(save: any) {
    var saveReq: any = {
      data: save
    };
    this.subscriptions.push(this.service.postMortality(saveReq).subscribe((res: any) => {
      this.dataReceive.emit(false);
    }));
  }
  /**
   * clear array and value from create to list component 
   */
  clear() {
    this.dataReceive.emit(false);
  }

  validateThresholdValue(i: number, control_name: string, form_array: FormArray) {
    if (i == 0) {
      const current_value = form_array.at(i).get(control_name)?.value;
      const next_value = form_array.at(i + 1).get(control_name)?.value;
      if ((current_value >= next_value) && next_value != null) {
        form_array.at(i).get(control_name)?.reset();
        this.subscriptions.push(
          this.translate.get('mortality_score.must_be_less_than').subscribe((translation) => {
            this.show_msg.errorSnackBar(`${translation} ${next_value}`);
          })
        );
      }
    }
    if (i > 0) {
      const current_value = form_array.at(i).get(control_name)?.value;
      const previous_value = form_array.at(i - 1).get(control_name)?.value;
      const next_value = form_array.at(i + 1).get(control_name)?.value;
      if (current_value || current_value == 0) {
        if (previous_value == null) {
          form_array.at(i).get(control_name)?.reset();
          this.subscriptions.push(
            this.translate.get('mortality_score.enter_threshold_value').subscribe((translation) => {
              this.show_msg.errorSnackBar(`${translation} ${(i + 1) - 1}`);
            })
          );
          return;
        }
        if (previous_value >= current_value) {
          form_array.at(i).get(control_name)?.reset();
          this.subscriptions.push(
            this.translate.get('mortality_score.must_be_greater_than').subscribe((translation) => {
              this.show_msg.errorSnackBar(`${translation} ${previous_value}`);
            })
          );
          return;
        }
        if ((next_value <= current_value) && (next_value != null && Number(next_value) != 10101)) {
          form_array.at(i).get(control_name)?.reset();
          this.subscriptions.push(
            this.translate.get('mortality_score.must_be_less_than').subscribe((translation) => {
              this.show_msg.errorSnackBar(`${translation} ${next_value}`);
            })
          );
        }
      }

    }
  }
  ngOnDestroy() {
    this.subscriptions.forEach((sub: Subscription) => sub.unsubscribe());
  }
}
