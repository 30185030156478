import { Directive, ElementRef, HostListener, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appSanitize]'
})
export class SanitizeDirective {

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  @HostListener('input', ['$event']) onInput(event: Event): void {
    const inputElement = this.el.nativeElement as HTMLInputElement;
    const inputValue = inputElement.value;

    // Your logic to restrict certain characters
    const restrictedCharacters = /.*/g;
    const sanitizedValue = inputValue.replace(restrictedCharacters, '');

    // Update the input value
    this.renderer.setProperty(inputElement, 'value', sanitizedValue);
  }

}
