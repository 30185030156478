import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_LOCALE, MAT_DATE_FORMATS } from '@angular/material/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { BatchService } from 'src/app/core/services/batch/batch.service';
import { HomeService } from 'src/app/core/services/home/home.service';
import { MessageService } from 'src/app/core/services/message/message.service';
import { MessageDialogComponent } from 'src/shared/message-dialog/message-dialog.component';

const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'DD-MM-YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};
@Component({
  selector: 'app-medication',
  templateUrl: './medication.component.html',
  styleUrls: ['./medication.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class MedicationComponent implements OnInit, OnDestroy {
  public subscriptions: Subscription[] = [];
  public current_time: any;
  public medication_list: any = [];
  isUpdate = false;

  public medication_group: any = new FormArray([]);

  public company_name = localStorage.getItem('company')?.length ? JSON.parse(localStorage.getItem('company') || '')?.name : '--';
  public shed_data = localStorage.getItem('tank')?.length ? JSON.parse(localStorage.getItem('tank') || '') : '--';


  public showTable = true;

  public is_add_medicaiton !: number;
  public add_medication_control: FormControl = new FormControl(null, [Validators.required]);

  public min_date: any;
  displayedColumns: string[] = ['S.NO', 'age in week', 'age in day', 'medication name', 'dose', 'Due Date', 'type', 'company', 'Action'];
  dataSource = new MatTableDataSource([]);
  public access: any;
  public active_batch: object | any;
  public medication_index!: number;

  constructor(private fb: FormBuilder,
    private route: Router,
    private service: BatchService,
    private msg: MessageService,
    private dialog: MatDialog,
    private home: HomeService

  ) {
    this.access = this.home.getAccess(16);

  }

  ngOnInit(): void {
    this.medication_group.push(this.medicationFromGroupFn());
    this.getMedication();
    this.getAllMedicationSchedule();

    this.subscriptions.push(
      this.service.getActiveBatch().subscribe((res: any) => {
        if (res?.data[0]?.batch_id) {
          this.active_batch = res.data[0];
          this.min_date = res?.data[0]?.start_date;
        }
      })
    );


  }

  medicationFromGroupFn() {
    return this.fb.group({
      tank_id: new FormControl(JSON.parse(localStorage.getItem('tank') || '')?.id, [Validators.required]),
      batch_id: new FormControl(localStorage.getItem('batch_id'), [Validators.required]),
      age_in_week: new FormControl('', [Validators.required]),
      age_in_day: new FormControl('', [Validators.required]),
      medication_id: new FormControl('', [Validators.required]),
      remark_1: new FormControl(''),
      remark_2: new FormControl(''),
      remark_3: new FormControl(''),
      dose: new FormControl('', [Validators.required]),
      company: new FormControl('', [Validators.required]),
      due_date: new FormControl('', [Validators.required]),
    });

  }

  medicationFromGroupUpdate(data: any) {
    return this.fb.group({
      tank_id: new FormControl(JSON.parse(localStorage.getItem('tank') || '')?.id, [Validators.required]),
      batch_id: new FormControl(localStorage.getItem('batch_id'), [Validators.required]),
      age_in_week: new FormControl(data.age_in_week, [Validators.required]),
      age_in_day: new FormControl(data.age_in_day, [Validators.required]),
      medication_id: new FormControl(data.medication_id, [Validators.required]),
      remark_1: new FormControl(data.remark_1),
      remark_2: new FormControl(data.remark_2),
      remark_3: new FormControl(data.remark_3),
      dose: new FormControl(data.dose, [Validators.required]),
      company: new FormControl(data.company, [Validators.required]),
      due_date: new FormControl(data.due_date, [Validators.required,]),
    });

  }


  addMedicationDialog() {

  }

  getMedication() {
    this.subscriptions.push(
      this.service.getMedication().subscribe((res: any) => {
        if (res?.data) {
          res.data.sort((a: any, b: any) => a.medication_name.localeCompare(b.medication_name));;
          this.medication_list = res.data;
        }
      })
    );

  }

  tabledata: any[] = [];
  getAllMedicationSchedule() {
    this.subscriptions.push(
      this.service.getAllMedicationSchedule().subscribe((res: any) => {
        this.dataSource.data = [];
        if (res) {
          this.dataSource.data = res;
          this.tabledata = res;
        }
      })
    );

  }

  /*-- ---------------------------------------- -----------------------------------*/
  /**
 * Handles the click event for the schedule button.
Checks if there is an active batch and displays an error message if not.
Toggles the visibility of the table and resets the medication schedule form.
 */

  onClickSchedule() {
    const active_batch_id = !!localStorage.getItem('batch_id');
    if (!active_batch_id) {
      this.msg.errorSnackBar('no_active_batch');
    } else {
      this.showTable = !this.showTable;
    }
    this.isUpdate = false;
    this.medication_group.controls = [];
    this.medication_group.push(this.medicationFromGroupFn());
    this.medicationSchadulevalue = undefined;
  }
  /*-- ---------------------------------------- -----------------------------------*/

  /*-- ---------------------------------------- -----------------------------------*/

  /**
   * Shows a warning message for invalid or empty values in a form array,
   *iterating through each form group and its controls to identify missing values.
   */

  showFormArrayWarning(form_array: any) {
    let index = 1;
    form_array.controls.forEach((form_group: FormGroup, i: any) => {
      Object.entries(form_group.controls).forEach(([key, value]) => {
        if (value.invalid && !value.value?.length && index) {
          index = 0;
          key = key == 'medication_id' ? 'medicine_name' : key;
          this.msg.errorSnackBar(`${key.replace(/_/g, " ")}`);
        }
      });
    });

  }

  /*-- ---------------------------------------------------- -----------------------------------*/

  /*-- ---------------------------------------------------- -----------------------------------*/

  /**Updates the medication form data.
If the form is invalid, shows a warning message for missing values in the form array and returns.
Calls the updateMedication() service method with the updated medication data and the scheduled ID.
Updates the medication schedule table, clears the form, and displays the table.
If the response contains data, updates the medication list.
Resets the update flag and clears the medication schedule value.
*/
  update() {
    if (this.medication_group.invalid) {
      this.showFormArrayWarning(this.medication_group);
      return;
    }
    this.subscriptions.push(
      this.service.updateMedication(this.medication_group.value[0], this.medicationSchadulevalue.scheduled_id).subscribe((res: any) => {
        this.getAllMedicationSchedule();
        this.clearAll();
        this.showTable = true;
        if (res?.data) {
          this.medication_list = res.data;
        }
        this.isUpdate = false;
        this.medicationSchadulevalue = undefined;
      })
    );
  }

  /*-- ---------------------------------------------------- -----------------------------------*/


  /*-- ---------------------------------------------------- -----------------------------------*/

  /**Saves the medication form data.
If the form is invalid, shows a warning message for missing values in the form array and returns.
Constructs the data object using the values from the medication form group.
Calls the submitMedication() service method to submit the medication data.
Updates the medication schedule table, clears the form, and displays the table.
If the response contains data, updates the medication list.
*/

  save() {
    if (this.medication_group.invalid) {
      this.showFormArrayWarning(this.medication_group);
      return;
    }
    const data = {
      data: this.medication_group.value
    };
    this.subscriptions.push(
      this.service.submitMedication(data).subscribe((res: any) => {
        this.getAllMedicationSchedule();
        this.clearAll();
        this.showTable = true;
        if (res?.data) {
          this.medication_list = res.data;
        }

      })
    );
  }

  /*-- ---------------------------------------------------- -----------------------------------*/


  /*-- ---------------------------------------------------- -----------------------------------*/

  /**Opens a confirmation dialog to delete a medication schedule.
Displays the medication name in the dialog title.
If the user confirms the deletion, calls the deleteSchedule() service method with the scheduled ID.
Updates the medication schedule table after successful deletion.
*/
  deletevalue(data: any) {
    const config: MatDialogConfig = {
      panelClass: 'dialog-responsive',
      width: '50%',
      minWidth: '50%',
      disableClose: true,
      data: {
        title: `medication ${data.medication_name} `,
        isDelete: true
      },
    };
    const openDialog = this.dialog.open(MessageDialogComponent, config);
    openDialog.afterClosed().subscribe((result: any) => {
      if (result) {
        this.subscriptions.push(
          this.service.deleteSchedule(data.scheduled_id).subscribe((res: any) => {
            this.getAllMedicationSchedule();
          }, (error: Response) => {
          })
        );

      }

    });
  }

  /*-- ---------------------------------------------------- -----------------------------------*/

  submit() {

    if (!this.isUpdate) this.save();
    if (this.isUpdate) this.update();
  }

  addMedication(index: any) {
    this.medication_group.push(this.medicationFromGroupFn());
    setTimeout(() => {
      var div: any = document.getElementById(`medication_schedule_${index + 1}`);
      div.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
    }, 150);
  }

  removeMedication(index: any) {
    this.medication_group.removeAt(index);
  }

  clearAll() {
    this.showTable = true;
    this.medication_group.reset();
  }

  medicationSchadulevalue: any = undefined;
  editvalue(id: any) {
    this.tabledata.filter((c) => {
      if (c.scheduled_id == id) {
        this.medicationSchadulevalue = c;
      }
    });
    this.updatemedication(this.medicationSchadulevalue);
  }

  updatemedication(data: any) {
    this.medication_group.controls = [];
    this.medication_group.push(this.medicationFromGroupUpdate(data));
    this.showTable = false;
    this.isUpdate = true;
  }

  /**
 * Calculates the due date for a medication based on user input and updates the corresponding form control.
 *
 * This function takes an index 'i' representing the medication group control to operate on.
 * It extracts age_in_week and age_in_day from the form control's value and computes the due date
 * based on the start date and user-entered values. The calculated due date is then set or the
 * control is reset, depending on the comparison between entered days and start date.
 *
 * @param {number} i - The index of the medication group control to operate on.
 */
  calculateDueDate(i: number) {
    setTimeout(() => {
      const { age_in_week, age_in_day } = this.medication_group.controls[i].value;
      const start_date_in_days = (Number(this.active_batch.start_age_week) * 7) + (Number(this.active_batch.start_age_days));
      const entered_days = (Number(age_in_week) * 7) + Number(age_in_day);
      if (entered_days >= start_date_in_days) {
        const today = moment(this.active_batch.start_date);
        const due_date = today.clone().add(entered_days - start_date_in_days, 'days');
        this.medication_group.controls[i].get('due_date').setValue(due_date);
      } else {
        this.medication_group.controls[i].get('due_date').reset();
      }
    }, 100);

  }
/**
 * Validates and formats input for age in days, considering breed type.
 *
 * @param {number} i - Index of the medication group control.
 * @param {Event} event - The input event triggering the function.
 */  ageInDaysRegex(i: number, event: Event) {
    const inputElement = event.target as HTMLInputElement;
    if (this.shed_data.breedType == 'broiler') {
      inputElement.value = inputElement.value.trim().replace(/^[0]+([1-9][0-9]*)?/, '$1').slice(0, 3);
      this.medication_group.controls[i].get('age_in_day').setValue(inputElement.value);
    } else {
      inputElement.value = inputElement.value.trim().replace(/[^1-7]/g, '').slice(0, 1);
      this.medication_group.controls[i].get('age_in_day').setValue(inputElement.value);
    }
  }


  addNewMedication(index: number) {
    if (this.add_medication_control.invalid) {
      return;
    }
    const req_obj = {
      tank_id: this.shed_data.id,
      medication_name: this.add_medication_control.value,
      type: 'vaccine'
    };
    this.subscriptions.push(
      this.service.addNewMedication(req_obj).subscribe((res: any) => {
        this.getMedication();
        this.cancelNewMedication(index);
      })
    );
  }
  cancelNewMedication(index: number) {
    console.log("INDEX", index);

    this.is_add_medicaiton = -1;
    this.add_medication_control.reset();

  }
  removeName(data: any) {

    const config: MatDialogConfig = {
      panelClass: 'dialog-responsive',
      width: '50%',
      minWidth: '50%',
      disableClose: true,
      data: {
        title: `medication ${data.medication_name} `,
        isDelete: true
      },
    };
    const openDialog = this.dialog.open(MessageDialogComponent, config);
    openDialog.afterClosed().subscribe((is_delete: any) => {
      if (is_delete) {
        this.subscriptions.push(
          this.service.deleteMedication(data.medication_id).subscribe(() => {
            this.getMedication();
          })
        );
      }

    });
  }
  ngOnDestroy(): void {
    this.subscriptions.forEach((sub: Subscription) => sub.unsubscribe());
  }
}
