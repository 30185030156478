import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { HomeService } from 'src/app/core/services/home/home.service';
import { MessageService } from 'src/app/core/services/message/message.service';
import { MessageDialogComponent } from 'src/shared/message-dialog/message-dialog.component';
import { LayoutService } from '../../layout/layout-service/layout.service';
import { Subscription } from 'rxjs';
import { MatTableDataSource } from '@angular/material/table';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { ViewDetailsDialogComponent } from 'src/shared/view-details-dialog/view-details-dialog.component';

@Component({
  selector: 'app-tank',
  templateUrl: './tank.component.html',
  styleUrls: ['./tank.component.scss']
})
export class TankComponent implements OnInit, OnDestroy {
  subscriptions: Subscription[] = [];
  @HostListener('window:beforeunload', ['$event'])
  beforeunloadHandler(event: any) {
    if (this.is_edit_shed) {
      event.preventDefault();
      event.returnValue = '';
      localStorage.setItem("show_create_button", JSON.stringify(true));
    }
  }
  public shed_list: any = [];
  public shed_type_list: any = [];
  public cage_type_list: any = [];
  public bird_type_list: any = [];
  public supervisor_name_list: any = [];
  public tank_form_group!: FormGroup;
  public is_create_shed = localStorage.getItem('create_button') == 'false';
  public user_data: any = localStorage.getItem('user_data') ? JSON.parse(localStorage.getItem('user_data') || '') : null;
  public farm_id: any = localStorage.getItem('farm') ? JSON.parse(localStorage.getItem('farm') || '')?.id : '';;
  public farm_data: any = localStorage.getItem('farm') ? JSON.parse(localStorage.getItem('farm') || '') : null;
  public company_data: any = localStorage.getItem('company') ? JSON.parse(localStorage.getItem('company') || '') : null;
  public access: any = this.home.getAccess(5);
  public is_show_data: any;
  public shed_data: any;
  public sensor_data_source = new MatTableDataSource([]);
  public displayedColumns: string[] = ['S.NO', 'Sensor name', 'Type', 'Product code', 'Device id', 'Status', 'Last update'];
  public is_tank_name_changed = false;
  public is_edit_shed = false;
  public is_caged = false;

  constructor(private fb: FormBuilder,
    private home: HomeService,
    private route: Router,
    private dialog: MatDialog,
    private tab_service: LayoutService,
    private auth: AuthService,
    private msg: MessageService) {
  }

  ngOnInit(): void {

    this.initForm();
    this.home.getShedBehaviorSubject();
    if (this.is_create_shed) {
      this.getAllShedSupervisor();
    }

    this.subscriptions = [
      this.home.getShedListServices.subscribe((res: any) => {
        if (res?.length) {
          res.forEach((sensor: any) => {
            sensor.active = sensor?.sensor_data?.filter((act: any) => act.status == 'active')?.length || 0;
            sensor.in_active = sensor?.sensor_data?.filter((in_act: any) => in_act.status == 'In active')?.length || 0;
          });
          this.shed_list = res;
          console.log("Tank List", this.shed_list);
        } else {
          this.shed_list = [];
        }
      }, (error: any) => {
        this.shed_list = [];
      }),

      // to get all Fish type
      this.home.getAllFishType().subscribe((res: any) => {
        if (res.data?.length) {
          this.bird_type_list = res.data;
        }
      }),

      // to get all cage type
      // this.home.getAllCageType().subscribe((res: any) => {
      //   if (res.data?.length) {
      //     this.cage_type_list = res.data;
      //   }
      // }),

      // to get all Tank type
      this.home.getAllTankType().subscribe((res: any) => {
        if (res.data?.length) {
          res.data.sort((a: any, b: any) => a.name.localeCompare(b.name));;
          this.shed_type_list = res.data;
          console.log("shed_type_list", this.shed_type_list)
        }
      }),

      this.tab_service.hide_home_tab_button.subscribe((label: any) => {
        this.is_create_shed = !label;
        this.getAllShedSupervisor();
      }),
    ];
  }

  initForm(data?: any) {
    let shed_user_data: any;
    if (data) {
      console.log("Tank form data", data);
      shed_user_data = this.filterShedUser(data.user_id);
      // this.cageTypeSelecetionChange({ value: data?.cage_type_id });
      this.birdTypeSelecetionChange({ value: data?.fish_type_id });
    }
    this.tank_form_group = this.fb.group({
      company_id: new FormControl(this.company_data.id, [Validators.required]),
      tank_name: new FormControl(data?.tank_name, [Validators.required]),
      user_id: new FormControl(data?.user_id, [Validators.required]),
      tank_id: new FormControl(data?.tank_id),
      mobile_no: new FormControl({ value: shed_user_data?.mobile_no, disabled: true }),
      labour_count: new FormControl(data?.labour_count, [Validators.required]),
      length: new FormControl(data?.length, [Validators.required]),
      width: new FormControl(data?.width, [Validators.required]),
      height: new FormControl(data?.height, [Validators.required]),
      rows_count: new FormControl(data?.rows_count),
      tier_count: new FormControl(data?.tier_count),
      tank_type_id: new FormControl(data?.tank_type_id, [Validators.required]),
      partition_count: new FormControl(data?.partition_count),
      fish_type_id: new FormControl(data?.fish_type_id, [Validators.required]),
      box_count: new FormControl(data?.box_count),
      female_box_count: new FormControl(data?.female_box_count),
      male_box_count: new FormControl(data?.male_box_count),
      fish_count: new FormControl(data?.fish_count, [Validators.required]),

    });

    if (data) {
      this.tank_form_group.markAllAsTouched();
    }
  }

  /*-------------------------to check tank name is already exists or not---------------------*/

  isTankNameExist() {
    return new Promise((resolve, reject) => {
      const comp_id = localStorage.getItem('company') ? JSON.parse(localStorage.getItem('company') || '').id : null;
      const tank_name = this.tank_form_group.get("tank_name")?.value;
      const requestData = {
        key: 'tank',
        value: tank_name,
        company_id: comp_id
      };

      if (tank_name?.length && this.is_tank_name_changed && !this.tank_form_group.controls['tank_name']?.errors?.['alreadyExist']) {
        this.auth.isRecordExist(requestData).subscribe((res: any) => {
          console.log("isRecordExist", res)
          this.is_tank_name_changed = false;
          if (res) {
            if (res?.isRecordExist) {
              this.tank_form_group.controls['tank_name']?.setErrors({ alreadyExist: true });
              resolve(true);
            } else {
              this.tank_form_group.controls['tank_name']?.setErrors(null);
              resolve(false);
            }
          }
        });
      } else {
        resolve(false);
      }
    });
  }

  /*----------------------------------------------------------------------------------------*/

  /*------------------------------submit shed record-----------------------------------------*/

  async submit() {
    if (this.tank_form_group.invalid) {
      this.msg.showFromGroupWarning(this.tank_form_group, 'tank supervisor');
      return;
    }

    if (this.is_tank_name_changed) {
      const isExist = await this.isTankNameExist();
      if (isExist) return;
    }

    const resetFn = () => {

      this.home.getShedBehaviorSubject();
      this.clearAll();
      this.tab_service.showCreateButton(true);

    };

    if (this.is_edit_shed) {
      this.subscriptions.push(
        this.home.updateShed(this.tank_form_group.value).subscribe((res: any) => {
          resetFn();
        })
      );


      return;
    }
    this.subscriptions.push(
      this.home.createShed(this.tank_form_group.value).subscribe((res: any) => {
        resetFn();
      })
    );

  }

  /*----------------------------------------------------------------------------------------*/

  /*---------------------------------shed edit and delete-----------------------------------*/

  async editShed(data: any) {
    await this.getAllShedSupervisor();
    this.initForm(data);
    this.is_edit_shed = true;
    this.tab_service.showCreateButton(false);
  }


  // to delete shed 
  deleteShed(data: any) {
    const config: MatDialogConfig = {
      panelClass: 'dialog-responsive',
      width: '50%',
      minWidth: '50%',
      disableClose: true,
      data: {
        title: `${data.tank_name}`,
        isDelete: true
      },
    };
    const openDialog = this.dialog.open(MessageDialogComponent, config);
    openDialog.afterClosed().subscribe((result: any) => {
      if (result) {
        this.subscriptions.push(
          this.home.deleteShed(data).subscribe((res: any) => {
            this.clearAll();
            this.home.getShedBehaviorSubject();
          })
        );

      }

    });

  }

  /*----------------------------------------------------------------------------------------*/


  filterShedUser(user_id: any) {
    return this.supervisor_name_list.find((id: any) => id.user_id == user_id);
  }

  // to get all shed supervisors
  getAllShedSupervisor() {
    return new Promise((resolve, reject) => {
      this.subscriptions.push(
        this.home.getAllShedSupervisorUnderCompany().subscribe((res: any) => {
          console.log("getAllShedSupervisorUnderCompany", res)
          if (res?.data?.length) {
            res.data.sort((a: any, b: any) => a.user_name.localeCompare(b.user_name));;
            this.supervisor_name_list = res.data;
            resolve(res.data);
          }
          resolve([]);
        }, (error: any) => {
          resolve([]);
        })
      );
    });

  }

  navigateToShed(shed_data: any) {
    localStorage.setItem('tank', JSON.stringify({ id: shed_data.tank_id, name: shed_data.tank_name, breedType: shed_data.fish_type_name }));
    this.route.navigate(['main/dashboard']);
  }

  clearAll() {
    this.is_create_shed = false;
    this.is_edit_shed = false;
    this.is_caged = false;
    this.is_breed = false;
    this.male_breed = false;
    this.initForm();
    this.tab_service.showCreateButton(true);
  }

  setContactNo(user_id: any) {
    const data = this.supervisor_name_list.find((s: any) => {
      return s.user_id == user_id;
    });
    this.tank_form_group.get('mobile_no')?.setValue(data?.mobile_no);
  }

  cageTypeSelecetionChange(event: any) {
    if (event?.value == 1) {
      this.is_caged = true;
    } else {
      this.is_caged = false;
      this.tank_form_group.get('rows_count')?.reset();
      this.tank_form_group.get('tier_count')?.reset();
    }
  }
  is_breed: boolean = false;
  male_breed: boolean = false;
  selectedBreedType: any = '';
  birdTypeSelecetionChange(event: any) {
    this.selectedBreedType = event.value;
    this.is_breed = true;
    this.tank_form_group.get("male_box_count")?.reset();
    this.tank_form_group.get("female_box_count")?.reset();
    this.tank_form_group.get("box_count")?.reset();
    this.tank_form_group.get("bird_count")?.reset();
    if (event?.value == 2) {
      this.male_breed = true;
    } else {
      this.male_breed = false;
    }
  }

  showSensorData(data: any) {
    if (data?.sensor_data) {
      this.shed_data = data;
      this.is_show_data = true;
      this.sensor_data_source.data = data.sensor_data;
    }

  }

  viewShed(data: any) {
    const shed_supervisor_data = this.supervisor_name_list.find((s: any) => {
      return s.user_id == data.user_id;
    });
    const tank_type = this.shed_type_list.find((s: any) => s.tank_type_id == data.tank_type_id)?.name;
    // const cage_type = this.cage_type_list.find((s: any) => s.cage_type_id == data.cage_type_id)?.name;
    data.supervisor_name = shed_supervisor_data?.user_name;
    data.contact_no = shed_supervisor_data?.mobile_no;
    data.tank_type = tank_type;
    // data.cage_type = cage_type;
    const config: MatDialogConfig = {
      panelClass: 'dialog-responsive',
      width: '75%',
      height: '75%',
      disableClose: false,
      data: {
        is_shed_detail: true,
        data
      },
    };
    this.dialog.open(ViewDetailsDialogComponent, config);


  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub: Subscription) => sub.unsubscribe());
  }

}
