import { Component, OnInit } from '@angular/core';
import { MessageService } from 'src/app/core/services/message/message.service';

@Component({
  selector: 'app-full-layout',
  templateUrl: './full-layout.component.html',
  styleUrls: ['./full-layout.component.scss']
})
export class FullLayoutComponent implements OnInit {

  constructor(private message: MessageService) {
  }
  start_loader = false;
  ngOnInit(): void {

    this.message.loaderState.subscribe((state: any) => {
      this.start_loader = state;
    })
  }

}
