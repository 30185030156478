import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { CryptoService } from 'src/app/core/services/crypto/crypto.service';
import { MessageService } from 'src/app/core/services/message/message.service';
@Component({
  selector: 'app-otp',
  templateUrl: './otp.component.html',
  styleUrls: ['./otp.component.scss']
})
export class OtpComponent implements OnInit, OnDestroy {
  userId: string = '';
  public securedEmail: any;
  public subscription: Subscription[] = [];

  public is_otp_expired = false;
  private auth_data: any;
  public otp_expire_date!: string;
  public count_down: any;
  public otp: Array<any> = [];
  public stop_interval: any;
  otpFormGroup!: FormGroup;
  constructor (
    private fb: FormBuilder,
    private route: Router,
    private AuthService: AuthService,
    private show_msg: MessageService,
    private crypto: CryptoService
  ) {
    if (localStorage.getItem('auth_data')) {
      this.auth_data = this.crypto.decrypt(localStorage.getItem('auth_data') || '');
      this.otp_expire_date = this.auth_data.otp_expires_in;
    }
    if (!!!this.auth_data?.auth_id || this.crypto.decrypt(localStorage.getItem('otp_validate') || '') == 'done') {
      this.route.navigateByUrl('/auth/forgot-password');
      localStorage.clear();
      return;
    }
    this.OTPTimerFn(this.otp_expire_date);

  }

  ngOnInit (): void {

    this.otpFormGroup = this.fb.group({
      otp1: new FormControl('', [Validators.required]),
      otp2: new FormControl('', [Validators.required]),
      otp3: new FormControl('', [Validators.required]),
      otp4: new FormControl('', [Validators.required]),
    });

    let email: any = this.auth_data?.email || '';
    this.securedEmail = email.length ? this.emailMask(email) : '--';
  }

  // to hide user name /email
  emailMask (email: string) {
    const [username, domain] = email.split('@');
    const scuredUsername = `${username.slice(0, 4)}${'*'.repeat(username.length - 4)}`;
    return `${scuredUsername}@${domain}`;
  }

  /**------------------------------------------------------------------------------------------------- */

  /**
 * Handles the paste event and automatically populates OTP form fields if a 4-digit value is pasted.
 * It extracts the pasted value, checks its length, and sets the corresponding form controls in otpFormGroup.
 */
  handlePaste (event: any) {
    const pastedValue = event.clipboardData.getData('text/plain')?.split('');
    if (pastedValue.length == 4) {
      setTimeout(() => {
        pastedValue.forEach((value: any, index: any) => {
          this.otpFormGroup.controls[`otp${index + 1}`].reset();
          this.otpFormGroup.controls[`otp${index + 1}`].setValue(value);
        });
      });
    }
  }

  /**---------------------------------------------------------------------------------------- */

  /** Handles keyboard events to move focus between input elements based on the key pressed. */
  moveFocus (event: any, input: any) {
    if (event.key !== 'Backspace') {
      if (input.nextElementSibling) {
        input.nextElementSibling.focus();
      }
    } else if (event.key == 'Backspace') {
      if (input.previousElementSibling) {
        input.previousElementSibling.focus();
      }
    }
  }

  /**--------------------------------------------------------------------------------------- */



  /**--------------------------------------------------------------------------------------- */

  /**
 * Handles the submission of the OTP form.
 * If the form is invalid, it shows an error message and returns.
 * Otherwise, it constructs an OTP string, sends a request to validate the OTP,
 * and navigates to the reset password page upon successful validation.
 */

  submit () {
    if (this.otpFormGroup.invalid) {
      this.show_msg.errorSnackBar('enter_otp');
      return;
    }

    var otp: string = (this.otpFormGroup.value['otp1'].toString()) + this.otpFormGroup.value['otp2']
      + this.otpFormGroup.value['otp3'] + this.otpFormGroup.value['otp4'];
    var validateotp = new validateOtp();
    validateotp.user_id = this.auth_data?.auth_id;
    validateotp.otp = otp;
    this.subscription.push(
      this.AuthService.validateOtp(validateotp).subscribe((res: any) => {
        this.auth_data.otp = otp;
        localStorage.setItem('auth_data', this.crypto.encrypt(this.auth_data));
        this.route.navigate(['/auth/reset-password']);
        localStorage.setItem('otp_validate', this.crypto.encrypt('done'));
      }, (error: Response) => {
        console.log(error);
      })
    );


  }

  /**------------------------------------------------------------------------------------------------- */


  /**------------------------------------------------------------------------------------------------- */

  /** sends a request to resend OTP for the corresponding user. */
  resendOtp () {
    this.otpFormGroup.reset();
    const email = this.auth_data?.email || null;
    const user_name = this.auth_data?.user_name || null;
    this.subscription.push(
      this.AuthService.forgetPassword({ email_id: email, user_id: this.auth_data?.aut_id, user_login_name: user_name }).subscribe((res: any) => {
        this.auth_data.otp_expires_in = res?.expires_in;
        localStorage.setItem('auth_data', this.crypto.encrypt(this.auth_data));
        this.otp_expire_date = res?.expires_in;
        this.OTPTimerFn(this.otp_expire_date);
      })
    );
  }
  /**------------------------------------------------------------------------------------------------- */

  // to check OTP expired or not
  isOTPExpired (expires_in: any) {
    const otp_expiry_time = new Date(expires_in).valueOf();
    if (otp_expiry_time > new Date().valueOf()) {
      return false;
    }
    return true;
  }

  OTPTimer = (expires_in: any) => {
    let minutes;
    let seconds;
    var currentDate = new Date();
    var otpExpirationDate = new Date(expires_in);
    var timeDifference = otpExpirationDate.getTime() - currentDate.getTime();
    // var days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
    // var hours = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
    seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);
    this.count_down = `${minutes}m : ${String(seconds).length == 1 ? '0' + seconds : seconds}s`;
  };

  // to show otp expire time
  OTPTimerFn = (expires_in: any) => {
    if (expires_in) {
      this.stop_interval = setInterval(() => {
        const otp_expiry_time = new Date(expires_in).valueOf();
        if (otp_expiry_time > new Date().valueOf()) {
          this.OTPTimer(expires_in);
        } else {
          clearInterval(this.stop_interval);
          this.otp_expire_date = '';
        }
      }, 1000);
    };
  };


  ngOnDestroy (): void {
    clearInterval(this.stop_interval);
  };
}






export class validateOtp {
  user_id: string = '';
  otp: string = '';
}
