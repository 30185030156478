import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AUTH_API, COMMON_API } from 'src/app/config/api.config';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private BASE_URL = environment.BASE_URL;

  constructor (private http: HttpClient) { }

  getToken () {
    return !!localStorage.getItem("token");
  }
  public signIn (user: any): Observable<any> {
    return this.http.post(`${this.BASE_URL}${AUTH_API.login}`, user);


  }
  public forgetPassword (userName: any): Observable<any> {
    return this.http.post(`${this.BASE_URL}${AUTH_API.requestOTP}`, userName);


  }
  public validateOtp (otpObj: any): Observable<any> {
    return this.http.post(`${this.BASE_URL}${AUTH_API.validateOTP}`, otpObj);


  }

  public changePassword (changePass: any): Observable<any> {
    return this.http.put(`${this.BASE_URL}${AUTH_API.changePasssword}`, changePass);


  }

  public isRecordExist (data: any): Observable<any> {
    return this.http.post(`${this.BASE_URL}${COMMON_API.IS_RECORD_EXIST}`, data);
  }
  public sendMail (data: any): Observable<any> {
    return this.http.post(`${this.BASE_URL}${COMMON_API.SEND_MAIL}`, data);
  }

}
