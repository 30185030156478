import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { SettingsService } from 'src/app/core/services/settings/settings.service';
import { Clipboard } from '@angular/cdk/clipboard';
import { MessageService } from 'src/app/core/services/message/message.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { IotDialogComponent } from 'src/shared/iot-dialog/iot-dialog.component';
import { MessageDialogComponent } from 'src/shared/message-dialog/message-dialog.component';
import { HomeService } from 'src/app/core/services/home/home.service';
import { Subscription } from 'rxjs';
@Component({
  selector: 'app-iot-gateway',
  templateUrl: './iot-gateway.component.html',
  styleUrls: ['./iot-gateway.component.scss']
})
export class IotGatewayComponent implements OnInit, OnDestroy {
  current_time = new Date();
  public subscriptions: Subscription[] = [];
  public iot_gateway_group: any;
  public edit_serial_no = false;
  public serial_data: any;
  public is_value_changed = false;
  public is_show_submit = false;
  public TypeList = ['Farm Agent', 'Weight'];
  public company_name = localStorage.getItem('company')?.length ? JSON.parse(localStorage.getItem('company') || '')?.name : '--';
  public tank_name = localStorage.getItem('tank')?.length ? JSON.parse(localStorage.getItem('tank') || '')?.name : '--';
  sensorStaticData: any = {
    temperature_and_humidity: 'Temperature and Humidity',
    anemometer: 'Air flow',
    lux: 'Light',
    ammonia_and_co: 'Ammonia and Co',
    pressure: 'Pressure',
    carbon_dioxide: 'Carbon dioxide'
  };
  displayedColumns: string[] = ['S.NO', 'Topic name', 'Topic structure', 'Action'];
  dataSource = new MatTableDataSource([]);
  dataSourceIOT = new MatTableDataSource([]);
  public access: any;
  constructor(private fb: FormBuilder, private SettingsService: SettingsService, private show_msg: MessageService, private clipboard: Clipboard, private dialog: MatDialog,
    private msg: MessageService,
    private home: HomeService
  ) {
    this.access = this.home.getAccess(13);

  }

  ngOnInit(): void {
    this.iot_gateway_group = this.fb.group({
      serial_number: new FormControl('', [Validators.required])
    });
    this.getSerialNo();
  }
  tabledata = [];
  /**
   * get gateway serial no API
   */
  getSerialNo() {
    this.subscriptions.push(
      this.SettingsService.getIotSerialNo().subscribe((res: any) => {
        if (res?.data?.length) {
          this.tabledata = res.data;
          this.dataSourceIOT.data = res.data;
          var datum: any = this.tabledata.filter((m: any) => (m.type == 'Farm Agent'));
          //   this.iot_gateway_group.controls['serial_number'].setValue(res.data[0].serial_no);
          //   this.iot_gateway_group.controls['serial_number'].disable();
          //   this.edit_serial_no = false;
          if (datum.length > 0) {
            this.getTopic(datum[0].serial_no);
          } {
            this.dataSource.data = [];
          }
        } else {
          this.tabledata = [];
        }
      }, (error: any) => {
        this.is_show_submit = true;
      })
    );

  }
  /**
   * get topic get serial number have 'Farm Agent' type Api
   * @param serialNo 
   */
  getTopic(serialNo: any) {
    this.subscriptions.push(
      this.SettingsService.getTopicUsingTankANDSerialNO(serialNo).subscribe((res: any) => {
        this.dataSource.data = [];
        if (res?.data.length > 0) {
          this.dataSource.data = res?.data;
        }

      }, (error: any) => {
      })
    );

  }
  /**
   * save the link in topic to clipboard
   * @param data 
   */
  saveInClipboard(data: any) {
    this.clipboard.copy(data);
    this.msg.successSnackBar('gateway.topic_copied');
  }

  /**
   * add new gateway in gateways table
   * @returns 
   */
  addNewIot() {
    var templist: any[] = [];
    this.TypeList.filter((n: any) => {
      var data = this.tabledata.filter((m: any) => (m.type == n));
      if (data.length == 0) templist.push(n);
    });
    if (templist.length > 0) {
      this.addIotDialog(null);
    } else {
      return this.show_msg.errorSnackBar('gateway_implemented');
    }
  }
  /**
   * sub-method of addNewIot to open popup to create new gateway
   * @param id 
   */
  addIotDialog(id: any) {
    const config: MatDialogConfig = {
      panelClass: 'dialog-responsive2',
      width: '30%',
      minWidth: '30%',
      disableClose: true,
      data: {
        title: id,
        isUpdate: id != null
      },
    };
    const openDialog = this.dialog.open(IotDialogComponent, config);
    openDialog.afterClosed().subscribe((result: any) => {
      if (result) {
        this.getSerialNo();
      }
    });
  }
  /**
   * delete gateway in gateways table
   * @param event 
   */
  delete(event: any) {
    const config: MatDialogConfig = {
      panelClass: 'dialog-responsive',
      width: '50%',
      minWidth: '50%',
      disableClose: true,
      data: {
        title: `Gateway Type ${event.type}`,
        isDelete: true
      },
    };
    const openDialog = this.dialog.open(MessageDialogComponent, config);
    openDialog.afterClosed().subscribe((result: any) => {
      if (result) {
        this.subscriptions.push(
          this.SettingsService.deleteIotSerialNo(event.id).subscribe((res: any) => {
            this.getSerialNo();
          })
        );
      }
    });
  }
  ngOnDestroy(): void {
    this.subscriptions.forEach((sub: Subscription) => sub.unsubscribe());
  }
}