<div fxLayout="column">
    <!-- <div class="step-one" [ngStyle]="next? {'opacity': 1}:{'opacity':0}">

    </div> -->
    <div fxLayout="column" fxLayoutGap="20" [ngClass]="next? 'hide-container':'show-container'">
        <div fxLayout="row warp" fxLayoutAlign="center center">
            <div class="m-5">
                <button class="submit grow" mat-stroked-button fxLayoutAlign="space-between center"
                    (click)="download('template')">
                    <mat-icon class="downvalue" svgIcon="downloadicon"></mat-icon>
                    <span class="button-class"> {{'bulk_upload.download_template'|translate|uppercase}}</span>
                </button>
            </div>
            <div class="m-5">
                <button class="submit grow" mat-stroked-button fxLayoutAlign="space-between center"
                    (click)="download('input_file')">
                    <mat-icon class="downvalue" svgIcon="downloadicon"></mat-icon>
                    <span class="button-class"> {{'bulk_upload.download_input_data'|translate|uppercase}}</span>
                </button>
            </div>
            <!-- <div class="m-5">
                <button class="submit grow" mat-stroked-button fxLayoutAlign="space-between center"
                    (click)="download('user_manual')">
                    <mat-icon class="downvalue" svgIcon="downloadicon"></mat-icon>
                    <span class="button-class"> {{'bulk_upload.download_user_manual'|translate|uppercase}}</span>
                </button>
            </div> -->




        </div>

        <div fxLayout="column" fxLayoutGap="5">
            <div class="content-box">
                <div class="pointer"></div>
                <div class="content"><span>
                        {{'bulk_upload.growth_score_content'|translate}}
                    </span>
                    <mat-icon style="font-size: 14px;" [matMenuTriggerFor]="growth_info">info</mat-icon>
                </div>
            </div>
            <mat-menu class="menu-container" #growth_info="matMenu" xPosition="before">
                <div>
                    {{'bulk_upload.growth_score_info_f'|translate}}<br>
                    {{'bulk_upload.growth_score_info_mw'|translate}}<br>
                    {{'bulk_upload.growth_score_info_fw'|translate}}<br>

                </div>
            </mat-menu>

            <div class="content-box">
                <span class="pointer"></span>
                <span class="content"> <span>
                        {{'bulk_upload.mortality_score_content'|translate}}
                    </span><mat-icon style="font-size: 14px;"
                        [matMenuTriggerFor]="mortality_info">info</mat-icon></span>
            </div>
            <mat-menu class="menu-container" #mortality_info="matMenu" xPosition="before">
                <div>
                    {{'bulk_upload.mortality_score_info_mm'|translate}}<br>
                    {{'bulk_upload.mortality_score_info_fm'|translate}}<br>
                    {{'bulk_upload.mortality_score_info_mcm'|translate}}<br>
                    {{'bulk_upload.mortality_score_info_fcm'|translate}}<br>
                </div>
            </mat-menu>
            <div class="content-box">
                <span class="pointer"></span>
                <span class="content"><span>
                        {{'bulk_upload.productivity_score_content'|translate}}
                    </span><mat-icon style="font-size: 14px;"
                        [matMenuTriggerFor]="productivity_info">info</mat-icon></span>
            </div>
            <mat-menu class="menu-container" #productivity_info="matMenu" xPosition="before">
                {{'bulk_upload.productivity_score_info_te'|translate}}<br>
                {{'bulk_upload.productivity_score_info_se'|translate}}<br>
                {{'bulk_upload.productivity_score_info_cte'|translate}}<br>
                {{'bulk_upload.productivity_score_info_cse'|translate}}<br>
                {{'bulk_upload.productivity_score_info_ew'|translate}}<br>
            </mat-menu>
            <div class="content-box">
                <span class="pointer"></span>
                <span class="content"><span>
                        {{'bulk_upload.thresholds'|translate}}
                    </span><mat-icon style="font-size: 14px;"
                        [matMenuTriggerFor]="threshold_info">info</mat-icon></span>
            </div>
            <mat-menu class="menu-container" #threshold_info="matMenu">

                {{'bulk_upload.thresholds_info_threshold'|translate}}<br>
                {{'bulk_upload.thresholds_info_max'|translate}}<br>
                {{'bulk_upload.thresholds_info_range'|translate}}<br>
            </mat-menu>

        </div>
        <div fxFlex="100" fxLayoutAlign="center center" fxLayoutGap="25" fxLayoutAlign.lt-sm="center center"
            class="buttondiv m-t-10" style="max-height: 6vh;">
            <button class="submit grow submin" mat-raised-button
                style="box-shadow: none;line-height: 32px;font-size: 12px;"
                (click)="next=true">{{'button.next'|translate|uppercase}}</button>
            <button class="cancel grow submin" mat-raised-button
                style="box-shadow: none;line-height: 32px;font-size: 12px;"
                (click)="cancel()">{{'button.cancel'|translate|uppercase}}</button>
        </div>
    </div>


    <!-- <button mat-flat-button (click)="next=!next">next</button> -->

    <div [ngClass]="next? 'show-container':'hide-container'">
        <div fxLayout="column" fxLayoutGap="24px" class="hide">
            <!-- <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="12px" fxLayoutAlign="center center" class="marbt">
                <span style="font-size: 16px;"> {{"bulk_upload.need_template"|translate|titlecase}}</span>
                <button class="submit grow" mat-stroked-button fxLayoutAlign="space-between center"
                    (click)="download()">
                    <mat-icon class="downvalue" svgIcon="downloadicon"></mat-icon>
                    <span class="hide"> {{'bulk_upload.download_template'|translate|uppercase}}</span>
                    <span class="show"> {{'bulk_upload.template'|translate|uppercase}}</span>
                </button>
            </div> -->
            <div class="dropzone" id="dropzoneid">
                <div style="z-index: 3;position: absolute; height: 36vh;" [style.width]="width">
                    <input type="file" name="file" accept=".csv" class="input-file" onclick="this.value=null;"
                        #fileDropRef id="fileDropRef" (change)="onFileChange($event)">
                </div>
                <div class="text-wrapper">
                    <div class="centered" fxLayout="column" fxLayoutGap="8px">
                        <mat-icon class="uploadicon" svgIcon="upload"></mat-icon>
                        <span class="font">{{'bulk_upload.drag_drop'|translate|titlecase}}</span>
                        <span class="font">{{'bulk_upload.or'|translate|titlecase}}</span>
                        <label for="fileDropRef"><span
                                class="textLink font">{{'bulk_upload.browse_file'|translate|titlecase}}</span></label>
                    </div>
                </div>
            </div>
            <div fxLayout="row" style="width: 100%;margin-top: 20px;" fxLayoutAlign="space-between center">
                <div fxLayout="row" *ngIf="selectedFile!=undefined" fxLayoutGap="8px" fxFlex="50">
                    <div>
                        <mat-icon class="csvicon" svgIcon="csv"></mat-icon>
                    </div>
                    <div class="withspace">
                        <div
                            style="height: 100%;display: flex;align-items: center;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;">
                            {{selectedFile.name}}
                        </div>
                        <!-- <mat-progress-bar mode="determinate" value="100"></mat-progress-bar> -->
                    </div>
                    <div (click)="remove()"
                        style="width: 3vh;justify-content: center;display: flex;align-items: center;font-size: 20px;color: blue;cursor: pointer;">
                        <mat-icon style="transform: rotate(45deg);color: #626262;">add_circle_outline</mat-icon>
                    </div>
                </div>
                <div fxFlex="100" fxLayoutAlign="end end" fxLayoutGap="25" fxLayoutAlign.lt-sm="center center"
                    class="buttondiv" style="max-height: 6vh;">
                    <button fxFlex="13" class="submit grow submin" mat-raised-button
                        style="box-shadow: none;line-height: 32px;font-size: 12px;"
                        (click)="submit()">{{'button.submit'|translate|uppercase}}</button>
                    <button fxFlex="13" class="cancel grow submin" mat-raised-button
                        style="box-shadow: none;line-height: 32px;font-size: 12px;"
                        (click)="cancel()">{{'button.cancel'|translate|uppercase}}</button>
                </div>
            </div>
        </div>
        <div class="show" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20">
            <div fxLayout="row" style="width: 100%;margin-top: 20px;" fxLayoutAlign="space-between center"
                [ngClass]="{marg: !(selectedFile!=undefined)}">
                <div>
                    <span *ngIf="selectedFile!=undefined" style="font-weight: 600;">Uploaded file</span>
                </div>
                <div>
                    <mat-icon (click)="cancel()" [ngStyle]="(selectedFile!=undefined) ? {'top':'-15px'}:{'top':'-4px'}"
                        style="color: black;position: relative;left: 15px;">close</mat-icon>
                </div>
            </div>
            <div fxLayout="column" *ngIf="!(selectedFile!=undefined)" fxLayoutAlign.lt-sm="center center"
                fxLayoutGap="25">
                <button class="submit grow" mat-stroked-button
                    style="box-shadow: none;line-height: 32px;font-size: 12px;    min-width: 6rem;">
                    <label for="fileDropRef"><span class="textLink"
                            style="color: white;">{{'bulk_upload.browse_file'|translate|titlecase}}</span></label>
                </button>
                <!-- <div class="downloadcsv">Don't have the template?<span style="color: #1990de;display: flex;gap: 4px;"
                        (click)="download()">
                        <mat-icon class="boxicon" svgIcon="downloadbox" style="height: 16px;width: 16px;"></mat-icon>
                        Download
                    </span></div> -->
            </div>
            <div fxLayout="row" *ngIf="selectedFile!=undefined" fxLayoutGap="8px"
                style="max-width :100%;justify-content: center;">
                <div>
                    <mat-icon class="csvicon" style="height: 24px;width: 24px;" svgIcon="csv"></mat-icon>
                </div>
                <div class="withspace">
                    <div
                        style="height: 100%;display: flex;align-items: center;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;font-size: 12px;">
                        {{selectedFile.name}}
                    </div>
                    <!-- <mat-progress-bar mode="determinate" value="100"></mat-progress-bar> -->
                </div>
                <div (click)="remove()"
                    style="width: 3vh;justify-content: center;display: flex;align-items: center;font-size: 20px;color: blue;cursor: pointer;">
                    <mat-icon style="transform: rotate(45deg);color: #626262;">add_circle_outline</mat-icon>
                </div>
            </div>
            <div fxFlex="100" *ngIf="selectedFile!=undefined" fxLayoutAlign="end end" fxLayoutGap="25"
                fxLayoutAlign.lt-sm="center center" class="buttondiv" style="max-height: 6vh;">
                <button fxFlex="50" class="submit grow submin" mat-raised-button
                    style="box-shadow: none;line-height: 32px;font-size: 12px;"
                    (click)="submit()">{{'button.submit'|translate|uppercase}}</button>
                <button fxFlex="50" class="cancel grow submin" mat-raised-button
                    style="box-shadow: none;line-height: 32px;font-size: 12px;"
                    (click)="cancel()">{{'button.cancel'|translate|uppercase}}</button>
            </div>
        </div>
    </div>
</div>