import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ReportRoutingModule } from './report-routing.module';
import { MaterialModule } from 'src/shared/material/material.module';
import { FullLayoutModule } from '../../layout/layout.module';
import { ReportSelectiveComponent } from '../../report-selective/report-selective.component';
import { ReportComponent } from '../report.component';
import { TranslateModule } from '@ngx-translate/core';


@NgModule({
  declarations: [
    ReportSelectiveComponent,
    ReportComponent
  ],
  imports: [
    CommonModule,
    TranslateModule.forChild(),
    ReportRoutingModule,
    MaterialModule,
    FullLayoutModule,
  ]
})
export class ReportModule { }
