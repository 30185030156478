<div class="m-p-1" fxLayout="column" fxLayoutGap="10" id="sensor_scroll_end">
    <mat-card style="background-color: #166DA6;color: white;height:4.2rem;" fxLayout="row"
        fxLayoutAlign="space-between center">
        <div fxLayout="column">
            <span style="color: white;font-weight: bold;line-height: 1;">{{tank_name}}</span>
            <span style="line-height: 1;margin-top: 7px;font-size: 10px;
    color: #FFFFFF;">{{company_name}}</span>
        </div>



        <button mat-stroked-button class="add-button grow" (click)="redirect()">
            <span>{{'sensor.all_sensor'|translate|titlecase}}</span></button>
    </mat-card>


    <mat-card fxLayout="column" fxLayoutGap="10">



        <div fxFlex="100" fxLayout="column">
            <ng-container *ngFor="let sensorGroup of addSensorArray.controls ;let i = index;let last =last">
                <mat-card fxLayout.lt-sm="column" class="sensor-card">
                    <div fxLayoutAlign="space-between center">
                        <div mat-card-title style="margin-left: 10px;" class="label">
                            {{'sensor.sensor'|translate|titlecase}} {{i+1}}
                        </div>
                        <div fxLayout="row" fxLayoutGap="5">
                            <mat-icon class="remove-icon grow" *ngIf="addSensorArray.controls.length >1 "
                                (click)="removeSensor(i)">do_not_disturb_on</mat-icon>
                            <mat-icon class="add-icon grow" *ngIf="last && !isupdate"
                                (click)="addSensor()">add_circle</mat-icon>
                        </div>
                    </div>
                    <form [formGroup]="sensorGroup" fxLayout.lt-sm="column" fxLayout="row warp">
                        <div fxFlex="31" style="margin: 5px;">
                            <mat-form-field appearance="outline" style="height: 3rem;">
                                <mat-label>{{'sensor.iot_data_tag'|translate|titlecase}}</mat-label>
                                <mat-select disableOptionCentering formControlName="iot_data_tag"
                                    panelClass="PanelClass" placeholder="Select type">
                                    <ng-container *ngFor="let unit of sensortypeList">
                                        <mat-option (click)="PatchProductCode(i,unit.product_code)"
                                            [matTooltip]=" unit.name" matTooltipPosition="right"
                                            matTooltipHideDelay="10" [value]="unit.sensor_type_id">{{unit.name
                                            |anyToTitle}}
                                        </mat-option>
                                    </ng-container>
                                </mat-select>
                            </mat-form-field>
                        </div>

                        <div fxFlex="31" style="margin: 5px;">
                            <mat-form-field appearance="outline" style="height: 3rem;">
                                <mat-label>{{'sensor.name'|translate|titlecase}}</mat-label>
                                <input  matInput formControlName="name" type="text" autocomplete="off">
                            </mat-form-field>
                        </div>


                        <!-- pattern="^[^\d]+$" oninput="this.value=this.value.replace(/\d/g,'');" -->

                        <div fxFlex="31" style="margin: 5px;">
                            <mat-form-field appearance="outline" style="height: 3rem;">
                                <mat-label>{{'sensor.device_id'|translate|titlecase}}</mat-label>
                                <input  matInput formControlName="device_id" type="text" autocomplete="off">
                            </mat-form-field>
                        </div>
                        <div fxFlex="31" style="margin: 5px;">
                            <mat-form-field class="custom-disabled-field" appearance="outline" style="height: 3rem;">
                                <mat-label>{{'sensor.product_code'|translate|titlecase}}</mat-label>
                                <input readonly="true" matInput formControlName="product_code" type="text" autocomplete="off">
                            </mat-form-field>
                        </div>

                        <div fxFlex="31" style="margin: 5px;">
                            <mat-form-field appearance="outline" style="height: 3rem;">
                                <mat-label>{{'sensor.installed_location'|translate|titlecase}}</mat-label>
                                <input  matInput formControlName="installed_location" type="text" autocomplete="off">
                            </mat-form-field>
                        </div>
                        <div fxFlex="31" style="margin: 5px;">
                            <mat-form-field appearance="outline" style="height: 3rem;">
                                <mat-label>{{'sensor.installed_ulocation'|translate|titlecase}}</mat-label>
                                <input  matInput formControlName="installed_ulocation" type="text" autocomplete="off">
                            </mat-form-field>
                        </div>
                    </form>
                </mat-card>

            </ng-container>

        </div>

        <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="25px">
            <button class="submit grow" mat-stroked-button (click)="submit()">{{!!updatedata ?
                ('button.update'|translate|titlecase):'button.submit'|translate|titlecase}}</button>
            <button class="cancel grow" mat-stroked-button
                (click)="clearAll()">{{'button.cancel'|translate|titlecase}}</button>

        </div>

    </mat-card>





</div>