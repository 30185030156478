import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { HOME } from 'src/app/config/api.config';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HomeService {

  private BASE_URL = environment.BASE_URL;
  constructor(private http: HttpClient,
    private route: Router) {
    const token = localStorage.getItem("token");
    !!token ? this.getRoleBehaviorSubject() : "";
    // const is_auth_url = !window.location.href.includes("management-register");
    // if (is_auth_url) {
    this.getCountryBehaviorSubject();
    // }
  }

  public tank_data: any = null;
  private tank_list = new BehaviorSubject(this.tank_data);
  public getShedListServices = this.tank_list.asObservable();
  private role_list = new BehaviorSubject([]);
  public getRoleServices = this.role_list.asObservable();
  private country_list = new BehaviorSubject([]);
  public getCountryServices = this.country_list.asObservable();


  getAccess(menu_id: number | string) {
    const user_data: any = localStorage.getItem('user_data') ? JSON.parse(localStorage.getItem('user_data') || '') : null;
    if (user_data.user_role != 1) {
      const access: any[] = JSON.parse(localStorage.getItem("user_data") || "")?.access?.menu_access
        ? JSON.parse(JSON.parse(localStorage.getItem("user_data") || "")?.access?.menu_access || "")
        : null;

      return access?.find((m: any) => {
        return Number(m.menu_id) == Number(menu_id);
      });

    } else {
      return {
        create: true,
        edit: true,
        delete: true,
        view: false
      };
    }

  }

  getRoleBehaviorSubject() {
    this.getAllRole().subscribe((res: any) => {
      if (res.data?.length) {
        this.role_list.next(res);
      } else {
        this.role_list.next([]);
      }
    });
  }
  getCountryBehaviorSubject() {
    this.getCountry().subscribe((res: any) => {
      if (res?.length) {
        this.country_list.next(res);
      } else {
        this.country_list.next([]);
      }
    });
  }

  getShedBehaviorSubject() {
    const user_data = JSON.parse(localStorage.getItem('user_data') || '');
    const company_id = localStorage.getItem('company') ? JSON.parse(localStorage.getItem('company') || '')?.id : '';
    this.tank_list.next([]);
    if (user_data?.user_role == 5) {
      this.getAllTankByUserId().subscribe((res: any) => {
        if (res?.data?.length && res?.data?.length > 1) {
          res.data.sort((a: any, b: any) => a.tank_name.localeCompare(b.tank_name));;
          this.tank_list.next(res.data);
          this.route.navigate(['/home']);
        } else {
          localStorage.setItem('tank', JSON.stringify({ id: res.data[0]?.tank_id, name: res.data[0]?.tank_name }));
          this.route.navigate(['/main/dashboard']);
          this.tank_list.next([]);
        }
      }, (error) => {
        this.tank_list.next([]);
      });
    } else {
      this.getAllShedByFarmId(company_id).subscribe((res: any) => {
        if (res?.data?.length) {
          res.data.sort((a: any, b: any) => a.tank_name.localeCompare(b.tank_name));;
          this.tank_list.next(res.data);
          // this.route.navigate(['/home']);
        } else {
          this.tank_list.next([]);
        }
      }, (error) => {
        this.tank_list.next([]);
      });
      // this.getAllShedForTc(farm_id).subscribe((res: any) => {
      //   if (res?.data?.length) {
      //     res.data.sort((a: any, b: any) => a.shed_name.localeCompare(b.shed_name));;
      //     this.shed_list.next(res.data)
      //     // this.route.navigate(['/home']);
      //   } else {
      //     this.shed_list.next([])
      //   }
      // }, (error) => {
      //   this.shed_list.next([])
      // })
    }
  }


  //SHED API
  public createShed(data: any): Observable<any> {

    return this.http.post(`${this.BASE_URL}${HOME.CREAT_SHED}`, data);
  }
  public updateShed(data: any): Observable<any> {

    return this.http.put(`${this.BASE_URL}${HOME.CREAT_SHED}/${data.tank_id}`, data);
  }
  public deleteShed(data: any): Observable<any> {

    return this.http.delete(`${this.BASE_URL}${HOME.CREAT_SHED}/${data.tank_id}`);
  }

  // USER API
  public createUser(data: any): Observable<any> {
    return this.http.post(`${this.BASE_URL}${HOME.USER}`, data);
  }
  public updateUser(data: any): Observable<any> {
    return this.http.patch(`${this.BASE_URL}${HOME.USER}/${data.user_id}`, data);
  }
  public deleteUser(data: any): Observable<any> {
    return this.http.delete(`${this.BASE_URL}${HOME.USER}/${data.user_id}`);
  }
  public registerUser(data: any): Observable<any> {
    return this.http.post(`${this.BASE_URL}${HOME.REGISTER_USER}`, data);
  }
  public getAllUser(page: any) {
    return this.http.get(`${this.BASE_URL}${HOME.USER}/?perpage=${page.per_page || null}&currentpage=${page.current_page || null}&sort=${page.sort_by || null}`);
  }
  // public getAllUser(page: any) {
  //   return this.http.get(`${this.BASE_URL}${HOME.USER}/?perpage=${page.per_page || null}`);
  // }
  public getUserByUserId() {
    const user_id = JSON.parse(localStorage.getItem('user_data') || '')?.user_id;
    return this.http.get(`${this.BASE_URL}${HOME.GET_USER_BY_ID}/${user_id}`);
  }
  public getUsersUnderCompany(page: any) {
    const company_id = localStorage.getItem('company') ? JSON.parse(localStorage.getItem('company') || '')?.id : null;
    return this.http.get(`${this.BASE_URL}${HOME.GET_ALL_USER_UNDER_COMPANY}/?perpage=${page.per_page || null}&company_id=${company_id}&currentpage=${page.current_page || null}&sort=${page.sort_by || null}`);
  }
  public validateToken(company_id: any) {
    return this.http.post(`${this.BASE_URL}/${HOME.TOKEN_VALIDATION}`, company_id);
  }

  //FARM API
  public createFarm(data: any): Observable<any> {
    return this.http.post(`${this.BASE_URL}${HOME.FARM}`, data);
  }
  public updateFarm(data: any): Observable<any> {
    return this.http.put(`${this.BASE_URL}${HOME.FARM}/${data.farm_id}`, data);
  }
  public deleteFarm(data: any): Observable<any> {
    return this.http.delete(`${this.BASE_URL}${HOME.FARM}/${data.farm_id}`);
  }
  public getAllFarms(): Observable<any> {
    const page = 500;
    return this.http.get(`${this.BASE_URL}${HOME.FARM}?perpage=${page}`);
  }

  public getAllFarmsUnderCompany(): Observable<any> {
    const page = 500;
    const company_id = localStorage.getItem('company') ? JSON.parse(localStorage.getItem('company') || '')?.id : null;
    return this.http.get(`${this.BASE_URL}${HOME.GET_FARM_UNDER_COMPANY}/${company_id}?perpage=${page}`);
  }

  public getAllFarmsUnderFarmUser(): Observable<any> {
    const user_id: any = localStorage.getItem('user_data') ? JSON.parse(localStorage.getItem('user_data') || '')?.user_id : null;
    const page = 500;
    return this.http.get(`${this.BASE_URL}${HOME.GET_FARM_UNDER_FARM_USER}/${user_id}?perpage=${page}`);
  }

  public getAllFarmsManagerUnderCompany(): Observable<any> {
    const page = 500;
    const company_id = localStorage.getItem('company') ? JSON.parse(localStorage.getItem('company') || '')?.id : null;
    return this.http.get(`${this.BASE_URL}${HOME.GET_FARM_MANAGER_UNDER_COMPANY}/${company_id}?perpage=${page}`);
  }



  //COMPANY API

  public createCompany(data: any): Observable<any> {
    return this.http.post(`${this.BASE_URL}${HOME.CREATE_COMPANY}`, data);
  }
  public updateCompany(data: any): Observable<any> {
    return this.http.put(`${this.BASE_URL}${HOME.COMPANY}/${data.company_id}`, data);
  }
  public deleteCompany(data: any): Observable<any> {
    return this.http.delete(`${this.BASE_URL}${HOME.COMPANY}/${data.company_id}`);
  }

  public getAllCompany(page?: any): Observable<any> {
    return this.http.get(`${this.BASE_URL}${HOME.COMPANY}?perpage=${page?.per_page || 500}&currentpage=${page?.current_page || 1}`);
  }
  public getAllCompanyManager(): Observable<any> {
    return this.http.get(`${this.BASE_URL}${HOME.GET_ALL_COMPANY_MANAGER}`);
  }
  public getCountry(): Observable<any> {
    return this.http.get(`${this.BASE_URL}${HOME.GET_COUNTRY}`);
  }
  public getState(country_id: any): Observable<any> {
    return this.http.get(`${this.BASE_URL}${HOME.GET_STATE}/${country_id}`);
  }



  public getAllRole() {
    const page = 500;
    return this.http.get(`${this.BASE_URL}${HOME.ROLE}/?perpage=${page}`);
  }
  // public getAllCageType() {

  //   return this.http.get(`${this.BASE_URL}${HOME.GET_CAGE_TYPE}`);
  // }
  public getAllFishType() {

    return this.http.get(`${this.BASE_URL}${HOME.GET_FISH_TYPE}`);
  }
  public getAllTankType() {

    return this.http.get(`${this.BASE_URL}${HOME.GET_TANK_TYPE}`);
  }
  public getAllShedSupervisor(farm_id: any) {

    return this.http.get(`${this.BASE_URL}${HOME.GET_SHED_SUPERVISOR}/${farm_id}`);
  }
  public getAllShedSupervisorUnderCompany(page?: any) {
    const company_id = localStorage.getItem('company') ? JSON.parse(localStorage.getItem('company') || '')?.id : null;

    return this.http.get(`${this.BASE_URL}${HOME.GET_SHED_SUPERVISOR_UNDER_COMPANY}/${company_id}?perpage=${page?.per_page || null}&currentpage=${page?.current_page || null}&sort=${page?.sort_by || null}`);
  }
  public getAllTankByUserId() {
    const user_id: any = localStorage.getItem('user_data') ? JSON.parse(localStorage.getItem('user_data') || '')?.user_id : null;
    return this.http.get(`${this.BASE_URL}${HOME.GET_ALL_TANKS_UNDER_USER}/${user_id}`);
  }

  public getTankByTankId() {
    const tank_id: any = localStorage.getItem('tank') ? JSON.parse(localStorage.getItem('tank') || '')?.id : null;
    return this.http.get(`${this.BASE_URL}${HOME.GET_ALL_TANK_BY_TANK_ID}/${tank_id}`);
  }

  public getAllShedByFarmId(farm_id: any) {

    return this.http.get(`${this.BASE_URL}${HOME.GET_ALL_TANKS_UNDER_COMPANY}/${farm_id}`);
  }
  public getAllShedForTc(farm_id: any) {

    return this.http.get(`${this.BASE_URL}${HOME.GET_ALL_SHED_FOR_TC}/${farm_id}`);
  }
  public getPrivilege(req_data: any) {

    return this.http.get(`${this.BASE_URL}${HOME.GET_PRIVILIGE}?company_id=${req_data?.company_id}&role_id=${req_data?.role_id}`);
  }

  public updateUserPrivilege(request_data: any) {

    return this.http.patch(`${this.BASE_URL}${HOME.UPDATE_PRIVILIGE}/${request_data?.company_id}`, request_data);
  }
}
