<div>
    <div fxFlex="100" fxLayout="column" class="per1pad full-div" fxLayoutGap="10">
        <!-- Blue header with create, list and sync button  with sync indicator  with company details -->
        <mat-card style="background-color: #166DA6;color: white;height:4rem;overflow: hidden;" fxLayout="row"
            fxLayoutAlign="space-between center">
            <div fxLayout="column">
                <span style="font-size: 13px;color: white;font-weight: bold;line-height: 1;">{{shed_name}}</span>
                <span style="line-height: 1;margin-top: 7px;font-size: 10px;
                color: #FFFFFF;">{{company_name}}</span>
            </div>
            <div fxLayoutAlign="center center" fxLayout="row" fxLayoutGap="10">
                <!-- sync progress spinner -->
                <mat-spinner strokeWidth="3" [diameter]="30"
                    *ngIf="showspin  && !createscreen"></mat-spinner>
                <div style="display: grid;align-content: center;"
                    *ngIf="showicon && !showspin  && !createscreen">
                    <mat-icon
                        [ngClass]="{alarmtick:icon=='tick' ,alarexc: icon=='exclamation',alardot: (icon=='dots'||icon=='sync')}"
                        [matTooltip]="message" matTooltipPosition="left" svgIcon="{{icon}}"
                        style="display: inline-flex;"></mat-icon>
                </div>
                <button mat-stroked-button style="text-align: center;" fxLayout="row" class="add-button grow"
                    *ngIf="!createscreen && (access?.create && !access?.view)"
                    (click)="syncAlertsAlarm();" >
                    <span>{{'sensor_alarm.sync'|translate|titlecase}}</span> </button>
                <button *ngIf="!createscreen &&(access?.create && !access?.view)" mat-stroked-button
                    class="add-button grow hide" fxLayout="row" (click)="createList();">
                    <mat-icon class="add-icon">add_circle</mat-icon>
                    <span>{{'button.create'|translate|uppercase}}</span>
                </button>
                <button *ngIf="!createscreen &&(access?.create && !access?.view)" mat-stroked-button
                    class="add-button grow show"
                    style="min-width: 0;width: 34px;justify-content: center;align-items: center;"
                    (click)="createList();">
                    <mat-icon class="listicon" svgIcon="plus" style="height: 22px;width: 22px;"></mat-icon>
                </button>
                <button *ngIf="createscreen &&(access?.create && !access?.view)" mat-stroked-button
                    class="add-button grow" fxLayout="row" (click)="createList()">
                    <mat-icon svgIcon="list" style="margin-right: 6px;height: 22px;width: 22px;"></mat-icon>
                    <span>{{'sensor_alarm.list'|translate|titlecase}}</span>
                </button>
            </div>
        </mat-card>
        <div fxFlex="100" fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="10px" *ngIf="!createscreen">
            <div fxLayout="column" fxFlex="100" fxLayoutGap="10">
                <mat-card fxLayout="column">
                    <div fxLayoutAlign="space-between center">

                    </div>
                    <div fxLayoutAlign="start start" fxLayoutAlign.lt-sm="center center"
                        style="width: 100%;overflow: auto;" class="scrollnull">
                        <div style="width: 100%;">
                            <!-- load empty image when no table data is available  -->
                            <div *ngIf="tabledata.length==0 && isloaded" style="color: #3B3B3B;text-align: center;height: 60vh;align-items: center;
                                display: grid;">
                                <div fxLayout="column" fxLayoutGap="10px" style="justify-content: center;
                                align-items: center;">
                                    <img src="../../../assets/images/empty/Createscreens.png" alt=""
                                        style="height: 140px;width: 140px;">
                                    <span>
                                        {{'sensor_alarm.empty_msg'|translate|titlecase}}
                                    </span>
                                </div>
                            </div>
                           <div class="table-container">
                             <!-- load table when table data is available  -->
                             <table class="level-two-table" mat-table [dataSource]="dataSource1" style="min-width: 300vh"
                             *ngIf="tabledata.length>0">

                             <ng-container matColumnDef="code" sticky>
                                 <th [ngStyle]="{'display': 'none'}" mat-header-cell *matHeaderCellDef
                                     [attr.rowspan]="2"> Code </th>
                                 <td mat-cell *matCellDef="let element" > {{element.code}} </td>
                             </ng-container>
                             <ng-container matColumnDef="type">
                                 <th [ngStyle]="{'display': 'none'}" mat-header-cell *matHeaderCellDef
                                     [attr.rowspan]="2"> type </th>
                                 <td mat-cell *matCellDef="let element"> {{element.type}} </td>
                             </ng-container>
                             <ng-container matColumnDef="subtype">
                                 <th [ngStyle]="{'display': 'none'}" mat-header-cell *matHeaderCellDef
                                     [attr.rowspan]="2"> subtype </th>
                                 <td mat-cell *matCellDef="let element"> {{element.subtype}} </td>
                             </ng-container>
                             <ng-container matColumnDef="unt">
                                 <th [ngStyle]="{'display': 'none'}" mat-header-cell *matHeaderCellDef
                                     [attr.rowspan]="2"> unt </th>
                                 <td mat-cell *matCellDef="let element"> {{element.unt}} </td>
                             </ng-container>
                             <ng-container matColumnDef="id">
                                 <th [ngStyle]="{'display': 'none'}" mat-header-cell *matHeaderCellDef
                                     [attr.rowspan]="2"> id </th>
                                 <td mat-cell *matCellDef="let element">
                                     <div style="display: flex;">
                                         <div style="width: 33%;">
                                             <span><mat-icon class="iconvalue" svgIcon="view"
                                                     (click)="editvalue(element.id,true)"
                                                     style="margin:0%"></mat-icon></span>
                                         </div>
                                         <div *ngIf="access?.edit && !access?.view" style="width: 33%;">
                                             <span><mat-icon class="iconvalue" svgIcon="Edit" style="margin:0%"
                                                     (click)="editvalue(element.id,false)"></mat-icon></span>
                                         </div>
                                         <div *ngIf="access?.delete && !access?.view" style="width: 33%;">
                                             <span><mat-icon class="iconvalue" svgIcon="Delete" style="margin:0%"
                                                     (click)="deletevalue(element.sensorId,element.code)"></mat-icon></span>
                                         </div>
                                     </div>
                                 </td>
                             </ng-container>


                             <ng-container matColumnDef="cadTrade.total" class="cellspace">
                                 <th mat-header-cell *matHeaderCellDef> {{'sensor_alarm.value'|translate|titlecase}}
                                 </th>
                                 <td mat-cell *matCellDef="let element"> {{element.cadTrade.total}} </td>
                             </ng-container>

                             <ng-container matColumnDef="cadTrade.amount">
                                 <th mat-header-cell *matHeaderCellDef class="similar-cell-width cellspace">
                                     {{'sensor_alarm.alert_level'|translate|titlecase}}
                                 </th>
                                 <td mat-cell *matCellDef="let element" class="similar-cell-width">
                                     <span *ngIf="element.cadTrade.amount=='0'">
                                         {{"sensor_alarm.no_alerts"|translate|titlecase}}</span>
                                     <span *ngIf="element.cadTrade.amount!='0'">
                                         {{'sensor_alarm.level'|translate|titlecase}}
                                         {{element.cadTrade.amount}}</span>
                                 </td>
                             </ng-container>

                             <ng-container matColumnDef="data2.total">
                                 <th mat-header-cell *matHeaderCellDef class="cellspace">
                                     {{'sensor_alarm.value'|translate|titlecase}}
                                 </th>
                                 <td mat-cell *matCellDef="let element"> {{element.data2.total}} </td>
                             </ng-container>

                             <ng-container matColumnDef="data2.amount">
                                 <th mat-header-cell *matHeaderCellDef class="similar-cell-width cellspace">
                                     {{'sensor_alarm.alert_level'|translate|titlecase}}
                                 </th>
                                 <td mat-cell *matCellDef="let element" class="similar-cell-width">
                                     <span *ngIf="element.data2.amount=='0'">
                                         {{'sensor_alarm.no_alerts'|translate|titlecase}}</span>
                                     <span *ngIf="element.data2.amount!='0'">
                                         {{'sensor_alarm.level'|translate|titlecase}} {{element.data2.amount}}
                                     </span>
                                 </td>
                             </ng-container>

                             <ng-container matColumnDef="data3.total">
                                 <th mat-header-cell *matHeaderCellDef class="cellspace">
                                     {{'sensor_alarm.value'|translate|titlecase}}
                                 </th>
                                 <td mat-cell *matCellDef="let element"> {{element.data3.total}} </td>
                             </ng-container>

                             <ng-container matColumnDef="data3.amount">
                                 <th mat-header-cell *matHeaderCellDef class="similar-cell-width cellspace">
                                     {{'sensor_alarm.alert_level'|translate|titlecase}}
                                 </th>
                                 <td mat-cell *matCellDef="let element" class="similar-cell-width">
                                     <span *ngIf="element.data3.amount=='0'">
                                         {{'sensor_alarm.no_alerts'|translate|titlecase}}</span>
                                     <span *ngIf="element.data3.amount!='0'">
                                         {{'sensor_alarm.level'|translate|titlecase}} {{element.data3.amount}}
                                     </span>
                                 </td>
                             </ng-container>

                             <ng-container matColumnDef="data4.total">
                                 <th mat-header-cell *matHeaderCellDef class="cellspace">
                                     {{'sensor_alarm.value'|translate|titlecase}}
                                 </th>
                                 <td mat-cell *matCellDef="let element"> {{element.data4.total}} </td>
                             </ng-container>

                             <ng-container matColumnDef="data4.amount">
                                 <th mat-header-cell *matHeaderCellDef class="similar-cell-width cellspace">
                                     {{'sensor_alarm.alert_level'|translate|titlecase}}
                                 </th>
                                 <td mat-cell *matCellDef="let element" class="similar-cell-width">
                                     <span *ngIf="element.data4.amount=='0'">
                                         {{'sensor_alarm.no_alerts'|translate|titlecase}}</span>
                                     <span *ngIf="element.data4.amount!='0'">
                                         {{'sensor_alarm.level'|translate|titlecase}} {{element.data4.amount}}</span>
                                 </td>
                             </ng-container>
                             <ng-container matColumnDef="data5.amount">
                                 <th mat-header-cell *matHeaderCellDef class="similar-cell-width cellspace">
                                     {{'sensor_alarm.alert_level'|translate|titlecase}}
                                 </th>
                                 <td mat-cell *matCellDef="let element" class="similar-cell-width">
                                     <span *ngIf="element.data5.amount=='0'">
                                         {{'sensor_alarm.no_alerts'|translate|titlecase}}</span>
                                     <span *ngIf="element.data5.amount!='0'&& element.data5.amount!='-'">
                                         {{'sensor_alarm.level'|translate|titlecase}} {{element.data5.amount}}
                                     </span>
                                     <span *ngIf="element.data5.amount=='-'">
                                         -</span>
                                 </td>
                             </ng-container>
                             <ng-container matColumnDef="data7.amount">
                                 <th mat-header-cell *matHeaderCellDef class="similar-cell-width cellspace">
                                     {{'sensor_alarm.alert_level'|translate|titlecase}}
                                 </th>
                                 <td mat-cell *matCellDef="let element" class="similar-cell-width">
                                     <span *ngIf="element.data7.amount=='0'">
                                         {{'sensor_alarm.no_alerts'|translate|titlecase}}</span>
                                     <span *ngIf="element.data7.amount!='0' && element.data7.amount!='-'">
                                         {{'sensor_alarm.level'|translate|titlecase}} {{element.data7.amount}}</span>
                                     <span *ngIf="element.data7.amount=='-'">
                                         -</span>
                                 </td>
                             </ng-container>


                             <ng-container matColumnDef="header-row-first-group" sticky>
                                 <th mat-header-cell *matHeaderCellDef [attr.rowspan]="2" class="cellspace" >
                                     {{'sensor_alarm.sensor_name'|translate|titlecase}}
                                 </th>
                             </ng-container>
                             <ng-container matColumnDef="header-row-first-group2">
                                 <th mat-header-cell *matHeaderCellDef [attr.rowspan]="2" class="cellspace">
                                     {{'sensor_alarm.sensor_type'|translate|titlecase}}
                                 </th>
                             </ng-container>
                             <ng-container matColumnDef="header-row-type">
                                 <th mat-header-cell *matHeaderCellDef [attr.rowspan]="2" class="cellspace">
                                     {{'sensor_alarm.parameter'|translate|titlecase}}
                                 </th>
                             </ng-container>
                             <ng-container matColumnDef="header-row-sub-type">
                                 <th mat-header-cell *matHeaderCellDef [attr.rowspan]="2" class="cellspace">
                                     {{'sensor_alarm.unit'|translate|titlecase}}
                                 </th>
                             </ng-container>
                             <ng-container matColumnDef="header-row-fifth-group-Action">
                                 <th mat-header-cell *matHeaderCellDef [attr.rowspan]="2" class="cellspace">
                                     {{'sensor_alarm.action'|translate|titlecase}}
                                 </th>
                             </ng-container>
                             <ng-container matColumnDef="header-row-sec-group">
                                 <th mat-header-cell *matHeaderCellDef [attr.colspan]="2">
                                     {{'sensor_alarm.too_low'|translate|titlecase}}
                                 </th>
                             </ng-container>
                             <ng-container matColumnDef="header-row-third-group">
                                 <th mat-header-cell *matHeaderCellDef [attr.colspan]="2">
                                     {{'sensor_alarm.low'|translate|titlecase}}
                                 </th>
                             </ng-container>
                             <ng-container matColumnDef="header-row-forth-group">
                                 <th mat-header-cell *matHeaderCellDef [attr.colspan]="2">
                                     {{'sensor_alarm.high'|translate|titlecase}}
                                 </th>
                             </ng-container>
                             <ng-container matColumnDef="header-row-fifth-group">
                                 <th mat-header-cell *matHeaderCellDef [attr.colspan]="2">
                                     {{'sensor_alarm.too_high'|translate|titlecase}}
                                 </th>
                             </ng-container>
                             <ng-container matColumnDef="header-row-sixth-group">
                                 <th mat-header-cell *matHeaderCellDef [attr.colspan]="1">
                                     {{'sensor_alarm.battery'|translate|titlecase}}
                                 </th>
                             </ng-container>

                             <ng-container matColumnDef="header-row-eighth-group">
                                 <th mat-header-cell *matHeaderCellDef [attr.colspan]="1">
                                     {{'sensor_alarm.signal_strength'|translate|titlecase}}
                                 </th>
                             </ng-container>

                             <tr mat-header-row *matHeaderRowDef="['header-row-first-group','header-row-first-group2','header-row-type','header-row-sub-type','header-row-sec-group','header-row-third-group','header-row-forth-group','header-row-fifth-group'
                                 ,'header-row-sixth-group','header-row-eighth-group','header-row-fifth-group-Action']
                                 ;sticky:true">
                             </tr>
                             <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky:true"></tr>
                             <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                         </table>
                           </div>

                        </div>
                    </div>
                </mat-card>
            </div>
        </div>
        <!-- create sensor alarm screen -->
        <div fxFlex="100" fxLayout="column" fxLayout.lt-sm="column" *ngIf="createscreen" fxLayoutGap="10">
            <app-create-sensor-alarm [style.width]="'100%'" (path)="receiveData($event)"
                [sensordata]="sensordatavalue"></app-create-sensor-alarm>
        </div>
    </div>

</div>