import { Component, HostListener, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Observable, startWith, map, Subscription } from 'rxjs';
import { HomeService } from 'src/app/core/services/home/home.service';
import { MessageService } from 'src/app/core/services/message/message.service';
import { MapComponent } from 'src/shared/map/map.component';
import { MessageDialogComponent } from 'src/shared/message-dialog/message-dialog.component';
import { LayoutService } from '../../layout/layout-service/layout.service';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { ViewDetailsDialogComponent } from 'src/shared/view-details-dialog/view-details-dialog.component';

@Component({
  selector: 'app-farm',
  templateUrl: './farm.component.html',
  styleUrls: ['./farm.component.scss']
})
export class FarmComponent implements OnInit, OnDestroy {
  @HostListener('window:beforeunload', ['$event'])
  beforeunloadHandler (event: any) {
    if (this.is_edit_farm) {
      event.preventDefault();
      event.returnValue = '';
      localStorage.setItem("show_create_button", JSON.stringify(true));
    }
  }
  public subscriptions: Subscription[] = [];
  public farm_list: any = [];
  public farm_form_group!: FormGroup;
  public is_edit_farm = false;
  public is_farm_name_changed = false;
  public is_create_farm = localStorage.getItem('show_create_button') == 'false';
  public manager_list: any = [];
  public state_list: any = [];
  public country_list: any = [];
  public company_data: any = localStorage.getItem('company') ? JSON.parse(localStorage.getItem('company') || '') : null;
  public user_data: any = localStorage.getItem('user_data') ? JSON.parse(localStorage.getItem('user_data') || '') : null;
  public countryObservable!: Observable<any>;
  public stateObservable!: Observable<any>;
  public access: any;
  constructor (private fb: FormBuilder,
    private tab_service: LayoutService,
    private home: HomeService,
    private show_msg: MessageService,
    private auth: AuthService,
    private dialog: MatDialog) {
    this.access = this.home.getAccess(4);

  }

  ngOnInit (): void {
    this.initForm();
    // for management , super admin,senior manager
    if (this.user_data.user_role <= 3) {
      this.GetAllFarmUnderCompany();
    } else {
      // for other role user
      this.GetAllFarmUnderUser();
    }

    if (this.is_create_farm) {
      this.getFormManagerUnderCompany();
    }
    this.subscriptions = [

      // to get all countries 
      this.home.getCountryServices.subscribe((res: any) => {
        if (res?.length) {
          this.country_list = res;
          this.countryObservableFn();
        }
      }),

      // for show and hide create button
      this.tab_service.hide_home_tab_button.subscribe((label: any) => {
        this.is_create_farm = !label;
        this.getFormManagerUnderCompany();
      })

    ];
  }

  async initForm (data?: any) {
    let farm_user_data: any = null;
    if (data?.country_id) {
      await this.state(data?.country_id);
      farm_user_data = this.filterFarmUser(data?.user_id);
    }
    this.farm_form_group = this.fb.group({
      company_id: new FormControl(data?.company_id || this.company_data?.id, [Validators.required]),
      farm_name: new FormControl(data?.farm_name, [Validators.required]),
      farm_id: new FormControl(data?.farm_id),
      // farm_location: new FormControl(data?.farm_location, [Validators.required]),
      farm_address: new FormControl(data?.farm_address, [Validators.required]),
      // farm_latitude: new FormControl(data?.farm_latitude, [Validators.required]),
      // farm_longitude: new FormControl(data?.farm_longitude, [Validators.required]),
      user_id: new FormControl(data?.user_id, [Validators.required]),
      country_id: new FormControl(data?.country_id, [Validators.required]),
      state_id: new FormControl(data?.state_id, [Validators.required]),
      city_name: new FormControl(data?.city_name, [Validators.required]),
      contact_no: new FormControl({ value: farm_user_data?.mobile_no, disabled: true }, [Validators.required,]),
    });

    if (data) {
      this.farm_form_group.markAllAsTouched();
    }

  }
  // to get all farms under company
  GetAllFarmUnderCompany () {
    this.subscriptions.push(
      this.home.getAllFarmsUnderCompany().subscribe((res: any) => {
        if (res?.data?.length) {
          res.data.sort((a: any, b: any) => a.farm_name.localeCompare(b.farm_name));;
          this.farm_list = res.data;
        } else {
          this.farm_list = [];
        }
      }, (error: any) => {
        this.farm_list = [];
      })
    );

  }
  // to get all farms under user
  GetAllFarmUnderUser () {
    this.subscriptions.push(
      this.home.getAllFarmsUnderFarmUser().subscribe((res: any) => {
        if (res?.data?.length) {
          res.data.sort((a: any, b: any) => a.farm_name.localeCompare(b.farm_name));;
          this.farm_list = res.data;
        } else {
          this.farm_list = [];
        }
      }, (error: any) => {
        this.farm_list = [];
      })
    );

  }

  // to get all farm managers
  getFormManagerUnderCompany () {
    this.subscriptions.push(
      this.home.getAllFarmsManagerUnderCompany().subscribe((res: any) => {
        if (res?.length) {
          res.sort((a: any, b: any) => a.user_name.localeCompare(b.user_name));;
          this.manager_list = res;
        }
      })
    );


  }

  getState () {
    setTimeout(async () => {
      const country_id = this.farm_form_group.controls['country_id'].value;
      this.farm_form_group.controls['state_id'].reset();
      this.state_list = await this.state(country_id);
      this.stateObservableFn();
    });
  }

  state (country_id: any): any {
    return new Promise((resolve, reject) => {
      this.subscriptions.push(
        this.home.getState(country_id).subscribe((res: any) => {
          if (res?.length) {
            this.state_list = res;
            resolve(res);
          } else {
            return resolve([]);
          }
        })
      );
      this.stateObservableFn();
    });
  }
  /*------------------------to change autocomplete display option value-------------------------*/

  countryDisplayFn = (country_id: string): string => {
    if (!country_id) return '';
    const option = this.country_list.find((option: any) => {
      return option.country_id == country_id;
    });
    return option ? option.country_name : '';
  };

  stateDisplayFn = (state_id: string): string => {
    if (!state_id) return '';
    const option = this.state_list.find((option: any) => {
      return option.state_id == state_id;
    });
    return option ? option.state_name : '';
  };
  /*-----------------------------------------------------------------------------------------*/

  /*------------------------country and state all functions----------------------------------*/

  private conuntryFilter (value: string) {
    const filterValue = value?.toLowerCase();
    return this.country_list
      .filter((option: any) => option?.country_name?.toLowerCase().startsWith(filterValue));

  }

  private stateFilter (value: string) {
    const filterValue = value?.toLowerCase();
    return this.state_list
      .filter((option: any) => option?.state_name?.toLowerCase().startsWith(filterValue));

  }

  countryObservableFn () {
    this.countryObservable = this.farm_form_group.controls['country_id'].valueChanges.pipe(
      startWith(''),
      map(value => {
        const name = typeof value === 'string' ? value : value?.country_name;
        return name ? this.conuntryFilter(name) : this.country_list.slice();
      }
      ),
    );
  }

  stateObservableFn () {
    this.stateObservable = this.farm_form_group.controls['state_id'].valueChanges.pipe(
      startWith(''),
      map(value => {
        const name = typeof value === 'string' ? value : value?.state_name;
        return name?.length ? this.stateFilter(name) : this.state_list.slice();
      }
      ),
    );
  }

  // to check country is valid or not
  focusOutCountryField () {
    const country_id = this.farm_form_group.controls['country_id'].value;
    switch (true) {
      case typeof country_id == 'string' && !!country_id.length:
        this.farm_form_group.controls['country_id'].setErrors({ isError: true });
        this.farm_form_group.controls['state_id'].reset();
        this.state_list = [];
        this.stateObservableFn();
        break;
      case typeof country_id == 'string' && !!!country_id.length:
        this.farm_form_group.controls['country_id'].setErrors({ required: true });
        this.farm_form_group.controls['state_id'].reset();
        this.state_list = [];
        this.stateObservableFn();
        break;
    }
  }

  // to check state is valid or not
  focusOutStateField () {
    const state_id = this.farm_form_group.controls['state_id'].value;
    switch (true) {
      case typeof state_id == 'string' && !!state_id.length:
        this.farm_form_group.controls['state_id'].setErrors({ isError: true });
        break;
      case typeof state_id == 'string' && !!!state_id.length:
        this.farm_form_group.controls['state_id'].setErrors({ required: true });
        break;

    }
  }

  onFocusInState () {
    this.farm_form_group.controls['state_id'].markAllAsTouched();
  }

  onSelectState () {
    this.farm_form_group.controls['state_id'].reset();
    this.getState();
  }

  /*-----------------------------------------------------------------------------------------*/



  /*------------------------google map dialogbox--------------------------------------------*/

  openMap () {
    const config: MatDialogConfig = {
      panelClass: 'dialog-responsive',
      width: '80%',
      minWidth: '80%',
      minHeight: 'auto',
      height: '82%',
      disableClose: true,
      data: {
        title: 'Open',
      },
    };

    const openDialog = this.dialog.open(MapComponent, config);
    openDialog.afterClosed().subscribe(async (result: any) => {
      if (result) {
        this.farm_form_group.get('farm_location')?.setValue(result.address);
        this.farm_form_group.get('farm_latitude')?.setValue(result.latitude);
        this.farm_form_group.get('farm_longitude')?.setValue(result.longitude);
        const county_name = result.country?.toLowerCase();
        const state_name = result.state?.toLowerCase();
        const country_data = this.country_list.find((c: any) => {
          return c.country_name?.toLowerCase()?.startsWith(county_name);
        });
        this.farm_form_group.get('country_id')?.setValue(country_data?.country_id);
        await this.state(country_data.country_id);
        const state_data = this.state_list.find((s: any) => {
          return s.state_name?.toLowerCase()?.startsWith(state_name);
        });
        this.farm_form_group.get('state_id')?.setValue(state_data?.state_id);

      }
    });
  }

  /*----------------------------------------------------------------------------------------------*


  /*-------------------------------delete farm---------------------------------------------------*/
  deleteFarm (data: any) {
    const config: MatDialogConfig = {
      panelClass: 'dialog-responsive',
      width: '50%',
      minWidth: '50%',
      disableClose: true,
      data: {
        title: `${data.farm_name}`,
        isDelete: true
      },
    };

    const openDialog = this.dialog.open(MessageDialogComponent, config);
    openDialog.afterClosed().subscribe((result: any) => {
      if (result) {
        this.home.deleteFarm(data).subscribe((res: any) => {
          this.clearAll();
          this.GetAllFarmUnderCompany();
        });
      }

    });

  }
  /*--------------------------------------------------------------------------------------------*/

  /*-----------------------------TO check farm name is already exists or not--------------------*/
  isFarmNameExist () {
    return new Promise((resolve, reject) => {
      const farm_name = this.farm_form_group.get("farm_name")?.value;
      const requestData = {
        key: 'farm',
        value: farm_name,
        company_id: this.farm_form_group.get("company_id")?.value,
        farm_id: this.farm_form_group.get("farm_id")?.value,
      };

      if (farm_name?.length && this.is_farm_name_changed
        && !this.farm_form_group.controls['farm_name']?.errors?.['alreadyExist']) {
        this.auth.isRecordExist(requestData).subscribe((res: any) => {
          if (res) {
            if (res?.isRecordExist) {
              this.farm_form_group.controls['farm_name']?.setErrors({ alreadyExist: true });
              resolve(true);
            } else {
              this.farm_form_group.controls['farm_name']?.setErrors(null);
              resolve(false);
            }
          }
          this.is_farm_name_changed = false;
        });
      } else {
        resolve(false);
      }
    });


  }

  /*--------------------------------------------------------------------------------------------*/

  /*------------------------Submit farm data---------------------------------------------------*/
  async submit () {
    if (this.farm_form_group.invalid) {
      this.show_msg.showFromGroupWarning(this.farm_form_group, 'farm manager');
      return;
    }

    if (this.is_farm_name_changed) {
      const isExist = await this.isFarmNameExist();
      if (isExist) return;
    }

    const resetFn = () => {
      this.is_create_farm = false;
      this.is_edit_farm = false;
      this.initForm();
      this.GetAllFarmUnderCompany();
      this.tab_service.showCreateButton(true);
    };

    // for update data
    if (this.is_edit_farm) {
      this.subscriptions.push(
        this.home.updateFarm(this.farm_form_group.value).subscribe((res: any) => {
          resetFn();
        })
      );

      return;
    }

    this.subscriptions.push(
      this.home.createFarm(this.farm_form_group.value).subscribe((res: any) => {
        resetFn();
      })
    );


  }

  /*------------------------------------------------------------------------------------------*/

  filterFarmUser (user_id: any) {
    return this.manager_list.find((i: any) => i.user_id == user_id);
  }

  redirectToShed (data: any) {
    localStorage.setItem('farm', JSON.stringify({ id: data.farm_id || 1, name: data.farm_name }));
    const index = JSON.parse(localStorage.getItem('current_tab') || '');
    this.tab_service.switchHomeTab(index + 1);
  }

  editFarm (data: any) {
    this.getFormManagerUnderCompany();
    this.initForm(data);
    this.is_edit_farm = true;
    this.tab_service.showCreateButton(false);

  }

  clearAll () {
    this.is_create_farm = false;
    this.is_edit_farm = false;
    this.initForm();
    this.tab_service.showCreateButton(true);
    this.state_list = [];
  }

  setContactNo (user_id: any) {
    const data: any = this.manager_list.find((m: any) => {
      return m.user_id == user_id;
    });
    this.farm_form_group.get('contact_no')?.setValue(data?.mobile_no);
  }

  async viewFarm (data: any) {

    const country = this.country_list.find((c: any) => {
      return c.country_id == data.country_id;
    })?.country_name;

    await this.state(data?.country_id);
    const state = this.state_list.find((s: any) => {
      return s.state_id == data?.state_id;
    })?.state_name;

    const manager_data = this.manager_list.find((m: any) => {
      return m.user_id == data.user_id;
    });
    data.state = state;
    data.country = country;
    data.manager_name = manager_data?.user_name;
    data.contact_no = manager_data?.mobile_no;
    const config: MatDialogConfig = {
      panelClass: 'dialog-responsive',
      width: '70%',
      height: '60%',
      disableClose: false,
      data: {
        is_farm_detail: true,
        data
      },
    };
    this.dialog.open(ViewDetailsDialogComponent, config);


  }

  ngOnDestroy (): void {
    this.subscriptions.forEach((sub: Subscription) => sub.unsubscribe());

  }

}
