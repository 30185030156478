import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from './material/material.module';
import { CustSnackbarComponent } from './cust-snackbar/cust-snackbar.component';
import { MessageDialogComponent } from './message-dialog/message-dialog.component';
import { MapComponent } from './map/map.component';
import { AgmCoreModule } from '@agm/core';
import { ScoreDialogComponent } from './score-dialog/score-dialog.component';
import { IotDialogComponent } from './iot-dialog/iot-dialog.component';
import { BulkUploadDialogComponent } from './bulk-upload-dialog/bulk-upload-dialog.component';
import { InactiveSensorDialogComponent } from './inactive-sensor-dialog/inactive-sensor-dialog.component';
import { BatchEndingDialogComponent } from './batch-ending-dialog/batch-ending-dialog.component';
import { BatchDetailsDialogComponent } from './batch-details-dialog/batch-details-dialog.component';
import { ViewDetailsDialogComponent } from './view-details-dialog/view-details-dialog.component';
import { AnyToTitlePipe } from 'src/app/core/pipes/any-to-title.pipe';
import { RecalculateDialogComponent } from './recalculate-dialog/recalculate-dialog.component';
import { AlertDialogComponent } from './alert-dialog/alert-dialog.component';
import { TranslateModule } from '@ngx-translate/core';
import { SanitizeDirective } from './directives/sanitize.directive';

@NgModule({
  declarations: [
    CustSnackbarComponent,
    MessageDialogComponent,
    MapComponent,
    ScoreDialogComponent,
    IotDialogComponent,
    BulkUploadDialogComponent,
    InactiveSensorDialogComponent,
    BatchEndingDialogComponent,
    BatchDetailsDialogComponent,
    ViewDetailsDialogComponent,
    AnyToTitlePipe,
    RecalculateDialogComponent,
    AlertDialogComponent,
    SanitizeDirective
  ],
  imports: [
    CommonModule,
    MaterialModule,
    TranslateModule.forChild(),
    AgmCoreModule.forRoot({
      // apiKey: 'AIzaSyCh4jwOrfwzirrBE0KrI8sPTStlvkzL5CY',
      // apiKey: 'AIzSyCh4jwOrfwzirrBE0KrI8sPTStlvkzL5CY',
      apiKey: 'AIzaSyCh4jwOrEAANq2rMm9XwBAPonVvNDXPeZBLrLUvaNw',
      libraries: ['places']
    })
  ],
  exports: [
    AnyToTitlePipe,
    SanitizeDirective
  ]
})
export class SharedModule { }
