import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DASHBOARD } from 'src/app/config/api.config';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DashboardAPIService {

  private BASE_URL = environment.BASE_URL;

  constructor(private http: HttpClient) { }

  public getLatestData(gatewayId: any) {

    return this.http.get(`${this.BASE_URL}${DASHBOARD.GET_LATEST_SENSOR_DATA}${gatewayId}`);
  }
  public getDeviceName(deviceid: any): Observable<any> {
    return this.http.get(`${this.BASE_URL}${DASHBOARD.DEVICENAME}${deviceid}`);

  }
  public getDataEntries(entry: any): Observable<any> {
    return this.http.get(`${this.BASE_URL}${DASHBOARD.DATEENTRY}${entry}`);

  }
  public getDataEquipment(entry: any): Observable<any> {
    return this.http.get(`${this.BASE_URL}${DASHBOARD.EQUIPMENTENTRY}${entry}`);

  }
  public getSensorHistory(sensor: any): Observable<any> {
    return this.http.post(`${this.BASE_URL}${DASHBOARD.SENSORHISTORY}`, sensor);

  }
  public getMajorAlarts(data: any): Observable<any> {
    return this.http.post(`${this.BASE_URL}${DASHBOARD.MAJORALARTS}`, data);
  }
  public getLiveScore(): Observable<any> {
    const tank_id = JSON.parse(localStorage.getItem('tank') || '')?.id;
    const batch_id = localStorage.getItem('batch_id') ? JSON.parse(localStorage.getItem('batch_id') || '') : undefined;
    return this.http.get(`${this.BASE_URL}${DASHBOARD.SHOWSCORE}?tank_id=${tank_id}&batch_id=${batch_id}`);
  }
  public getnvironmentalLiveScore(): Observable<any> {
    const tank_id = JSON.parse(localStorage.getItem('tank') || '')?.id;
    return this.http.get(`${this.BASE_URL}${DASHBOARD.ENVIRONMENTALSCORE}?tank_id=${tank_id}`);
  }
  public getHistoricalLiveScore(fromDate: any, toDate: any): Observable<any> {
    const tank_id = JSON.parse(localStorage.getItem('tank') || '')?.id;
    const batch_id = localStorage.getItem('batch_id') ? JSON.parse(localStorage.getItem('batch_id') || '') : undefined;
    return this.http.get(`${this.BASE_URL}${DASHBOARD.SHOWSCORE}?tank_id=${tank_id}&batch_id=${batch_id}&to_date=${toDate}&from_date=${fromDate}`);
  }
  public getHistoricalEnvironmentalLiveScore(fromDate: any, toDate: any): Observable<any> {
    const tank_id = JSON.parse(localStorage.getItem('tank') || '')?.id;
    return this.http.get(`${this.BASE_URL}${DASHBOARD.ENVIRONMENTALSCORE}?tank_id=${tank_id}&from_date=${fromDate}&to_date=${toDate}`);
  }
  public getHistoricalGrowthScore(fromDate: any, toDate: any): Observable<any> {
    const tank_id = JSON.parse(localStorage.getItem('tank') || '')?.id;
    const batch_id = localStorage.getItem('batch_id') ? JSON.parse(localStorage.getItem('batch_id') || '') : undefined;
    return this.http.get(`${this.BASE_URL}${DASHBOARD.GROWTHSCORE}?tank_id=${tank_id}&batch_id=${batch_id}&from_date=${fromDate}&to_date=${toDate}`);
  }
  public getGrowthLiveScore(Date: any): Observable<any> {
    const tank_id = JSON.parse(localStorage.getItem('tank') || '')?.id;
    const batch_id = localStorage.getItem('batch_id') ? JSON.parse(localStorage.getItem('batch_id') || '') : undefined;
    return this.http.get(`${this.BASE_URL}${DASHBOARD.GROWTHSCORE}?tank_id=${tank_id}&batch_id=${batch_id}`);
  }
  public getProducts(): Observable<any> {
    return this.http.get(`${this.BASE_URL}${DASHBOARD.PRODUCT}`);

  }

  public getFcrData(data: any): Observable<any> {
    const tank_id = JSON.parse(localStorage.getItem('tank') || '')?.id;
    const batch_id = localStorage.getItem('batch_id') ? JSON.parse(localStorage.getItem('batch_id') || '') : undefined;
    return this.http.get(`${this.BASE_URL}${DASHBOARD.GET_FCR}?tank_id=${tank_id}&batch_id=${batch_id}&from_date=${data?.from_date}&to_date=${data?.to_date}&bird_type=${data.bird_type}`);
  }

}
