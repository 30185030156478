<div class="background-img"></div>
<div class="outer-container">
    <mat-card>
        <div style="text-align: center;">
            <div><img src="assets/images/iFRM_logo.png" width="80" height="70" alt=""></div>
            <div>
                <span style="color: #166DA6;font-weight: bold;">{{params_data?.company_name|| ""}} -
                    {{'management_register.register'|translate|titlecase}}</span>
            </div>
        </div>
        <!--------------------------To show link expired message-------------------------------->

        <div *ngIf="is_expired == 'TRUE'" style="padding: 20px; text-align: center;color: red;">
            <span>
                {{'management_register.link_expire'|translate|titlecase}}
            </span>
        </div>

        <!-------------------------------------------------------------------------------------->

        <!-----------------------------management registration form----------------------------->
        <div *ngIf="is_expired == 'FALSE'" fxLayout="column" style="padding: 20px;">
            <form [formGroup]="user_form_group">
                <div fxFlex="100" fxLayoutGap="10" fxLayout="column">
                    <div style="font-size: 13px">
                        <mat-form-field appearance="outline">
                            <mat-label>{{'management_register.user_name'|translate|titlecase}}</mat-label>
                            <input matInput oninput="this.value = this.value.trimStart().replace(/\s+/g, ' ')" id="User"
                                name="user" formControlName="user_name" type="text" autocomplete="new-password">
                            <mat-error>
                                <span
                                    *ngIf="user_form_group.controls['user_name'].touched  && user_form_group.get('user_name')?.hasError('required')">
                                    {{'management_register.user_name_msg'|translate|titlecase}}
                                </span>
                            </mat-error>
                        </mat-form-field>

                    </div>

                    <div fxFlex="100" fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="20" fxLayoutGap.lt-sm="10">

                        <div fxFlex="48" fxLayout="column" fxLayoutGap="13">

                            <div style="font-size: 13px">
                                <mat-form-field appearance="outline">
                                    <mat-label>{{'management_register.email'|translate|titlecase}}</mat-label>
                                    <input oninput="this.value = this.value.trimStart().replace(/\s+/g, ' ')" matInput
                                        formControlName="user_email" type="email" autocomplete="new-password">
                                    <mat-error>
                                        <span
                                            *ngIf="user_form_group.controls['user_email'].touched  && user_form_group.get('user_email')?.hasError('required')">
                                            {{'management_register.email_msg'|translate|titlecase}}
                                        </span>
                                        <span
                                            *ngIf="user_form_group.controls['user_email'].touched  && user_form_group.get('user_email')?.hasError('pattern')">
                                            {{'management_register.email_invalid_msg'|translate|titlecase}}
                                        </span>
                                    </mat-error>
                                </mat-form-field>

                            </div>

                            <div fxLayout="row" fxLayoutGap="3">
                                <mat-form-field (click)="mobileDialCode.click()" fxFlex="30" appearance="outline">
                                    <mat-label>{{'management_register.code'|translate|titlecase}}</mat-label>
                                    <mat-select (blur)="dialCodeFocusOut('Mobile')" placeholder="+"
                                        formControlName="dial_code_mobile_no" panelClass="PanelClass"
                                        autocomplete="new-password">
                                        <mat-select-trigger>
                                            {{user_form_group.get('dial_code_mobile_no')?.value || '+'}}
                                        </mat-select-trigger>

                                        <div class="dial-code-filter">
                                            <mat-form-field floatLabel="never">
                                                <input #whatsAppDialCode matInput
                                                    [formControl]="mobile_dial_code_filter" type="text"
                                                    autocomplete="new-password"
                                                    [placeholder]="('search_here'|translate|titlecase)+'...'"
                                                    (keydown)="$event.stopPropagation()">
                                            </mat-form-field>
                                        </div>

                                        <ng-container *ngFor="let country of mobileDialcodeObservable | async">
                                            <mat-option [value]="country.dial_code">{{country.country_name
                                                +' '+ country.dial_code|titlecase}}
                                            </mat-option>
                                        </ng-container>
                                    </mat-select>
                                </mat-form-field>
                                <mat-form-field fxFlex="70" appearance="outline">
                                    <mat-label>{{'management_register.mobile_number'|translate|titlecase}}</mat-label>
                                    <input matInput #mobileDialCode formControlName="mobile" type="number"
                                        (keydown)="$event.key =='e' || $event.key =='E' ||$event.key =='-' || $event.key =='+' ||$event.key == '.'? $event.preventDefault():''"
                                        oninput="this.value=this.value.replace(/[^0-9.]/g,'');javascript: if (this.value.length >15) this.value = this.value.slice(0,15);"
                                        autocomplete="new-password">
                                    <mat-error>
                                        <span
                                            *ngIf="user_form_group.controls['dial_code_mobile_no'].touched  && user_form_group.get('dial_code_mobile_no')?.hasError('required')">
                                            {{'management_register.code_msg'|translate|titlecase}}
                                        </span>
                                        <span
                                            *ngIf="user_form_group.controls['mobile'].touched  && user_form_group.get('mobile')?.hasError('required') && !user_form_group.get('dial_code_mobile_no')?.hasError('required')">
                                            {{'management_register.mobile_msg'|translate|titlecase}}
                                        </span>
                                    </mat-error>
                                </mat-form-field>

                            </div>

                            <div fxLayout="row" fxLayoutGap="3">
                                <mat-form-field (click)="whatsAppDialCode.click()" fxFlex="30" appearance="outline">
                                    <mat-label>{{'management_register.code'|translate|titlecase}}</mat-label>
                                    <mat-select (blur)="dialCodeFocusOut('WhatsApp')" placeholder="+"
                                        formControlName="dial_code_whatsapp_no" panelClass="PanelClass"
                                        autocomplete="new-password">
                                        <mat-select-trigger>
                                            {{user_form_group.get('dial_code_whatsapp_no')?.value || '+'}}
                                        </mat-select-trigger>

                                        <div class="dial-code-filter">
                                            <mat-form-field floatLabel="never">
                                                <input #whatsAppDialCode matInput
                                                    [formControl]="whatsapp_dial_code_filter" type="text"
                                                    autocomplete="new-password"
                                                    [placeholder]="('search_here'|translate|titlecase)+'...'"
                                                    (keydown)="$event.stopPropagation()">
                                            </mat-form-field>
                                        </div>
                                        <ng-container *ngFor="let country of whatsappDialcodeObservable | async">
                                            <mat-option [value]="country.dial_code">{{country.country_name
                                                +' '+ country.dial_code|titlecase}}
                                            </mat-option>
                                        </ng-container>
                                    </mat-select>
                                </mat-form-field>
                                <mat-form-field fxFlex="70" appearance="outline">
                                    <mat-label>{{'management_register.whatsapp_number'|translate|titlecase}}</mat-label>
                                    <input matInput formControlName="whatsapp" type="number"
                                        (keydown)="$event.key =='e' || $event.key =='E' || $event.key =='-' || $event.key =='+' || $event.key == '.'? $event.preventDefault():''"
                                        oninput="this.value=this.value.replace(/[^0-9.]/g,'');javascript: if (this.value.length >15) this.value = this.value.slice(0,15);"
                                        autocomplete="new-password">
                                    <mat-error>
                                        <span
                                            *ngIf="user_form_group.controls['dial_code_whatsapp_no'].touched  && user_form_group.get('dial_code_whatsapp_no')?.hasError('required')">
                                            {{'management_register.code_msg'|translate|titlecase}}
                                        </span>
                                        <span
                                            *ngIf="user_form_group.controls['whatsapp'].touched  && user_form_group.get('whatsapp')?.hasError('required') && !user_form_group.get('dial_code_whatsapp_no')?.hasError('required')">
                                            {{'management_register.whatsapp_msg'|translate|titlecase}}
                                        </span>
                                    </mat-error>
                                </mat-form-field>

                            </div>

                        </div>

                        <div fxFlex="48" fxLayout="column" fxLayoutGap="16">

                            <div style="font-size: 13px">
                                <mat-form-field appearance="outline">
                                    <mat-label>{{'management_register.login_name'|translate|titlecase}}</mat-label>
                                    <input matInput (ngModelChange)="is_login_name_changed = true" id="username"
                                        name="username" (focusout)="isLoginNameExist()" [maxLength]="25"
                                        oninput="this.value = this.value.trimStart().replace(/[^a-zA-Z0-9#!~\-_.]/g, '').replace(/\s+/g, ' ');"
                                        formControlName="user_login_name" type="text" autocomplete="new-password">
                                    <mat-error>
                                        <span
                                            *ngIf="user_form_group.controls['user_login_name'].touched  && user_form_group.get('user_login_name')?.hasError('required')">
                                            {{'management_register.login_name_msg'|translate|titlecase}}
                                        </span>
                                        <span
                                            *ngIf="user_form_group.controls['user_login_name'].touched  && user_form_group.get('user_login_name')?.hasError('alreadyExist')">
                                            {{'management_register.login_name_already_exist'|translate|titlecase}}
                                        </span>
                                    </mat-error>
                                </mat-form-field>

                            </div>


                            <div style="font-size: 13px">
                                <mat-form-field appearance="outline">
                                    <mat-label>{{'management_register.password'|translate|titlecase}}</mat-label>
                                    <input matInput oninput="this.value = this.value.trimStart().replace(/\s+/g, ' ')"
                                        [type]="hide ? 'password' 
                                    : 'text'" (ngModelChange)="passwordMatchValidator()" formControlName="password"
                                        autocomplete="new-password">
                                    <mat-icon style="color: #BCBCBC;" matSuffix (click)="hide = !hide"
                                        [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">{{hide ?
                                        'visibility_off' : 'visibility'}}</mat-icon>
                                    <mat-error>
                                        <span
                                            *ngIf="user_form_group.controls['password'].touched  && user_form_group.get('password')?.hasError('pattern')">
                                            {{'management_register.password_format_msg'|translate|titlecase}}</span>
                                        <span
                                            *ngIf="user_form_group.controls['password'].touched  && user_form_group.get('password')?.hasError('required')">
                                            {{'management_register.password_msg'|translate|titlecase}}</span>
                                    </mat-error>
                                </mat-form-field>

                            </div>


                            <div style="font-size: 13px">
                                <mat-form-field appearance="outline">
                                    <mat-label>
                                        {{'management_register.confirm_password'|translate|titlecase}}</mat-label>
                                    <input matInput oninput="this.value = this.value.trimStart().replace(/\s+/g, ' ')"
                                        (focusin)="onFocusIn()" (ngModelChange)="passwordMatchValidator()"
                                        [readonly]="this.user_form_group.controls['password'].value?.length < 8" [type]="hide1 ? 'password' 
                                    : 'text'" formControlName="confirm_password" autocomplete="new-password">
                                    <mat-icon style="color: #BCBCBC;" matSuffix (click)="hide1 = !hide1"
                                        [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide1">{{hide1 ?
                                        'visibility_off' : 'visibility'}}</mat-icon>

                                    <mat-error>

                                        <span
                                            *ngIf="user_form_group.controls['confirm_password'].touched  && user_form_group.get('confirm_password')?.hasError('pattern')">
                                            {{'management_register.password_format_msg'|translate|titlecase}}
                                        </span>
                                        <span
                                            *ngIf="!!this.user_form_group.controls['password'].value?.length && user_form_group.controls['confirm_password'].touched  && user_form_group.get('confirm_password')?.hasError('required')">
                                            {{'management_register.password_msg'|translate|titlecase}}</span>
                                        <span
                                            *ngIf="!!!this.user_form_group.controls['password'].value?.length  && user_form_group.get('confirm_password')?.hasError('required')">
                                            {{'management_register.password_first_msg'|translate|titlecase}}
                                        </span>
                                        <span
                                            *ngIf="user_form_group.controls['confirm_password']?.hasError('mismatch')  && !user_form_group.get('confirm_password')?.hasError('required')
                                                    && !user_form_group.controls['confirm_password']?.hasError('pattern') && user_form_group.get('password')?.value?.length && user_form_group.get('confirm_password')?.value?.length > 5">
                                            {{'management_register.password_mismatch'|translate|titlecase}}
                                        </span>

                                    </mat-error>

                                </mat-form-field>

                            </div>

                        </div>

                    </div>

                </div>

            </form>
            <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="20" style="margin-top:20px">
                <button fxFlex="48" class="submit grow" mat-stroked-button (click)="submit()">
                    {{'button.submit' |translate |titlecase}}</button>
                <!-- <button fxFlex="48" class="cancel" mat-stroked-button (click)="cancel()">Clear All</button> -->
            </div>
        </div>
        <!-------------------------------------------------------------------------------------->

    </mat-card>
</div>