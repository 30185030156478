import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Subscription, interval, mergeMap } from 'rxjs';
import { HomeService } from 'src/app/core/services/home/home.service';
import { SettingsService } from 'src/app/core/services/settings/settings.service';
import { MessageDialogComponent } from 'src/shared/message-dialog/message-dialog.component';

@Component({
  selector: 'app-sensor',
  templateUrl: './sensor.component.html',
  styleUrls: ['./sensor.component.scss']
})
export class SensorComponent implements OnInit, OnDestroy {
  public subscriptions: Subscription[] = [];
  public current_time: any;
  displayedColumns: string[] = ['S.NO', 'Sensor name', 'Type', 'Product code', 'Device id', 'Installed location', 'Installed Ulocation', 'Action'];

  public dataSource: any = new MatTableDataSource([]);
  public company_name = localStorage.getItem('company')?.length ? JSON.parse(localStorage.getItem('company') || '')?.name : '--';
  public tank_name = localStorage.getItem('tank')?.length ? JSON.parse(localStorage.getItem('tank') || '')?.name : '--';
  showicon = false;
  showspin = false;
  icon = '';
  public access: any;

  constructor(private route: Router, private SettingsService: SettingsService,
    private dialog: MatDialog,
    private home: HomeService


  ) {
    this.access = this.home.getAccess(12);

  }

  ngOnInit(): void {
    this.getTable();
    this.getSensorType();
    this.syncSensorFlag(false);
  }
  tabledata: any[] = [];
  getTable() {
    this.subscriptions.push(
      this.SettingsService.getSensors().subscribe((res: any) => {
        this.tabledata = res.data;
        this.setTable(res.data);
      }, (error: Response) => {
        console.log(error);
      })
    );

  }
  setTable(data: any) {
    var displayedColumns: sensorData[] = [];
    data.filter((c: any) => {
      var data1 = new sensorData();
      data1.name = c.name;
      data1.type = c.sensor_type_id;
      data1.code = c.product_code;
      data1.dId = c.device_id;
      data1.il = c.location;
      data1.iu = c.ulocation;
      data1.id = c.sensor_id;
      displayedColumns.push(data1);
    });
    displayedColumns.sort((a: any, b: any) => a.name.localeCompare(b.name));;
    this.dataSource = displayedColumns;
  }
  sensorTypeList: any[] = [];
  getSensorType() {
    this.subscriptions.push(
      this.SettingsService.getSensorType().subscribe((res: any) => {
        this.sensorTypeList = res.data;
      }, (error: Response) => {
        console.log(error);
      })
    );

  }
  getSensorTypeName(id: any) {
    var data = this.sensorTypeList.length > 0 ? this.sensorTypeList.filter(m => (m.sensor_type_id == id))[0].name : '';
    return data;
  }

  addSensor() {
    this.route.navigate(['/configuration/add-sensor']);

  }
  syncSensorFlag(flag: boolean) {
    this.showspin = true;
    this.subscriptions.push(
      this.SettingsService.syncSensorFlag().subscribe((res: any) => {
        if (res.data.length != 0) {
          this.setvalues(res.data[0], flag);
        } else {
          this.showicon = true;
          this.icon = 'dots';
          this.message = 'pending';
          this.showspin = false;
        }
      }, (error: Response) => {
        console.log(error);
        this.showspin = false;
      })
    );


  }
  message = '';
  setvalues(data: any, flag: boolean) {
    this.showicon = true;
    if (data.status == 'Pending') {
      this.icon = 'dots';
      this.message = 'pending';
      this.showspin = false;
    } else if (data.status == 'Success') {
      if (!flag && data.is_updated == 0) {
        this.icon = 'sync';
        this.message = 'sync needed';
        this.showspin = false;
      } else if (!flag && data.is_updated != 0) {
        this.icon = 'tick';
        this.message = 'success';
        this.showspin = false;
      } else if (data.is_updated == 0) {
        this.loopedfunction();
      } else {
        this.icon = 'tick';
        this.message = 'success';
        this.showspin = false;
      }
    } else if (data.status == 'Error') {
      this.icon = 'exclamation';
      this.message = data.message;
      this.showspin = false;
    } else {
      this.showicon = false;
      this.icon = '';
      this.message = '';
      this.showspin = false;
    }
  }
  loopedfunction() {
    this.showicon = false;
    var i = 0;
    this.showspin = true;
    var subscription = interval(10 * 1000)
      .pipe(
        mergeMap(() => this.SettingsService.syncSensorFlag())
      )
      .subscribe((res: any) => {
        i++;
        if (res.data.length != 0 && res.data[0].is_updated != 0) {
          this.icon = 'tick';
          this.message = 'success';
          this.showicon = true;
          subscription.closed = true;
          this.showspin = false;
        } else if (i == 5) {
          this.icon = 'sync';
          this.message = 'not updated';
          this.showicon = true;
          subscription.closed = true;
          this.showspin = false;
        }
      });
  }
  syncSensor() {
    this.showspin = true;
    this.subscriptions.push(
      this.SettingsService.syncSensor().subscribe((res: any) => {
        setTimeout(() => {
          this.showspin = true;
          this.syncSensorFlag(true);
        }, 3000);
      }, (error: Response) => {
        console.log(error);
        this.showspin = false;
      })
    );

  }
  editvalue(id: any) {
    this.tabledata.filter((c) => {
      if (c.sensor_id == id) {
        localStorage.setItem('sensor', JSON.stringify(c));
      }
    });
    this.route.navigate(['/configuration/add-sensor']);
  }

  deleteSensor(data: any) {
    const config: MatDialogConfig = {
      panelClass: 'dialog-responsive',
      width: '50%',
      minWidth: '50%',
      disableClose: true,
      data: {
        title: `${data?.name} sensor`,
        isDelete: true
      },
    };
    const openDialog = this.dialog.open(MessageDialogComponent, config);
    openDialog.afterClosed().subscribe((result: any) => {
      if (result) {
        this.subscriptions.push(
          this.SettingsService.deleteSensor(data.id).subscribe((res: any) => {
            this.getTable();
          }, (error: Response) => {
            console.log(error);
          })
        );

      }

    });


  }


  ngOnDestroy(): void {
    this.subscriptions.forEach((sub: Subscription) => sub.unsubscribe());
  }
}
export class sensorData {
  name: any;
  type: any;
  code: any;
  dId: any;
  il: any;
  iu: any;
  id: any;
}
