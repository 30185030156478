import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'anyToTitle'
})
export class AnyToTitlePipe implements PipeTransform {
  transform(value: string): string {
    if (!value) {
      return '';
    }

    // Replace underscores with spaces
    const stringWithSpaces = value.replace(/_/g, ' ');

    // Split the string into words using spaces
    const words = stringWithSpaces.split(' ');

    // Capitalize the first letter of each word and convert the rest to lowercase
    const titleCaseWords = words.map(word => {
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    });

    // Join the title-cased words back into a single string
    const titleCaseString = titleCaseWords.join(' ');

    return titleCaseString;
  }
}
