import { Component, OnInit, OnDestroy, ViewChild, HostListener } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { HomeService } from 'src/app/core/services/home/home.service';
import { MessageService } from 'src/app/core/services/message/message.service';
import { REGEX } from 'src/shared/helpers/validators';
import { MessageDialogComponent } from 'src/shared/message-dialog/message-dialog.component';
import { LayoutService } from '../../layout/layout-service/layout.service';
import { Observable, startWith, map, Subscription } from 'rxjs';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { ViewDetailsDialogComponent } from 'src/shared/view-details-dialog/view-details-dialog.component';
import { ALERT_INTERFACE, ALERT_LEVELS } from 'src/shared/common-model/common.model';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss']
})
export class UserComponent implements OnInit, OnDestroy {
  public subscriptions: Subscription[] = [];
  @HostListener('window:beforeunload', ['$event'])
  beforeunloadHandler(event: any) {
    if (this.is_edit_user) {
      event.preventDefault();
      event.returnValue = '';
      localStorage.setItem("show_create_button", JSON.stringify(true));
    }
  }

  @ViewChild(MatPaginator)
  public paginator!: MatPaginator;
  pageEvent!: PageEvent;
  public user_form_group !: FormGroup;
  public user_role: any[] = [];
  public company_list: any[] = [];
  public user_list: any[] = [];
  public country_list: any[] = [];
  public displayedColumns: String[] = ["S.No", "Name", "Login Name", "User Role", "Company", "Email", "Mobile", "Action"];
  public isCreateUser: any = localStorage.getItem('create_button') == 'false';
  public hide: boolean = true;
  public hide1: boolean = true;

  public is_edit_user: boolean = false;

  length = 0;
  pageSize = 10;
  pageIndex = 0;
  pageSizeOptions = [5, 10, 25];

  hidePageSize: boolean = false;
  showPageSizeOptions: boolean = true;
  showFirstLastButtons: boolean = true;
  disabled: boolean = false;
  is_login_name_changed: boolean = false;
  view_only: boolean = localStorage.getItem('user_view_only') ? JSON.parse(localStorage.getItem('user_view_only') || '') : false;
  view_user_id: boolean = localStorage.getItem('view_user_id') ? JSON.parse(localStorage.getItem('view_user_id') || '') : null;
  dataSource = new MatTableDataSource([]);
  mobile_dial_code_filter = new FormControl(null);
  whatsapp_dial_code_filter = new FormControl(null);
  public mobileDialcodeObservable!: Observable<any>;
  public whatsappDialcodeObservable!: Observable<any>;
  public user_data: any = localStorage.getItem('user_data') ? JSON.parse(localStorage.getItem('user_data') || '') : null;
  public company_data: any = localStorage.getItem('company') ? JSON.parse(localStorage.getItem('company') || '') : null;
  public access: any;
  public focus_out_confirm_password = false;
  public alert_levels: ALERT_INTERFACE[] = ALERT_LEVELS;
  constructor(private fb: FormBuilder,
    private home: HomeService,
    private auth: AuthService,
    private msg: MessageService,
    private tab_service: LayoutService,
    private dialog: MatDialog) {
    this.access = this.home.getAccess(2);
  }


  ngOnInit(): void {
    this.formGroupFn();
    this.getUsers();
    if (this.user_data.user_role == 1) localStorage.setItem("company", '');
    if (this.isCreateUser) {
      this.getAllCompany();
    }

    this.subscriptions = [

      //to get all the roles
      this.home.getRoleServices.subscribe((res: any) => {
        if (res.data?.length) {
          if (this.user_data?.user_role == 2) {
            res.data = res.data.filter((val: any) => {
              return Number(val.role_id) >= Number(this.user_data?.user_role);
            });
          } else {
            res.data = res.data.filter((val: any) => {
              return Number(val.role_id) > Number(this.user_data?.user_role);
            });
          }
          this.user_role = res.data;
        }
      }),

      //to enable disable create button
      this.tab_service.hide_home_tab_button.subscribe((label: any) => {
        this.isCreateUser = !label;
        if (this.user_data.user_role == 1) {
          this.getAllCompany();
        }
      }),

      // to get all the countries
      this.home.getCountryServices.subscribe((res: any) => {
        if (res?.length) {
          this.country_list = res;
          this.dialCodeObservableFn();
        }
      })
    ];

    if (this.view_only) {
      this.getUsersRecordById();
    }
  }


  /*-----------------------------Form group------------------------------------------------*/

  formGroupFn(data?: any) {
    this.user_form_group = this.fb.group({
      user_name: new FormControl(data?.user_name, [Validators.required]),
      user_id: new FormControl(data?.user_id),
      role_id: new FormControl(data?.role_id, [Validators.required]),
      company_id: new FormControl(this.user_data.user_role !== 1 ? this.company_data?.id : data?.user_company_id, [Validators.required]),
      user_email: new FormControl(data?.user_email, [Validators.pattern(REGEX.EMAIL)]),
      dial_code_mobile_no: new FormControl(data?.mobile_no?.split(" ")[0], [Validators.required]),
      mobile: new FormControl(data?.mobile_no?.split(" ")[1], [Validators.required, Validators.minLength(10), Validators.maxLength(10)]),
      dial_code_whatsapp_no: new FormControl(data?.whatsapp_no?.split(" ")[0], [Validators.required]),
      whatsapp: new FormControl(data?.whatsapp_no?.split(" ")[1], [Validators.required]),
      country_code: new FormControl(data?.country_code),
      user_login_name: new FormControl(data?.user_login_name, [Validators.required]),
      password: new FormControl(data?.password, [Validators.required, Validators.pattern(REGEX.PASSWORD)]),
      confirm_password: new FormControl(data?.confirm_password, [Validators.required]),
      company_wide_alert: new FormControl(data?.company_wide_alert == 1 ? true : false),
      email_alert: new FormControl({ value: data?.email_alert == 1 ? true : false, disabled: true }),
      alert_level: new FormControl({ value: data?.alert_level, disabled: true }),
      ring_alert: new FormControl({ value: data?.ring_alert == 1 ? true : false, disabled: true }),
      sms_alert: new FormControl({ value: data?.sms_alert == 1 ? true : false, disabled: true }),
      whatsapp_alert: new FormControl({ value: data?.whatsapp_alert == 1 ? true : false, disabled: true }),
    });

  }
  /*-------------------------------------------------------------------------------------*/

  /*---------------------------------Get Apis--------------------------------------------*/

  getAllCompany() {
    return new Promise((resolve, reject) => {
      this.home.getAllCompany().subscribe((res: any) => {
        if (res.data?.length) {
          this.company_list = res.data;
          resolve(res);
        }
        resolve([]);
      }, (error: any) => {
        resolve([]);
      });

    });

  }

  getUsers() {
    const per_page = {
      per_page: this.pageSize,
      current_page: this.pageIndex > 0 ? this.pageIndex + 1 : 1,
      sort_by: 'user_name'
    };
    this.user_data?.user_role == 1
      ? this.getAllUser(per_page)
      : this.user_data?.user_role == 2 || this.user_data?.user_role == 3
        ? this.getCompanyUsers(per_page) : this.user_data?.user_role == 4
          ? this.getAllShedSupervisorUnderCompany(per_page) : '';
  }

  getAllUser(page: any) {
    this.home.getAllUser(page).subscribe((res: any) => {
      this.setDataSource(res);
    });
  }
  setAlertLevel(id: any) {
    const data: any = this.alert_levels.find((m: any) => {
      return m.id == id;
    });
    this.user_form_group.get('alert_level')?.setValue(data?.name);
  }
  // to get specific user data
  getUsersRecordById() {
    if (this.view_user_id) {
      this.subscriptions.push(
        this.home.getUserByUserId().subscribe((res: any) => {
          if (res?.length) {
            this.viewUser(res[0]);
          }
        })
      );
    }
  }

  getCompanyUsers(page_data: any) {
    this.home.getUsersUnderCompany(page_data).subscribe((res: any) => {
      // res.data.sort((a: any, b: any) => a.user_name.localeCompare(b.user_name));;
      this.setDataSource(res);
    });
  }

  // to get all shed supervisors
  getAllShedSupervisorUnderCompany(page_data: any) {
    this.home.getAllShedSupervisorUnderCompany(page_data).subscribe((res: any) => {
      this.setDataSource(res);
    });
  }

  /*----------------------------------------------------------------------------------*/


  /*------------------------------Observables------------------------------------------*/

  dialCodeObservableFn() {
    this.mobileDialcodeObservable = this.mobile_dial_code_filter.valueChanges.pipe(
      startWith(''),
      map((value: any) => {
        const name = typeof value === 'string' ? value : value?.country_name;
        return name ? this.conuntryFilter(name) : this.country_list.slice();
      }

      ),
    );

    this.whatsappDialcodeObservable = this.whatsapp_dial_code_filter.valueChanges.pipe(
      startWith(''),
      map((value: any) => {
        const name = typeof value === 'string' ? value : value?.country_name;
        return name ? this.conuntryFilter(name) : this.country_list.slice();;
      }

      ),
    );
  }

  companyWideChange() {
    const data = this.user_form_group.get('company_wide_alert')?.value;
    const form_controls = ['alert_level', 'email_alert', 'ring_alert', 'sms_alert', 'whatsapp_alert'];
    if (data) {

      this.user_form_group.get('alert_level')?.setValidators([Validators.required]);
      form_controls.forEach((controls: string) => {
        this.user_form_group.get(controls)?.updateValueAndValidity();
        this.user_form_group.get(controls)?.enable();
        // this.user_form_group.get(controls)?.markAllAsTouched();
      });

    } else {
      form_controls.forEach((controls: string) => {
        this.user_form_group.get(controls)?.reset();
        this.user_form_group.get(controls)?.clearValidators();
        this.user_form_group.get(controls)?.updateValueAndValidity();
        this.user_form_group.get(controls)?.disable();
      });

    }
  }
  showCompanyWideAlertStatus() {
    const data = this.user_form_group.get('company_wide_alert')?.value;
    this.msg.infoSnackBar(`cwa_${data ? 'on' : 'off'}`);
  }
  focusInAlerts() {
    const data = !this.user_form_group.get('company_wide_alert')?.value;
    if (data)
      this.msg.infoSnackBar('cwa_first');
  }

  private conuntryFilter(value: string) {
    const filterValue = value?.toLowerCase();
    return this.country_list.filter((option: any) => option?.country_name?.toLowerCase().startsWith(filterValue));

  }

  /*----------------------------------------------------------------------------------*/


  /*-----------------------------Table functions---------------------------------------*/


  setDataSource = (data: any) => {
    if (data?.data?.length) {
      this.length = data?.total;
      this.dataSource.data = [];
      data.data = data.data.filter((u: any) => Number(u.role_id) > Number(this.user_data?.user_role));
      this.dataSource.data = data.data;
    } else {
      this.dataSource.data = [];
    }

  };

  nextPage(e: PageEvent) {
    this.pageEvent = e;
    this.length = e.length;
    this.pageSize = e.pageSize;
    this.pageIndex = e.pageIndex;
    this.getUsers();
  }

  /*----------------------------------------------------------------------------------*/

  /*-----------------------------validators------------------------------------------------*/

  // to check password and confirm password are same
  passwordMatchValidator = () => {

    if (!(this.user_form_group?.controls['password']?.value === this.user_form_group?.controls['confirm_password']?.value)) {
      this.user_form_group?.controls['confirm_password'].setErrors({ ...this.user_form_group?.controls['confirm_password'].errors, 'mismatch': true });
    }
    else {
      const error: any = this.user_form_group?.controls['confirm_password']?.errors;
      if (error) {
        delete error.mismatch;
        Object.keys(error).length
          ? this.user_form_group?.controls['confirm_password'].setErrors({ error })
          : this.user_form_group?.controls['confirm_password'].setErrors(null);
      }
    }

  };

  // make email field required or optional based on role selection
  setRemoveValidator(role_id: any, data?: any) {
    if (role_id != 5) {
      this.user_form_group.get('user_email')?.setValidators([Validators.required, Validators.pattern(REGEX.EMAIL)]);
      this.user_form_group.get('user_email')?.updateValueAndValidity();
    } else {
      this.user_form_group.get('user_email')?.setValidators([Validators.pattern(REGEX.EMAIL)]);
      this.user_form_group.get('user_email')?.updateValueAndValidity();
    }

    // enable company wide alert for management user
    if (!data) {
      if (role_id == 2) {
        this.user_form_group.get('company_wide_alert')?.setValue(role_id == 2);
        this.showCompanyWideAlertStatus();
      } else {
        this.user_form_group.get('company_wide_alert')?.setValue(false);
      }
    }

    this.companyWideChange();
  }

  dialCodeFocusOut(field: any) {
    if (field == 'Mobile') {
      this.user_form_group.controls['mobile'].markAllAsTouched();
      this.mobile_dial_code_filter.reset();
    }
    if (field == 'WhatsApp') {
      this.user_form_group.controls['whatsapp'].markAllAsTouched();
      this.whatsapp_dial_code_filter.reset();
    }

  }

  showHidePassword(label: any) {

    switch (true) {
      case label == 'password':
        if (this.user_form_group.controls['password'].value?.length) {
          this.hide = !this.hide;
        } else {
          this.hide = true;
        }
        break;
      case label == 'confirm_password':
        if (this.user_form_group.controls['confirm_password'].value?.length) {
          this.hide1 = !this.hide1;
        } else {
          this.hide1 = true;
        }
        break;

      default:
        break;
    }

  }

  hidePassword(label: any) {
    switch (true) {
      case label == 'password':
        if (!!!this.user_form_group.controls['password'].value?.length) {
          this.hide = true;
        }
        break;
      case label == 'confirm_password':
        if (!!!this.user_form_group.controls['confirm_password'].value?.length) {
          this.hide1 = true;
        }
        break;

      default:
        break;
    }


  }

  onFocusConfirmPassword() {
    this.user_form_group.controls['confirm_password'].markAllAsTouched();
    this.focus_out_confirm_password = false;
  }
  onFocusOutConfirmPassword() {
    this.focus_out_confirm_password = true;
  }



  /*-------------------------------------------------------------------------------------*/


  /*-----------------------------to check login name already exist or not ----------------*/

  isLoginNameExist() {
    return new Promise((resolve, reject) => {
      const user_login_name = this.user_form_group.get("user_login_name")?.value;
      const requestData = {
        key: 'user',
        value: user_login_name,
        user_id: this.user_form_group.get("user_id")?.value
      };

      if (user_login_name?.length && this.is_login_name_changed
        && !this.user_form_group.controls['user_login_name']?.errors?.['alreadyExist']) {
        this.auth.isRecordExist(requestData).subscribe((res: any) => {
          if (res) {
            if (res?.isRecordExist) {
              this.user_form_group.controls['user_login_name']?.setErrors({ alreadyExist: true });
              resolve(true);
            } else {
              this.user_form_group.controls['user_login_name']?.setErrors(null);
              resolve(false);
            }
          }
          this.is_login_name_changed = false;
        });
      } else {
        resolve(false);
      }
    });


  }

  /*-------------------------------------------------------------------------------------*/

  /*------------------------------User crud functions-----------------------------------*/
  // to submit user data
  async submit() {
    if (this.user_form_group.invalid) {
      this.msg.showFromGroupWarning(this.user_form_group);
      return;
    }
    if (this.is_login_name_changed) {
      const isExist = await this.isLoginNameExist();
      if (isExist) return;
    }

    // Ensure that at least one alert type is chosen when the company-wide alert is activated.
    const is_company_wide_alert = this.user_form_group.get('company_wide_alert')?.value;
    if (is_company_wide_alert) {
      const form_controls = ['email_alert', 'ring_alert', 'sms_alert', 'whatsapp_alert'];
      const is_not_valid = !form_controls.some((control: string) => this.user_form_group.get(control)?.value);
      if (is_not_valid)
        return this.msg.infoSnackBar('select_mode');
    }

    const resetFn = () => {
      this.formGroupFn();
      this.isCreateUser = false;
      this.is_edit_user = false;
      this.getUsers();
      this.tab_service.showCreateButton(true);
    };


    // merge dial code and contact number
    const mobile_no = this.user_form_group.controls['dial_code_mobile_no'].value + " " + this.user_form_group.controls['mobile'].value;
    const whatsapp_no = this.user_form_group.controls['dial_code_whatsapp_no'].value + " " + this.user_form_group.controls['whatsapp'].value;
    const data = { ...this.user_form_group.value, mobile_no, whatsapp_no };

    // for update user data 
    if (this.is_edit_user) {
      delete this.user_form_group.value.password;
      delete this.user_form_group.value.confirm_password;
      this.home.updateUser(data).subscribe((res: any) => {
        resetFn();
      });
      return;
    }
    // for management user
    if (this.user_form_group.value.role_id == 2) {
      this.home.registerUser(data).subscribe((res: any) => {
        resetFn();

      });
    }
    else {
      this.home.createUser(data).subscribe((res: any) => {
        resetFn();
      });
    }
  }

  // to edit user data
  async editUser(data: any) {
    if (this.user_data.user_role == 1) {
      await this.getAllCompany();
    }
    this.formGroupFn(data);
    this.is_edit_user = true;
    this.user_form_group.get('password')?.clearValidators();
    this.user_form_group.get('password')?.updateValueAndValidity();
    this.user_form_group.get('confirm_password')?.clearValidators();
    this.user_form_group.get('confirm_password')?.updateValueAndValidity();
    this.setRemoveValidator(
      data.role_id
      , !!data
    );
    this.tab_service.showCreateButton(false);

  }


  // to view user data
  async viewUser(data: any) {

    const config: MatDialogConfig = {
      panelClass: 'dialog-responsive',
      width: '70%',
      height: '60%',
      disableClose: false,
      data: {
        is_user_detail: true,
        data
      },
    };
    this.dialog.open(ViewDetailsDialogComponent, config);



    // if (this.user_data.user_role == 1) {
    //   await this.getAllCompany();
    // }
    // this.formGroupFn(data);
    // this.view_only = true;
    // localStorage.setItem('user_view_only', JSON.stringify(true));
    // localStorage.setItem('view_user_id', JSON.stringify(data.user_id));
    // this.user_form_group.get('password')?.clearValidators();
    // this.user_form_group.get('password')?.updateValueAndValidity();
    // this.user_form_group.get('confirm_password')?.clearValidators();
    // this.user_form_group.get('confirm_password')?.updateValueAndValidity();
    // this.setRemoveValidator({
    //   value: data.role_id
    // });
    // this.tab_service.showCreateButton(false);

  }


  // to delete user data
  deleteUser(data: any) {
    const config: MatDialogConfig = {
      panelClass: 'dialog-responsive',
      width: '50%',
      minWidth: '50%',
      disableClose: true,
      data: {
        title: `${data.user_name}`,
        isDelete: true
      },
    };
    const openDialog = this.dialog.open(MessageDialogComponent, config);
    openDialog.afterClosed().subscribe((result: any) => {
      if (result) {
        this.home.deleteUser(data).subscribe((res: any) => {
          this.getUsers();
        });
      }

    });


  }

  /*-----------------------------------------------------------------------------------*/

  clearAll() {
    this.isCreateUser = false;
    this.is_edit_user = false;
    this.formGroupFn();
    this.view_only = false;
    this.tab_service.showCreateButton(true);
    localStorage.removeItem('user_view_only');
    localStorage.removeItem('view_user_id');
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub: Subscription) => sub.unsubscribe());
  }
}
