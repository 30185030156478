<mat-card style="padding: 0 3rem;min-height: 72vh;" fxLayout="column">
    <div fxLayoutAlign="space-between center" fxLayout="row" style="margin:15px 0px">
        <div fxLayout="row" fxFlex="100" fxLayoutGap="10">
            <div>
                <mat-form-field appearance="outline">
                    <mat-label>Role</mat-label>
                    <div class="selected-role-color">
                        <mat-select disableOptionCentering (selectionChange)="getPrivilege()"
                            [formControl]="role_control" panelClass="PanelClass" placeholder="Select User Role">
                            <ng-container *ngFor="let role of user_role">
                                <mat-option [value]="role.role_id">{{role.role_name |titlecase}}
                                </mat-option>
                            </ng-container>
                        </mat-select>
                    </div>

                </mat-form-field>
            </div>


            <div *ngIf="user_data.user_role ==1">
                <mat-form-field appearance="outline">
                    <mat-label>Company</mat-label>
                    <div class="selected-role-color">
                        <mat-select disableOptionCentering [formControl]="company_control"
                            (selectionChange)="getPrivilege()" panelClass="PanelClass" autocomplete="new-password">
                            <ng-container *ngFor="let company of company_list">
                                <mat-option [value]="company.company_id">{{company.company_name |titlecase}}
                                </mat-option>
                            </ng-container>
                        </mat-select>
                    </div>
                </mat-form-field>
            </div>

        </div>
        <div>
            <button *ngIf="dataSource.data?.length" mat-stroked-button style="color: white;" fxLayout="row">
                <mat-icon style="color: white;font-size: 18px;margin-top: 5px;">edit</mat-icon> Edit
            </button>
        </div>
    </div>

    <div *ngIf="is_prililege_empty =='EMPTY' && user_data.user_role ==1 &&role_control.invalid&& company_control.invalid"
        style="height: 50vh;justify-content: center;align-items: center;display: flex;">
        Please Select both role and company !!</div>
    <div *ngIf="is_prililege_empty =='EMPTY' && user_data.user_role ==1 && !!!dataSource.data?.length"
        style="height: 50vh;justify-content: center;align-items: center;display: flex;">
        Privilege is empty !!</div>
    <div *ngIf="is_prililege_empty =='EMPTY' && user_data.user_role !=1"
        style="height: 50vh;justify-content: center;align-items: center;display: flex;">
        Privilege is empty !!</div>

    <div *ngIf="dataSource.data?.length">

        <table class="level-one-table" mat-table [dataSource]="dataSource">

            <!-- Menu Column -->
            <ng-container matColumnDef="Menu">
                <th mat-header-cell *matHeaderCellDef>Menu</th>
                <td mat-cell *matCellDef="let element;let i = index">{{element.menu}}
                </td>
            </ng-container>

            <!-- Create/Add Column -->
            <ng-container matColumnDef="Create/Add">
                <th mat-header-cell *matHeaderCellDef>Create/Add </th>
                <td mat-cell *matCellDef="let element;let i = index">
                    <div class="check-box">
                        <mat-checkbox [checked]="element.create" [disabled]="element.view"
                            [(ngModel)]="element.create"></mat-checkbox>
                    </div>
                </td>
            </ng-container>

            <!-- Edit Column -->
            <ng-container matColumnDef="Edit">
                <th mat-header-cell *matHeaderCellDef> Edit </th>
                <td mat-cell *matCellDef="let element;let i = index">
                    <div class="check-box">
                        <mat-checkbox [checked]="element.edit && !element.view" [disabled]="element.view"
                            [(ngModel)]="element.edit"></mat-checkbox>

                    </div>
                </td>
            </ng-container>

            <!-- Delete Column -->
            <ng-container matColumnDef="Delete">
                <th mat-header-cell *matHeaderCellDef> Delete </th>
                <td mat-cell *matCellDef="let element;let i = index">
                    <div class="check-box"> <mat-checkbox [checked]="element.delete" [disabled]="element.view"
                            [(ngModel)]="element.delete"></mat-checkbox></div>
                </td>
            </ng-container>
            <!-- View only Column -->
            <ng-container matColumnDef="View only">
                <th mat-header-cell *matHeaderCellDef>View only </th>
                <td mat-cell *matCellDef="let element;let i = index">
                    <div class="check-box">
                        <mat-checkbox (change)="checkView(i)" [checked]="element.view"
                            [(ngModel)]="element.view"></mat-checkbox>
                    </div>
                </td>
            </ng-container>



            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>

    </div>

    <div *ngIf="dataSource.data?.length" style="margin: 50px 0px;" fxLayout="row" fxLayoutAlign="center center"
        fxLayoutGap="50px">
        <button class="submit grow" mat-stroked-button (click)="submit()">{{'Submit'}}</button>
        <button class="cancel grow" mat-stroked-button (click)="cancel()">Cancel</button>

    </div>
</mat-card>