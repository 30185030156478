<div class="m-p-1" fxFlex="100" fxLayout="column" fxLayoutGap="10" style="overflow: auto;">
    <mat-card style="background-color: #166DA6;color: white;height:4.2rem;" fxLayout="row"
        fxLayoutAlign="space-between center">
        <div fxLayout="column">
            <span style="font-size: 13px;color: white;font-weight: bold;line-height: 1;">{{shed_name}}</span>
            <span style="line-height: 1;margin-top: 7px;font-size: 10px;
color: #FFFFFF;">{{company_name}}</span>
        </div>
        <div>
        </div>

    </mat-card>
    <mat-card>

        <div *ngIf="!dataSource.data.length">
            <app-common-message [message]="'score.empty_msg'|translate|titlecase">
            </app-common-message>
        </div>
        <div>
            <span mat-card-title style="font-size: 18px;">{{'score.Daily Score'|translate|titlecase}}</span>
        </div>
        <div style="overflow-x: auto;height: 68vh;" *ngIf="dataSource?.data?.length">
            <table class="level-one-table" mat-table [dataSource]="dataSource">
                <!-- s_no Column -->
                <ng-container matColumnDef="s_no">
                    <th mat-header-cell *matHeaderCellDef>{{'score.s_no'|translate|titlecase}}</th>
                    <td mat-cell *matCellDef="let element;let i = index">
                        {{i+1}}
                    </td>
                </ng-container>
                <!-- Date Column -->
                <ng-container matColumnDef="Date">
                    <th mat-header-cell *matHeaderCellDef>{{'score.Date'|translate|titlecase}}</th>
                    <td mat-cell *matCellDef="let element;let i = index">
                        {{element.dated_on
                        |date:'d-MM-YYYY'}}
                    </td>
                </ng-container>

                <!-- No.Of Female Live Birds Column -->
                <ng-container matColumnDef="No.Of Female Live Birds">
                    <th mat-header-cell *matHeaderCellDef> {{'score.No.Of Female Live Birds'|translate|titlecase}} </th>
                    <td mat-cell *matCellDef="let element"> {{element.fish_opening_stock}} </td>
                </ng-container>
                <!-- Avg Female Bird Weight Column -->
                <ng-container matColumnDef="Avg Female Bird Weight">
                    <th mat-header-cell *matHeaderCellDef> {{'score.Avg Female Bird Weight'|translate|titlecase}} </th>
                    <td mat-cell *matCellDef="let element"> {{element.avg_f_weight}} </td>
                </ng-container>
                <!-- No.Of Male Live Birds Column -->
                <ng-container matColumnDef="No.Of Male Live Birds">
                    <th mat-header-cell *matHeaderCellDef> {{'score.No.Of Male Live Birds'|translate|titlecase}} </th>
                    <td mat-cell *matCellDef="let element"> {{element.male_opening_stock}} </td>
                </ng-container>
                <!-- Avg Male Bird Weight Column -->
                <ng-container matColumnDef="Avg Male Bird Weight">
                    <th mat-header-cell *matHeaderCellDef> {{'score.Avg Male Bird Weight'|translate|titlecase}} </th>
                    <td mat-cell *matCellDef="let element"> {{element.avg_m_bird_weight}} </td>
                </ng-container>

                <!-- Total Egg /Day Column -->
                <ng-container matColumnDef="Total Egg /Day">
                    <th mat-header-cell *matHeaderCellDef>{{'score.Total Egg /Day'|translate|titlecase}} </th>
                    <td mat-cell *matCellDef="let element"> {{element.total_egg_count}} </td>
                </ng-container>

                <!-- Total Egg % Column -->
                <ng-container matColumnDef="Total Egg %">
                    <th mat-header-cell *matHeaderCellDef> {{'score.Total Egg %'|translate|titlecase}} </th>
                    <td mat-cell *matCellDef="let element"> {{element.t_egg_percentage}} </td>
                </ng-container>
                <!-- Total Egg Score Column -->
                <ng-container matColumnDef="Total Egg Score">
                    <th mat-header-cell *matHeaderCellDef> {{'score.Total Egg Score'|translate|titlecase}} </th>
                    <td mat-cell *matCellDef="let element" style="color: blue;"> {{element.t_egg_percentage_score}}
                    </td>
                </ng-container>
                <!-- Selection Egg / Day Column -->
                <ng-container matColumnDef="Selection Egg / Day">
                    <th mat-header-cell *matHeaderCellDef>{{'score.Selection Egg / Day'|translate|titlecase}} </th>
                    <td mat-cell *matCellDef="let element"> {{element.hatching_egg_count}} </td>
                </ng-container>

                <!-- Selection Egg % Column -->
                <ng-container matColumnDef="Selection Egg %">
                    <th mat-header-cell *matHeaderCellDef>{{'score.Selection Egg %'|translate|titlecase}} </th>
                    <td mat-cell *matCellDef="let element"> {{element.h_egg_percentage}} </td>
                </ng-container>
                <!-- Selection Egg Score Column -->
                <ng-container matColumnDef="Selection Egg Score">
                    <th mat-header-cell *matHeaderCellDef>{{'score.Selection Egg Score'|translate|titlecase}} </th>
                    <td mat-cell *matCellDef="let element" style="color: blue;"> {{element.h_egg_percentage_score}}
                    </td>
                </ng-container>
                <!-- Cumulative Total Egg Column -->
                <ng-container matColumnDef="Cumulative Total Egg">
                    <th mat-header-cell *matHeaderCellDef>{{'score.Cumulative Total Egg'|translate|titlecase}} </th>
                    <td mat-cell *matCellDef="let element"> {{element.cumulative_t_egg}} </td>
                </ng-container>
                <!-- Cumulative Total Egg Score Column -->
                <ng-container matColumnDef="Cumulative Total Egg Score">
                    <th mat-header-cell *matHeaderCellDef>{{'score.Cumulative Total Egg Score'|translate|titlecase}}
                    </th>
                    <td mat-cell *matCellDef="let element" style="color: blue;">
                        {{element.cumulative_t_egg_score}}
                    </td>
                </ng-container>
                <!-- Cumulative Selection Egg Column -->
                <ng-container matColumnDef="Cumulative Selection Egg">
                    <th mat-header-cell *matHeaderCellDef>{{'score.Cumulative Selection Egg'|translate|titlecase}} </th>
                    <td mat-cell *matCellDef="let element"> {{element.cumulative_h_egg}}</td>
                </ng-container>
                <!-- Cumulative Selection Egg Score Column -->
                <ng-container matColumnDef="Cumulative Selection Egg Score">
                    <th mat-header-cell *matHeaderCellDef>{{'score.Cumulative Selection Egg Score'|translate|titlecase}}
                    </th>
                    <td mat-cell *matCellDef="let element" style="color: blue;">
                        {{element.cumulative_h_egg_score}}
                    </td>
                </ng-container>

                <!-- Egg Weight Column -->
                <ng-container matColumnDef="Egg Weight">
                    <th mat-header-cell *matHeaderCellDef>{{'score.Egg Weight'|translate|titlecase}} </th>
                    <td mat-cell *matCellDef="let element">
                        {{element.avg_weight}}
                    </td>
                </ng-container>
                <!-- Egg Weight Score Column -->
                <ng-container matColumnDef="Egg Weight Score">
                    <th mat-header-cell *matHeaderCellDef>{{'score.Egg Weight Score'|translate|titlecase}} </th>
                    <td mat-cell *matCellDef="let element" style="color: blue;">
                        {{element.egg_weight_percentage_score}}
                    </td>
                </ng-container>
                <!-- No of Female Mortality / day Column -->
                <ng-container matColumnDef="No of Female Mortality / day">
                    <th mat-header-cell *matHeaderCellDef>{{'score.No of Female Mortality / day'|translate|titlecase}}
                    </th>
                    <td mat-cell *matCellDef="let element">
                        {{element.fish_mortality_count}}
                    </td>
                </ng-container>
                <!-- Mortality Female % Column -->
                <ng-container matColumnDef="Mortality Female %">
                    <th mat-header-cell *matHeaderCellDef>{{'score.Mortality Female %'|translate|titlecase}} </th>
                    <td mat-cell *matCellDef="let element">
                        {{element.female_mortality_percentage}}
                    </td>
                </ng-container>
                <!-- Mortality Female Score Column -->
                <ng-container matColumnDef="Mortality Female Score">
                    <th mat-header-cell *matHeaderCellDef>{{'score.Mortality Female Score'|translate|titlecase}} </th>
                    <td mat-cell *matCellDef="let element" style="color: blue;">
                        {{element.female_mortality_score_sop}}
                    </td>
                </ng-container>
                <!-- No of Male Mortality / day Column -->
                <ng-container matColumnDef="No of Male Mortality / day">
                    <th mat-header-cell *matHeaderCellDef>{{'score.No of Male Mortality / day'|translate|titlecase}}
                    </th>
                    <td mat-cell *matCellDef="let element">
                        {{element.male_mortality_count}}
                    </td>
                </ng-container>
                <!-- Mortality Male % Column -->
                <ng-container matColumnDef="Mortality Male %">
                    <th mat-header-cell *matHeaderCellDef>{{'score.Mortality Male %'|translate|titlecase}} </th>
                    <td mat-cell *matCellDef="let element">
                        {{element.male_mortality_percentage}}
                    </td>
                </ng-container>
                <!-- Mortality Male Score Column -->
                <ng-container matColumnDef="Mortality Male Score">
                    <th mat-header-cell *matHeaderCellDef>{{'score.Mortality Male Score'|translate|titlecase}} </th>
                    <td mat-cell *matCellDef="let element" style="color: blue;">
                        {{element.male_mortality_score_sop}}
                    </td>
                </ng-container>
                <!-- total female birds on batch start date Column -->
                <ng-container matColumnDef="total female birds on batch start date">
                    <th mat-header-cell *matHeaderCellDef>
                        {{'score.Total Female Birds on Batch Start Date'|translate|titlecase}}
                    </th>
                    <td mat-cell *matCellDef="let element">
                        {{this.batch_data.fish_opening_stock}}
                    </td>
                </ng-container>
                <!-- total male birds on batch start date Column -->
                <ng-container matColumnDef="total male birds on batch start date">
                    <th mat-header-cell *matHeaderCellDef>
                        {{'score.Total Male Birds on Batch Start Date'|translate|titlecase}}
                    </th>
                    <td mat-cell *matCellDef="let element">
                        {{this.batch_data.start_male_count}}
                    </td>
                </ng-container>
                <!-- Cumulative Female total mortality as on day Column -->
                <ng-container matColumnDef="Cumulative Female total mortality as on day">
                    <th mat-header-cell *matHeaderCellDef>
                        {{'score.Cumulative Female Total Mortality as on Day'|translate|titlecase}}
                    </th>
                    <td mat-cell *matCellDef="let element">
                        {{element.cumulative_female_mortality_count}}
                    </td>
                </ng-container>
                <!-- Cumulative Mortality Female % Column -->
                <ng-container matColumnDef="Cumulative Mortality Female %">
                    <th mat-header-cell *matHeaderCellDef>{{'score.Cumulative Mortality Female %'|translate|titlecase}}
                    </th>
                    <td mat-cell *matCellDef="let element">
                        {{element.cumulative_female_mortality_percentage}}
                    </td>
                </ng-container>
                <!-- Cumulative Mortality Female Score Column -->
                <ng-container matColumnDef="Cumulative Mortality Female Score">
                    <th mat-header-cell *matHeaderCellDef>
                        {{'score.Cumulative Mortality Female Score'|translate|titlecase}}
                    </th>
                    <td mat-cell *matCellDef="let element" style="color: blue;">
                        {{element.cumulative_female_mortality_score_sop}}
                    </td>
                </ng-container>
                <!-- Cumulative Male total mortality as on day Column -->
                <ng-container matColumnDef="Cumulative Male total mortality as on day">
                    <th mat-header-cell *matHeaderCellDef>
                        {{'score.Cumulative Male Total Mortality as on Day'|translate|titlecase}}
                    </th>
                    <td mat-cell *matCellDef="let element">
                        {{element.cumulative_male_mortality_count}}
                    </td>
                </ng-container>
                <!-- Cumulative Mortality Male % Column -->
                <ng-container matColumnDef="Cumulative Mortality Male %">
                    <th mat-header-cell *matHeaderCellDef>{{'score.Cumulative Mortality Male %'|translate|titlecase}}
                    </th>
                    <td mat-cell *matCellDef="let element">
                        {{element.cumulative_male_mortality_percentage}}
                    </td>
                </ng-container>
                <!-- Cumulative Mortality Male Score Column -->
                <ng-container matColumnDef="Cumulative Mortality Male Score">
                    <th mat-header-cell *matHeaderCellDef>
                        {{'score.Cumulative Mortality Male Score'|translate|titlecase}}
                    </th>
                    <td mat-cell *matCellDef="let element" style="color: blue;">
                        {{element.cumulative_male_mortality_score_sop}}
                    </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
        </div>
    </mat-card>

    <div style="padding-top: 2rem;">
        <mat-card>

            <div *ngIf="!weeklyDataSource.data.length">
                <app-common-message [message]="'score.empty_msg'|translate|titlecase">
                </app-common-message>
            </div>
            <div>
                <span mat-card-title style="font-size: 18px;">{{'score.Weekly Score'|translate|titlecase}}</span>
            </div>
            <div style="overflow-x: auto;height: 68vh;" *ngIf="weeklyDataSource?.data?.length">
                <table class="level-one-table" mat-table [dataSource]="weeklyDataSource">
                    <!-- s_no Column -->
                    <ng-container matColumnDef="s_no">
                        <th mat-header-cell *matHeaderCellDef>{{'score.s_no'|translate|titlecase}}</th>
                        <td mat-cell *matCellDef="let element;let i = index">
                            {{i+1}}
                        </td>
                    </ng-container>
                    <!-- Date Column -->
                    <ng-container matColumnDef="Date">
                        <th mat-header-cell *matHeaderCellDef>{{'score.Date'|translate|titlecase}}</th>
                        <td mat-cell *matCellDef="let element;let i = index">
                            {{element.date
                        |date:'d-MM-YYYY'}}
                        </td>
                    </ng-container>

                    <!-- Weekly Female Bird Weight Column -->
                    <ng-container matColumnDef="Weekly Female Bird Weight">
                        <th mat-header-cell *matHeaderCellDef> {{'score.Weekly Female Bird Weight'|translate|titlecase}}
                        </th>
                        <td mat-cell *matCellDef="let element"> {{element.weekly_f_bird_weight}} </td>
                    </ng-container>

                    <!-- Weekly Female Bird Weight Score Column -->
                    <ng-container matColumnDef="Weekly Female Bird Weight Score">
                        <th mat-header-cell *matHeaderCellDef>
                            {{'score.Weekly Female Bird Weight Score'|translate|titlecase}}
                        </th>
                        <td mat-cell *matCellDef="let element" style="color: blue;"> {{element.f_bird_weight_score}}
                        </td>
                    </ng-container>
                    <!-- Weekly Female Bird Weight Gain Column -->
                    <ng-container matColumnDef="Weekly Female Bird Weight Gain">
                        <th mat-header-cell *matHeaderCellDef>
                            {{'score.Weekly Female Bird Weight Gain'|translate|titlecase}}
                        </th>
                        <td mat-cell *matCellDef="let element"> {{element.weekly_f_weight_gain}} </td>
                    </ng-container>

                    <!-- Weekly Female Bird Weight Gain Score Column -->
                    <ng-container matColumnDef="Weekly Female Bird Weight Gain Score">
                        <th mat-header-cell *matHeaderCellDef>
                            {{'score.Weekly Female Bird Weight Gain Score'|translate|titlecase}}
                        </th>
                        <td mat-cell *matCellDef="let element" style="color: blue;">
                            {{element.f_bird_weight_gain_score}}
                        </td>
                    </ng-container>

                    <!-- Weekly Male Bird Weight Column -->
                    <ng-container matColumnDef="Weekly Male Bird Weight">
                        <th mat-header-cell *matHeaderCellDef> {{"score.Weekly Male Bird Weight"|translate|titlecase}}
                        </th>
                        <td mat-cell *matCellDef="let element"> {{element.weekly_m_bird_weight}} </td>
                    </ng-container>

                    <!-- Weekly Male Bird Weight Score Column -->
                    <ng-container matColumnDef="Weekly Male Bird Weight Score">
                        <th mat-header-cell *matHeaderCellDef>
                            {{'score.Weekly Male Bird Weight Score'|translate|titlecase}}
                        </th>
                        <td mat-cell *matCellDef="let element" style="color: blue;">
                            {{element.m_bird_weight_score}}
                        </td>
                    </ng-container>

                    <!-- Weekly Male Bird Weight Gain Column -->
                    <ng-container matColumnDef="Weekly Male Bird Weight Gain">
                        <th mat-header-cell *matHeaderCellDef>
                            {{'score.Weekly Male Bird Weight Gain'|translate|titlecase}}
                        </th>
                        <td mat-cell *matCellDef="let element"> {{element.weekly_m_weight_gain}} </td>
                    </ng-container>

                    <!-- Weekly Male Bird Weight Gain Score Column -->
                    <ng-container matColumnDef="Weekly Male Bird Weight Gain Score">
                        <th mat-header-cell *matHeaderCellDef>
                            {{'score.Weekly Male Bird Weight Gain Score'|translate|titlecase}}
                        </th>
                        <td mat-cell *matCellDef="let element" style="color: blue;">
                            {{element.m_bird_weight_gain_score}}
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="weeklyDisplayColumn;sticky: true"></tr>
                    <tr mat-row *matRowDef="let row; columns: weeklyDisplayColumn;"></tr>
                </table>
            </div>
        </mat-card>
    </div>













</div>