<form>
    <div fxFlex="100" fxLayout="column">
        <mat-card class="m-p-1" style="background-color: #166DA6;color: white;" fxLayout="row" fxLayoutGap="10px"
            fxLayoutAlign="space-between center">
            <div fxLayout="column" fxFlex="65">
                <span style="font-size: 13px;color: white;font-weight: bold;line-height: 1;">{{tank_data?.name}}</span>
                <span style="line-height: 1;margin-top: 7px;font-size: 10px;
                color: #FFFFFF;">{{company_name}}</span>
            </div>
            <div fxLayoutAlign="end center" fxFlex="45" class="mrglef" fxLayoutGap="10">

                <div class="progress-div" *ngIf="regenerate_status == 'pending'">
                    <mat-progress-bar class="progress-bar" mode="determinate"
                        [value]="progress_percentage"></mat-progress-bar>
                    <div class="progress-data">{{progress_percentage}}%</div>
                </div>



                <button *ngIf="regenerate_status !== 'pending'" mat-stroked-button class="add-button grow"
                    (click)="reportRegenerateMissing();" [disabled]="regenerate_status == 'pending'">
                    <span fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="5">
                        <mat-spinner strokeWidth="3" [diameter]="25"
                            *ngIf="regenerate_status == 'pending'"></mat-spinner>
                        <mat-icon *ngIf="regenerate_status !== 'pending'"
                            style="height: 22px;width: 13px;padding-right: 13px !important; padding-left: -8px !important;">restore_page</mat-icon>
                        <span>{{regenerate_status == 'pending'? ('Regenerating'|translate|titlecase)+'...' :'report.regenerate'|translate|uppercase}}</span>
                    </span>


                </button>
                <button class="buttonclass grow buttonclass1" mat-stroked-button (click)="downloadReport()"
                    fxLayoutAlign="space-between center" [disabled]="openTrigger() || regenerate_status == 'pending' ">
                    <mat-icon class="iconvalue" svgIcon="downloadicon"></mat-icon>
                    <span class="hide"> {{'button.download'|translate|titlecase}}</span>
                </button>

                <button class="buttonclass grow buttonclass1" mat-stroked-button (click)="viewReport($event)"
                    fxLayoutAlign="space-between center" [disabled]="openTrigger() || regenerate_status == 'pending' ">
                    <mat-icon class="iconvalue">visibility</mat-icon>
                    <span class="hide"> View </span>
                </button>
            </div>
        </mat-card>
        <mat-card fxFlex="100" class="m-p-1 minheight" fxLayout="row" fxLayoutAlign="center center">
            <form [formGroup]="report_from_group" fxFlex="30" fxFlex.lt-md="65" fxFlex.lt-sm="100" class="m-p-1"
                fxLayout="column" class="selectivereports">
                <mat-form-field appearance="outline" #triggerReport="matMenuTrigger" [matMenuTriggerFor]="report"
                    (click)="triggerReport.openMenu()" matSuffix>
                    <mat-label>{{'report.choose_report'|translate|titlecase}}</mat-label>
                    <!-- <mat-select class="matselect" formControlName="report"  disableOptionCentering panelClass="panelclass1">
                        <mat-option *ngFor="let report of reportsList" [value]="report.id">
                            {{report.name}}
                        </mat-option>
                    </mat-select> -->
                    <input type="text" matInput formControlName="report" [readonly]="true" autocomplete="new-password" value="">
                    <mat-icon style="color: #0000008a;" matSuffix>arrow_drop_down</mat-icon>
                </mat-form-field>
                <mat-menu #report="matMenu" xPosition="before" class="matmenureport">
                    <button mat-menu-item
                        (click)="selectReport('report.productivity_report',2)">{{'report.productivity_report'|translate|titlecase}}</button>
                    <button mat-menu-item
                        (click)="selectReport('report.sensor_report',1)">{{'report.sensor_report'|translate|titlecase}}</button>
                </mat-menu>
                <mat-form-field appearance="outline" #PeriodTrigger="matMenuTrigger" [matMenuTriggerFor]="menu"
                    (click)="PeriodTrigger.openMenu()" matSuffix
                    [ngStyle]="openTrigger()? {'pointer-events':'none'}:{}">
                    <mat-label>{{'report.report_type'|translate|titlecase}}</mat-label>
                    <input type="text" matInput formControlName="report_type" autocomplete="new-password" value="">
                    <mat-icon style="color: #0000008a;" matSuffix>arrow_drop_down</mat-icon>
                </mat-form-field>
                <mat-menu #menu="matMenu" xPosition="before" class="matmenureport">
                    <button mat-menu-item [matMenuTriggerFor]="weekly">{{'report.weekly'|translate|titlecase}}</button>
                    <button mat-menu-item
                        [matMenuTriggerFor]="monthly">{{'report.monthly'|translate|titlecase}}</button>
                </mat-menu>

                <mat-menu #weekly="matMenu">
                    <div *ngIf="weeklyOptions.length>0">
                        <mat-selection-list #weeklyList (click)="checkAlert($event)">
                            <mat-list-option *ngFor="let week of weeklyOptions" [disabled]="week.file_key==null"
                                [value]="week.collection_id" (click)="onWeeklyOptionClick($event, week, monthlyList)">
                                {{'report.week'|translate|titlecase}} {{ week.week }}
                            </mat-list-option>
                        </mat-selection-list>
                    </div>
                    <div *ngIf="weeklyOptions.length==0">
                        <mat-selection-list (click)="defineNot($event,'Week')">
                            <mat-list-option [disabled]="true" [selected]="false">
                                {{'report.current_week'|translate|titlecase}}
                            </mat-list-option>
                        </mat-selection-list>
                    </div>
                </mat-menu>

                <mat-menu #monthly="matMenu">
                    <div *ngIf="monthlyOptions.length>0">
                        <mat-selection-list #monthlyList (click)="checkAlert($event)">
                            <mat-list-option *ngFor="let month of monthlyOptions" [disabled]="month.file_key==null"
                                [value]="month.collection_id" (click)="onMonthlyOptionClick($event, month, weeklyList)">
                                {{ month.month }}
                            </mat-list-option>
                        </mat-selection-list>
                    </div>
                    <div *ngIf="monthlyOptions.length==0">
                        <mat-selection-list (click)="defineNot($event,'Month')">
                            <mat-list-option [disabled]="true" [selected]="false">
                                {{'report.current_month'|translate|titlecase}}
                            </mat-list-option>
                        </mat-selection-list>
                    </div>
                </mat-menu>
                <!-- <mat-form-field appearance="outline" >
                    <mat-label>Report Type</mat-label>
                    <mat-select class="matselect" formControlName="report_period" multiple disableOptionCentering panelClass="panelclass1">
                        <mat-option *ngFor="let type of optionlist" [value]="type.id" >
                        {{type.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field> -->
            </form>
        </mat-card>
    </div>
</form>