<form>
    <div fxFlex="100" fxLayout="column">
        <mat-card class="m-p-1" style="background-color: #166DA6;color: white;" fxLayout="row" fxLayoutGap="10px"
            fxLayoutAlign="space-between center">
            <div fxLayout="column" fxFlex="25" class="hide">
                <span style="font-size: 13px;color: white;font-weight: bold;line-height: 1;">{{shed_name}}</span>
                <span style="line-height: 1;margin-top: 7px;font-size: 10px;
                color: #FFFFFF;">{{company_name}}</span>
            </div>
            <!-- Start date -->
            <div fxLayout="row" fxFlex.lt-md="35" fxFlex="25" fxLayoutAlign="space-between center" fxLayoutGap="20">
                <div fxFlex="50" class="hide endstate" mat-card-title style="font-size: 14px;color: white;">
                    {{'custom_report.start_from'|translate|titlecase}}
                </div>
                <div fxFlex="50"
                    style="font-size: 12px;background-color: white !important;color: #166DA6;border-radius: 10px;"
                    class="report-calender maxwidth">
                    <mat-form-field appearance="outline" class="headerdate">
                        <div fxLayout="row" style="height: 20px !important;">
                            <input matInput [matDatepicker]="startDate" [formControl]="Date_from" [max]="max_date1"
                                (dateInput)="fromDateChange($event)" [readonly]="true">
                            <mat-datepicker-toggle
                                style="position: relative !important; bottom: 12px !important;margin-right: -10px;"
                                matIconSuffix [for]="startDate"></mat-datepicker-toggle>
                            <mat-datepicker #startDate></mat-datepicker>
                        </div>
                    </mat-form-field>
                </div>
            </div>
            <!-- End date -->
            <div fxLayout="row" fxFlex.lt-md="35" fxFlex="25" fxLayoutAlign="space-between center" fxLayoutGap="20">
                <div fxFlex="50" class="hide endstate" mat-card-title style="font-size: 14px;color: white;">{{'custom_report.to'|translate|titlecase}}</div>
                <div fxFlex="50"
                    style="font-size: 12px;background-color: white !important;color: #166DA6;border-radius: 10px;"
                    class="report-calender maxwidth">
                    <mat-form-field appearance="outline" class="headerdate">
                        <div fxLayout="row" style="height: 20px !important;">
                            <input matInput [matDatepicker]="endDate" [formControl]="Date_to" [max]="max_date"
                                [min]="min_date" (dateInput)="toDateChange($event)" [readonly]="true">
                            <mat-datepicker-toggle
                                style="position: relative !important; bottom: 12px !important;margin-right: -10px;"
                                matIconSuffix [for]="endDate"></mat-datepicker-toggle>
                            <mat-datepicker #endDate></mat-datepicker>
                        </div>
                    </mat-form-field>
                </div>
            </div>
            <!-- Select all -->
            <div fxLayout="row" fxFlex="10" fxLayoutAlign="space-between center" class="hide">
                <div fxFlex="70" mat-card-title style="font-size: 14px;color: white;">
                    {{'custom_report.select_all' |translate |titlecase}}
                </div>
                <div fxFlex="20" class="report-calender headercheck">
                    <mat-checkbox (change)="selectAll($event)" [formControl]="selectallbtn"></mat-checkbox>
                </div>
            </div>
            <!-- download -->
            <div fxLayoutAlign="end center" fxFlex="15" class="mrglef">
                <button class="buttonclass grow buttonclass1" mat-stroked-button *ngIf="!isSensor"
                    (click)="downloadProductivityReport()" fxLayoutAlign="space-between center">
                    <mat-icon class="iconvalue" svgIcon="downloadicon"></mat-icon>
                    <span class="hide"> {{'button.download'|translate|titlecase}}</span>
                </button>
                <button class="buttonclass grow buttonclass1" mat-stroked-button *ngIf="isSensor"
                    (click)="downloadSensorData()" fxLayoutAlign="space-between center">
                    <mat-icon class="iconvalue" svgIcon="downloadicon"></mat-icon>
                    <span class="hide"> {{'button.download'|translate|titlecase}}</span>
                </button>

            </div>
        </mat-card>
        <div fxFlex="100" fxLayoutGap="5" class="tab-containerReport m-p-1" fxLayoutAlign="space-between center">
            <mat-tab-group class="dashview" fxFlex="100" (selectedTabChange)=" selectTab()" [selectedIndex]="0">
                <mat-tab [label]="'custom_report.productivity_report'|translate|titlecase"></mat-tab>
                <mat-tab [label]="'custom_report.sensor_report'|translate|titlecase"></mat-tab>
            </mat-tab-group>
            <mat-tab-group class="mobview" fxFlex="75" (selectedTabChange)=" selectTab()" [selectedIndex]="0">
                <mat-tab [label]="'custom_report.productivity'|translate|titlecase"></mat-tab>
                <mat-tab [label]="'custom_report.sensor'|translate|titlecase"></mat-tab>
            </mat-tab-group>
            <!-- select all for mob and tab view -->
            <div fxFlex="20" class="snow m-p-1" fxLayoutAlign="end center" fxLayoutGap="5">
                <div mat-card-title style="font-size: 14px;color: #166DA6;display: flex;justify-content: end;">All
                </div>
                <div class="report-calender alignog">
                    <mat-checkbox (change)="selectAll($event)" [formControl]="selectallbtn"></mat-checkbox>
                </div>
            </div>
        </div>
        <mat-card fxFlex="100" class="m-p-1" fxLayout="row" *ngIf="!isSensor">
            <form [formGroup]="report_from_group" fxFlex="100" class="m-p-1" fxLayout="row" fxLayout.lt-sm="column"
                fxLayoutGap="20px" style="height: 100%">
                <div fxLayout="column" fxFlex="33" fxLayoutGap="20px">

                    <!-- opening Stock  -->
                    <mat-card class="innercard" fxLayout="row" fxLayoutAlign="space-between center">
                        <div mat-card-title class="label dataAlign">
                            {{'custom_report.opening_stock'|translate|titlecase}}
                        </div>
                        <div style="cursor: pointer;" class="bodychecked" fxLayoutAlign="center center">
                            <mat-checkbox formControlName="fish_opening_stock" [color]="'accent'"
                                (change)="selectAllCheck($event)">
                            </mat-checkbox>
                        </div>
                    </mat-card>
                    <!-- depletion -->
                    <mat-card class="innercard" fxLayout="column">
                        <div mat-card-title class="label Btlable">{{'custom_report.depletion'|translate|titlecase}}
                        </div>
                        <div fxFlex="100" fxLayout="column" fxLayoutGap="30">
                            <div fxFlex="100" fxLayout="row" fxLayoutAlign="space-between center">
                                <div class="field">{{'custom_report.mortality'|translate|titlecase}}</div>
                                <div style="cursor: pointer;" class="bodychecked" fxLayoutAlign="center center">
                                    <mat-checkbox formControlName="fish_mortality_count"
                                        (change)="selectAllCheck($event)"></mat-checkbox>
                                </div>
                            </div>
                            <div fxFlex="100" fxLayout="row" fxLayoutAlign="space-between center">
                                <div class="field">{{'custom_report.sold'|translate|titlecase}}</div>
                                <div style="cursor: pointer;" class="bodychecked" fxLayoutAlign="center center">
                                    <mat-checkbox formControlName="fish_sold_count"
                                        (change)="selectAllCheck($event)"></mat-checkbox>
                                </div>
                            </div>
                            <div fxFlex="100" fxLayout="row" fxLayoutAlign="space-between center">
                                <div class="field">{{'custom_report.transfer_in'|translate|titlecase}}</div>
                                <div style="cursor: pointer;" class="bodychecked" fxLayoutAlign="center center">
                                    <mat-checkbox formControlName="fish_transfer_in_count"
                                        (change)="selectAllCheck($event)"></mat-checkbox>
                                </div>
                            </div>
                            <div fxFlex="100" fxLayout="row" fxLayoutAlign="space-between center">
                                <div class="field">{{'custom_report.transfer_out'|translate|titlecase}}</div>
                                <div style="cursor: pointer;" class="bodychecked" fxLayoutAlign="center center">
                                    <mat-checkbox formControlName="fish_transfer_out_count"
                                        (change)="selectAllCheck($event)"></mat-checkbox>
                                </div>
                            </div>
                        </div>
                    </mat-card>
                    <!-- Closing Stock  -->
                    <mat-card class="innercard" fxLayout="row" fxLayoutAlign="space-between center">
                        <div mat-card-title class="label dataAlign">
                            {{'custom_report.closing_stock'|translate|titlecase}}
                        </div>
                        <div style="cursor: pointer;" class="bodychecked" fxLayoutAlign="center center">
                            <mat-checkbox formControlName="fish_closing_stock"
                                (change)="selectAllCheck($event)"></mat-checkbox>
                        </div>
                    </mat-card>
                    <!-- Feed issue -->
                    <mat-card class="innercard" fxLayout="column">
                        <div mat-card-title class="label Btlable">{{'custom_report.feed_issue'|translate|titlecase}}
                        </div>
                        <div fxFlex="100" fxLayout="column" fxLayoutGap="30">
                            <div fxFlex="100" fxLayout="row" fxLayoutAlign="space-between center">
                                <div class="field">{{'custom_report.type_of_feed'|translate|titlecase}}</div>
                                <div style="cursor: pointer;" class="bodychecked" fxLayoutAlign="center center">
                                    <mat-checkbox formControlName="type_feed"
                                        (change)="selectAllCheck($event)"></mat-checkbox>
                                </div>
                            </div>
                            <div fxFlex="100" fxLayout="row" fxLayoutAlign="space-between center">
                                <div class="field">{{
                                    birdtype != "shrimp"
                                    ? ("custom_report.feed_in_grams_f" | translate | titlecase)
                                    : ("custom_report.feed_in_grams_s" | translate | titlecase)
                                    }}</div>
                                <div style="cursor: pointer;" class="bodychecked" fxLayoutAlign="center center">
                                    <mat-checkbox formControlName="feed_per_fish"
                                        (change)="selectAllCheck($event)"></mat-checkbox>
                                </div>
                            </div>
                            <div fxFlex="100" fxLayout="row" fxLayoutAlign="space-between center">
                                <div class="field">{{
                                    birdtype != "shrimp"
                                    ? ("custom_report.weight_in_grams_f" | translate | titlecase)
                                    : ("custom_report.weight_in_grams_s" | translate | titlecase)
                                    }}</div>
                                <div style="cursor: pointer;" class="bodychecked" fxLayoutAlign="center center">
                                    <mat-checkbox formControlName="avg_f_weight"
                                        (change)="selectAllCheck($event)"></mat-checkbox>
                                </div>
                            </div>
                        </div>
                    </mat-card>
                    <!-- Labour attendance  -->
                    <mat-card class="innercard" fxLayout="row" fxLayoutAlign="space-between center">
                        <div mat-card-title class="label dataAlign">
                            {{'custom_report.labour_attendence'|translate|titlecase}}
                        </div>
                        <div style="cursor: pointer;" class="bodychecked" fxLayoutAlign="center center">
                            <mat-checkbox formControlName="labour_attendance"
                                (change)="selectAllCheck($event)"></mat-checkbox>
                        </div>
                    </mat-card>
                </div>
                
                <div fxLayout="column" fxFlex="33" fxLayoutGap="20px">
                    <!-- Delivery challan  -->
                    <mat-card class="innercard" fxLayout="row" fxLayoutAlign="space-between center"
                        *ngIf="birdtype=='broiler'">
                        <div mat-card-title class="label dataAlign">
                            {{'custom_report.delivery_challan'|translate|titlecase}}
                        </div>
                        <div style="cursor: pointer;" class="bodychecked" fxLayoutAlign="center center">
                            <mat-checkbox formControlName="delivary_challan"
                                (change)="selectAllCheck($event)"></mat-checkbox>
                        </div>
                    </mat-card>
                    <!-- Medication  -->
                    <mat-card class="innercard" fxLayout="row" fxLayoutAlign="space-between center">
                        <div mat-card-title class="label dataAlign">{{'custom_report.medication'|translate|titlecase}}
                        </div>
                        <div style="cursor: pointer;" class="bodychecked" fxLayoutAlign="center center">
                            <mat-checkbox formControlName="medication" (change)="selectAllCheck($event)"></mat-checkbox>
                        </div>
                    </mat-card>
                    <!-- Equipment  -->
                    <mat-card class="innercard" fxLayout="row" fxLayoutAlign="space-between center">
                        <div mat-card-title class="label dataAlign">{{'custom_report.equipment'|translate|titlecase}}
                        </div>
                        <div style="cursor: pointer;" class="bodychecked" fxLayoutAlign="center center">
                            <mat-checkbox formControlName="equipment" (change)="selectAllCheck($event)"></mat-checkbox>
                        </div>
                    </mat-card>
                </div>
            </form>
        </mat-card>
        <!-- <mat-card fxFlex="100" class="m-p-1" fxLayout="row" *ngIf="isSensor">
            <form [formGroup]="sensor_report_from_group" fxFlex="100" class="m-p-1" fxLayout="row"
                fxLayout.lt-sm="column" fxLayoutGap="20px" style="height: 100%">
                <div fxLayout="column" fxFlex="33" fxLayoutGap="20px">
                    <mat-card class="innercard" fxLayout="column">
                        <div mat-card-title class="label Btlable">Environment report</div>
                        <div fxFlex="100" fxLayout="column" fxLayoutGap="30">
                            <div fxFlex="100" fxLayout="row" fxLayoutAlign="space-between center">
                                <div class="field">Air flow</div>
                                <div style="cursor: pointer;" class="bodychecked" fxLayoutAlign="center center">
                                    <mat-checkbox formControlName="air_flow"
                                        [class.mat-checkbox-disabled]="!sensorsAvailabilty[2]"
                                        (change)="selectAllCheck($event)"
                                        [disabled]="!sensorsAvailabilty[2]"></mat-checkbox>
                                </div>
                            </div>
                            <div fxFlex="100" fxLayout="row" fxLayoutAlign="space-between center">
                                <div class="field">Ventilation(Pressure)</div>
                                <div style="cursor: pointer;" class="bodychecked" fxLayoutAlign="center center">
                                    <mat-checkbox formControlName="ventilation_pressure"
                                        [class.mat-checkbox-disabled]="!sensorsAvailabilty[3]"
                                        (change)="selectAllCheck($event)"
                                        [disabled]="!sensorsAvailabilty[3]"></mat-checkbox>
                                </div>
                            </div>
                            <div fxFlex="100" fxLayout="row" fxLayoutAlign="space-between center">
                                <div class="field">Light level</div>
                                <div style="cursor: pointer;" class="bodychecked" fxLayoutAlign="center center">
                                    <mat-checkbox formControlName="light_level"
                                        [class.mat-checkbox-disabled]="!sensorsAvailabilty[5]"
                                        (change)="selectAllCheck($event)"
                                        [disabled]="!sensorsAvailabilty[5]"></mat-checkbox>
                                </div>
                            </div>
                            <div fxFlex="100" fxLayout="row" fxLayoutAlign="space-between center">
                                <div class="field">Temperature and humidity</div>
                                <div style="cursor: pointer;" class="bodychecked" fxLayoutAlign="center center">
                                    <mat-checkbox formControlName="temp_humid"
                                        [class.mat-checkbox-disabled]="!sensorsAvailabilty[1]"
                                        (change)="selectAllCheck($event)"
                                        [disabled]="!sensorsAvailabilty[1]"></mat-checkbox>
                                </div>
                            </div>
                        </div>
                    </mat-card>
                    <mat-card class="innercard" fxLayout="column">
                        <div mat-card-title class="label Btlable">Gases</div>
                        <div fxFlex="100" fxLayout="column" fxLayoutGap="30">
                            <div fxFlex="100" fxLayout="row" fxLayoutAlign="space-between center">
                                <div class="field">Ammonia & Co</div>
                                <div style="cursor: pointer;" class="bodychecked" fxLayoutAlign="center center">
                                    <mat-checkbox formControlName="ammoniaCo"
                                        [class.mat-checkbox-disabled]="!sensorsAvailabilty[4]"
                                        (change)="selectAllCheck($event)"
                                        [disabled]="!sensorsAvailabilty[4]"></mat-checkbox>
                                </div>
                            </div>
                            <div fxFlex="100" fxLayout="row" fxLayoutAlign="space-between center">
                                <div class="field">Carbon dioxide</div>
                                <div style="cursor: pointer;" class="bodychecked" fxLayoutAlign="center center">
                                    <mat-checkbox formControlName="carbon_di"
                                        [class.mat-checkbox-disabled]="!sensorsAvailabilty[6]"
                                        (change)="selectAllCheck($event)"
                                        [disabled]="!sensorsAvailabilty[6]"></mat-checkbox>
                                </div>
                            </div>
                        </div>
                    </mat-card>
                    
                </div>
            </form>
        </mat-card> -->
        <mat-card fxFlex="100" class="m-p-1" fxLayout="row" *ngIf="isSensor">
            <form [formGroup]="sensorproduct_group" fxFlex="100" class="m-p-1" fxLayout="row" fxLayout.lt-sm="column"
                fxLayoutGap="20px" style="height: 100%">
                <div fxLayout="column" fxFlex="33" fxLayoutGap="20px">
                    <div *ngFor="let item of products">
                        <mat-card class="innercard" fxLayout="column" *ngIf="item[0].category!='stand_alone'">
                            <div mat-card-title class="label Btlable">{{'custom_report.'+item[0].category|translate |titlecase}}
                                {{'custom_report.report'|translate|titlecase}}
                            </div>
                            <div fxFlex="100" fxLayout="column" fxLayoutGap="30">
                                <div fxFlex="100" fxLayout="row" fxLayoutAlign="space-between center"
                                    *ngFor="let product of item">
                                    <div class="field" *ngIf="product.data.length==1">
                                        {{toTitleCase(product.product_name)}}
                                    </div>
                                    <div class="field" *ngIf="product.data.length>1">
                                        {{toTitleCase(product.data[0].name)}} & {{toTitleCase(product.data[1].name)}}
                                    </div>
                                    <div style="cursor: pointer;" class="bodychecked" fxLayoutAlign="center center">
                                        <mat-checkbox formControlName="{{product.product_name}}"
                                            [class.mat-checkbox-disabled]="!sensorsAvailabiltymeth(product)"
                                            (change)="selectAllCheckSensor($event,product)"
                                            [disabled]="!sensorsAvailabiltymeth(product)"></mat-checkbox>
                                    </div>
                                </div>
                            </div>
                        </mat-card>
                    </div>
                </div>
            </form>
        </mat-card>
    </div>
</form>