<!-- to show success message -->
<div *ngIf="data.success" style="border-left: 18px solid #47D764;height: 3.5rem;border-radius: 10px;line-height: 10px;"
    fxLayout="row" fxLayoutAlign="space-between center">
    <div style="margin-left: 10px;"><mat-icon style="color: #47D764;font-size: 2rem;">check_box</mat-icon>
    </div>
    <div style="font-weight: 600;color: rgb(22 109 166);margin: 0px 20px;line-height: 1.1;">
        {{ data.msg |translate |titlecase }}
    </div>
    <div style="margin-right: 20px;" fxLayoutAlign="end center">
        <mat-icon style="color: #bababa;" (click)="snackBarRef.dismiss()">close</mat-icon>
    </div>
</div>

<!-- to show error message -->
<div *ngIf="data.error" style="border-left: 18px solid #FF3131;height: 3.5rem;border-radius: 10px;line-height: 10px;"
    fxLayout="row" fxLayoutAlign="space-between center">
    <div style="margin-left: 10px;"><mat-icon style="color: #FF3131;font-size: 2rem;">disabled_by_default</mat-icon>
    </div>
    <div style="font-weight: 600;margin: 0px 20px;line-height: 1.1;">
        {{ data.msg |translate |titlecase }}
    </div>
    <div style="margin-right: 20px;" fxLayoutAlign="end center">
        <mat-icon style="color: #bababa;" (click)="snackBarRef.dismiss()">close</mat-icon>
    </div>
</div>

<!-- to show common message -->
<div *ngIf="data.info" style="border-left: 18px solid orange;height: 3.5rem;border-radius: 10px;line-height: 10px;"
    fxLayout="row" fxLayoutAlign="space-between center">
    <div style="margin-left: 10px;"><mat-icon style="color: orange;font-size: 2rem;">info</mat-icon>
    </div>
    <div style="font-weight: 600;margin: 0px 20px;line-height: 1.1;">
        {{ data.msg |translate |titlecase }}
    </div>
    <div style="margin-right: 20px;" fxLayoutAlign="end center">
        <mat-icon style="color: #bababa;" (click)="snackBarRef.dismiss()">close</mat-icon>
    </div>
</div>