import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-common-message',
  templateUrl: './common-message.component.html',
  styleUrls: ['./common-message.component.scss']
})
export class CommonMessageComponent implements OnInit {
  @Input('message') message: any;
  constructor() { }

  ngOnInit(): void {
  }

}
