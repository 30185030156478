<div class="loginPage">

    <mat-card fxFlex.gt-sm="33" fxFlex.sm="50" fxFlex.lt-sm="80" style="padding: 2rem;">
        <mat-card-title class="title">

            <img src="assets/images/iFRM_logo.png" width="100" height="50">

        </mat-card-title>
        <mat-card-content class="content">
            <form fxFlex="100" [formGroup]="loginPage" fxLayout="column" fxLayoutGap="5">

                <div>
                    <mat-form-field appearance="outline">
                        <mat-label>{{'login_page.login_name'|translate|titlecase}}</mat-label>
                        <input type="text" autocomplete="new-password" matInput formControlName="user_login_name"
                            [maxLength]="25"
                            oninput="this.value = this.value.trimStart().replace(/[^a-zA-Z0-9#!~\-_.]/g, '').replace(/\s+/g, ' ');">
                        <mat-icon style="color: #BCBCBC;cursor: default;" matSuffix>person</mat-icon>
                        <mat-error
                            *ngIf="loginPage.controls['user_login_name'].hasError('required') && loginPage.controls['user_login_name'].touched">
                            {{'login_page.login_name_msg'|translate|titlecase}}
                        </mat-error>
                    </mat-form-field>

                </div>

                <div>
                    <mat-form-field appearance="outline">
                        <mat-label>{{'login_page.password'|translate|titlecase}}</mat-label>
                        <input matInput (ngModelChange)="hidePassword()" autocomplete="new-password" [type]="hide ? 'password'
                            : 'text'" formControlName="password">
                        <mat-icon style="color: #BCBCBC;" matSuffix (click)="showHidePassword()"
                            [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">{{hide ?
                            'visibility_off' : 'visibility'}}</mat-icon>
                        <mat-error>
                            <div
                                *ngIf="loginPage.controls['password'].hasError('required') && loginPage.controls['password'].touched">
                                {{'login_page.password_msg'|translate|titlecase}}
                            </div>
                            <div *ngIf="loginPage.controls['password'].hasError('pattern')">
                                {{'login_page.password_invalid_msg'|translate|titlecase}}
                            </div>
                        </mat-error>

                    </mat-form-field>

                </div>


                <div fxLayout="column" fxLayoutGap="20">
                    <div>
                        <button class="w-100 m-t-20 submit grow" style="min-height: 2.5rem;" (click)="login()"
                            mat-raised-button>{{'login_page.login'|translate|titlecase}}</button>
                    </div>
                    <div fxLayoutAlign="end center" style="margin-top: 2rem">

                        <a style="color: #166DA6;text-decoration: none !important;"
                            routerLink="/auth/forgot-password">{{'login_page.forgot_password'|translate|titlecase}}</a>
                    </div>
                </div>


            </form>
        </mat-card-content>
    </mat-card>
</div>