<div class="loginPage">
    <mat-card fxFlex.gt-sm="30" fxFlex.sm="50" fxFlex.lt-sm="80" style="padding: 2rem;height:400px ;">
        <div fxLayout="column" fxLayoutAlign="center center">
            <img src="assets/images/iFRM_logo.png" width="150" height="100">
            <span class="label">{{'forgot_password.enter_your_name'|translate|titlecase}}</span>
        </div>
        <mat-card-content style="margin-top: 4%;">
            <form [formGroup]="forgotPage" fxLayout="column">
                <div>
                    <mat-form-field appearance="outline">
                        <mat-label>{{'forgot_password.login_name'|translate|titlecase}}</mat-label>
                        <input type="text" matInput formControlName="user_login_name" autocomplete="new-password">
                        <mat-icon style="color: #BCBCBC;" matSuffix>person</mat-icon>
                        <mat-error
                            *ngIf="forgotPage.controls['user_login_name'].hasError('required') && forgotPage.controls['user_login_name'].touched">
                            {{'forgot_password.login_name_msg'|translate|titlecase}}</mat-error>
                    </mat-form-field>

                </div>
                <div>
                    <div class=" m-t-20">
                        <button fxFlex="100" class="submit grow" style="min-height: 2.5rem;" (click)="submit()"
                            mat-raised-button>{{'button.submit'|translate|titlecase}}</button>
                    </div>

                    <div fxLayoutAlign="end center" style="margin-top: 1.5rem">

                        <a style="color: #166DA6;text-decoration: none !important;"
                            routerLink="/auth/login">{{'forgot_password.back_to_login'|translate|titlecase}}</a>
                    </div>
                </div>


            </form>
        </mat-card-content>
    </mat-card>
</div>