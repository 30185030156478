import { Component, OnInit, ViewChild } from "@angular/core";
import { MatSidenav } from "@angular/material/sidenav";
import { kebabMenuItems } from "src/shared/model/layout-model/layout-model";
import { LayoutService } from "../layout-service/layout.service";
import { Router } from "@angular/router";
import { HomeService } from "src/app/core/services/home/home.service";
import { MatMenuTrigger } from '@angular/material/menu';
import { BatchService } from "src/app/core/services/batch/batch.service";
import { BreakpointObserver } from "@angular/cdk/layout";
import { TranslationService } from "src/app/core/services/translate/translate.service";

@Component({
  selector: "app-top-bar",
  templateUrl: "./top-bar.component.html",
  styleUrls: ["./top-bar.component.scss"]
})
export class TopBarComponent implements OnInit {
  @ViewChild(MatSidenav) sidenav!: MatSidenav;
  @ViewChild(MatMenuTrigger) trigger!: MatMenuTrigger;
  public kebabMenu: any;
  public current_time: any;
  public hours: any;
  public msg: any;
  public notifications: Array<any> = [];
  hidden = true;
  public isToggle = false;
  public hide_home_tabs = false;

  public data: any;
  public newMsgCount = 0;
  public show_shed: any;
  public user_data: any = localStorage.getItem('user_data') ? JSON.parse(localStorage.getItem('user_data') || '') : null;

  public redirect_to: any = localStorage.getItem('redirect_to') ? JSON.parse(localStorage.getItem('redirect_to') || '') : null;
  public redirect_list = [
    {
      label: 'company'
    },
    {
      label: 'tank'
    },
  ];

  public company_name = localStorage.getItem('company')?.length ? JSON.parse(localStorage.getItem('company') || '')?.name : '--';
  public tank_name = localStorage.getItem('tank')?.length ? JSON.parse(localStorage.getItem('tank') || '')?.name : '--';
  public show_profile = false;
  constructor(
    private toggleService: LayoutService,
    private route: Router,
    private home: HomeService,
    private batch_service: BatchService,
    private screenObserver: BreakpointObserver,
    private translate: TranslationService
  ) {
    this.hide_home_tabs = !window.location.href.split('/').includes('home');
  }

  ngOnInit(): void {
    this.screenObserver.observe(["(max-width:961px)"]).subscribe(res => {
      this.show_profile = res.matches && window.location.href.includes('home');
      if (!res.matches) {
        this.show_profile = true;
      }
    });

    // if (!!!this.redirect_to) {
    localStorage.setItem('redirect_to', JSON.stringify('tank'));
    this.redirect_to = 'tank';

    // }

    switch (true) {
      case this.user_data.user_role == 1:
        break;
      case this.user_data.user_role > 1 && this.user_data.user_role < 5:
        this.redirect_list = this.redirect_list.filter((r: any) => {
          return r.label != 'company';
        });
        break;
      default:
        this.redirect_list = [];
        localStorage.setItem('redirect_to', JSON.stringify('tank'));
        this.redirect_to = 'tank';
        break;
    }

    if (this.user_data.user_role == 5) {
      this.home.getAllTankByUserId().subscribe((res: any) => {
        this.show_shed = res.data?.length > 1;
      });
    } else {
      this.show_shed = true;
    }

    this.kebabMenu = kebabMenuItems.map(v => {
      v.this = this;
      return v;
    }
    );
    // Arrow Icon Toggle
    this.toggleService.toggle.subscribe((isClose: boolean) => {
      this.isToggle = isClose;
    });
  }

  languageSelect(lang: string) {
    this.translate.setLanguage(lang || 'en');
  }
  getCompany() {
    // setTimeout(() => {
    return localStorage.getItem('company')?.length ? JSON.parse(localStorage.getItem('company') || '')?.name : null;
    // });
  }

  closeMenu() {
    this.trigger.closeMenu();
  }

  openMenu() {
    this.trigger.openMenu();
  }

  // Side Nav Toggle
  public toggleSideNav(toggle: boolean) {
    this.toggleService.sideNavToggle(toggle);
  }

  public logout() {
    localStorage.clear();
    this.route.navigate(["/auth/login"]);
    setTimeout(() => {
      window.location.reload();
    }, 300);

  }
  public disableNotification() {
  }


  setRedirect(label: any) {
    this.redirect_to = label;
    localStorage.setItem('redirect_to', JSON.stringify(label));
    this.redirect();
  }

  redirect() {

    let index = localStorage.getItem('current_tab')?.length ? JSON.parse(localStorage.getItem('current_tab') || '') : 0;
    if (this.redirect_to == 'tank') {
      this.toggleService.switchHomeTab(index);
    }


    if (this.redirect_to == 'company') {
      this.toggleService.switchHomeTab(index - 2);
    }
    const removeFromLocal = ['tank', 'batch_id'];
    removeFromLocal.forEach((R: any) => {
      localStorage.removeItem(R);
    });

    this.batch_service.active_batch_data = null;
    this.route.navigate(['home']);
  }
}
