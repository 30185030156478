<!-- -------------------------------USER DETAILS------------------------------------ -->
<div *ngIf="dialogData.is_user_detail" class="h-100">
    <div fxLayoutAlign="space-between center">
        <div style="font-size: 18px;"><b>{{'user_tab.user_details'|translate|titlecase}}</b></div>
        <mat-icon (click)="dialogRef.close()">close</mat-icon>
    </div>
    <div
        style="background-color:#f8f8f8;margin-top: 1rem;border-radius: 4px;padding: 1rem;height: 80%;overflow-y: auto;">
        <div fxLayout="row wrap">

            <div style="margin: 9px 5px;" fxFlex.lt-sm="100" fxFlex.sm="42.5" fxFlex="28">
                <div class="label">{{'user_tab.user_name'|translate|titlecase}}</div>
                <div class="value">{{data?.user_name || '--'}}</div>
            </div>

            <div style="margin: 9px 5px;" fxFlex.lt-sm="100" fxFlex.sm="42.5" fxFlex="28">
                <div class="label">{{'user_tab.user_role'|translate|titlecase}}</div>
                <div class="value">{{"user_tab."+data?.role_name|translate|titlecase}}</div>
            </div>

            <div style="margin: 9px 5px;" fxFlex.lt-sm="100" fxFlex.sm="42.5" fxFlex="28">
                <div class="label">{{'user_tab.company'|translate|titlecase}}</div>
                <div class="value">{{data?.company_name || '--'}}</div>
            </div>

            <div style="margin: 9px 5px;" fxFlex.lt-sm="100" fxFlex.sm="42.5" fxFlex="28">
                <div class="label">{{'user_tab.email'|translate|titlecase}}</div>
                <div class="value">{{data?.user_email || '--'}}</div>
            </div>

            <div style="margin: 9px 5px;" fxFlex.lt-sm="100" fxFlex.sm="42.5" fxFlex="28">
                <div class="label">{{'user_tab.mobile_number'|translate|titlecase}}</div>
                <div class="value">{{data?.mobile_no || '--'}}</div>
            </div>

            <div style="margin: 9px 5px;" fxFlex.lt-sm="100" fxFlex.sm="42.5" fxFlex="28">
                <div class="label">{{'user_tab.whatsapp_number'|translate|titlecase}}</div>
                <div class="value">{{data?.whatsapp_no || '--'}}</div>
            </div>

            <div style="margin: 9px 5px;" fxFlex.lt-sm="100" fxFlex.sm="42.5" fxFlex="28">
                <div class="label">{{'user_tab.login_name'|translate|titlecase}}</div>
                <div class="value">{{data?.user_login_name || '--'}}</div>
            </div>

            <div style="margin: 9px 5px;" fxFlex.lt-sm="100" fxFlex.sm="42.5" fxFlex="28">
                <div class="label">{{'user_tab.email_alert'|translate|titlecase}}</div>
                <div class="value p-e-n"><mat-slide-toggle [checked]="data?.email_alert == 1 ? true : false"
                        color="primary"></mat-slide-toggle>
                </div>
            </div>
            <div style="margin: 9px 5px;" fxFlex.lt-sm="100" fxFlex.sm="42.5" fxFlex="28">
                <div class="label">{{'user_tab.ring_alert'|translate|titlecase}}</div>
                <div class="value p-e-n"><mat-slide-toggle [checked]="data?.ring_alert == 1 ? true : false"
                        color="primary"></mat-slide-toggle>
                </div>
            </div>

            <div style="margin: 9px 5px;" fxFlex.lt-sm="100" fxFlex.sm="42.5" fxFlex="28">
                <div class="label">{{'user_tab.sms_alert'|translate|titlecase}}</div>
                <div class="value p-e-n"><mat-slide-toggle [checked]="data?.sms_alert == 1 ? true : false"
                        color="primary"></mat-slide-toggle>
                </div>
            </div>

            <div style="margin: 9px 5px;" fxFlex.lt-sm="100" fxFlex.sm="42.5" fxFlex="28">
                <div class="label">{{'user_tab.whatsapp_alert'|translate|titlecase}}</div>
                <div class="value p-e-n"><mat-slide-toggle [checked]="data?.whatsapp_alert == 1 ? true : false"
                        color="primary"></mat-slide-toggle>
                </div>
            </div>

        </div>

    </div>
</div>

<!-- ----------------------------------------------------------------------------------- -->


<!-- -------------------------------COMPANY DETAILS------------------------------------ -->
<div *ngIf="dialogData.is_company_detail" class="h-100">
    <div fxLayoutAlign="space-between center">
        <div style="font-size: 18px;"><b>{{'company_tab.company_details'|translate|titlecase}}</b></div>
        <mat-icon (click)="dialogRef.close()">close</mat-icon>
    </div>
    <div
        style="background-color:#f8f8f8;margin-top: 1rem;border-radius: 4px;padding: 1rem;height: 80%;overflow-y: auto;">
        <div fxLayout="row wrap">

            <div style="margin: 9px 5px;" fxFlex.lt-sm="100" fxFlex.sm="42.5" fxFlex="31.5">
                <div class="label">{{'company_tab.company_name'|translate|titlecase}}</div>
                <div class="value">{{data?.company_name || '--'}}</div>
            </div>

            <div style="margin: 9px 5px;" fxFlex.lt-sm="100" fxFlex.sm="42.5" fxFlex="31.5">
                <div class="label">{{'company_tab.address'|translate|titlecase}}</div>
                <div class="value">{{data?.company_address || '--'}}</div>
            </div>

            <div style="margin: 9px 5px;" fxFlex.lt-sm="100" fxFlex.sm="42.5" fxFlex="31.5">
                <div class="label">{{'company_tab.zip_code'|translate|titlecase}}</div>
                <div class="value">{{data?.zip_code || '--'}}</div>
            </div>

            <div style="margin: 9px 5px;" fxFlex.lt-sm="100" fxFlex.sm="42.5" fxFlex="31.5">
                <div class="label">{{'company_tab.country'|translate|titlecase}}</div>
                <div class="value">{{data?.country || '--'}}</div>
            </div>

            <div style="margin: 9px 5px;" fxFlex.lt-sm="100" fxFlex.sm="42.5" fxFlex="31.5">
                <div class="label">{{'company_tab.state'|translate|titlecase}}</div>
                <div class="value">{{data?.state || '--'}}</div>
            </div>

            <div style="margin: 9px 5px;" fxFlex.lt-sm="100" fxFlex.sm="42.5" fxFlex="31.5">
                <div class="label">{{'company_tab.city'|translate|titlecase}}</div>
                <div class="value">{{data?.city_name || '--'}}</div>
            </div>

            <div style="margin: 9px 5px;" fxFlex.lt-sm="100" fxFlex.sm="42.5" fxFlex="31.5">
                <div class="label">{{'company_tab.contact_person_name'|translate|titlecase}}</div>
                <div class="value">{{data?.primary_spoc || '--'}}</div>
            </div>
            <div style="margin: 9px 5px;" fxFlex.lt-sm="100" fxFlex.sm="42.5" fxFlex="31.5">
                <div class="label">{{'company_tab.mobile_number'|translate|titlecase}}</div>
                <div class="value">{{data?.contact_no || '--'}}</div>
            </div>
            <div style="margin: 9px 5px;" fxFlex.lt-sm="100" fxFlex.sm="42.5" fxFlex="31.5">
                <div class="label">{{'company_tab.email'|translate|titlecase}}</div>
                <div class="value" style="overflow-x: hidden;text-overflow: ellipsis;">{{data?.primary_email || '--'}}
                </div>
            </div>
            <div style="margin: 9px 5px;" fxFlex.lt-sm="100" fxFlex.sm="42.5" fxFlex="31.5">
                <div class="label">{{'company_tab.tax_type'|translate|titlecase}}</div>
                <div class="value">{{data?.tax_type || '--'}}</div>
            </div>
            <div style="margin: 9px 5px;" fxFlex.lt-sm="100" fxFlex.sm="42.5" fxFlex="31.5">
                <div class="label">{{'company_tab.tax_number'|translate|titlecase}}</div>
                <div class="value">{{data?.tax_no || '--'}}</div>
            </div>



        </div>

    </div>
</div>

<!-- ----------------------------------------------------------------------------------- -->

<!-- -------------------------------SHED DETAILS------------------------------------ -->
<div *ngIf="dialogData.is_shed_detail" class="h-100"
    style="background-color:#f8f8f8;padding: 1rem;height: 90%;overflow-y: auto;">
    <div fxLayoutAlign="space-between center">
        <div style="font-size: 18px;"><b>{{'tank_tab.tank_details'|translate|titlecase}}</b></div>
        <mat-icon (click)="dialogRef.close()">close</mat-icon>
    </div>
    <div style="margin-top: 1rem;border-radius: 4px;">
        <div fxLayout="row wrap">

            <div style="margin: 9px;" fxFlex.lt-sm="40" fxFlex.sm="40" fxFlex="22.5">
                <div class="label">{{'tank_tab.tank_name'|translate|titlecase}}</div>
                <div class="value">{{data?.tank_name || '--'}}</div>
            </div>

            <div style="margin: 9px;" fxFlex.lt-sm="40" fxFlex.sm="40" fxFlex="22.5">
                <div class="label">{{'tank_tab.tank_supervisor_name'|translate|titlecase}}</div>
                <div class="value">{{data?.supervisor_name || '--'}}</div>
            </div>

            <div style="margin: 9px;" fxFlex.lt-sm="40" fxFlex.sm="40" fxFlex="22.5">
                <div class="label">{{'tank_tab.contact_number'|translate|titlecase}}</div>
                <div class="value">{{data?.contact_no || '--'}}</div>
            </div>

            <div style="margin: 9px;" fxFlex.lt-sm="40" fxFlex.sm="40" fxFlex="22.5">
                <div class="label">{{'tank_tab.labour_count'|translate|titlecase}}</div>
                <div class="value">{{data?.labour_count || '--'}}</div>
            </div>

            <div style="margin: 9px;" fxFlex.lt-sm="40" fxFlex.sm="40" fxFlex="22.5">
                <div class="label">{{'tank_tab.length_of_tank'|translate|titlecase}}</div>
                <div class="value">{{data?.length || '--'}}</div>
            </div>

            <div style="margin: 9px;" fxFlex.lt-sm="40" fxFlex.sm="40" fxFlex="22.5">
                <div class="label">{{'tank_tab.width_of_tank'|translate|titlecase}}</div>
                <div class="value">{{data?.width || '--'}}</div>
            </div>

            <div style="margin: 9px;" fxFlex.lt-sm="40" fxFlex.sm="40" fxFlex="22.5">
                <div class="label">{{'tank_tab.height_of_tank'|translate|titlecase}}</div>
                <div class="value">{{data?.height || '--'}}</div>
            </div>

        </div>

    </div>
    <div>
        <div style="font-size: 18px;margin-top: 1rem"><b>{{'tank_tab.house_type'|translate|titlecase}}</b></div>
    </div>
    <div style="margin-top: 1rem;border-radius: 4px;">
        <div fxLayout="row wrap">

            <div style="margin: 9px;" fxFlex.lt-sm="40" fxFlex.sm="40" fxFlex="22.5">
                <div class="label">{{'tank_tab.tank_type'|translate|titlecase}}</div>
                <div class="value">{{"tank_tab."+data?.tank_type|translate|titlecase}}</div>
            </div>

            <!-- <div style="margin: 9px;" fxFlex.lt-sm="40" fxFlex.sm="40" fxFlex="22.5">
                <div class="label">{{'tank_tab.no_of_row'|translate|titlecase}}</div>
                <div class="value">{{data?.rows_count || '--'}}</div>
            </div> -->

            <!-- <div style="margin: 9px;" fxFlex.lt-sm="40" fxFlex.sm="40" fxFlex="22.5">
                <div class="label">{{'tank_tab.no_of_tiers'|translate|titlecase}}</div>
                <div class="value">{{data?.tier_count || '--'}}</div>
            </div> -->

            <!-- <div style="margin: 9px;" fxFlex.lt-sm="40" fxFlex.sm="40" fxFlex="22.5">
                <div class="label">{{'tank_tab.no_of_partitions'|translate|titlecase}}</div>
                <div class="value">{{data?.partition_count || '--'}}</div>
            </div> -->
        </div>

    </div>

    <div>
        <div style="font-size: 18px;margin-top: 1rem"><b>{{'tank_tab.breed_details'|translate|titlecase}}</b></div>
    </div>
    <div style="margin-top: 1rem;border-radius: 4px;">
        <div fxLayout="row wrap">

            <div style="margin: 9px;" fxFlex.lt-sm="40" fxFlex.sm="40" fxFlex="22.5">
                <div class="label">{{'tank_tab.breed_type'|translate|titlecase}}</div>
                <div class="value">{{"tank_tab."+data?.fish_type_name|translate|titlecase}}</div>
            </div>

            <!-- <div style="margin: 9px;" fxFlex.lt-sm="40" fxFlex.sm="40" fxFlex="22.5">
                <div class="label">{{'tank_tab.total_box'|translate|titlecase}}</div>
                <div class="value">{{data?.box_count || '--'}}</div>
            </div> -->

            <!-- <div style="margin: 9px;" fxFlex.lt-sm="40" fxFlex.sm="40" fxFlex="22.5">
                <div class="label">
                    {{data?.fish_type_id == 2 ? ('tank_tab.female/box' |translate|titlecase):('tank_tab.bird/box'|translate|titlecase)}}
                </div>
                <div class="value">{{data?.female_box_count || '--'}}</div>
            </div> -->

            <!-- <div *ngIf="data?.fish_type_id == 2" style="margin: 9px;" fxFlex.lt-sm="40" fxFlex.sm="40" fxFlex="22.5">
                <div class="label">{{'tank_tab.male/box'|translate|titlecase}}</div>
                <div class="value">{{data?.male_box_count || '--'}}</div>
            </div> -->

            <div style="margin: 9px;" fxFlex.lt-sm="40" fxFlex.sm="40" fxFlex="22.5">
                <div class="label">{{data?.fish_type_name=='fish' ? ('tank_tab.no_of_fish'|translate|titlecase) :('tank_tab.no_of_shrimp'|translate|titlecase)}}</div>
                <div class="value">{{data?.fish_count || '--'}}</div>
            </div>
        </div>

    </div>
</div>

<!-- ----------------------------------------------------------------------------------- -->