import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AddSensorComponent } from '../add-sensor/add-sensor.component';
import { IotGatewayComponent } from '../iot-gateway/iot-gateway.component';
import { SensorComponent } from '../sensor/sensor.component';
import { PermissionGuard } from 'src/app/core/permission-guard/permission.guard';

const routes: Routes = [
  {
    path: "",
    redirectTo: "sensor",
    pathMatch: 'full'
  },
  {
    path: "sensor",
    canActivate: [PermissionGuard],
    component: SensorComponent
  },
  {
    path: "add-sensor",
    canActivate: [PermissionGuard],
    component: AddSensorComponent
  },
  {
    path: "iot-gateway",
    canActivate: [PermissionGuard],
    component: IotGatewayComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ConfigurationRoutingModule { }
