<mat-card fxFlex="100" class="responsive-container" fxLayout="column">
  <!---------------------------Show message if company list is empty---------------------------------->

  <div *ngIf="!!!company_list?.length && !is_create_company">
    <app-common-message [message]="
        'company_tab.company_empty_msg'|translate|titlecase
      ">
    </app-common-message>
  </div>
  <!------------------------------------------------------------------------------------------->

  <!---------------------------to list all company--------------------------------------------->
  <div id="company-container" *ngIf="!is_create_company && !is_edit_company" fxLayout.lt-sm="column" fxLayout="row warp"
    style="overflow-y: auto;">
    <ng-container *ngFor="let company of company_list;trackBy:companyTrackBy; let i = index;">
      <mat-card (click)="redirectToFarm(company)" fxFlex.gt-sm="23" fxFlex.sm="48" fxLayoutGap="30"
        fxLayoutGap.gt-md="35" fxLayoutGap.gt-lg="80" class="inner-card" fxLayout="column">

        <div fxLayout="row" fxLayoutAlign="space-between start" fxFlex="77" style="padding: 10px;overflow: hidden;">
          <div fxLayout="row" fxLayoutAlign="start start" fxFlex="94" fxLayoutGap="3">
            <div *ngIf="company.is_registered">
              <mat-icon>verified</mat-icon>
            </div>
            <div *ngIf="!company.is_registered">
              <mat-icon class="material-icons-sharp"> watch_later </mat-icon>
            </div>
            <div fxFlex="90" class="elipsis" [matTooltip]=" company.company_name?.length >90 ?  company.company_name:''"
              (click)="viewCompany(company);$event.stopPropagation()" matTooltipPosition="above">
              <span class="name-underline">
                {{ company.company_name | titlecase }}
              </span>
            </div>
          </div>

          <!-- <div fxLayout="row" fxLayoutGap="16">
            <mat-icon style="width: 1.2rem" svgIcon="Edit" (click)="editCompany(company);
             $event.stopPropagation()">
            </mat-icon>
            <mat-icon style="width: 1rem" svgIcon="Delete" (click)="deleteCompany(company); 
            $event.stopPropagation()">
            </mat-icon>
          </div> -->

          <div fxLayout="row" #triggerReport="matMenuTrigger" [matMenuTriggerFor]="process" (click)="triggerReport.openMenu();
          $event.stopPropagation()" class="process">
            <mat-icon class="process" style="color: #166DA6;width: 1.2rem">more_vert</mat-icon>
          </div>
          <mat-menu #process="matMenu" xPosition="before">
            <div class="redirect-menu">
              <ng-container>
                <button mat-menu-item (click)="editCompany(company);
                      $event.stopPropagation()">
                  <span>{{'button.edit'|translate|titlecase}}</span>
                </button>
              </ng-container>
              <ng-container>
                <button mat-menu-item (click)="deleteCompany(company);
                      $event.stopPropagation()">
                  <span>{{'button.delete'|translate|titlecase}}</span>
                </button>
              </ng-container>
            </div>
          </mat-menu>
        </div>

        <div fxFlex="30" fxLayout="row" style="background-color: #166DA6;padding: 10px;border-bottom-left-radius: 18px;
        border-bottom-right-radius: 18px;" fxLayoutGap="15" fxLayoutAlign="start center">
          <div class="white-bg">
            <img style="width: 18px" src="../../../../assets/images/company.svg" alt="" />
          </div>
          <div style="color: #ffffff; font-size: 14px; margin-top: 5px">
            {{ company.total_tanks }}
          </div>
          <div *ngIf="!company.is_registered && isLinkExpired(company)"
            style="position: absolute; bottom: 10px; right: 12px;">
            <a href="#"
              (click)="resendMail(company, i); $event.stopPropagation()">{{'company_tab.resend_link'|translate|titlecase}}
            </a>
          </div>

          <div *ngIf="!company.is_registered && !isLinkExpired(company)"
            style="position: absolute; bottom: 10px; right: 12px">
            <div style="display: flex; color: #ffffff">
              <div style="font-size: 13px">{{'company_tab.resend_link_in'|translate|titlecase}}</div>
              <div style="width: 4.1rem; text-align: end; font-weight: 600">
                {{ this.link_expire_time[i] }}
              </div>
            </div>
          </div>
        </div>
        <!-- <div fxLayout="column" style="text-align: center; align-items: center">
          <div class="white-bg">
            <img style="width: 20px" src="../../../../assets/images/company.svg" alt="" />
          </div>
          <div style="color: #1492E6; font-size: medium; margin-top: 5px">
            {{ company.total_farms }}
          </div>
          <div *ngIf="!company.is_registered && isLinkExpired(company)"
            style="position: absolute; bottom: 10px; right: 12px">
            <a href="#" (click)="resendMail(company, i); $event.stopPropagation()">Resend link
            </a>
          </div>

          <div *ngIf="!company.is_registered && !isLinkExpired(company)"
            style="position: absolute; bottom: 10px; right: 12px">
            <div style="display: flex; color: #1492E6">
              <div style="font-size: 13px">Resend Link In</div>
              <div style="width: 4.1rem; text-align: end; font-weight: 600">
                {{ this.link_expire_time[i] }}
              </div>
            </div>
          </div>
        </div> -->
      </mat-card>
    </ng-container>
  </div>

  <!------------------------------------------------------------------------------------------->

  <!---------------------------new company creation--------------------------------------------->

  <div *ngIf="is_create_company || is_edit_company" fxLayout="column">
    <form [formGroup]="company_form_group" style="margin: 25px">
      <div fxLayout.gt-sm="row" fxLayout.lt-sm="column" fxLayoutGap.lt-sm="10"
        fxLayoutAlign.lt-sm="space-between stretch" fxLayoutAlign.sm="space-between start"
        fxLayoutAlign.gt-sm="space-between start">
        <div fxFlex="35" fxFlex.sm="45" fxFlex.lt-sm="100" fxLayout="column" fxLayoutGap="10">
          <div class="label">
            {{
            is_edit_company
            ? ('company_tab.update_company_details' |translate |titlecase)
            : 'company_tab.fill_company_details' |translate|titlecase
            }}
          </div>

          <div style="font-size: 13px">
            <mat-form-field appearance="outline">
              <mat-label>{{'company_tab.company_name'|translate|titlecase}}</mat-label>
              <input  (ngModelChange)="is_company_name_changed = true" (focusout)="isCompanyNameExist()" matInput
                formControlName="company_name" type="text"
                oninput="this.value = this.value.trimStart().replace(/\s+/g, ' ')" autocomplete="new-password" />
              <mat-error>
                <span *ngIf="
                    company_form_group.controls['company_name'].touched &&
                    company_form_group.get('company_name')?.hasError('required')
                  ">
                  {{'company_tab.company_name_msg'|translate|titlecase}}
                </span>
                <span *ngIf="
                    company_form_group.controls['company_name'].touched &&
                    company_form_group
                      .get('company_name')
                      ?.hasError('alreadyExist')
                  ">
                  {{'company_tab.company_name_exist_msg'|translate|titlecase}}
                </span>
              </mat-error>
            </mat-form-field>
          </div>

          <div style="font-size: 13px">
            <mat-form-field appearance="outline">
              <mat-label>{{'company_tab.address'|translate|titlecase}}</mat-label>
              <input  matInput formControlName="company_address" type="text"
                oninput="this.value = this.value.trimStart().replace(/\s+/g, ' ')" autocomplete="new-password" />
              <mat-error>
                <span *ngIf="
                    company_form_group.controls['company_address'].touched &&
                    company_form_group
                      .get('company_address')
                      ?.hasError('required')
                  ">
                  {{'company_tab.address_msg'|translate|titlecase}}
                </span>
              </mat-error>
            </mat-form-field>
          </div>

          <div style="font-size: 13px">
            <mat-form-field appearance="outline">
              <mat-label>{{'company_tab.zip_code'|translate|titlecase}}</mat-label>
              <input [maxLength]="10" matInput formControlName="zip_code" type="text" autocomplete="new-password"
                oninput="this.value=this.value.replace(/[^a-zA-Z0-9]/g,'');" />
              <mat-error>
                <span *ngIf="
                    company_form_group.controls['zip_code'].touched &&
                    company_form_group.get('zip_code')?.hasError('required')
                  ">
                  {{'company_tab.zip_code_msg'|translate|titlecase}}
                </span>
              </mat-error>
            </mat-form-field>
          </div>

          <div style="font-size: 13px">
            <mat-form-field appearance="outline">
              <mat-label>{{'company_tab.country'|translate|titlecase}}</mat-label>
              <input type="text" autocomplete="new-password" aria-label="Number" matInput formControlName="country_id"
                [matAutocomplete]="auto" oninput="this.value=this.value.replace(/[^a-zA-Z]/g,'');"
                (focusout)="focusOutCountryField()" />
              <mat-icon matSuffix style="
                  color: rgba(0, 0, 0, 0.54);
                  width: 21px;
                  height: 1rem;
                  font-size: 22px;
                ">arrow_drop_down</mat-icon>
              <mat-autocomplete #auto="matAutocomplete" [displayWith]="countryDisplayFn">
                <mat-option (onSelectionChange)="onSelectState()" *ngFor="let country of countryObservable | async"
                  [value]="country.country_id">
                  {{ country.country_name | titlecase }}
                </mat-option>
              </mat-autocomplete>
              <mat-error>
                <span *ngIf="
                    company_form_group.controls['country_id'].touched &&
                    company_form_group.get('country_id')?.hasError('required')
                  ">
                  {{'company_tab.country_msg'|translate|titlecase}}
                </span>
                <span *ngIf="
                    company_form_group.controls['country_id'].touched &&
                    company_form_group.get('country_id')?.hasError('isError')
                  ">
                  {{'company_tab.country_invalid_msg'|translate|titlecase}}
                </span>
              </mat-error>
            </mat-form-field>
          </div>

          <div style="font-size: 13px">
            <mat-form-field appearance="outline">
              <mat-label>{{'company_tab.state'|translate|titlecase}}</mat-label>
              <input type="text" (focusin)="onFocusInState()" autocomplete="new-password" matInput
                formControlName="state_id" [matAutocomplete]="stateauto"
                oninput="this.value=this.value.replace(/[^a-zA-Z]/g,'');" (focusout)="focusOutStateField()" />
              <mat-icon matSuffix style="
                  color: rgba(0, 0, 0, 0.54);
                  width: 21px;
                  height: 1rem;
                  font-size: 22px;
                ">arrow_drop_down</mat-icon>
              <mat-autocomplete #stateauto="matAutocomplete" [displayWith]="stateDisplayFn">
                <mat-option *ngFor="let state of stateObservable | async" [value]="state.state_id">
                  {{ state.state_name | titlecase }}
                </mat-option>
              </mat-autocomplete>
              <mat-error>
                <span *ngIf="
                    !!company_form_group.controls['country_id'].value &&
                    company_form_group.controls['state_id'].touched &&
                    company_form_group.get('state_id')?.hasError('required')
                  ">
                  {{'company_tab.state_msg'|translate|titlecase}}
                </span>

                <span *ngIf="
                    !!!company_form_group.controls['country_id'].value &&
                    company_form_group.get('state_id')?.touched
                  ">
                  {{'company_tab.country_first_msg'|translate|titlecase}}

                </span>

                <span *ngIf="
                    !!company_form_group.controls['country_id'].value &&
                    company_form_group.get('state_id')?.hasError('isError')
                  ">
                  {{'company_tab.state_invalid_msg'|translate|titlecase}}
                </span>
              </mat-error>
            </mat-form-field>
          </div>

          <div style="font-size: 13px">
            <mat-form-field appearance="outline">
              <mat-label> {{'company_tab.city'|translate|titlecase}}</mat-label>
              <input matInput formControlName="city_name" type="text"
              oninput="this.value = this.value.replace(/[^a-zA-Z\u0600-\u06FF ]/gu, '').trim().replace(/\s+/g, ' ')"
              autocomplete="new-password" />
              <mat-error>
                <span *ngIf="
                    company_form_group.controls['city_name'].touched &&
                    company_form_group.get('city_name')?.hasError('required')
                  ">
                  {{'company_tab.city_msg'|translate|titlecase}}
                </span>
              </mat-error>
            </mat-form-field>
          </div>
        </div>

        <div fxHide fxShow.gt-sm style="
            border-left: 1.5px solid #bcbcbc;
            height: 26rem;
            position: relative;
            top: 2.3rem;
          "></div>

        <div fxFlex="35" fxFlex.sm="45" fxFlex.lt-sm="100" fxLayout="column" fxLayoutGap="10">
          <div></div>
          <div></div>
          <div></div>
          <div style="font-size: 13px">
            <mat-form-field appearance="outline">
              <mat-label> {{'company_tab.contact_person_name'|translate|titlecase}}</mat-label>
              <input matInput formControlName="primary_spoc" type="text"
              oninput="this.value = this.value.replace(/[^a-z A-Z\u0600-\u06FF ]/gu, '')"
                autocomplete="new-password" />
              <mat-error>
                <span *ngIf="
                    company_form_group.controls['primary_spoc'].touched &&
                    company_form_group.get('primary_spoc')?.hasError('required')
                  ">
                  {{'company_tab.contact_person_name_msg'|translate|titlecase}}
                </span>
              </mat-error>
            </mat-form-field>
          </div>

          <div fxLayout="row" fxLayoutGap="3">
            <mat-form-field fxFlex="25" appearance="outline">
              <mat-label> {{'company_tab.code'|translate|titlecase}}</mat-label>
              <mat-select (blur)="dialCodeFocusOut('Mobile')" formControlName="dial_code_mobile_no"
                panelClass="PanelClass" autocomplete="new-password">
                <mat-select-trigger>
                  {{ company_form_group.get("dial_code_mobile_no")?.value }}
                </mat-select-trigger>

                <div class="dial-code-filter">
                  <mat-form-field floatLabel="never">
                    <input matInput [formControl]="mobile_dial_code_filter" type="text" autocomplete="new-password"
                      [placeholder]="('search_here'|translate|titlecase)+'...'" (keydown)="$event.stopPropagation()" />
                  </mat-form-field>
                </div>

                <ng-container *ngFor="let country of mobileDialcodeObservable | async">
                  <mat-option [value]="country.dial_code">{{
                    country.country_name + " " + country.dial_code | titlecase
                    }}
                  </mat-option>
                </ng-container>
              </mat-select>
            </mat-form-field>
            <mat-form-field fxFlex="74" appearance="outline">
              <mat-label> {{'company_tab.mobile_number'|translate|titlecase}}</mat-label>
              <input [maxLength]="15" matInput formControlName="mobile" type="text" (keydown)="
                  $event.key == 'e' ||
                  $event.key == 'E' ||
                  $event.key == '-' ||  
                  $event.key == '.'
                    ? $event.preventDefault()
                    : ''
                "
                oninput="this.value=this.value.replace(/[^0-9.]/g,'');javascript: if (this.value.length >15) this.value = this.value.slice(0,15);"
                autocomplete="new-password" />
              <mat-error>
                <span *ngIf="
                    company_form_group.controls['dial_code_mobile_no']
                      .touched &&
                    company_form_group
                      .get('dial_code_mobile_no')
                      ?.hasError('required')
                  ">
                  {{'company_tab.code_msg'|translate|titlecase}}

                </span>
                <span *ngIf="
                    company_form_group.controls['mobile'].touched &&
                    company_form_group.get('mobile')?.hasError('required')
                    && !company_form_group.get('dial_code_mobile_no')?.hasError('required')
                  ">
                  {{'company_tab.mobile_msg'|translate|titlecase}}
                </span>
                <!-- <span *ngIf="
                    company_form_group.controls['mobile'].touched &&
                    company_form_group.get('mobile')?.hasError('minlength')
                    && !company_form_group.get('dial_code_mobile_no')?.hasError('required')
                  ">
                  {{'company_tab.mobile_num_msg'|translate|titlecase}}
                </span> -->
              </mat-error>
            </mat-form-field>
          </div>
          <div style="font-size: 13px">
            <mat-form-field appearance="outline">
              <mat-label> {{'company_tab.email'|translate|titlecase}}</mat-label>
              <input matInput formControlName="primary_email" type="text" pattern="^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$"
                oninput="this.value = this.value.trimStart().replace(/[^\x00-\x7F]/g,'').trim('')" autocomplete="new-password" />
              <mat-error>
                <span *ngIf="
                    company_form_group.controls['primary_email'].touched &&
                    company_form_group
                      .get('primary_email')
                      ?.hasError('required')
                  ">
                  {{'company_tab.email_msg'|translate|titlecase}}
                </span>
                <span *ngIf="
                    company_form_group.controls['primary_email'].touched &&
                    company_form_group.get('primary_email')?.hasError('pattern')
                  ">
                  {{'company_tab.email_invalid_msg'|translate|titlecase}}
                </span>
              </mat-error>
            </mat-form-field>
          </div>
          <div style="font-size: 13px">
            <mat-form-field appearance="outline">
              <mat-label> {{'company_tab.tax_type'|translate|titlecase}}</mat-label>
              <input matInput formControlName="tax_type" type="text"
              oninput="this.value = this.value.replace(/[^a-zA-Z\u0600-\u06FF ]/gu, '')"
                autocomplete="new-password" />
              <mat-error>
                <span *ngIf="
                    company_form_group.controls['tax_type'].touched &&
                    company_form_group.get('tax_type')?.hasError('required')
                  ">
                  {{'company_tab.tax_type_msg'|translate|titlecase}}
                </span>
              </mat-error>
            </mat-form-field>
          </div>

          <div style="font-size: 13px">
            <mat-form-field appearance="outline">
              <mat-label> {{'company_tab.tax_number'|translate|titlecase}}</mat-label>
              <input matInput formControlName="tax_no" type="text"
                autocomplete="new-password" />
              <mat-error>
                <span *ngIf="
                    company_form_group.controls['tax_no'].touched &&
                    company_form_group.get('tax_no')?.hasError('required')
                  ">
                  {{'company_tab.tax_number_msg'|translate|titlecase}}
                </span>
              </mat-error>
            </mat-form-field>
          </div>
        </div>
      </div>
    </form>
    <div style="margin: 10px 0px" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="50" fxLayoutGap.lt-sm="25">
      <button class="submit grow" mat-stroked-button (click)="submit()">
        {{ is_edit_company ? ("button.update"|translate|titlecase) : "button.submit" |translate|titlecase}}
      </button>
      <button class="cancel grow" mat-stroked-button (click)="clearAll()">
        {{'button.cancel'|translate|titlecase}}
      </button>
    </div>
  </div>

  <!------------------------------------------------------------------------------------------->
</mat-card>