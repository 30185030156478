import { Component, OnInit, Inject, ViewEncapsulation} from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-score-dialog',
  templateUrl: './score-dialog.component.html',
  styleUrls: ['./score-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ScoreDialogComponent implements OnInit {
  public score=new FormControl('',[Validators.required]);
  constructor(public dialogRef: MatDialogRef<ScoreDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: any,) { }
    data=''
  ngOnInit(): void {
    const dialogContainer: any = document.getElementsByClassName("mat-dialog-container")[0];
    if (this.dialogData?.isDelete) {
      dialogContainer.style.overflow = "initial";
    } else {
      dialogContainer.style.overflow = "auto";
    }
    if(this.dialogData?.title!=''){
      this.data=this.dialogData?.title
    }
  }
  cancel() {
    this.dialogRef.close();
  }

}
