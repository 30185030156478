import { BreakpointObserver } from "@angular/cdk/layout";
import { AfterViewInit, Component, OnInit, ViewChild } from "@angular/core";
import { MatSidenav } from "@angular/material/sidenav";
import { NavigationEnd, Router } from "@angular/router";
import { MessageService } from "src/app/core/services/message/message.service";
import {
  MenuItem, MenuItemList,
  sideMenuItem
} from "../../../../shared/model/layout-model/layout-model";
import { LayoutService } from "../layout-service/layout.service";
import { environment } from '../../../../environments/environment';

@Component({
  selector: "app-side-bar",
  templateUrl: "./side-bar.component.html",
  styleUrls: ["./side-bar.component.scss"]
})
export class SideBarComponent implements OnInit, AfterViewInit {
  @ViewChild(MatSidenav) sidenav!: MatSidenav;
  menuItems: MenuItem[] = MenuItemList;
  sideMenuItems: sideMenuItem[] = sideMenuItem;
  public isOpen: Array<boolean> = [];
  public isActive: Array<boolean> = [true];
  public user_data: any = localStorage.getItem('user_data') ? JSON.parse(localStorage.getItem('user_data') || '') : null;
  // public version: any = environment.VERSION;

  public isExpand = true;
  public is_hide_collapse_icon = false;
  public side_bar_width: any = '15%';

  public company_name = localStorage.getItem('company')?.length ? JSON.parse(localStorage.getItem('company') || '')?.name : '--';
  public shed = localStorage.getItem('tank')?.length ? JSON.parse(localStorage.getItem('tank') || '') : null;
  public birdtype = localStorage.getItem('tank')?.length ? JSON.parse(localStorage.getItem('tank') || '')?.breedType : '--';

  constructor(private screenObserver: BreakpointObserver,
    private router: Router,
    private toggleService: LayoutService,
    private show_msg: MessageService) { }

  ngOnInit(): void {
    let menu_access: any[] | null = this.user_data?.access?.menu_access ? JSON.parse(this.user_data?.access?.menu_access) : null;
    if (menu_access && this.user_data.user_role != 1) {
      const menuAccessIds: any = menu_access.filter((menu: any) => Object.values(menu).includes(true)).map((d: any) => d.menu_id);
      let filteredSideMenuItems: any = [];
      for (let i = 0; i < this.sideMenuItems.length; i++) {
        const menu: any = this.sideMenuItems[i];
        if (menu.subMenu) {
          const filteredSubMenuItems = [];

          for (let j = 0; j < menu.subMenuItems.length; j++) {
            const sub_menu: any = menu.subMenuItems[j];

            if (menuAccessIds.includes(sub_menu.menu_id)) {
              filteredSubMenuItems.push(sub_menu);
            }
          }

          if (filteredSubMenuItems.length) {
            const filteredMenu = { ...menu, subMenuItems: filteredSubMenuItems };
            filteredSideMenuItems.push(filteredMenu);
          }
        } else if (menuAccessIds.includes(menu.menu_id)) {
          filteredSideMenuItems.push(menu);
        }
      }
      this.sideMenuItems = filteredSideMenuItems;
      const settings_id = filteredSideMenuItems.findIndex((s: any) => s.menu_id == 18);
      if (settings_id !== -1) {
        if (this.shed?.breedType !== 'breeder') {
          filteredSideMenuItems[settings_id].subMenuItems?.forEach((s: any) => {
            if (s.menu_id == 21 || s.menu_id == 22 || s.menu_id == 23 || s.menu_id == 26 || s.menu_id == 27 || s.menu_id == 28) {
              s.disable = true;
            }
          });
        }
        else {
          filteredSideMenuItems[settings_id].subMenuItems?.forEach((s: any) => {
            if (s.menu_id == 21 || s.menu_id == 22 || s.menu_id == 23 || s.menu_id == 26 || s.menu_id == 27 || s.menu_id == 28) {
              s.disable = false;
            }
          });
        }
      }
      this.sideMenuItems = filteredSideMenuItems;

    } else {
      const settings_id = this.sideMenuItems.findIndex((s: any) => s.menu_id == 18);
      if (settings_id !== -1) {
        if (this.shed?.breedType !== 'breeder') {
          this.sideMenuItems[settings_id].subMenuItems?.forEach((s: any) => {
            if (s.menu_id == 21 || s.menu_id == 22 || s.menu_id == 23 || s.menu_id == 26 || s.menu_id == 27 || s.menu_id == 28) {
              s.disable = true;
            }
          });
        }
        else {
          this.sideMenuItems[settings_id].subMenuItems?.forEach((s: any) => {
            if (s.menu_id == 21 || s.menu_id == 22 || s.menu_id == 23 || s.menu_id == 26 || s.menu_id == 27 || s.menu_id == 28) {
              s.disable = false;
            }
          });
        }
      }
    }
    // Side Nav Toggle Using Services
    this.toggleService.toggle.subscribe((isClose: boolean) => {
      isClose ? this.sidenav?.close() : this.sidenav?.open();
    });

    // if (this.user_data.user_role == 5) {
    //   this.sideMenuItems = this.sideMenuItems.filter((s: any) => {
    //     return s.url == 'day-entry';
    //   });

    // }
    const selectCurrentTab = () => {
      const current_url = window.location.href.split('#').pop()?.replace('/', '');
      this.sideMenuItems.map((data: any, index: any) => {
        if (current_url?.includes(data.url)) {
          this.isActive.forEach((b, index) => this.isActive[index] = false);
          this.isActive[index] = true;
          if (data.subMenu) {
            this.isOpen[index] = true;
          }
        }
      });
    };
    selectCurrentTab();
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        selectCurrentTab();
      }
    });

  }

  ngAfterViewInit(): void {

    setTimeout(() => {

      // Reset Top Bar Arrow Icon  
      this.sidenav.closedStart?.subscribe((isClose: any) => {
        this.toggleService.sideNavToggle(!isClose);
      });

      // To close sideNav When Screen Max-width 800px
      this.screenObserver.observe(["(max-width:959px)"]).subscribe(res => {
        this.is_hide_collapse_icon = !res.matches;
        res.matches ? this.side_bar_width = 'auto' : this.side_bar_width = '15%';
        this.toggleService.sideNavToggle(res.matches);

        // Close SideNav When Routing (for mobiles);

        this.router.events.subscribe(event => {
          event instanceof NavigationEnd && res.matches ?
            [
              this.toggleService.sideNavToggle(event instanceof NavigationEnd),
              this.sidenav.close()
            ] : [
              this.toggleService.sideNavToggle(event instanceof NavigationEnd),
              this.sidenav.open()
            ];
        });

        if (res.matches) {
          this.sidenav.mode = "over";
          this.sidenav.close();
        } else {
          this.sidenav.mode = "side";
          this.sidenav.open();
        }
      });
    });

  }


  expandCollapse() {
    this.isExpand = !this.isExpand;
    if (!this.isExpand) {
      this.isOpen.forEach((b, index) => this.isOpen[index] = false);
    }

  }
  // To Close And Open SubMenu
  openSubMenu(i: number, item?: any) {
    if (item.label == "Configuration" && this.user_data.user_role != 1) {
      this.show_msg.errorSnackBar('Access denied !');
      return;
    }

    if (item.subMenu) {
      this.isExpand = true;
      if (this.isOpen[i]) {
        this.isOpen[i] = !this.isOpen[i];
      } else {
        this.isOpen.forEach((b, index) => this.isOpen[index] = false);
        this.isOpen[i] = true;
      }

    } else {
      this.router.navigateByUrl(item.url);
      this.isOpen.forEach((b, index) => this.isOpen[index] = false);
    }
    this.isActive.forEach((b, index) => this.isActive[index] = false);
    this.isActive[i] = true;
  }

  logout() {
    localStorage.clear();
    window.location.reload();
  }

}
