import { MapsAPILoader } from '@agm/core';
import { Component, ElementRef, Inject, NgZone, OnInit, ViewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MessageService } from 'src/app/core/services/message/message.service';

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss']
})
export class MapComponent implements OnInit {
  latitude!: any;
  longitude!: number;
  zoom!: number;
  address!: any;
  country!: string;
  state!: string;
  geoCoder: any;
  searchControl = new FormControl('', [Validators.required, Validators.minLength(5)]);
  @ViewChild('search')
  public searchElementRef!: ElementRef;
  constructor(private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    public dialogRef: MatDialogRef<MapComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: any,
    private msg: MessageService) { }

  ngOnInit(): void {
    //load Places Autocomplete
    this.mapsAPILoader.load().then(() => {
      this.setCurrentLocation();
      this.geoCoder = new google.maps.Geocoder;

      let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement);
      autocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          //get the place result
          let place: google.maps.places.PlaceResult = autocomplete.getPlace();

          //verify result
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }

          //set latitude, longitude and zoom
          this.latitude = place.geometry.location.lat();
          this.longitude = place.geometry.location.lng();
          this.zoom = 12;
          this.getAddress(this.latitude, this.longitude);
        });
      });
    });
  }

  private setCurrentLocation() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.latitude = position.coords.latitude;
        this.longitude = position.coords.longitude;
        this.zoom = 12;
      });
    }
  }

  markerDragEnd(event: any) {
    this.latitude = event.coords.lat;
    this.longitude = event.coords.lng;
    this.getAddress(this.latitude, this.longitude);
  }
  clickMarker(event: any) {
    this.latitude = event.latitude;
    this.longitude = event.longitude;
    this.getAddress(this.latitude, this.longitude);
  }

  getAddress(latitude: any, longitude: any) {
    this.geoCoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results: any, status: any) => {
      if (status === 'OK') {
        if (results[0]) {
          this.zoom = 12;
          this.address = results[0].formatted_address;
          this.searchControl.setValue(this.address);
          const country_data = results[0].address_components.find((d: any) => {
            return d.types[0].includes('country')
          })
          const state_data = results[0].address_components.find((d: any) => {
            return d.types[0].includes('administrative_area_level_1')
          })
          this.country = country_data.long_name
          this.state = state_data.long_name
        } else {
          this.msg.errorSnackBar('No results found')
        }
      } else {
        this.msg.errorSnackBar('Geocoder failed due to: ' + status)
      }

    });
  }

  mapClick(event: any) {
    this.latitude = event.coords.lat;
    this.longitude = event.coords.lng;
    this.getAddress(this.latitude, this.longitude);
  }


  searchFieldChange() {
    const address_data: any = this.searchControl.value;
    if (address_data?.length < 10) {
      this.address = null;
    }
  }
  submit() {
    const data = {
      address: this.address,
      latitude: this.latitude,
      longitude: this.longitude,
      country: this.country,
      state: this.state
    }
    this.dialogRef.close(data);
  }



}
