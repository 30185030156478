<form fxLayout="column" fxLayoutGap="20">
    <div (click)="cancel()"
        style="margin-bottom: 5px;display: flex;justify-content: end;position: relative;left: 16px;">
        <mat-icon style="transform: rotate(45deg);color: #626262;margin-top: -16px">add_circle_outline</mat-icon>
        <!-- <mat-icon style="margin-top: -16px;">cancel</mat-icon> -->
    </div>
    <div fxFlex="20" class="scoreclass" *ngIf="!endinfo && !isnotStarted">
        <span style="display: flex;text-align: center;width: 80%;">
            Ending the batch will end all the operations in shed. Are you sure you want to end the batch ?
        </span>
    </div>
    <div fxFlex="20" class="scoreclass" *ngIf="endinfo && !isnotStarted">
        <span style="display: flex;text-align: center;width: 80%;">
            Transferring-out of birds is not yet completed. Are you sure you want to end the batch ?
        </span>
    </div>
    <div fxFlex="20" class="scoreclass" *ngIf="isnotStarted">
        <span style="display: flex;text-align: center;width: 80%;">
            Batch has not actually started yet. Are you sure you want to end the batch ?
        </span>
    </div>
    <div fxLayout="row" style="width: 100%;" fxLayoutAlign="center end">
        <div fxFlex="100" fxLayoutAlign="center center" fxLayout.lt-sm="column" fxLayoutGap="25"
            fxLayoutAlign.lt-sm="center center" class="buttondiv" style="width: 100%;max-height: 6vh;">
            <button class="submit grow changeview" mat-raised-button style="box-shadow: none;width: 14%;"
                (click)="submit()">YES, I CONFIRM</button>
            <button class=" cancel grow changeview" mat-raised-button style="box-shadow: none;width: 14%;"
                (click)="cancel()">Cancel</button>
        </div>
    </div>
</form>