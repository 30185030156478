import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeRoutingModule } from './home-routing.module';
import { HomeLayoutComponent } from './home-layout/home-layout.component';
import { MaterialModule } from 'src/shared/material/material.module';
import { UserComponent } from './user/user.component';
import { FullLayoutModule } from '../layout/layout.module';
import { CompanyComponent } from './company/company.component';
import { FarmComponent } from './farm/farm.component';
import { PrivilegeComponent } from './privilege/privilege.component';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from 'src/shared/shared.module';
import { TankComponent } from './Tank/tank.component';

@NgModule({
  declarations: [
    HomeLayoutComponent,
    UserComponent,
    CompanyComponent,
    FarmComponent,
    TankComponent,
    PrivilegeComponent
  ],
  imports: [
    CommonModule,
    TranslateModule.forChild(),
    HomeRoutingModule,
    MaterialModule,
    FullLayoutModule,
    SharedModule
  ],
  exports: [
    UserComponent
  ]
})
export class HomeModule { }
