<mat-card class="responsive-container" fxLayout="column">
    <!-- onkeypress="return event.charCode >= 48 && event.charCode <= 57" type="text" -->
    <!---------------------show message if shed list is empty----------------------------->

    <div *ngIf="!!!shed_list?.length && !is_create_shed">
        <app-common-message [message]="'tank_tab.tank_empty_msg'|translate|titlecase">
        </app-common-message>
    </div>

    <!------------------------------------------------------------------------------------>

    <mat-card *ngIf="is_show_data" style="position: sticky;
        top: 30px; box-shadow: 0px 0px 9px 7px rgb(13 40 210 / 10%), 0 4px 8px rgba(0, 0, 0, 0.1) !important;
        padding: 20px;
        font-size: 20px;z-index: 10;min-height:80%;">
        <div fxLayout="row" fxLayoutAlign="space-between center" style="margin-bottom: 10px;">
            <div class="label">
                {{shed_data.tank_name |titlecase}}
            </div>
            <div>
                <mat-icon (click)="is_show_data=false;$event.stopPropagation();">close</mat-icon>
            </div>
        </div>
        <div style="height: 19.1rem;overflow: auto;">
            <table class="level-one-table" mat-table [dataSource]="sensor_data_source">

                <!-- S.NO Column -->
                <ng-container matColumnDef="S.NO">
                    <th mat-header-cell *matHeaderCellDef>S.NO</th>
                    <td mat-cell *matCellDef="let element;let i = index"> {{i+1}} </td>
                </ng-container>

                <!-- Sensor name Column -->
                <ng-container matColumnDef="Sensor name">
                    <th mat-header-cell *matHeaderCellDef>Sensor name </th>
                    <td mat-cell *matCellDef="let element"><a
                            style="border-bottom: 2px solid #1492E6;color: #1492E6;cursor: pointer;">{{element.name}}</a>
                    </td>
                </ng-container>
                <!-- Type Column -->
                <ng-container matColumnDef="Type">
                    <th mat-header-cell *matHeaderCellDef>Type </th>
                    <td mat-cell *matCellDef="let element">{{element.type}} </td>
                </ng-container>

                <!-- Product code Column -->
                <ng-container matColumnDef="Product code">
                    <th mat-header-cell *matHeaderCellDef> Product code </th>
                    <td mat-cell *matCellDef="let element"> {{element.product_code}} </td>
                </ng-container>

                <!-- Device id Column -->
                <ng-container matColumnDef="Device id">
                    <th mat-header-cell *matHeaderCellDef> Device id </th>
                    <td mat-cell *matCellDef="let element"> {{element.device_id}} </td>
                </ng-container>
                <!-- Status Column -->
                <ng-container matColumnDef="Status">
                    <th mat-header-cell *matHeaderCellDef> Status </th>
                    <td mat-cell *matCellDef="let element"> <span
                            [ngStyle]="element.status=='active' ? {color:'green'}:{color:'red'}">{{element.status}}</span>
                    </td>
                </ng-container>
                <!-- Last update Column -->
                <ng-container matColumnDef="Last update">
                    <th mat-header-cell *matHeaderCellDef> Last update </th>
                    <td mat-cell *matCellDef="let element"> {{element.latest_timestamp |date :'MMM d, y, h:mm:ss a'}}
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>

        </div>
    </mat-card>


    <!------------------------------to show shed list--------------------------------------->

    <div *ngIf="!is_create_shed && !is_edit_shed" fxLayout.lt-sm="column" fxLayout="row warp">
        <ng-container *ngFor="let shed of shed_list;let i = index">
            <mat-card (click)="navigateToShed(shed)" fxFlex="23" fxFlex.sm="48" class="inner-card" fxLayout="column">
                <div fxLayout="row" fxLayoutAlign="space-between start" fxFlex="77"
                    style="padding: 12px;position: relative;overflow: hidden;">
                    <div fxFlex="90" fxLayout="column" fxLayoutGap="5">
                        <div fxFlex="90" class="shed-elipsis" (click)="viewShed(shed);$event.stopPropagation()">
                            <span class="name-underline"> {{shed.tank_name |titlecase}}</span>
                        </div>
                        <div fxFlex="90" class="company-elipsis"
                            [matTooltip]="company_data.name?.length >47 ? company_data.name:''"
                            matTooltipPosition="above" style="font-size: 11.5px;font-weight: 400;">
                            {{company_data.name |titlecase}}
                        </div>
                        
                    </div>
                    <!-- <div>
                        <div *ngIf="user_data.user_role !=5" fxLayout="row" fxLayoutGap="16">
                            <mat-icon *ngIf="access?.edit && !access?.view" style="width: 1.2rem;" svgIcon="Edit"
                                (click)="editShed(shed);$event.stopPropagation();">
                            </mat-icon>
                            <mat-icon *ngIf="access?.delete && !access?.view" style="width: 1rem;" svgIcon="Delete"
                                (click)="deleteShed(shed);$event.stopPropagation();">
                            </mat-icon>
                        </div>
                    </div> -->
                    <div fxLayout="row"
                        *ngIf="user_data.user_role !=5 && ((access?.edit && !access?.view)||(access?.delete && !access?.view))"
                        #triggerReport="matMenuTrigger" [matMenuTriggerFor]="process"
                        (click)="triggerReport.openMenu();$event.stopPropagation()" class="process">
                        <mat-icon class="process" style="color: #166DA6;width: 1.2rem">more_vert</mat-icon>
                    </div>
                    <mat-menu #process="matMenu" xPosition="before">
                        <div class="redirect-menu">
                            <ng-container *ngIf="access?.edit && !access?.view">
                                <button mat-menu-item (click)="editShed(shed);$event.stopPropagation()">
                                    <span>{{'button.edit'|translate|titlecase}}</span>
                                </button>
                            </ng-container>
                            <ng-container *ngIf="access?.delete && !access?.view">
                                <button mat-menu-item (click)="deleteShed(shed);$event.stopPropagation()">
                                    <span>{{'button.delete'|translate|titlecase}}</span>
                                </button>
                            </ng-container>
                        </div>
                    </mat-menu>
                    <div class="tagclass" fxLayout="row" fxLayoutGap="10">
                        <mat-icon class="iconzone" svgIcon="{{shed.fish_type_name}}" style="margin:0%"
                            [ngStyle]="shed.fish_type_name=='fish'? {'width':'30px'}:{}"></mat-icon>
                        <span>{{'tank_tab.'+shed.fish_type_name |translate| titlecase}}</span>
                    </div>
                </div>
                <div fxFlex="30" fxLayout="row" style="background-color: #166DA6;padding: 12px;overflow: hidden;"
                    fxLayoutGap="5" fxLayoutAlign="center center">
                    <div fxLayout="row" fxFlex="25" style="align-items: center;" fxLayoutGap="5">
                        <div class="white-bg">
                            <img style="width: 15px;" src="../../../../assets/images/shed.svg" alt="">
                        </div>
                        <div
                            style="color: #ffffff;font-size: 12px;width: 1.8rem;overflow: hidden;text-overflow: ellipsis;">
                            {{shed.current_age ||0}}
                        </div>
                    </div>
                    <div fxLayout="row" fxFlex="30" style="align-items: center;" fxLayoutGap="5">
                        <div class="white-bg">
                            <img style="width: 18px;" [src]="shed.fish_type_name === 'fish' ? '../../../../assets/images/Fish_icon.svg' : '../../../../assets/images/Shrimp_icon.svg'" alt="">
                        </div>
                        <div
                            style="color: #ffffff;font-size: 12px;width: 3rem;overflow: hidden;text-overflow: ellipsis;">
                            {{
                            shed.fish_count === 0 ? 0
                            : shed.fish_count || shed.start_fish_count ||0 }}
                        </div>
                    </div>
                    <div fxLayout="row" fxFlex="30" style="align-items: center;" fxLayoutGap="5"
                        *ngIf="shed.fish_type_name=='fish' || 'shrimp'">
                        <div class="white-bg">
                            <img style="width: 13px;" src="../../../../assets/images/Weight_machine.svg" alt="">
                        </div>
                        <div
                            style="color: #ffffff;font-size: 12px;width: 3.2rem;overflow: hidden;text-overflow: ellipsis;">
                            {{shed.male_bird_count === 0 ?
                            0:shed.male_bird_count ||
                            shed.start_male_count ||0}}
                        </div>
                    </div>
                </div>

                <!-- <div fxLayout="row" fxLayout="row" fxLayoutAlign="space-evenly center" style="margin-top: 2rem;">

                    <div fxLayout="column" style="text-align: center;align-items: center;">
                        <div class="white-bg">
                            <img style="width: 20px;" src="../../../../assets/images/shed.svg" alt="">
                        </div>
                        <div style="color: #1492E6;font-size: medium;margin-top: 5px;">{{shed.current_age ||0}}</div>
                    </div>
                    <div fxLayout="column" style="text-align: center;align-items: center;"
                        *ngIf="shed.bird_type_name=='breeder'">
                        <div class="white-bg">
                            <img style="width: 19px;" src="../../../../assets/images/cock.svg" alt="">
                        </div>
                        <div style="color: #1492E6;font-size: medium;margin-top: 5px;">{{shed.male_bird_count === 0 ?
                            0:shed.male_bird_count ||
                            shed.start_male_count ||0}}
                        </div>
                    </div>
                    <div fxLayout="column" style="text-align: center;align-items: center;">
                        <div class="white-bg">
                            <img style="width: 30px;" src="../../../../assets/images/Hen.svg" alt="">
                        </div>
                        <div style="color: #1492E6;font-size: medium;margin-top: 5px;">{{
                            shed.female_bird_count === 0 ? 0
                            : shed.female_bird_count || shed.start_female_count ||0 }}
                        </div>
                    </div>
                </div> -->

            </mat-card>


        </ng-container>


    </div>

    <!-- <div *ngIf="!is_create_shed && !is_edit_shed" fxLayout.lt-sm="column" fxLayout="row warp">
        <ng-container *ngFor="let shed of shed_list;let i = index">
            <mat-card style="filter: drop-shadow(0px 0px 1px rgb(207, 207, 207));background: ghostwhite;"
                (click)="navigateToShed(shed)" fxFlex="31" fxFlex.sm="48" fxLayoutGap="30" fxLayoutGap.gt-md="40"
                fxLayoutGap.gt-lg="80" class="shed-info-card" fxLayout="column">
                <div fxLayout="row" fxLayoutAlign="space-between center">
                    <div>
                        <div class="label">{{shed.shed_name |titlecase}}</div>
                        <div style="color: #166DA6  ;">{{shed.company_name ||'company name' |titlecase}} >>
                            </div>
                    </div>

                    <div>
                        <div fxLayout="row" fxLayoutGap="16">
                            <mat-icon *ngIf="shed?.gateway[0]?.gateway_id" class="blink"
                                [ngStyle]="shed.status == 'active' ? {color:'green'}:{color:'red'}">lightbulb
                            </mat-icon>
                        </div>
                    </div>

                </div>
                <div *ngIf="shed?.gateway[0]?.gateway_id" fxLayout="column" fxLayoutGap="10">

                    <table style="width: 100%;color: #166DA6;">
                        <tr style="text-align: left;">
                            <th>Gateway type</th>
                            <th>Gateway id</th>
                            <th>Status</th>
                        </tr>
                        <ng-container *ngFor="let gate of shed.gateway ;let i = index">
                            <tr style="text-align:left">
                                <td>{{i+1 }}. {{gate.type}}</td>
                                <td>{{gate.gateway_id}}</td>
                                <td [ngStyle]="gate.gateway_id ? {color:'green'}:{color:'red'}">Active</td>
                            </tr>
                        </ng-container>


                    </table>

                    <div>
                        <div fxFlex="50" fxLayout="row" fxLayoutAlign="space-between center">
                            <div style="color: green;" (click)="showSensorData(shed);$event.stopPropagation();">
                                <span class="underline">Active sensors :</span>
                                <span> {{shed.active}}</span>
                            </div>
                        </div>
                        <div fxFlex="50" fxLayout="row" fxLayoutAlign="space-between center">
                            <div style="color: red;" (click)="showSensorData(shed);$event.stopPropagation();">
                                <span class="underline">Inactive sensors : </span>
                                <span> {{shed.in_active}}</span>
                            </div>
                        </div>


                    </div>

                </div>
                <div *ngIf="!!!shed?.gateway[0]?.gateway_id" style="text-align:center">

                    <span style="color:  red;text-align: center; "> No GateWay</span>

                </div>



            </mat-card>
        </ng-container>

    </div> -->
    <!------------------------------------------------------------------------------------------->



    <!--------------------------------shed creation farm----------------------------------------->

    <div *ngIf="is_create_shed || is_edit_shed" fxLayout="column">
        <form fxFlex="100" [formGroup]="tank_form_group" style="margin: 15px;" fxLayout="column">
            <div fxLayout.gt-sm="row" fxLayout.lt-sm="column" fxLayoutGap.lt-sm="20"
                fxLayoutAlign.lt-sm="space-between stretch" fxLayoutAlign="space-between start">
                <div fxFlex="30" fxFlex.lt-sm="100" fxLayout="column">
                    <div fxLayout="column" fxLayoutGap="8">
                        <div class="label">
                            {{is_edit_shed?('tank_tab.update_tank_details'|translate|titlecase):'tank_tab.fill_tank_details'|translate|titlecase}}
                        </div>

                        <div>
                            <mat-form-field appearance="outline">
                                <mat-label>{{'tank_tab.tank_name'|translate|titlecase}}</mat-label>
                                <input  (ngModelChange)="is_tank_name_changed = true" (focusout)="isTankNameExist()"
                                    [maxLength]="50" matInput formControlName="tank_name" type="text"
                                    oninput="this.value = this.value.trimStart().replace(/\s+/g, ' ')"
                                    autocomplete="new-password">
                                <mat-error>
                                    <span
                                        *ngIf="tank_form_group.controls['tank_name'].touched  && tank_form_group.get('tank_name')?.hasError('required')">
                                        {{'tank_tab.tank_name_msg'|translate|titlecase}}
                                    </span>
                                    <span
                                        *ngIf="tank_form_group.controls['tank_name'].touched  && tank_form_group.get('tank_name')?.hasError('alreadyExist')">
                                        {{'tank_tab.tank_name_exist_msg'|translate|titlecase}}
                                    </span>
                                </mat-error>
                            </mat-form-field>
                        </div>


                        <div>
                            <mat-form-field appearance="outline">
                                <mat-label>{{'tank_tab.tank_supervisor_name'|translate|titlecase}}</mat-label>
                                <mat-select disableOptionCentering (valueChange)="setContactNo($event)"
                                    formControlName="user_id" panelClass="PanelClass" autocomplete="new-password">
                                    <ng-container *ngFor="let name of supervisor_name_list">
                                        <mat-option [value]="name.user_id">{{name.user_name
                                            |titlecase}}
                                        </mat-option>
                                    </ng-container>
                                </mat-select>
                                <mat-error>
                                    <span
                                        *ngIf="tank_form_group.controls['user_id'].touched  && tank_form_group.get('user_id')?.hasError('required')">
                                        {{'tank_tab.tank_supervisor_name_msg'|translate|titlecase}}
                                    </span>
                                </mat-error>
                            </mat-form-field>
                        </div>

                        <div id="field-disabled">
                            <!-- [min]="0"
                                    (keydown)="$event.key =='e' || $event.key =='E' ||$event.key =='-' || $event.key=='.' ? $event.preventDefault():''" -->
                            <mat-form-field appearance="outline">
                                <mat-label>{{'tank_tab.contact_number'|translate|titlecase}}</mat-label>
                                <input [disabled]="true" matInput formControlName="mobile_no" type="text"
                                    autocomplete="new-password">
                                <mat-error>
                                    <span
                                        *ngIf="tank_form_group.controls['mobile_no'].touched  && tank_form_group.get('mobile_no')?.hasError('required')">
                                        {{'tank_tab.contact_number_msg'|translate|titlecase}}
                                    </span>
                                </mat-error>
                            </mat-form-field>
                        </div>

                        <!-- [maxLength]="5" onkeypress="return event.charCode >= 48 && event.charCode <= 57" -->
                        <div fxFlex="50">
                            <mat-form-field appearance="outline">
                                <mat-label>{{'tank_tab.labour_count'|translate|titlecase}}</mat-label>
                                <input matInput formControlName="labour_count" autocomplete="new-password"
                                    (keydown.arrowup)="$event.preventDefault()"
                                    (keydown.arrowdown)="$event.preventDefault()" (wheel)="$event.preventDefault()"
                                    (keydown)="$event.key =='e' || $event.key =='E' || $event.key =='+'|| $event.key =='-' ||$event.key == '.'? $event.preventDefault():''"
                                    oninput="this.value=this.value.replace(/[^0-9]/g,'');javascript: if (this.value.length >5) this.value = this.value.slice(0,5);"
                                    type="number">
                                <mat-error>
                                    <span
                                        *ngIf="tank_form_group.controls['labour_count'].touched  && tank_form_group.get('labour_count')?.hasError('required')">
                                        {{'tank_tab.labour_count_msg'|translate|titlecase}}
                                    </span>
                                </mat-error>
                            </mat-form-field>
                        </div>

                        <div>
                            <mat-form-field appearance="outline">
                                <mat-label>{{'tank_tab.length_of_tank'|translate|titlecase}}</mat-label>
                                <input matInput formControlName="length" (keydown.arrowup)="$event.preventDefault()"
                                    (keydown.arrowdown)="$event.preventDefault()" (wheel)="$event.preventDefault()"
                                    (keydown)="$event.key =='e' || $event.key =='E' || $event.key =='+'|| $event.key =='-' ||$event.key == '.'? $event.preventDefault():''"
                                    oninput="this.value=this.value.replace(/[^0-9]/g,'');javascript: if (this.value.length >4) this.value = this.value.slice(0,4);"
                                    type="number" autocomplete="new-password">
                                <mat-error>
                                    <span
                                        *ngIf="tank_form_group.controls['length'].touched  && tank_form_group.get('length')?.hasError('required')">
                                        {{'tank_tab.length_of_tank_msg'|translate|titlecase}}
                                    </span>
                                </mat-error>
                            </mat-form-field>
                        </div>

                        <div>
                            <mat-form-field appearance="outline">
                                <mat-label>{{'tank_tab.width_of_tank'|translate|titlecase}}</mat-label>
                                <input matInput formControlName="width" (keydown.arrowdown)="$event.preventDefault()"
                                    (wheel)="$event.preventDefault()"
                                    (keydown)="$event.key =='e' || $event.key =='E' || $event.key =='+'|| $event.key =='-' ||$event.key == '.'? $event.preventDefault():''"
                                    oninput="this.value=this.value.replace(/[^0-9]/g,'');javascript: if (this.value.length >4) this.value = this.value.slice(0,4);"
                                    type="number" autocomplete="new-password">
                                <mat-error>
                                    <span
                                        *ngIf="tank_form_group.controls['width'].touched  && tank_form_group.get('width')?.hasError('required')">
                                        {{'tank_tab.width_of_tank_msg'|translate|titlecase}}
                                    </span>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div>
                            <mat-form-field appearance="outline">
                                <mat-label>{{'tank_tab.height_of_tank'|translate|titlecase}}</mat-label>
                                <input matInput formControlName="height" (keydown.arrowdown)="$event.preventDefault()"
                                    (wheel)="$event.preventDefault()"
                                    (keydown)="$event.key =='e' || $event.key =='E' || $event.key =='+'|| $event.key =='-' ||$event.key == '.'? $event.preventDefault():''"
                                    oninput="this.value=this.value.replace(/[^0-9]/g,'');javascript: if (this.value.length >4) this.value = this.value.slice(0,4);"
                                    type="number" autocomplete="new-password">
                                <mat-error>
                                    <span
                                        *ngIf="tank_form_group.controls['height'].touched  && tank_form_group.get('height')?.hasError('required')">
                                        {{'tank_tab.height_of_tank_msg'|translate|titlecase}}
                                    </span>
                                </mat-error>
                            </mat-form-field>
                        </div>

                    </div>


                </div>
                <div fxHide fxShow.gt-sm
                    style="border-left: 1.5px solid #BCBCBC;height: 31.3rem;position: relative;top: 2.2rem;">
                </div>
                <div fxFlex="30" fxFlex.lt-sm="100" fxLayout="column" fxLayoutGap="8">
                    <div class="label">{{'tank_tab.house_type'|translate|titlecase}}</div>
                    <div>
                        <!-- [min]="0"
                                (keydown)="$event.key =='e' || $event.key =='E' ||$event.key =='-' || $event.key=='.' ? $event.preventDefault():''" -->
                        <mat-form-field appearance="outline">
                            <mat-label>{{'tank_tab.tank_type'|translate|titlecase}}</mat-label>
                            <!-- <input matInput formControlName="tank_type_id" type="text"
                                autocomplete="new-password"> -->
                                <mat-select disableOptionCentering formControlName="tank_type_id" panelClass="PanelClass"
                                autocomplete="new-password">
                                <ng-container *ngFor="let type of shed_type_list">
                                    <mat-option
                                        [value]="type.tank_type_id">{{'tank_tab.'+type.name |translate |titlecase}}
                                    </mat-option>
                                </ng-container>
                            </mat-select>

                            <mat-error>
                                <span
                                    *ngIf="tank_form_group.controls['tank_type_id'].touched  && tank_form_group.get('tank_type_id')?.hasError('required')">
                                    {{'tank_tab.tank_type_msg'|translate|titlecase}}
                                </span>
                            </mat-error>
                        </mat-form-field>
                    </div>
                    
                    <!-- <div>
                        <mat-form-field appearance="outline">
                            <mat-label>{{'tank_tab.no_of_partitions'|translate|titlecase}}</mat-label>
                            <input matInput formControlName="partition_count"
                                (keydown.arrowdown)="$event.preventDefault()" (wheel)="$event.preventDefault()"
                                (keydown)="$event.key =='e' || $event.key =='E' || $event.key =='+'|| $event.key =='-' ||$event.key == '.'? $event.preventDefault():''"
                                oninput="this.value=this.value.replace(/[^0-9]/g,'');javascript: if (this.value.length >4) this.value = this.value.slice(0,4);"
                                type="number" autocomplete="new-password">
                            <mat-error>
                                <span
                                    *ngIf="shed_form_group.controls['partition_count'].touched  && shed_form_group.get('partition_count')?.hasError('required')">
                                    {{'tank_tab.no_of_partitions_msg'|translate|titlecase}}
                                </span>
                            </mat-error>
                        </mat-form-field>
                    </div> -->


                </div>
                <div fxHide fxShow.gt-sm
                    style="border-left: 1.5px solid #BCBCBC;height: 31.3rem;position: relative;top: 2.2rem;">
                </div>


                <div fxFlex="30" fxFlex.lt-sm="100" fxLayout="column">

                    <div fxLayout="column" fxLayoutGap="8">
                        <div class="label">{{'tank_tab.breed_details'|translate|titlecase}}</div>
                        <div>
                            <mat-form-field appearance="outline">
                                <mat-label>{{'tank_tab.breed_type'|translate|titlecase}}</mat-label>
                                <mat-select disableOptionCentering formControlName="fish_type_id"
                                    (selectionChange)="birdTypeSelecetionChange($event)" autocomplete="new-password"
                                    panelClass="PanelClass">
                                    <ng-container *ngFor="let type of bird_type_list">
                                        <mat-option
                                            [value]="type.fish_type_id">{{'tank_tab.'+type.name |translate |titlecase}}
                                        </mat-option>
                                    </ng-container>
                                </mat-select>
                                <mat-error>
                                    <span
                                        *ngIf="tank_form_group.controls['fish_type_id'].touched  && tank_form_group.get('fish_type_id')?.hasError('required')">
                                        {{'tank_tab.breed_type_msg'|translate|titlecase}}
                                    </span>
                                </mat-error>
                            </mat-form-field>
                        </div>

                        <!-- <div *ngIf="is_breed">
                            <mat-form-field appearance="outline">
                                <mat-label>{{'tank_tab.total_box'|translate|titlecase}}</mat-label>
                                <input matInput formControlName="box_count"
                                    (keydown.arrowdown)="$event.preventDefault()" (wheel)="$event.preventDefault()"
                                    (keydown)="$event.key =='e' || $event.key =='E' || $event.key =='+'|| $event.key =='-' ||$event.key == '.'? $event.preventDefault():''"
                                    oninput="this.value=this.value.replace(/[^0-9]/g,'');javascript: if (this.value.length >8) this.value = this.value.slice(0,8);"
                                    type="number" autocomplete="new-password">
                                <mat-error>
                                    <span
                                        *ngIf="shed_form_group.controls['box_count'].touched  && shed_form_group.get('box_count')?.hasError('required')">
                                        {{'tank_tab.total_box_msg'|translate|titlecase}}
                                    </span>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div *ngIf="is_breed">
                            <mat-form-field appearance="outline">
                                <mat-label>{{male_breed?('tank_tab.female/box'|translate|titlecase):'tank_tab.bird/box'|translate|titlecase}}</mat-label>
                                <input matInput formControlName="female_box_count"
                                    (keydown.arrowdown)="$event.preventDefault()" (wheel)="$event.preventDefault()"
                                    (keydown)="$event.key =='e' || $event.key =='E' || $event.key =='+'|| $event.key =='-' ||$event.key == '.'? $event.preventDefault():''"
                                    oninput="this.value=this.value.replace(/[^0-9]/g,'');javascript: if (this.value.length >2) this.value = this.value.slice(0,2);"
                                    type="number" autocomplete="new-password">
                                <mat-error>
                                    <span
                                        *ngIf="shed_form_group.controls['female_box_count'].touched  && shed_form_group.get('female_box_count')?.hasError('required')">
                                        {{male_breed?('tank_tab.female/box_msg'|translate|titlecase):'tank_tab.bird/box_msg'|translate|titlecase}}
                                    </span>
                                </mat-error>
                            </mat-form-field>
                        </div>

                        <div *ngIf="is_breed && male_breed">
                            <mat-form-field appearance="outline">
                                <mat-label>{{'tank_tab.male/box'|translate|titlecase}}</mat-label>
                                <input matInput formControlName="male_box_count" type="number"
                                    (keydown.arrowdown)="$event.preventDefault()" (wheel)="$event.preventDefault()"
                                    (keydown)="$event.key =='e' || $event.key =='E' || $event.key =='+'|| $event.key =='-' ||$event.key == '.'? $event.preventDefault():''"
                                    oninput="this.value=this.value.replace(/[^0-9]/g,'');javascript: if (this.value.length >2) this.value = this.value.slice(0,2);"
                                    type="number" autocomplete="new-password">
                                <mat-error>
                                    <span
                                        *ngIf="shed_form_group.controls['male_box_count'].touched  && shed_form_group.get('male_box_count')?.hasError('required')">
                                        {{'tank_tab.male_box_msg'|translate|titlecase}}
                                    </span>
                                </mat-error>
                            </mat-form-field>
                        </div> -->

                        <div>
                            <mat-form-field appearance="outline">
                                <mat-label> {{
                                    selectedBreedType === ''
                                      ? ('tank_tab.no_of_count' | translate | titlecase)
                                      : selectedBreedType == 1
                                        ? ('tank_tab.no_of_fish' | translate | titlecase)
                                        : ('tank_tab.no_of_shrimp' | translate | titlecase)
                                  }}</mat-label>
                                <input matInput formControlName="fish_count"
                                    (keydown.arrowdown)="$event.preventDefault()" (wheel)="$event.preventDefault()"
                                    (keydown)="$event.key =='e' || $event.key =='E' || $event.key =='+'|| $event.key =='-' ||$event.key == '.'? $event.preventDefault():''"
                                    oninput="this.value=this.value.replace(/[^0-9]/g,'');javascript: if (this.value.length >8) this.value = this.value.slice(0,8);"
                                    type="number" autocomplete="new-password">
                                <mat-error>
                                    <span
                                        *ngIf="tank_form_group.controls['fish_count'].touched  && tank_form_group.get('fish_count')?.hasError('required')">
                                        {{
                                            selectedBreedType === ''
                                              ? ('tank_tab.no_of_count_msg' | translate | titlecase)
                                              : selectedBreedType == 1
                                                ? ('tank_tab.no_of_fish_msg' | translate | titlecase)
                                                : ('tank_tab.no_of_shrimp_msg' | translate | titlecase)
                                          }}
                                    </span>
                                </mat-error>
                            </mat-form-field>
                        </div>

                    </div>



                </div>

            </div>

            <div style="margin: 10px 0px;" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="50"
                fxLayoutGap.lt-sm="25">
                <button class="submit grow" mat-stroked-button
                    (click)="submit()">{{is_edit_shed?('button.update'|translate|titlecase):'button.submit'|translate|titlecase}}</button>
                <button class="cancel grow" mat-stroked-button
                    (click)="clearAll()">{{'button.cancel'|translate|titlecase }}</button>

            </div>

        </form>



    </div>

    <!------------------------------------------------------------------------------------------->




</mat-card>