<div class="p-1 h-100" fxLayout="column" fxLayoutGap="10">
    <!-- Blue header with create and list button with company details -->
    <mat-card style="background-color: #166DA6;color: white;height:4rem;overflow: hidden;" fxLayout="row"
        fxLayoutAlign="space-between center">
        <div fxLayout="column">
            <span style="font-size: 13px;color: white;font-weight: bold;line-height: 1;">{{shed_name}}</span>
            <span style="line-height: 1;margin-top: 7px;font-size: 10px;
     color: #FFFFFF;">{{company_name}}</span>
        </div>
        <form [formGroup]="header_group" fxLayoutAlign="center center" fxLayout="row"
            style="height: 2.9rem;background: white;border-radius: 5px;">
            <!--  form field for age in weeks-->
            <mat-form-field appearance="outline" style="height: 3.5rem !important;color: black !important;">
                <mat-label>{{'productivity_score.age_in_weeks'|translate|titlecase}}</mat-label>
                <input matInput formControlName="age_In_Week" type="number" (keydown.arrowup)="$event.preventDefault()"
                    (keydown.arrowdown)="$event.preventDefault()" (wheel)="$event.preventDefault()"
                    (keydown)="$event.key =='e' || $event.key =='E' || $event.key =='+'|| $event.key =='-' ||$event.key == '.'? $event.preventDefault():''"
                    oninput="this.value=this.value.replace(/^(0*|[^\d]+)/g,''); if (this.value.length > 3) this.value = this.value.slice(0, 3);"
                    autocomplete="new-password" [readonly]="isview" autocomplete="off">
            </mat-form-field>
        </form>

        <button mat-stroked-button class="add-button grow" fxLayout="row" (click)="clear()">
            <mat-icon svgIcon="list" style="margin-right: 6px;height: 22px;width: 22px;"></mat-icon>
            <span>{{'growth_score.list'|translate|uppercase}}</span>
        </button>
    </mat-card>

    <mat-card class="mat-elevation-z6" fxLayout="column" fxLayoutGap="10px" fxLayoutAlign="space-between center"
        style="height: 90%;">
        <!-- form container for sensor age in weeks-->
        <!-- <form [formGroup]="header_group" class="productivityscoreField fieldprod"
            style="display: flex;gap: 2%;width:100%">
            <mat-form-field appearance="outline">
                <mat-label>{{'productivity_score.age_in_weeks'|translate|titlecase}}</mat-label>
                <input matInput formControlName="age_In_Week" type="number" (keydown.arrowup)="$event.preventDefault()"
                    (keydown.arrowdown)="$event.preventDefault()" (wheel)="$event.preventDefault()"
                    (keydown)="$event.key =='e' || $event.key =='E' || $event.key =='+'|| $event.key =='-' ||$event.key == '.'? $event.preventDefault():''"
                    oninput="this.value=this.value.replace(/^(0*|[^\d]+)/g,''); if (this.value.length > 3) this.value = this.value.slice(0, 3);"
                    autocomplete="new-password" [readonly]="isview" autocomplete="off">
            </mat-form-field>
        </form> -->
        <!--Container -->
        <div style="width: 100%;background-color: #f5faf5;" class="tableProductivity formdata">
            <!-- Form Container for Totaleggs-->
            <form style="padding: 2%;overflow: auto;" class="scrollnull">
                <table mat-table [dataSource]="dataSource1" matSort>
                    <!-- threshold column  -->
                    <ng-container matColumnDef="Threshold">
                        <th mat-header-cell *matHeaderCellDef>{{'productivity_score.threshold'|translate|titlecase}}
                        </th>
                        <td mat-cell *matCellDef="let element;let i = index"> {{i+1}} </td>
                    </ng-container>
                    <!-- Totaleggs value -->
                    <ng-container matColumnDef="Value">
                        <th mat-header-cell *matHeaderCellDef>
                            {{'productivity_score.total_eggs'|translate|titlecase}}
                        </th>
                        <td mat-cell *matCellDef="let element;let i = index">
                            <div *ngIf="i !=(Totaleggs.controls.length-1)" style="text-align: start;margin-left: 25%;">
                                <mat-form-field appearance="outline" style="width:70%">
                                    <mat-label>{{'productivity_score.value'|translate|titlecase}}</mat-label>
                                    <input type="number" (keydown.arrowup)="$event.preventDefault()"
                                        (keydown.arrowdown)="$event.preventDefault()" (wheel)="$event.preventDefault()"
                                        (keydown)="$event.key =='e' || $event.key =='E' || $event.key =='+'|| $event.key =='-' ? $event.preventDefault():''"
                                        oninput="if(!/^\d{1,3}(\.\d{0,2})?$/.test(this.value)) this.value = this.value.slice(0, -1)"
                                        autocomplete="new-password"
                                        (focusout)="validateThresholdValue(i,'Value',Totaleggs)" matInput
                                        [formControl]="getControl(i,'Value',Totaleggs)"
                                        [readonly]="isview" /></mat-form-field>
                            </div>
                            <div *ngIf="i ==(Totaleggs.controls.length-1)" class="disableclassprod"
                                style="text-align: start;margin-left: 25%;">
                                <mat-form-field appearance="outline" style="width:70%;pointer-events:none;">
                                    <mat-label>{{'productivity_score.max'|translate|titlecase}}</mat-label>
                                    <input type="number" matInput [readonly]="true" />
                                </mat-form-field>
                                <mat-icon (click)="info(Totaleggs,'Value')"
                                    style="position: relative;top: 5px;">info</mat-icon>
                            </div>
                        </td>
                    </ng-container>
                    <!-- Totaleggs score -->
                    <ng-container matColumnDef="Score">
                        <th mat-header-cell *matHeaderCellDef>{{'productivity_score.score'|translate|titlecase}}</th>
                        <td mat-cell *matCellDef="let element;let i=index" class="productivityscoreField" fxLayout="row"
                            fxLayoutGap="25">
                            <div fxFlex="80">
                                <mat-slider style="width:75% !important ;margin-top: 20px;"
                                    [formControl]="getControl(i,'mandatory',Totaleggs)" [disabled]="isview" thumbLabel
                                    min="-10" max="10"></mat-slider>
                            </div>
                            <div [ngStyle]="isview ? {'pointer-events':'none'}:{}" fxFlex="20"
                                style="display: flex;align-items: center;justify-content: end;">
                                <mat-icon style="color:red;" *ngIf="i > 3 && i !=(Totaleggs.controls.length-1)"
                                    (click)="removeSensor(i,dataSource1,Totaleggs)">do_not_disturb_on</mat-icon>
                                <mat-icon style="color:#4bae4f;" *ngIf="i ==(Totaleggs.controls.length-2)"
                                    (click)="addSensor(dataSource1,Totaleggs,i)">add_circle</mat-icon>
                            </div>
                        </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="displayedColumns1"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns1;"></tr>
                </table>
            </form>
            <!-- Form Container for Selectedeggs-->
            <form style="padding: 2%;overflow: auto;" class="scrollnull">
                <table mat-table [dataSource]="dataSource2" matSort>
                    <!-- threshold column  -->
                    <ng-container matColumnDef="Threshold">
                        <th mat-header-cell *matHeaderCellDef>{{'productivity_score.threshold'|translate|titlecase}}
                        </th>
                        <td mat-cell *matCellDef="let element;let i = index"> {{i+1}} </td>
                    </ng-container>
                    <!-- Selectedeggs value -->
                    <ng-container matColumnDef="Value">
                        <th mat-header-cell *matHeaderCellDef>
                            {{'productivity_score.selected_egg'|translate|titlecase}}
                        </th>
                        <td mat-cell *matCellDef="let element;let i = index">
                            <div *ngIf="i !=(Selectedeggs.controls.length-1)"
                                style="text-align: start;margin-left: 25%;">
                                <mat-form-field appearance="outline" style="width:70%">
                                    <mat-label>{{'productivity_score.value'|translate|titlecase}}</mat-label>
                                    <input type="number" (keydown.arrowup)="$event.preventDefault()"
                                        (keydown.arrowdown)="$event.preventDefault()" (wheel)="$event.preventDefault()"
                                        (keydown)="$event.key =='e' || $event.key =='E' || $event.key =='+'|| $event.key =='-' ? $event.preventDefault():''"
                                        oninput="if(!/^\d{1,3}(\.\d{0,2})?$/.test(this.value)) this.value = this.value.slice(0, -1)"
                                        autocomplete="new-password"
                                        (focusout)="validateThresholdValue(i,'Value',Selectedeggs)" matInput
                                        [formControl]="getControl(i,'Value',Selectedeggs)"
                                        [readonly]="isview" /></mat-form-field>
                            </div>
                            <div *ngIf="i ==(Selectedeggs.controls.length-1)" class="disableclassprod"
                                style="text-align: start;margin-left: 25%;">
                                <mat-form-field appearance="outline" style="width:70%;pointer-events:none;">
                                    <mat-label>{{'productivity_score.max'|translate|titlecase}}</mat-label>
                                    <input type="number" matInput [readonly]="true" />
                                </mat-form-field>
                                <mat-icon (click)="info(Selectedeggs,'Value')"
                                    style="position: relative;top: 5px;">info</mat-icon>
                            </div>
                        </td>
                    </ng-container>
                    <!-- Selectedeggs score -->
                    <ng-container matColumnDef="Score">
                        <th mat-header-cell *matHeaderCellDef>{{'productivity_score.score'|translate|titlecase}}</th>
                        <td mat-cell *matCellDef="let element;let i=index" class="productivityscoreField" fxLayout="row"
                            fxLayoutGap="25">
                            <div fxFlex="80">
                                <mat-slider style="width:75% !important ;margin-top: 20px;"
                                    [formControl]="getControl(i,'mandatory',Selectedeggs)" [disabled]="isview"
                                    thumbLabel min="-10" max="10"></mat-slider>
                            </div>
                            <div [ngStyle]="isview ? {'pointer-events':'none'}:{}" fxFlex="20"
                                style="display: flex;align-items: center;justify-content: end;">
                                <mat-icon style="color:red;" *ngIf="i > 3 && i !=(Selectedeggs.controls.length-1)"
                                    (click)="removeSensor(i,dataSource2,Selectedeggs)">do_not_disturb_on</mat-icon>
                                <mat-icon style="color:#4bae4f;" *ngIf="i ==(Selectedeggs.controls.length-2)"
                                    (click)="addSensor(dataSource2,Selectedeggs,i)">add_circle</mat-icon>
                            </div>
                        </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="displayedColumns1"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns1;"></tr>
                </table>
            </form>
            <!-- Form Container for CumulativeTotaleggs-->
            <form style="padding: 2%;overflow: auto;" class="scrollnull">
                <table mat-table [dataSource]="dataSource3" matSort>
                    <!-- threshold column  -->
                    <ng-container matColumnDef="Threshold">
                        <th mat-header-cell *matHeaderCellDef>{{'productivity_score.threshold'|translate|titlecase}}
                        </th>
                        <td mat-cell *matCellDef="let element;let i = index"> {{i+1}} </td>
                    </ng-container>
                    <!-- CumulativeTotaleggs value -->
                    <ng-container matColumnDef="Value">
                        <th mat-header-cell *matHeaderCellDef>
                            {{'productivity_score.cumulative_eggs'|translate|titlecase}}
                        </th>
                        <td mat-cell *matCellDef="let element;let i = index">
                            <div *ngIf="i !=(CumulativeTotaleggs.controls.length-1)"
                                style="text-align: start;margin-left: 25%;">
                                <mat-form-field appearance="outline" style="width:70%">
                                    <mat-label>{{'productivity_score.value'|translate|titlecase}}</mat-label>
                                    <input type="number" (keydown.arrowup)="$event.preventDefault()"
                                        (keydown.arrowdown)="$event.preventDefault()" (wheel)="$event.preventDefault()"
                                        (keydown)="$event.key =='e' || $event.key =='E' || $event.key =='+'|| $event.key =='-' ? $event.preventDefault():''"
                                        oninput="if(!/^\d{1,3}(\.\d{0,2})?$/.test(this.value)) this.value = this.value.slice(0, -1)"
                                        autocomplete="new-password"
                                        (focusout)="validateThresholdValue(i,'Value',CumulativeTotaleggs)" matInput
                                        [formControl]="getControl(i,'Value',CumulativeTotaleggs)"
                                        [readonly]="isview" /></mat-form-field>
                            </div>
                            <div *ngIf="i ==(CumulativeTotaleggs.controls.length-1)" class="disableclassprod"
                                style="text-align: start;margin-left: 25%;">
                                <mat-form-field appearance="outline" style="width:70%;pointer-events:none;">
                                    <mat-label>{{'productivity_score.max'|translate|titlecase}}</mat-label>
                                    <input type="number" matInput [readonly]="true" />
                                </mat-form-field>
                                <mat-icon (click)="info(CumulativeTotaleggs,'Value')"
                                    style="position: relative;top: 5px;">info</mat-icon>
                            </div>
                        </td>
                    </ng-container>
                    <!-- CumulativeTotaleggs score -->
                    <ng-container matColumnDef="Score">
                        <th mat-header-cell *matHeaderCellDef>{{'productivity_score.score'|translate|titlecase}}</th>
                        <td mat-cell *matCellDef="let element;let i=index" class="productivityscoreField" fxLayout="row"
                            fxLayoutGap="25">
                            <div fxFlex="80">
                                <mat-slider style="width:75% !important ;margin-top: 20px;"
                                    [formControl]="getControl(i,'mandatory',CumulativeTotaleggs)" [disabled]="isview"
                                    thumbLabel min="-10" max="10"></mat-slider>
                            </div>
                            <div [ngStyle]="isview ? {'pointer-events':'none'}:{}" fxFlex="20"
                                style="display: flex;align-items: center;justify-content: end;">
                                <mat-icon style="color:red;"
                                    *ngIf="i > 3 && i !=(CumulativeTotaleggs.controls.length-1)"
                                    (click)="removeSensor(i,dataSource3,CumulativeTotaleggs)">do_not_disturb_on</mat-icon>
                                <mat-icon style="color:#4bae4f;" *ngIf="i ==(CumulativeTotaleggs.controls.length-2)"
                                    (click)="addSensor(dataSource3,CumulativeTotaleggs,i)">add_circle</mat-icon>
                            </div>
                        </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="displayedColumns1"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns1;"></tr>
                </table>
            </form>
            <!-- Form Container for cumulativeSelectedeggs-->
            <form style="padding: 2%;overflow: auto;" class="scrollnull">
                <table mat-table [dataSource]="dataSource4" matSort>
                    <!-- threshold column  -->
                    <ng-container matColumnDef="Threshold">
                        <th mat-header-cell *matHeaderCellDef>{{'productivity_score.threshold'|translate|titlecase}}
                        </th>
                        <td mat-cell *matCellDef="let element;let i = index"> {{i+1}} </td>
                    </ng-container>
                    <!-- cumulativeSelectedeggs value -->
                    <ng-container matColumnDef="Value">
                        <th mat-header-cell *matHeaderCellDef>
                            {{'productivity_score.cumulative_selected_eggs'|translate|titlecase}}
                        </th>
                        <td mat-cell *matCellDef="let element;let i = index">
                            <div *ngIf="i !=(cumulativeSelectedeggs.controls.length-1)"
                                style="text-align: start;margin-left: 25%;">
                                <mat-form-field appearance="outline" style="width:70%">
                                    <mat-label>{{'productivity_score.value'|translate|titlecase}}</mat-label>
                                    <input type="number" (keydown.arrowup)="$event.preventDefault()"
                                        (keydown.arrowdown)="$event.preventDefault()" (wheel)="$event.preventDefault()"
                                        (keydown)="$event.key =='e' || $event.key =='E' || $event.key =='+'|| $event.key =='-' ? $event.preventDefault():''"
                                        oninput="if(!/^\d{1,3}(\.\d{0,2})?$/.test(this.value)) this.value = this.value.slice(0, -1)"
                                        autocomplete="new-password"
                                        (focusout)="validateThresholdValue(i,'Value',cumulativeSelectedeggs)" matInput
                                        [formControl]="getControl(i,'Value',cumulativeSelectedeggs)"
                                        [readonly]="isview" /></mat-form-field>
                            </div>
                            <div *ngIf="i ==(cumulativeSelectedeggs.controls.length-1)" class="disableclassprod"
                                style="text-align: start;margin-left: 25%;">
                                <mat-form-field appearance="outline" style="width:70%;pointer-events:none;">
                                    <mat-label>{{'productivity_score.max'|translate|titlecase}}</mat-label>
                                    <input type="number" matInput [readonly]="true" />
                                </mat-form-field>
                                <mat-icon (click)="info(cumulativeSelectedeggs,'Value')"
                                    style="position: relative;top: 5px;">info</mat-icon>
                            </div>
                        </td>
                    </ng-container>
                    <!-- cumulativeSelectedeggs score -->
                    <ng-container matColumnDef="Score">
                        <th mat-header-cell *matHeaderCellDef>{{'productivity_score.score'|translate|titlecase}}</th>
                        <td mat-cell *matCellDef="let element;let i=index" class="productivityscoreField" fxLayout="row"
                            fxLayoutGap="25">
                            <div fxFlex="80">
                                <mat-slider style="width:75% !important ;margin-top: 20px;"
                                    [formControl]="getControl(i,'mandatory',cumulativeSelectedeggs)" [disabled]="isview"
                                    thumbLabel min="-10" max="10"></mat-slider>
                            </div>
                            <div [ngStyle]="isview ? {'pointer-events':'none'}:{}" fxFlex="20"
                                style="display: flex;align-items: center;justify-content: end;">
                                <mat-icon style="color:red;"
                                    *ngIf="i > 3 && i !=(cumulativeSelectedeggs.controls.length-1)"
                                    (click)="removeSensor(i,dataSource4,cumulativeSelectedeggs)">do_not_disturb_on</mat-icon>
                                <mat-icon style="color:#4bae4f;" *ngIf="i ==(cumulativeSelectedeggs.controls.length-2)"
                                    (click)="addSensor(dataSource4,cumulativeSelectedeggs,i)">add_circle</mat-icon>
                            </div>
                        </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="displayedColumns1"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns1;"></tr>
                </table>
            </form>
            <!-- Form Container for Avgeggs-->
            <form style="padding: 2%;overflow: auto;" class="scrollnull">
                <table mat-table [dataSource]="dataSource5" matSort>
                    <!-- threshold column  -->
                    <ng-container matColumnDef="Threshold">
                        <th mat-header-cell *matHeaderCellDef>{{'productivity_score.threshold'|translate|titlecase}}
                        </th>
                        <td mat-cell *matCellDef="let element;let i = index"> {{i+1}} </td>
                    </ng-container>
                    <!-- Avgeggs value -->
                    <ng-container matColumnDef="Value">
                        <th mat-header-cell *matHeaderCellDef>
                            {{'productivity_score.avg_weight'|translate|titlecase}}
                        </th>
                        <td mat-cell *matCellDef="let element;let i = index">
                            <div *ngIf="i !=(Avgeggs.controls.length-1)" style="text-align: start;margin-left: 25%;">
                                <mat-form-field appearance="outline" style="width:70%">
                                    <mat-label>{{'productivity_score.value'|translate|titlecase}}</mat-label>
                                    <input type="number" (keydown.arrowup)="$event.preventDefault()"
                                        (keydown.arrowdown)="$event.preventDefault()" (wheel)="$event.preventDefault()"
                                        (keydown)="$event.key =='e' || $event.key =='E' || $event.key =='+'|| $event.key =='-' ? $event.preventDefault():''"
                                        oninput="if(!/^\d{1,3}(\.\d{0,2})?$/.test(this.value)) this.value = this.value.slice(0, -1)"
                                        autocomplete="new-password"
                                        (focusout)="validateThresholdValue(i,'Value',Avgeggs)" matInput
                                        [formControl]="getControl(i,'Value',Avgeggs)"
                                        [readonly]="isview" /></mat-form-field>
                            </div>
                            <div *ngIf="i ==(Avgeggs.controls.length-1)" class="disableclassprod"
                                style="text-align: start;margin-left: 25%;">
                                <mat-form-field appearance="outline" style="width:70%;pointer-events:none;">
                                    <mat-label>{{'productivity_score.max'|translate|titlecase}}</mat-label>
                                    <input type="number" matInput [readonly]="true" />
                                </mat-form-field>
                                <mat-icon (click)="info(Avgeggs,'Value')"
                                    style="position: relative;top: 5px;">info</mat-icon>
                            </div>
                        </td>
                    </ng-container>
                    <!-- Avgeggs score -->
                    <ng-container matColumnDef="Score">
                        <th mat-header-cell *matHeaderCellDef>{{'productivity_score.score'|translate|titlecase}}</th>
                        <td mat-cell *matCellDef="let element;let i=index" class="productivityscoreField" fxLayout="row"
                            fxLayoutGap="25">
                            <div fxFlex="80">
                                <mat-slider style="width:75% !important ;margin-top: 20px;"
                                    [formControl]="getControl(i,'mandatory',Avgeggs)" [disabled]="isview" thumbLabel
                                    min="-10" max="10"></mat-slider>
                            </div>
                            <div [ngStyle]="isview ? {'pointer-events':'none'}:{}" fxFlex="20"
                                style="display: flex;align-items: center;justify-content: end;">
                                <mat-icon style="color:red;" *ngIf="i > 3 && i !=(Avgeggs.controls.length-1)"
                                    (click)="removeSensor(i,dataSource5,Avgeggs)">do_not_disturb_on</mat-icon>
                                <mat-icon style="color:#4bae4f;" *ngIf="i ==(Avgeggs.controls.length-2)"
                                    (click)="addSensor(dataSource5,Avgeggs,i)">add_circle</mat-icon>
                            </div>
                        </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="displayedColumns1"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns1;"></tr>
                </table>
            </form>
        </div>
        <!-- Submit button -->
        <!-- while create flow its submit button if its update flow it will show update button 
            and if its view flow its didnt show any submit or update button it only shows back button  -->
        <div fxLayout="row" fxFlex="30" style="width: 100%;margin-top: 30px;" fxLayoutAlign="center end">
            <div fxFlex="100" fxLayoutAlign="center center" fxLayout.lt-sm="column" fxLayoutGap="25"
                fxLayoutAlign.lt-sm="center center" class="buttondiv" style="width: 100%;max-height: 6vh;">
                <button class="submit grow changeview" mat-raised-button style="box-shadow: none;width: 14%;"
                    (click)="submit()"
                    *ngIf="!isview">{{isupdate?('button.update'|translate|titlecase):'submit'|translate|titlecase}}</button>
                <button class="cancel grow changeview" mat-raised-button style="box-shadow: none;width: 14%;"
                    (click)="clear()">{{isview?('button.back'|translate|titlecase):'button.cancel'|translate|titlecase}}</button>
            </div>
        </div>
    </mat-card>
</div>