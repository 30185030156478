import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Subscription, interval, mergeMap } from 'rxjs';
import { HomeService } from 'src/app/core/services/home/home.service';
import { MessageDialogComponent } from 'src/shared/message-dialog/message-dialog.component';
import { valuedata } from '../settings.model';
import { SettingsService } from 'src/app/core/services/settings/settings.service';
import { MatTableDataSource } from '@angular/material/table';
import { MessageService } from 'src/app/core/services/message/message.service';

@Component({
  selector: 'app-list-score-alerts',
  templateUrl: './list-score-alerts.component.html',
  styleUrls: ['./list-score-alerts.component.scss']
})
export class ListScoreAlertsComponent implements OnInit {
  pageType: any;
  createscreen = false;
  isloaded = false;
  public company_name = localStorage.getItem('company')?.length ? JSON.parse(localStorage.getItem('company') || '')?.name : '--';
  public shed_name = localStorage.getItem('tank')?.length ? JSON.parse(localStorage.getItem('tank') || '')?.name : '--';
  public access: any;
  public subscriptions: Subscription[] = [];

  score_alert_form = new FormGroup({
    shed_id: new FormControl('', Validators.required),
    type: new FormControl('', Validators.required),
    low_value: new FormControl('', Validators.required),
    low_level_id: new FormControl('', Validators.required),
    too_low_value: new FormControl('', Validators.required),
    too_low_level_id: new FormControl('', Validators.required),
    high_value: new FormControl('', Validators.required),
    high_level_id: new FormControl('', Validators.required),
    too_high_value: new FormControl('', Validators.required),
    too_high_level_id: new FormControl('', Validators.required)
  });
  editMode = false;
  haveData = false;
  constructor(private fb: FormBuilder, private show_msg: MessageService, private SettingsService: SettingsService, private dialog: MatDialog, private home: HomeService
  ) {
    this.access = this.home.getAccess(20);
    this.pageType = window.location.href.split('/').pop();
    this.score_alert_form.patchValue({
      shed_id: JSON.parse(localStorage.getItem('tank') || '')?.id,
      type: this.pageType

    });
  }
  data: any;
  levelList: any[] = [];
  public shed = localStorage.getItem('tank')?.length ? JSON.parse(localStorage.getItem('tank') || '') : null;

  ngOnInit(): void {
    console.log(this.shed);

    this.editMode = false;
    this.getScoreAlertData();
    this.getAlertDatas();
  }


  getScoreAlertData() {
    this.SettingsService.getAllScoreAlertLevelData(this.pageType).subscribe((res: any) => {
      console.log(res);

      this.data = res?.data[0];
      if (res.data?.length) {
        this.haveData = true;
        this.score_alert_form.patchValue({
          low_value: this.data.low_value,
          low_level_id: this.data.low_level_id,
          too_low_value: this.data.too_low_value,
          too_low_level_id: this.data.too_low_level_id,
          high_value: this.data.high_value,
          high_level_id: this.data.high_level_id,
          too_high_value: this.data.too_high_value,
          too_high_level_id: this.data.too_high_level_id,
        });
        this.score_alert_form.controls['low_value'].disable();
        this.score_alert_form.controls['too_low_value'].disable();
        this.score_alert_form.controls['high_value'].disable();
        this.score_alert_form.controls['too_high_value'].disable();


      } else {
        this.haveData = false;
      }

      this.isloaded = true;
    }, (error: Response) => {
      this.data = [];
      this.haveData = false;

      this.isloaded = true;
      console.log(error);
    });
  }
  getAlertDatas() {
    this.subscriptions.push(this.SettingsService.getAlarts().subscribe((res: any) => {
      this.levelList = res.data;
    }, (error: Response) => {
      console.log(error);
    }));
  }
  summit() {

    this.editMode ? this.update() : this.save();
  }
  save() {
    if (this.score_alert_form.invalid) {
      return this.show_msg.errorSnackBar('no_empty_field');
    }
    this.SettingsService.saveScoreAlertData(this.score_alert_form.value).subscribe(res => {
      console.log(res);
      this.score_alert_form.reset();
      this.score_alert_form.patchValue({
        shed_id: JSON.parse(localStorage.getItem('tank') || '')?.id,
        type: this.pageType
      });
      this.ngOnInit();
    });
  }

  update() {
    if (this.score_alert_form.invalid) {
      return this.show_msg.errorSnackBar('no_empty_field');
    }
    this.SettingsService.updateScoreAlertData(this.data.id, this.score_alert_form.value).subscribe(res => {
      console.log(res);
      this.score_alert_form.reset();
      this.score_alert_form.patchValue({
        shed_id: JSON.parse(localStorage.getItem('tank') || '')?.id,
        type: this.pageType
      });
      this.ngOnInit();
    });
  }
  enableFields() {
    this.score_alert_form.controls['low_value'].enable();
    this.score_alert_form.controls['too_low_value'].enable();
    this.score_alert_form.controls['high_value'].enable();
    this.score_alert_form.controls['too_high_value'].enable();
  }
  clearData() {
    this.score_alert_form.reset();
    this.ngOnInit();

  }
}