export enum AUTH_API {
    login = 'user/login',
    requestOTP = 'authentication/requestOtp',
    validateOTP = 'authentication/validateOtp',
    changePasssword = 'authentication/changePassword'
}
export enum DAY_ENTRY {
    GET_FEED = 'feed',
    GET_EQUIPMENT = 'equipment/showByTank',
    GET_EQPT_FAILURE_BY_ID = 'failure/showByEId',
    GET_OPENING_STOCK = 'daywiseentry/getOpeningStockForDaywiseentry',
    GET_DAY_ENTRY = 'getdaywiseentry',
    SUBMIT_DAY_ENTRY = 'daywiseentry',
    GET_LAST_DAY_ENTRY = 'daywiseentry/getLastDayentry'
}
export enum REPORT {
    GET_REPORT = 'reports/gettankanddatefilter',
    GET_SENSOR_REPORT = 'reports/getSensorReports',
    GET_REPORT_COLLECTION = 'reports/getReportCollection',
    GET_DOWNLOAD_URL = 'reports/getDownloadUrl',
    GET_REPORT_AS_JSON = 'reports/getReportAsJSON',
    REGENERATE_MISSING = 'reports/regenerateMissing',
    REGENERATE_STATUS = 'reports/getReportGenStatus'
}
export enum SETTINGS {
    IOT = "iot",
    GET_IOT_SERIAL_NO = "iot/getIotUnderTank",
    GET_TOPIC = "iot/getTopicsUsingSerialNoAndTank",
    SYNCALERTSALARM = 'sensor/syncAlerts',
    SYNCALERTSLEVEL = 'alert/syncAlertLevels',
    SYNCUSERSETTINGS = 'user/syncUserSettings',
    SYNCSENSORCONFIG = 'sensor/syncSensorConfig',
    SYNCSENSORCONFIGSTATUS = 'sensor/showSensorConfigStatus',
    SYNCALERTSALARMSTATUS = 'sensor/showAlertValueStatus',
    SYNCALERTSLEVELSTATUS = 'alert/showAlertLevelStatus',
    SAVESENSORALARM = 'sensor/createSensorAlertSop',
    GETSENSORALARM = 'sensor/showSensorAlertSopByTankId',
    UPDATEALARM = 'sensor/updateSensorAlertSop/',
    DELETEALARM = 'sensor/removeSensorAlertSop/',
    SAVEEQUIPMENT = 'equipment/',
    GETEQUIPMENT = 'equipment/',
    GETFAULTEQUIPMENT = 'failure/showByTank',
    SAVEFAULT = 'failure/',
    GET_All_SENSORS = 'sensor/showsensorSop/',
    GET_All_UNITS = 'unit/',
    GET_SENSORS = 'sensor/showSensorSopByTankId',
    SAVESENSORS = 'sensor/createsensorSop',
    UPDATESENSORS = 'sensor/updatesensorSop/',
    DELETESENSOR = 'sensor/removesensorSop/',
    GETALERTS = 'alert/showAlertLevelSopUsingTankId',
    SAVEALERT = 'alert/createAlertLevelSop',
    UPDATEALERT = 'alert/updateAlertLevelSop/',
    DELETEALERT = 'alert/removeAlertLevelSop/',
    EQUIPMENTDROPDOWN = 'equipment/showByShed/1',
    SENSORTYPE = 'sensor/showsensorType/',
    GETENVIRONMENTSCORE = 'environment/showByTankId',
    ENVIRONMENTSCORE = 'environment/',
    SCOREALERT = 'score/?shed_id=',
    saveScore = 'score'
}

export enum CWA {
    SAVE_CWA = 'user/updateCompanyList'
}
export enum BATCH {
    BATCH = 'batch',
    DELETE_BATCH = 'batch',
    GET_ACTIVE_BATCH_USING_TANKID = 'batch/showActiveBatchWithTankId',
    GET_ACTIVE_OR_ALL__BATCH = 'batch/showActiveBatchWithShedId',
    GET_BREED = 'batch/showBreedName',
    CURRENT_BATCH = 'batch/showCurrentBatchDetails',

    GET_MEDICATION = 'medication/getMedicationUnderTank',
    GET_MEDICATION_SCHEDULE = 'medication/getUntakenMedsSchedule',
    GET_ALL_MEDICATION_SCHEDULE = 'medication/medicationSchedule',
    SUBMIT_MEDICATION = 'medication/medicationSchedule',
    ADD_MEDICATION = 'medication',
    DELETE_MEDICATION = 'medication',
    SCHEDULE_STATUS = 'medication/medicationScheduleStatus',

}
export enum DASHBOARD {
    GET_LATEST_SENSOR_DATA = "dashboard/getLatestSensorData?gateway_id=",
    DEVICENAME = 'sensor/getSensorSopUsingDeviceId?device_id=',
    DATEENTRY = 'dashboard/showDaywiseWithTankAndDateFilter/',
    EQUIPMENTENTRY = 'equipment/showEquipmentLogWithTankAndDateFilter/',
    SENSORHISTORY = 'dashboard/fumesdatas',
    MAJORALARTS = 'alert/showAlertLogWithTankAndDateFilter',
    SHOWSCORE = 'dashboard/showScore',
    GROWTHSCORE = 'dashboard/showGrowthScore',
    ENVIRONMENTALSCORE = 'dashboard/showEnvScore',
    PRODUCT = 'dashboard/products',
    GET_FCR = 'dashboard/showFcrGraph'
}

export enum IOT {
    GET_IOT = 'iot/getIotUnderTank'
}

export enum HOME {
    CREAT_SHED = 'tank',
    USER = 'user',
    GET_USER_BY_ID = 'user/getUserUsingId',
    REGISTER_USER = 'user/managementRegistration',
    ROLE = 'role',
    GET_ALL_TANK_BY_TANK_ID = 'tank/showTankById',
    GET_ALL_TANKS_UNDER_COMPANY = 'tank/getAllTanksUnderCompany',
    GET_ALL_TANKS_UNDER_USER = 'tank/getAllTanksUnderUser',
    GET_TANK_TYPE = 'tank/showTankType',
    GET_FISH_TYPE = 'tank/showFishType',

    GET_ALL_SHED_BY_USER_ID = 'tank/getAllShedsUnderUser',
    GET_ALL_SHED_BY_FARM_ID = 'tank/getAllShedsUnderFarm',
    GET_ALL_SHED_FOR_TC = 'tank/getShedsForTechUnderFarm',
    GET_SHED_SUPERVISOR = 'farm/getSupervisorUnderFarm',
    GET_SHED_SUPERVISOR_UNDER_COMPANY = 'company/getSupervisorUnderCompany',
    GET_ALL_USER_UNDER_COMPANY = 'user/getUserUnderCompany',
    GET_BIRD_TYPE = 'tank/showBirdType',
    // GET_CAGE_TYPE = 'tank/showCageType',
    GET_SHED_TYPE = 'tank/showShedType',
    FARM = 'farm',
    GET_FARM_UNDER_COMPANY = 'farm/getFarmsUnderCompany',
    GET_FARM_UNDER_FARM_USER = 'farm/getFarmsUnderUser',
    GET_FARM_MANAGER_UNDER_COMPANY = 'company/getFarmManagersUnderCompany',
    COMPANY = 'company',
    CREATE_COMPANY = 'user/createCompanyUser',
    GET_ALL_COMPANY_MANAGER = 'company/getCompanyManagers',
    GET_COUNTRY = 'company/getCountry',
    GET_STATE = 'company/getStateusingCountryid',
    GET_PRIVILIGE = 'user/getUserPrivilege',
    UPDATE_PRIVILIGE = 'user/updateUserPrivilege',
    TOKEN_VALIDATION = "user/tokenValidation",
}

export enum GROWTH {
    CREATE_GROWTH = 'growth',
    GET_GROWTH = '/growth/showByshedId',
    BULKUPLOAD = 'growth/bulkUpdate',
    GROTH_SCORE = 'daywiseentry/getGrowthScoreUnderShedAndBatch'
}
export enum MORTALITY {
    CREATE_MORTALITY = 'mortality',
    GET_MORTALITY = '/mortality/getMortalityUnderShed',
    BULKUPLOAD = 'mortality/bulkUpdate'
}
export enum PRODUCTIVITY {
    CREATE_PRODUCTIVITY = 'productivity',
    GET_PRODUCTIVITY = '/productivity/getProductivityUnderShed',
    BULKUPLOAD = 'productivity/bulkUpdate'
}

export enum COMMON_API {
    IS_RECORD_EXIST = 'user/isRecordExist',
    GET_SHED_DETAILS = 'tank/showTankById',
    SEND_MAIL = 'company/sendVerificationMail'
}

export enum RECALCULATE {
    GORWTH_SCORE = 'daywiseentry/reCalculateGrowth',
    PRODUCTIVITY_SCORE = 'daywiseentry/reCalculateProductivity',
    MORTALITY_SCORE = 'daywiseentry/reCalculateMortality',
    GROWTH_RECAL_STATUS = 'daywiseentry/getRecalculatedGrowthStatus',
    PRODUCTIVITY_RECAL_STATUS = 'daywiseentry/getRecalculatedProdStatus',
    MORTALITY_RECAL_STATUS = 'daywiseentry/getRecalculatedMortStatus',
}

export enum CONTROLLER {
    GET_CONTROLLER_DATA = 'dashboard/showLatestControllerData',
}