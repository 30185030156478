<div class="bg-img">
    <mat-card fxLayout="column" fxLayoutGap.sm="10" fxLayoutGap.gt-sm="10" fxLayoutGap.lt-sm="0" fxLayoutGap.lt-sm="10"
        fxFlex.sm="50" fxFlex.gt-sm="50" fxFlex.lt-sm="85" class="card-container mat-elevation-z8">
        <img height="40%" src="../../../assets/images/forgot_password.svg" alt="">
        <!-- Email Information -->
        <span class="label">{{'otp.enter_otp'|translate|titlecase}}
            <br>{{securedEmail ? securedEmail :'--'}}</span>
        <form style="padding-top: 15px;" [formGroup]="otpFormGroup" fxLayout="row" fxLayoutAlign="center center"
            fxLayoutGap="35" fxLayoutGap.lt-sm="15" (paste)="handlePaste($event)">

            <!-- OTP Input Fields -->
            <input class="otp-outline align-center" onKeyPress="if(this.value.length==1) return false;" #otp1
                (keyup)="moveFocus($event,otp1)" type="number" formControlName="otp1" autocomplete="new-password">
            <input class="otp-outline align-center" onKeyPress="if(this.value.length==1) return false;" #otp2
                (keyup)="moveFocus($event,otp2)" type="number" formControlName="otp2" autocomplete="new-password">
            <input class="otp-outline align-center" onKeyPress="if(this.value.length==1) return false;" #otp3
                (keyup)="moveFocus($event,otp3)" type="number" formControlName="otp3" autocomplete="new-password">
            <input class="otp-outline align-center" onKeyPress="if(this.value.length==1) return false;" #otp4
                (keyup)="moveFocus($event,otp4)" type="number" formControlName="otp4" autocomplete="new-password">
        </form>
        <!-- Verify Button and Resend OTP Link -->
        <div style="padding-top: 20px;" fxLayout="column">
            <div fxLayoutAlign="center center">
                <button fxFlex="69" class="submit grow" (click)="submit()"
                    mat-raised-button>{{'otp.verify'|translate|uppercase}}</button>
            </div>

            <span *ngIf="isOTPExpired(otp_expire_date)" style="color: #A4A4A4;padding-top: 20px;font-size: 11px;">
                {{'otp.not_received_otp'|translate}}<a
                    style="color: #166DA6;font-size: 13px;font-weight:600 ;white-space: nowrap;"
                    href="javascript:void(0);" (click)="resendOtp()"> {{'otp.resend_otp'|translate|uppercase}}</a>
            </span>
            <span *ngIf="!isOTPExpired(otp_expire_date)" style="color: #A4A4A4;padding-top: 20px;font-size: 11px;">
                {{'otp.resend_otp_in'|translate|titlecase}}<span
                    style="color: #166DA6;font-size: 13px;font-weight:600 ;"> {{count_down}}</span></span>


        </div>
    </mat-card>
</div>