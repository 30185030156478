<form fxLayout="column"  fxLayoutGap="20">
    <div class="lineclass" style="font-size: 18px;"><b>{{'batch.starting_details' | translate | titlecase}}</b></div>
    <div fxLayout="row wrap"  fxLayoutGap="20">
        <div fxFlex="30" fxFlex.lt-md="45"  fxLayout="column"  fxLayoutGap="10" class="margbtm">
            <div class="lineclass">{{'batch.breed_type' | translate | titlecase}}</div>
            <div><b>{{'batch.'+birdtype|translate|titlecase}}</b></div>
        </div>
        <div fxFlex="30" fxFlex.lt-md="45" fxLayout="column"  fxLayoutGap="10" class="margbtm">
            <div class="lineclass">{{'batch.breed_name' | translate | titlecase}}</div>
            <div><b>{{batchData.breed_name | titlecase}}</b></div>
        </div>
        <div fxFlex="30" fxFlex.lt-md="45" fxLayout="column"  fxLayoutGap="10" class="margbtm">
            <div class="lineclass">{{'batch.batch_number' | translate | titlecase}}</div>
            <div><b>{{batchData.batch_number}}</b></div>
        </div>
    </div>
    <div fxLayout="row wrap"  fxLayoutGap="20">
        <div fxFlex="30" fxFlex.lt-md="45" fxLayout="column"  fxLayoutGap="10" class="margbtm">
            <div class="lineclass">{{'batch.batch_start_date' | translate | titlecase}}</div>
            <div><b>{{batchData.start_date |date:'dd-MM-YYYY'}}</b></div>
        </div>
        <div fxFlex="30" fxFlex.lt-md="45" fxLayout="column"  fxLayoutGap="10" class="margbtm">
            <div class="lineclass">{{'batch.age_in_weeks' | translate | titlecase}}</div>
            <div><b>{{batchData.start_age_week}}</b></div>
        </div>
        <div fxFlex="30" fxFlex.lt-md="45" fxLayout="column"  fxLayoutGap="10" class="margbtm">
            <div class="lineclass">{{'batch.age_in_days' | translate | titlecase}}</div>
            <div><b>{{batchData.start_age_days}}</b></div>
        </div>
        <div fxFlex="30" fxFlex.lt-md="45" fxLayout="column"  fxLayoutGap="10" class="margbtm">
            <div class="lineclass">{{birdtype==='fish'?('fish'|translate | titlecase):('shrimp' |translate | titlecase)}} {{'batch.count' | translate | titlecase}}</div>
            <div><b>{{batchData.start_fish_count}}</b></div>
        </div>
        <div fxFlex="30" fxFlex.lt-md="45" fxLayout="column"  fxLayoutGap="10" class="margbtm" *ngIf="birdtype=='breeder'">
            <div class="lineclass">{{'batch.male_count' | translate | titlecase}}</div>
            <div><b>{{batchData.start_male_count}}</b></div>
        </div>
    </div>
    <div class="lineclass"  style="font-size: 18px;"><b id="batchdetails">{{endbatchboo?('batch.Batch end details' |translate | titlecase): ('batch.current_details' |translate | titlecase)}}</b></div>
    <div fxLayout="row wrap"  fxLayoutGap="20" >
        <div fxFlex="30" fxFlex.lt-md="45" fxLayout="column"  fxLayoutGap="10" class="margbtm">
            <div class="lineclass" id="batchdate">{{endbatchboo?('batch.Batch end date' |translate | titlecase):('batch.Current Batch date' |translate | titlecase)}}</div>
            <div><b>{{endcrrDate |date:'dd-MM-YYYY'
            }}</b></div>
        </div>
        <div fxFlex="30" fxFlex.lt-md="45" fxLayout="column"  fxLayoutGap="10" class="margbtm" >
            <div class="lineclass">{{'batch.age_in_weeks' | translate | titlecase}}</div>
            <div><b>{{weekend!=''?weekend:batchData.start_age_week}}</b></div>
        </div>
        <div fxFlex="30" fxFlex.lt-md="45" fxLayout="column"  fxLayoutGap="10" class="margbtm" >
            <div class="lineclass">{{'batch.age_in_days' | translate | titlecase}}</div>
            <div><b>{{dayend!=''?dayend:batchData.start_age_days}}</b></div>
        </div>
        <div fxFlex="30" fxFlex.lt-md="45" fxLayout="column"  fxLayoutGap="10" class="margbtm" >
            <div class="lineclass" id="femalecount">{{birdtype==='fish'?('batch.Fish count' |translate | titlecase):'Shrimp count'}} {{endbatchboo?('batch.on end date' |translate | titlecase):''}}</div>
            <div><b>{{batchData.start_fish_count?batchData.start_fish_count:batchData.end_fish_count}}</b></div>
        </div>
        
    </div>
</form>
