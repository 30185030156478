import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from 'src/shared/material/material.module';
import { IotGatewayComponent } from '../iot-gateway/iot-gateway.component';
import { ConfigurationRoutingModule } from './configuration-routing.module';
import { SensorComponent } from '../sensor/sensor.component';
import { AddSensorComponent } from '../add-sensor/add-sensor.component';
import { FullLayoutModule } from "../../layout/layout.module";
import { SharedModule } from 'src/shared/shared.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [
    SensorComponent,
    AddSensorComponent,
    IotGatewayComponent,
  ],
  imports: [
    CommonModule,
    TranslateModule.forChild(),
    MaterialModule,
    ConfigurationRoutingModule,
    FullLayoutModule,
    SharedModule
  ]
})
export class ConfigurationModule { }
